import React, { useEffect } from "react";
// import './App.css';

import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import { Link } from "react-router-dom";

import {
  Button,
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
  RenderPageProps,
} from "@react-pdf-viewer/core";

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import GlobalConfigs from "../../../configs/GlobalConfigs";

import { useSelector } from 'react-redux';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useState } from "react";

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useLocation, useHistory } from "react-router-dom"

const AWS = require('aws-sdk');
AWS.config.update({
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
});

const s3 = new AWS.S3({ apiVersion: '2006-03-01' });



// const pdfUrl = "https://decentraland.org/whitepaper.pdf";
// const pdfUrl = "https://www.africau.edu/images/default/sample.pdf";

export default function ConceptMapPdfView(props) {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const loacation = useLocation()

  // const pdfUrl = "https://crestest-dam-bucket.s3.us-east-2.amazonaws.com/dam/demoexam_1665100800_130.pdf";
  // const pdfUrl = "https://crestest-dam-bucket.s3.us-east-2.amazonaws.com/dam/NTGCH5CM1_2021.1F-2.pdf";
  // const pdfUrl = "";

  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);
  const [filePathFromList, setFilePathFromList] = useState(loacation.state);
  const [pdfUrl, setPdfUrl] = useState();
  const [showFirstPageView, setShowFirstPageView] = useState(0);
  const [registrationModal, setRegistrationModal] = useState(false)
  const [previousPageFilterData, setPreviousPageFilterData] = useState('');

  useEffect(() => {

    // console.log("ConceptMapPdfView---22--", loacation.state.conceptMapPath);

    // console.log("ConceptMapPdfView---22--", loacation.state);

    setPreviousPageFilterData(props.location.filterData)

    // setPdfUrl(filePathFromList.conceptMapPath);
    // setPdfUrl(loacation.state.conceptMapPath+"?r="+(Date.now()));
    // setPdfUrl(loacation.state.conceptMapPath+"?r="+(Date.now()));
    getPdfContentFromAWSS3BucketPrivately(loacation.state.conceptMapPath)

    const handleContextmenu = e => {
      e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextmenu)

    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  useEffect(() => {
    caches.delete(pdfUrl);
  }, [pdfUrl])

  /* open pdf link */
  const openLinksPlugin = () => {
    //console.log(">>>>>>>>>>>openLinksPlugin>>>>>>>>>>>");

    const findLinks = (e) => {
      //console.log(">>>>>>>>>>>>>>>>>>>>>>", e.container);

      e.container.querySelectorAll('a:not([data-annotation-link-dest])').forEach((link) => {
        // console.log("####################", link);
        // link.addEventListener('click', handleClick);
        let href = link.getAttribute('href');
        // console.log("####################href", href);
        if (href && href != "") {
          // console.log("sssssssssssssssssssssssssssssssss");
          link.addEventListener('click', handleClick);
        }
      });
    };

    /* create link */
    const handleClick = (e) => {
      e.preventDefault();
      let href = (e.target).href;
      console.log("href", href);
      let domainName = process.env.REACT_APP_ADMIN_PDF_URL;
      let midPath = "elibrary-content-pdf-view"
      // let midPath = "demo-e-library-pdf"
      if (href.indexOf("http") >= 0) {
        //this is an old link and has the full path along with domain name
        let parts = href.split("/");
        href = parts[parts.length - 1];
      }
      let finalURL = domainName + midPath + "/" + href;
      console.log("href", href);
      console.log("finalURL", finalURL);
      window.open(finalURL, '_self', 'noreferrer');
    };

    return {
      onAnnotationLayerRender: findLinks,
    }
  }

  const openLinksPluginInstance = openLinksPlugin();

  // const findLinks = (e) => {
  //   e.container.querySelectorAll('a[data-target="external"]').forEach((link) => {
  //     link.addEventListener('click', handleClick);
  //   });
  // };

  /* const openLinksPlugin = () => {
    const store = React.useMemo(() => createStore < StoreProps > ({}), []);
  }; */

  /* find s3 pdf find name */
  const getPdfContentFromAWSS3BucketPrivately = (url) => {
    //const S3_BUCKET_NAME = "crestest-dam-bucket"; //process.env.S3_BUCKET_NAME;
    setPdfUrl();
    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;

    let parts = url.split("/");
    // href = parts[parts.length - 1];
    const pdfFileName = parts[parts.length - 1];

    let pdfFileWithoutPageNumber = pdfFileName.split("#");
    let finalPdfFileWithoutPageNumber = pdfFileWithoutPageNumber[0];

    // console.log("$>>>>", parts)
    // console.log("$>>11>>", pdfFileName)
    // console.log("$>>222>>", finalPdfFileWithoutPageNumber)

    // let fullPathPdf = `elibrary/${pdfFileName}`
    let fullPathPdf = `elibrary/${finalPdfFileWithoutPageNumber}`

    // console.log("$>>fullPathPdf>>", fullPathPdf)
    s3.getObject({
      Bucket: S3_BUCKET_NAME,
      // Key: "elibrary/1674811975750NTPHCH1CM.pdf",
      Key: fullPathPdf,
      ResponseContentType: 'Uint8Array',
    }, (err, data) => {
      if (err) {
        console.log("@@Error : " + err);
      } else {
        // console.log ( "@@data.Body : " + data.Body );
        setPdfUrl(data.Body);
      }
    });
  }


  /* pdf plugin */
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  let noteId = notes.length;

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={highlightContent}>
              <MessageIcon />
            </Button>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: 'none' }}>
          {props.textLayer.children}
        </div>
        {props.annotationLayer.children}
      </>
    );
  };

  /* navigtion go back */
  const onGoBackHandler = () => {
    /* history.push({
			pathname: "/elibrary-content-details",
			state: {data: loacation.state, filterData: previousPageFilterData},
		  }); */
    history.goBack();
  }

  return (
    <div className="App">
      {/* <h1>Demo Exam Details</h1> */}
      <div className="col-xl-2">
        <div className="back-arrow-container">
          <Link
            onClick={onGoBackHandler}>
            <i class="fa-solid fa-arrow-left"></i>
          </Link>
        </div>
      </div>
      {pdfUrl != undefined && pdfUrl != '' ?
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
          <div
            style={{
              alignItems: 'center',
              backgroundColor: '#eeeeee',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              padding: '4px',
            }}
          >
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </div>
          <div
            style={{ height: "700px", border: "1px solid rgba(250, 0, 250, 1)" }}
          >
            {/* {console.log("URL-PDF-11--", pdfUrl)} */}
            <Viewer
              initialPage={showFirstPageView}
              fileUrl={pdfUrl}
              renderPage={renderPage}
              // plugins={[highlightPluginInstance, zoomPluginInstance]}
              plugins={[openLinksPluginInstance, highlightPluginInstance, zoomPluginInstance]}
            /* plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
          ]} */
            />
          </div>
        </Worker>
        : null}
    </div>
  );
}
