import {
    QUESTION_PATTERN_LOADING,
    ADD_QUESTION_PATTERN,
    GET_QUESTION_PATTERN,
    REMOVE_QUESTION_PATTERN,
    UPLOAD_STATUS_QUESTION_PATTERN
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function QuestionPatternReducer(state = initialState, action) {

    if (action.type === ADD_QUESTION_PATTERN) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTION_PATTERN) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPLOAD_STATUS_QUESTION_PATTERN) {

        const patternList = [...state.list];
        const index = patternList.findIndex((item) => item.id === action.payload.id);
        patternList[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: patternList,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_QUESTION_PATTERN) {

        const patternList = [...state.list];
        const index = patternList.findIndex((item) => item.id === action.payload);
        patternList.splice(index, 1);

        return {
            ...state,
            list: patternList,
            showLoading: false,
        };
    }

    if (action.type === QUESTION_PATTERN_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


