import {
    EXAM_DETAILS_LOADING,
    GET_SCHOLASTIC_EXAM_DETAILS,
    GET_COMPETITIVE_EXAM_DETAILS,
    UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS,
    UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS,
    REMOVE_SCHOLASTIC_EXAM_DETAILS,
    REMOVE_COMPETITIVE_EXAM_DETAILS,
    SCHOLASTIC_ACADEMIC_SESSION,
    SCHOLASTIC_EXAM_DETAILS_BOARD,
    SCHOLASTIC_EXAM_DETAILS_CLASS,
    SCHOLASTIC_EXAM_DETAILS_SUBJECT,
    SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION,
    SCHOLASTIC_EXAM_DETAILS_STAUS,
    COMPETITIVE_EXAM_DETAILS_TYPE,
    COMPETITIVE_EXAM_DETAILS_CLASS,
    COMPETITIVE_EXAM_DETAILS_SUBJECT,
    COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION,
    COMPETITIVE_EXAM_DETAILS_STAUS,
    COMPETITIVE_EXAM_DETAILS_SUB_TYPE

} from '../constants';

const initialState = {
    scholasticExamDetailslist: [],
    competitiveExamDetailslist: [],
    showLoading: false,
    ScholasticAcademicSession: '',
    CompetitiveAcademicSession: '',

    scholasticExamDetailsBoard: 0,
    scholasticExamDetailsClass: 0,
    scholasticExamDetailsSubject: 0,
    scholasticExamDetailsAccademicSession: 0,
    scholasticExamDetailsStaus: '',

    competitiveExamDetailsType: 0,
    competitiveExamDetailsClass: 0,
    competitiveExamDetailsSubject: 0,
    competitiveExamDetailsAccademicSession: 0,
    competitiveExamDetailsStaus: '',
    competitiveExamDetailsSubType: 0,

};

export function ExamDetailsReducer(state = initialState, action) {

    if (action.type === GET_SCHOLASTIC_EXAM_DETAILS) {
        return {
            ...state,
            scholasticExamDetailslist: action.payload.data,
            showLoading: true,
        };
    }
    if (action.type === SCHOLASTIC_ACADEMIC_SESSION) {
        return {
            ...state,
            ScholasticAcademicSession: action.payload,
            showLoading: true,
        };
    }

    if (action.type === GET_COMPETITIVE_EXAM_DETAILS) {
        return {
            ...state,
            competitiveExamDetailslist: action.payload.data,
            CompetitiveAcademicSession: action.payload.academic_session_master,
            showLoading: true,
        };
    }

    if (action.type === UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS) {

        const list = [...state.scholasticExamDetailslist];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            scholasticExamDetailslist: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS) {

        const list = [...state.competitiveExamDetailslist];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            competitiveExamDetailslist: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_SCHOLASTIC_EXAM_DETAILS) {

        const list = [...state.scholasticExamDetailslist];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            scholasticExamDetailslist: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_COMPETITIVE_EXAM_DETAILS) {

        const list = [...state.competitiveExamDetailslist];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            competitiveExamDetailslist: list,
            showLoading: false,
        };
    }

    if (action.type === EXAM_DETAILS_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === SCHOLASTIC_EXAM_DETAILS_BOARD) {
        return {
            ...state,
            scholasticExamDetailsBoard: action.payload,
        };
    }

    if (action.type === SCHOLASTIC_EXAM_DETAILS_CLASS) {
        return {
            ...state,
            scholasticExamDetailsClass: action.payload,
        };
    }

    if (action.type === SCHOLASTIC_EXAM_DETAILS_SUBJECT) {
        return {
            ...state,
            scholasticExamDetailsSubject: action.payload,
        };
    }

    if (action.type === SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION) {
        return {
            ...state,
            scholasticExamDetailsAccademicSession: action.payload,
        };
    }

    if (action.type === SCHOLASTIC_EXAM_DETAILS_STAUS) {
        return {
            ...state,
            scholasticExamDetailsStaus: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_TYPE) {
        return {
            ...state,
            competitiveExamDetailsType: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_CLASS) {
        return {
            ...state,
            competitiveExamDetailsClass: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_SUBJECT) {
        return {
            ...state,
            competitiveExamDetailsSubject: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION) {
        return {
            ...state,
            competitiveExamDetailsAccademicSession: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_STAUS) {
        return {
            ...state,
            competitiveExamDetailsStaus: action.payload,
        };
    }

    if (action.type === COMPETITIVE_EXAM_DETAILS_SUB_TYPE) {
        return {
            ...state,
            competitiveExamDetailsSubType: action.payload,
        };
    }

    return state;
}


