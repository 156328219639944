import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { validateText, validateShortCode } from '../../../utility/Utility';

import { updateTypeExamData } from '../../../store/actions/TypeExamAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateTypeExam = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation();

	let errorsObj = { type: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [typeExma, setTypeExma] = useState(state.type_of_exam_name);

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (typeExma === '') {
			errorObj.type = 'Type of exam name is Required';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(updateTypeExamData(state.id, typeExma, props.history));
		setTypeExma('');
	}

	/* navigation go back */
	const onGoBackHandler = () => {
		history.goBack();
	}

	return (
		<>
			{console.log('state', state)}
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3">
										<div className="back-arrow-container">
											<Link
												onClick={onGoBackHandler}>
												<i class="fa-solid fa-arrow-left"></i>
											</Link>
										</div>
									</div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Type of exam<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the type of exam name" value={typeExma} onChange={(e) => setTypeExma(e.target.value)} />
														{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
	};
};
export default connect(mapStateToProps)(UpdateTypeExam);