import {
    EXAM_CONFIGURATION_LOADING,
    ADD_EXAM_CONFIGURATION,
    GET_EXAM_CONFIGURATION,
    UPDATE_EXAM_CONFIGURATION,
    REMOVE_EXAM_CONFIGURATION,
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function ExamConfigurationReducer(state = initialState, action) {

    if (action.type === ADD_EXAM_CONFIGURATION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_CONFIGURATION) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_EXAM_CONFIGURATION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_EXAM_CONFIGURATION) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }
    if (action.type === EXAM_CONFIGURATION_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


