import {
  LOGIN_CONFIRMED,
  LOADING_TOGGLE,
  LOGIN_FAILED,
  LOGOUT,
  SIGNUP_CONFIRMED,
  SET_OTP,
  SIGNUP_FAILED,
} from "../constants";

const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
  },
  otp: "",
  email: "",
  user_id: "",
  user_name: "",
  token: "",
  errorMessage: "",
  successMessage: "",
  menuList: [],
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED) {
    return {
      ...state,
      menuList: action.payload.menu_list,
      email: action.payload.email,
      user_id: action.payload.id,
      user_name: action.payload.name,
      token: action.payload.token,
      showLoading: false,
    };
  }
  if (action.type === LOGOUT) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      email: "",
      user_id: "",
      user_name: "",
      token: "",
    };
  }

  if (action.type === SIGNUP_FAILED || action.type === LOGIN_FAILED) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === SET_OTP) {
    return {
      ...state,
      otp: action.payload,
    };
  }

  if (action.type === LOADING_TOGGLE) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
