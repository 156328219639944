import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { uploadBulkImage, loadingContentAction } from '../../../store/actions/ExamContentAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const BulkQuestionImage = (props) => {

	const fileRef = useRef();

	let errorsObj = { file: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [file, setFile] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		// dispatch(loadingPatternAction(true));
		// dispatch(getQuestionPattern(props.history));
	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (file === null) {
			errorObj.file = 'File is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(uploadBulkImage(file, props.history));
		fileRef.current.value = null;
	}

	/* set file into state */
	const handleChange = (event) => {
		setFile(event.target.files[0]);
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>


													<div className="mb-3">
														<label htmlFor="formFileDisabled" className="form-label file-form-label">Upload zip File<span className="text-danger">*</span></label>
														<input
															ref={fileRef}
															className="form-control"
															type="file"
															id="formFileDisabled"
															accept=".zip"
															onChange={handleChange} />
														{errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>}
													</div>


													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Upload
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.content.showLoading,
	};
};

export default connect(mapStateToProps)(BulkQuestionImage);