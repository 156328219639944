import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { assignValidatorData, getDetailsDeveloperContentForElibraryData } from '../../../store/actions/AssignContentAction';
import { getContentValidatorData, loadingContentValidatorAction } from '../../../store/actions/ContentValidatorAction';
import { getLanguageValidatorData, loadingLanguageValidatorAction } from '../../../store/actions/ContentLanguageValidatorAction';
import { getContentDesignerData, loadingContentDesignerAction } from '../../../store/actions/ContentDesignerAction';

const AssignElibraryDetails = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const contentbody = useSelector(state => state.assign.eLibraryContentDetails.content);

    let errorsObj = { validator: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [validator, setValidator] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getDetailsDeveloperContentForElibraryData(state.id, props.history));

    }, []);

    /* form submit */
    function onSubmit(e) {
        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (validator === '') {
            errorObj.validator = 'Developer is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(assignValidatorData(state.id, validator, props.history))
        setValidator('');
    }

    /* get validator Handler */
    const onValidatorHandler = () => {
        if (props.contentvalidatorList.length == 0) {
            dispatch(loadingContentValidatorAction(true, props.history));
            dispatch(getContentValidatorData(0, '', '','', props.history));
        }
    }

    /* get Language Validator Handler */
    const onLanguageValidatorHandler = () => {
        if (props.languagevalidatorList.length == 0) {
            dispatch(loadingLanguageValidatorAction(true, props.history));
            dispatch(getLanguageValidatorData(0, '', '','', props.history));
        }
    }

    /* get Designer Handler */
    const onDesignerHandler = () => {
        if (props.contentdesignerList.length == 0) {
            dispatch(loadingContentDesignerAction(true, props.history));
            dispatch(getContentDesignerData(0, '', '','', props.history));
        }
    }

    /* navigation go back */
    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                {console.log('state', state)}
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Content</strong>
                                                        </label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            config={{
                                                                toolbar: {
                                                                    shouldNotGroupWhenFull: true,
                                                                    items: []
                                                                }
                                                            }}
                                                            data={contentbody}
                                                        />
                                                    </div>
                                                    {state.assigned_validator == 0 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Assign Content Validator</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow">
                                                                    {props.validatorLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control"
                                                                    value={validator}
                                                                    onChange={(e) => setValidator(e.target.value)}
                                                                    onClick={onValidatorHandler}
                                                                >
                                                                    <option value="">Select Validator</option>
                                                                    {
                                                                        props.contentvalidatorList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.validator && <div className="text-danger fs-12">{errors.validator}</div>}
                                                            </div>
                                                        </div>
                                                        : null}

                                                    {state.assigned_language_validator == 0 && state.assigned_validator !== 0 ?

                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Assign Content Language Validator</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow">
                                                                    {props.languageValidatorLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control"
                                                                    value={validator}
                                                                    onChange={(e) => setValidator(e.target.value)}
                                                                    onClick={onLanguageValidatorHandler}
                                                                >
                                                                    <option value="">Select Language Validator</option>
                                                                    {
                                                                        props.languagevalidatorList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.validator && <div className="text-danger fs-12">{errors.validator}</div>}
                                                            </div>
                                                        </div>
                                                        : null}

                                                    {state.assigned_desiner == 0 && state.assigned_language_validator !== 0 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Assign Content Designer</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow">
                                                                    {props.designerLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control"
                                                                    value={validator}
                                                                    onChange={(e) => setValidator(e.target.value)}
                                                                    onClick={onDesignerHandler}
                                                                >
                                                                    <option value="">Select Designer</option>
                                                                    {
                                                                        props.contentdesignerList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.validator && <div className="text-danger fs-12">{errors.validator}</div>}
                                                            </div>
                                                        </div>
                                                        : null}

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Submit
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.assign.showLoading,
        validatorLoading: state.contentvalidator.showLoading,
        contentvalidatorList: state.contentvalidator.list,
        languageValidatorLoading: state.languagevalidator.showLoading,
        languagevalidatorList: state.languagevalidator.list,
        designerLoading: state.contentdesigner.showLoading,
        contentdesignerList: state.contentdesigner.list,
        eLibraryContentDetails: state.assign.eLibraryContentDetails,
    };
};
export default connect(mapStateToProps)(AssignElibraryDetails);