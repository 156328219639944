import {
    PERMISSION_LOADING,
    GET_PERMISSION,
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function PermissionReducer(state = initialState, action) {

    if (action.type === GET_PERMISSION) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === PERMISSION_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


