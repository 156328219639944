import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addTimeConfiguration(exam_category_id, exam_type_id, exam_subtype_id, board_id, class_id, subject_id, competive_exam_duration, set_exam_duration, module_exam_duration, mock_exam_duration) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        board_id,
        class_id,
        subject_id,
        competive_exam_duration,
        set_exam_duration,
        module_exam_duration,
        mock_exam_duration
    };
    console.log('postData',postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TIME_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTimeConfiguration(id, exam_category_id, exam_type_id, exam_subtype_id, board_id, class_id, subject_id, competive_exam_duration, set_exam_duration, module_exam_duration, mock_exam_duration) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        board_id,
        class_id,
        subject_id,
        competive_exam_duration,
        set_exam_duration,
        module_exam_duration,
        mock_exam_duration
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TIME_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTimeConfiguration(page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TIME_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeTimeConfiguration(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TIME_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

