import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function uploadEventImg(file) {

    const formData = new FormData();
    formData.append("tag_image", file);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
        method: "POST",
        headers: {
            ContentType: "multipart/form-data",
        },
        data: formData,
    });

}

export function addEventHistory(title, sub_title, description, event_date, event_image) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        title,
        sub_title,
        description,
        event_date,
        event_image
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_EVENT_HISTORY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateEventHistory(record_id, title, sub_title, description, event_date, event_image) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        record_id,
        title,
        sub_title,
        description,
        event_date,
        event_image
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.Edit_EVENT_HISTORY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getEventHistory(page, filter_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        filter_date
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_EVENT_HISTORY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateEventHistoryStatus(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EVENT_HISTORY_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeEventHistory(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_EVENT_HISTORY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

