import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";

import {
  getBoardData,
  loadingBoardAction,
  removeBoardData,
  updateStatusBoardData,
} from "../../../store/actions/BoardAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const BoardList = (props) => {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState("");

  useEffect(() => {
    permissionCheck();
    dispatch(globalLoadingAction(true));
    dispatch(getBoardData(props.history));
  }, []);

  /* delete confirmation modal */
  const onDeleteModal = (i) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(removeBoardData(i, props.history));
      }
    });
  };

  /* update board status */
  const onUpdateStatus = (item) => {
    dispatch(globalLoadingAction(true));
    dispatch(updateStatusBoardData(item, props.history));
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 22) {
        setPermission(item);
      }
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Board</strong>
                    </th>
                    <th>
                      <strong>Board Full Name</strong>
                    </th>
                    <th>
                      <strong>Short Code</strong>
                    </th>
                    <th>
                      <strong>Conducted By</strong>
                    </th>
                    <th>
                      <strong>Create Date</strong>
                    </th>

                    {permission.status == "true" && (
                      <th>
                        <strong>Status</strong>
                      </th>
                    )}
                    {(permission.edit == "true" ||
                      permission.delete == "true") && (
                      <th>
                        <strong>Action</strong>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {props.list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.full_name}</td>
                        <td>{item.short_code}</td>
                        <td>{item.conducted_by}</td>
                        <td>
                          <Moment format="D MMM YYYY" withTitle>
                            {item.created_at}
                          </Moment>
                        </td>
                        {permission.status == "true" && (
                          <td>
                            <Button
                              //disabled={props.showLoading}
                              className="btn-xs"
                              variant={item.status == 1 ? "success" : "danger"}
                              onClick={() => onUpdateStatus(item)}
                            >
                              {item.status == 1 ? "Active" : "Inactive"}
                            </Button>
                          </td>
                        )}
                        {(permission.edit == "true" ||
                          permission.delete == "true") && (
                          <td>
                            <div className="d-flex">
                              {permission.edit == "true" && (
                                <Link
                                  to={{
                                    pathname: "/update-board",
                                    state: item,
                                  }}
                                  className="btn btn-warning shadow btn-xs sharp me-1"
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Link>
                              )}

                              {permission.delete == "true" && (
                                <Button
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => onDeleteModal(item.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.board.showLoading,
    list: state.board.list,
  };
};

export default connect(mapStateToProps)(BoardList);
