import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';


import { updateTimeData, loadingTimeConfiguration } from '../../../store/actions/TimeConfigurationAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, subjectLoading } from '../../../store/actions/SujectActions';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamSubType } from '../../../store/actions/ExamSubTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateTimeConfiguration = (props) => {

    let history = useHistory();
    const { state } = useLocation();

    let errorsObj = {
        category: '',
        type: '',
        subtype: '',
        board: '',
        class: '',
        subject: '',
        comduration: '',
        setduration: '',
        moduleduration: '',
        mockduration: '',
    };

    const [errors, setErrors] = useState(errorsObj);
    const [category, setCategory] = useState(state.exam_category_id);
    const [type, setType] = useState(state.exam_type_id);
    const [subtype, setSubtype] = useState(state.exam_subtype_id);
    const [className, setClassName] = useState(state.class_id);
    const [board, setBoard] = useState(state.board_id);
    const [subject, setSubject] = useState(state.subject_id);
    const [comDuration, setComDuration] = useState(state.competive_exam_duration);
    const [duration, setDuration] = useState(state.set_exam_duration);
    const [moduleDuration, setModuleDuration] = useState(state.module_exam_duration);
    const [mockDuration, setMockDuration] = useState(state.mock_exam_duration);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(loadingToggleAction(true));
        dispatch(getExamCategory(props.history));
        dispatch(loadingAction(true));
        dispatch(getExamType(props.history));
        dispatch(getExamSubType(props.history));
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(loadingClassAction(true));
        dispatch(getClassData(props.history));
        dispatch(subjectLoading(true));
        dispatch(getSubjects(props.history));

    }, []);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();

        let error = false;

        const errorObj = { ...errorsObj };

        if (category === '') {
            errorObj.category = 'Exam category is required';
            error = true;
        }
        if (type === '' && parseInt(category) === 2) {
            errorObj.type = 'Exam type is required';
            error = true;
        }
        if (subtype === '' && parseInt(category) === 2) {
            errorObj.subtype = 'Exam subtype is required';
            error = true;
        }
        if (className === '' && parseInt(type) == 2) {
            errorObj.class = 'Class Name is Required';
            error = true;
        } else if (parseInt(className) === 0) {
            errorObj.class = 'Class Name is Required';
            error = true;
        }
        if (board === '' && parseInt(category) === 1 || category === '') {
            errorObj.board = 'Board is Required';
            error = true;
        }
        if (subject === '') {
            errorObj.subject = 'Subject is Required';
            error = true;
        }
        if (comDuration === '' && parseInt(category) === 2) {
            errorObj.comduration = 'Competive Duration is Required';
            error = true;
        }
        if (duration === '' && parseInt(category) === 1 || category === '') {
            errorObj.setduration = 'Set Duration is Required';
            error = true;
        }
        if (moduleDuration === '' && parseInt(category) === 1 || category === '') {
            errorObj.moduleduration = 'Module Duration is Required';
            error = true;
        }
        if (mockDuration === '' && parseInt(category) === 1 || category === '') {
            errorObj.mockduration = 'Mock Duration is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        dispatch(globalLoadingAction(true));
        dispatch(updateTimeData(
            state.id,
            category,
            parseInt(category) === 2 ? type : '',
            parseInt(category) === 2 ? subtype : '',
            parseInt(category) === 1 ? board : '',
            parseInt(type) === 2 ? className : '',
            subject,
            parseInt(category) === 2 ? comDuration : '',
            parseInt(category) === 1 ? duration : '',
            parseInt(category) === 1 ? moduleDuration : '',
            parseInt(category) === 1 ? mockDuration : '',
            props.history
        ));
        setCategory('');
        setType('');
        setSubtype('');
        setClassName('');
        setBoard('');
        setSubject('');
        setComDuration('');
        setDuration('');
        setModuleDuration('')
        setMockDuration('');
    }

    /* navigtion go back */
    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Exam category name {className} {state.class_id}</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={category}
                                                                onChange={(e) => setCategory(e.target.value)}
                                                            >
                                                                <option value="">Select category</option>
                                                                {
                                                                    props.categoryList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.category}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.category && <div className="text-danger fs-12">{errors.category}</div>}
                                                    </div>

                                                    {category == 2 ?
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam type</strong>
                                                                </label>
                                                                <div className="basic-dropdown">
                                                                    <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                    <select
                                                                        defaultValue={"option"}
                                                                        className="form-control form-control"
                                                                        value={type}
                                                                        onChange={(e) => setType(e.target.value)}
                                                                    >
                                                                        <option value="">Select type</option>
                                                                        {
                                                                            props.typeList.map((item, index) => {
                                                                                return <option key={index} value={item.id}>{item.type_name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam subtype</strong>
                                                                </label>
                                                                <div className="basic-dropdown">
                                                                    <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                    <select
                                                                        defaultValue={"option"}
                                                                        className="form-control form-control"
                                                                        value={subtype}
                                                                        onChange={(e) => setSubtype(e.target.value)}
                                                                    >
                                                                        <option value="">Select subtype</option>
                                                                        {
                                                                            props.subtypeList.map((item, index) => {
                                                                                return <option key={index} value={item.id}>{item.subtype_name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
                                                            </div>
                                                        </>
                                                        :

                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Board</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={board}
                                                                    onChange={(e) => setBoard(e.target.value)}
                                                                >
                                                                    <option value="">Select board</option>
                                                                    {
                                                                        props.boardList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                        </div>
                                                    }

                                                    {parseInt(type) == 2 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={className}
                                                                    onChange={(e) => setClassName(e.target.value)}
                                                                >
                                                                    <option value="">Select class</option>
                                                                    {
                                                                        props.classList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.short_code}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.class && <div className="text-danger fs-12">{errors.class}</div>}
                                                        </div>
                                                        : null
                                                    }

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Subject</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={subject}
                                                                onChange={(e) => setSubject(e.target.value)}
                                                            >
                                                                <option value="">Select subject</option>
                                                                {
                                                                    props.subjectList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                                    </div>

                                                    {category == 2 ?

                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Competive Exam duration</strong>
                                                            </label>
                                                            <input className="form-control" type="number" placeholder="Competive exam duration" value={comDuration} onChange={(e) => setComDuration(e.target.value)} />
                                                            {errors.comduration && <div className="text-danger fs-12">{errors.comduration}</div>}
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam duration for set</strong>
                                                                </label>
                                                                <input className="form-control" type="number" placeholder="Exam duration for set" value={duration} onChange={(e) => setDuration(e.target.value)} />
                                                                {errors.setduration && <div className="text-danger fs-12">{errors.setduration}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam duration for module</strong>
                                                                </label>
                                                                <input className="form-control" type="number" placeholder="Exam duration for module" value={moduleDuration} onChange={(e) => setModuleDuration(e.target.value)} />
                                                                {errors.moduleduration && <div className="text-danger fs-12">{errors.moduleduration}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam duration for mock</strong>
                                                                </label>
                                                                <input className="form-control" type="number" placeholder="Exam duration for mock" value={mockDuration} onChange={(e) => setMockDuration(e.target.value)} />
                                                                {errors.mockduration && <div className="text-danger fs-12">{errors.mockduration}</div>}
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Upload
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.time.showLoading,
        categoryList: state.category.list,
        typeList: state.type.list,
        subtypeList: state.subtype.list,
        boardList: state.board.list,
        classList: state.class.list,
        subjectList: state.subject.subjectList,
    };
};

export default connect(mapStateToProps)(UpdateTimeConfiguration);