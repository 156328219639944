import {
    SUBJECT_LIST,
    ADD_SUBJECT,
    UPDATE_SUBJECT,
    SUBJECT_LOADING,
    REMOVE_SUBJECT,
    UPDATE_STATUS_SUBJECT,
    GET_NON_GROUP_SUBJECT_LIST,
    CLEAR_SUBJECT_LIST,
    SUBJECT_FILTTER_LIST,
    E_SUB_FILTER_SUBJECTS,
    BOARD_CLASS_BY_SUBJECT_LIST,
    GET_SUBJECTS_BY_BOARDS,
    GET_SINGLE_SUBJECT_LIST,
    SUBJECT_CATEGORY_LIST,
    SUBJECT_BOARD_LIST,
    SUBJECT_GROUP_INDIVIDUAL_LIST,
    SUBJECT_TYPE_LIST,
    SUBJEC_TLIST_PAGE_NUMBER
} from '../constants'

const initialState = {
    subjectList: [],
    nonGroupSubjectList: [],
    boardClassBySubjectList: [],
    subjectFiltterList: [],
    subjectFilterSubLibrary: [],
    totalPage: 0,
    totalRecord: 0,
    showLoading: false,
    getSubjectsByBoards: [],
    getSingleSubjectList: [],
    subject_category_list: 0,
    subject_board_list: 0,
    subject_type_list: 0,
    subject_group_individual_list: 0,
    subjectListPageNumber: 1,
};

export function SubjectReducer(state = initialState, action) {

    if (action.type === GET_NON_GROUP_SUBJECT_LIST) {
        return {
            ...state,
            nonGroupSubjectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_FILTTER_LIST) {
        return {
            ...state,
            subjectFiltterList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === E_SUB_FILTER_SUBJECTS) {
        return {
            ...state,
            subjectFilterSubLibrary: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_LIST) {
        return {
            ...state,
            subjectList: action.payload.data,
            totalPage: action.payload.total_subject_length,
            totalRecord: action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === CLEAR_SUBJECT_LIST) {
        return {
            ...state,
            subjectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === BOARD_CLASS_BY_SUBJECT_LIST) {
        return {
            ...state,
            boardClassBySubjectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === ADD_SUBJECT) {

        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_SUBJECT) {

        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_SUBJECT) {

        const list = [...state.subjectList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            subjectList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_SUBJECT) {

        const list = [...state.subjectList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            subjectList: list,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === GET_SUBJECTS_BY_BOARDS) {
        return {
            ...state,
            getSubjectsByBoards: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_SINGLE_SUBJECT_LIST) {
        return {
            ...state,
            getSingleSubjectList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_CATEGORY_LIST) {
        return {
            ...state,
            subject_category_list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_BOARD_LIST) {
        return {
            ...state,
            subject_board_list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_GROUP_INDIVIDUAL_LIST) {

        return {
            ...state,
            subject_group_individual_list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJECT_TYPE_LIST) {

        return {
            ...state,
            subject_type_list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === SUBJEC_TLIST_PAGE_NUMBER) {

        return {
            ...state,
            subjectListPageNumber: action.payload,
            showLoading: false,
        };
    }
    return state;
}


