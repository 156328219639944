import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addExamConfiguration(exam_category_id, board_id, type_id, subtype_id, branch_id, question_no, configuration_id, configuration_details) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        exam_category_id,
        board_id,
        type_id,
        subtype_id,
        branch_id,
        question_no,
        configuration_id,
        configuration_details
    };
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_EXAM_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getExamConfiguration(exam_category_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        exam_category_id
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_EXAM_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateExamConfiguration(id, exam_category_id, board_id, type_id, subtype_id, branch_id, question_no, configuration_id, configuration_details) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        exam_category_id,
        board_id,
        type_id,
        subtype_id,
        branch_id,
        question_no,
        configuration_id,
        configuration_details
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EXAM_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeExamConfiguratio(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_EXAM_CONFIGURATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

