import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { addNewSchoolData } from "../../../store/actions/SchoolAction";
import { connect } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

const AddNewSchool = (props) => {
  const errorsObj = {
    region: "",
    pin: "",
    board: "",
    schoolName: "",
    address: "",
    website: "",
    contactNumber: "",
    HOI: "",
    mail: "",
    enrollmentDate: "",
    affilitationStart: "",
    affiliationEnd: "",
    schoolStatus: "",
    foundationYear: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [region, setRegion] = useState("");
  const [pin, setPin] = useState("");
  const [board, setBoard] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [address, setaddress] = useState("");
  const [website, setWebsite] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, SetAlternateNumber] = useState("");
  const [HOI, setHOI] = useState("");
  const [mail, setMail] = useState("");
  const [enrollmentDate, setEnrollmentDate] = useState("");
  const [affiliationNumber, setAffilitationNumber] = useState("");
  const [affilitationStart, setAffiliationStart] = useState("");
  const [affiliationEnd, setAffiliationEnd] = useState("");
  const [schoolStatus, setSchoolStatus] = useState("");
  const [foundationYear, setFoundationYear] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const enrollmentRef = useRef();
  const affiliationStartRef = useRef();
  const affiliationEndRef = useRef();
  const foundationYearRef = useRef();

  const options = props.boardList.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  //custome style for multi select start
  const style = {
    control: (base) => ({
      ...base,
      background: "#fff",
      color: "#6e6e6e",
      lineHeight: "2.3",
      fontSize: "1rem",
      borderRadius: "0.625rem",
      border: "1px solid rgba(0,0,0,0.2)",
      boxShadow: "none",
      transition: "border-color 0.3s",
      "&:focus, &:focus-within": {
        border: "1px solid #bf1400",
      },
    }),
  };
  //custome style for multi select end

  /* form submit */
  const onSubmit = (e) => {
    e.preventDefault();

    console.log("onSubmit----")
    let error = false;
    const errorObj = { ...errorsObj };

    //convert array to string start//

    const commaSeparatedString = convertArrayToString(board);

    //convert array to string end//

    // let pinPttern = /^[\d]{6}$/.test(pin);
    let pinPttern = /^\d{6}$/.test(pin);
    let mailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      mail
    );

    // if (region == "") {
    //   errorObj.region = "Region is required";
    //   error = true;
    // }

    if (pin == "") {
      errorObj.pin = "Pin is required";
      error = true;
    } else if (!pinPttern) {
      errorObj.pin = "Invalid pin";
      error = true;
    }

    if (board == "") {
      errorObj.board = "Board is required";
      error = true;
    }
    if (schoolName == "") {
      errorObj.schoolName = "School name is required";
      error = true;
    } else if (!/^[a-zA-Z ]+$/.test(schoolName)) {
      errorObj.schoolName = "Only alphabetic characters is allow";
      error = true;
    }


    if (address == "") {
      errorObj.address = "Address is required";
      error = true;
    }

    if (contactNumber == "") {
      errorObj.contactNumber = "Contact number is required";
      error = true;
    }

    if (HOI == "") {
      errorObj.HOI = "Head of the institute name is required";
      error = true;
    } else if (!/^[a-zA-Z ]+$/.test(HOI)) {
      errorObj.HOI = "Only alphabetic characters is allow";
      error = true;
    }

    if (mail == "") {
      errorObj.mail = "Email is required";
      error = true;
    } else if (!mailPattern) {
      errorObj.mail = "Invalid email";
      error = true;
    }

    if (enrollmentDate == "" || enrollmentDate == null) {
      errorObj.enrollmentDate = "Enrollment date is required";
      error = true;
    }

    if (affiliationNumber == "") {
      errorObj.affiliationNumber = "Affiliation number is required";
      error = true;
    }

    if (affilitationStart == "" || affilitationStart == null) {
      errorObj.affilitationStart = "Affiliation start date is required";
      error = true;
    }

    if (affiliationEnd == "" || affiliationEnd == null) {
      errorObj.affiliationEnd = "Affiliation end date is required";
      error = true;
    }
    if (schoolStatus == "") {
      errorObj.schoolStatus = "School status is required";
      error = true;
    }
    if (foundationYear == "" || foundationYear == null) {
      errorObj.foundationYear = "School status is required";
      error = true;
    }

    if (error) {
      setErrors(errorObj);
      return;
    }

    dispatch(globalLoadingAction(true));

    dispatch(
      addNewSchoolData(
        region,
        pin,
        commaSeparatedString,
        schoolName.trim(),
        address,
        website,
        contactNumber,
        alternateNumber,
        HOI.trim(),
        mail,
        moment(enrollmentDate).format("MM/DD/YYYY"),
        affiliationNumber,
        moment(affilitationStart).format("MM/DD/YYYY"),
        moment(affiliationEnd).format("MM/DD/YYYY"),
        schoolStatus,
        "",
        moment(foundationYear).format("MM/DD/YYYY"),
        history
      )
    );

    // console.log(region,pin,board,schoolName,address,website,contactNumber,alternateNumber,HOI,mail,moment(enrollmentDate).format('DD/MM/YYYY'),affiliationNumber,moment(affilitationStart).format('DD/MM/YYYY'),moment(affiliationEnd).format('DD/MM/YYYY'),schoolStatus,"",moment(foundationYear).format('DD/MM/YYYY'))
    //  history.push('/school-list')

    setRegion("");
    setPin("");
  };

  useEffect(() => {
    dispatch(getBoardData());
  }, []);

  const convertArrayToString = (board) => {
    if (board !== "") {
      let boardAsString = board;
      const array = boardAsString.map((item) => item.value);
      const commaSeparatedString = array.join(",");
      return commaSeparatedString;
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            {/* <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Region
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the region"
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                              />
                              {errors.region && (
                                <div className="text-danger fs-12">
                                  {errors.region}
                                </div>
                              )}
                            </div> */}
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Pin code
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pin code "
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                                onKeyPress={inputNumberOnly}
                                maxlength="6"
                              />
                              {errors.pin && (
                                <div className="text-danger fs-12">
                                  {errors.pin}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Board<span className="text-danger">*</span>
                                </strong>
                              </label>

                              <Select
                                isMulti
                                options={options}
                                value={board}
                                onChange={(data) => setBoard(data)}
                                styles={style}
                              >
                                <option value="">Select Board</option>
                              </Select>

                              {errors.board && (
                                <div className="text-danger fs-12">
                                  {errors.board}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  School Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the school name"
                                value={schoolName}
                                onChange={(e) => setSchoolName(e.target.value)}
                              />
                              {errors.schoolName && (
                                <div className="text-danger fs-12">
                                  {errors.schoolName}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  School Address
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the school address"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                              />
                              {errors.address && (
                                <div className="text-danger fs-12">
                                  {errors.address}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Website</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the website link"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                              />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Contact Number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control no-spinners"
                                placeholder="Enter the contact number"
                                value={contactNumber}
                                type="text"
                                onKeyDown={(e) => {
                                  // Prevent default if the key pressed is not a number, backspace, tab, enter, arrow keys, etc.
                                  if (!/[\d]+|Backspace|ArrowLeft|ArrowRight|Tab|Enter/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) =>
                                  setContactNumber(e.target.value)
                                }
                              />
                              {errors.contactNumber && (
                                <div className="text-danger fs-12">
                                  {errors.contactNumber}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Alternate Contact Number</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact number"
                                value={alternateNumber}
                                type="text"
                                onKeyDown={(e) => {
                                  // Prevent default if the key pressed is not a number, backspace, tab, enter, arrow keys, etc.
                                  if (!/[\d]+|Backspace|ArrowLeft|ArrowRight|Tab|Enter/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) =>
                                  SetAlternateNumber(e.target.value)
                                }
                              />
                              {/* {errors.fName && (
                                <div className="text-danger fs-12">
                                  {errors.fName}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Head of the institute
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the name of head"
                                value={HOI}
                                onChange={(e) => setHOI(e.target.value)}
                              />
                              {errors.HOI && (
                                <div className="text-danger fs-12">
                                  {errors.HOI}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  E-mail
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the e-mail"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                              />
                              {errors.mail && (
                                <div className="text-danger fs-12">
                                  {errors.mail}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Date of Enrollment
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="date-control-view">
                                <DatePicker
                                  ref={enrollmentRef}
                                  selected={enrollmentDate}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setEnrollmentDate(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    enrollmentRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.enrollmentDate && (
                                <div className="text-danger fs-12">
                                  {errors.enrollmentDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affiliation number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter affiliation number"
                                value={affiliationNumber}
                                // type="number"
                                onChange={(e) =>
                                  setAffilitationNumber(e.target.value)
                                }
                                onKeyPress={inputNumberOnly}
                                
                              />

                              {errors.affiliationNumber && (
                                <div className="text-danger fs-12">
                                  {errors.affiliationNumber}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affliation period start
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={affiliationStartRef}
                                  selected={affilitationStart}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setAffiliationStart(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    affiliationStartRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.affilitationStart && (
                                <div className="text-danger fs-12">
                                  {errors.affilitationStart}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affliation period end
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={affiliationEndRef}
                                  selected={affiliationEnd}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setAffiliationEnd(date)}
                                  yearDropdownItemNumber={100}
                                  showYearDropdown
                                  showMonthDropdown
                                // maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    affiliationEndRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>

                              {errors.affiliationEnd && (
                                <div className="text-danger fs-12">
                                  {errors.affiliationEnd}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Status<span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={schoolStatus}
                                  onChange={(e) =>
                                    setSchoolStatus(e.target.value)
                                  }
                                >
                                  <option value="">Select school status</option>
                                  <option value="Primary">Primary</option>
                                  <option value="Secondary">Secondary</option>
                                  <option value="Higher Secondary">
                                    Higher Secondary
                                  </option>
                                </select>
                              </div>
                              {errors.schoolStatus && (
                                <div className="text-danger fs-12">
                                  {errors.schoolStatus}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Foundation year{" "}
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={foundationYearRef}
                                  selected={foundationYear}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setFoundationYear(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    foundationYearRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.foundationYear && (
                                <div className="text-danger fs-12">
                                  {errors.foundationYear}
                                </div>
                              )}
                            </div>
                            {/* <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Academic year{" "}
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                   ref={academicYearRef}
                                   selected={academicYear}
                                  placeholderText="Enter the date"
                                   onChange={(date) => setAcademicYear(date)}
                                   showYearDropdown
                                   showMonthDropdown
                                  // maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    academicYearRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              
                              {errors.academicYear && (
                                <div className="text-danger fs-12">
                                  {errors.academicYear}
                                </div>
                              )}
                            </div> */}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                            // disabled={props.showLoading}
                            >
                              Submit
                              {/* {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null} */}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    boardList: state.board.list,
  };
};

export default connect(mapStateToProps)(AddNewSchool);
