import React, {
  useContext,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import QuestionArea from "../ExamContent/component/QuestionArea";
import axios from "axios";
import * as Apis from "../../../apis/Apis";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import MathJax from "react-mathjax-preview";
import PreviewCard from "./component/PreviewCard";

import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getExamSubType,
  loadingSubTypeAction,
} from "../../../store/actions/ExamSubTypeAction";
import {
  getNonGroupSubjectsList,
  subjectLoading,
  getNonGroupSubjectsAction,
} from "../../../store/actions/SujectActions";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../store/actions/QuestionPatternAction";
import {
  updateQuestionData,
  updateContinueQuestionData,
  getSpecialCharactersData,
  getUpdateContentData,
  loadingContentAction,
  downloadQuetionFile,
  reloadContentAction,
  updateErrorLogData,
  updatePreviewQuestionData,
} from "../../../store/actions/ExamContentAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateQuestion = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  let errorsObj = {
    category: "",
    board: "",
    type: "",
    subtype: "",
    className: "",
    subject: "",
    chapter: "",
    quesType: "",
    quesNo: "",
    quesBody: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    anws: "",
    resone: "",
    file: "",
    groupId: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [category, setCategory] = useState("");

  const [board, setBoard] = useState("");
  const [examtype, setExamtype] = useState("");
  const [subtype, setSubtype] = useState("");
  const [subject, setSubject] = useState("");
  const [fetchedSubject, setFetchedSubjct] = useState("");
  const [fetchedType, setfetchedType] = useState("");
  const [classroom, setClassroom] = useState("");
  const [chapter, setChapter] = useState("");
  const [fetchedChapter, setFetchedChapter] = useState("");
  const [questiontype, setQuestiontype] = useState("");
  const [questiono, setQuestiono] = useState("");
  const [questiobody, setQuestiobody] = useState("");
  const [option, setOption] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [answers, setAnswers] = useState("");
  const [answersbody, setAnswersbody] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [reasonArray, setReasonArray] = useState([]);
  const [firstOptionArray, setFirstOptionArray] = useState([]);
  const [secondOptionArray, setSecondOptionArray] = useState([]);
  const [thirdOptionArray, setThirdOptionArray] = useState([]);
  const [fourthOptionArray, setFourthOptionArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [htmlResult, setHtmlResult] = useState("");
  const [demo, setDemo] = useState("");
  const [questionEditor, setQuestionEditor] = useState(false);
  const [optionAEditor, setOptionAEditor] = useState(false);
  const [optionBEditor, setOptionBEditor] = useState(false);
  const [optionCEditor, setOptionCEditor] = useState(false);
  const [optionDEditor, setOptionDEditor] = useState(false);
  const [reasonEditor, setReasonEditor] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [activeNumber, setActiveNumber] = useState(0);
  const [continueStatus, setContinueStatus] = useState(false);
  const [questioStatus, setQuestioStatus] = useState(false);
  const [optionStatus, setOptionStatus] = useState(false);
  const [optionStatus2, setOptionStatus2] = useState(false);
  const [optionStatus3, setOptionStatus3] = useState(false);
  const [optionStatus4, setOptionStatus4] = useState(false);
  const [answersbodyStatus, setAnswersbodyStatus] = useState(false);
  const [isFinalPreview, setIsFinalPreview] = useState(false);
  const [cssGroupId, setCssGroupId] = useState("");


  useLayoutEffect(() => {
    dispatch(globalLoadingAction(true));
    if (state.currentPage == 1) {
      dispatch(getUpdateContentData(state.id, props.history));
    } else {
      dispatch(updateErrorLogData(state.id, props.history));
    }
  }, []);

  useEffect(() => {
    getSubjectHandler();
    //console.log("question details from redux----", props.questionDetails);
    onSetStateData();
    onSetQuestionImage();
    onSetOptionAImage();
    onSetOptionBImage();
    onSetOptionCImage();
    onSetOptionDImage();
    onSetReasonImage();

    getCategoryHandler();
    getBoardHandler();
    getExamtypeHandler();
    getSubtypeHandler();
    getClassHandler();
    setSubjectAndChapter();
    getChapterHandler();

    dispatch(getQuestionPattern(props.history));
    dispatch(getSpecialCharactersData());
    dispatch(loadingContentAction(true));
    dispatch(reloadContentAction(false));
    //console.log('useEffect.......')
  }, [props.isStatus]);

  const onSetStateData = () => {
    setCategory(props.questionDetails.exam_category);
    setDemo(props.questionDetails.demo_exam);
    setBoard(props.questionDetails.board_id);
    //setSubject(props.questionDetails.branch_id)
    setExamtype(
      props.questionDetails.exam_type_id + "^" + props.questionDetails.is_class
    );

    //console.log("----debasis----", props.questionDetails.branch_id);
    setSubtype(props.questionDetails.exam_subtype_id);
    setSubject(props.questionDetails.branch_id);
    setClassroom(props.questionDetails.class_id);
    // setChapter(props.questionDetails.chapter_id);
    setQuestiontype(props.questionDetails.question_type);
    setQuestiono(props.questionDetails.question_no);
    setCssGroupId(props.questionDetails.css_group_id);
    setAnswers(props.questionDetails.answer);

    // if (continueStatus) {
    setQuestiobody(props.questionDetails.question);
    setOption(props.questionDetails.option_a);
    setOption2(props.questionDetails.option_b);
    setOption3(props.questionDetails.option_c);
    setOption4(props.questionDetails.option_d);
    setAnswersbody(props.questionDetails.reason);
    //console.log('continueStatus.....', continueStatus)
    // }
  };

  /* set subject and chapter list */
  const setSubjectAndChapter = () => {
    let data = props.questionDetails;

    if (data.exam_category == 1 && data.board_id) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(data.exam_category),
          0,
          0,
          parseInt(data.board_id),
          props.history
        )
      );
    }
    if (
      data.exam_category == 2 &&
      data.exam_type_id == 1 &&
      data.exam_subtype_id
    ) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(data.exam_category),
          parseInt(data.exam_type_id),
          parseInt(data.exam_subtype_id),
          0,
          props.history
        )
      );
    }
    if (data.exam_category == 2 && data.exam_type_id == 2) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(data.exam_category),
          parseInt(data.exam_type_id),
          0,
          0,
          props.history
        )
      );
    }

    //setSubject(data.branch_id);
    setFetchedSubjct(data.branch);

    if (data.exam_category == 1 && data.board_id) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(data.exam_category),
          data.board_id,
          0,
          0,
          data.branch_id,
          data.class_id,
          props.history
        )
      );
    }
    if (
      data.exam_category == 2 &&
      data.exam_type_id == 1 &&
      data.exam_subtype_id
    ) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(data.exam_category),
          0,
          data.exam_type_id,
          data.exam_subtype_id,
          data.branch_id,
          0,
          props.history
        )
      );
    }
    if (data.exam_category == 2 && data.exam_type_id == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(data.exam_category),
          0,
          data.exam_type_id,
          0,
          data.branch_id,
          data.class_id,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }

    setChapter(data.chapter_id);
    setFetchedChapter(data.chapter);
  };

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Exam category is required";
      error = true;
    }
    if (parseInt(demo) == 0 && parseInt(category) == 1 && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (parseInt(demo) === 0 && parseInt(category) === 2 && examtype === "") {
      errorObj.type = "Exam type is Required";
      error = true;
    }
    if (parseInt(demo) == 0 && parseInt(examtype) == 1 && subtype === "") {
      errorObj.subtype = "Exam sub type is Required";
      error = true;
    }
    if (
      classroom === "" &&
      parseInt(demo) === 0 &&
      (parseInt(examtype) == 2 || parseInt(category) == 1)
    ) {
      errorObj.className = "Class is required";
      error = true;
    }
    if (subject === "" && parseInt(demo) === 0) {
      errorObj.branch = "Branch is Required";
      error = true;
    }
    if (chapter === "" && parseInt(demo) === 0) {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (questiontype === "") {
      errorObj.quesType = "Question type is Required";
      error = true;
    }
    if (questiono === "") {
      errorObj.quesNo = "Question no is Required";
      error = true;
    }
    if (cssGroupId === "" && questiontype == "CSS") {
      errorObj.groupId = "Group id is Required";
      error = true;
    }
    // if (option === '') {
    //     errorObj.optionA = 'first option is Required';
    //     error = true;
    // }
    // if (option2 === '') {
    //     errorObj.optionB = 'second option is Required';
    //     error = true;
    // }
    // if (option3 === '') {
    //     errorObj.optionC = 'third option is Required';
    //     error = true;
    // }
    // if (option4 === '') {
    //     errorObj.optionD = 'fourth is Required';
    //     error = true;
    // }
    if (answers === "") {
      errorObj.anws = "Answers is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    let que_array = [...imagesArray];
    let resone_array = [...reasonArray];
    let firstOption_array = [...firstOptionArray];
    let secondOption_array = [...secondOptionArray];
    let thirdOption_array = [...thirdOptionArray];
    let fourthOption_array = [...fourthOptionArray];

    let que_img = [];
    let resone_img = [];
    let firstOption_img = [];
    let secondOption_img = [];
    let thirdOption_img = [];
    let fourthOption_img = [];

    que_array.forEach((item, index) => {
      if (item.file) {
        que_img.push(item.file);
      }
    });

    resone_array.forEach((item, index) => {
      resone_img.push(item.file);
    });

    firstOption_array.forEach((item, index) => {
      firstOption_img.push(item.file);
    });

    secondOption_array.forEach((item, index) => {
      secondOption_img.push(item.file);
    });

    thirdOption_array.forEach((item, index) => {
      thirdOption_img.push(item.file);
    });

    fourthOption_array.forEach((item, index) => {
      fourthOption_img.push(item.file);
    });

    dispatch(globalLoadingAction(true));
    dispatch(
      updateQuestionData(
        props.questionDetails.id,
        parseInt(category),
        parseInt(demo) == 0 && parseInt(category) == 1 ? board : 0,
        parseInt(demo) == 0 && parseInt(category) == 2
          ? parseInt(examtype.split("^")[0])
          : 0,
        parseInt(demo) == 0 && parseInt(examtype) == 1 ? subtype : 0,
        parseInt(demo) == 0 &&
          (parseInt(category) == 1 || parseInt(examtype.split("^")[1]) == 2)
          ? classroom
          : 0,
        parseInt(demo) == 0 ? subject : 0,
        parseInt(demo) == 0 ? chapter : 0,
        questiontype,
        questiono,
        questiobody,
        que_img,
        option,
        firstOption_img,
        option2,
        secondOption_img,
        option3,
        thirdOption_img,
        option4,
        fourthOption_img,
        answers,
        answersbody,
        resone_img,
        demo,
        questiontype == "CSS" ? cssGroupId : "",
        state,
        props.history
      )
    );
  }

  const onContinueHandler = () => {
    setContinueStatus(true);

    let que_array = [...imagesArray];
    let resone_array = [...reasonArray];
    let firstOption_array = [...firstOptionArray];
    let secondOption_array = [...secondOptionArray];
    let thirdOption_array = [...thirdOptionArray];
    let fourthOption_array = [...fourthOptionArray];

    let que_img = [];
    let resone_img = [];
    let firstOption_img = [];
    let secondOption_img = [];
    let thirdOption_img = [];
    let fourthOption_img = [];

    que_array.forEach((item, index) => {
      if (item.file) {
        que_img.push(item.file);
      }
    });

    resone_array.forEach((item, index) => {
      resone_img.push(item.file);
    });

    firstOption_array.forEach((item, index) => {
      firstOption_img.push(item.file);
    });

    secondOption_array.forEach((item, index) => {
      secondOption_img.push(item.file);
    });

    thirdOption_array.forEach((item, index) => {
      thirdOption_img.push(item.file);
    });

    fourthOption_array.forEach((item, index) => {
      fourthOption_img.push(item.file);
    });
    dispatch(globalLoadingAction(true));

    dispatch(globalLoadingAction(true));

    dispatch(
      updateContinueQuestionData(
        props.questionDetails.id,
        parseInt(category),
        parseInt(demo) == 0 && parseInt(category) == 1 ? board : 0,
        parseInt(demo) == 0 && parseInt(category) == 2
          ? examtype.split("^")[0]
          : 0,
        parseInt(demo) == 0 && parseInt(examtype) == 1 ? subtype : 0,
        parseInt(demo) == 0 &&
          (parseInt(category) == 1 || parseInt(examtype) == 2)
          ? classroom
          : 0,
        parseInt(demo) == 0 ? subject : 0,
        parseInt(demo) == 0 ? chapter : 0,
        questiontype,
        questiono,
        questiobody,
        que_img,
        option,
        firstOption_img,
        option2,
        secondOption_img,
        option3,
        thirdOption_img,
        option4,
        fourthOption_img,
        answers,
        answersbody,
        resone_img,
        demo,
        questiontype == "CSS" ? cssGroupId : "",
        state.filterObj,
        props.history
      )
    );
  };

  /* preview hamdler */
  const onFinalPreviewHandler = () => {
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Exam category is required";
      error = true;
    }
    if (parseInt(demo) == 0 && parseInt(category) == 1 && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (parseInt(demo) === 0 && parseInt(category) === 2 && examtype === "") {
      errorObj.type = "Exam type is Required";
      error = true;
    }
    if (parseInt(demo) == 0 && parseInt(examtype) == 1 && subtype === "") {
      errorObj.subtype = "Exam sub type is Required";
      error = true;
    }
    if (
      classroom === "" &&
      parseInt(demo) === 0 &&
      (parseInt(examtype) == 2 || parseInt(category) == 1)
    ) {
      errorObj.className = "Class is required";
      error = true;
    }
    if (subject === "" && parseInt(demo) === 0) {
      errorObj.branch = "Branch is Required";
      error = true;
    }
    if (chapter === "" && parseInt(demo) === 0) {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (questiontype === "") {
      errorObj.quesType = "Question type is Required";
      error = true;
    }
    if (questiono === "") {
      errorObj.quesNo = "Question no is Required";
      error = true;
    }
    if (cssGroupId === "" && questiontype == "CSS") {
      errorObj.groupId = "Group id is Required";
      error = true;
    }
    if (answers === "") {
      errorObj.anws = "Answers is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    let que_array = [...imagesArray];
    let resone_array = [...reasonArray];
    let firstOption_array = [...firstOptionArray];
    let secondOption_array = [...secondOptionArray];
    let thirdOption_array = [...thirdOptionArray];
    let fourthOption_array = [...fourthOptionArray];

    let que_img = [];
    let resone_img = [];
    let firstOption_img = [];
    let secondOption_img = [];
    let thirdOption_img = [];
    let fourthOption_img = [];

    que_array.forEach((item, index) => {
      if (item.file) {
        que_img.push(item.file);
      }
    });

    resone_array.forEach((item, index) => {
      resone_img.push(item.file);
    });

    firstOption_array.forEach((item, index) => {
      firstOption_img.push(item.file);
    });

    secondOption_array.forEach((item, index) => {
      secondOption_img.push(item.file);
    });

    thirdOption_array.forEach((item, index) => {
      thirdOption_img.push(item.file);
    });

    fourthOption_array.forEach((item, index) => {
      fourthOption_img.push(item.file);
    });

    dispatch(globalLoadingAction(true));
    dispatch(
      updatePreviewQuestionData(
        props.questionDetails.id,
        parseInt(category),
        parseInt(demo) == 0 && parseInt(category) == 1 ? board : 0,
        parseInt(demo) == 0 && parseInt(category) == 2
          ? parseInt(examtype.split("^")[0])
          : 0,
        parseInt(demo) == 0 && parseInt(examtype) == 1 ? subtype : 0,
        parseInt(demo) == 0 &&
          (parseInt(category) == 1 || parseInt(examtype.split("^")[1]) == 2)
          ? classroom
          : 0,
        parseInt(demo) == 0 ? subject : 0,
        parseInt(demo) == 0 ? chapter : 0,
        questiontype,
        questiono,
        questiobody,
        que_img,
        option,
        firstOption_img,
        option2,
        secondOption_img,
        option3,
        thirdOption_img,
        option4,
        fourthOption_img,
        answers,
        answersbody,
        resone_img,
        demo,
        questiontype == "CSS" ? cssGroupId : "",
        state,
        callbackQuestion,
        props.history
      )
    );
  };

  const callbackQuestion = () => {
    dispatch(globalLoadingAction(false));
    setIsFinalPreview(true);
  };

  /* set question image */
  const onSetQuestionImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.question_image) {
      props.questionDetails.question_image.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setImagesArray(array);
  };

  /* set optionA image */
  const onSetOptionAImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.option_a_image) {
      props.questionDetails.option_a_image.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setFirstOptionArray(array);
  };

  /* set optionB image */
  const onSetOptionBImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.option_b_image) {
      props.questionDetails.option_b_image.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setSecondOptionArray(array);
  };

  /* set optionC image */
  const onSetOptionCImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.option_c_image) {
      props.questionDetails.option_c_image.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setThirdOptionArray(array);
  };

  /* set optionD image */
  const onSetOptionDImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.option_d_image) {
      props.questionDetails.option_d_image.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setFourthOptionArray(array);
  };

  /* set reasone image */
  const onSetReasonImage = () => {
    let array = [];
    let obj = {};
    if (props.questionDetails.supporting_reason) {
      props.questionDetails.supporting_reason.forEach((item, index) => {
        obj = { name: `#Img${index + 1}`, path: item };
        array.push(obj);
      });
    }
    setReasonArray(array);
  };

  /* modal open */
  const onQueModalHandlers = () => {
    setModal(true);
    let str = questiobody;
    imagesArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* reason preview */
  const onReasonPreviewHandler = () => {
    setModal(true);
    let str = answersbody;
    reasonArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* option A preview */
  const onFirstOptionPreviewHandler = () => {
    setModal(true);
    let str = option;
    firstOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* option B preview */
  const onSecondOptionPreviewHandler = () => {
    setModal(true);
    let str = option2;
    secondOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* option C preview */
  const onThirdOptionPreviewHandler = () => {
    setModal(true);
    let str = option3;
    thirdOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* option D preview */
  const onFourthOptionPreviewHandler = () => {
    setModal(true);
    let str = option4;
    fourthOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* file download */
  const onDownloadFile = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      downloadQuetionFile(props.questionDetails.upload_path, props.history)
    );
  };


  /* set question into state */
  const onQuestionInputHandler = (val) => {
    setQuestiobody(val);
  };

  /* set question image into state */
  const onQuestionImageHandler = (item) => {
    setImagesArray(item);
  };

  /* set option A into state */
  const onOptionAInputHandler = (val) => {
    setOption(val);
  };

  /* set option A image into state */
  const onOptionAImageHandler = (item) => {
    setFirstOptionArray(item);
  };

  /* set option B into state */
  const onOptionBInputHandler = (val) => {
    setOption2(val);
  };

  /* set option B image into state */
  const onOptionBImageHandler = (item) => {
    setSecondOptionArray(item);
  };

  /* set option c into state */
  const onOptionCInputHandler = (val) => {
    setOption3(val);
  };

  /* set option C image into state */
  const onOptionCImageHandler = (item) => {
    setThirdOptionArray(item);
  };

  /* set option D into state */
  const onOptionDInputHandler = (val) => {
    setOption4(val);
  };

  /* set option D image into state */
  const onOptionDImageHandler = (item) => {
    setFourthOptionArray(item);
  };
  /* set reasone into state */
  const onReasonInputHandler = (val) => {
    setAnswersbody(val);
  };
  /* set reasone image into state */
  const onReasonImageHandler = (item) => {
    setReasonArray(item);
  };

  /* set Question edit */
  const onQuestionEditHandler = (val, status) => {
    if (questionEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* set Option A edit */
  const onOptionAEditHandler = (val, status) => {
    if (optionAEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* set Option B edit */
  const onOptionBEditHandler = (val, status) => {
    if (optionBEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* set Option C edit */
  const onOptionCEditHandler = (val, status) => {
    if (optionCEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* set Option D edit */
  const onOptionDEditHandler = (val, status) => {
    if (optionDEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* set reasone edit */
  const onReasonEditHandler = (val, status) => {
    if (reasonEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  const onConfirmHandler = () => {
    if (activeNumber == 1) {
      setQuestionEditor(!questionEditor);
    }
    if (activeNumber == 2) {
      setOptionAEditor(!optionAEditor);
    }
    if (activeNumber == 3) {
      setOptionBEditor(!optionBEditor);
    }
    if (activeNumber == 4) {
      setOptionCEditor(!optionCEditor);
    }
    if (activeNumber == 5) {
      setOptionDEditor(!optionDEditor);
    }
    if (activeNumber == 6) {
      setReasonEditor(!reasonEditor);
    }
    setConfirmModal(false);
  };

  /* image upload */
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("tag_image", file);
            axios({
              url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
              method: "POST",
              headers: {
                ContentType: "multipart/form-data",
              },
              data: formData,
            })
              .then((res) => {
                //console.log('result', res.data.imagepath);
                resolve({
                  default: res.data.imagepath,
                });
              })
              .then((json) => {
                //console.log('result2', json);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  /* get category list */
  const getCategoryHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingToggleAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  /* change category */
  const onCategoryHandler = (val) => {
    setCategory(val);
    setExamtype("");
    setBoard("");
    setSubtype("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get board list */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };

  /* change board */
  const onBoardHandler = (val) => {
    setBoard(val);
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get exam type list */
  const getExamtypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingAction(true));
      dispatch(getExamType(props.history));
    }
  };

  /*change exam type */
  const onExamTypeHandler = (val) => {
    setExamtype(val);
    setSubtype("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get sub type list */
  const getSubtypeHandler = () => {
    if (props.subTypeList.length == 0) {
      dispatch(loadingSubTypeAction(true));
      dispatch(getExamSubType(props.history));
    }
  };

  /* change subtype  */
  const onSubtypeTypeHandler = (val) => {
    //console.log("sub type ", val);
    setSubtype(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get subject list */
  const getSubjectHandler = () => {
    let exam_type = examtype.split("^")[0];

    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && exam_type == 1 && subtype) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(exam_type),
          parseInt(subtype),
          0,
          props.history
        )
      );
    } else if (category == 2 && exam_type != 1) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(exam_type),
          0,
          0,
          props.history
        )
      );
    }
  };

  /* get class list */
  const getClassHandler = () => {
    if (props.classList.length == 0) {
      dispatch(loadingClassAction(true));
      dispatch(getClassData(props.history));
    }
  };

  /* get chapter list */
  const getChapterHandler = () => {
    let exam_type = examtype.split("^")[0];

    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && exam_type == 1 && subtype) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          exam_type,
          subtype,
          subject,
          0,
          props.history
        )
      );
    } else if (category == 2 && exam_type != 1) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          parseInt(exam_type),
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* get question list */
  const getQuestionTypeHandler = () => {
    if (props.questionTypeList.length == 0) {
      dispatch(loadingPatternAction(true));
      dispatch(getQuestionPattern(props.history));
    }
  };

  /* on image upload */
  const onImageHandler = (blobInfo) => {
    return new Promise((success, reject) => {
      const formData = new FormData();
      formData.append("tag_image", blobInfo.blob());
      axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          success(res.data.imagepath);
        })
        .then((json) => {
          //console.log('result2', json);
        });
    });
  };

  /* navigation go back  */
  const onGoBackHandler = () => {
    if (state.currentPage == 1) {
      history.push({
        pathname: "/exam-content-list",
        state: state,
      });
    } else {
      history.push({
        pathname: "/question-error-log",
        state: state,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Exam category name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.categoryLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                onClick={getCategoryHandler}
                                onChange={(e) =>
                                  onCategoryHandler(e.target.value)
                                }
                              >
                                <option value="">Select category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          {parseInt(demo) == 0 ? (
                            <>
                              {parseInt(category) == 1 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>
                                      Board
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.boardLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={board}
                                      onClick={getBoardHandler}
                                      onChange={(e) =>
                                        onBoardHandler(e.target.value)
                                      }
                                    >
                                      <option value="">Select Board</option>
                                      {props.boardList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.board && (
                                    <div className="text-danger fs-12">
                                      {errors.board}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Exam Type
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow add-Question-arrow-position">
                                        {props.typeLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          <i className="bi bi-caret-down-fill"></i>
                                        )}
                                      </span>
                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={examtype}
                                        onClick={getExamtypeHandler}
                                        onChange={(e) =>
                                          onExamTypeHandler(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Exam type
                                        </option>
                                        {props.typeList.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={
                                                item.id + "^" + item.is_class
                                              }
                                            >
                                              {item.type_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    {errors.type && (
                                      <div className="text-danger fs-12">
                                        {errors.type}
                                      </div>
                                    )}
                                  </div>

                                  {parseInt(category) == 2 &&
                                    parseInt(examtype.split("^")[0]) == 1 ? (
                                    <div className="form-group mb-3">
                                      <label className="mb-2">
                                        <strong>
                                          Exam Sub Type
                                          <span className="text-danger">*</span>
                                        </strong>
                                      </label>
                                      <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-arrow-position">
                                          {props.subTypeLoading ? (
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                          ) : (
                                            <i className="bi bi-caret-down-fill"></i>
                                          )}
                                        </span>
                                        <select
                                          defaultValue={"option"}
                                          className="form-control form-control"
                                          value={subtype}
                                          onClick={getSubtypeHandler}
                                          onChange={(e) =>
                                            onSubtypeTypeHandler(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select Sub type
                                          </option>
                                          {props.subTypeList.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {item.subtype_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      {errors.subtype && (
                                        <div className="text-danger fs-12">
                                          {errors.subtype}
                                        </div>
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : null}

                          {parseInt(demo) == 0 ? (
                            <>
                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Subject
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow add-Question-arrow-position">
                                    {props.subjectLoading ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      <i className="bi bi-caret-down-fill"></i>
                                    )}
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={subject}
                                    onClick={getSubjectHandler}
                                    onChange={(e) => {
                                      setSubject(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Subject</option>
                                    {props.subjectList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.subject && (
                                  <div className="text-danger fs-12">
                                    {errors.subject}
                                  </div>
                                )}
                              </div>

                              {parseInt(category) == 1 ||
                                examtype.split("^")[1] == 1 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>
                                      Class
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.classLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={classroom}
                                      onClick={getClassHandler}
                                      onChange={(e) =>
                                        setClassroom(e.target.value)
                                      }
                                    >
                                      <option value="">Select Class</option>
                                      {props.classList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.short_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.className && (
                                    <div className="text-danger fs-12">
                                      {errors.className}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Chapter
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow add-Question-arrow-position">
                                    {props.chapterLoading ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      <i className="bi bi-caret-down-fill"></i>
                                    )}
                                  </span>

                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={chapter}
                                    onClick={getChapterHandler}
                                    onChange={(e) => setChapter(e.target.value)}
                                  >
                                    <option value="">Select Chapter</option>
                                    {props.chapterList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.chapter_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.chapter && (
                                  <div className="text-danger fs-12">
                                    {errors.chapter}
                                  </div>
                                )}
                              </div>
                            </>
                          ) : null}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Question Type
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.questionTypeLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={questiontype}
                                onClick={getQuestionTypeHandler}
                                onChange={(e) =>
                                  setQuestiontype(e.target.value)
                                }
                              >
                                <option value="">Select Question type</option>
                                {props.questionTypeList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.short_code}>
                                      {item.short_code}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.quesType && (
                              <div className="text-danger fs-12">
                                {errors.quesType}
                              </div>
                            )}
                          </div>

                          {questiontype == "CSS" && (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  CSS Group Id
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter CSS group ID"
                                value={cssGroupId}
                                onChange={(e) => setCssGroupId(e.target.value)}
                              />
                              {errors.groupId && (
                                <div className="text-danger fs-12">
                                  {errors.groupId}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Question No.
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter question No"
                              value={questiono}
                              onChange={(e) => setQuestiono(e.target.value)}
                            />

                            {errors.quesNo && (
                              <div className="text-danger fs-12">
                                {errors.quesNo}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Question Body
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() =>
                                    onQuestionEditHandler(1, false)
                                  }
                                  className={
                                    questionEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onQuestionEditHandler(1, true)}
                                  className={
                                    questionEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onQueModalHandlers}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>
                            {/* {console.log('questionDetails...', questiobody)} */}
                            {questionEditor ? (
                              <QuestionArea
                                id="formQuestion"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"question_image"}
                                question={questiobody}
                                questionImg={imagesArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onQuestionInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onQuestionImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.question}
                                  value={questiobody}
                                  // onInit={(evt, editor) => {
                                  //     setQuestiobody(props.questionDetails.question);
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setQuestioStatus(true);
                                    setQuestiobody(
                                      questioStatus
                                        ? newValue
                                        : props.questionDetails.question
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Option A</strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionAEditHandler(2, false)}
                                  className={
                                    optionAEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionAEditHandler(2, true)}
                                  className={
                                    optionAEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onFirstOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionAEditor ? (
                              <QuestionArea
                                id="formOptionA"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"option_1_image"}
                                question={option}
                                questionImg={firstOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionAInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionAImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.option_a}
                                  value={option}
                                  // onInit={(evt, editor) => {
                                  //     setOption(props.questionDetails.option_a);
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setOptionStatus(true);
                                    setOption(
                                      optionStatus
                                        ? newValue
                                        : props.questionDetails.option_a
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Option B</strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionBEditHandler(3, false)}
                                  className={
                                    optionBEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionBEditHandler(3, true)}
                                  className={
                                    optionBEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onSecondOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionBEditor ? (
                              <QuestionArea
                                id="formOptionB"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"option_2_image"}
                                question={option2}
                                questionImg={secondOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionBInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionBImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.option_b}
                                  value={option2}
                                  // onInit={(evt, editor) => {
                                  //     setOption2(props.questionDetails.option_b);
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setOptionStatus2(true);
                                    setOption2(
                                      optionStatus2
                                        ? newValue
                                        : props.questionDetails.option_b
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Option C</strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionCEditHandler(4, false)}
                                  className={
                                    optionCEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionCEditHandler(4, true)}
                                  className={
                                    optionCEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onThirdOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionCEditor ? (
                              <QuestionArea
                                id="formOptionC"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"option_3_image"}
                                question={option3}
                                questionImg={thirdOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionCInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionCImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.option_c}
                                  value={option3}
                                  // onInit={(evt, editor) => {
                                  //     setOption3(props.questionDetails.option_c);
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setOptionStatus3(true);
                                    setOption3(
                                      optionStatus3
                                        ? newValue
                                        : props.questionDetails.option_c
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Option D</strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionDEditHandler(5, false)}
                                  className={
                                    optionDEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionDEditHandler(5, true)}
                                  className={
                                    optionDEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onFourthOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionDEditor ? (
                              <QuestionArea
                                id="formOptionD"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"option_4_image"}
                                question={option4}
                                questionImg={fourthOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionDInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionDImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.option_d}
                                  value={option4}
                                  // onInit={(evt, editor) => {
                                  //     setOption4(props.questionDetails.option_d);
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setOptionStatus4(true);
                                    setOption4(
                                      optionStatus4
                                        ? newValue
                                        : props.questionDetails.option_d
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Answers<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                className="form-control form-control"
                                value={answers}
                                onChange={(e) => setAnswers(e.target.value)}
                              >
                                <option value="">Select Answers</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                              </select>
                            </div>
                            {errors.anws && (
                              <div className="text-danger fs-12">
                                {errors.anws}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Reason</strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onReasonEditHandler(6, false)}
                                  className={
                                    reasonEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onReasonEditHandler(6, true)}
                                  className={
                                    reasonEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onReasonPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {reasonEditor ? (
                              <QuestionArea
                                id="formReason"
                                rows="4"
                                questionId={props.questionDetails.id}
                                questionName={"reason_image"}
                                question={answersbody}
                                questionImg={reasonArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onReasonInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onReasonImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  initialValue={props.questionDetails.reason}
                                  value={answersbody}
                                  // onInit={(evt, editor) => {
                                  //     setAnswersbody(props.questionDetails.reason)
                                  // }}
                                  onEditorChange={(newValue, editor) => {
                                    setAnswersbodyStatus(true);
                                    setAnswersbody(
                                      answersbodyStatus
                                        ? newValue
                                        : props.questionDetails.reason
                                    );
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="question-submit-btn-container mt-4">
                            <button
                              onClick={onGoBackHandler}
                              type="button"
                              className="btn btn-primary"
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                            <button
                              type="button"
                              onClick={() => onFinalPreviewHandler()}
                              //onClick={() => setIsFinalPreview(true)}
                              className="btn btn-primary"
                            >
                              Save & Preview
                            </button>
                            <button
                              type="button"
                              onClick={onContinueHandler}
                              className="btn btn-primary"
                            >
                              Update & Continue
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2">
                    <div className="download-excel-view">
                      <button
                        style={{ minWidth: "120px" }}
                        className={`btn btn-secondary sharp d-inline-flex align-items-center justify-content-center  px-2 ${props.questionDetails.upload_path ? null : 'content_disable'}`}
                        onClick={() => onDownloadFile()}
                      >
                        <i class="fa-solid fa-download me-2"></i> Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal className="fade bd-example-modal-lg" show={modal} size="lg">
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {/* <div dangerouslySetInnerHTML={{ __html: htmlResult }} /> */}
            {/* <MathJax math={htmlResult} /> */}
            {/* {
              console.log("1---------",htmlResult)
            } */}
            <div className="modal-editor">
              <Editor
                apiKey={GlobalConfigs.TINY_API_KEY}
                value={htmlResult}
                disabled={true}
                init={{
                  height: 300,
                  menubar: false,
                  external_plugins: {
                    tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                  },
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger light" onClick={() => setModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="fade bd-example-modal-lg"
          show={confirmModal}
          size="md"
        >
          <Alert
            //variant={data.variant}
            className="alert alert-primary notification mb-0"
          >
            <p className="notificaiton-title mb-2">
              <strong>Warning!</strong> Vampires The Romantic Ideology Behind
              Them
            </p>
            <p>
              The following article covers a topic that has recently moved to
              center stage-at lease it seems that way.
            </p>
            <Button
              //variant={data.variant}
              onClick={() => onConfirmHandler()}
              size="sm"
            >
              Confirm
            </Button>
            <Button
              variant="link"
              onClick={() => setConfirmModal(false)}
              size="sm"
            >
              Cancel
            </Button>
          </Alert>
        </Modal>

        <Modal
          className="fade bd-example-modal-lg"
          show={isFinalPreview}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setIsFinalPreview(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-editor">
              <PreviewCard data={props.questionPreview} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => setIsFinalPreview(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.content.showLoading,
    categoryLoading: state.category.showLoading,
    categoryList: state.category.list,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeLoading: state.type.showLoading,
    typeList: state.type.list,
    subTypeLoading: state.subtype.showLoading,
    subTypeList: state.subtype.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.nonGroupSubjectList,
    classLoading: state.class.showLoading,
    classList: state.class.list,
    chapterLoading: state.chapter.showLoading,
    chapterList: state.chapter.typelist,
    questionTypeLoading: state.pattern.showLoading,
    questionTypeList: state.pattern.list,
    questionDetails: state.content.questionDetails,
    characterList: state.content.characterList,
    isStatus: state.content.isStatus,
    questionPreview: state.content.questionPreview,
  };
};
export default connect(mapStateToProps)(UpdateQuestion);
