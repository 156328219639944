import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { MisDashboardInfo } from "../../services/MisDashBoardInfoService";
import { GET_DASHBOARD_INFO } from "../constants";


export function MisDashboardInfoData(
    datediff,
   
  ) {
    return (dispatch) => {
        MisDashboardInfo(datediff)
        .then((response) => {
        
          if (response.data.status == 200) {
            dispatch(MisDashboardInfoAction(response.data))
            dispatch(globalLoadingAction(false));
          } 
          dispatch(globalLoadingAction(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(globalLoadingAction(false));
          utility.showError(error.response.data);
        });
    };
  }


  export function MisDashboardInfoAction(data){
        return{
            type:GET_DASHBOARD_INFO,
            payload:data
        }
  }