import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Pagination,
  Modal,
  Button,
} from "react-bootstrap";

import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from "../../../apis/Apis";

import {
  getElibraryData,
  loadingElibraryAction,
  deleteLibraryData,
  elibraryContentListTypeAction,
  elibraryContentListCategoryAction,
  elibraryContentListBoardAction,
  elibraryContentListClassAction,
  elibraryContentListSubjectAction,
  elibraryContentListChapterAction,
  elibraryContentListStatusAction,
  elibraryContentListExamTypeAction
} from "../../../store/actions/ElibraryAction";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getSubjectsFiltterList,
  subjectLoading,
  getSingleSubjectListDetails,
  getSingleSubjectListActions
} from "../../../store/actions/SujectActions";
import {
  getChaptersFilterList,
  loadingChaptersAction,
} from "../../../store/actions/ChapterAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import { getClassData } from '../../../store/actions/ClassAction';
import { getChaptersNoPagination } from "../../../store/actions/ChapterAction";

const ElibraryContentList = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const demoTypeRef = useRef("");

  const [previousFilterData, setPreviousFilterData] = useState(props?.location.state);

  const [defalutSelectValue, setDefalutSelectValue] = useState('');

  const [active, setActive] = useState(previousFilterData?.active ?? 1);

  const [demo, setDemo] = useState(previousFilterData?.demo ?? '');

  const [classes, setclasses] = useState([]);
  const [cls, setCls] = useState(previousFilterData?.cls ?? '');
  const [category, setCategory] = useState(previousFilterData?.category ?? '');
  const [board, setBoard] = useState(previousFilterData?.board ?? '');
  const [examType, setExamType] = useState(previousFilterData?.examType ?? '');
  const [subject, setSubject] = useState(previousFilterData?.subject ?? '');
  const [chapter, setChapter] = useState(previousFilterData?.chapter ?? '');
  const [status, setStatus] = useState(previousFilterData?.status ?? '');

  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getClassData(props.history));
    permissionCheck();
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        active,
        demo,
        category,
        board,
        examType,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  }, []);

  useEffect(() => {
    getCategoryHandler();
    getExamTypeHandler();
    getBoardHandler();
    getSubjectHandler();
    getChapterHandler();
    
  }, [previousFilterData]);


  /* confirmation modal open */
  const onDeleteModal = (i) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.elibraryList.length == 1) {
          dispatch(deleteLibraryData(i, active - 1, props.history));
          setActive(active - 1)
        } else {
          dispatch(deleteLibraryData(i, active, props.history));
        }

      }
    });
  };

  /* set demo elibrary */
  const onChangeDemoHandler = (e) => {
    setDemo(e.target.value)
    const selectedOption = e.target.options[e.target.selectedIndex];
    const value = selectedOption.getAttribute("value");
    const name = selectedOption.getAttribute("name");
    demoTypeRef.current.name = name;

    // dispatch(elibraryContentListTypeAction(e.target.value))

    setDemo(value);
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        value,
        category,
        board,
        examType,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* get category list */
  const getCategoryHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingToggleAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  /* on category change */
  const onCategoryHandler = (value) => {
    setCategory(value);
    dispatch(elibraryContentListCategoryAction(value))
    dispatch(getSingleSubjectListActions([]))
    setExamType("");
    setBoard("");
    setSubject("");
    setChapter("");
    setStatus("");
    setCls("");

    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        value,
        board,
        examType,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* get board list */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };

  /* on board change */
  const onBoardHandler = (value) => {
    setBoard(value);
    dispatch(elibraryContentListBoardAction(value))
    setExamType('')
    dispatch(elibraryContentListExamTypeAction(0))
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        value,
        examType,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        data.selected + 1,
        demo,
        category,
        board,
        examType,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* on status change */
  const onStatusHandler = (value) => {
    setStatus(value);
    dispatch(elibraryContentListStatusAction(value))
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        board,
        examType,
        subject,
        chapter,
        value,
        cls,
        props.history
      )
    );
  };

  /* on change class */
  const onClassHandler = (value) => {

    setCls(value);
    dispatch(elibraryContentListClassAction(value))
    globalLoadingAction(true);
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        board,
        examType,
        subject,
        chapter,
        status,
        value,
        props.history
      )
    );
  };

  /* on exam type change */
  const onExamTypeHandler = (value) => {
    setExamType(value);
    dispatch(elibraryContentListExamTypeAction(value))
    setBoard('');
    dispatch(elibraryContentListBoardAction(0))
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        board,
        value,
        subject,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* get exam type list */
  const getExamTypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingAction(true));
      dispatch(getExamType(props.history));
    }
  };

  /* on subject change */
  const onSubjectHandler = (value) => {

    setSubject(value);
    dispatch(elibraryContentListSubjectAction(value))
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        board,
        examType,
        value,
        chapter,
        status,
        cls,
        props.history
      )
    );
  };

  /* get subject list */
  const getSubjectHandler = () => {
    if (board != '' || examType != '' && category != '') {
      dispatch(subjectLoading(true));
      // dispatch(getSubjectsFiltterList(props.history));
      dispatch(getSingleSubjectListDetails(0, board, examType, '', category, props.history));
    }
  };

  /* on change chapter */
  const onChapterHandler = (value) => {
    setChapter(value);
    dispatch(elibraryContentListChapterAction(value))
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getElibraryData(
        1,
        demo,
        category,
        board,
        examType,
        subject,
        value,
        status,
        cls,
        props.history
      )
    );
  };

  /* get chapter list */
  const getChapterHandler = () => {
    // if (props.chapterList.length == 0) {
    dispatch(loadingChaptersAction(true));
    dispatch(getChaptersNoPagination(subject, board, examType, "", cls, category))

    // }
  };

  const ClassList = async () => {
    try {
      let getData = localStorage.getItem("userDetails");
      let token = JSON.parse(getData).token;
      const response = await fetch(GlobalConfigs.API_URL + Apis.GET_CLASS_API, {
        headers: {
          ContentType: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setclasses(data.data);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  /* filter reset */
  const onResetHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(getElibraryData(1, "", "", "", "", "", "", "", "", props.history));
    setActive(1);
    setDemo("");
    demoTypeRef.current.selectedIndex = 0;
    setCategory("");
    setBoard("");
    setExamType("");
    setSubject("");
    setChapter("");
    setStatus("");
    setclasses([]);
    setCls("");
    setPreviousFilterData('')
    const stateCopy = { ...state };
    delete stateCopy.active;
    delete stateCopy.demo;
    delete stateCopy.category;
    delete stateCopy.examType;
    delete stateCopy.board;
    delete stateCopy.cls;
    delete stateCopy.subject;
    delete stateCopy.chapter;
    delete stateCopy.status;
    props.history.replace({ state: stateCopy });
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 63) {
        setPermission(item);
      }
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row add-Question-list-view">

                <div className="col-sm-12 px-1 reset-btn-view">
                  <button
                    onClick={onResetHandler}
                    className={`btn btn-secondary ${demo == '' && category == '' && board == '' && examType == '' && cls == '' && chapter == '' && status == '' ? 'content_disable' : null}`}
                  >
                    Reset
                  </button>
                </div>

                <div className="filter-container ">
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>

                      <select
                        defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={demo}
                        ref={demoTypeRef}
                        onChange={(e) => onChangeDemoHandler(e)}
                      >
                        <option value="" name="Select Type">
                          Select Type
                        </option>

                        <option value="2" name="Live">
                          Live
                        </option>
                        <option value="1" name="Guest Demo">
                          Guest Demo
                        </option>
                        <option value="3" name="Register Demo">
                          Register Demo
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">

                        <i className="bi bi-caret-down-fill"></i>

                      </span>
                      <select
                        defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={category}
                        onChange={(e) => onCategoryHandler(e.target.value)}
                        onClick={getCategoryHandler}
                      >
                        <option value="">Select category</option>
                        {props.categoryList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.category}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  {category == 2 ? (
                    <div className="col-sm-2 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow">

                          <i className="bi bi-caret-down-fill"></i>

                        </span>
                        <select
                          defaultValue={"option"}
                          className="form-control filter-form-control"
                          value={examType}
                          onChange={(e) => onExamTypeHandler(e.target.value)}
                          onClick={getExamTypeHandler}
                        >
                          <option value="">Select Exam type</option>
                          {props.typeList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.type_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-2 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow">

                          <i className="bi bi-caret-down-fill"></i>

                        </span>
                        <select
                          defaultValue={"option"}
                          className="form-control filter-form-control"
                          value={board}
                          onChange={(e) => onBoardHandler(e.target.value)}
                          onClick={getBoardHandler}
                        >
                          <option value="">Select Board</option>
                          {props.boardList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className={`col-sm-2 px-1 ${examType == 1 ? 'content_disable' : null}`}>
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={defalutValue}
                        className="form-control filter-form-control"
                        value={cls}
                        // onChange={(e) => setSubject(e.target.value)}
                        onChange={(e) => onClassHandler(e.target.value)}
                      >
                        <option value={defalutSelectValue}>Select Class</option>
                        {
                          props.classList.map((item, index) => {
                            // return <option key={index} value={[item.class_no, index]}>{item.short_code}</option>
                            return <option key={index} value={item.class_no}>{item.class_no}</option>
                          })
                        }
                      </select>

                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">

                        <i className="bi bi-caret-down-fill"></i>

                      </span>
                      <select
                        defaultValue={"option"}
                        className={`form-control filter-form-control ${board != '' || examType != '' && category != '' ? null : 'content_disable'}`}
                        value={subject}
                        onChange={(e) => onSubjectHandler(e.target.value)}
                        onClick={getSubjectHandler}
                      >
                        <option value="">Select Subject</option>
                        {/* {props.subjectList.map((item, index) => { */}
                        {props.getSingleSubjectList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">

                        <i className="bi bi-caret-down-fill"></i>

                      </span>
                      <select
                        defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={chapter}
                        onChange={(e) => onChapterHandler(e.target.value)}
                        onClick={getChapterHandler}
                      >
                        <option value="">Select Chapter</option>
                        {props.chapterList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2 px-1 filter-class-library">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={status}
                        onChange={(e) => onStatusHandler(e.target.value)}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Published</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                  </div>


                </div>
              </div>
              {props.totalRecord == 0 ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>Board/Exam Type</strong>
                      </th>
                      <th>
                        <strong>Category</strong>
                      </th>
                      <th>
                        <strong>Subject</strong>
                      </th>
                      <th>
                        <strong>Chapter</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Create Date</strong>
                      </th>

                      <th>
                        <strong>Update Date</strong>
                      </th>

                      <th>
                        <strong>Approve Status</strong>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.elibraryList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.content_status}</td>
                          <td>{item.board_name == null ? '-' : item.board_name}</td>
                          <td>{item.category}</td>
                          <td>{item.branch_name == null ? '-' : item.branch_name}</td>
                          <td>{item.chapter_name == null ? '-' : item.chapter_name}</td>
                          <td>{item.class_no == null ? '-' : item.class_no}</td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.created_at}
                            </Moment>
                          </td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.updated_at}
                            </Moment>
                          </td>
                          <td>
                            <div
                              className={`content_status ${item.admin_approved_status == 0
                                ? `pending_background`
                                : item.admin_approved_status == 1
                                  ? `assian_validator_background`
                                  : item.admin_approved_status == 2
                                    ? `validator_approved_background`
                                    : item.admin_approved_status == 3
                                      ? `assian_designer_background`
                                      : item.admin_approved_status == 4
                                        ? `designer_uploaded_background`
                                        : item.admin_approved_status == 5
                                          ? `admin_publish_background`
                                          : null
                                } fwhite`}
                            >
                              {item.admin_approved_status == 0
                                ? "Pending"
                                : item.admin_approved_status == 1
                                  ? "Assign to Validator"
                                  : item.admin_approved_status == 2
                                    ? "Validator Approve"
                                    : item.admin_approved_status == 3
                                      ? "Assign to Designer"
                                      : item.admin_approved_status == 4
                                        ? "uploaded by Designer"
                                        : item.admin_approved_status == 5
                                          ? "Published"
                                          : null}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <Link
                                to={{
                                  pathname: "/elibrary-content-details",
                                  state: {
                                    data: item, filterData: { active, demo, category, examType, board, cls, subject, chapter, status, }
                                  },

                                }}
                                className="btn btn-info shadow btn-xs sharp me-1"
                              >
                                <i className="fas fa-eye"></i>
                              </Link>

                              {
                                permission.delete == "true" && (
                                  <Button
                                    className="btn btn-danger shadow btn-xs sharp"
                                    onClick={() => onDeleteModal(item.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                )
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}


              <div className="pagination-container">
                <div className="page-result">
                  {props.totalRecord !== 0 && (
                    <p>
                      Showing: {(active - 1) * 10 + 1} -{" "}
                      {10 * active - (10 - props.elibraryList.length)} of{" "}
                      {props.totalRecord}
                    </p>
                  )}
                </div>
                {props.totalRecord > 10 ?
                  <ReactPaginate
                    containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextLabel={<i class="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    previousLabel={<i class="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={pageHandler}
                    pageRangeDisplayed={3}
                    pageCount={props.totalPage}
                    forcePage={active - 1}
                  />
                  : null}
              </div>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    elibraryList: state.elibrary.list,
    categoryList: state.category.list,
    categoryLoading: state.category.showLoading,
    branchList: state.branch.branchList,
    branchLoading: state.branch.showLoading,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeLoading: state.type.showLoading,
    typeList: state.type.list,
    classList: state.class.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.subjectFiltterList,
    chapterLoading: state.chapter.showLoading,
    chapterList: state.chapter.chapterFiltterList,
    totalPage: state.elibrary.totalPage,
    totalRecord: state.elibrary.totalRecord,
    conceptMapPath: state.elibrary.conceptMapPath,
    getSingleSubjectList: state.subject.getSingleSubjectList
  };
};

export default connect(mapStateToProps)(ElibraryContentList);
