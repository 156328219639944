import swal from "sweetalert";
import {
  addAcademicSession,
  getAcademicSession,
  updateAcademicSession,
  removeAcademicSession,
  updateStatusAcademicSession,
} from "../../services/AcademicServices";

import { GET_ACADEMIC_SESSION_LIST,POST_ACADEMIC_SESSION_STATUS_UPDATE } from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "./GlobalAction";

export function addAcademicSessionDetails(
  category_id,
  exam_board_type,
  name,
  start_date,
  end_date,
  cleardata,
  history
) {
  return (dispatch) => {
    addAcademicSession(category_id, exam_board_type, name, start_date, end_date)
      .then((response) => {
        if (response.data.status === 200) {
          cleardata();
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateAcademicSessionDetails(
  id,
  category_id,
  exam_board_type,
  name,
  start_date,
  end_date,
  filter,
  history
) {
  return (dispatch) => {
    updateAcademicSession(
      id,
      category_id,
      exam_board_type,
      name,
      start_date,
      end_date
    )
      .then((response) => {
        if (response.data.status === 200) {
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAcademicSessionData(
  category,
  exam_board_type,
  name,
  startDate,
  endDate,
  active,
  history
) {
  return (dispatch) => {
    getAcademicSession(
      category,
      exam_board_type,
      name,
      startDate,
      endDate,
      active
    )
      .then((response) => {
        console.log("response.........", response.data);
        if (response.data.status == 200) {
          dispatch(getAcademicSessionAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateStatusAcademicSessionData(item, history) {
  return (dispatch) => {
    updateStatusAcademicSession(item.id)
      .then((response) => {
        if (response.data.status == 200) {
        utility.showSuccess(response.data.msg);
        dispatch(updateStatusAcademicSessionAction(item));
        dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function removeAcademicSessionData(
  id,
  category,
  exam_board_type,
  name,
  startDate,
  endDate,
  active,
  listLenght,
  history
) {
  return (dispatch) => {
    removeAcademicSession(id)
      .then((response) => {
        dispatch(
          getAcademicSessionData(
            category,
            exam_board_type,
            name,
            startDate,
            endDate,
            listLenght == 1 && active > 1 ? active - 1 : active,
            history
          )
        );
        swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function getAcademicSessionAction(data) {
  return {
    type: GET_ACADEMIC_SESSION_LIST,
    payload: data,
  };
}

export function updateStatusAcademicSessionAction(data) {
  return {
    type: POST_ACADEMIC_SESSION_STATUS_UPDATE,
    payload: data,
  };
}

