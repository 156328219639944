import {
    getCancelQuestionsReason
} from '../../services/ReasonService';

import {
    GET_CENCEL_QUESTIONS_REASON
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function getCancelQuestionsReasonData(history) {
    return (dispatch) => {
        getCancelQuestionsReason()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getCancelQuestionsReasonAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));   
            });
    };
}

export function getCancelQuestionsReasonAction(data) {
    return {
        type: GET_CENCEL_QUESTIONS_REASON,
        payload: data,
    };
}
