import {
  GET_ELIBRARY_LIST,
  ELIBRARY_LOADING,
  APPROVE_VADIDATOR_BY_ADMIN,
  UPLOAD_ELIBRARY_CONTENT,
  UPLOAD_CONTENT_MAP,
  UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH,
  CONTENT_DEVELOPER_LIST,
  ASSIGN_LIBRARY,
  GET_LIBRARY_COUNT,
  DELETE_LIBRARY_CONTENT,
  REMOVE_UPDATE_DATE,
  CONCEPT_MAP_PATH_ONLY,
  ELIBRARY_CONTENT_LIST_TYPE,
  ELIBRARY_CONTENT_LIST_CATEGORY,
  ELIBRARY_CONTEN_TLIST_BOARD,
  ELIBRARY_CONTENT_LIST_CLASS,
  ELIBRARY_CONTENT_LIST_SUBJECT,
  ELIBRARY_CONTENT_LIST_CHAPTER,
  ELIBRARY_CONTENT_LIST_STATUS,
  ELIBRARY_CONTENT_LIST_EXAM_TYPE
} from "../constants";

const initialState = {
  list: [],
  contentList: [],
  showLoading: false,
  conceptMapPath: null,
  pdfPath: null,
  updateByFileUpload: null,
  libararyDeveloperList: [],
  totalPage: 0,
  totalRecord: 0,
  uploadId: null,
  deleteId: null,
  totalWriteOf: 0,
  totalConceptMap: 0,

  elibraryContentListType: 0,
  elibraryContentListCategory: 0,
  elibraryContentListBoard: 0,
  elibraryContentListClass: 0,
  elibraryContentListSubject: 0,
  elibraryContentListChapter: 0,
  elibraryContentListStatus: '',
  elibraryContentListExamType: 0,

};

export function ElibraryReducer(state = initialState, action) {
  if (action.type === GET_ELIBRARY_LIST) {
    return {
      ...state,
      list: action.payload.data,
      totalPage: action.payload.page,
      totalRecord: action.payload.library_total_record,
      showLoading: false,
    };
  }

  if (action.type === ELIBRARY_LOADING) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  if (action.type === UPLOAD_ELIBRARY_CONTENT) {
    return {
      ...state,
      classList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === UPLOAD_CONTENT_MAP) {
    console.log("reducer is called")
    return {
      ...state,
      conceptMapPath: action.payload.file_path,
      pdfPath: action.payload.uploadfilepath_details,
      updateByFileUpload: action.payload.update_date,
      showLoading: false,
    };
  }

  if (action.type === UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH) {
    console.log("@hello2", action.payload);
    return {
      ...state,
      conceptMapPath: action.payload.file_path,
      pdfPath: action.payload.uploadfilepath_details,
      updateByFileUpload: action.payload.update_date,
      showLoading: false,
    };
  }

  if (action.type === CONTENT_DEVELOPER_LIST) {
    return {
      ...state,
      libararyDeveloperList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === ASSIGN_LIBRARY) {
    return {
      ...state,
      uploadIds: action.payload,
      showLoading: false,
    };
  }

  if (action.type === GET_LIBRARY_COUNT) {
    return {
      ...state,
      totalWriteOf: action.payload.total_write_off,
      totalConceptMap: action.payload.total_concept_map,
      showLoading: false,
    };
  }

  if (action.type === DELETE_LIBRARY_CONTENT) {
    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload);
    list.splice(index, 1);

    return {
      ...state,
      list: list,
      showLoading: false,
    };
  }

  if (action.type === REMOVE_UPDATE_DATE) {
    console.log("remove reducer is called");
    return {
      ...state,
      updateByFileUpload: null,
    };
  }

  if (action.type === CONCEPT_MAP_PATH_ONLY) {
    return {
      ...state,
      conceptMapPath: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_TYPE) {
    return {
      ...state,
      elibraryContentListType: action.payload,
    };
  }
  if (action.type === ELIBRARY_CONTENT_LIST_CATEGORY) {
    return {
      ...state,
      elibraryContentListCategory: action.payload,
    };
  }
  if (action.type === ELIBRARY_CONTEN_TLIST_BOARD) {
    return {
      ...state,
      elibraryContentListBoard: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_CLASS) {
    return {
      ...state,
      elibraryContentListClass: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_SUBJECT) {
    return {
      ...state,
      elibraryContentListSubject: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_CHAPTER) {
    return {
      ...state,
      elibraryContentListChapter: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_STATUS) {
    return {
      ...state,
      elibraryContentListStatus: action.payload,
    };
  }

  if (action.type === ELIBRARY_CONTENT_LIST_EXAM_TYPE) {
    return {
      ...state,
      elibraryContentListExamType: action.payload,
    };
  }

  return state;
}
