import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function getElibraryList(page,content_type,exam_category,board,exam_type,subject,chapter,status,className) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  const postData = {
    page,
    user_id,
    content_type,
    exam_category,
    board,
    exam_type,
    subject,
    chapter,
    status,
    class:className
  };
  
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ELIBRARY_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function approveValidator(record_id, assigned_validator_id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  const postData = {
    assigned_validator_id,
    record_id,
    assigned_by: user_id,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.APPROVE_VALIDATOR_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function uploadContent(
  category,
  board,
  examType,
  subtype,
  subject,
  class_id,
  chapter,
  file,
  demo
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("branch_id", subject);
  formData.append("library_pdf", file);
  formData.append("chapter_id", chapter);
  formData.append("class_id", class_id);
  formData.append("exam_category_id", category);
  formData.append("board_id", board);
  formData.append("exam_type_id", examType);
  formData.append("subtype_id", subtype);
  formData.append("is_demo", demo);

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_ELIBRARY_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function uploadConceptMap(recid, file, file2) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  let postData = {
    recid,
    file,
    file2,
  };

  const formData = new FormData();
  formData.append("library_pdf", file);
  formData.append("library_pdf_details", file2);
  formData.append("recid", recid);
  // console.log("postdata", postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_CONCEPT_MAP_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function saveAndPublishedConceptMap(recid, file, file2) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  let postData = {
    recid,
    file,
    file2,
  };
  
  const formData = new FormData();
  formData.append("library_pdf", file);
  formData.append("library_pdf_details", file2);
  formData.append("recid", recid);
  // console.log("postdata", postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_PUBLISHED_LIBRARY_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function contentDeveloperList() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_CONTECT_DEVELOPER_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function assignLibrary(
  branch_id,
  chapter_id,
  class_id,
  exam_category_id,
  exam_type_id,
  board_id,
  assigned_developer
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  const postData = {
    user_id,
    branch_id,
    chapter_id,
    class_id,
    exam_category_id,
    exam_type_id,
    board_id,
    assigned_developer,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_ASSIGN_LIBRARY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getElibraryCount(
  type,
  category_id,
  board_id,
  exam_type_id,
  subject_id,
  class_no
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let user_id = JSON.parse(getData).id;

  const postData = {
    type,
    category_id,
    board_id,
    exam_type_id,
    subject_id,
    class_no,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ELIBRARY_dashboard_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function deleteLibraryContentData(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_DELETE_CONTENT_LIBRARY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
