import swal from "sweetalert";
import { MisRegisterUser } from "../../services/MisRegUserServices";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { GET_MIS_REGISTER_USER } from "../constants";

export function getMisRegisterUserData(
  datediff,
  board,
  className,
  pincode,
  subscribe_status,
  appeared_after_register
) {
  return (dispatch) => {
    MisRegisterUser(
      datediff,
      board,
      className,
      pincode,
      subscribe_status,
      appeared_after_register
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getRegUserAction(response.data.data));
        } else {
          dispatch(getRegUserAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getRegUserAction(data) {
  return {
    type: GET_MIS_REGISTER_USER,
    payload: data,
  };
}
