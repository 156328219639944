import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function addAcademicSession(exam_category_id,exam_board_type,session_name,session_start_date,session_end_date) {
  
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board_type,
    session_name,
    session_start_date,
    session_end_date
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateAcademicSession(recid,exam_category_id,exam_board_type,session_name,session_start_date,session_end_date) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    recid,
    exam_category_id,
    exam_board_type,
    session_name,
    session_start_date,
    session_end_date
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getAcademicSession(exam_category_id,exam_board_type,session_name,session_start_date,session_end_date,page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board_type,
    session_name,
    session_start_date,
    session_end_date,
    page
  }; 

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateStatusAcademicSession(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    recid,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ACADEMIC_SESSIONS_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeAcademicSession(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
