import {
    ADD_COMPETETIVE_SUBSCRIPTION,
    GET_COMPETETIVE_SUBSCRIPTION_LIST,
    ADD_SCHOLASTIC_SUBSCRIPTION,
    GET_SCHOLASTIC_SUBSCRIPTION,
    GET_LIBRARY_SUBSCRIPTION,
    GET_INTEGRATED_SUBSCRIPTION,
    UPDATE_COMPETETIVE_SUBSCRIPTION,
    UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS,
    UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS,
    UPDATE_LIBRARY_SUBSCRIPTION_STATUS,
    REMOVE_COMPETETIVE_SUBSCRIPTION,
    REMOVE_SCHOLASTIC_SUBSCRIPTION,
    REMOVE_LIBRARY_SUBSCRIPTION,
    SUBSCRIPTION_LOADING
} from '../constants';

const initialState = {
    competetiveList: [],
    totalCompetetivePage: 0,
    totalCompetetiveRecord: 0,
    scholasticList: [],
    totalScholasticPage: 0,
    totalScholasticRecord: 0,
    libraryList: [],
    totalLibraryPage: 0,
    totalLibraryRecord: 0,
    integratedList: [],
    totalIntegratedPage: 0,
    totalIntegratedRecord: 0,
    showLoading: false,
};

export function SubscriptionReducer(state = initialState, action) {

    if (action.type === ADD_COMPETETIVE_SUBSCRIPTION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_COMPETETIVE_SUBSCRIPTION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_COMPETETIVE_SUBSCRIPTION_LIST) {
        return {
            ...state,
            competetiveList: action.payload.data,
            totalCompetetivePage: action.payload.total_page,
            totalCompetetiveRecord: action.payload.total_record_count,
            showLoading: false,
        };
    }

    if (action.type === ADD_SCHOLASTIC_SUBSCRIPTION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_SCHOLASTIC_SUBSCRIPTION) {
        return {
            ...state,
            scholasticList: action.payload.data,
            totalScholasticPage: action.payload.total_page,
            totalScholasticRecord: action.payload.total_record_count,
            showLoading: false,
        };
    }

    if (action.type === GET_LIBRARY_SUBSCRIPTION) {
        return {
            ...state,
            libraryList: action.payload.data,
            totalLibraryPage: action.payload.total_page,
            totalLibraryRecord: action.payload.total_record_count,
            showLoading: false,
        };
    }

    if (action.type === GET_INTEGRATED_SUBSCRIPTION) {
        return {
            ...state,
            integratedList: action.payload.data,
            totalIntegratedPage: action.payload.total_page,
            totalIntegratedRecord: action.payload.total_record_count,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS) {

        const list = [...state.competetiveList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            competetiveList: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS) {

        const list = [...state.scholasticList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            scholasticList: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_LIBRARY_SUBSCRIPTION_STATUS) {

        const list = [...state.libraryList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            libraryList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_COMPETETIVE_SUBSCRIPTION) {

        const list = [...state.competetiveList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            competetiveList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_SCHOLASTIC_SUBSCRIPTION) {

        const list = [...state.scholasticList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            scholasticList: list,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_LIBRARY_SUBSCRIPTION) {

        const list = [...state.libraryList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            libraryList: list,
            showLoading: false,
        };
    }

    if (action.type === SUBSCRIPTION_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


