import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { Editor } from '@tinymce/tinymce-react';
import GlobalConfigs from "../../../configs/GlobalConfigs";

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getLibraryHistoryLogData } from '../../../store/actions/AssignContentAction';
import { BsJournalMedical } from "react-icons/bs";

import {
    Row,
    Col,
    Table,
    Card,
    Modal,
    Button
} from "react-bootstrap";

const AssignDeveloper = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { state } = useLocation();

    const [contentModal, setContentModal] = useState(false);
    const [content, setContent] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getLibraryHistoryLogData(state.id, props.history))
        return () => {
        };
    }, []);


    const onGoBackHandler = () => {
        history.goBack();
    }

    /* open modal */
    const openContentModal = (content_data) => {
        setContent(content_data)
        setContentModal(true)
    }

    /* image upload */
    const onImageHandler = (blobInfo) => {
        return new Promise((success, reject) => {
            const formData = new FormData();
            formData.append("content_image", blobInfo.blob());
            /* axios({
                url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_IMAGE_API,
                method: "POST",
                headers: {
                    ContentType: "multipart/form-data",
                },
                data: formData,
            }).then((res) => {
                success(res.data.imagepath);
            }).then((json) => {
                console.log(json);
            }); */
        });
    }


    return (

        <>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="col-xl-3 pt-3">
                                <div className="back-arrow-container">
                                    <Link
                                        onClick={onGoBackHandler}>
                                        <i class="fa-solid fa-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-2"></div>
                                <div className="col-xl-8">

                                    <div className="card-body">
                                        <div className="basic-form">


                                            <div className="form-group mb-3 two_column">
                                                <div className="mb-2 one_column">
                                                    <h4><strong>Category : </strong>{state.category}</h4>
                                                </div>
                                                <div className="mb-2 one_column">
                                                    <h4><strong>Chapter :</strong> {state.chapter_name}</h4>
                                                </div>
                                            </div>
                                            {state.exam_category_id == 1 ?
                                                <div className="form-group mb-3 two_column">
                                                    <div className="mb-2 one_column">
                                                        <h4><strong>Board :</strong> {state.board_name}</h4>
                                                    </div>
                                                    <div className="mb-2 one_column">
                                                        <h4><strong>Class :</strong> {state.class_code}</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="form-group mb-3 two_column">
                                                    <div className="mb-2 one_column">
                                                        <h4><strong>Exam Type :</strong> {state.type_name}</h4>
                                                    </div>
                                                    <div className="mb-2 one_column">
                                                        <h4><strong>Class :</strong> {state.class_code}</h4>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-group mb-3 two_column">
                                                <div className="mb-2 one_column">
                                                    <h4><strong>Subject :</strong> {state.subject_name}</h4>
                                                </div>
                                                <div className="mb-2 one_column">
                                                    <h4><strong>Type :</strong> {`Live`}</h4>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-2"></div>
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Assign Person</strong>
                                        </th>
                                        <th>
                                            <strong>Time Limit</strong>
                                        </th>
                                        <th>
                                            <strong>Price</strong>
                                        </th>
                                        <th>
                                            <strong>Status</strong>
                                        </th>
                                        <th>
                                            <strong>Created Date</strong>
                                        </th>
                                        <th className='chapter_col'>
                                            <strong>Reject Reason</strong>
                                        </th>
                                        <th>
                                            <strong>Content Details</strong>
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {props.historyLog != '' ?
                                        props.historyLog.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{`${item.developer_type} : ${item.name}`}</td>
                                                <td>{item.time_limit}</td>
                                                <td>{item.price}</td>
                                                <td>{item.status}</td>
                                                <td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
                                                <td>{item.rejection_msg}</td>
                                                <td className='text-center'> {item.content_data == '' || item.content_data == null ? null : <BsJournalMedical size={25} color={'#FF7F27'}
                                                    onClick={() => openContentModal(item.content_data)}
                                                    className='cursorPointer' />
                                                }
                                                </td>
                                            </tr>
                                        })
                                        : null}


                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal className="fade" show={contentModal} size="lg">
                <Modal.Header>
                    <Modal.Title className="modal_title">Content Pdf</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ck-editer-view">

                        <Editor
                            apiKey={GlobalConfigs.TINY_API_KEY}
                            value={content}
                            onInit={(evt, editor) => {
                                //setText(editor.getContent({ format: 'text' }));
                            }}
                            onEditorChange={(newValue, editor) => {
                                setContent(newValue);
                                //setText(editor.getContent({ format: 'text' }));
                            }}
                            //initialValue="<p>This is the initial content of the editor.</p>"
                            init={{
                                height: 400,
                                menubar: true,
                                image_advtab: true,
                                external_plugins: {
                                    'tiny_mce_wiris': `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                },
                                images_upload_handler: onImageHandler,
                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'pagebreak', 'help', 'wordcount'],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat |pagebreak| help | image |' + 'tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                                setup: editor => {
                                    editor.ui.registry.addButton('pagebreak', {
                                        text: 'Page Break',
                                        onAction: function (_) {
                                            editor.execCommand('mceInsertContent', false, '<p style="page-break-before:always;"></p>');
                                        }
                                    });
                                },
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer_right_container show_right">
                        <Button
                            variant="warning text-center m-1"
                            onClick={() => setContentModal(false)}
                        >
                            OK
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </ >

    )
}

const mapStateToProps = (state) => {
    // console.log("state.assign.historyLog---", state.assign.historyLog)
    return {
        // documentDetails: state.assign.documentDetails,
        historyLog: state.assign.historyLog,
    };
};
export default connect(mapStateToProps)(AssignDeveloper);