import { ReactChild, Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,

} from "react-bootstrap";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getMisRegisterUserData } from "../../../store/actions/MisRegUserAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { useDispatch, connect } from "react-redux";

const MisRegUser = (props) => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState("");
  const [subscription, setSubscription] = useState("");
  const [className, setClassName] = useState("");
  const [appeared, setAppeared] = useState("");
  const [pin, setPin] = useState("");
  const [typedPin, setTypedPin] = useState("");
  const [board, setboard] = useState("");
  const [regVia, setRegVia] = useState('')

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
  }, []);

  useEffect(() => {
    getList(period, board, className, pin, subscription, appeared);
  }, [subscription, className, pin, period, appeared, board]);

  const getList = (
    datediff,
    board,
    className,
    pincode,
    subscribe_status,
    appeared_after_register
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getMisRegisterUserData(
        datediff,
        board,
        className,
        pincode,
        subscribe_status,
        appeared_after_register
      )
    );
  };

  const resetHandler = () => {
    setPeriod('')
    setSubscription('')
    setClassName('')
    setAppeared('')
    setPin('')
    setTypedPin('')
    setboard('')
    setRegVia('')
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className=" filtersAlign mt-3" style={{ marginRight: 25 }}>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                  >
                    <option value="">Select Period</option>
                    <option value="1">Today</option>
                    <option value="7">Last 7 days</option>
                    <option value="15">Last 15 days</option>
                    <option value="30">Last 1 month</option>
                    <option value="90">Last 3 months</option>
                    <option value="365">Last 12 months</option>
                  </select>
                </div>
              </div>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={regVia}
                    onChange={(e) => { setRegVia(e.target.value) }}

                  >
                    <option value="">Select Registration via</option>
                    <option value="1">direct reg (Through website)</option>
                    <option value="2">after guest reg (Through portal)</option>
                    <option value="3">Social media</option>
                  </select>
                </div>
              </div>

              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={board}
                    onChange={(e) => {
                      setboard(e.target.value);
                    }}
                  >
                    <option value="">Select Board</option>

                    {props.boardList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                    {/* <option value="1">CBSE</option>
                    <option value="2">ICSE</option> */}
                  </select>
                </div>
              </div>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={className}
                    onChange={(e) => {
                      setClassName(e.target.value);
                    }}
                  >
                    <option value="">Select Class</option>
                    {props.classList.map((item) => {
                      return (
                        <option value={item.class_no}>{item.class_no}</option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={appeared}
                    onChange={(e) => {
                      setAppeared(e.target.value);
                    }}
                  >
                    <option value="">Select Appeared</option>
                    <option value="1">Whether attempted Rest Exam</option>
                    <option value="2">
                      Whether accessed e-library and accessed A.I part
                    </option>
                    <option value="3">Whether accessed only e-library</option>
                    <option value="4">Whether Visited Live Classes</option>
                  </select>
                </div>
              </div>

              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={subscription}
                    onChange={(e) => {
                      setSubscription(e.target.value);
                    }}
                  >
                    <option value="">Select Subscription Status</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>
              </div>
              <div className=" me-2" style={{ width: "200px" }}>
                <input
                  className="form-control filter-form-control"
                  placeholder="Enter location pin"
                  value={typedPin}
                  onChange={(e) => {
                    setTypedPin(e.target.value);
                  }}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col px-1 ">
                  <button onClick={resetHandler} className={`btn btn-secondary ${period == '' && regVia == '' && board == '' && className == '' && appeared == ''  && subscription == '' && typedPin == '' ? 'content_disable' : null}`}>Reset</button>
                </div>

                <button
                  class="btn btn-primary"
                  onClick={() => {
                    setPin(typedPin);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <Card.Body>


              {props.list.length == 0 ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>DOB</strong>
                        </th>
                        <th>
                          <strong>Gender</strong>
                        </th>
                        <th>
                          <strong>Mobile No</strong>
                        </th>
                        <th>
                          <strong>Email Id</strong>
                        </th>
                        <th>
                          <strong>Location Pin</strong>
                        </th>
                        <th>
                          <strong>School Name</strong>
                        </th>
                        <th>
                          <strong>School Address</strong>
                        </th>
                        <th>
                          <strong>Board</strong>
                        </th>
                        <th>
                          <strong>Class</strong>
                        </th>
                        <th>
                          <strong>Subscription</strong>
                        </th>
                        {/* <th>
                      <strong>
                        Info &nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      </strong>
                    </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.list.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.fname + " " + item.lname}</td>
                            <td>{item.dob}</td>
                            <td>{item.gender}</td>
                            <td>{item.mobile}</td>
                            <td>{item.email}</td>
                            <td>{item.pincode}</td>
                            <td>{item.school_name}</td>
                            <td>{item.school_address}</td>
                            <td>{item.board}</td>
                            <td>{item.standard}</td>
                            <td>{item.subscription_details == 1 ? "Yes" : "No"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>)}

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

// export default MisRegUser;

const mapStateToProps = (state) => {
  return {
    list: state.MisRegUser.list,
    classList: state.class.list,
    boardList: state.board.list,
  };
};

export default connect(mapStateToProps)(MisRegUser);
