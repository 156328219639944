import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import swal from "sweetalert";
import Moment from "react-moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
  Pagination,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getQuestionPattern } from "../../../store/actions/QuestionPatternAction";
import globalConfigs from "../../../configs/GlobalConfigs";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getExamSubType,
  loadingSubTypeAction,
} from "../../../store/actions/ExamSubTypeAction";
import {
  getNonGroupSubjectsList,
  subjectLoading,
  getNonGroupSubjectsAction,
} from "../../../store/actions/SujectActions";
import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  loadingContentAction,
  approvedMultipleQuestionData,
  removeContentData,
  getContentList,
  getUpdateContentData,
  updateApproveContentData,
  downloadQuetionFile,
  removeMultipleQuestionData,
} from "../../../store/actions/ExamContentAction";

const ExamContentList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();


  const previousFilterData = props?.location.state;

  const [active, setActive] = useState(previousFilterData?.active ?? 1);
  const [questiono, setQuestiono] = useState(previousFilterData?.questiono ?? '');
  const [status, setStatus] = useState(previousFilterData?.status ?? '');
  /* ---- */
  const [demo, setDemo] = useState(previousFilterData?.filterObj?.demo ?? '');
  const [category, setCategory] = useState(previousFilterData?.filterObj?.category ?? '');
  const [board, setBoard] = useState(previousFilterData?.filterObj?.board ?? '');
  const [type, setType] = useState(previousFilterData?.filterObj?.type ?? '');
  const [subtype, setSubtype] = useState(previousFilterData?.filterObj?.subtype ?? '');
  const [className, setClassName] = useState(previousFilterData?.filterObj?.className ?? '');
  const [subject, setSubject] = useState(previousFilterData?.filterObj?.subject ?? '');
  const [chapter, setChapter] = useState(previousFilterData?.filterObj?.chapter ?? '');
  const [questionType, setQuestionType] = useState(previousFilterData?.filterObj?.questionType ?? '');


  const [checkArray, setCheckArray] = useState([]);

  const [modal, setModal] = useState(false);
  const chackbox = document.querySelectorAll(".checkbox-select input");
  const motherChackBox = document.querySelector("#checkAll");

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState();
  const firstTimeCallStartPage = useRef(true);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    permissionCheck();
    dispatch(getQuestionPattern());
    /* if (firstTimeCallStartPage.current) {
      dispatch(globalLoadingAction(true));
      dispatch(
        getContentList(
          parseInt(active),
          parseInt(demo) == 10 ? "" : demo,
          category ? parseInt(category) : "",
          parseInt(category) == 1 ? board : "",
          parseInt(category) == 2 ? type : "",
          parseInt(type) == 1 ? subtype : "",
          subject,
          parseInt(type) !== 1 ? className : "",
          chapter,
          questiono,
          status == "5" ? "" : status,
          questionType,
          props.history
        )
      );
    } */

    setTotalPage(props.totalRecord / pageRangeDisplayed);

    if (firstTimeCallStartPage.current) {
      setStart((page - 1) * pageRangeDisplayed + 1);
      setEnd(pageRangeDisplayed * page);
    }
    firstTimeCallStartPage.current = false;
    // page, start, end, props.totalRecord
  }, [page, start, end, props.totalRecord]);

  useEffect(() => {
    getCategoryHandler();
    getBoardHandler();
    getExamtypeHandler();
    getSubtypeHandler();
    getClassHandler();
    getSubjectHandler();
    getChapterHandler();
  }, [previousFilterData]);

  useEffect(() => {
    if (questiono != '') {
      onQuestionHandler();
    } else {
      dispatch(globalLoadingAction(true));
      dispatch(
        getContentList(
          parseInt(active),
          parseInt(demo) == 10 ? "" : demo,
          category ? parseInt(category) : "",
          parseInt(category) == 1 ? board : "",
          parseInt(category) == 2 ? type : "",
          parseInt(type) == 1 ? subtype : "",
          subject,
          parseInt(type) !== 1 ? className : "",
          chapter,
          questiono,
          status == "5" ? "" : status,
          questionType,
          props.history
        )
      );
    }
  }, [active, status, demo, category, board, type, subtype, className, subject, chapter, questionType]);

  /* filter function */
  const filterQuestionHandler = () => {
    setModal(false);
    setQuestiono('');
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        parseInt(active),
        parseInt(demo) == 10 ? "" : demo,
        category ? parseInt(category) : "",
        parseInt(category) == 1 ? board : "",
        parseInt(category) == 2 ? type : "",
        parseInt(type) == 1 ? subtype : "",
        subject,
        parseInt(type) !== 1 ? className : "",
        chapter,
        questiono,
        status == "5" ? "" : status,
        questionType,
        props.history
      )
    );
    setActive(1);
  };

  /* get question list */
  const onQuestionHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        questiono.trim(),
        "",
        "",
        props.history
      )
    );
    setActive(1);
    const stateCopy = { ...props?.location.state };
		delete stateCopy.questiono;
		props.history.replace({ state: stateCopy });
  };

  /* status handler */
  const onChangeStatusHandler = (val) => {
    clearCheckBoxes();

    setStatus(val);
    /* dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        1,
        parseInt(demo) == 10 ? "" : demo,
        category ? parseInt(category) : "",
        parseInt(category) == 1 ? board : "",
        parseInt(category) == 2 ? type : "",
        parseInt(type) == 1 ? subtype : "",
        subject,
        parseInt(type) !== 1 ? className : "",
        chapter,
        questiono,
        val == "5" ? "" : val,
        questionType,
        props.history
      )
    ); */
    setActive(1);
  };

  /* checkbox clear */
  const clearCheckBoxes = () => {
    setCheckArray([]);
    motherChackBox.checked = false;
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
  };

  /* delete confirmation */
  const onDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.list.length == 1) {
          dispatch(removeContentData(id, parseInt(active) - 1, props.history));
          setActive(parseInt(active) - 1);
        } else {
          dispatch(removeContentData(id, parseInt(active), props.history));
        }
      }
    });
  };


  const onUpdateApprove = (item) => {
    dispatch(globalLoadingAction(true));
    dispatch(updateApproveContentData(item, props.history));
    setCheckArray([]);
    motherChackBox.checked = false;
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
  };

  const onEdit = (item) => {
    let obj = {
      active,
      demo,
      category,
      board,
      type,
      subtype,
      subject,
      className,
      chapter,
      questiono,
      status,
      currentPage: 1,
      id: item.id,
      filterObj: {
        demo,
        category,
        board,
        type,
        subtype,
        className,
        subject,
        chapter,
        questionType,
      },
    };
    history.push({
      pathname: "/update-question",
      state: obj,
    });
  };

  /* go to add question screen */
  const addQuestionHandler = () => {
    props.history.push("/add-question");
  };

  /* on download file */
  const onDownloadFile = (load) => {
    dispatch(downloadQuetionFile(load, props.history));
  };

  /* select check boad */
  const chackboxFun = (element, item) => {
    let array = [...checkArray];

    if (item === 0) {
      if (motherChackBox.checked == true) {
        props.list.forEach((val, index) => {
          array.push(val.id);
          chackbox[index].checked = true;
        });
      } else {
        props.list.forEach((val, index) => {
          array = [];
          chackbox[index].checked = false;
        });
      }
    } else {
      if (element.target.checked == true) {
        for (let x = 0; x < chackbox.length; x++) {
          if (!chackbox[x].checked == true) {
            motherChackBox.checked = false;
            break;
          } else {
            motherChackBox.checked = true;
          }
        }
        array.push(item.id);
      } else {
        motherChackBox.checked = false;
        const index = array.findIndex((val) => val === item.id);
        array.splice(index, 1);
      }
    }
    setCheckArray(array);
  };


  const onApprpvedHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(approvedMultipleQuestionData(checkArray, 1, props.history));
    setCheckArray([]);
    motherChackBox.checked = false;
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
  };

  /* delete confirmation */
  const onDeleteHandler = () => {
    //console.log('hello world', checkArray);
    //return;
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.list.length == 1) {
          dispatch(
            removeMultipleQuestionData(
              checkArray,
              1,
              parseInt(active) - 1,
              props.history
            )
          );
          setActive(parseInt(active) - 1);
        } else {
          dispatch(
            removeMultipleQuestionData(
              checkArray,
              1,
              parseInt(active),
              props.history
            )
          );
        }
      }
    });
    setCheckArray([]);
    motherChackBox.checked = false;
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
  };

  /* get category list */
  const getCategoryHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingToggleAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  /* on change category */
  const onCategoryHandler = (value) => {
    setCategory(value);
    setBoard("");
    setType("");
    setSubtype("");
    setSubject("");
    setClassName("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
    dispatch(getChaptersDeffarentsParamsAction([]));
  };


/* get board list */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };
  const onBoardHandler = (value) => {
    setBoard(value);
    setType("");
    setSubtype("");
    setSubject("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* get exam type list */
  const getExamtypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingAction(true));
      dispatch(getExamType(props.history));
    }
  };

  /* on exam type change */
  const onExamTypeHandler = (val) => {
    setType(val);
    setBoard("");
    setSubtype("");
    setSubject("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* get subject list */
  const getSubtypeHandler = () => {
    if (props.subTypeList.length == 0) {
      dispatch(loadingSubTypeAction(true));
      dispatch(getExamSubType(props.history));
    }
  };

  /* on change subtype  */
  const onSubtypeTypeHandler = (val) => {
    setSubtype(val);
    setSubject("");
    setChapter("");
    setClassName("");
    dispatch(getNonGroupSubjectsAction([]));
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* on change subject */
  const getSubjectHandler = () => {
    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && type == 1 && subtype) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type),
          parseInt(subtype),
          0,
          props.history
        )
      );
    } else if (category == 2 && type == 2) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type),
          0,
          0,
          props.history
        )
      );
    }
  };

  /* on class list */
  const getClassHandler = () => {
    if (props.classList.length == 0) {
      dispatch(loadingClassAction(true));
      dispatch(getClassData(props.history));
    }
  };

  /* on change class */
  const onClassHandler = (value) => {
    setClassName(value);
    setChapter("");
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* get chapter list */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          className,
          props.history
        )
      );
    } else if (category == 2 && type == 1 && subtype) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          type,
          subtype,
          subject,
          0,
          props.history
        )
      );
    } else if (category == 2 && type == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          type,
          0,
          subject,
          className,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* on clear filter */
  const onClearHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        props.history
      )
    );
    setActive(1);
    setQuestiono("");
  };

  /* on clear filter */
  const onResetHandler = () => {
    //setModal(false);
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        1,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        status == "5" ? "" : status,
        "",
        props.history
      )
    );
    setActive(1);
    setDemo("");
    setCategory("");
    setBoard("");
    setType("");
    setSubtype("");
    setSubject("");
    setClassName("");
    setChapter("");
    setQuestionType("");
  };

  /* pagination */
  const handlePageClick = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentList(
        data.selected + 1,
        parseInt(demo) == 10 ? "" : demo,
        category ? parseInt(category) : "",
        parseInt(category) == 1 ? board : "",
        parseInt(category) == 2 ? type : "",
        parseInt(type) == 1 ? subtype : "",
        subject,
        parseInt(type) !== 1 ? className : "",
        chapter,
        questiono,
        status == "5" ? "" : status,
        questionType,
        props.history
      )
    );

    //motherChackBox.checked = false;
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
    setCheckArray([]);

    setStart((data.selected + 1 - 1) * pageRangeDisplayed + 1);
    setEnd((data.selected + 1) * pageRangeDisplayed);
    if ((data.selected + 1) * pageRangeDisplayed > props.totalRecord) {
      setEnd(props.totalRecord);
    }
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 48) {
        setPermission(item);
      }
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="add-question-view">
                <div className="hear-btn-content">
                  <button
                    onClick={() => onApprpvedHandler()}
                    className="btn btn-success me-2"
                    disabled={checkArray.length > 0 ? false : true}
                  >
                    Approved
                  </button>
                  <button
                    onClick={() => onDeleteHandler()}
                    className="btn btn-danger me-2"
                    disabled={checkArray.length > 0 ? false : true}
                  >
                    Delete
                  </button>
                </div>
                <div className="add-filter-group">
                  <div className="me-2">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        defaultValue={"option"}
                        className="form-control filter-form-control-input"
                        value={status}
                        onChange={(e) => onChangeStatusHandler(e.target.value)}
                      >
                        <option value="">Select status</option>
                        <option value="1">Approve</option>
                        <option value="0">Pending</option>
                      </select>
                    </div>
                  </div>
                  <div className="clear-question-type">
                    <input
                      className="form-control filter-form-control-input me-2"
                      placeholder="Enter question No"
                      value={questiono}
                      onChange={(e) => {
                        setQuestiono(e.target.value);
                        clearCheckBoxes();
                      }}
                    />
                    {questiono ? (
                      <button
                        onClick={onClearHandler}
                        className="clear-date-btn"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    ) : null}
                  </div>
                  <button
                    disabled={!questiono}
                    onClick={() => onQuestionHandler()}
                    className={`btn btn-danger me-2 ${!questiono ? 'content_disable' : null}`}
                  >
                    Search
                  </button>
                  <button
                    onClick={() => addQuestionHandler()}
                    className="btn btn-success me-2"
                  >
                    Add Question
                  </button>
                  <button
                    onClick={() => {
                      setModal(true);
                      clearCheckBoxes();
                    }}
                    className="btn btn-secondary me-2"
                  >
                    Filter
                  </button>
                </div>
              </div>

              {props.list.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="customer_shop">
                        <div className="form-check custom-checkbox mx-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            onClick={(e) => chackboxFun("all", 0)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>Question for</strong>
                      </th>
                      <th>
                        <strong>Exam Category</strong>
                      </th>
                      <th>
                        <strong>Board/Type</strong>
                      </th>
                      <th>
                        <strong>Sub Type</strong>
                      </th>
                      <th>
                        <strong>Subject</strong>
                      </th>
                      <th>
                        <strong>Chapter</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong> Question Type</strong>
                      </th>
                      <th>
                        <strong>CSS Group ID</strong>
                      </th>
                      <th>
                        <strong>Question No</strong>
                      </th>

                      {permission.status == "true" && (
                        <th>
                          <strong>Status</strong>
                        </th>
                      )}
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="form-check custom-checkbox checkbox-select mx-2">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`check${index}`}
                                onClick={(e) => chackboxFun(e, item)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`check${index}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            {(() => {
                              switch (parseInt(item.demo_exam)) {
                                case 0:
                                  return "Live";
                                case 1:
                                  return "Guest Demo";
                                case 2:
                                  return "Register Demo";
                                default:
                                  return null;
                              }
                            })()}
                          </td>
                          <td>{item.category}</td>
                          <td>{item.exam_type}</td>
                          <td>{item.exam_subtype != '' ? item.exam_subtype : '-'}</td>
                          <td>{item.branch}</td>
                          <td>{item.chapter}</td>
                          <td>{item.class}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.question_type}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.css_group_id ? item.css_group_id : "-"}
                          </td>
                          <td>{item.question_no}</td>

                          {permission.status == "true" && (
                            <td>
                              <Button
                                //disabled={props.showLoading}
                                className="btn-xs"
                                variant={
                                  item.is_approve == 1 ? "success" : "danger"
                                }
                                onClick={() => onUpdateApprove(item)}
                              >
                                {item.is_approve == 1 ? "Approve" : "Pending"}
                              </Button>
                            </td>
                          )}
                          <td>
                            <div className="d-flex">
                              <button
                                className={`btn btn-secondary btn-xs sharp me-1 ${item.upload_path ? null : 'content_disable'}`}
                                onClick={() => onDownloadFile(item.upload_path)}
                              >
                                <i class="fa-solid fa-download"></i>
                              </button>

                              {permission.edit == "true" && (
                                <button
                                  className="btn btn-warning btn-xs sharp me-1"
                                  onClick={() => onEdit(item)}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                              )}

                              {permission.delete == "true" && (
                                <Button
                                  className="btn btn-danger btn-xs sharp"
                                  onClick={() => onDelete(item.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              )}

              <div className="pagination-container">
                <div className="page-result">
                  {props.totalRecord !== 0 && (<>
                    <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}  items</p>
                    {/* <p>
                      {start +
                        "-" +
                        end +
                        " of " +
                        props.totalRecord +
                        " items"}
                    </p> */}
                  </>
                  )}
                </div>
                {props.totalRecord > 10 ?
                  <ReactPaginate
                    containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    nextLabel={<i class="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousLabel={<i class="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={props.totalPage}
                    //initialPage={active - 1}
                    forcePage={active - 1}
                  />
                  : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- Modal --> */}
        <Modal className="fade" show={modal} size="md">
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">
              <div className="reset-filtter-value">
                <button
                  className="btn btn-info btn-xs"
                  onClick={() => onResetHandler()}
                >
                  Reset
                </button>
              </div>

              <div className="row">
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={demo}
                      onChange={(e) => setDemo(e.target.value)}
                    >
                      <option value="">Select question</option>
                      {/* <option value="10">All</option> */}
                      <option value="3">Live</option>
                      <option value="1">Guest Demo</option>
                      <option value="2">Register Demo</option>
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.categoryLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={category}
                      onClick={getCategoryHandler}
                      onChange={(e) => onCategoryHandler(e.target.value)}
                    >
                      <option value="">Select category</option>
                      {props.categoryList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.boardLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className={`form-control ${category == 1 ? null : 'content_disable'}`}
                      // disabled={category == 1 ? false : true}
                      value={board}
                      onClick={getBoardHandler}
                      onChange={(e) => onBoardHandler(e.target.value)}
                    >
                      <option value="">Select board</option>
                      {props.boardList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.typeLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className={`form-control ${category == 2 ? null : 'content_disable'}`}
                      // disabled={category == 2 ? false : true}
                      value={type}
                      onClick={getExamtypeHandler}
                      onChange={(e) => onExamTypeHandler(e.target.value)}
                    >
                      <option value="">Select exam type</option>
                      {props.typeList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.subTypeLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className={`form-control ${category == 2 && type == 1 ? null : 'content_disable'}`}
                      // disabled={category == 2 && type == 1 ? false : true}
                      value={subtype}
                      onClick={getSubtypeHandler}
                      onChange={(e) => onSubtypeTypeHandler(e.target.value)}
                    >
                      <option value="">Select Sub type</option>
                      {props.subTypeList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.subtype_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.classLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={className}
                      onClick={getClassHandler}
                      onChange={(e) => onClassHandler(e.target.value)}
                    >
                      <option value="">Select class</option>
                      {props.classList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.short_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}

                      className={`form-control ${(category && board) || (category && type) ? null : 'content_disable'}`}
                      // disabled={(category && board) || (category && type) ? false : true }
                      value={subject}
                      onClick={getSubjectHandler}
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <option value="">Select subjects</option>
                      {props.subjectList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.chapterLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={chapter}
                      onClick={getChapterHandler}
                      onChange={(e) => setChapter(e.target.value)}
                    >
                      <option value="">Select chapter</option>
                      {props.chapterList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.chapter_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mt-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      {/* {props.chapterLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={questionType}
                      // onClick={getChapterHandler}
                      onChange={(e) => setQuestionType(e.target.value)}
                    >
                      <option value="">Select question type</option>
                      {props.questionTypes.map((item, index) => {
                        return (
                          <option key={index} value={item.short_code}>
                            {item.short_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModal(false)} variant="danger light">
              Cancel
            </Button>
            <Button onClick={() => filterQuestionHandler()} variant="primary">
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.content.showLoading,
    list: state.content.contentList,
    totalPage: state.content.totalPage,
    totalRecord: state.content.totalQuestionRecord,
    categoryLoading: state.category.showLoading,
    categoryList: state.category.list,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeLoading: state.type.showLoading,
    typeList: state.type.list,
    subTypeLoading: state.subtype.showLoading,
    subTypeList: state.subtype.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.nonGroupSubjectList,
    classLoading: state.class.showLoading,
    classList: state.class.list,
    chapterLoading: state.chapter.showLoading,
    chapterList: state.chapter.typelist,
    globalLoader: state.global.showLoading,
    branchLoading: state.branch.showLoading,
    questionTypes: state.pattern.list,
  };
};

export default connect(mapStateToProps)(ExamContentList);
