import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import globalConfigs from '../../../configs/GlobalConfigs';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { Alert, Button, Badge, Media } from "react-bootstrap";

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { uploadFile, loadingContentAction, duplicateQuestionsStatusAction } from '../../../store/actions/ExamContentAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getNonGroupSubjectsList, subjectLoading, getNonGroupSubjectsAction } from '../../../store/actions/SujectActions';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getChaptersDeffarentsParamsData, loadingChaptersAction, getChaptersDeffarentsParamsAction } from '../../../store/actions/ChapterAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';

const CreateExamContent = (props) => {

	const fileRef = useRef();

	let errorsObj = { category: '', board: '', type: '', subtype: '', className: '', subject: '', chapter: '', file: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [isCheck, setIsCheck] = useState(false);
	const [category, setCategory] = useState('');
	const [board, setBoard] = useState('');
	const [type, setType] = useState('');
	const [typeFullString, setTypeFullString] = useState('');
	const [subType, setSubType] = useState('');
	const [className, setClassName] = useState('');
	const [subject, setSubject] = useState('');
	const [chapter, setChapter] = useState('');
	const [file, setFile] = useState(null);
	const [radioVal, setRadioVal] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (category === '') {
			errorObj.category = 'Exam category is required';
			error = true;
		}
		if ((radioVal == 0 && board === '' && category == 1) || (radioVal == 2 && category == 1 && board === '')) {
			errorObj.board = 'Board is required';
			error = true;
		}
		if (radioVal == 0 && type === '' && category == 2) {
			errorObj.type = 'Exam type is required';
			error = true;
		}
		if (subType === '' && type == 1) {
			errorObj.subtype = 'Exam type is required';
			error = true;
		}
		if (subject === '' && parseInt(radioVal) == 0) {
			errorObj.subject = 'Subject is Required';
			error = true;
		}
		if (((radioVal == 0) && (className === '') && (category == 1 || type == 2)) || (radioVal == 2 && className === '')) {
			errorObj.className = 'Class name is required';
			error = true;
		}
		if (chapter === '' && parseInt(radioVal) == 0) {
			errorObj.chapter = 'Chapter is Required';
			error = true;
		}
		if (file === null) {
			errorObj.file = 'File is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}



		dispatch(globalLoadingAction(true));

		dispatch(uploadFile(
			parseInt(category),
			((parseInt(radioVal) == 0 && parseInt(category) == 1) || (parseInt(radioVal) == 2 && parseInt(category) == 1)) ? parseInt(board) : 0,
			parseInt(radioVal) == 0 && parseInt(category) == 2 ? parseInt(type) : 0,
			parseInt(radioVal) == 0 && parseInt(type) == 1 ? subType : 0,
			((parseInt(radioVal) == 0 && (parseInt(category) == 1 || parseInt(type) == 2)) || parseInt(radioVal) == 2) ? className : 0,
			parseInt(radioVal) == 0 ? subject.split("^")[0] : 0,
			parseInt(radioVal) == 0 ? subject.split("^")[1] : 0,
			parseInt(radioVal) == 0 ? chapter.split("^")[0] : 0,
			parseInt(radioVal) == 0 ? chapter.split("^")[1] : 0,
			parseInt(radioVal),
			file,
			props.history));

		setIsCheck(false);
		fileRef.current.value = null;
		setChapter('');
		setSubject('');
		setClassName('');
		setType('');
		setCategory('');
	}

	/* file set into state */
	const handleChange = (event) => {
		setFile(event.target.files[0]);
	}

	/* change radio */
	const onChangeRadioHandler = (event) => {
		setRadioVal(event.target.value);
	}

	/* get category list */
	const getCategoryHandler = () => {
		if (props.categoryList.length == 0) {
			dispatch(loadingToggleAction(true));
			dispatch(getExamCategory(props.history));
		}
	}

	/* category change */
	const onCategoryHandler = (val) => {
		setCategory(val);
		setType('');
		setBoard('');
		setSubType('');
		dispatch(getNonGroupSubjectsAction([]));
	}

	/* get board list */
	const getBoardHandler = () => {
		if (props.boardList.length == 0) {
			dispatch(loadingBoardAction(true));
			dispatch(getBoardData(props.history));
		}
	}

	/* get board list */
	const onBoardHandler = (val) => {
		setBoard(val);
		dispatch(getNonGroupSubjectsAction([]));
	}

	/* get exam type list */
	const getExamtypeHandler = () => {

		if (props.typeList.length == 0) {
			dispatch(loadingAction(true));
			dispatch(getExamType(props.history));
		}
	}

	/* get exam type list */
	const onExamTypeHandler = (val) => {
		setType(val.split("^")[0]);
		setTypeFullString(val)

		setSubType('');
		dispatch(getNonGroupSubjectsAction([]));
		dispatch(getChaptersDeffarentsParamsAction([]));
	}

	/* get subtype list */
	const getSubtypeHandler = () => {
		if (props.subTypeList.length == 0) {
			dispatch(loadingSubTypeAction(true));
			dispatch(getExamSubType(props.history));
		}
	}

	/* get subtype change */
	const onSubtypeTypeHandler = (val) => {
		setSubType(val);
		dispatch(getNonGroupSubjectsAction([]));
	}

	/* get subject list */
	const getSubjectHandler = () => {
		if (category == 1 && board) {
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(parseInt(category), 0, 0, parseInt(board), props.history));
		} else if ((category == 2) && (type == 1 && subType)) {
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(parseInt(category), parseInt(type), parseInt(subType), 0, props.history));
		} else if (category == 2 && type != 1) {
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(parseInt(category), parseInt(type), 0, 0, props.history));
		}
	}

	/* get class list */
	const getClassHandler = () => {
		if (props.classList.length == 0) {
			dispatch(loadingClassAction(true));
			dispatch(getClassData(props.history));
		}
	}

	/* get chapter list */
	const getChapterHandler = () => {
		if (category == 1 && board) {
			dispatch(loadingChaptersAction(true));
			dispatch(getChaptersDeffarentsParamsData(parseInt(category), board, 0, 0, subject.split("^")[0], className, props.history));
		} else if ((category == 2) && (type == 1 && subType)) {
			dispatch(loadingChaptersAction(true));
			dispatch(getChaptersDeffarentsParamsData(parseInt(category), 0, type, subType, subject.split("^")[0], 0, props.history));
		}
		else if (category == 2 && type != 1) {
			dispatch(loadingChaptersAction(true));
			dispatch(getChaptersDeffarentsParamsData(parseInt(category), 0, type, 0, subject.split("^")[0], className, props.history));
		} else {
			dispatch(getChaptersDeffarentsParamsAction([]));
		}
	}

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }} className="col-xl-12 mb-2">
				<div className="school-tem-btn-view">

					<i class="fa-regular fa-file-zipper fa-xl" style={{ marginRight: '10px', color: '#962424' }}></i>
					<a href={globalConfigs.SAMPLE_CSS}><strong>Download CSS</strong></a>
				</div>
				<div className="school-tem-btn-view">

					<i class="fa-regular fa-file-zipper fa-xl" style={{ marginRight: '10px', color: '#962424' }}></i>
					<a href={globalConfigs.EXCEL_SAMPLE_QUESTIONS}><strong>Download Sample Questions</strong></a>
				</div>
			</div>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div>
										{props.duplicateQuestionsStatus == 0 ?
											null
											:
											<Alert
												variant="warning"
												className="alert-dismissible fade show me-5 ms-5"
											>
												<span className="duplicate_questions_icon"><i class="mdi mdi-alert"></i></span>
												<strong>Duplicate questions exist in this excel : </strong>{props.duplicateQuestions}
												<button type="button" onClick={() => dispatch(duplicateQuestionsStatusAction(0))} ata-dismiss="alert" aria-label="btn-close" class="btn-close  btn"></button>
											</Alert>
										}

									</div>
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam category name<span className="text-danger">*</span></strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow add-Question-arrow-position">
																{props.categoryLoading ?
																	<Spinner animation="border" size="sm" />
																	: <i className="bi bi-caret-down-fill"></i>}
															</span>
															<select
																defaultValue={"option"}
																className="form-control form-control"
																value={category}
																onClick={getCategoryHandler}
																onChange={(e) => onCategoryHandler(e.target.value)}
															>
																<option value="">Select category</option>
																{
																	props.categoryList.map((item, index) => {
																		return <option key={index} value={item.id}>{item.category}</option>
																	})
																}
															</select>
														</div>
														{errors.category && <div className="text-danger fs-12">{errors.category}</div>}
													</div>

													<div onChange={onChangeRadioHandler} className="content-radio-view">
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="gridRadios"
																value="0"
																defaultChecked
																id="live"
															/>
															<label className="form-check-label" htmlFor="live">
																Live
															</label>
														</div>
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="gridRadios"
																value="1"
																id="guest"
															/>
															<label className="form-check-label" htmlFor="guest">
																Guest Demo
															</label>
														</div>
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="gridRadios"
																value="2"
																id="register"
															/>
															<label className="form-check-label" htmlFor="register">
																Register Demo
															</label>
														</div>
													</div>
													
													{parseInt(radioVal) == 0 ?
														<>
															{category == 1 ?
																<div className="form-group mb-3">
																	<label className="mb-2">
																		<strong>Board<span className="text-danger">*</span></strong>
																	</label>
																	<div className="basic-dropdown">
																		<span className="drop_down_arrow add-Question-arrow-position">
																			{props.boardLoading ?
																				<Spinner animation="border" size="sm" />
																				: <i className="bi bi-caret-down-fill"></i>}
																		</span>
																		<select
																			defaultValue={"option"}
																			className="form-control form-control"
																			value={board}
																			onClick={getBoardHandler}
																			onChange={(e) => onBoardHandler(e.target.value)}
																		>

																			<option value="">Select board</option>
																			{
																				props.boardList.map((item, index) => {
																					return <option key={index} value={item.id}>{item.name}</option>
																				})
																			}

																		</select>
																	</div>
																	{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
																</div>
																:
																<>
																	<div className={`form-group mb-3 ${category == '' || category == 3 ? 'content_disable' : null}`}>
																		<label className="mb-2">
																			<strong>Exam type<span className="text-danger">*</span></strong>
																		</label>
																		<div className="basic-dropdown">
																			<span className="drop_down_arrow add-Question-arrow-position">
																				{props.typeLoading ?
																					<Spinner animation="border" size="sm" />
																					: <i className="bi bi-caret-down-fill"></i>}
																			</span>
																			<select
																				defaultValue={"option"}
																				className="form-control form-control"
																				value={typeFullString}
																				onClick={getExamtypeHandler}
																				onChange={(e) => onExamTypeHandler(e.target.value)}
																			>

																				<option value="">Select exam type</option>
																				{
																					props.typeList.map((item, index) => {
																						return <option key={index} value={item.id + "^" + item.is_class}>{item.type_name}</option>
																					})
																				}

																			</select>
																		</div>
																		{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
																	</div>

																	{type == 1 ?
																		<div className="form-group mb-3">
																			<label className="mb-2">
																				<strong>Exam Sub Type<span className="text-danger">*</span></strong>
																			</label>
																			<div className="basic-dropdown">
																				<span className="drop_down_arrow add-Question-arrow-position">
																					{props.subTypeLoading ?
																						<Spinner animation="border" size="sm" />
																						: <i className="bi bi-caret-down-fill"></i>}
																				</span>
																				<select
																					defaultValue={"option"}
																					className="form-control form-control"
																					value={subType}
																					onClick={getSubtypeHandler}
																					onChange={(e) => onSubtypeTypeHandler(e.target.value)}
																				>
																					<option value="">Select Sub type</option>
																					{
																						props.subTypeList.map((item, index) => {
																							return <option key={index} value={item.id}>{item.subtype_name}</option>
																						})
																					}
																				</select>
																			</div>
																			{errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
																		</div>
																		: null}
																</>
															}

															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Subjects<span className="text-danger">*</span></strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow add-Question-arrow-position">
																		{props.subjectLoading ?
																			<Spinner animation="border" size="sm" />
																			: <i className="bi bi-caret-down-fill"></i>}
																	</span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={subject}
																		onClick={getSubjectHandler}
																		onChange={(e) => setSubject(e.target.value)}
																	>
																		<option value="">Select subjects</option>
																		{
																			props.subjectList.map((item, index) => {
																				return <option key={index} value={item.id + "^" + item.subject_code}>{item.name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
															</div>


															{category == 1 || typeFullString.split("^")[1] == 1 ?

																<div className="form-group mb-3">
																	<label className="mb-2">
																		<strong>Class<span className="text-danger">*</span></strong>
																	</label>
																	<div className="basic-dropdown">
																		<span className="drop_down_arrow add-Question-arrow-position">
																			{props.classLoading ?
																				<Spinner animation="border" size="sm" />
																				: <i className="bi bi-caret-down-fill"></i>}
																		</span>
																		<select
																			defaultValue={"option"}
																			className="form-control form-control"
																			value={className}
																			onClick={getClassHandler}
																			onChange={(e) => setClassName(e.target.value)}
																		>
																			<option value="">Select class</option>
																			{
																				props.classList.map((item, index) => {
																					return <option key={index} value={item.id}>{item.short_code}</option>
																				})
																			}

																		</select>
																	</div>
																	{errors.className && <div className="text-danger fs-12">{errors.className}</div>}
																</div>
																: null
															}

															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Chapter<span className="text-danger">*</span></strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow add-Question-arrow-position">
																		{props.chapterLoading ?
																			<Spinner animation="border" size="sm" />
																			: <i className="bi bi-caret-down-fill"></i>}
																	</span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={chapter}
																		onClick={getChapterHandler}
																		onChange={(e) => setChapter(e.target.value)}
																	>
																		<option value="">Select chapter</option>
																		{
																			props.chapterList.map((item, index) => {
																				return <option key={index} value={item.id + '^' + item.short_code}>{item.chapter_name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.chapter && <div className="text-danger fs-12">{errors.chapter}</div>}
															</div>
														</>
														: null}

													{
														parseInt(radioVal) == 2 && (
															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Class<span className="text-danger">*</span></strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow add-Question-arrow-position">
																		{props.classLoading ?
																			<Spinner animation="border" size="sm" />
																			: <i className="bi bi-caret-down-fill"></i>}
																	</span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={className}
																		onClick={getClassHandler}
																		onChange={(e) => setClassName(e.target.value)}
																	>
																		<option value="">Select class</option>
																		{
																			props.classList.map((item, index) => {
																				return <option key={index} value={item.id}>{item.short_code}</option>
																			})
																		}

																	</select>
																</div>
																{errors.className && <div className="text-danger fs-12">{errors.className}</div>}
															</div>
														)
													}

													{
														((parseInt(radioVal) == 2 && category == 1)) && (
															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Board<span className="text-danger">*</span></strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow add-Question-arrow-position">
																		{props.boardLoading ?
																			<Spinner animation="border" size="sm" />
																			: <i className="bi bi-caret-down-fill"></i>}
																	</span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={board}
																		onClick={getBoardHandler}
																		onChange={(e) => onBoardHandler(e.target.value)}
																	>

																		<option value="">Select board</option>
																		{
																			props.boardList.map((item, index) => {
																				return <option key={index} value={item.id}>{item.name}</option>
																			})
																		}

																	</select>
																</div>
																{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
															</div>
														)
													}

													<div className="mb-3">
														<label htmlFor="formFileDisabled" className="form-label file-form-label">Select File<span className="text-danger">*</span></label>
														<input
															ref={fileRef}
															className="form-control"
															type="file"
															id="formFileDisabled"
															accept=".zip"
															onChange={handleChange} />
														{errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>}
													</div>


													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Upload
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.content.showLoading,
		categoryLoading: state.category.showLoading,
		categoryList: state.category.list,
		typeLoading: state.type.showLoading,
		typeList: state.type.list,
		subTypeLoading: state.subtype.showLoading,
		subTypeList: state.subtype.list,
		boardLoading: state.board.showLoading,
		boardList: state.board.list,
		subjectLoading: state.subject.showLoading,
		subjectList: state.subject.nonGroupSubjectList,
		classLoading: state.class.showLoading,
		classList: state.class.list,
		chapterLoading: state.chapter.showLoading,
		chapterList: state.chapter.typelist,
		patternList: state.pattern.list,
		duplicateQuestions: state.content.duplicateQuestions,
		duplicateQuestionsStatus: state.content.duplicateQuestionsStatus,
	};
};

export default connect(mapStateToProps)(CreateExamContent);