import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  validateShortCode,
  validateTextOnly,
  validateSubheading,
} from "../../../utility/Utility";
import {
  updateExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateExamCategory = (props) => {
  let history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const file = useRef();
  

  let errorsObj = {
    category: "",
    code: "",
    guestExam: "",
    registerUserExam: "",
    guestLibrary: "",
    registerLibrary: "",
    performanceScore: "",
    libraryPerformanceScore: "",
    photoLiveExam: "",
    photoLibrary: "",
    photoPerformance: "",
    photoPerformanceL: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [category, setCategory] = useState(state.category);
  const [code, setCode] = useState(state.short_code);
  const [guestExam, setGuestExam] = useState(state.onlineExam_guest);
  const [registerUserExam, setRegisteruserExam] = useState(state.onlineExam_register);
  const [guestLibrary, setGuestLibrary] = useState(state.e_library_guest);
  const [registerLibrary, setRegisterLibrary] = useState(state.e_library_register);
  const [performanceScore, setPerformanceScore] = useState(state.performance);
  const [libraryPerformanceScore, setLibraryPerformanceScore] = useState(state.performance_e_library);
  const [photoLiveExam, setPhotoLiveExam] = useState(state.photo_onlineexam);
  const [photoLiveExamFile, setPhotoLiveExamFile] = useState();
  const [photoLibrary, setPhotoLibrary] = useState(state.photo_e_library);
  const [photoLibraryFile, setPhotoLibraryFile] = useState("");
  const [photoPerformance, setPhotoPerformance] = useState(state.photo_performance);
  const [photoPerformanceFile, setPhotoPerformanceFile] = useState("");
  const [photoPerformanceL, setPhotoPerformanceL] = useState(state.photo_performance_e_library);
  const [photoPerformanceLFile, setPhotoPerformanceLFile] = useState("");

  useEffect(() => {
    // console.log("fetched values from previous page......",state)
    
  }, []);

  /* image upload */
  const handleChangeLiveExamPhoto = (event) => {
    setPhotoLiveExam(URL.createObjectURL(event.target.files[0]));
    setPhotoLiveExamFile(event.target.files[0]);
  };

/* image upload */
  const handleChangePhotoLibrary = (event) => {
    setPhotoLibrary(URL.createObjectURL(event.target.files[0]));
    setPhotoLibraryFile(event.target.files[0]);
  };

/* image upload */
  const handleChangePhotoPerformance = (event) => {
    setPhotoPerformance(URL.createObjectURL(event.target.files[0]));
    setPhotoPerformanceFile(event.target.files[0]);
  };

/* image upload */
  const handleChangePhotoPerformanceL = (event) => {
    setPhotoPerformanceL(URL.createObjectURL(event.target.files[0]));
    setPhotoPerformanceLFile(event.target.files[0]);
  };

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Exam category is Required";
      error = true;
    }
    if (validateTextOnly(category) === false) {
      errorObj.category = "Invalid Category";
      error = true;
    }
    if (code === "") {
      errorObj.code = "Exam Short code is Required";
      error = true;
    }
    if (validateShortCode(code) === false) {
      errorObj.code = "Invalid Short code";
      error = true;
    }
    if (guestExam === "") {
      errorObj.guestExam = "Guest user exam is Required";
      error = true;
    }
  
    if (registerUserExam === "") {
      errorObj.registerUserExam =
        "Register user exam is Required";
      error = true;
    }

    if (guestLibrary === "") {
      errorObj.guestLibrary =
        "e-library for guest user is Required";
      error = true;
    }
  
    if (registerLibrary === "") {
      errorObj.registerLibrary =
        "e-library for register user is Required";
      error = true;
    }
  
    if (performanceScore === "") {
      errorObj.performanceScore =
        "Performance score card is Required";
      error = true;
    }
  
    if (libraryPerformanceScore === "") {
      errorObj.libraryPerformanceScore =
        "Library performance score card is Required";
      error = true;
    }
    // if (photoLiveExam === "") {
    //   errorObj.photoLiveExam = "Photo of live exam is Required";
    //   error = true;
    // }
 
    // if (photoLibrary === "") {
    //   errorObj.photoLibrary = "Photo of scholastic e-library is Required";
    //   error = true;
    // }
   
    // if (photoPerformance === "") {
    //   errorObj.photoPerformance =
    //     "Photo of performance score is Required";
    //     error = true;
    // }
   
    // if (photoPerformanceL === "") {
    //   errorObj.photoPerformanceL =
    //     "Photo of e-library performance score is Required";
    //     error = true;
    // }


    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(updateExamCategory(state.id, category, code, guestExam,
      registerUserExam,
      guestLibrary,
      registerLibrary,
      performanceScore,
      libraryPerformanceScore,
      photoLiveExamFile,
      photoLibraryFile,
      photoPerformanceFile,
      photoPerformanceLFile, props.history));
    setCategory("");
    setCode("");
  }

  /* navigation go back */
  const onGoBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Exam category<span className="text-danger">*</span></strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the category name"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Exam short code<span className="text-danger">*</span></strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the short code"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                            />
                            {errors.code && (
                              <div className="text-danger fs-12">
                                {errors.code}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Online exam for guest<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter guest user exam"
                              value={guestExam}
                              onChange={(e) => setGuestExam(e.target.value)}
                            />
                            {errors.guestExam && (
                              <div className="text-danger fs-12">
                                {errors.guestExam}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Online exam for register user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter register user exam"
                              value={registerUserExam}
                              onChange={(e) =>
                                setRegisteruserExam(e.target.value)
                              }
                            />
                            {errors.registerUserExam && (
                              <div className="text-danger fs-12">
                                {errors.registerUserExam}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>e-library for guest user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter guest user e-library"
                              value={guestLibrary}
                              onChange={(e) => setGuestLibrary(e.target.value)}
                            />
                            {errors.guestLibrary && (
                              <div className="text-danger fs-12">
                                {errors.guestLibrary}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>e-library for register user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter register user e-library"
                              value={registerLibrary}
                              onChange={(e) =>
                                setRegisterLibrary(e.target.value)
                              }
                            />
                            {errors.registerLibrary && (
                              <div className="text-danger fs-12">
                                {errors.registerLibrary}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Performance score card<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter performance score card"
                              value={performanceScore}
                              onChange={(e) =>
                                setPerformanceScore(e.target.value)
                              }
                            />
                            {errors.performanceScore && (
                              <div className="text-danger fs-12">
                                {errors.performanceScore}
                              </div>
                            )}
                          </div>

                        

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>E-library performance score card<span className="text-danger">*</span> </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter live e-library"
                              value={libraryPerformanceScore}
                              onChange={(e) =>
                                setLibraryPerformanceScore(e.target.value)
                              }
                            />
                            {errors.libraryPerformanceScore && (
                              <div className="text-danger fs-12">
                                {errors.libraryPerformanceScore}
                              </div>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of live exam
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangeLiveExamPhoto}
                              />
                              {/* {errors.photoLiveExam && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoLiveExam}
                                </div>
                              )} */}
                            </div>

                            <div className="form-group mb-3">
                              {photoLiveExam ? (
                                <img
                                  className="subject-img-form"
                                  src={photoLiveExam}
                                />
                              ) : null}
                            </div>
                          </div>

                       

                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of e-library
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoLibrary}
                              />
                              {/* {errors.photoLibrary && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoLibrary}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group mb-3">
                              {photoLibrary ? (
                                <img
                                  className="subject-img-form"
                                  src={photoLibrary}
                                />
                              ) : null}
                            </div>
                          </div>

                      

                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of performance score
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoPerformance}
                              />
                              {/* {errors.photoPerformance && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoPerformance}
                                </div>
                              )} */}
                            </div>

                            <div className="form-group mb-3">
                              {photoPerformance ? (
                                <img
                                  className="subject-img-form"
                                  src={photoPerformance}
                                />
                              ) : null}
                            </div>
                          </div>

                      

                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of e-library performance score
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoPerformanceL}
                              />
                              {/* {errors.photoPerformanceL && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoPerformanceL}
                                </div>
                              )} */}
                            </div>

                            <div className="form-group mb-3">
                              {photoPerformanceL ? (
                                <img
                                  className="subject-img-form"
                                  src={photoPerformanceL}
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Update
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  
  return {
    showLoading: state.category.showLoading,
  };
};

export default connect(mapStateToProps)(UpdateExamCategory);
