import React, { useContext, useEffect, useState, Fragment } from "react";
import { Row, Col, Card, Table, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import * as utility from "../../../utility/Utility";
import { getRolesData } from "../../../store/actions/UserAction";
import { deleteRoleData } from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import swal from "sweetalert";

//images
import Educat from "./../../../images/egucation-girl.png";

const RoleList = (props) => {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState("");
  useEffect(() => {
    permissionCheck();
    dispatch(globalLoadingAction(true));
    dispatch(getRolesData());
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 87) {
        setPermission(item);
      }
    });
  };


  /* confirmation for delete */
const onDeleteModal = (i) => {
  swal({
    title: "Are you sure?",
    text: "You want to remove this item !",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      dispatch(globalLoadingAction(true))
      dispatch(deleteRoleData(i))
    }})}




  return (
    <>
    {console.log("hola..",props.roleList)}
      <Fragment>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Role</strong>
                      </th>
                      <th width={'70%'}>
                        <strong>Description</strong>
                      </th>
                     {
                      (permission.edit=="true" || permission.delete=="true") && (
                        <th>
                        <strong>Action</strong>
                      </th>
                      )
                     }
                    </tr>
                  </thead>
                  <tbody>
                    {props.roleList.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.role_name}</td>
                          <td>{item.description}</td>

                        {
                          (permission.edit=="true" || permission.delete=="true") && (
                            <td>
                            <div className="d-flex">
                             {
                              permission.edit=="true" && (
                                <Link
                                to={{
                                  pathname: "/update-role",
                                  state: item,
                                }}
                                className="btn btn-warning shadow btn-xs sharp me-1"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                              )
                             }

                              {
                                permission.delete=="true" && (
                                  <Button
                                className="btn btn-danger shadow btn-xs sharp"
                                 onClick={() => onDeleteModal(item.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                                )
                               }
                            </div>
                          </td>
                          )
                        }
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    roleList: state.user.rolesList,
  };
};

export default connect(mapStateToProps)(RoleList);
