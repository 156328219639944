import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import swal from "sweetalert";
import Moment from 'react-moment';
import moment from 'moment';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Modal,
    Button,
    Pagination
} from "react-bootstrap";

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getEventHistoryList, updateEventHistoryStatusData, removeEventHistoryData } from '../../../store/actions/EventHistoryAction';


const EventHistoryList = (props) => {

    const calendarRef = useRef();

    const history = useHistory();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const previousFilterData = props?.location.state?.previousPageFilterData;

    const [page, setPage] = useState(previousFilterData?.page ?? 1);
    // const [date, setDate] = useState(state == undefined ? '' : state.date);
    const [date, setDate] = useState(previousFilterData?.date ?? '');
    const [permission, setPermission] = useState("");

    useEffect(() => {
        permissionCheck();
        dispatch(globalLoadingAction(true));
        dispatch(getEventHistoryList(page, date ? moment(date).format('YYYY-MM-DD') : '', props.history));
    }, []);

    /* delect confirmation */
    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (props.eventList.length == 1) {
                    dispatch(removeEventHistoryData(id, page - 1, props.history));
                    setPage(page - 1)
                } else {
                    dispatch(removeEventHistoryData(id, page, props.history));
                }
            }
        })
    }

    /* status updated */
    const onUpdateApprove = (item) => {
        dispatch(globalLoadingAction(true));
        dispatch(updateEventHistoryStatusData(item, props.history));
    }

    /* change date */
    const onDateHandler = (date) => {
        setDate(date);
        setPage(1)
        dispatch(globalLoadingAction(true));
        dispatch(getEventHistoryList(1, moment(date).format('YYYY-MM-DD'), props.history));

    }

    /* clear data */
    const onClearHandler = () => {
        setDate('');
        dispatch(globalLoadingAction(true));
        dispatch(getEventHistoryList(page, '', props.history));
    }

    /* pagination */
    const pageHandler = (data) => {
        setPage(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getEventHistoryList(data.selected + 1, date ? moment(date).format('YYYY-MM-DD') : '', props.history));
    }

    const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
            if (item.id == 67) {
                setPermission(item);
            }
        });
    };

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="row add-Question-list-view">

                                <div className="col-sm-3 px-1 clear-question-type-new">
                                    <div className="form-group">
                                        <div className="date-control-view">
                                            <DatePicker
                                                ref={calendarRef}
                                                placeholderText="Select date"
                                                selected={date}
                                                onChange={(date) => onDateHandler(date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                            <button className="picker-btn"
                                                onClick={() => calendarRef.current.setOpen(true)}
                                            >
                                                <i class="fa-solid fa-calendar-days"></i>
                                            </button>
                                            {date ?
                                                <button onClick={onClearHandler} className="clear-date-btn-align "><i class="fa fa-times" aria-hidden="true"></i></button>
                                                : null}
                                        </div>

                                    </div>
                                </div>


                            </div>
                            {
                                props.totalRecord == 0 ? (
                                    <div className='no_data'>
                                        <strong >No data found</strong>
                                    </div>) : (
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Title</strong>
                                                </th>
                                                <th>
                                                    <strong>Sub title</strong>
                                                </th>
                                                <th>
                                                    <strong>Event Date</strong>
                                                </th>
                                                {
                                                    permission.status == "true" && (
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                    )
                                                }
                                                {
                                                    (permission.edit == "true" || permission.delete == "true") && (
                                                        <th>
                                                            <strong>Action</strong>
                                                        </th>
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        {props.eventList != '' ?
                                            <tbody>
                                                {
                                                    props.eventList.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td>{item.title}</td>
                                                            <td><p className="td-ellipsis">{item.sub_title}</p></td>
                                                            <td><Moment format="D MMM YYYY" withTitle>{item.event_date}</Moment></td>
                                                            {
                                                                permission.status == "true" && (
                                                                    <td>
                                                                        <Button
                                                                            disabled={props.showLoading}
                                                                            className="btn-xs"
                                                                            variant={item.status == 1 ? "success" : "danger"}
                                                                            onClick={() => onUpdateApprove(item)}
                                                                        >
                                                                            {item.status == 1 ? "Approve" : "Pending"}
                                                                        </Button>
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                (permission.edit == "true" || permission.delete == "true") && (
                                                                    <td>
                                                                        <div className="d-flex">

                                                                            {
                                                                                permission.edit == "true" && (
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: '/update-event-history',
                                                                                            state: { 'data': item, 'filter': { 'page': page, 'date': date } },
                                                                                            filterData: { page, date }

                                                                                        }}
                                                                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                                                                    >
                                                                                        <i className="fas fa-pencil-alt"></i>
                                                                                    </Link>
                                                                                )
                                                                            }

                                                                            {
                                                                                permission.delete == "true" && (
                                                                                    <Button
                                                                                        className="btn btn-danger btn-xs sharp"
                                                                                        onClick={() => onDelete(item.id)}
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                )
                                                            }
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                            : <tbody>
                                                <td colSpan={12}>
                                                    <div className='no_data'>
                                                        <strong >No data found</strong>
                                                    </div>
                                                </td>
                                            </tbody>}
                                    </Table>
                                )
                            }
                        
                            <div className="pagination-container">
                                <div className="page-result">
                                    {/* {props.totalRecord !== 0 && <p>Showing: {(page - 1) * (10) + 1} - {(10 * page) - (10 - props.eventList.length)} of {props.totalRecord}</p>} */}
                                    {props.totalRecord !== 0 && (
                                        <p>
                                            Showing: {(page - 1) * 10 + 1} -{" "}
                                            {10 * page - (10 - props.eventList.length)} of{" "}
                                            {props.totalRecord}
                                        </p>
                                    )}

                                </div>
                                {props.totalRecord > 10 ?
                                    <ReactPaginate
                                        containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
                                        pageClassName='page-item'
                                        pageLinkClassName='page-link'
                                        nextLabel={<i class="la la-angle-right"></i>}
                                        nextClassName='page-item'
                                        nextLinkClassName='page-link'
                                        breakLabel="..."
                                        previousLabel={<i class="la la-angle-left"></i>}
                                        previousClassName='page-item'
                                        previousLinkClassName='page-link'
                                        breakClassName='page-item'
                                        breakLinkClassName='page-link'
                                        renderOnZeroPageCount={null}
                                        activeClassName="active"
                                        onPageChange={pageHandler}
                                        pageRangeDisplayed={3}
                                        pageCount={props.totalPage}
                                        forcePage={page - 1}
                                    />
                                    : null}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        eventList: state.eventhistory.eventList,
        totalPage: state.eventhistory.totalPage,
        totalRecord: state.eventhistory.totalRecord,
    };
};

export default connect(mapStateToProps)(EventHistoryList);