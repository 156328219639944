import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from "../../../apis/Apis";

const DatabaseBackup = () => {
  const [filelist, setFileList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GlobalConfigs.API_URL + Apis.DB_BCKUP);
      setFileList(response.data.filelist);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h3>Old Database Backup List</h3>
      <div class="container container bg-white p-4 rounded">
        <table class="table bg-white rounded">
          <thead>
            <tr>
              <th class="font-weight-bold">Link</th>
              <th class="font-weight-bold">Download Option</th>
            </tr>
          </thead>
          <tbody>
            {filelist.map((i) => {
              return (
                <tr class="bg-white">
                  <td>
                    <a>{i}</a>
                  </td>
                  <td>
                    <a href={process.env.REACT_APP_DATABASE_BACKUP+i} class="btn btn-success btn-sm" target="_blank">
                      Download
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DatabaseBackup;
