import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { SubscriptionDetails } from "../../services/SubscriptionDetailsService";
import { GET_SUBSCRIPTION_DETAILS_LIST } from "../constants";

export function SubscriptionDetailsData(datediff,board,className,pincode,category,subject,services,fsd,nbf,subscribe_method) {
  return (dispatch) => {
    SubscriptionDetails(datediff,board,className,pincode,category,subject,services,fsd,nbf,subscribe_method)
      .then((response) => {
        if (response.data.status == 200) {
        //    console.log("response",response.data.data)
           dispatch(SubscriptionDetailsAction(response.data.data))
          dispatch(globalLoadingAction(false));
        } else {
         
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function SubscriptionDetailsAction(data){
    return{
        type:GET_SUBSCRIPTION_DETAILS_LIST,
        payload:data
    }
}