import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Card, Modal, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getQuestionHistoryLogData } from "../../../store/actions/AssignContentAction";
import { BsJournalMedical } from "react-icons/bs";

const AssignQuestionHistoryList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { state } = useLocation();

  const [contentModal, setContentModal] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getQuestionHistoryLogData(state?.id, props.history));
    return () => {};
  }, []);

  /* navigation go back with state data */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/assign-question-list",
      state: state.filterObj,
    });
  };

  /* open modal */
  const openContentModal = (content_data) => {
  //  console.log('parse....',JSON.parse(content_data))
    setContent(content_data ? JSON.parse(content_data) : []);
    setContentModal(true);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="col-xl-3 pt-3">
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler}>
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-2"></div>
                <div className="col-xl-8">
                  <div className="card-body">
                    <div className="basic-form">
                      <div className="form-group mb-3 two_column">
                        <div className="mb-2 one_column">
                          <h4>
                            <strong>Category : </strong>
                            {state?.category}
                          </h4>
                        </div>
                        <div className="mb-2 one_column">
                          <h4>
                            <strong>Chapter :</strong> {state?.chapter_name}
                          </h4>
                        </div>
                      </div>
                      {state.exam_category_id == 1 ? (
                        <div className="form-group mb-3 two_column">
                          <div className="mb-2 one_column">
                            <h4>
                              <strong>Board :</strong> {state?.board_name}
                            </h4>
                          </div>
                          <div className="mb-2 one_column">
                            <h4>
                              <strong>Class :</strong> {state?.class_code}
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group mb-3 two_column">
                          <div className="mb-2 one_column">
                            <h4>
                              <strong>Exam Type :</strong> {state?.type_name}
                            </h4>
                          </div>
                          <div className="mb-2 one_column">
                            <h4>
                              <strong>Class :</strong> {state?.class_code}
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="form-group mb-3 two_column">
                        <div className="mb-2 one_column">
                          <h4>
                            <strong>Subject :</strong> {state?.subject_name}
                          </h4>
                        </div>
                        <div className="mb-2 one_column">
                          <h4>
                            <strong>Type :</strong> {`Live`}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2"></div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Assign Person</strong>
                    </th>
                    <th>
                      <strong>Time Limit</strong>
                    </th>
                    <th>
                      <strong>Price</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>Update Date</strong>
                    </th>
                    <th className="chapter_col">
                      <strong>Reject Reason</strong>
                    </th>
                    <th>
                      <strong>Content Details</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.historyLog.length > 0
                    ? props.historyLog.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{`${item.developer_type} : ${item.name}`}</td>
                            <td>{item.time_limit}</td>
                            <td>{item.price}</td>
                            <td>{item.status}</td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {item.updated_at}
                              </Moment>
                            </td>
                            <td>{item.rejection_msg}</td>
                            <td className="text-center">
                              {" "}
                              {item.content_data == "" ||
                              item.content_data == null ? null : (
                                <BsJournalMedical
                                  size={25}
                                  color={"#FF7F27"}
                                  onClick={() =>
                                    openContentModal(item.content_data)
                                  }
                                  className="cursorPointer"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="fade" show={contentModal} size="lg">
        <Modal.Header>
          <Modal.Title className="modal_title">Question List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="table-scrollable-view">
              {content.length > 0 ? (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>#</strong>
                        </th>
                        <th>
                          <strong>Question Type</strong>
                        </th>
                        <th>
                          <strong>Question No.</strong>
                        </th>
                        <th>
                          <strong>Group ID</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {content.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.question_type}</td>
                            <td>{item.question_no}</td>
                            <td>{item.css_group_id}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_right_container show_right">
            <Button
              variant="warning text-center m-1"
              onClick={() => setContentModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    historyLog: state.assign.questionHistory,
  };
};
export default connect(mapStateToProps)(AssignQuestionHistoryList);
