import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function getUserType() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_USER_TYPE_LIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function addAdmin(user_type, fname, mname, lname, dob, gender, phone, email, house_flat_name, area_location, city_name, district_name, state_name, pincode, panno, adhar_no, highest_qualification, experience, computer_knowledge, academic_year, date_of_enrollment, menus,role_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_type,
        fname,
        mname,
        lname,
        dob,
        gender,
        phone,
        email,
        house_flat_name,
        area_location,
        city_name,
        district_name,
        state_name,
        pincode,
        panno,
        adhar_no,
        highest_qualification,
        experience,
        computer_knowledge,
        academic_year,
        date_of_enrollment,
        menus,
        role_id
    };
    console.log('HELLO postData', postData);
    // return;
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addDeveloper(user_type, fname, mname, lname, dob, gender, phone, email, house_flat_name, area_location, city_name, district_name, state_name, state_id, pincode, panno, adhar_no, highest_qualification, experience, subject_preference, board_preference, class_preference, time_slot_preference, language_communication, computer_knowledge, academic_year, date_of_enrollment, roll, board, standard, school_name, incharge_name, menus) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_type,
        fname,
        mname,
        lname,
        dob,
        gender,
        phone,
        email,
        house_flat_name,
        area_location,
        city_name,
        district_name,
        state_name,
        state_id,
        pincode,
        panno,
        adhar_no,
        highest_qualification,
        experience,
        subject_preference,
        board_preference,
        class_preference,
        time_slot_preference,
        language_communication,
        computer_knowledge,
        academic_year,
        date_of_enrollment,
        roll,
        board,
        standard,
        school_name,
        incharge_name,
        menus
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_USER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addTeacher(name, email, phone, gender) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        name,
        email,
        phone,
        gender
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TEACHER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getAdmin(page, name, email,phoneno) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        name,
        email,
        phoneno
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ADMIN_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getContentDeveloper(page, name, email,phoneno) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        name,
        email,
        phoneno
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_DEVELOPER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getStudent(page, name, email,phoneno,start_date,end_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        name,
        email,
        phoneno,
        start_date,
        end_date
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_STUDENT_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTeacher(page, name, email,phoneno) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        name,
        email,
        phoneno
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TEACHER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateAdmin(id, fname, mname, lname, dob, gender, phone, email, house_flat_name, area_location, city_name, district_name, state_name, pincode, panno, adhar_no, highest_qualification, experience, computer_knowledge, academic_year, date_of_enrollment, menus,role_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        fname,
        mname,
        lname,
        dob,
        gender,
        phone,
        email,
        house_flat_name,
        area_location,
        city_name,
        district_name,
        state_name,
        pincode,
        panno,
        adhar_no,
        highest_qualification,
        experience,
        computer_knowledge,
        academic_year,
        date_of_enrollment,
        menus,
        role_id
    };
console.log('postData..........',postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ADMIN_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateContentDeveloper(id,user_type, fname, mname, lname, dob, gender, phone, house_flat_name, area_location, city_name, district_name, state_name, state_id, pincode, panno, adhar_no, highest_qualification, experience,subject_preference,board_preference,class_preference,time_slot_preference,language_communication, computer_knowledge, academic_year, date_of_enrollment, menus,role_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,user_type, fname, mname, lname, dob, gender, phone, house_flat_name, area_location, city_name, district_name, state_name, state_id, pincode, panno, adhar_no, highest_qualification, experience,subject_preference,board_preference,class_preference,time_slot_preference,language_communication, computer_knowledge, academic_year, date_of_enrollment, menus,role_id
    };
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_CONTENT_DEVELOPER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateStudent(id, fname, email, mobile) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        fname,
        email,
        mobile,
        lname: '',
        dob: '',
        profile_pic: '',
        gender: '',
        address: '',
        pincode: '',
        standard: '',
        board: '',
        school_name: '',
        school_address: '',
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_STUDENT_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updaTeacher(id, name, email, phone) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        name,
        email,
        phone
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TEACHER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function adminStatus(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_ADMIN_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function developerStatus(id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_DEVELOPER_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function studentStatus(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_STUDENT_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function teacherStatus(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHANGE_TEACHER_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeUser(id, user_type) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        user_type
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_USER_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeAdmin(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_ADMIN_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeContentDeveloper(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_CONTENT_DEVELOPER_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeStudent(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_STUDENT_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeTeacher(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TEACHER_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getDistrict(state_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData={
        state_id
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_DISTRICT_LIST,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData
    });

}

export function getState() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_STATE_LIST,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}


export function addRole(role_name,description,role_permission) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_name,description,role_permission
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_ROLE,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function getRoles() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ROLE_LIST,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function deleteRole(role_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.DELETE_ROLE,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateRole(role_id,role_name,description,role_permission) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        role_id,role_name,description,role_permission
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ROLE,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function createUsersDropdownData() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.CREATE_USERS_DROPDOWN_DATA_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}