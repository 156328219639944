import {
    addTimeConfiguration,
    getTimeConfiguration,
    updateTimeConfiguration,
    removeTimeConfiguration
} from '../../services/TimeConfigurationServices';

import {
    TIME_CONFIGURATION_LOADING,
    ADD_TIME_CONFIGURATION,
    GET_TIME_CONFIGURATION,
    REMOVE_TIME_CONFIGURATION,
    UPDATE_TIME_CONFIGURATION
} from '../constants';

import swal from "sweetalert";
import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addTimeData(c_id, ty_id, sty_id, b_id, cls_id, sub_id, com_id, set_id, md_id, mok_id, history) {
    return (dispatch) => {
        addTimeConfiguration(c_id, ty_id, sty_id, b_id, cls_id, sub_id, com_id, set_id, md_id, mok_id)
            .then((response) => {
                dispatch(addTimeAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateTimeData(id, c_id, ty_id, sty_id, b_id, cls_id, sub_id, com_id, set_id, md_id, mok_id, history) {
    return (dispatch) => {
        updateTimeConfiguration(id, c_id, ty_id, sty_id, b_id, cls_id, sub_id, com_id, set_id, md_id, mok_id)
            .then((response) => {
                dispatch(updateTimeAction(true));
                //utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingTimeConfiguration(false));
                utility.showError(error.response.data);
            });
    };
}

export function getTimeData(page, history) {
    return (dispatch) => {
        getTimeConfiguration(page)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeAction(response.data));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function removeTimeData(id, history) {
    return (dispatch) => {
        removeTimeConfiguration(id)
            .then((response) => {
                dispatch(removeTimeAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addTimeAction(data) {
    return {
        type: ADD_TIME_CONFIGURATION,
        payload: data,
    };
}

export function getTimeAction(data) {
    return {
        type: GET_TIME_CONFIGURATION,
        payload: data,
    };
}

export function updateTimeAction(data) {
    return {
        type: UPDATE_TIME_CONFIGURATION,
        payload: data,
    };
}

export function removeTimeAction(data) {
    return {
        type: REMOVE_TIME_CONFIGURATION,
        payload: data,
    };
}

export function loadingTimeConfiguration(status) {
    return {
        type: TIME_CONFIGURATION_LOADING,
        payload: status,
    };
}
