import {
    GET_CENCEL_QUESTIONS_REASON
} from '../constants';

const initialState = {
    cancelReasonList: [],
    showLoading: false,
};

export function ReasonReducer(state = initialState, action) {

    if (action.type === GET_CENCEL_QUESTIONS_REASON) {
        return {
            ...state,
            cancelReasonList: action.payload,
            showLoading: false,
        };
    }

    return state;
}


