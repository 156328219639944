import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  updateAssignQuestionContentData,
  getAssignQuestionDetailsById,
} from "../../../store/actions/AssignContentAction";
import {
  loadingUserAction,
  getUserTypeData,
  getDeveloperData,
} from "../../../store/actions/UserAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../store/actions/QuestionPatternAction";
import { getLanguageValidatorData } from "../../../store/actions/ContentLanguageValidatorAction";
import { getContentDesignerData } from "../../../store/actions/ContentDesignerAction";
import { getContentValidatorData } from "../../../store/actions/ContentValidatorAction";

const UpdateAssignQuestion = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const { state } = useLocation();

  let errorsObj = {
    developer: "",
    question: "",
    date: "",
    amount: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [developer, setDeveloper] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");
  const [amount, setAmount] = useState("");
  const [userType, setUserType] = useState("");
  const [patternArray, setPatternArray] = useState([
    { type: "", number: "", sets: "", rate: "" },
  ]);

  useEffect(() => {
    dispatch(getUserTypeData(props.history));
    dispatch(getQuestionPattern(props.history));
    dispatch(getDeveloperData(0, "", "", "", props.history));
    dispatch(getContentValidatorData(0, "", "", "", props.history));
    dispatch(getLanguageValidatorData(0, "", "", "", props.history));
    dispatch(getContentDesignerData(0, "", "", "", props.history));
    dispatch(getAssignQuestionDetailsById(state.id, props.history));
  }, []);

  useEffect(() => {
    if (props.documentDetails !== null) {
      setPatternArray(props.documentDetails?.question_no_details);
      setSubmissionDate(
        props.documentDetails?.date_of_submission
          ? new Date(props.documentDetails?.date_of_submission)
          : new Date()
      );
      if (
        props.documentDetails?.approved_status == 1 &&
        props.documentDetails?.admin_approval_status == 1 &&
        props.documentDetails?.status == 1
      ) {
        setUserType(props.documentDetails?.developer_approval_status + 1);
      } else {
        setUserType(props.documentDetails?.developer_approval_status);
        //setDeveloper(props.documentDetails?.assigned_developer);
      }
    }
  }, [props.documentDetails]);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (submissionDate === "") {
      errorObj.date = "Submission date is Required";
      error = true;
    }
    if (userType == 1 && developer === "") {
      errorObj.developer = "Content Developer is Required.";
      error = true;
    } else if (userType == 2 && developer === "") {
      errorObj.developer = "Content Validator is Required.";
      error = true;
    } else if (userType == 3 && developer === "") {
      errorObj.developer = "Language validator is required.";
      error = true;
    } else if (userType == 4 && developer === "") {
      errorObj.developer = "Designer is Required";
      error = true;
    }

    patternArray.forEach((element) => {
      if (
        element.type === "" ||
        element.number === "" ||
        element.rate === "" ||
        (element.type === "CSS" && element.sets == "")
      ) {
        errorObj.question = "Question type is Required";
        error = true;
      }
    });

    let totalAmount = 0;
    let totalQuestion = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number && arry[x].rate) {
        totalAmount += parseInt(arry[x].number) * parseInt(arry[x].rate);
        totalQuestion += parseInt(arry[x].number);
      }
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      updateAssignQuestionContentData(
        state.id,
        userType ? parseInt(userType) : 0,
        patternArray,
        submissionDate ? moment(submissionDate).format("YYYY-MM-DD") : "",
        totalAmount,
        totalQuestion,
        developer ? parseInt(developer) : 0,
        state.filterObj,
        props.history
      )
    );
  }

  /* on Remove Hnadler */
  const onRemoveHandler = (index) => {
    let arry = [...patternArray];
    arry.splice(index, 1);
    setPatternArray(arry);
  };
  
  /* change Qws type */
  const onChangeQwsType = (val, index) => {
    let arry = [...patternArray];
    arry[index].type = val;
    setPatternArray(arry);
  };

  /* Change Qws Sets */
  const onChangeQwsSets = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].sets = val;
      let no = val * 5;
      arry[index].number = val ? no.toString() : "";
      setPatternArray(arry);
    }
  };

  /* change Qws number */
  const onChangeQwsNumber = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].number = val;
      setPatternArray(arry);
    }
  };

  /* change Qws Rate */
  const onChangeQwsRate = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].rate = val;
      setPatternArray(arry);
    }
  };

  /* add handler */
  const onAddHandler = () => {
    let arry = [...patternArray];
    let obj = {
      type: "",
      number: "",
      sets: "",
      rate: "",
    };
    arry.push(obj);
    setPatternArray(arry);
  };

  const onAddDisabledItem = () => {
    let arry = [...patternArray];
    let status = false;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].type === "" || arry[x].number === "" || arry[x].rate === "") {
        status = true;
      } else if (arry[x].type === "CSS" && arry[x].sets === "") {
        status = true;
      }
    }
    return status;
  };

  /* change Qws type */
  const onQwsType = (val) => {
    let status;
    let arry = [...patternArray];
    let temp = arry.find((element) => element.type == val);
    if (temp == undefined) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  /* get total Qestion */
  const getTotalQuestions = () => {
    let status = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number) {
        status += parseInt(arry[x].number);
      }
    }

    return status == 0 ? "" : status;
  };

  /* get total amount */
  const getTotalAmount = () => {
    let status = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number && arry[x].rate) {
        status += parseInt(arry[x].number) * parseInt(arry[x].rate);
      }
    }

    return status == 0 ? "" : status;
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  /* navigation go back with state value */
  const onGoBackHandler = () => {
    history.replace({
      pathname: "/assign-question-list",
      state: state.filterObj,
    });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-1">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-10">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3 two_column">
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Category :</strong>{" "}
                                {props.documentDetails?.category}
                              </h4>
                            </div>
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Chapter :</strong>{" "}
                                {props.documentDetails?.chapter_name}
                              </h4>
                            </div>
                          </div>
                          {props.documentDetails?.exam_category_id == 1 ? (
                            <div className="form-group mb-3 two_column">
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Board :</strong>{" "}
                                  {props.documentDetails?.board_name}
                                </h4>
                              </div>
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Class :</strong>{" "}
                                  {props.documentDetails?.class_code}
                                </h4>
                              </div>
                            </div>
                          ) : (
                            <div className="form-group mb-3 two_column">
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Exam Type :</strong>{" "}
                                  {props.documentDetails?.type_name}
                                </h4>
                              </div>

                              <div className="mb-2 one_column">
                                {props.documentDetails?.class_code ? (
                                  <h4>
                                    <strong>Class :</strong>{" "}
                                    {props.documentDetails?.class_code}
                                  </h4>
                                ) : (
                                  <h4>
                                    <strong className="pe-4">Class :</strong>-
                                  </h4>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="form-group mb-3 two_column">
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Subject :</strong>{" "}
                                {props.documentDetails?.subject_name}
                              </h4>
                            </div>
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Type :</strong> {`Live`}
                              </h4>
                            </div>
                          </div>

                          {(() => {
                            switch (userType) {
                              case 1:
                                return (
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Content Developer
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow">
                                        <i className="bi bi-caret-down-fill"></i>
                                      </span>

                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={developer}
                                        onChange={(e) =>
                                          setDeveloper(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Content Developer
                                        </option>
                                        {props.developerlist.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      {errors.developer && (
                                        <div className="text-danger fs-12">
                                          {errors.developer}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              case 2:
                                return (
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Content Validator
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow">
                                        <i className="bi bi-caret-down-fill"></i>
                                      </span>

                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={developer}
                                        onChange={(e) =>
                                          setDeveloper(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Content Validator
                                        </option>
                                        {props.contentvalidatorList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      {errors.developer && (
                                        <div className="text-danger fs-12">
                                          {errors.developer}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              case 3:
                                return (
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Language Validator
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow">
                                        <i className="bi bi-caret-down-fill"></i>
                                      </span>

                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={developer}
                                        onChange={(e) =>
                                          setDeveloper(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Language Validator
                                        </option>
                                        {props.languagevalidatorList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      {errors.developer && (
                                        <div className="text-danger fs-12">
                                          {errors.developer}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              case 4:
                                return (
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Content Designer
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow">
                                        <i className="bi bi-caret-down-fill"></i>
                                      </span>

                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={developer}
                                        onChange={(e) =>
                                          setDeveloper(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Content Designer
                                        </option>
                                        {props.contentdesignerList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      {errors.developer && (
                                        <div className="text-danger fs-12">
                                          {errors.developer}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              default:
                                return null;
                            }
                          })()}
                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Question Type
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            {patternArray.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="inline-form-group-view"
                                >
                                  <div className="basic-dropdown flex-1">
                                    <span className="drop_down_arrow">
                                      <i className="bi bi-caret-down-fill"></i>
                                    </span>
                                    <select
                                      disabled={true}
                                      defaultValue={"option"}
                                      className="form-control"
                                      value={item.type}
                                      onChange={(e) =>
                                        onChangeQwsType(e.target.value, index)
                                      }
                                    >
                                      <option value="">
                                        Select question type
                                      </option>
                                      {props.patternList.map((i, idex) => {
                                        return (
                                          <option
                                            key={idex}
                                            value={i.short_code}
                                            disabled={onQwsType(i.short_code)}
                                          >
                                            {i.short_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {item.type === "CSS" ? (
                                    <input
                                      disabled={true}
                                      className="form-control form-control-Qws-pattan"
                                      placeholder="Sets no."
                                      value={item.sets}
                                      onChange={(e) =>
                                        onChangeQwsSets(e.target.value, index)
                                      }
                                      maxLength="3"
                                    />
                                  ) : null}
                                  <input
                                    className="form-control form-control-Qws-pattan"
                                    placeholder="Questions"
                                    value={item.number}
                                    disabled={true}
                                    onChange={(e) =>
                                      onChangeQwsNumber(e.target.value, index)
                                    }
                                    maxLength="3"
                                  />
                                  <input
                                    className="form-control form-control-Qws-pattan"
                                    placeholder="Rate per question"
                                    type="number"
                                    value={item.rate}
                                    onChange={(e) =>
                                      onChangeQwsRate(e.target.value, index)
                                    }
                                    maxLength="3"
                                  />

                                  {index === 0 ? (
                                    <button
                                      type="button"
                                      className="plus-btn"
                                      onClick={onAddHandler}
                                      //disabled={onAddDisabledItem()}
                                      disabled={true}
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => onRemoveHandler(index)}
                                      className="plus-btn"
                                      disabled={true}
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </button>
                                  )}
                                </div>
                              );
                            })}
                            {errors.question && (
                              <div className="text-danger fs-12">
                                {errors.question}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Total No. of Questions<span className="text-danger">*</span></strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Total No. of Questions"
                              disabled={true}
                              value={getTotalQuestions()}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Date Of submission
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="date-control-view">
                              <DatePicker
                                ref={calendarRef}
                                selected={submissionDate}
                                placeholderText="Select the submission date"
                                onChange={(date) => setSubmissionDate(date)}
                                showYearDropdown
                                showMonthDropdown
                                // minDate={new Date() }
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() + 1)
                                  )
                                }
                                onChangeRaw={handleDateChangeRaw}
                              />
                              <button
                                type="button"
                                className="picker-btn"
                                onClick={() =>
                                  calendarRef.current.setOpen(true)
                                }
                              >
                                <i className="fa-solid fa-calendar-days"></i>
                              </button>
                            </div>
                            {errors.date && (
                              <div className="text-danger fs-12">
                                {errors.date}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Amount<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Amount"
                              disabled={true}
                              value={getTotalAmount()}
                            />
                            {errors.amount && (
                              <div className="text-danger fs-12">
                                {errors.amount}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Assign
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    showLoadingChapter: state.chapter.showLoading,
    patternList: state.pattern.list,
    documentDetails: state.assign.questionDocumentDetails,
    developerlist: state.user.contentDeveloperList,
    contentvalidatorList: state.contentvalidator.list,
    languagevalidatorList: state.languagevalidator.list,
    contentdesignerList: state.contentdesigner.list,
  };
};
export default connect(mapStateToProps)(UpdateAssignQuestion);
