import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisDashboardInfo(datediff) {
    let getData = localStorage.getItem("userDetails");
    let token = JSON.parse(getData).token;
   
    const postData = {
      datediff,
    
    };
    return axios({
      url: GlobalConfigs.API_URL + Apis.GET_DASHBOARD_INFO,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    });
  }
  
