import { GET_RESUMES,GET_INDIVIDUAL_RESUME } from "../constants";

const initialState = {
    List: [],
    totalRecode: 0,
    totalPage: 0,
    individualDetails:{}
};


export function ResumeReducer(state = initialState, action) {

    if (action.type === GET_RESUMES) {
          
        return {
            ...state,
            List: action.payload.data,
            totalRecode:action.payload.total_record,
            totalPage:action.payload.total_page
        };
    }

    if (action.type === GET_INDIVIDUAL_RESUME) {
        
        return {
            ...state,
            individualDetails:{...action.payload.data[0]}
           
        };
    }

    return state

}

