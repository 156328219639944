import { GET_DISTRICT_LIST,GET_STATE_LIST } from "../constants";

const initialState = {
  Districtlist: [],
  StateList:[]
};



export function LocationReducer(state = initialState, action) {


if (action.type === GET_DISTRICT_LIST) {

    return {
        ...state,
        Districtlist:action.payload
    };
}
if (action.type === GET_STATE_LIST) {

  return {
      ...state,
      StateList:action.payload
  };
}


return state;

}