import {
    BOARD_LOADING,
    ADD_BOARD,
    UPDATE_BOARD,
    GET_BOARD,
    REMOVE_BOARD,
    UPDATE_STATUS_BOARD
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function BoardReducer(state = initialState, action) {

    if (action.type === ADD_BOARD) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_BOARD) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_BOARD) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_BOARD) {

        const boardList = [...state.list];
        const index = boardList.findIndex((item) => item.id === action.payload.id);
        boardList[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: boardList,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_BOARD) {

        const bordList = [...state.list];
        const index = bordList.findIndex((item) => item.id === action.payload);
        bordList.splice(index, 1);

        return {
            ...state,
            list: bordList,
            showLoading: false,
        };
    }

    if (action.type === BOARD_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


