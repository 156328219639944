import React from "react";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const Footer = () => {
	var d = new Date();
	return (
		<>
			<div className="footer">
				<div className="copyright">
					<p>Copyright © Designed &amp; Developed by{" "}
						<a href="#" target="_blank" rel="noreferrer">
							Crestest Learning Ventures LLP
						</a>{" "}
						{/* 2022 */}
						{<Moment format="YYYY" withTitle>{d}</Moment>}
					</p>
				</div>
			</div>
		</>
	);
};

export default Footer;
