import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";

import { getQuestionPattern, loadingPatternAction, removeQuestionPattern, updateStatusPatternData } from '../../../store/actions/QuestionPatternAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const QuestionPatternList = (props) => {

	const dispatch = useDispatch();
	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionPattern(props.history));
	}, []);

	/* delete confirmation */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeQuestionPattern(i, props.history));
			}
		})
	}

	/* status updated */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateStatusPatternData(item, props.history));
	}

	const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
          if (item.id == 40) {
            setPermission(item);
          }
        });
      };

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Question type code</strong>
										</th>
										<th>
											<strong>Question type name</strong>
										</th>
										<th>
											<strong>Marks</strong>
										</th>
										{
											permission.status=="true" && (
												<th>
											<strong>Status</strong>
										</th>
											)
										}
										{
											(permission.edit=="true" || permission.delete=="true") && (
												<th>
											<strong>Action</strong>
										</th>
											)
										}
									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.short_code}</td>
												<td>{item.name}</td>
												<td>{item.marks}</td>
												{
													permission.status=="true" && (
														<td>
													<Button
														//disabled={props.showLoading}
														className="btn-xs"
														variant={item.status == 1 ? "success" : "danger"}
														onClick={() => onUpdateStatus(item)}
													>
														{item.status == 1 ? "Active" : "Inactive"}
													</Button>
												</td>
													)
												}
												{
													(permission.edit=="true" || permission.delete=="true") && (
														<td>
													<div className="d-flex">
														{
															permission.edit=="true" && (
																<Link
															to={{
																pathname: '/update-question-type',
																state: item
															}}
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
															)
														}
														{
															permission.delete=="true" && (
																<Button
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => onDeleteModal(item.id)}
														>
															<i className="fa fa-trash"></i>
														</Button>
															)
														}

													</div>
												</td>
													)
												}
											</tr>
										})
									}
								</tbody>
							</Table>

						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.pattern.showLoading,
		list: state.pattern.list,
	};
};

export default connect(mapStateToProps)(QuestionPatternList);