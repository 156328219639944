import { 
    GET_SUBSCRIBED_USER_ACTIVITY,
    PAYMENT_TRANSACTION_DETAILS 

} from "../constants";

const initial={
    list:[],
    transactionList:[],
    studentList:[],
    totalPage:'',
    totalRecord:''
}

export function subscribedUserActivityReducer(state=initial,action){
        if(action.type == GET_SUBSCRIBED_USER_ACTIVITY){
            return{
                ...state,
                list:action.payload
            }
        }

        if(action.type === PAYMENT_TRANSACTION_DETAILS){
            return{
                ...state,
                transactionList:action.payload.data,
                studentList:action.payload.student_list,
                totalPage:action.payload.total_page,
                totalRecord:action.payload.total_record,
            }
        }
        return state
}