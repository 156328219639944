import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Modal,
	Button
} from "react-bootstrap";

import { getBranchDetails, branchLoading, removeBranchData, updateStatusBranchData } from '../../../store/actions/BranchActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const BranchList = (props) => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getBranchDetails(props.history));
	}, []);

	/* delete confirmation modal */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeBranchData(i, props.history));
			}
		})
	}

	/* updated status */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateStatusBranchData(item, props.history));
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Subject Name</strong>
										</th>
										<th>
											<strong>Branch Name</strong>
										</th>
										<th>
											<strong>Branch code</strong>
										</th>
										<th>
											<strong>Create Date</strong>
										</th>
										<th>
											<strong>Status</strong>
										</th>
										<th>
											<strong>Action</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										props.branchList.map((item, index) => {
											return <tr>

												<td>{item.subject_name}</td>
												<td>{item.branch_name}</td>
												<td>{item.branch_code}</td>
												<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
												<td>
													<Button
														//disabled={props.showLoading}
														className="btn-xs"
														variant={item.status == 1 ? "success" : "danger"}
														onClick={() => onUpdateStatus(item)}
													>
														{item.status == 1 ? "Active" : "Inactive"}
													</Button>
												</td>
												<td>
													<div className="d-flex">
														<Link
															to={{
																pathname: '/update-branch',
																state: item
															}}
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
														<Button
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => onDeleteModal(item.id)}
														>
															<i className="fa fa-trash"></i>
														</Button>
													</div>
												</td>
											</tr>
										})
									}

								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.branch.showLoading,
		branchList: state.branch.branchList,
	};
};
export default connect(mapStateToProps)(BranchList);
