import {
    CHAPTER_LOADING,
    ADD_CHAPTER,
    UPDATE_CHAPTER,
    GET_CHAPTER,
    REMOVE_CHAPTER,
    GET_BRANCH_TYPE_CHAPTER,
    GET_CHAPTER_FOR_DEFFARENTS_PARAMS,
    UPDATE_STATUS_CHAPTER,
    GET_CHAPTER_FILTTER
} from '../constants';

const initialState = {
    list: [],
    typelist: [],
    chapterFiltterList:[],
    totalPage: 0,
    totalRecord: 0,
    showLoading: false,
};

export function ChapterReducer(state = initialState, action) {

    if (action.type === ADD_CHAPTER) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_CHAPTER) {
        return {
            ...state,
            list: action.payload.data,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === GET_CHAPTER_FILTTER) {
        return {
            ...state,
            chapterFiltterList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CHAPTER) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_CHAPTER_FOR_DEFFARENTS_PARAMS) {
        return {
            ...state,
            typelist: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_BRANCH_TYPE_CHAPTER) {
        return {
            ...state,
            typelist: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_CHAPTER) {

        const chapterList = [...state.list];
        const index = chapterList.findIndex((item) => item.id === action.payload.id);
        chapterList[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: chapterList,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CHAPTER) {

        const chapterList = [...state.list];
        const index = chapterList.findIndex((item) => item.id === action.payload);
        chapterList.splice(index, 1);

        return {
            ...state,
            list: chapterList,
            showLoading: false,
        };
    }

    if (action.type === CHAPTER_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


