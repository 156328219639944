import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import RankingTextInput from './component/RankingTextInput';
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Pagination
} from "react-bootstrap";
import Moment from 'react-moment';

import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, subjectLoading } from '../../../store/actions/SujectActions';
import { getNonGroupSubjectsList } from '../../../store/actions/SujectActions';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getSubjectsFiltterList } from '../../../store/actions/SujectActions';
import { getSubjectsFiltterSubLibraryList } from '../../../store/actions/SujectActions';
import { removeLibrarySubscriptionData, updateLibrarySubscriptionStatusData, getLibrarySubscriptionData, getSubscriptionRankingListForLibrary } from '../../../store/actions/SubscriptionAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const LibrarySubscriptionList = (props) => {

    const dispatch = useDispatch();
    const { state } = useLocation();

    const [permission, setPermission] = useState("");

    const previousFilterData = props?.location.state?.previousPageFilterData;

    const [active, setActive] = useState(previousFilterData?.active ?? 1);
    const [category, setCategory] = useState(previousFilterData?.category ?? '');
    const [type, setType] = useState(previousFilterData?.type ?? '');
    const [subject, setSubject] = useState(previousFilterData?.subject ?? '');
    const [board, setBoard] = useState(previousFilterData?.board ?? '');
    const [classNo, setClassNo] = useState(previousFilterData?.classNo ?? '');


    useEffect(() => {
        console.log("@112>>>>>>>", active)
        permissionCheck();
        // getSubjectHandler()
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(active, category, type, subject, board, classNo, props.history));


    }, [category, type, subject, board, classNo]);

    useEffect(() => {
        getCategoryHandler();
        getTypeHandler();
        getBoardHandler();
        getClassHandler();
        getSubjectHandler();
    }, [previousFilterData]);

    /* delete confirmation */
    const onDeleteHandler = (id) => {
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                //dispatch(globalLoadingAction(true));
                dispatch(removeLibrarySubscriptionData(id, props.history));
            }
        })
    }

    /* change item */
    const onUpdateStatus = (item) => {
        dispatch(globalLoadingAction(true));
        dispatch(updateLibrarySubscriptionStatusData(item, props.history));
    }

    /* change category */
    const onCategoryHandler = (id) => {
        console.log("@112------------------")
        setType('');
        setBoard('');
        setClassNo('');
        setCategory(id);
        setActive(1)
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(active, id, type, subject, board, classNo, props.history));
        if (id == '') {
            onSubjectHandler('')
        }
    }

    /* get category list */
    const getCategoryHandler = () => {
        if (props.categoryList.length == 0) {
            dispatch(loadingToggleAction(true));
            dispatch(getExamCategory(props.history));
        }
    }

    /* change type */
    const onTypeHandler = (id) => {
        setType(id);
        setActive(1)
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(active, category, id, subject, board, classNo, props.history));
        if (id == '' && board == "" && category == "" && classNo == "") {
            onSubjectHandler('')
        }
    }

    /* get type list */
    const getTypeHandler = () => {
        if (props.typeList.length == 0) {
            dispatch(loadingAction(true));
            dispatch(getExamType(props.history));
        }
    }

    /* change subject */
    const onSubjectHandler = (id) => {

        setSubject(id);
        setActive(1)
        dispatch(globalLoadingAction(true));

        dispatch(getLibrarySubscriptionData(active, category, type, id, board, classNo, props.history));
    }

    const getSubjectHandler = () => {

        dispatch(subjectLoading(true));

        dispatch(getSubjectsFiltterSubLibraryList(board, type, category, classNo, props.history))

    }

    /* change board */
    const onBoardHandler = (id) => {
        setBoard(id);
        setActive(1)
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(active, category, type, subject, id, classNo, props.history));
        if (id == '' && category == "" && type == "" && classNo == "") {
            onSubjectHandler('')
        }
    }

    /* get board */
    const getBoardHandler = () => {
        if (props.boardList.length == 0) {
            dispatch(loadingBoardAction(true));
            dispatch(getBoardData(props.history));
        }
    }

    /* change class */
    const onClassHandler = (no) => {
        setClassNo(no);
        setActive(1)
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(active, category, type, subject, board, no, props.history));
        if (no == '' && board == "" && category == "" && type == "") {
            onSubjectHandler('')
        }
        // category, type, board,classNo, subject,
    }

    /* get class list */
    const getClassHandler = () => {
        if (props.classList.length == 0) {
            dispatch(loadingClassAction(true));
            dispatch(getClassData(props.history));
        }


    }

    /* pagination */
    const pageHandler = (data) => {
        setActive(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getLibrarySubscriptionData(data.selected + 1, category, type, subject, board, classNo, props.history));
    }

    /* change item on blur */
    const onBlurHandler = (val, id) => {
        dispatch(globalLoadingAction(true));
        dispatch(getSubscriptionRankingListForLibrary(val, id, active, category, type, subject, board, classNo, props.history));
    }


    const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
            if (item.id == 56) {
                setPermission(item);
            }
        });
    };

    /* reset filter */
    const resetHandler = () => {
        setActive(1)
        setCategory('')
        setType('')
        setBoard('')
        setSubject('')
        setClassNo('')

        const stateCopy = { ...props?.location.state?.previousPageFilterData };
		delete stateCopy.active;
		delete stateCopy.category;
		delete stateCopy.type;
		delete stateCopy.board;
		delete stateCopy.classNo;
		delete stateCopy.subject;
		props.history.replace({ state: stateCopy });
        
        /* active, category, type, board, classNo, subject */
    }

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>

                            <div className="row add-Question-list-view">

                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            {props.categoryLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={category}
                                            onChange={(e) => onCategoryHandler(e.target.value)}
                                            onClick={getCategoryHandler}
                                        >
                                            <option value="">Select Category</option>
                                            {
                                                props.categoryList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.category}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            {props.typeLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className={`form-control filter-form-control ${category == 1 ? 'content_disable' : null}`}
                                            value={type}
                                            // disabled={category == 1 ? true : false}
                                            onChange={(e) => onTypeHandler(e.target.value)}
                                            onClick={getTypeHandler}
                                        >
                                            <option value="">Select Exam type</option>
                                            {
                                                props.typeList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.type_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            {props.subjectLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>

                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={subject}
                                            onChange={(e) => onSubjectHandler(e.target.value)}
                                            onClick={getSubjectHandler}
                                        >
                                            <option value="">Select subject</option>
                                            {
                                                props.subjectList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            {/* {props.boardLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>} */}
                                            <i className="bi bi-caret-down-fill"></i>
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className={`form-control filter-form-control ${category == 2 ? 'content_disable' : null}`}
                                            value={board}
                                            // disabled={category == 2 ? true : false}
                                            onChange={(e) => onBoardHandler(e.target.value)}
                                            onClick={getBoardHandler}
                                        >
                                            <option value="">Select board</option>
                                            {
                                                props.boardList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            {props.classLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={classNo}
                                            onChange={(e) => onClassHandler(e.target.value)}
                                            onClick={getClassHandler}
                                        >
                                            <option value="">Select class</option>
                                            {
                                                props.classList.map((item, index) => {
                                                    return <option key={index} value={item.class_no}>{item.short_code}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-status-arrow-position">
                                            <i className="bi bi-caret-down-fill"></i>
                                        </span>

                                        <select
                                            defaultValue={"option"}
                                            className={`form-control filter-form-control ${board == "" && category == "" && type == "" && classNo == "" ? 'content_disable' : null}`}
                                            value={subject}
                                            onChange={(e) => onSubjectHandler(e.target.value)}
                                            onClick={getSubjectHandler}
                                        // disabled={board == "" && category == "" && type == "" && classNo == ""}
                                        >
                                            <option value="">Select subject</option>
                                            {
                                                props.subjectFilterSubLibrary.map((item, index) => {
                                                    return <option key={index} value={item.subject_id}>{item.subject_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-1 px-1 ">
                                    <button onClick={resetHandler} className={`btn btn-secondary ${category == '' && type == '' && board == '' && classNo == '' && subject == '' ? 'content_disable' : null}`}>Reset</button>
                                </div>
                            </div>
                            {
                                props.totalRecord == 0 ? (
                                    <div className='no_data'>
                                        <strong >No data found</strong>
                                    </div>) : (
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Exam Category</strong>
                                                </th>
                                                <th>
                                                    <strong>Exam Type</strong>
                                                </th>
                                                <th>
                                                    <strong>Board</strong>
                                                </th>
                                                <th>
                                                    <strong>Subject</strong>
                                                </th>
                                                <th>
                                                    <strong>Class</strong>
                                                </th>
                                                <th>
                                                    <strong>Ranking</strong>
                                                </th>
                                                <th>
                                                    <strong>Library Price</strong>
                                                </th>
                                                <th>
                                                    <strong>Sticker text</strong>
                                                </th>
                                                <th>
                                                    <strong>Date Time</strong>
                                                </th>
                                                {
                                                    permission.status == "true" && (
                                                        <th width={120}>
                                                            <strong>Status</strong>
                                                        </th>
                                                    )
                                                }

                                                {
                                                    (permission.edit == "true" || permission.delete == "true") && (
                                                        <th>
                                                            <strong>Action</strong>
                                                        </th>
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props.list.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.category}</td>
                                                        <td>{item.type_name ? item.type_name : 'NULL'}</td>
                                                        <td>{item.board_name ? item.board_name : 'NULL'}</td>
                                                        <td>{item.subject_name ? item.subject_name : 'NULL'}</td>
                                                        <td>{item.class}</td>
                                                        <td><RankingTextInput rank={item.ranking_no} onBlurHandler={(r) => onBlurHandler(r, item.id)} /></td>
                                                        <td>{item.library_price}</td>
                                                        <td>{item.sticker_text}</td>
                                                        <td><Moment format="D MMM YYYY hh:mm a" withTitle>{item.created_at}</Moment></td>
                                                        {
                                                            permission.status == "true" && (
                                                                <td>
                                                                    <Button
                                                                        disabled={props.showLoading}
                                                                        className="btn-xs"
                                                                        variant={item.status == 1 ? "success" : "danger"}
                                                                        onClick={() => onUpdateStatus(item)}
                                                                    >
                                                                        {item.status == 1 ? "Active" : "Inactive"}
                                                                    </Button>
                                                                </td>
                                                            )
                                                        }
                                                        <td>
                                                            <div className="d-flex">

                                                                {
                                                                    permission.edit == "true" && (
                                                                        <Link
                                                                            to={{
                                                                                pathname: '/update-library-subscription',
                                                                                state: { item, filterData: { active, category, type, board, classNo, subject, } }

                                                                            }}
                                                                            className="btn btn-warning shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>
                                                                    )
                                                                }

                                                                {
                                                                    permission.delete == "true" && (
                                                                        <Button
                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                            onClick={() => onDeleteHandler(item.id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }

                                        </tbody>

                                    </Table>
                                )
                            }



                            <div className="pagination-container">
                                <div className="page-result">
                                    {props.totalRecord !== 0 && <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}</p>}
                                </div>
                                {props.totalRecord > 10 ?
                                    <ReactPaginate
                                        containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
                                        pageClassName='page-item'
                                        pageLinkClassName='page-link'
                                        nextLabel={<i class="la la-angle-right"></i>}
                                        nextClassName='page-item'
                                        nextLinkClassName='page-link'
                                        breakLabel="..."
                                        previousLabel={<i class="la la-angle-left"></i>}
                                        previousClassName='page-item'
                                        previousLinkClassName='page-link'
                                        breakClassName='page-item'
                                        breakLinkClassName='page-link'
                                        renderOnZeroPageCount={null}
                                        activeClassName="active"
                                        onPageChange={pageHandler}
                                        pageRangeDisplayed={3}
                                        pageCount={props.totalPage}
                                        forcePage={active - 1}
                                    />
                                    : null}
                            </div>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>

        </Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        list: state.subscription.libraryList,
        totalPage: state.subscription.totalLibraryPage,
        totalRecord: state.subscription.totalLibraryRecord,
        categoryLoading: state.category.showLoading,
        categoryList: state.category.list,
        typeLoading: state.type.showLoading,
        typeList: state.type.list,
        subjectLoading: state.subject.showLoading,
        // subjectList: state.subject.subjectList,
        boardLoading: state.board.showLoading,
        boardList: state.board.list,
        classLoading: state.class.showLoading,
        classList: state.class.list,
        subjectList: state.subject.nonGroupSubjectList,
        subjectFiltterList: state.subject.subjectFiltterList,
        subjectFilterSubLibrary: state.subject.subjectFilterSubLibrary

    };
};
export default connect(mapStateToProps)(LibrarySubscriptionList);