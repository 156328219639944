import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";

import {
  getAcademicSessionData,
  removeAcademicSessionData,
  updateStatusAcademicSessionData,
} from "../../../store/actions/AcademicAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const AcademicSessionList = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const calendarRef = useRef();

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [active, setActive] = useState(1);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionData(
        category,
        category == 1 ? board : category == 2 ? type : "",
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        active,
        props.history
      )
    );
  }, []);

  /* delete confirmation modal */
  const onDeleteModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(
          removeAcademicSessionData(
            id,
            category,
            category == 1 ? board : category == 2 ? type : "",
            name,
            startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            endDate ? moment(endDate).format("YYYY-MM-DD") : "",
            active,
            props.list.length,
            props.history
          )
        );
      }
    });
  };

  /* updated item dtails */
  const onUpdateStatus = (item) => {
    dispatch(globalLoadingAction(true));
    dispatch(updateStatusAcademicSessionData(item, props.history));
  };

  /* go to add academic page */
  const addAcademicHandler = () => {
    history.push("add-academic-session");
    clearFilterdata();
  };

  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionData(
        category ? parseInt(category) : "",
        category == 1 ? board : category == 2 ? type : "",
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        data.selected + 1,
        props.history
      )
    );
  };

  /* delete handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  /* Category change */
  const onChangeCategoryHandler = (val) => {
    setCategory(val);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionData(
        val ? parseInt(val) : "",
        val == 1 ? board : val == 2 ? type : "",
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        1,
        props.history
      )
    );
    setActive(1);
  };

   /* Category Board */
  const onChangeBoardHandler = (val) => {
    setBoard(val);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionData(
        category ? parseInt(category) : "",
        val ? parseInt(val) : "",
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        1,
        props.history
      )
    );
    setActive(1);
  };

  /* Change Type */
  const onChangeTypeHandler = (val) => {
    setType(val);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionData(
        category ? parseInt(category) : "",
        val ? parseInt(val) : "",
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        1,
        props.history
      )
    );
    setActive(1);
  };

  /* Change Name */
  const onNameHander = (val) => {
    setName(val);
    setTimeout(() => {
      dispatch(globalLoadingAction(true));
      dispatch(
        getAcademicSessionData(
          category ? parseInt(category) : "",
          category == 1 ? board : category == 2 ? type : "",
          val,
          startDate ? moment(startDate).format("YYYY-MM-DD") : "",
          endDate ? moment(endDate).format("YYYY-MM-DD") : "",
          1,
          props.history
        )
      );
      setActive(1);
    }, 1000); // 1000 milliseconds = 1 second
  };

  /* data reset */
  const onResetHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(getAcademicSessionData("", "", "", "", "", 1, props.history));
    clearFilterdata();
  };

  /* clear filter data */
  const clearFilterdata = () => {
    setCategory("");
    setName("");
    setBoard("");
    setType("");
    setStartDate("");
    setEndDate(null);
    setActive(1);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="filter-group-container">
                <div className="add-filter-group">
                  <Button
                    className="btn btn-success session-filter-btn mb-3"
                    variant={"success"}
                    onClick={() => addAcademicHandler()}
                  >
                    Add Academic
                  </Button>
                </div>
                <div className="session-filter-container">
                  <div className="row me-0 ms-0">
                    <div className="col-sm-3 px-1">
                      <div className="basic-dropdown">
                        <span className="drop_down_arrow add-Question-status-arrow-position">
                          <i className="bi bi-caret-down-fill"></i>
                        </span>
                        <select
                          // defaultValue={"option"}
                          className="form-control filter-form-control"
                          value={category}
                          onChange={(e) =>
                            onChangeCategoryHandler(e.target.value)
                          }
                        >
                          <option value="">Select Category</option>
                          {props.categoryList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.category}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    {category == 1 ? (
                      <div className="col-sm-3 px-1">
                        <div className="basic-dropdown">
                          <span className="drop_down_arrow add-Question-status-arrow-position">
                            <i className="bi bi-caret-down-fill"></i>
                          </span>
                          <select
                            // defaultValue={"option"}
                            className="form-control filter-form-control"
                            value={board}
                            onChange={(e) =>
                              onChangeBoardHandler(e.target.value)
                            }
                          >
                            <option value="">Select Board</option>
                            {props.boardList.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    ) : category == "" ? (
                      <div className="col-sm-3 px-1">
                        <div className="basic-dropdown">
                          <span className="drop_down_arrow add-Question-status-arrow-position">
                            <i className="bi bi-caret-down-fill"></i>
                          </span>
                          <select
                            // defaultValue={"option"}
                            className="form-control filter-form-control"
                            disabled
                          >
                            <option value="">Select Board/Type</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      <div className="col-sm-3 px-1">
                        <div className="basic-dropdown">
                          <span className="drop_down_arrow add-Question-status-arrow-position">
                            <i className="bi bi-caret-down-fill"></i>
                          </span>
                          <select
                            // defaultValue={"option"}
                            className="form-control filter-form-control"
                            value={type}
                            onChange={(e) =>
                              onChangeTypeHandler(e.target.value)
                            }
                          >
                            <option value="">Select type</option>
                            {props.typeList.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.type_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-sm-3 px-1">
                      <div className="form-group mb-3">
                        <input
                          className="form-control session-filter-input"
                          placeholder="Enter the name"
                          value={name}
                          onChange={(e) => onNameHander(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 px-1">
                      <div className="date-control-view session-date-control">
                        <DatePicker
                          ref={calendarRef}
                          placeholderText="Select date range"
                          selectsRange
                          selected={startDate}
                          onChange={onDateHandler}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          startDate={startDate}
                          endDate={endDate}
                          dropdownMode="select"
                        />
                        <button
                          className="picker-btn"
                          onClick={() => calendarRef.current.setOpen(true)}
                        >
                          <i class="fa-solid fa-calendar-days"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reset-btn-view">
                  <button
                    onClick={() => onResetHandler()}
                    className="btn btn-secondary session-filter-btn"
                  >
                    Reset
                  </button>
                </div>
              </div>

              {props.list.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Category</strong>
                      </th>
                      <th>
                        <strong>Board/Type</strong>
                      </th>
                      <th>
                        <strong>Session name</strong>
                      </th>
                      <th>
                        <strong>Session date</strong>
                      </th>
                      <th>
                        <strong>Create Date</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.category}</td>
                          <td>{item.exam_board_type_name}</td>
                          <td>{item.session_name}</td>
                          <td>
                            {item.session_start_date} to {item.session_end_date}
                          </td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.created_at}
                            </Moment>
                          </td>
                          <td>
                            <Button
                              //disabled={props.showLoading}
                              className="btn-xs"
                              variant={item.status == 1 ? "success" : "danger"}
                              onClick={() => onUpdateStatus(item)}
                            >
                              {item.status == 1 ? "Active" : "Inactive"}
                            </Button>
                          </td>

                          <td>
                            <div className="d-flex">
                              <Link
                                onClick={() => {
                                  history.push({
                                    pathname: "/update-academic-session",
                                    state: item,
                                    filter: {
                                      name: name,
                                      category: category,
                                      board: board,
                                      type: type,
                                      start_date: startDate,
                                      end_date: endDate,
                                      active: active,
                                    },
                                  });
                                }}
                                className="btn btn-warning shadow btn-xs sharp me-1"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>

                              <Button
                                className="btn btn-danger shadow btn-xs sharp"
                                onClick={() => onDeleteModal(item.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div style={{ marginTop: "50px" }} className="no_data">
                  <strong>No data found</strong>
                </div>
              )}

              {props.list.length > 0 && (
                <div className="pagination-container">
                  {props.list.length > 0 ? (
                    <div className="page-result">
                      <p>
                        Showing: {(active - 1) * 10 + 1} -{" "}
                        {10 * active - (10 - props.list.length)} of({" "}
                        {props.totalRecode} items)
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {props.totalPage > 1 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i class="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i class="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      forcePage={active - 1}
                      pageCount={props.totalPage}
                    />
                  ) : null}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.academic.list,
    totalPage: state.academic.totalPage,
    totalRecode: state.academic.totalRecode,
    categoryList: state.category.list,
    categoryLoading: state.category.showLoading,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeList: state.type.list,
  };
};

export default connect(mapStateToProps)(AcademicSessionList);
