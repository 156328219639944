import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import swal from "sweetalert";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button,
	Pagination
} from "react-bootstrap";

import { removeTimeData, getTimeData } from '../../../store/actions/TimeConfigurationAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const TimeConfigurationList = (props) => {

	const [active, setActive] = useState(1);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getTimeData(active, props.history));
	}, []);

	/* increment handler */
	const onIncrementHandler = () => {
		if (active < props.totalPage) {
			setActive(active + 1);
			dispatch(globalLoadingAction(true));
			dispatch(getTimeData(active + 1, props.history));
		}
	}

	/* decrement handler */
	const onDecrementHandler = () => {
		if (active > 1) {
			setActive(active - 1);
			dispatch(globalLoadingAction(true));
			dispatch(getTimeData(active - 1, props.history));
		}
	}

	/* delect confirmation */
	const onDeleteHandler = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeTimeData(i, props.history));
			}
		})
	}



	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Category</strong>
										</th>
										<th>
											<strong>Class</strong>
										</th>
										<th>
											<strong>Subject</strong>
										</th>
										<th>
											<strong>Competive</strong>
										</th>
										<th>
											<strong>Set</strong>
										</th>
										<th>
											<strong>Module</strong>
										</th>
										<th>
											<strong>Mock</strong>
										</th>
										<th>
											<strong>Action</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.category}</td>
												<td>{item.class}</td>
												<td>{item.subject_name}</td>
												<td>{item.competive_exam_duration}</td>
												<td>{item.set_exam_duration}</td>
												<td>{item.module_exam_duration}</td>
												<td>{item.mock_exam_duration}</td>
												<td>
													<div className="d-flex">
														<Link
															to={{
																pathname: '/update-time-configuration',
																state: item
															}}
															href="#"
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
														<Button
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => onDeleteHandler(item.id)}
														>
															<i className="fa fa-trash"></i>
														</Button>
													</div>
												</td>
											</tr>
										})
									}
								</tbody>
							</Table>

							<div className="pagination-container">
								<div className="page-result">
									<p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}</p>
								</div>
								<Pagination
									//size={size}
									className="mt-2 pagination pagination-circle">
									<li className="page-item page-indicator">
										<Button className="page-link" onClick={onDecrementHandler} disabled={active <= 1 ? true : false}>
											<i className="la la-angle-left" />
										</Button>
									</li>
									<Pagination.Item
										active={active}
									>
										{active}
									</Pagination.Item>

									<li className="page-item page-indicator">
										<Button className="page-link" onClick={onIncrementHandler} disabled={active == props.totalPage ? true : false}>
											<i className="la la-angle-right" />
										</Button>
									</li>
								</Pagination>

							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.time.showLoading,
		list: state.time.list,
		totalPage: state.time.totalPage,
		totalRecord: state.time.totalRecord,
	};
};

export default connect(mapStateToProps)(TimeConfigurationList);