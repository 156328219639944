import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { validateText, validateTextOnly } from '../../../utility/Utility';
import { addExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getExamType } from '../../../store/actions/ExamTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const CreateExamSubtype = (props) => {

	let errorsObj = { subtype: '', examtype: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [subtype, setSubtype] = useState('');
	const [examtype, setExamtype] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getExamType(props.history));
	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (examtype === '') {
			errorObj.examtype = 'Exam type is Required';
			error = true;
		}
		if (subtype === '') {
			errorObj.subtype = 'Exam subtype is Required';
			error = true;

		// }else if (validateTextOnly(subtype) === false) {
		}else if ((!/^[a-zA-Z0-9\s]+$/.test(subtype))) {
			errorObj.subtype = 'Only alpha numeric characters are allowed'; /* Invalid Exam subtype */
			error = true;
		}
		
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(addExamSubType(examtype, subtype, props.history));
		setSubtype('');
		setExamtype('');

	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam type<span className="text-danger">*</span></strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
														<select
															defaultValue={"option"}
															className="form-control form-control"
															value={examtype}
															onChange={(e) => setExamtype(e.target.value)}
														>
															<option value="">Select exam type</option>
															{
																props.typeList.map((item, index) => {
																	return <option key={index} value={item.id}>{item.type_name}</option>
																})
															}
														</select>
														</div>
														{errors.examtype && <div className="text-danger fs-12">{errors.examtype}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam sub type name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the sub type name" value={subtype} onChange={(e) => setSubtype(e.target.value)} />
														{errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.subtype.showLoading,
		typeList: state.type.list,
	};
};

export default connect(mapStateToProps)(CreateExamSubtype);