import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function SubscriptionDetails(
  datediff,
  board,
  className,
  pincode,
  category,
  subject,
  services,
  fsd,
  nbf,
  subscribe_method
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  
  const postData = {
    datediff,
    board,
    class:className,
    pincode,
    category,
    subject,
    services,
    fsd,
    nbf,
    subscribe_method
  };
  console.log(postData)
 
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_SUBSCRIPTION_DETAILS,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
