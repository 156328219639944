import swal from "sweetalert";
import { getFeedBack, sentStstus } from "../../services/FeedBackService";
import { GET_FEEDBACK_QUESTION, FEEDBACK_LOADING, FEEDBACK_QUESTION_DELETE } from "../constants";
import { SentQuestion } from "../../services/FeedBackService";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { DeleteQuestion } from "../../services/FeedBackService";
import { UpdateQuestion } from "../../services/FeedBackService";
import { showSuccess } from "../../utility/Utility";


export function updateFeedback(recid,question,questions_type) {
  


  return (dispatch) => {
    UpdateQuestion(recid,question,questions_type)
      .then((response) => {
        dispatch(globalLoadingAction(false));
        // showSuccess("Updated successfully")
        utility.showSuccess(response.data.msg);
        
       
      })
      .catch((error) => {
        console.log("===", error);
      });
  };
}



export function deleteFeedback(recid) {
    console.log(recid)
  
    return (dispatch) => {
      DeleteQuestion(recid)
      
        .then((response) => {
          // console.log("response-----", response)
          utility.showSuccess(response.data.msg);
          dispatch(deleteFeedBackAction(1));
          // dispatch(getFeedBackData());
        })
        .catch((error) => {
          console.log("===", error);
        });
    };
  }

export function addFeedback(question,questions_type) {
  return (dispatch) => {
    SentQuestion(question,questions_type)
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log("===", error);
      });
  };
}

export default function statusUpdate(recid, statuss) {
  return (dispatch) => {
    sentStstus(recid, statuss)
      .then((response) => {
        dispatch(getFeedBackData());
      })
      .catch((error) => {
        console.log("===", error);
      });
  };
}

export function getFeedBackData(type, history) {
  return (dispatch) => {
    getFeedBack(type)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getFeedBackAction(response.data.data));
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getFeedBackAction(data) {
  return {
    type: GET_FEEDBACK_QUESTION,
    payload: data,
  };
}
export function loadingFeedBackAction(status) {
  return {
    type: FEEDBACK_LOADING,
    payload: status,
  };
}
export function deleteFeedBackAction(status) {
  return {
    type: FEEDBACK_QUESTION_DELETE,
    payload: status,
  };
}