import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Alert, Button, Modal } from "react-bootstrap";


import { removeQuestionImageData } from '../../../../store/actions/ExamContentAction';

const QuestionArea = (props) => {

    let history = useHistory();
    const dispatch = useDispatch();


    const [questiobody, setQuestiobody] = useState(props.question);
    const [modal, setModal] = useState(false);
    const [questiobodyEvent, setQuestiobodyEvent] = useState(null);
    const [count, setCount] = useState(0);
    const [imagesArray, setImagesArray] = useState(props.questionImg);

    const onCharacterHandler = () => {
        setModal(true);
    }

    const onChangeQuestionBody = (e) => {
        setQuestiobodyEvent(e.target);
        setQuestiobody(e.target.value);
        props.onQuestionInput(e.target.value);
    }

    const onClickQuestionBody = (e) => {
        setQuestiobodyEvent(e.target);
    }

    const addCharacterHandler = (tag) => {
        setModal(false);
        if (questiobodyEvent == null) {
            setQuestiobody(questiobody.concat(tag));
            props.onQuestionInput(questiobody.concat(tag));
        } else {
            let start = questiobodyEvent.selectionStart;
            let end = questiobodyEvent.selectionEnd;
            let before = questiobodyEvent.value.substring(0, start);
            let after = questiobodyEvent.value.substring(end, questiobodyEvent.value.length);
            questiobodyEvent.value = (before + tag + after);
            questiobodyEvent.selectionStart = questiobodyEvent.selectionEnd = start + tag.length;
            questiobodyEvent.focus();
            setQuestiobody(questiobodyEvent.value);
            props.onQuestionInput(questiobodyEvent.value);
        }
    }

    const onQuestionhandle = (event) => {

        let file = event.target.files[0];
        const fr = new FileReader();
        return new Promise((resolve, reject) => {
            fr.addEventListener("load", e => {
                let image = fr.result;
                addQuestionContent(image, file);
            });
            fr.addEventListener("error", e => {
                reject();
            });
            fr.readAsDataURL(file);
        });
    }

    const addQuestionContent = (img, file) => {

        let str = '';
        let len = imagesArray.length;
        let arrayData = [...imagesArray];
        let arrayObj = { 'name': `#Img${len + 1}`, 'path': img, 'file': file };
        arrayData.push(arrayObj);

        arrayData.forEach((item, index) => {
            str = `#Img${len + 1}`;
            //add = questiobody.concat(str);
        });
        if (questiobodyEvent == null) {
            props.onQuestionInput(questiobody.concat(str));
            props.onQuestionImg(arrayData);
            setQuestiobody(questiobody.concat(str));
            setImagesArray(arrayData);


        } else {

            let start = questiobodyEvent.selectionStart;
            let end = questiobodyEvent.selectionEnd;
            let before = questiobodyEvent.value.substring(0, start);
            let after = questiobodyEvent.value.substring(end, questiobodyEvent.value.length);
            questiobodyEvent.value = (before + str + after);
            questiobodyEvent.selectionStart = questiobodyEvent.selectionEnd = start + str.length;
            questiobodyEvent.focus();
            setQuestiobody(questiobodyEvent.value);
            props.onQuestionInput(questiobodyEvent.value);
            setImagesArray(arrayData);
            props.onQuestionImg(arrayData);
        }
        setCount(count + 1);

    }

    const onRemove = (item, index) => {
        //console.log('result',item)
        //
        //return;
        let array = [...imagesArray];
        let result = questiobody.replace(`${item.name}`, '');

        for (let x = index + 1; x <= array.length; x++) {
            result = result.replace(`#Img${x}`, `#Img${x - 1}`);
        }
        array.splice(index, 1);

        if (!item.file) {
            dispatch(removeQuestionImageData(props.questionId, props.questionName, index, history));
        }

        for (let x = 0; x < array.length; x++) {
            array[x].name = `#Img${x + 1}`;
        }
        setImagesArray(array);
        setQuestiobody(result);
        props.onQuestionImg(array);
        props.onQuestionInput(result);
    }

    return (
        <>
            <div className="textarea-container">
                <button onClick={() => onCharacterHandler()} type='button' className="chra-btn"><i class="fa-solid fa-hashtag"></i></button>
                <textarea
                    className="form-control"
                    rows={props.rows}
                    value={questiobody}
                    onClick={(e) => onClickQuestionBody(e)}
                    onChange={(e) => onChangeQuestionBody(e)}
                />
                {/* {errors.quesBody && <div className="text-danger fs-12">{errors.quesBody}</div>} */}
            </div>
            <div className="form-control multi-Image-View mb-3">

                <div className="custom-file-input">
                    <label htmlFor={props.id}><i class="fa-solid fa-plus"></i></label>
                    <input
                        type="file"
                        id={props.id}
                        multiple
                        accept="image/*"
                        onChange={onQuestionhandle}
                        hidden
                    />
                </div>

                <div className="main-img-preview">
                    {
                        imagesArray.map((item, index) => {
                            return <div key={index} className="img-preview-item">
                                <img src={item.path} />
                                <button
                                    type='button'
                                    color="danger"
                                    onClick={() => onRemove(item, index)}
                                >
                                    <i class="fa-regular fa-circle-xmark"></i>
                                </button>
                                <p>{item.name}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            <Modal
                className="fade bd-example-modal-lg"
                show={modal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Special Character List</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setModal(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="character-image-view">
                        {
                            props.listData.map((item, index) => {
                                return <div className="character-image-item" onClick={() => addCharacterHandler(item.tag_name)} key={index}>
                                    <h4>{item.tag_name}</h4>
                                </div>
                            })
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger light"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default QuestionArea;