import { React, Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getExamType } from "../../../store/actions/ExamTypeAction";
import { useDispatch, connect } from "react-redux";
import { getMisUserActivityData } from "../../../store/actions/MisUserActivityAction";
import moment from "moment";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UserActivity = (props) => {
  const dispatch = useDispatch();
  const [courseComplition, setCourseComplition] = useState("")
  const [category, setcategory] = useState("");
  const [className, setClassName] = useState("");
  const [examType, setExamType] = useState("");
  const [board, setBoard] = useState("");
  const [services, setServices] = useState("");

  useEffect(() => {

    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(getExamType());

  }, []);

  useEffect(() => {
    getData();
  }, [courseComplition, className, board, services])

  const getData = () => {
    dispatch(globalLoadingAction(true))
    dispatch(getMisUserActivityData(board, className, services, courseComplition))

  }

  const resetHandler = () => {
    setCourseComplition('')
    setClassName('')
    setServices('')
  }
  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className=" filtersAlign mt-3" style={{ marginRight: 25 }}>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    value={courseComplition}
                    className="form-control filter-form-control-input"
                    onChange={(e) => {
                      setCourseComplition(e.target.value);
                    }}
                  >
                    <option value="">Select Course Completion</option>
                    <option value="50">50%</option>
                    <option value="60">60%</option>
                    <option value="70">70%</option>
                    <option value="100">100%</option>
                  </select>
                </div>
              </div>

              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    value={className}
                    className="form-control filter-form-control-input"
                    onChange={(e) => {
                      setClassName(e.target.value);
                    }}
                  >
                    <option value="">Select Class</option>
                    {props.classList.map((item) => {
                      return (
                        <option value={item.class_no}>{item.class_no}</option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    value={category}
                    className="form-control filter-form-control-input"
                    onChange={(e) => {
                      setcategory(e.target.value);
                    }}
                  >
                    <option value="">Select Category</option>
                    <option value="1">Compititive</option>
                    <option value="2">Scholastic</option>
                  </select>
                </div>
              </div> */}

              {category == "1" && (
                <div className="me-2">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={examType}
                      className="form-control filter-form-control-input"
                      onChange={(e) => {
                        setExamType(e.target.value);
                      }}
                    >
                      <option value="">Select Exam Type</option>

                      {props.typeList.map((item) => {
                        return <option value={item.id}>{item.type_name}</option>;
                      })}

                    </select>
                  </div>
                </div>
              )}

              {category == "2" && (
                <div className="me-2">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={board}
                      className="form-control filter-form-control-input"
                      onChange={(e) => {
                        setBoard(e.target.value);
                      }}
                    >
                      <option value="">Select Board</option>

                      {props.boardList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}

                    </select>
                  </div>
                </div>
              )}

              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    value={services}
                    className="form-control filter-form-control-input"
                    onChange={(e) => {
                      setServices(e.target.value);
                    }}
                  >
                    <option value="">Select Services</option>
                    <option value="1">FSD</option>
                    <option value="2">CMC</option>
                    <option value="3">NBF</option>
                  </select>
                </div>
              </div>

              <div style={{marginTop:'5px'}} className="col-sm-1 px-1 ">
                <button onClick={resetHandler} className="btn btn-secondary">Reset</button>
              </div>

            </div>
            <Card.Body>

            {props.subscribedUserActivityList.length == 0 ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                 <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>SL</strong>
                    </th>
                    <th>
                      <strong>ID</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Mobile</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>Subscription Date</strong>
                    </th>
                    <th>
                      <strong>Service Expire</strong>
                    </th>
                    <th>
                      <strong>Service Consumed</strong>
                    </th>
                    <th>
                      <strong>Service Not Consumed</strong>
                    </th>
                    <th>
                      <strong>Service Not Subscribed</strong>
                    </th>
                    <th>
                      <strong>Mail Shoted</strong>
                    </th>
                    <th>
                      <strong>Shoted Date</strong>
                    </th>
                    <th>
                      <strong>Date of Reminder Mail</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {
                    props.subscribedUserActivityList.map((item, index) => {
                      return (
                        <tr kry={index}>
                          <td>{index + 1}</td>
                          <td>{item.student_id}</td>
                          <td>{item.fname + " " + item.lname}</td>
                          <td>{item.mobile}</td>
                          <td>{item.email}</td>
                          <td style={{ textAlign: 'center' }}>{moment(item.created_at).format("DD-MM-YYYY")}</td>
                          <td></td>
                          <td style={{ textAlign: 'center' }}>{item.exam_completed}</td>
                          <td style={{ textAlign: 'center' }}>{item.exam_pending}</td>
                          <td style={{ textAlign: 'center' }}>{item.not_subscribed}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
                </>)}

             
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log(state.subscribedUserActivity.list)
  return {
    classList: state.class.list,
    boardList: state.board.list,
    typeList: state.type.list,
    subscribedUserActivityList: state.subscribedUserActivity.list
  };
};

export default connect(mapStateToProps)(UserActivity);
