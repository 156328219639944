import {
    TIME_CONFIGURATION_LOADING,
    ADD_TIME_CONFIGURATION,
    GET_TIME_CONFIGURATION,
    REMOVE_TIME_CONFIGURATION,
    UPDATE_TIME_CONFIGURATION
} from '../constants';

const initialState = {
    list: [],
    totalPage: 0,
    totalRecord: 0,
    showLoading: false,
};

export function TimeConfigurationReducer(state = initialState, action) {

    if (action.type === ADD_TIME_CONFIGURATION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_TIME_CONFIGURATION) {
        return {
            ...state,
            list: action.payload.data,
            totalPage: action.payload.total_length,
            totalRecord: action.payload.total_record,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_TIME_CONFIGURATION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_TIME_CONFIGURATION) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === TIME_CONFIGURATION_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


