import {
    CONTENT_LANGUAGE_VALIDATOR_LOADING,
    GET_CONTENT_LANGUAGE_VALIDATOR_LIST,
    UPDATE_CONTENT_LANGUAGE_VALIDATOR,
    UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS,
    REMOVE_CONTENT_LANGUAGE_VALIDATOR
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
    totalPage:0,
    totalRecord:0,
};

export function ContentLanguageValidatorReducer(state = initialState, action) {

    if (action.type === GET_CONTENT_LANGUAGE_VALIDATOR_LIST) {
        return {
            ...state,
            list: action.payload.data,
            totalPage:action.payload.page_count,
            totalRecord:action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CONTENT_LANGUAGE_VALIDATOR) {
        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === CONTENT_LANGUAGE_VALIDATOR_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


