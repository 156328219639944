import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as utility from "../../../utility/Utility";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  getPermisstionData,
  loadingPermissionAction,
} from "../../../store/actions/PermissionAction";
import { addRoleData } from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const CreateRole = (props) => {
  const dispatch = useDispatch();

  const [permissionList, setPermissionList] = useState([]);
  const chackbox = document.querySelectorAll(".form-check input");
  const [roleName, setRollName] = useState("");
  const [description, setDescription] = useState("");

  let errorsObj = {
    roleName: "",
    description: "",
    permissionList: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const history = useHistory();

  useEffect(() => {
    dispatch(getPermisstionData(true));
  }, []);

  /* permission handler */
  const onMainPermissionHandler = (event, item) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    if (event.target.checked) {
      arry.push({
        id: item.id,
        edit: item.edit.value,
        delete: item.delete.value,
        status: item.status.value,
      });
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          arry.push({
            id: dataArray[i].id,
            edit: dataArray[i].edit.value,
            delete: dataArray[i].delete.value,
            status: dataArray[i].status.value,
          });
          document.querySelector(`#check${dataArray[i].id}`).checked = true;
        }
      }
    } else {
      const index = arry.findIndex((val) => val.id === item.id);
      arry.splice(index, 1);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          const chilsIndex = arry.findIndex(
            (val) => val.id === dataArray[i].id
          );
          arry.splice(chilsIndex, 1);
          document.querySelector(`#check${dataArray[i].id}`).checked = false;
        }
      }
    }

    setPermissionList(arry);
  };

  /* subpermission handler */
  const onSubPermissionHandler = (event, item, data) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    let parentId = false;

    for (let i = 0; arry.length > i; i++) {
      if (arry[i].id == item.id) {
        parentId = true;
        break;
      }
    }
    if (event.target.checked) {
      if (parentId) {
        arry.push({
          id: data.id,
          edit: data.edit.value,
          delete: data.delete.value,
          status: data.status.value,
        });
      } else {
        document.querySelector(`#check${item.id}`).checked = true;
        arry.push(
          {
            id: item.id,
            edit: item.edit.value,
            delete: item.delete.value,
            status: item.status.value,
          },
          {
            id: data.id,
            edit: data.edit.value,
            delete: data.delete.value,
            status: data.status.value,
          }
        );
      }
    } else {
      let ins = null;
      const subIndex = arry.findIndex((val) => val.id === data.id);
      arry.splice(subIndex, 1);

      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          for (let j = 0; arry.length > j; j++) {
            if (arry[j].id == dataArray[i].id) {
              ins = true;
              break;
            }
          }
        }
      }
      if (!ins) {
        const parentIndex = arry.findIndex((val) => val.id === item.id);
        arry.splice(parentIndex, 1);
        document.querySelector(`#check${item.id}`).checked = false;
      }
    }

    setPermissionList(arry);
  };

  /* on status handler */
  const onStatusHandler = (event, status, item) => {
    let arry = [...permissionList];
    const index = arry.findIndex((val) => val.id === item.id);
    if (status == 1) {
      arry[index].edit = event.target.checked;
    } else if (status == 2) {
      arry[index].delete = event.target.checked;
    } else {
      arry[index].status = event.target.checked;
    }
    setPermissionList(arry);
  };

  /* form submit */
  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (roleName === "") {
      errorObj.roleName = "Role is Required";
      error = true;
    }
    if (description === "") {
      errorObj.description = "Description is Required";
      error = true;
    }
    if (permissionList.length == 0) {
      errorObj.permissionList = "Permission is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(addRoleData(roleName, description, permissionList, history));
  };

  return (
    <>
      <div className="row">
        {/* {console.log('ps...list.....', props.permissionList)} */}
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form fromContainer">
                    <form onSubmit={onSubmit}>
                      <div className="row RoleDescription">
                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Role Name
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter role name"
                            value={roleName}
                            onChange={(e) => setRollName(e.target.value)}
                          />
                          {errors.roleName && (
                            <div className="text-danger fs-12">
                              {errors.roleName}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Description
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <label className="mb-2">
                        <strong>
                          Permission
                          <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <div
                        className="permission-list-view"
                        style={{ backgroundColor: "#8300000a" }}
                      >
                        {props.permissionList.map((item, i) => {
                          if (item.parent_menu == 0) {
                            return (
                              <div
                                key={item.id + i}
                                className="main-form-check-view"
                              >
                                <div className="form-check mt-2 mb-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`check${item.id}`}
                                    name={`check${item.id}`}
                                    //value={props.totalModules}
                                    //checked={isLibrary}
                                    onChange={(e) =>
                                      onMainPermissionHandler(e, item)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`check${item.id}`}
                                  >
                                    {item.menu_name}
                                  </label>
                                </div>

                                <div className="sub-form-check-view colorPaddingSubFrom">
                                  {props.permissionList.map((data, j) => {
                                    if (item.id == data.parent_menu) {
                                      return (
                                        <div
                                          key={data.id + j}
                                          className="form-check mt-2 me-3"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`check${data.id}`}
                                            name={`check${data.id}`}
                                            //value={props.totalModules}
                                            //checked={isLibrary}
                                            onChange={(e) =>
                                              onSubPermissionHandler(
                                                e,
                                                item,
                                                data
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`check${data.id}`}
                                          >
                                            {data.menu_name}
                                          </label>
                                          {data.action ? (
                                            <div className="permission-submenu">
                                              {data.edit.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        1,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Edit
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.delete.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDelete${data.id}`}
                                                    name={`checkDelete${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        2,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDelete${data.id}`}
                                                  >
                                                    Delete
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.status.active ? (
                                                <div className="form-check">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkStatus${data.id}`}
                                                    name={`checkStatus${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        3,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkStatus${data.id}`}
                                                  >
                                                    Status
                                                  </label>
                                                </div>
                                              ) : null}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            );
                          }
                        })}

                        {errors.permissionList && (
                          <div className="text-danger fs-12">
                            {errors.permissionList}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-4 submitButtonRole">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block category-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  console.log("checking permission list",state.permission.list)
  return {
    permissionList: state.permission.list,
  };
};

export default connect(mapStateToProps)(CreateRole);
