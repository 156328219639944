import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisFailedRegistration(datediff,user_problem,action_taken) {
    let getData = localStorage.getItem("userDetails");
    let token = JSON.parse(getData).token;
   
    const postData = {
        datediff,user_problem,action_taken
    };
   
    return axios({
      url: GlobalConfigs.API_URL + Apis.GET_FAILED_REGISTRATION_API,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    });
  }
  

  export function UpdateFailedRegistration(id,message,reply_mail_date,status) {
    let getData = localStorage.getItem("userDetails");
    let token = JSON.parse(getData).token;
   
    const postData = {
      id,message,reply_mail_date,status
    };
    return axios({
      url: GlobalConfigs.API_URL + Apis.UPDATE_FAILED_REGISTRATION_API,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: postData,
    });
  }