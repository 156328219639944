import swal from "sweetalert";
import {
    getContentValidator,
    updateContentValidator,
    editContentValidatorStatus,
    removeContentValidator
} from '../../services/ContentValidatorServices';

import {
    CONTENT_VALIDATOR_LOADING,
    GET_CONTENT_VALIDATOR_LIST,
    UPDATE_CONTENT_VALIDATOR,
    UPDATE_CONTENT_VALIDATOR_STATUS,
    REMOVE_CONTENT_VALIDATOR
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function getContentValidatorData(page, name, email,phoneno, history) {
    return (dispatch) => {
        getContentValidator(page, name, email,phoneno)
            .then((response) => {
                dispatch(getContentValidatorAction(response.data));
                //utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateContentValidatorData(id, name, email, phone, history) {
    return (dispatch) => {
        updateContentValidator(id, name, email, phone)
            .then((response) => {
                dispatch(updateContentValidatorAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateContentValidatorStatus(item, history) {
    return (dispatch) => {
        editContentValidatorStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateContentValidatorStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeContentValidatorDetails(id,active, history) {
    return (dispatch) => {
        removeContentValidator(id)
            .then((response) => {
                getContentValidatorData(active, '', '','',history)
                dispatch(removeContentValidatorAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getContentValidatorAction(data) {
    return {
        type: GET_CONTENT_VALIDATOR_LIST,
        payload: data,
    };
}
export function updateContentValidatorAction(data) {
    return {
        type: UPDATE_CONTENT_VALIDATOR,
        payload: data,
    };
}
export function updateContentValidatorStatusAction(data) {
    return {
        type: UPDATE_CONTENT_VALIDATOR_STATUS,
        payload: data,
    };
}
export function removeContentValidatorAction(id) {
    return {
        type: REMOVE_CONTENT_VALIDATOR,
        payload: id,
    };
}
export function loadingContentValidatorAction(status) {
    return {
        type: CONTENT_VALIDATOR_LOADING,
        payload: status,
    };
}
