import swal from "sweetalert";
import {
    getSettings,
    updateSettings,
    updateTermsCondition,
    updatePrivacyPolicy,
    callMigrationTasks
} from '../../services/SettingsServices';

import {
    SETTINGS_LOADING,
    GET_SETTINGS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function getSettingsData(history) {
    return (dispatch) => {
        getSettings()
            .then((response) => {
                console.log('hello world',response.data);
                dispatch(getSettingsAction(response.data.data));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateSettingsData(gst, text, history) {
    return (dispatch) => {
        updateSettings(gst, text)
            .then((response) => {
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTermsConditionData(terms_condition, history) {
    return (dispatch) => {
        updateTermsCondition(terms_condition)
            .then((response) => {
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updatePrivacyPolicyData(privacy_policy, history) {
    return (dispatch) => {
        updatePrivacyPolicy(privacy_policy)
            .then((response) => {
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function callMigrationTasksData(history) {
    return (dispatch) => {
        callMigrationTasks()
            .then((response) => {
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSettingsAction(data) {
    return {
        type: GET_SETTINGS,
        payload: data,
    };
}

export function loadingSettingsAction(status) {
    return {
        type: SETTINGS_LOADING,
        payload: status,
    };
}
