import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getLanguageValidator(page, name, email,phoneno) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        page,
        name,
        email,
        phoneno
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_LANGUAGE_VALIDATOR_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateLanguageValidator(id, name, email, phone) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        name,
        email,
        phone
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LANGUAGE_VALIDATOR_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function updateLanguageValidatorStatus(id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LANGUAGE_VALIDATOR_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeLanguageValidator(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_LANGUAGE_VALIDATOR_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


