import swal from "sweetalert";
import {
    addSubType,
    getSubType,
    getSubTypeById,
    updateSubType,
    removeSubType,
    updateStatusSubType
} from '../../services/ExamSubTypeServices';

import {
    SUB_TYPE_LOADING,
    ADD_EXAM_SUB_TYPE,
    GET_EXAM_SUB_TYPE,
    UPDATE_EXAM_SUB_TYPE,
    REMOVE_EXAM_SUB_TYPE,
    UPDATE_STATUS_EXAM_SUB_TYPE
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addExamSubType(examtype, subtype, history) {
    return (dispatch) => {
        addSubType(examtype, subtype)
            .then((response) => {
                dispatch(addSubTypeAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getExamSubType(item, history) {
    return (dispatch) => {
        getSubType()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSubTypeAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getExamSubTypeById(type_id, history) {
    return (dispatch) => {
        getSubTypeById(type_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSubTypeAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateExamSubType(id, examtype, subtype, history) {
    return (dispatch) => {
        updateSubType(id, examtype, subtype)
            .then((response) => {
                dispatch(updateSubTypeAction(true));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingSubTypeAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateStatusExamSubType(item, history) {
    return (dispatch) => {
        updateStatusSubType(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusSubTypeAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeExamSubType(id, history) {
    return (dispatch) => {
        removeSubType(id)
            .then((response) => {
                dispatch(removeSubTypeAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addSubTypeAction(data) {
    return {
        type: ADD_EXAM_SUB_TYPE,
        payload: data,
    };
}
export function updateSubTypeAction(data) {
    return {
        type: UPDATE_EXAM_SUB_TYPE,
        payload: data,
    };
}
export function getSubTypeAction(data) {
    return {
        type: GET_EXAM_SUB_TYPE,
        payload: data,
    };
}
export function updateStatusSubTypeAction(item) {
    return {
        type: UPDATE_STATUS_EXAM_SUB_TYPE,
        payload: item,
    };
}
export function removeSubTypeAction(id) {
    return {
        type: REMOVE_EXAM_SUB_TYPE,
        payload: id,
    };
}

export function loadingSubTypeAction(status) {
    return {
        type: SUB_TYPE_LOADING,
        payload: status,
    };
}
