import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from 'react-bootstrap';

const UpdateState = () => {

	let errorsObj = { name: '', details: '', code: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [stateName, setStateName] = useState('');
	const [stateDetails, setStateDetails] = useState('');
	const [stateCode, setStateCode] = useState('');

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (stateCode === '') {
			errorObj.code = 'Error state code is Required';
			error = true;
		}
		if (stateName === '') {
			errorObj.name = 'Error state name is Required';
			error = true;
		}
		if (stateDetails === '') {
			errorObj.details = 'Error state details is Required';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>State code</strong>
														</label>
														<input className="form-control" placeholder="Enter the short code" value={stateCode} onChange={(e) => setStateCode(e.target.value)} />
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>State name</strong>
														</label>
														<input className="form-control" placeholder="Enter the state name" value={stateName} onChange={(e) => setStateName(e.target.value)} />
														{errors.name && <div className="text-danger fs-12">{errors.name}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>State details</strong>
														</label>
														<textarea className="form-control" placeholder="Enter the state details" onChange={(e) => setStateDetails(e.target.value)}>
															{stateDetails}
														</textarea>
														{errors.details && <div className="text-danger fs-12">{errors.details}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block">Submit</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
export default UpdateState;