import { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { getExamCategory } from "../../../store/actions/ExamCategoryAction";
import { getSubjects } from "../../../store/actions/SujectActions";
import { SubscriptionDetailsData } from "../../../store/actions/SubscriptionDetailActions";
import Moment from "react-moment";

const SubscriptionDatabase = (props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const [period, setPeriod] = useState("");
  const [category, setCategory] = useState("");
  const [className, setClassName] = useState("");
  const [board, setBoard] = useState("");
  const [pin, setPin] = useState("");
  const [service, setService] = useState("");
  const [subject, setSubject] = useState("");
  const [FSD, setFSD] = useState("");
  const [NBF, setNBF] = useState("");
  const [subscription, setSubscription] = useState("");
  const [subscribeMethd, setSubscribeMethod] = useState("");
  const [amount, setAmount] = useState(0);

  const onResetHandler = (e) => {
    e.preventDefault();
    setPeriod("");
    setBoard("");
    setCategory("");
    setClassName("");
    setPin("");
    setService("");
    setSubject("");
    setFSD("");
    setNBF("");
    setSubscription("");
    setSubscribeMethod("");
  };

  const filterHandler = () => {
    getList();

    setModal(false);
  };

  useEffect(() => {
    dispatch(getBoardData());
    dispatch(getClassData());
    dispatch(getExamCategory());
    dispatch(getSubjects("", "", "", "", ""));
  }, []);

  useEffect(() => {
    getList();
  }, []);

  const amoutCount = () => {
    let total = 0;
    props.subscriptionDetailsList.map((item, index) => {
      total += parseInt(item.cart_amount);
    });
    return total;
  };

  useEffect(() => {
    setAmount(amoutCount());
  }, [props.subscriptionDetailsList]);

  const getList = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      SubscriptionDetailsData(
        period,
        board,
        className,
        pin,
        category,
        subject,
        service,
        FSD,
        NBF,
        subscribeMethd
      )
    );
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >
                <div class="subscription-details">
                  <div class="subscription-info">
                    <strong>Total Count:</strong>
                    <span class="count">
                      {props.subscriptionDetailsList.length}
                    </span>
                  </div>
                  <div class="subscription-info">
                    <strong>Total Amount:</strong>
                    <span class="amount">
                      <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{amount}
                      .00
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => setModal(true)}
                  className="btn btn-secondary"
                >
                  Filter
                </button>
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>SL</strong>
                    </th>
                    <th>
                      <strong>ID</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>subscription Date</strong>
                    </th>
                    <th>
                      <strong>DOB</strong>
                    </th>
                    <th>
                      <strong>Gender</strong>
                    </th>
                    <th>
                      <strong>Mobile</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>PIN</strong>
                    </th>
                    <th>
                      <strong>School</strong>
                    </th>
                    <th>
                      <strong>School Address</strong>
                    </th>
                    <th>
                      <strong>Board</strong>
                    </th>
                    <th>
                      <strong>Class</strong>
                    </th>
                    <th>
                      <strong>Method</strong>
                    </th>
                    <th>
                      <strong>Subscription Code</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.subscriptionDetailsList.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.student_id}</td>
                        <td>{item.fname + " " + item.lname}</td>

                        <td>
                          {
                            <Moment format="D MMM YYYY" withTitle>
                              {item.created_at}
                            </Moment>
                          }
                        </td>
                        <td>{item.dob}</td>
                        <td>{item.gender}</td>
                        <td>{item.mobile}</td>
                        <td>{item.email}</td>
                        <td>{item.pincode}</td>
                        <td>{item.school_name}</td>
                        <td>{item.school_address}</td>
                        <td>{item.board_name}</td>
                        <td>{item.class}</td>
                        <td>
                          {item.subscribe_method == 1 ? "Individual" : "Bulk"}
                        </td>
                        <td>
                          {item.category === "COMPETITIVE"
                            ? item.no_set + " set"
                            : item.only_elibrary == 1
                            ? "Only Library"
                            : item.no_set +
                              " ch test" +
                              (item.no_mock == 1 ? " + Mock" : "") +
                              (item.no_module ? " + Mod" : "") +
                              (item.has_library ? " + Library" : "")}
                        </td>

                        <td>{item.cart_amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal className="fade" show={modal} size="md">
        <form>
          <Modal.Header>
            <Modal.Title>Filtter</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">
              <div className="reset-filtter-value">
                <button
                  className="btn btn-info btn-xs"
                  onClick={(e) => {
                    onResetHandler(e);
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="row">
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={period}
                      className="form-control"
                      onChange={(e) => {
                        setPeriod(e.target.value);
                      }}
                    >
                      <option value="">Select Period</option>
                      <option value="1">Today</option>
                      <option value="7">Last 7 days</option>
                      <option value="15">Last 15 days</option>
                      <option value="30">Last 1 month</option>
                      <option value="90">Last 3 months</option>
                      <option value="365">Last 12 months</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={board}
                      className="form-control"
                      onChange={(e) => {
                        setBoard(e.target.value);
                      }}
                    >
                      <option value="">Select board</option>
                      {props.boardList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={className}
                      className="form-control"
                      onChange={(e) => {
                        setClassName(e.target.value);
                      }}
                    >
                      <option value="">Select class</option>
                      {props.classList.map((item) => {
                        return (
                          <option value={item.id}>{item.short_code}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={category}
                      className="form-control"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="">Select category</option>
                      {props.categoryList.map((item) => {
                        return <option value={item.id}>{item.category}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={service}
                      className="form-control"
                      onChange={(e) => {
                        setService(e.target.value);
                      }}
                    >
                      <option value="">Select Service</option>
                      <option value="1">FSD</option>
                      <option value="2">CMC</option>
                      <option value="3">NBF</option>
                      <option value="4">NBF+CMC</option>
                      <option value="5">NBF+FSD</option>
                      <option value="6">FSD+CMC</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={subject}
                      className="form-control"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    >
                      <option value="">Select subject</option>
                      {props.subjectList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                </div>

                {category && (
                  <div className="form-group col-sm-6 px-1 mb-3">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>

                      {category == 1 ? (
                        <select
                          value={FSD}
                          className="form-control"
                          onChange={(e) => {
                            setFSD(e.target.value);
                          }}
                        >
                          <option value="">Select FSD Details</option>
                          <option value="1">1 Ch test</option>
                          <option value="2">2 Ch test</option>
                          <option value="3">1 Ch test + Mod</option>
                          <option value="4">1 Ch test + Mod+Mock</option>
                          <option value="5">2 Ch test + Mod</option>
                          <option value="6">2 Ch test + Mod+Mock</option>
                          <option value="7">Case Studies</option>
                        </select>
                      ) : (
                        <select
                          value={FSD}
                          className="form-control"
                          onChange={(e) => {
                            setFSD(e.target.value);
                          }}
                        >
                          <option value="">Select FSD Details</option>
                          <option value="8">3 Sets</option>
                          <option value="9">5 Sets</option>
                          <option value="10">10 Sets</option>
                          <option value="11">15 Sets</option>
                        </select>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={NBF}
                      className="form-control"
                      onChange={(e) => {
                        setNBF(e.target.value);
                      }}
                    >
                      <option value="">Select NBF Details</option>
                      <option value="1">Full</option>
                      <option value="2">Crash</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={subscription}
                      className="form-control"
                      onChange={(e) => {
                        setSubscription(e.target.value);
                      }}
                    >
                      <option value="">Select Subscription Status</option>
                      <option value="1">Active</option>
                      <option value="2">Expire</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      value={subscribeMethd}
                      className="form-control"
                      onChange={(e) => {
                        setSubscribeMethod(e.target.value);
                      }}
                    >
                      <option value="">Select Subscribed method</option>
                      <option value="1">Individual</option>
                      <option value="2">Bulk</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 px-1">
                  <input
                    style={{ height: "45px" }}
                    className="form-control filter-form-control"
                    placeholder="Enter Location Pin"
                    value={pin}
                    onChange={(e) => {
                      setPin(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModal(false)} variant="danger light">
              Cancel
            </Button>
            <Button onClick={filterHandler} variant="primary">
              Filtter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(SubscriptionDatabase);
