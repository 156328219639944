import { ReactChild, Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { MisFailedSubscriptionUpdateData } from "../../../store/actions/FailedSubscriptionAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { connect } from "react-redux";
import RankingTextInput from "../Subscription/component/RankingTextInput";
import { MisFailedSubscriptionData } from "../../../store/actions/FailedSubscriptionAction";
import moment from "moment";

const FailedSubscription = (props) => {
  const dispatch = useDispatch();

  const [period, setPeriod] = useState(1);
  const [userProblem, SetUserProblem] = useState("");
  const [actionTaken, setActionTaken] = useState("");

  useEffect(() => {
    getList();
  }, [period, userProblem, actionTaken]);

  const getList = () => {
    dispatch(globalLoadingAction(true));
    dispatch(MisFailedSubscriptionData(period, userProblem, actionTaken));
  };

  const onBlurHandler = (val, id) => {
    dispatch(globalLoadingAction(true));
    dispatch(MisFailedSubscriptionUpdateData(id, val, "", "",period,userProblem,actionTaken));
  };

  const StatusChange = (val, id) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      MisFailedSubscriptionUpdateData(
        id,
        "",
        "",
        parseInt(val),
        period,
        userProblem,
        actionTaken
      )
    );
  };

  const dateUpadteHandler = (val, id) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      MisFailedSubscriptionUpdateData(
        id,
        "",
        val,
        "",
        period,
        userProblem,
        actionTaken
      )
    );
  };

  const resetHandler=()=>{
    setPeriod(1)
    SetUserProblem('')
    setActionTaken('')
  }



  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className=" filtersAlign mt-3" style={{ marginRight: 25 }}>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={period}
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                  >
                    {/* <option value="">Select Period</option> */}
                    <option value="1">Today</option>
                    <option value="7">Last 7 days</option>
                    <option value="15">Last 15 days</option>
                    <option value="30">Last 1 month</option>
                    <option value="90">Last 3 months</option>
                    <option value="365">Last 12 months</option>
                  </select>
                </div>
              </div>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={userProblem}
                    onChange={(e) => {
                      SetUserProblem(e.target.value);
                    }}
                  >
                    <option value="">Select Failure Reasone</option>
                    <option value="2">Fail</option>
                    <option value="3">Abort</option>
                    <option value="4">Illegal</option>
                  </select>
                </div>
              </div>
              <div className="me-2">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control filter-form-control-input"
                    value={actionTaken}
                    onChange={(e) => {
                      setActionTaken(e.target.value);
                    }}
                  >
                    <option value="">Select Action</option>
                    <option value="1">Pending</option>
                    <option value="2">Progress</option>
                    <option value="3">Complete</option>
                  </select>
                </div>
              </div>
              <div style={{marginTop:'5px'}} className="col-sm-1 px-1 ">
									<button onClick={resetHandler}  className="btn btn-secondary">Reset</button>
								</div>

            
            </div>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>SL</strong>
                    </th>
                    <th width={200}>
                    <strong>Date</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <th>
                      <strong>Failure Reasone</strong>
                    </th>
                    <th>
                      <strong>Action Status</strong>
                    </th>
                    <th>
                      <strong>Soluation</strong>
                    </th>
                    <th>
                      <strong>Mail shooted</strong>
                    </th>
                    <th>
                      <strong>Date reply for mail</strong>
                    </th>
                    {/* <th>
                      <strong>Further Subscribed</strong>
                    </th> */}
                    <th style={{ textAlign: "center" }}>
                      <strong>Suggestion</strong>
                    </th>
                    {/* <th>
                      <strong>System Action</strong>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
                        <td>{item.fail_reason}</td>
                        <td>
                          <div className="basic-dropdown">
                            <span className="drop_down_arrow add-Question-status-arrow-position custome_dropdown_arrow_align">
                              <i className="bi bi-caret-down-fill"></i>
                            </span>

                            <select
                              //defaultValue={item.status_of_action}
                              value={item.status_of_action}
                              className="form-control filter-form-control-input custome_dropdown_width"
                              onChange={(e) => {
                                StatusChange(e.target.value, item.id);
                              }}
                            >
                              <option value="1">Pending</option>
                              <option value="2">Progress</option>
                              <option value="3">Complete</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          {item.helpdesk}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.mail_shoot == 1 ? "Yes" : "No"}
                        </td>
                        <td>
                         
                         <div className="date-control-view">
                           {" "}
                           <DatePicker
                             placeholderText="Enter the date"
                             selected={
                               item.mail_shoot_date_time == null ||
                               item.mail_shoot_date_time == ""
                                 ? ""
                                 : new Date(item.mail_shoot_date_time)
                             }
                             onChange={(date) => {
                               dateUpadteHandler(
                                 moment(date).format("YYYY-MM-DD HH:mm:ss"),
                                 item.id
                               );
                             }}
                           />
                           <button type="button" className="picker-btn">
                             <i class="fa-solid fa-calendar-days"></i>
                           </button>{" "}
                         </div>{" "}
                       </td>
                        {/* <td style={{ textAlign: "center" }}>
                          {item.regerster_later == 1 ? "Yes" : "No"}
                        </td> */}
                        <td>
                          <RankingTextInput
                            suggestion={true}
                            rank={item.suggestion_call}
                            onBlurHandler={(r) => {
                              onBlurHandler(r, item.id);
                            }}
                          />
                        </td>
                        {/* <td>{item.immediate_action}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log(state.FailedSubscription.list);

  return {
    list: state.FailedSubscription.list,
  };
};

export default connect(mapStateToProps)(FailedSubscription);
