import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import {
    Row,
    Col,
    Card,
} from "react-bootstrap";

import { getElibraryCountData, loadingElibraryAction, deleteLibraryData } from '../../../store/actions/ElibraryAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getSubjectsFiltterList, subjectLoading, getSingleSubjectListDetails, getSingleSubjectListActions } from '../../../store/actions/SujectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';

const ElibraryDashboard = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [demo, setDemo] = useState('');
    const [category, setCategory] = useState('');
    const [board, setBoard] = useState('');
    const [examType, setExamType] = useState('');
    const [subject, setSubject] = useState('');
    const [classNo, setClassNo] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(demo, category, board, examType, subject, classNo, props.history));
        dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, category, board, examType, subject, classNo, props.history));
    }, [demo, category, board, examType, subject, classNo]);

    /* on change demo */
    const onChangeDemoHandler = (value) => {
        setDemo(value);
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(parseInt(value) == 5 ? '' : value, category, board, examType, subject, classNo, props.history));
    }

    /* get category list */
    const getCategoryHandler = () => {
        if (props.categoryList.length == 0) {
            dispatch(loadingToggleAction(true));
            dispatch(getExamCategory(props.history));
        }
    }

    /* on change category */
    const onCategoryHandler = (value) => {
        // console.log("onCategoryHandler---", value)
        setCategory(value);
        dispatch(globalLoadingAction(true));
        dispatch(getSingleSubjectListActions([]));
        setSubject('');
        // dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, value, board, examType, subject, classNo, props.history));
        if (value == '') {
            setExamType('');
            setBoard('');
        } else if (value == 1) {
            setExamType('');
        } else if (value == 2) {
            setBoard('');
        }
    }

    /* get board list */
    const getBoardHandler = () => {
        if (props.boardList.length == 0) {
            dispatch(loadingBoardAction(true));
            dispatch(getBoardData(props.history));
        }
    }

    /* on change board */
    const onBoardHandler = (value) => {
        setBoard(value);
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, category, value, examType, subject, classNo, props.history));
    }

    /* on change exam type */
    const onExamTypeHandler = (value) => {
        if (value == 1) {
            setClassNo('');
        }
        setExamType(value);
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, category, board, value, subject, classNo, props.history));
    }

    /* get exam type list */
    const getExamTypeHandler = () => {
        if (props.typeList.length == 0) {
            dispatch(loadingAction(true));
            dispatch(getExamType(props.history));
        }
    }

    /* on change subject */
    const onSubjectHandler = (value) => {
        setSubject(value);
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, category, board, examType, value, classNo, props.history));
    }

    /* get subject list */
    const getSubjectHandler = () => {
        // if (props.subjectList.length == 0) {
        dispatch(subjectLoading(true));
        // dispatch(getSubjectsFiltterList(props.history));
        dispatch(getSingleSubjectListDetails(0, board, examType, '', category, props.history));
        // }
    }

    /* get class list */
    const getClassHandler = () => {
        if (props.classList.length == 0) {
            dispatch(loadingClassAction(true));
            dispatch(getClassData(props.history));
        }
    }

    /* on class change */
    const onClassHandler = (value) => {
        setClassNo(value);
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData(parseInt(demo) == 5 ? '' : demo, category, board, examType, subject, value, props.history));
    }


    /* reset all value */
    const onResetHandler = () => {
        dispatch(globalLoadingAction(true));
        // dispatch(getElibraryCountData('', '', '', '', '', '', props.history));
        setDemo('');
        setCategory('');
        setBoard('');
        setExamType('');
        setSubject('');
        setClassNo('');
    }

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="row add-Question-list-view">

                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={demo}
                                            onChange={(e) => onChangeDemoHandler(e.target.value)}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="5">All</option>
                                            <option value="2">Live</option>
                                            <option value="1">Guest Demo</option>
                                            <option value="3">Register Demo</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-2 px-1">
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {props.categoryLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={category}
                                            onChange={(e) => onCategoryHandler(e.target.value)}
                                            onClick={getCategoryHandler}
                                        >
                                            <option value="">Select category</option>
                                            {
                                                props.categoryList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.category}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                {category == 2 ?

                                    <div className={`col-sm-2 px-1 ${category == '' ? 'content_disable' : null}`}>
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow">
                                                {props.typeLoading ?
                                                    <Spinner animation="border" size="sm" />
                                                    : <i className="bi bi-caret-down-fill"></i>}
                                            </span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control filter-form-control"
                                                value={examType}
                                                onChange={(e) => onExamTypeHandler(e.target.value)}
                                                onClick={getExamTypeHandler}
                                            >
                                                <option value="">Select Exam type</option>
                                                {
                                                    props.typeList.map((item, index) => {
                                                        return <option key={index} value={item.id}>{item.type_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    :
                                    <div className={`col-sm-2 px-1 ${category == '' ? 'content_disable' : null}`}>
                                        <div className="basic-dropdown">
                                            <span className="drop_down_arrow">
                                                {props.boardLoading ?
                                                    <Spinner animation="border" size="sm" />
                                                    : <i className="bi bi-caret-down-fill"></i>}
                                            </span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control filter-form-control"
                                                value={board}
                                                onChange={(e) => onBoardHandler(e.target.value)}
                                                onClick={getBoardHandler}
                                            >
                                                <option value="">Select Board</option>
                                                {
                                                    props.boardList.map((item, index) => {
                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }

                                <div className={`col-sm-2 px-1 ${examType == 1 ? 'content_disable' : null}`}>
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {props.classLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={classNo}
                                            onChange={(e) => onClassHandler(e.target.value)}
                                            onClick={getClassHandler}
                                        >
                                            <option value="">Select class</option>
                                            {
                                                props.classList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.short_code}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className={`col-sm-2 px-1 ${(board == '' && examType == '') ? 'content_disable' : null}`}>
                                    <div className="basic-dropdown">
                                        <span className="drop_down_arrow">
                                            {props.subjectLoading ?
                                                <Spinner animation="border" size="sm" />
                                                : <i className="bi bi-caret-down-fill"></i>}
                                        </span>
                                        <select
                                            defaultValue={"option"}
                                            className="form-control filter-form-control"
                                            value={subject}
                                            onChange={(e) => onSubjectHandler(e.target.value)}
                                            onClick={getSubjectHandler}
                                        >
                                            <option value="">Select Subject</option>
                                            {
                                                // props.subjectList.map((item, index) => {
                                                props.getSingleSubjectList.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className={`col-sm-2 reset-btn-view ${demo == '' && category == '' && examType == '' && board == '' && subject == '' && classNo == '' ? 'content_disable' : null}`}>
                                    <button onClick={onResetHandler} className="btn btn-secondary">Reset</button>
                                </div>
                            </div>

                            <div className="library-dashboard-container">

                                <div className="library-counter-view">
                                    <h3>Write Up Count</h3>
                                    <h2>{props.totalWriteOf == null ? 0 : props.totalWriteOf}</h2>
                                </div>

                                <div className="library-counter-view">
                                    <h3>Concept Map Count</h3>
                                    <h2>{props.totalConceptMap == null ? 0 : props.totalConceptMap}</h2>
                                </div>

                            </div>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.elibrary.showLoading,
        elibraryList: state.elibrary.list,
        categoryList: state.category.list,
        categoryLoading: state.category.showLoading,
        branchList: state.branch.branchList,
        branchLoading: state.branch.showLoading,
        boardLoading: state.board.showLoading,
        boardList: state.board.list,
        typeLoading: state.type.showLoading,
        typeList: state.type.list,
        subjectLoading: state.subject.showLoading,
        subjectList: state.subject.subjectFiltterList,
        totalPage: state.elibrary.totalPage,
        totalRecord: state.elibrary.totalRecord,
        classLoading: state.class.showLoading,
        classList: state.class.list,
        totalWriteOf: state.elibrary.totalWriteOf,
        totalConceptMap: state.elibrary.totalConceptMap,
        getSingleSubjectList: state.subject.getSingleSubjectList
    };
};

export default connect(mapStateToProps)(ElibraryDashboard);