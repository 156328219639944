import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from 'react-moment';

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { assignValidatorData, getDetailsDeveloperContentForElibraryData } from '../../../store/actions/AssignContentAction';
import { getContentValidatorData, loadingContentValidatorAction } from '../../../store/actions/ContentValidatorAction';


const AssignQuestionDetails = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const contentbody = useSelector(state => state.assign.eLibraryContentDetails.content);
    let errorsObj = { validator: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [validator, setValidator] = useState('');


    useEffect(() => {
        // dispatch(globalLoadingAction(true));
        // dispatch(getDetailsDeveloperContentForElibraryData(state.id, props.history));
        // dispatch(loadingContentValidatorAction(props.history));
        // dispatch(getContentValidatorData(0, '', '', props.history));
    }, []);

    /* form submit */
    function onSubmit(e) {
        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (validator === '') {
            errorObj.validator = 'Developer is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(assignValidatorData(state.id, validator, props.history))
        setValidator('');
    }

    /* navigation go back */
    const onGoBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    {console.log('state', state)}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-1">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-10">

                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Board & Exam Type:</label><span>{state.exam_type}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Subject Code:</label><span>{state.subject_code}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Chapter No:</label><span>{state.chapter_no}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Class:</label><span>{state.standard}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Question Type:</label><span>{state.question_type}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Question No:</label><span>{state.question_no}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Answer:</label><span>{state.answer}</span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Create Date:</label><span><Moment format="D MMM YYYY" withTitle>{state.created_at}</Moment></span></div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <div class="basic-details-item"><label>Submission Date:</label><span><Moment format="D MMM YYYY" withTitle>{state.submission_date}</Moment></span></div>
                                                </div>

                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Question Content:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.question_content}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Option A:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.option_a}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Option B:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.option_b}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Option C:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.option_c}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Option D:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.option_d}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-xl-6">
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">
                                                        <strong>Reason:</strong>
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            toolbar: {
                                                                shouldNotGroupWhenFull: true,
                                                                items: []
                                                            }
                                                        }}
                                                        data={state.reason}
                                                    />
                                                </div>
                                                </div>

                                            </div>

                                    
                                        </div>

                                    </div>
                                    <div className="col-xl-1"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.assign.showLoading,
        validatorLoading: state.contentvalidator.showLoading,
        contentvalidatorList: state.contentvalidator.list,
        eLibraryContentDetails: state.assign.eLibraryContentDetails,
    };
};
export default connect(mapStateToProps)(AssignQuestionDetails);