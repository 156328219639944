import swal from "sweetalert";
import {
    addEventHistory,
    getEventHistory,
    removeEventHistory,
    updateEventHistory,
    updateEventHistoryStatus,
    uploadEventImg
} from '../../services/EventHistoryServices';

import {
    EVENT_HISTORY_LOADING,
    ADD_EVENT_HISTORY,
    GET_EVENT_HISTORY_LIST,
    UPDATE_EVENT_HISTORY_STATUS_LIST,
    REMOVE_EVENT_HISTORY_LIST
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function addEventHistoryData(title, sub_title, description, event_date, event_image, history) {
    return (dispatch) => {
        addEventHistory(title, sub_title, description, event_date, event_image)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addEventHistoryAction(true));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            }).catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateEventHistoryData(id, title, sub_title, description, event_date, event_image, filter, history) {
    return (dispatch) => {
        updateEventHistory(id, title, sub_title, description, event_date, event_image)
            .then((response) => {
                utility.showSuccess(response.data.msg);
                //history.push('/event-history-list');
                history.push({
                    pathname: '/event-history-list',
                    state: filter
                });
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateEventHistoryStatusData(item, history) {
    return (dispatch) => {
        updateEventHistoryStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(globalLoadingAction(false));
                dispatch(updateEventHistoryStatusAction(item));
                utility.showSuccess(response.data.msg);
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getEventHistoryList(page, date, history) {
    return (dispatch) => {
        getEventHistory(page, date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getEventHistoryAction(response.data));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));

            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function removeEventHistoryData(id,page, history) {
    return (dispatch) => {
        removeEventHistory(id)
            .then((response) => {
                dispatch(getEventHistoryList(page, '',history));
                dispatch(removeEventHistoryAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addEventHistoryAction(data) {
    return {
        type: ADD_EVENT_HISTORY,
        payload: data,
    };
}

export function getEventHistoryAction(data) {
    return {
        type: GET_EVENT_HISTORY_LIST,
        payload: data,
    };
}

export function updateEventHistoryStatusAction(data) {
    return {
        type: UPDATE_EVENT_HISTORY_STATUS_LIST,
        payload: data,
    };
}

export function removeEventHistoryAction(data) {
    return {
        type: REMOVE_EVENT_HISTORY_LIST,
        payload: data,
    };
}

export function loadingEventHistoryAction(status) {
    return {
        type: EVENT_HISTORY_LOADING,
        payload: status,
    };
}
