import swal from "sweetalert";
import {
    getContentDesigner,
    updateContentDesigner,
    updateContentDesinerStatus,
    removeContentDesigner
} from '../../services/ContentDesignerServices';

import {
    CONTENT_DESIGNER_LOADING,
    GET_CONTENT_DESIGNER_LIST,
    UPDATE_CONTENT_DESIGNER,
    UPDATE_CONTENT_DESIGNER_STATUS,
    REMOVE_CONTENT_DESIGNER
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function getContentDesignerData(page, name, email,phoneno, history) {
    return (dispatch) => {
        getContentDesigner(page, name, email,phoneno)
            .then((response) => {
                dispatch(getContentDesignerAction(response.data));
                //utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingContentDesignerAction(false));
                utility.showError(error.response.data);
            });
    };
}


export function updateContentDesignerData(id, name, email, phone, history) {
    return (dispatch) => {
        updateContentDesigner(id, name, email, phone)
            .then((response) => {
                dispatch(updateContentDesignerAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateContentDesignerStatus(item, history) {
    return (dispatch) => {
        updateContentDesinerStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateContentDesignerStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeContentDesignerDetails(id,active, history) {
    return (dispatch) => {
        removeContentDesigner(id)
            .then((response) => {
                dispatch(getContentDesignerData(active, '', '','', history));
                dispatch(removeContentDesignerAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getContentDesignerAction(data) {
    return {
        type: GET_CONTENT_DESIGNER_LIST,
        payload: data,
    };
}
export function updateContentDesignerAction(data) {
    return {
        type: UPDATE_CONTENT_DESIGNER,
        payload: data,
    };
}
export function updateContentDesignerStatusAction(data) {
    return {
        type: UPDATE_CONTENT_DESIGNER_STATUS,
        payload: data,
    };
}
export function removeContentDesignerAction(data) {
    return {
        type: REMOVE_CONTENT_DESIGNER,
        payload: data,
    };
}
export function loadingContentDesignerAction(status) {
    return {
        type: CONTENT_DESIGNER_LOADING,
        payload: status,
    };
}
