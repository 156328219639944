import { GET_SUBSCRIPTION_DETAILS_LIST } from "../constants";

const initial={
    list:[]
}

export function subscriptiondetailsReducer(state=initial,action){
     if(action.type==GET_SUBSCRIPTION_DETAILS_LIST){
        return{
            ...state,
            list:action.payload
        }
     }

     return state;
}