import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addAssignContent(request_type, exam_category_id, board_id, subject_id, class_no, chapter_id, date_of_submission, payment_per_page, assigned_developer) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        request_type, 
        exam_category_id, 
        board_id, 
        subject_id, 
        class_no, 
        chapter_id, 
        date_of_submission, 
        payment_per_page, 
        assigned_developer
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_ASSIGN_CONTENT_DEVELOPER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getAssignContent(request_type, exam_category_id, board_id, exam_type_id, class_no, subject_id, chapter_id, status, page, approved_status ) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        request_type,
        exam_category_id, 
        board_id,
        exam_type_id,
        class_no,
        subject_id,
        chapter_id,
        status, 
        page,
        approved_status
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ASSIGN_CONTENT_DEVELOPER_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getElibraryContentRequestDetails(rec_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        rec_id
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_E_LIBRARY_CONTENT_REQUEST_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getQuestionContentRequestDetails(rec_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        rec_id
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_QUESTION_CONTENT_REQUEST_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function assignValidator(recid,assigned_validator) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        assigned_validator
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.ASSIGN_CONTENT_VALIDATOR_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateContentAssign(user_type, payment_per_page, assigned_to_whom, date_of_submission, recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        user_type, 
        payment_per_page, 
        assigned_to_whom, 
        date_of_submission, 
        recid
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_UPDATE_CONTENT_ASSIGN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function deleteAssignContent(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_DELETE_ASSIGN_CONTENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getRejectedList(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_REJECTED_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getAddedDocumentDetails(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    }
    // console.log("postData---------", postData)
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_ADDED_DOCUMENT_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function acceptRejectUploadedContentDetails(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ACCEPT_REJECT_UPLOADED_CONTENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function assignToValidatorElibraryDetails(recid, date_of_submission, payment_per_page, assigned_validator) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        date_of_submission, 
        payment_per_page, 
        assigned_validator
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ASSIGN_TO_VALIDATOR_ELIBRARY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function assignToLanguageValidatorElibraryDetails(recid, date_of_submission, payment_per_page, assigned_validator) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        date_of_submission, 
        payment_per_page, 
        assigned_validator
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ASSIGN_TO_LANGUAGE_VALIDATOR_ELIBRARY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function assignToDesignerElibraryDetails(recid, date_of_submission, payment_per_page, assigned_designer) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        date_of_submission, 
        payment_per_page, 
        assigned_designer
    }
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ASSIGN_TO_DESIGNER_ELIBRARY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getlibraryhistorylogDetails(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
    }
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_LIBRARY_HISTORY_LOG_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


export function getNotificationDetailsDetails() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_NOTIFICATION_DETAILS_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}

export function getReadNotificationDetails(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_READ_NOTIFICATION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function addAssignQuestionContent(exam_category_id, board_id,exam_type_id,exam_subtype_id, subject_id, class_no, chapter_id,question_no_details, date_of_submission, total_payment,total_no_questions, assigned_developer) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        exam_category_id,
        board_id,
        exam_type_id,
        exam_subtype_id,
        subject_id,
        class_no,
        chapter_id,
        question_no_details,
        date_of_submission,
        total_payment,
        total_no_questions,
        assigned_developer
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_ASSIGN_CONTENT_QUESTION_DEVELOPER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateAssignQuestionContent(recid,user_type,question_no_details, date_of_submission,total_payment,total_no_questions, assigned_to_whom) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        user_type,
        question_no_details,
        date_of_submission,
        total_payment,
        total_no_questions,
        assigned_to_whom
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ASSIGN_CONTENT_QUESTION_DEVELOPER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getAssignQuestionList(request_type,exam_category_id,board_id,exam_type_id,subject_id,class_no,chapter_id,status,approved_status,page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        request_type,
        exam_category_id,
        board_id,
        exam_type_id,
        subject_id,
        class_no,
        chapter_id,
        status,
        approved_status,
        page
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ASSIGN_CONTENT_QUESTION_DEVELOPER_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getAssignQuestionById(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ASSIGN_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function deleteQuestionRequest(recid,rejection_msg) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        rejection_msg,
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_DELETE_ASSIGN_CONTENT_QUESTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function cancelQuestionRequest(recid,rejection_msg,submit_date,status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        rejection_msg,
        submit_date,
        status
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ACCEPT_REJECT_ASSIGN_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateQuestionStatusByAdmin(recid,status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_ACCEPT_REJECT_ASSIGN_SINGLE_QUESTION_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getQuestionHistorylog(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
    }
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_QUESTIONS_HISTORY_LOG_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}


