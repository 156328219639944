import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import swal from "sweetalert";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button,
	Pagination
} from "react-bootstrap";

import { getExamConfigurationData, removeExamConfigurationData, loadingConfiguration } from '../../../store/actions/ExamConfigurationAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ExamConfigurationList = (props) => {

	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(1);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getExamConfigurationData(activeTab, 1, props.history));
	}, []);

	/* set type */
	const onType = (type) => {
		if (type == 1) {
			return 'Set';
		} else if (type == 2) {
			return 'Module';
		} else if (type == 3) {
			return 'Mock';
		} else {
			return 'NA';
		}
	}

	/* delete confirmation */
	const onDelete = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeExamConfigurationData(id, props.history));
			}
		})

	}

	/* Tab handler */
	const onTabHandler = (val) => {
		setActiveTab(val);
		dispatch(globalLoadingAction(true));
		dispatch(getExamConfigurationData(val, props.history));
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="tab-view">
								<div className="tab-btn-view">
									<button className={`tab-scholastic-btn ${activeTab == 1 ? 'active-tab' : null}`} onClick={() => onTabHandler(1)}>SCHOLASTIC</button>
									<button className={`tab-competitive-btn ${activeTab == 2 ? 'active-tab' : null}`} onClick={() => onTabHandler(2)}>COMPETITIVE</button>
								</div>
								<div className="tab-content-view">
									{activeTab == 1 ?
										<Table responsive>
											<thead>
												<tr>
													<th>
														<strong>Board</strong>
													</th>
													<th>
														<strong>Configuration type</strong>
													</th>
													<th>
														<strong>Configuration</strong>
													</th>
													<th>
														<strong>Create date</strong>
													</th>
													<th>
														<strong>Action</strong>
													</th>
												</tr>
											</thead>
											<tbody>
												{
													props.list.map((item, index) => {
														return <tr key={index}>
															<td>{item.board_name}</td>
															<td>
																{onType(item.type)}
															</td>
															<td>{item.configuration_details}</td>
															<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
															<td>
																<div>
																	<Link
																		to={{
																			pathname: '/update-configuration-list',
																			state: item
																		}}
																		className="btn btn-warning shadow btn-xs sharp me-1"
																	>
																		<i className="fas fa-pencil-alt"></i>
																	</Link>
																	<Button
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={() => onDelete(item.id)}
																	//disabled={true}
																	>
																		<i className="fa fa-trash"></i>
																	</Button>

																</div>
															</td>
														</tr>
													})
												}
											</tbody>
										</Table>
										:
										<Table responsive>
											<thead>
												<tr>
													<th>
														<strong>Exam type</strong>
													</th>
													<th>
														<strong>Sub Type</strong>
													</th>
													<th>
														<strong>Branch</strong>
													</th>
													<th>
														<strong>No. of Question</strong>
													</th>
													<th>
														<strong>Create date</strong>
													</th>
													<th>
														<strong>Action</strong>
													</th>
												</tr>
											</thead>
											<tbody>
												{
													props.list.map((item, index) => {
														return <tr key={index}>
															<td>{item.type_name}</td>
															<td>{item.subtype_name}</td>
															<td>{item.branch_name}</td>
															<td>{item.no_questions}</td>
															<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
															<td>
																<div className="d-flex">
																	<Link
																		to={{
																			pathname: '/update-configuration-list',
																			state: item
																		}}
																		className="btn btn-warning shadow btn-xs sharp me-1"
																	>
																		<i className="fas fa-pencil-alt"></i>
																	</Link>
																	<Button
																		className="btn btn-danger shadow btn-xs sharp"
																		onClick={() => onDelete(item.id)}
																	//disabled={true}
																	>
																		<i className="fa fa-trash"></i>
																	</Button>

																</div>
															</td>
														</tr>
													})
												}
											</tbody>
										</Table>
									}
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.ExamConfiguration.showLoading,
		list: state.ExamConfiguration.list,
	};
};

export default connect(mapStateToProps)(ExamConfigurationList);