import React, { Fragment, useContext, useEffect, useState } from "react";

const RankingTextInput = (props) => {
  const [ranking, setRanking] = useState("");

  useEffect(() => {
    setRanking(props.rank);
  }, [props.rank]);

  const onRankingHandler = (event) => {
    setRanking(event.target.value);
  };

  const onKeyUpHandler = (event) => {
    if (event.code == "Enter") {
      props.onBlurHandler(event.target.value)
    }
  }

  return (
    <Fragment>
      <input
        style={{ width: props.suggestion ? '200px' : '' }}
        className="form-control ranking-form-control"
        type="text"
        value={ranking} 
        onChange={(e) => onRankingHandler(e)}
        onKeyUp={(e) => onKeyUpHandler(e)}
      //onBlur={(e) => props.onBlurHandler(e.target.value)}
      />
    </Fragment>
  );
};
export default RankingTextInput;
