import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addTypeExam(type_of_exam_name) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        type_of_exam_name
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TYPE_OF_EXAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTypeExam(recid, type_of_exam_name) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        type_of_exam_name
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TYPE_OF_EXAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTypeExam() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TYPE_OF_EXAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function updateStatusTypeExam(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_STATUS_TYPE_OF_EXAM_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeTypeExam(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TYPE_OF_EXAM_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

