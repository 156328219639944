import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { addFeedback } from "../../../store/actions/FeedBackAction";
import { showSuccess } from "../../../utility/Utility";

const AddFeedbackQuestion = () => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [feedbacktype,setFeedbacktype]= useState("");

  const errorsObj = { question: "",feedbacktype:"" };

  const [errors, setErrors] = useState(errorsObj);

  /* form submit */
  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;

    const errorObj = { ...errorsObj };

    if (question == "") {
      errorObj.question = "Question is Required";
      error = true;
    }
    if (feedbacktype == "") {
      errorObj.feedbacktype = "Type is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(addFeedback(question,feedbacktype));
    setFeedbacktype("")
    setQuestion("");
    // showSuccess("Question added successfully");
  };

  
  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Feedback Type
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={feedbacktype}
                                onChange={(e)=>{setFeedbacktype(e.target.value)}}
                              >
                                <option value="">Select Branch</option>
                                <option value="1">FSD</option>
                                <option value="2">CMC</option>
                                <option value="3">NBF</option>

                              </select>
                              {errors.feedbacktype && (
                                <div className="text-danger fs-12">
                                  {errors.feedbacktype}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Question<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Question"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                            />
                            {errors.question && (
                              <div className="text-danger fs-12">
                                {errors.question}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              //   disabled={props.showLoading}
                            >
                              Submit
                              {/* {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null} */}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFeedbackQuestion;
