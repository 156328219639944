import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addSCHExamDetails(title, exam_category_id, board_id, class_id, subject_id, syllabus_file_path, academic_session, exam_date, type_exam, question_type, total_no_question, total_marks, total_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        title,
        exam_category_id,
        board_id,
        class_id,
        subject_id,
        syllabus_file_path,
        academic_session,
        exam_date,
        type_exam,
        question_type,
        total_no_question,
        total_marks,
        total_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_SCHOLASTIC_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function editSCHExamDetails(id, title, exam_category_id, board_id, class_id, subject_id, syllabus_file_path, academic_session, exam_date, type_exam, question_type, total_no_question, total_marks, total_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        title,
        exam_category_id,
        board_id,
        class_id,
        subject_id,
        syllabus_file_path,
        academic_session,
        exam_date,
        type_exam,
        question_type,
        total_no_question,
        total_marks,
        total_time
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOLASTIC_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSCHExamDetails(academic_session, status, board_id, class_id, subject_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        academic_session,
        status,
        board_id,
        class_id,
        subject_id
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SCHOLASTIC_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateSCHExamDetailsStatus(id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        status
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeSCHExamDetails(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_SCHOLASTIC_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addCOMExamDetails(title, exam_category_id, exam_type_id, exam_subtype_id, class_id, syllabus_file_path, academic_session, exam_date, subjects_ary, total_no_question, total_marks, total_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        title,
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        class_id,
        syllabus_file_path,
        academic_session,
        exam_date,
        subjects_ary,
        total_no_question,
        total_marks,
        total_time
    };
    console.log('postData--------addCOMExamDetails------', postData);
    // return;
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_COMPETITIVE_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function editCOMExamDetails(id, title, exam_category_id, exam_type_id, exam_subtype_id, class_id, syllabus_file_path, academic_session, exam_date, subjects_ary, total_no_question, total_marks, total_time) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        title,
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        class_id,
        syllabus_file_path,
        academic_session,
        exam_date,
        subjects_ary,
        total_no_question,
        total_marks,
        total_time
    };
    console.log('postData', postData);
    // return;
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_COMPETITIVE_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getCOMExamDetails(academic_session, status, exam_type_id, class_id, exam_subtype_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        academic_session,
        status,
        exam_type_id,
        class_id,
        exam_subtype_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_COMPETITIVE_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateCOMExamDetailsStatus(id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id,
        status
    }
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeCOMExamDetails(id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_COMPETITIVE_EXAM_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

