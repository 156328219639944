import swal from "sweetalert";
import {
  addContent,
  getContent,
  getList,
  removeContent,
  updateApproveContent,
  uploadQuestionFile,
  getQuestionList,
  getUpdateContent,
  updateQuestion,
  addQuestion,
  getSpecialCharacters,
  downloadQuetion,
  uploadBulkImageFile,
  downloadErrorExcel,
  updateErrorStatus,
  updateQuestionError,
  removeQuestionImage,
  approvedMultipleQuestion,
  removeMultipleQuestion,
} from "../../services/ExamContentServices";

import {
  EXAM_CONTENT_LOADING,
  ADD_EXAM_CONTENT,
  GET_EXAM_CONTENT,
  UPLOAD_APPROVE_EXAM_CONTENT,
  REMOVE_EXAM_CONTENT,
  GET_TOTAL_CONTENT_PAGE_LIST,
  GET_EXAM_CONTENT_LIST,
  GET_QUESTION_ERROR_LIST,
  GET_TOTAL_ERROR_PAGE_LIST,
  GET_QUESTION_DETAILS,
  UPDATE_QUESTION,
  GET_SCECIAL_CHARACTER,
  ADD_QUESTION,
  UPDATE_QUESTION_ERROR_STATUS,
  UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS,
  RELOAD_CONTENT,
  DUPLICATE_QUESTIONS_STATUS_TOGGLE,
  REMOVE_MULTIPLE_QUESTION,
  GET_QUESTION_PREVIEW_DETAILS,
} from "../constants";

import * as utility from "../../utility/Utility";

import { globalLoadingAction } from "../actions/GlobalAction";

export function uploadFile(
  category,
  board,
  type,
  subtype,
  class_id,
  subject_id,
  subject_code,
  chapter_id,
  chapter_code,
  demo,
  file,
  history
) {
  return (dispatch) => {
    uploadQuestionFile(category, demo, file)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(
            addContentData(
              category,
              board,
              type,
              subtype,
              class_id,
              subject_id,
              subject_code,
              chapter_id,
              chapter_code,
              demo,
              response.data.file_path,
              response.data.uploadId
            )
          );
        }
        // dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addContentData(
  category,
  board,
  type,
  subtype,
  class_id,
  subject_id,
  subject_code,
  chapter_id,
  chapter_code,
  demo,
  path,
  uploadId
) {
  return (dispatch) => {
    addContent(
      category,
      board,
      type,
      subtype,
      class_id,
      subject_id,
      subject_code,
      chapter_id,
      chapter_code,
      demo,
      path,
      uploadId
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(addContentAction(response.data));
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
        } else if (response.data.status == 410) {
          dispatch(addContentAction(response.data));
          utility.showError(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addQuestionData(
  category,
  board,
  type_id,
  subtype_id,
  classroom,
  branch,
  chapter,
  questiontype,
  questiono,
  questiobody,
  que_img,
  option,
  optionImg,
  option2,
  option2Img,
  option3,
  option3Img,
  option4,
  option4Img,
  answers,
  answersbody,
  resone_img,
  demo,
  css_group_id,
  history
) {
  return (dispatch) => {
    addQuestion(
      category,
      board,
      type_id,
      subtype_id,
      classroom,
      branch,
      chapter,
      questiontype,
      questiono,
      questiobody,
      que_img,
      option,
      optionImg,
      option2,
      option2Img,
      option3,
      option3Img,
      option4,
      option4Img,
      answers,
      answersbody,
      resone_img,
      demo,
      css_group_id
    )
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          dispatch(addQuestionAction(false));
          history.push("/exam-content-list");
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(loadingContentAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function updateQuestionData(
  id,
  category,
  board,
  examtype_id,
  subtype_id,
  classroom,
  branch,
  chapter,
  questiontype,
  questiono,
  questiobody,
  que_img,
  option,
  optionImg,
  option2,
  option2Img,
  option3,
  option3Img,
  option4,
  option4Img,
  answers,
  answersbody,
  resone_img,
  demo,
  css_group_id,
  state_object,
  history
) {
  return (dispatch) => {
    updateQuestion(
      id,
      category,
      board,
      examtype_id,
      subtype_id,
      classroom,
      branch,
      chapter,
      questiontype,
      questiono,
      questiobody,
      que_img,
      option,
      optionImg,
      option2,
      option2Img,
      option3,
      option3Img,
      option4,
      option4Img,
      answers,
      answersbody,
      resone_img,
      demo,
      css_group_id,
      state_object.filterObj
    )
      .then((response) => {
        //console.log('update response', response.data)
        dispatch(updateQuestionAction(false));
        utility.showSuccess(response.data.msg);
        //history.push('/exam-content-list');
        if (state_object.currentPage == 1) {
          history.push({
            pathname: "/exam-content-list",
            state: state_object,
          });
        } else {
          history.push({
            pathname: "/question-error-log",
            state: state_object,
          });
        }
      })
      .catch((error) => {
        dispatch(updateQuestionAction(false));
        utility.showError(error.response.data);
      });
  };
}


export function updatePreviewQuestionData(
  id,
  category,
  board,
  examtype_id,
  subtype_id,
  classroom,
  branch,
  chapter,
  questiontype,
  questiono,
  questiobody,
  que_img,
  option,
  optionImg,
  option2,
  option2Img,
  option3,
  option3Img,
  option4,
  option4Img,
  answers,
  answersbody,
  resone_img,
  demo,
  css_group_id,
  state_object,
  callbackQuestion,
  history
) {
  return (dispatch) => {
    updateQuestion(
      id,
      category,
      board,
      examtype_id,
      subtype_id,
      classroom,
      branch,
      chapter,
      questiontype,
      questiono,
      questiobody,
      que_img,
      option,
      optionImg,
      option2,
      option2Img,
      option3,
      option3Img,
      option4,
      option4Img,
      answers,
      answersbody,
      resone_img,
      demo,
      css_group_id,
      state_object.filterObj
    )
      .then((response) => {
        if(response.data.status === 200) {
          dispatch(getSubmitionPreviewData(id,callbackQuestion,history));
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function updateContinueQuestionData(
  id,
  category,
  board,
  examtype_id,
  subtype_id,
  classroom,
  branch,
  chapter,
  questiontype,
  questiono,
  questiobody,
  que_img,
  option,
  optionImg,
  option2,
  option2Img,
  option3,
  option3Img,
  option4,
  option4Img,
  answers,
  answersbody,
  resone_img,
  demo,
  css_group_id,
  filterObj,
  history
) {
  return (dispatch) => {
    updateQuestion(
      id,
      category,
      board,
      examtype_id,
      subtype_id,
      classroom,
      branch,
      chapter,
      questiontype,
      questiono,
      questiobody,
      que_img,
      option,
      optionImg,
      option2,
      option2Img,
      option3,
      option3Img,
      option4,
      option4Img,
      answers,
      answersbody,
      resone_img,
      demo,
      css_group_id,
      filterObj,
      history
    )
      .then((response) => {
        if (response.data.nextrecord) {
          dispatch(getQuestionDetailsAction(response.data.nextrecord));
          dispatch(globalLoadingAction(false));
        } else {
          history.push("/exam-content-list");
        }
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getUpdateContentData(id, history) {
  return (dispatch) => {
    getUpdateContent(id)
      .then((response) => {
        dispatch(getQuestionDetailsAction(response.data.data));
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(addContentAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getSubmitionPreviewData(id, callbackQuestion, history) {
  return (dispatch) => {
    getUpdateContent(id)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getSubmitionPreviewAction(response.data.data));
          callbackQuestion();
        }
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function updateErrorLogData(question_no, history) {
  return (dispatch) => {
    updateQuestionError(question_no)
      .then((response) => {
        dispatch(getQuestionDetailsAction(response.data.data));
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getContentData(history) {
  return (dispatch) => {
    getContent()
      .then((response) => {
        dispatch(getContentAction(response.data.data));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getContentList(
  page,
  demo_exam,
  category,
  board,
  type,
  subtype,
  subject,
  className,
  chapter,
  questiono,
  is_approve,
  question_type,
  history
) {
  return (dispatch) => {
    getList(
      page,
      demo_exam,
      category,
      board,
      type,
      subtype,
      subject,
      className,
      chapter,
      questiono,
      is_approve,
      question_type
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getListAction(response.data));
        } else {
          dispatch(getListAction({data: [],total_questions_record: 0,total_page: 0}));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function downloadQuetionFile(load, history) {
  return (dispatch) => {
    downloadQuetion(load)
      .then((response) => {
        console.log("response-----",response.data )
        window.location.href = response.data.filepath;
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function downloadErrorExcelFile(load, history) {
  return (dispatch) => {
    downloadErrorExcel(load)
      .then((response) => {
        window.location.href = response.data.filename;
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function updateErrorStatusData(item, history) {
  return (dispatch) => {
    updateErrorStatus(item.id, item.status == 1 ? 2 : 1)
      .then((response) => {
        dispatch(updateErrorStatusAction(item));
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getQuestionListData(
  page,
  demo,
  question_no,
  branch,
  chapter,
  class_no,
  errorLog,
  history
) {
  return (dispatch) => {
    getQuestionList(
      page,
      demo,
      question_no,
      branch,
      chapter,
      class_no,
      errorLog
    )
      .then((response) => {
        dispatch(getQuestionListAction(response.data));
        dispatch(totalErrorPageAction(response.data.total_page_count));
        //utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateApproveContentData(item, history) {
  return (dispatch) => {
    updateApproveContent(item.id, parseInt(item.is_approve) === 1 ? 0 : 1)
      .then((response) => {
        dispatch(updateApproveContentAction(item));
        dispatch(globalLoadingAction(false));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function approvedMultipleQuestionData(ids, status, history) {
  return (dispatch) => {
    approvedMultipleQuestion(ids, status)
      .then((response) => {
        dispatch(approvedMultipleQuestionAction({ ids: ids, status: status }));
        dispatch(globalLoadingAction(false));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function removeMultipleQuestionData(ids, status, active, history) {
  return (dispatch) => {
    removeMultipleQuestion(ids, status)
      .then((response) => {
        dispatch(
          getContentList(
            active,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            history
          )
        );

        dispatch(removeMultipleQuestionAction({ ids: ids, status: status }));
        swal(response.data.msg, { icon: "success" });
        // dispatch(globalLoadingAction(false));
        // utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function getSpecialCharactersData(history) {
  return (dispatch) => {
    getSpecialCharacters()
      .then((response) => {
        dispatch(getSpecialCharactersAction(response.data.data));
      })
      .catch((error) => {
        dispatch(loadingContentAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function uploadBulkImage(file, history) {
  return (dispatch) => {
    uploadBulkImageFile(file)
      .then((response) => {
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        utility.showError(error.response.data);
      });
  };
}

export function removeContentData(id, active, history) {
  return (dispatch) => {
    removeContent(id)
      .then((response) => {
        dispatch(
          getContentList(
            active,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            history
          )
        );
        dispatch(removeContentAction(id));

        swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function removeQuestionImageData(id, name, index, history) {
  return (dispatch) => {
    removeQuestionImage(id, name, index)
      .then((response) => {
        console.log("delete image", response.data);
        //dispatch(removeContentAction(id));
        //swal(response.data.msg, { icon: "success", });
      })
      .catch((error) => {
        //console.log(error);
        //swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function addContentAction(data) {
  return {
    type: ADD_EXAM_CONTENT,
    payload: data,
  };
}
export function getContentAction(data) {
  return {
    type: GET_EXAM_CONTENT,
    payload: data,
  };
}
export function addQuestionAction(status) {
  return {
    type: ADD_QUESTION,
    payload: status,
  };
}
export function updateQuestionAction(status) {
  return {
    type: UPDATE_QUESTION,
    payload: status,
  };
}
export function getQuestionDetailsAction(data) {
  return {
    type: GET_QUESTION_DETAILS,
    payload: data,
  };
}
export function getSubmitionPreviewAction(data) {
  return {
    type: GET_QUESTION_PREVIEW_DETAILS,
    payload: data,
  };
}
export function getListAction(data) {
  return {
    type: GET_EXAM_CONTENT_LIST,
    payload: data,
  };
}
export function getSpecialCharactersAction(data) {
  return {
    type: GET_SCECIAL_CHARACTER,
    payload: data,
  };
}
export function totalErrorPageAction(data) {
  return {
    type: GET_TOTAL_ERROR_PAGE_LIST,
    payload: data,
  };
}
export function updateApproveContentAction(item) {
  return {
    type: UPLOAD_APPROVE_EXAM_CONTENT,
    payload: item,
  };
}
export function approvedMultipleQuestionAction(item) {
  return {
    type: UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS,
    payload: item,
  };
}
export function removeMultipleQuestionAction(item) {
  return {
    type: REMOVE_MULTIPLE_QUESTION,
    payload: item,
  };
}
export function removeContentAction(id) {
  return {
    type: REMOVE_EXAM_CONTENT,
    payload: id,
  };
}
export function getQuestionListAction(data) {
  return {
    type: GET_QUESTION_ERROR_LIST,
    payload: data,
  };
}
export function updateErrorStatusAction(item) {
  return {
    type: UPDATE_QUESTION_ERROR_STATUS,
    payload: item,
  };
}
export function loadingContentAction(status) {
  return {
    type: EXAM_CONTENT_LOADING,
    payload: status,
  };
}
export function duplicateQuestionsStatusAction(status) {
  return {
    type: DUPLICATE_QUESTIONS_STATUS_TOGGLE,
    payload: status,
  };
}
export function reloadContentAction(status) {
  return {
    type: RELOAD_CONTENT,
    payload: status,
  };
}
