import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { updateProfileData, loadingProfileAction } from '../../../store/actions/ProfileAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import GlobalConfigs from "../../../configs/GlobalConfigs";


import defoult_profile from "../../../images/demo-user.png";


const Profile = (props) => {

	const fileRef = useRef();

	let errorsObj = {
		name: '', email: '', phone: ''
	};

	const [errors, setErrors] = useState(errorsObj);
	const [proImg, setProImg] = useState('');
	const [localImg, setLocalImg] = useState('');
	const [proImgFile, setProImgFile] = useState(null);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {

		onGetDetails();

	}, []);

	/* form stubmit */
	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));

		dispatch(updateProfileData(
			name,
			email,
			phone,
			proImgFile == null ? '' : proImgFile,
			props.history
		));
	}

	/* get user details */
	const onGetDetails = () => {

		let getData = localStorage.getItem('userDetails');
		let profilePic = JSON.parse(getData).profile_pic;
		let name = JSON.parse(getData).name;
		let email = JSON.parse(getData).email;
		let phone = JSON.parse(getData).phone;

		setProImg(profilePic);
		setName(name);
		setEmail(email);
		setPhone(phone);
	}

	/* user image upload */
	const onImageHandle = (event) => {

		let file = event.target.files[0];
		const fr = new FileReader();
		return new Promise((resolve, reject) => {
			fr.addEventListener("load", e => {
				let image = fr.result;
				setLocalImg(image);
				setProImgFile(file);
			});
			fr.addEventListener("error", e => {
				reject();
			});
			fr.readAsDataURL(file);
		});
	}


	return (
		<Fragment>

			<div className="row">
				<div className="col-lg-3"></div>

				<div className="col-lg-6">
					<div className="card card-body">

						<div className="profile-container">

							<div className="profile-header-image">

								{localImg ?
									<img src={localImg} alt="" className="rounded" />
									:
									<img src={proImg ? proImg : defoult_profile} alt="" className="rounded" />
								}
								<div className="profile-custom-file-input">
									<label htmlFor="imgForm"><i className="fa fa-camera m-0" /></label>
									<input
										type="file"
										id="imgForm"
										multiple
										accept="image/*"
										onChange={onImageHandle}
										hidden
									/>
								</div>
							</div>

							<div className="profile-form-content">

								<form onSubmit={onSubmit}>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Name</strong>
										</label>
										<input className="form-control" placeholder="Enter the name" value={name} onChange={(e) => setName(e.target.value)} />
										{errors.name && <div className="text-danger fs-12">{errors.name}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Email</strong>
										</label>
										<input className="form-control email-disable" placeholder="Email" value={email} disabled={true} onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>

									<div className="form-group mb-3">
										<label className="mb-2">
											<strong>Phone</strong>
										</label>
										<input className="form-control" placeholder="Enter the phone no." value={phone} onChange={(e) => setPhone(e.target.value)} />
										{errors.phone && <div className="text-danger fs-12">{errors.phone}</div>}
									</div>


									<div className="text-center mt-4">
										<button type="submit" className="btn btn-primary btn-block profile-update-btn">Update
											{props.loading ?
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												: null}
										</button>
									</div>


								</form>


							</div>

						</div>

					</div>
				</div>

				<div className="col-lg-3"></div>
			</div>

		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		boardList: [],
		loading: false,
	};
};

export default connect(mapStateToProps)(Profile);