import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addComSubscription(exam_type_id, cName, set_count, question_per_set, amount, library_price, only_elibrary, sticker_text, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        exam_type_id,
        class: cName,
        set_count,
        question_per_set,
        amount,
        library_price,
        only_elibrary,
        sticker_text,
        course_code
    };
    console.log('postData', postData);
    //return
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_COMPETETIVE_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateComSubscription(recid, exam_type_id, cName, set_count, question_per_set, amount, library_price, sticker_text, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        exam_type_id,
        class: cName,
        set_count,
        question_per_set,
        amount,
        library_price,
        sticker_text,
        course_code
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_COMPETETIVE_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addSchoSubscription(subject_id, board_id, cName, package_details, library_price, only_elibrary, sticker_text, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        subject_id,
        board_id,
        class: cName,
        package_details,
        library_price,
        only_elibrary,
        sticker_text,
        course_code
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_SCHOLASTIC_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateSchoSubscription(recid, subject_id, board_id, cName, package_details, sticker_text, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        subject_id,
        board_id,
        class: cName,
        package_details,
        sticker_text,
        course_code
    };
    console.log('postData', postData);
    // return
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOLASTIC_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateLibrarySubscription(recid, exam_category_id, exam_type_id, board_id, subject_id, cName, library_price, sticker_text, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        exam_category_id,
        exam_type_id,
        class: cName,
        board_id,
        subject_id,
        library_price,
        sticker_text,
        course_code
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LIBRARY_SUBSCRIPTION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getComSubscriptionList(page, exam_type, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        exam_type,
        class_no
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_COMPETETIVE_SUBSCRIPTION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getComSubscriptionRanking(ranking_no, id, page, exam_type, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        ranking_no,
        id,
        page,
        exam_type,
        class_no
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_COMPETETIVE_SUBSCRIPTION_RANKING_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSchoSubscriptionList(page, subject_id, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        subject_id,
        board_id,
        class_no
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SCHOLASTIC_SUBSCRIPTION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSubscriptionRanking(ranking_no, id, page, subject_id, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        ranking_no,
        id,
        page,
        subject_id,
        board_id,
        class_no
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOLASTIC_SUBSCRIPTION_RANKING_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}


export function getSubscriptionRankingForLibrary(ranking_no, id, page, subject_id, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        ranking_no,
        id,
        page,
        subject_id,
        board_id,
        class_no
    }
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LIBRARY_SUBSCRIPTION_RANKING_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getLibrarySubscriptionList(page, exam_category, exam_type, subject_id, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        exam_category,
        exam_type,
        subject_id,
        board_id,
        class_no
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_E_LIBRARY_SUBSCRIPTION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateStatusComSubscription(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateStatusSchoSubscription(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateStatusLibrarySubscription(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_LIBRARY_SUBSCRIPTION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeComSubscription(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_COMPETETIVE_SUBSCRIPTION_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
export function removeSchoSubscription(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_SCHOLASTIC_SUBSCRIPTION_LIST_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeLibrarySubscription(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_Library_SUBSCRIPTION_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addIntegratedSubscription(integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        integrated_name,
        board_id,
        class: class_name,
        sch_no_set,
        com_exam_details,
        elibrary_exist,
        sticker_text,
        price,
        course_code
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_INTEGRATED_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateIntegratedSubscription(recid, integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        integrated_name,
        board_id,
        class: class_name,
        sch_no_set,
        com_exam_details,
        elibrary_exist,
        sticker_text,
        price,
        course_code
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_INTEGRATED_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getIntegratedSubscription(page, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        board_id,
        class_no
    }
    console.log('postData', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_INTEGRATED_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeIntegratedscription(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_INTEGRATED_SUBSCRIPTION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateIntegratedSubscriptionStatus(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_INTEGRATED_SUBSCRIPTION_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getIntegratedscriptionRanking(ranking_no, id, page, board_id, class_no) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        ranking_no,
        id,
        page,
        board_id,
        class_no
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_INTEGRATED_SUBSCRIPTION_RANKING_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}





