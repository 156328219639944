import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { validateText, validateShortCode, validateTextOnly } from '../../../utility/Utility';
import { branchLoading, updateBranchDetails } from '../../../store/actions/BranchActions';
import { getSubjects, } from '../../../store/actions/SujectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateBranch = (props) => {

	let history = useHistory();
	const { state } = useLocation();
	const dispatch = useDispatch();

	let errorsObj = { name: '', code: '', subject: '', question: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [branchName, setBranchName] = useState(state.branch_name);
	const [branchCode, setBranchCode] = useState(state.branch_code);
	const [branchSubject, setBranchSubject] = useState(state.subject_id);
	const [question, setQuestion] = useState(state.question_no);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getSubjects(props.history));
	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (branchName === '') {
			errorObj.name = 'Branch name is Required';
			error = true;

		} else if (validateTextOnly(branchName) === false) {
			errorObj.name = 'Invalid Branch name';
			error = true;
		}
		if (branchCode === '') {
			errorObj.code = 'Branch code is Required';
			error = true;

		} else if (validateShortCode(branchCode) === false) {
			errorObj.code = 'Invalid Short code';
			error = true;
		}
		if (branchSubject === '') {
			errorObj.subject = 'subject is Required';
			error = true;
		}
		if (question === '') {
			errorObj.question = 'No. of question is Required';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(globalLoadingAction(true));
		dispatch(updateBranchDetails(state.id, branchName, branchSubject, branchCode, question, props.history));
		setBranchName('');
		setBranchCode('');
		setBranchSubject('');
		setQuestion('');
	}

	/* navigation go back */
	const onGoBackHandler = () => {
		history.goBack();
	}

	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3">
										<div className="back-arrow-container">
											<Link
												onClick={onGoBackHandler}>
												<i class="fa-solid fa-arrow-left"></i>
											</Link>
										</div>
									</div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Subject</strong>
														</label>
														<select
															defaultValue={"option"}
															className="form-control form-control"
															value={branchSubject}
															onChange={(e) => setBranchSubject(e.target.value)}
														>
															<option value="">Select subject</option>
															{
																props.subjectList.map((item, index) => {
																	return <option key={index} value={item.id}>{item.name}</option>
																})
															}
														</select>
														{errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Branch name</strong>
														</label>
														<input className="form-control" placeholder="Enter the branch name" value={branchName} onChange={(e) => setBranchName(e.target.value)} />
														{errors.name && <div className="text-danger fs-12">{errors.name}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Branch code</strong>
														</label>
														<input className="form-control" placeholder="Enter the branch code" value={branchCode} onChange={(e) => setBranchCode(e.target.value)} />
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>No. of Question</strong>
														</label>
														<input className="form-control" placeholder="Enter the question No" value={question} onChange={(e) => setQuestion(e.target.value)} />
														{errors.question && <div className="text-danger fs-12">{errors.question}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.branch.showLoading,
		subjectList: state.subject.subjectList,
	};
};
export default connect(mapStateToProps)(UpdateBranch);
