import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function getAdvertisementScreen() {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ADVERTIESMENT_SCREEN_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    //data: formData,
  });
}

export function addAdvertisement(
  title,
  screen,
  file,
  description,
  estimationTime
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const formData = new FormData();
  formData.append("title", title);
  formData.append("screen_name", screen);
  formData.append("advertisments_image", file);
  formData.append("description", description);
  formData.append("estimation_time", estimationTime);
  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_ADVERTIESMENT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
}

export function updateAdvertisement(
  id,
  title,
  screen,
  file,
  description,
  estimationTime
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const formData = new FormData();
  formData.append("recid", id);
  formData.append("title", title);
  formData.append("screen_name", screen);
  formData.append("advertisments_image", file);
  formData.append("description", description);
  formData.append("estimation_time", estimationTime);
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ADVERTIESMENT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
}

export function getAdvertisement() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ADVERTIESMENT_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateStatusAdvertisement(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    recid,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ADVERTIESMENT_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeAdvertisement(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_ADVERTIESMENT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
