import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import {
  validateShortCode,
  validateTextOnly,
  validateSubheading,
} from "../../../utility/Utility";
import {
  addExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const CreateExamCategory = (props) => {
  const dispatch = useDispatch();
  const file = useRef();

  let errorsObj = {
    category: "",
    code: "",
    guestExam: "",
    registerUserExam: "",
    guestLibrary: "",
    registerLibrary: "",
    performanceScore: "",
    libraryPerformanceScore: "",
    photoLiveExam: "",
    photoLibrary: "",
    photoPerformance: "",
    photoPerformanceL: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [guestExam, setGuestExam] = useState("");
  const [registerUserExam, setRegisteruserExam] = useState("");
  const [guestLibrary, setGuestLibrary] = useState("");
  const [registerLibrary, setRegisterLibrary] = useState("");
  const [performanceScore, setPerformanceScore] = useState("");
  const [libraryPerformanceScore, setLibraryPerformanceScore] = useState("");
  const [photoLiveExam, setPhotoLiveExam] = useState("");
  const [photoLiveExamFile, setPhotoLiveExamFile] = useState("");
  const [photoLibrary, setPhotoLibrary] = useState("");
  const [photoLibraryFile, setPhotoLibraryFile] = useState("");
  const [photoPerformance, setPhotoPerformance] = useState("");
  const [photoPerformanceFile, setPhotoPerformanceFile] = useState("");
  const [photoPerformanceL, setPhotoPerformanceL] = useState("");
  const [photoPerformanceLFile, setPhotoPerformanceLFile] = useState("");
  const reader = new FileReader();

  useEffect(() => { }, []);

  /* save image in state */
  const handleChangeLiveExamPhoto = (event) => {
    setPhotoLiveExam(URL.createObjectURL(event.target.files[0]));
    setPhotoLiveExamFile(event.target.files[0]);
  };

/* save image in state */
  const handleChangePhotoLibrary = (event) => {
    setPhotoLibrary(URL.createObjectURL(event.target.files[0]));
    setPhotoLibraryFile(event.target.files[0]);
  };

/* save image in state */
  const handleChangePhotoPerformance = (event) => {
    setPhotoPerformance(URL.createObjectURL(event.target.files[0]));
    setPhotoPerformanceFile(event.target.files[0]);
  };

/* save image in state */
  const handleChangePhotoPerformanceL = (event) => {
    setPhotoPerformanceL(URL.createObjectURL(event.target.files[0]));
    setPhotoPerformanceLFile(event.target.files[0]);
  };

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Exam category is Required";
      error = true;
    } else if (/^[a-zA-Z 0-9]{3,}$/.test(category) === false) {
      errorObj.category = "Invalid Category";
      error = true;
    }

    if (code === "") {
      errorObj.code = "Exam Short code is Required";
      error = true;
    } else if (/^[A-Za-z0-9]{2,}$/.test(code) === false) {
      errorObj.code = "Only alpha numeric characters are allowed";
      error = true;
    }

    if (guestExam === "") {
      errorObj.guestExam = "Guest user exam is Required";
      error = true;
    }

    if (registerUserExam === "") {
      errorObj.registerUserExam = "Register user exam is Required";
      error = true;
    }

    if (guestLibrary === "") {
      errorObj.guestLibrary = "e-library for guest user is Required";
      error = true;
    }

    if (registerLibrary === "") {
      errorObj.registerLibrary = "e-library for register user is Required";
      error = true;
    }

    if (performanceScore === "") {
      errorObj.performanceScore = "Performance score card is Required";
      error = true;
    }

    if (libraryPerformanceScore === "") {
      errorObj.libraryPerformanceScore = "Library performance score card is Required";
      error = true;
    }

    if (photoLiveExam === "") {
      errorObj.photoLiveExam = "Photo of live exam is Required";
      error = true;
    }

    if (photoLibrary === "") {
      errorObj.photoLibrary = "Photo of scholastic e-library is Required";
      error = true;
    }

    if (photoPerformance === "") {
      errorObj.photoPerformance = "Photo of performance score is Required";
      error = true;
    }

    if (photoPerformanceL === "") {
      errorObj.photoPerformanceL = "Photo of e-library performance score is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      addExamCategory(
        category,
        code,
        guestExam,
        registerUserExam,
        guestLibrary,
        registerLibrary,
        performanceScore,
        libraryPerformanceScore,
        photoLiveExamFile,
        photoLibraryFile,
        photoPerformanceFile,
        photoPerformanceLFile,
        props.history
      )
    );
    // setCategory("");
    // setCode("");
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Exam category<span className="text-danger">*</span></strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the category name"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Exam short code<span className="text-danger">*</span></strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the short code"
                              value={code}
                              onChange={(e) => setCode(e.target.value)}
                            />
                            {errors.code && (
                              <div className="text-danger fs-12">
                                {errors.code}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Online exam for guest<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter guest user exam"
                              value={guestExam}
                              onChange={(e) => setGuestExam(e.target.value)}
                            />
                            {errors.guestExam && (
                              <div className="text-danger fs-12">
                                {errors.guestExam}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Online exam for register user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter register user exam"
                              value={registerUserExam}
                              onChange={(e) =>
                                setRegisteruserExam(e.target.value)
                              }
                            />
                            {errors.registerUserExam && (
                              <div className="text-danger fs-12">
                                {errors.registerUserExam}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>e-library for guest user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter guest user e-library"
                              value={guestLibrary}
                              onChange={(e) => setGuestLibrary(e.target.value)}
                            />
                            {errors.guestLibrary && (
                              <div className="text-danger fs-12">
                                {errors.guestLibrary}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>e-library for register user<span className="text-danger">*</span></strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter register user e-library"
                              value={registerLibrary}
                              onChange={(e) =>
                                setRegisterLibrary(e.target.value)
                              }
                            />
                            {errors.registerLibrary && (
                              <div className="text-danger fs-12">
                                {errors.registerLibrary}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Performance score card<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter performance score card"
                              value={performanceScore}
                              onChange={(e) =>
                                setPerformanceScore(e.target.value)
                              }
                            />
                            {errors.performanceScore && (
                              <div className="text-danger fs-12">
                                {errors.performanceScore}
                              </div>
                            )}
                          </div>



                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>E-library performance score card<span className="text-danger">*</span> </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter live e-library"
                              value={libraryPerformanceScore}
                              onChange={(e) =>
                                setLibraryPerformanceScore(e.target.value)
                              }
                            />
                            {errors.libraryPerformanceScore && (
                              <div className="text-danger fs-12">
                                {errors.libraryPerformanceScore}
                              </div>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of live exam<span className="text-danger">*</span>
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangeLiveExamPhoto}
                              />
                              {errors.photoLiveExam && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoLiveExam}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3">
                              {photoLiveExam ? (
                                <img
                                  className="subject-img-form"
                                  src={photoLiveExam}
                                />
                              ) : null}
                            </div>
                          </div>



                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of e-library<span className="text-danger">*</span>
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoLibrary}
                              />
                              {errors.photoLibrary && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoLibrary}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3">
                              {photoLibrary ? (
                                <img
                                  className="subject-img-form"
                                  src={photoLibrary}
                                />
                              ) : null}
                            </div>
                          </div>



                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of performance score<span className="text-danger">*</span>
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoPerformance}
                              />
                              {errors.photoPerformance && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoPerformance}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3">
                              {photoPerformance ? (
                                <img
                                  className="subject-img-form"
                                  src={photoPerformance}
                                />
                              ) : null}
                            </div>
                          </div>



                          <div className="col-sm-6">
                            <div className="form-group mb-3">
                              <label
                                htmlFor="formFile"
                                className="form-label file-form-label"
                              >
                                Upload photo of e-library performance score<span className="text-danger">*</span>
                              </label>
                              <input
                                ref={file}
                                className="form-control"
                                type="file"
                                id="formFile"
                                accept="image/png"
                                onChange={handleChangePhotoPerformanceL}
                              />
                              {errors.photoPerformanceL && (
                                <div className="text-danger fs-12 mt-1">
                                  {errors.photoPerformanceL}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3">
                              {photoPerformanceL ? (
                                <img
                                  className="subject-img-form"
                                  src={photoPerformanceL}
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.category.showLoading,
  };
};

export default connect(mapStateToProps)(CreateExamCategory);
