import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function uploadQuestionFile(catagory_id, demo, file) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let id = JSON.parse(getData).id;

  const formData = new FormData();
  formData.append("user_id", id);
  formData.append("question_type", demo);
  formData.append("exam_category_id", catagory_id);
  formData.append("question_zip", file);

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_QUESTION_FILE_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function addContent(
  exam_category,
  board_id,
  exam_type_id,
  exam_subtype_id,
  class_id,
  branch_id,
  branch_short_code,
  chapter_id,
  chapter_short_code,
  demo_exam,
  path,
  upload_id
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category,
    board_id,
    exam_type_id,
    exam_subtype_id,
    class_id,
    branch_id,
    branch_short_code,
    chapter_id,
    chapter_short_code,
    demo_exam,
    path,
    upload_id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_QUESTION_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getContent() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_BOARD_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUpdateContent(id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_QUESTION_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateQuestionError(question_no) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    question_no,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ERROR_QUESTION_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function addQuestion(
  exam_category,
  board,
  exam_type,
  subtype,
  class_no,
  branch,
  chapter,
  question_type,
  question_no,
  question,
  question_image,
  option_a,
  option_1_image,
  option_b,
  option_2_image,
  option_c,
  option_3_image,
  option_d,
  option_4_image,
  answer,
  reason,
  supporting_reason_image,
  demo_exam,
  css_group_id
) {
  let getData = localStorage.getItem("userDetails");
  let user_id = JSON.parse(getData).id;
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("exam_category", exam_category);
  formData.append("board", board);
  formData.append("exam_type", exam_type);
  formData.append("subtype", subtype);
  formData.append("class", class_no);
  formData.append("branch", branch);
  formData.append("chapter", chapter);
  formData.append("question_type", question_type);
  formData.append("question_no", question_no);
  formData.append("question", question);
  formData.append("option_a", option_a);
  formData.append("option_b", option_b);
  formData.append("option_c", option_c);
  formData.append("option_d", option_d);
  formData.append("answer", answer);
  formData.append("reason", reason);
  formData.append("demo_exam", demo_exam);
  formData.append("css_group_id", css_group_id);

  question_image.forEach((item, index) => {
    formData.append("question_image", item);
  });
  option_1_image.forEach((item, index) => {
    formData.append("option_1_image", item);
  });
  option_2_image.forEach((item, index) => {
    formData.append("option_2_image", item);
  });
  option_3_image.forEach((item, index) => {
    formData.append("option_3_image", item);
  });
  option_4_image.forEach((item, index) => {
    formData.append("option_4_image", item);
  });
  supporting_reason_image.forEach((item, index) => {
    formData.append("supporting_reason_image", item);
  });

  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_QUESTION_DETAILS_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateQuestion(
  id,
  exam_category,
  board,
  exam_type,
  subtype,
  class_no,
  branch,
  chapter,
  question_type,
  question_no,
  question,
  question_image,
  option_a,
  option_1_image,
  option_b,
  option_2_image,
  option_c,
  option_3_image,
  option_d,
  option_4_image,
  answer,
  reason,
  supporting_reason_image,
  demo_exam,
  css_group_id,
  filterObj
) {
  let getData = localStorage.getItem("userDetails");
  let user_id = JSON.parse(getData).id;
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("id", id);
  formData.append("user_id", user_id);
  formData.append("exam_category", exam_category);
  formData.append("board", board);
  formData.append("exam_type", exam_type);
  formData.append("subtype", subtype);
  formData.append("class", class_no);
  formData.append("branch", branch);
  formData.append("chapter", chapter);
  formData.append("question_type", question_type);
  formData.append("question_no", question_no);
  formData.append("question", question);
  formData.append("option_a", option_a);
  formData.append("option_b", option_b);
  formData.append("option_c", option_c);
  formData.append("option_d", option_d);
  formData.append("answer", answer);
  formData.append("reason", reason);
  formData.append("demo_exam", demo_exam);
  formData.append("FilterDemo", filterObj.demo ? filterObj.demo : "");
  formData.append(
    "FilterCategory",
    filterObj.category ? filterObj.category : ""
  );
  formData.append("FilterBoard", filterObj.board ? filterObj.board : "");
  formData.append("FilterType", filterObj.type ? filterObj.type : "");
  formData.append("FilterSubtype", filterObj.subtype ? filterObj.subtype : "");
  formData.append(
    "FilterClassName",
    filterObj.className ? filterObj.className : ""
  );
  formData.append("FilterSubject", filterObj.subject ? filterObj.subject : "");
  formData.append("FilterChapter", filterObj.chapter ? filterObj.chapter : "");
  formData.append(
    "FilterQuestionType",
    filterObj.questionType ? filterObj.questionType : ""
  );
  formData.append("css_group_id", css_group_id);

  question_image.forEach((item, index) => {
    formData.append("question_image", item);
  });
  option_1_image.forEach((item, index) => {
    formData.append("option_1_image", item);
  });
  option_2_image.forEach((item, index) => {
    formData.append("option_2_image", item);
  });
  option_3_image.forEach((item, index) => {
    formData.append("option_3_image", item);
  });
  option_4_image.forEach((item, index) => {
    formData.append("option_4_image", item);
  });
  supporting_reason_image.forEach((item, index) => {
    formData.append("supporting_reason_image", item);
  });
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_QUESTION_DETAILS_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getSpecialCharacters() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_SCECIAL_CHARACTER_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getList(
  page,
  demo_exam,
  exam_category,
  board,
  exam_type,
  subtype,
  branch,
  class_id,
  chapter,
  question_no,
  is_approve,
  question_type
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    demo_exam,
    exam_category,
    board,
    exam_type,
    subtype,
    branch,
    class: class_id,
    chapter,
    question_no,
    is_approve,
    question_type,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_QUESTION_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getQuestionList(
  page,
  exam_type,
  question_no,
  branch,
  chapter,
  class_no,
  log_status
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    question_no,
    branch,
    chapter,
    class: class_no,
    exam_type,
    log_status,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_QUESTION_ERROR_LOG_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateApproveContent(id, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id,
    status,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_QUESTION_APPROVE_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function approvedMultipleQuestion(ids, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    ids,
    status,
  };
  return axios({
    url:
      GlobalConfigs.API_URL + Apis.UPDATE_MULTIPLE_QUESTION_APPROVE_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeMultipleQuestion(ids, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    ids,
    status,
  };
  return axios({
    url:
      GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_QUESTION_APPROVE_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeContent(id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_QUESTION_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeQuestionImage(id, image_name, index) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id,
    image_name,
    index,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_QUESTION_IMAGE_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function downloadQuetion(path) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    path,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.DOWNLOAD_QUESTION_ZIP_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function downloadErrorExcel(path) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    path,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.DOWNLOAD_EXCEL_ERROR_LOG_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    // data: postData,
  });
}

export function uploadBulkImageFile(file) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("question_zip", file);

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_QUESTION_BULK_IMAGE_API,
    method: "POST",
    data: formData,
    headers: {
      ContentType: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateErrorStatus(recid, log_status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
    log_status,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_QUESTION_ERROR_LOG_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
