import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';



export function updateProfile(name, email, phone, profile_pic) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let recid = JSON.parse(getData).id;
    
    const formData = new FormData();
    formData.append("recid", recid);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("profile_pic", profile_pic);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROFILE_API,
        method: "POST",
        headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}


