import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { validateTextOnly } from '../../../utility/Utility';
import { subjectLoading, addSubjectDetails, getNonGroupSubjectsList } from '../../../store/actions/SujectActions';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from '../../../apis/Apis';
import * as Utility from '../../../utility/Utility';

const CreateSubject = (props) => {

	const dispatch = useDispatch();
	const fileRef = useRef();
	const iconRef = useRef();

	let errorsObj = { category: '', type: '', subtype: '', board: '', subject: '', subjectGroup: '', color: '', file: '', iconImg: '', subjectCombo: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [category, setCategory] = useState('');
	const [examType, setExamType] = useState('');
	const [subType, setSubType] = useState('');
	const [board, setBoard] = useState('');
	const [subjectName, setSubjectName] = useState('');
	const [subjectGroup, setSubjectGroup] = useState([]);
	const [isGroup, setIsGroup] = useState(false);
	const [isCheck, setIsCheck] = useState(true);
	const [colorChange, setColorChange] = useState(null);
	const [file, setFile] = useState('');
	const [iconImg, setIconImg] = useState('');
	const [imgLoader, setImgLoader] = useState(false);
	const [iconLoader, setIconLoader] = useState(false);
	const chackbox = document.querySelectorAll(".subject-group input");
	const comboChackbox = document.querySelectorAll(".subject-group.combo input");

	const [isCombo, setIsCombo] = useState(false);
	const [isComboCheck, setIsComboCheck] = useState(true);
	const [subjectCombo, setsubjectCombo] = useState([]);

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (category === '') {
			errorObj.category = 'Category name is Required';
			error = true;
		}
		if (examType === '' && category == 2) {
			errorObj.type = 'Exam type name is Required';
			error = true;
		}
		if (subType === '' && (category == 2 && examType == 1)) {
			errorObj.subtype = 'Sub type name is Required';
			error = true;
		}
		if (board === '' && category == 1) {
			errorObj.board = 'Board name is Required';
			error = true;
		}
		if (subjectName === '') {
			errorObj.subject = 'Subject name is Required';
			error = true;
		} else if (!/^[a-zA-Z0-9\s]+$/.test(subjectName)) {
			errorObj.subject = "Only alpha numeric characters are allowed"; /* Only alphabetic characters is allow */
			error = true;
		}

		if (isGroup && subjectGroup.length == 0) {
			errorObj.subjectGroup = 'Subject group is Required';
			error = true;
		}
		if (isCombo && subjectCombo.length == 0) {
			errorObj.subjectCombo = 'Subject Combo is Required';
			error = true;
		}
		if (!isCombo) {
			if (colorChange === null) {
				errorObj.color = 'Color is Required';
				error = true;
			}
		}
		if (file === '') {
			errorObj.file = 'Image is Required';
			error = true;
		}
		if (!isCombo) {
			if (iconImg === '') {
				errorObj.iconImg = 'Icon image is Required';
				error = true;
			}
		}


		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(addSubjectDetails(
			parseInt(category),
			parseInt(category) == 1 ? '' : parseInt(examType),
			parseInt(category) == 2 && parseInt(examType) == 1 ? parseInt(subType) : '',
			parseInt(category) == 1 ? parseInt(board) : '',
			subjectName,
			isGroup ? 1 : isCombo ? 3 : 2,
			isGroup ? subjectGroup : isCombo ? subjectCombo : [],
			colorChange,
			file,
			iconImg,
			props.history));

		if (!isCombo) {
			iconRef.current.value = "";
		}
		fileRef.current.value = "";
		// iconRef.current.value = "";
		setFile('');
		setIconImg('');
		setCategory('');
		setExamType('');
		setSubType('');
		setBoard('');
		setSubjectName('');
		setSubjectGroup([]);
		setIsGroup(false);
		setIsCombo(false)
		setIsCheck(true);
		setIsComboCheck(true);
		chackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
		comboChackbox.forEach((val, index) => {
			chackbox[index].checked = false;
		});
	}


	/* get category list */
	const getCategoryHandler = () => {
		if (props.categorylist.length == 0) {
			dispatch(loadingToggleAction(true));
			dispatch(getExamCategory(props.history));
		}
	}

	/* get examtype list */
	const getExamtypeHandler = () => {
		if (props.typeList.length == 0) {
			dispatch(loadingAction(true));
			dispatch(getExamType(props.history));
		}
	}

	/* get stubtype list */
	const getSubtypeHandler = () => {
		if (props.subTypeList.length == 0) {
			dispatch(loadingSubTypeAction(true));
			dispatch(getExamSubType(props.history));
		}
	}

	/* get board list */
	const getBoardHandler = () => {
		if (props.boardList.length == 0) {
			dispatch(loadingBoardAction(true));
			dispatch(getBoardData(props.history));
		}
	}

	/* get subject list */
	const getSubjectHandler = (event) => {
		setIsGroup(event.target.checked);
		if (event.target.checked == true) {
			setIsComboCheck(true)
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(
				parseInt(category),
				parseInt(category) == 1 ? '' : parseInt(examType),
				parseInt(category) == 2 && parseInt(examType) == 1 ? parseInt(subType) : '',
				parseInt(category) == 1 ? parseInt(board) : '',
				1,
				props.history));
		} else {
			setIsComboCheck(false)
		}
	}

	/* change category */
	const onCategoryHandler = (val) => {
		setCategory(val);
		setExamType('');
		setBoard('');
		setSubType('');
		setIsGroup(false);
		setIsCombo(false)
		setIsCheck(true);
		setIsComboCheck(true);
	}

	/* change examtype */
	const onExamTypeHandler = (val) => {
		setExamType(val);
		// setIsGroup(false);
		// setIsCombo(false)
		setSubType('');
		// if (val == 1) {
		// 	setIsCheck(true);
		// 	setIsComboCheck(true);
		// } else {
		// 	setIsCheck(false);
		// 	setIsComboCheck(false);
		// }
	}

	/* change subtype */
	const onSubtypeTypeHandler = (val) => {
		setSubType(val);
		setIsGroup(false);
		setIsCombo(false)
		if (val) {
			setIsCheck(false);
			setIsComboCheck(false);
		} else {
			setIsCheck(true);
			setIsComboCheck(true);
		}
	}

	/* change Board handler */
	const onBoardHandler = (val) => {
		setBoard(val);
		setIsGroup(false);
		setIsCombo(false)
		if (val) {
			setIsCheck(false);
			setIsComboCheck(false);
		} else {
			setIsCheck(true);
			setIsComboCheck(true);
		}
	}

	/* change subject  */
	const onSubjectHandler = (e, item) => {
		let array = [...subjectGroup];
		if (e.target.checked) {
			array.push(item.id);
		} else {
			const index = array.findIndex((val) => val.id === item.id);
			array.splice(index, 1);
		}
		setSubjectGroup(array);
	}

	/* change combo subject  */
	const onComboSubjectHandler = (e, item) => {
		let array = [...subjectCombo];
		if (e.target.checked) {
			array.push(item.id);
		} else {
			const index = array.findIndex((val) => val.id === item.id);
			array.splice(index, 1);
		}
		setsubjectCombo(array);
	}


	/* image upload */
	const handleChange = (event) => {
		setFile("")
		if (event.target.files[0].type.startsWith("image/") == false) {
			//this condition is for checking file is image or not
			Utility.showError("Please choose an image file");
			event.target.value = ""
			return;
		}
		setImgLoader(true);
		const formData = new FormData();
		return new Promise((resolve, reject) => {
			formData.append("tag_image", event.target.files[0]);
			axios({
				url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
				method: "POST",
				headers: {
					ContentType: "multipart/form-data",
				},
				data: formData,
			}).then((res) => {
				setImgLoader(false);
				setFile(res.data.imagepath);

			}).then((json) => {
				//console.log('result2', json);
			})

		});
	}

	/* icon image upload */
	const onIconHandler = (event) => {
		setIconImg("")
		if (event.target.files[0].type.startsWith("image/") == false) {
			//this condition is for checking file is image or not
			Utility.showError("Please choose an image file");
			event.target.value = ""
			return;
		}

		setIconLoader(true);
		const formData = new FormData();
		return new Promise((resolve, reject) => {
			formData.append("tag_image", event.target.files[0]);
			axios({
				url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
				method: "POST",
				headers: {
					ContentType: "multipart/form-data",
				},
				data: formData,
			}).then((res) => {
				setIconLoader(false);
				setIconImg(res.data.imagepath);

			}).then((json) => {
				//console.log('result2', json);
			})
		});
	}

	/* get combo subject list */
	const getComboSubjectHandler = (event) => {
		setIsCombo(event.target.checked);
		if (event.target.checked == true) {
			setIsCheck(true)
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(
				parseInt(category),
				parseInt(category) == 1 ? '' : parseInt(examType),
				parseInt(category) == 2 && parseInt(examType) == 1 ? parseInt(subType) : '',
				parseInt(category) == 1 ? parseInt(board) : '',
				3,
				props.history));
		} else {
			setIsCheck(false)
		}
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Category<span className="text-danger">*</span></strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow add-Question-arrow-position">
																{props.categoryLoading ?
																	<Spinner animation="border" size="sm" />
																	: <i className="bi bi-caret-down-fill"></i>}
															</span>
															<select
																defaultValue={"option"}
																className="form-control form-control"
																value={category}
																onClick={getCategoryHandler}
																onChange={(e) => onCategoryHandler(e.target.value)}
															>
																<option value="">Select Category</option>
																{
																	props.categorylist.map((item, index) => {
																		return <option key={index} value={item.id}>{item.category}</option>
																	})
																}
															</select>
														</div>
														{errors.category && <div className="text-danger fs-12">{errors.category}</div>}
													</div>
													{category == 1 ?

														<div className={`form-group mb-3 ${category == 'content_disable' ? '' : null}`}>
															<label className="mb-2">
																<strong>Board<span className="text-danger">*</span></strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow add-Question-arrow-position">
																	{props.boardLoading ?
																		<Spinner animation="border" size="sm" />
																		: <i className="bi bi-caret-down-fill"></i>}
																</span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={board}
																	onClick={getBoardHandler}
																	onChange={(e) => onBoardHandler(e.target.value)}
																>
																	<option value="">Select Board</option>
																	{
																		props.boardList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.name}</option>
																		})
																	}
																</select>
															</div>
															{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
														</div>
														:
														<div className={`form-group mb-3 ${category == '' ? 'content_disable' : null}`}>
															<label className="mb-2">
																<strong>Exam Type<span className="text-danger">*</span></strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow add-Question-arrow-position">
																	{props.typeLoading ?
																		<Spinner animation="border" size="sm" />
																		: <i className="bi bi-caret-down-fill"></i>}
																</span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={examType}
																	onClick={getExamtypeHandler}
																	onChange={(e) => onExamTypeHandler(e.target.value)}
																>
																	<option value="">Select Exam type</option>
																	{
																		props.typeList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.type_name}</option>
																		})
																	}
																</select>
															</div>
															{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
														</div>
													}

													{examType == 1 ?
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Exam Sub Type<span className="text-danger">*</span></strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow add-Question-arrow-position">
																	{props.subTypeLoading ?
																		<Spinner animation="border" size="sm" />
																		: <i className="bi bi-caret-down-fill"></i>}
																</span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={subType}
																	onClick={getSubtypeHandler}
																	onChange={(e) => onSubtypeTypeHandler(e.target.value)}
																>
																	<option value="">Select Sub type</option>
																	{
																		props.subTypeList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.subtype_name}</option>
																		})
																	}
																</select>
															</div>
															{errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
														</div>

														: null}


													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Subject name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the subject name" value={subjectName} onChange={(e) => setSubjectName(e.target.value)} />
														{errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
													</div>

													<div className="form-check mt-3 mb-3">
														<input
															type="checkbox"
															className="form-check-input"
															id="existGroup"
															disabled={isCheck}
															//value={props.totalModules}
															checked={isGroup}
															onChange={(e) => getSubjectHandler(e)}
														/>
														<label
															className="form-check-label"
															htmlFor="existGroup"
														>Group Exist</label>
													</div>

													{isGroup ?
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Subject Group<span className="text-danger">*</span></strong>
															</label>
															<div className="subject-group-view">
																{props.nonGroupsubjectLoading ?
																	<Spinner animation="border" />
																	:
																	<div className="form-subject-group">

																		{
																			props.nonGroupsubjectList.map((item, index) => {
																				return (
																					<div key={index} className="form-check subject-group mt-3">
																						<input
																							type="checkbox"
																							className="form-check-input"
																							id={"subjectGroup" + item.id}
																							//value={props.totalModules}
																							//checked={isGroup}
																							onChange={(e) => onSubjectHandler(e, item)}
																						/>
																						<label
																							className="form-check-label"
																							htmlFor={"subjectGroup" + item.id}
																						>{item.name}</label>
																					</div>
																				);
																			})
																		}

																	</div>
																}
															</div>
															{errors.subjectGroup && <div className="text-danger fs-12">{errors.subjectGroup}</div>}
														</div>
														: null}

													{/* -------------------------- */}
													<div className="form-check mt-3 mb-3">
														<input
															type="checkbox"
															className="form-check-input"
															id="existCombo"
															disabled={isComboCheck}
															//value={props.totalModules}
															checked={isCombo}
															onChange={(e) => getComboSubjectHandler(e)}
														/>
														<label
															className="form-check-label"
															htmlFor="existCombo"
														>Combo</label>
													</div>
													{isCombo ?
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Subject Combo Group<span className="text-danger">*</span></strong>
															</label>
															<div className="subject-group-view">
																{props.nonGroupsubjectLoading ?
																	<Spinner animation="border" />
																	:
																	<div className="form-subject-group">

																		{
																			props.nonGroupsubjectList.map((item, index) => {
																				return (
																					<div key={index} className="form-check subject-group combo mt-3">
																						<input
																							type="checkbox"
																							className="form-check-input"
																							id={"subjectCombo" + item.id}
																							//value={props.totalModules}
																							//checked={isGroup}
																							onChange={(e) => onComboSubjectHandler(e, item)}
																						/>
																						<label
																							className="form-check-label"
																							htmlFor={"subjectCombo" + item.id}
																						>{item.name}</label>
																					</div>
																				);
																			})
																		}

																	</div>
																}
															</div>
															{errors.subjectCombo && <div className="text-danger fs-12">{errors.subjectCombo}</div>}
														</div>
														: null}
													{/* -------------------------- */}

													<div className="row">

														<div className="col-sm-6">
															<div className="form-group mb-3">
																<label htmlFor="formFile" className="form-label file-form-label">Subject Image<span className="text-danger">*</span></label>
																<input
																	ref={fileRef}
																	className="form-control"
																	type="file"
																	id="formFile"
																	accept="image/*"
																	onChange={handleChange} />
																{errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>}
															</div>

															<div className="form-group mb-3">
																{imgLoader ?
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>
																	:
																	<>
																		{file ? <img className="subject-img-form" src={file} /> : null}
																	</>
																}

															</div>
														</div>

														{!isCombo ?
															<div className="col-sm-6">
																<div className="form-group mb-3">
																	<label htmlFor="formIcon" className="form-label file-form-label">Elibrary Image<span className="text-danger">*</span></label>
																	<input
																		ref={iconRef}
																		className="form-control"
																		type="file"
																		id="formIcon"
																		accept="image/*"
																		onChange={onIconHandler} />
																	{errors.iconImg && <div className="text-danger fs-12 mt-1">{errors.iconImg}</div>}
																</div>

																<div className="form-group mb-3">
																	{iconLoader ?
																		<Spinner
																			as="span"
																			animation="border"
																			size="sm"
																			role="status"
																			aria-hidden="true"
																		/>
																		:
																		<>
																			{iconImg ? <img className="subject-img-form" src={iconImg} /> : null}
																		</>
																	}

																</div>
															</div>
															: null}

													</div>
													{!isCombo ?
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Color Picker<span className="text-danger">*</span></strong>
															</label>
															<input
																type="color"
																className="as_colorpicker form-control"
																value={colorChange}
																onChange={(e) => setColorChange(e.target.value)}
															/>
															{errors.color && <div className="text-danger fs-12">{errors.color}</div>}
														</div>
														: null}

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn">
															Submit
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		nonGroupsubjectLoading: state.subject.showLoading,
		nonGroupsubjectList: state.subject.nonGroupSubjectList,
		showLoading: state.class.showLoading,
		categoryLoading: state.category.showLoading,
		categorylist: state.category.list,
		typeLoading: state.type.showLoading,
		typeList: state.type.list,
		subTypeLoading: state.subtype.showLoading,
		subTypeList: state.subtype.list,
		boardLoading: state.board.showLoading,
		boardList: state.board.list,
	};
};
export default connect(mapStateToProps)(CreateSubject);