import {
    ADD_EVENT_HISTORY,
    GET_EVENT_HISTORY_LIST,
    REMOVE_EVENT_HISTORY_LIST,
    UPDATE_EVENT_HISTORY_STATUS_LIST,
    EVENT_HISTORY_LOADING
} from '../constants';

const initialState = {
    eventList: [],
    totalPage: 0,
    totalRecord: 0,
    showLoading: false,
};

export function EventHistoryReducer(state = initialState, action) {

    if (action.type === ADD_EVENT_HISTORY) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === GET_EVENT_HISTORY_LIST) {
        return {
            ...state,
            eventList: action.payload.data,
            totalPage: action.payload.total_page,
            totalRecord:action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_EVENT_HISTORY_STATUS_LIST) {

        const list = [...state.eventList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            eventList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EVENT_HISTORY_LIST) {

        const list = [...state.eventList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            eventList: list,
            showLoading: false,
        };
    }

    if (action.type === EVENT_HISTORY_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


