import swal from "sweetalert";
import {
    addPattern,
    getPattern,
    removePattern,
    updateStatusPattern,
    updatePattern
} from '../../services/QuestionPatternServices';

import {
    QUESTION_PATTERN_LOADING,
    ADD_QUESTION_PATTERN,
    GET_QUESTION_PATTERN,
    REMOVE_QUESTION_PATTERN,
    UPLOAD_STATUS_QUESTION_PATTERN,
    UPDATE_QUESTION_PATTERN
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function addQuestionPattern(name, short_code, exam_category_id, board_ids, marks, history) {
    return (dispatch) => {
        addPattern(name, short_code, exam_category_id, board_ids, marks)
            .then((response) => {
                dispatch(addPatternAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addPatternAction(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getQuestionPattern(history) {
    return (dispatch) => {
        getPattern()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPatternAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateQuestionPattern(id, name, short_code, marks, history) {
    return (dispatch) => {
        updatePattern(id, name, short_code, marks)
            .then((response) => {
                dispatch(updatePatternAction(true));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(updatePatternAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function updateStatusPatternData(item, history) {
    return (dispatch) => {
        updateStatusPattern(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusPatternAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeQuestionPattern(id, history) {
    return (dispatch) => {
        removePattern(id)
            .then((response) => {
                dispatch(removePatternAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addPatternAction(data) {
    return {
        type: ADD_QUESTION_PATTERN,
        payload: data,
    };
}
export function updatePatternAction(data) {
    return {
        type: UPDATE_QUESTION_PATTERN,
        payload: data,
    };
}
export function getPatternAction(data) {
    return {
        type: GET_QUESTION_PATTERN,
        payload: data,
    };
}
export function updateStatusPatternAction(item) {
    return {
        type: UPLOAD_STATUS_QUESTION_PATTERN,
        payload: item,
    };
}
export function removePatternAction(id) {
    return {
        type: REMOVE_QUESTION_PATTERN,
        payload: id,
    };
}
export function loadingPatternAction(status) {
    return {
        type: QUESTION_PATTERN_LOADING,
        payload: status,
    };
}
