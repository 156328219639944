import swal from "sweetalert";
import {
    addBoard,
    getBoard,
    updateBoard,
    removeBoard,
    updateStatusBoard
} from '../../services/BoardServices';

import {
    BOARD_LOADING,
    ADD_BOARD,
    GET_BOARD,
    UPDATE_BOARD,
    REMOVE_BOARD,
    UPDATE_STATUS_BOARD
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addBoardData(board_name, full_name, conducted_by, short_code, history) {
    return (dispatch) => {
        addBoard(board_name, full_name, conducted_by, short_code)
            .then((response) => {
                dispatch(addBoardAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addBoardAction(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateBoardData(id, name, full_name, conducted_by, short_code, history) {
    return (dispatch) => {
        updateBoard(id, name, full_name, conducted_by, short_code)
            .then((response) => {
                dispatch(updateBoardAction(true));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingBoardAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getBoardData(history) {
    return (dispatch) => {
        getBoard()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getBoardAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));   
            });
    };
}

export function updateStatusBoardData(item, history) {
    return (dispatch) => {
        updateStatusBoard(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusBoardAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeBoardData(id, history) {
    return (dispatch) => {
        removeBoard(id)
            .then((response) => {
                dispatch(removeBoardAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addBoardAction(data) {
    return {
        type: ADD_BOARD,
        payload: data,
    };
}
export function updateBoardAction(data) {
    return {
        type: UPDATE_BOARD,
        payload: data,
    };
}
export function getBoardAction(data) {
    return {
        type: GET_BOARD,
        payload: data,
    };
}
export function updateStatusBoardAction(item) {
    return {
        type: UPDATE_STATUS_BOARD,
        payload: item,
    };
}
export function removeBoardAction(id) {
    return {
        type: REMOVE_BOARD,
        payload: id,
    };
}
export function loadingBoardAction(status) {
    return {
        type: BOARD_LOADING,
        payload: status,
    };
}
