import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
 
  
    return (
        <div className="authincation h-100 p-meddle">
        <div className="container h-100">
           <div className="row justify-content-center h-100 align-items-center ">
              <div className="col-md-6">
                 <div className="form-input-content text-center error-page">
                    <h6 style={{fontSize:'3.5rem'}} className="error-text new_size font-weight-bold">Error:Page Not Found</h6>
                    <h4 style={{fontSize:'1.5rem'}}>
                       <i className="fa fa-exclamation-triangle text-warning" />{" "}
                       Oops! It seems the page you are looking for is not found. Double-check the address or return to the homepage then try.
                    </h4>
                    {/* <p>
                       You may have mistyped the address or the page may have
                       moved.
                    </p> */}
                    <div>
                       <Link className="btn btn-primary text-center" to="/dashboard">
                          Back to Home
                       </Link>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
    )
}