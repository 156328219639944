import {
    CLASS_LOADING,
    ADD_CLASS,
    UPDATE_CLASS,
    GET_CLASS,
    REMOVE_CLASS,
    UPDATE_STATUS_CLASS,
    GET_BOARD_BY_CLASS,
    GET_EXAM_TYPE_BY_CLASS
} from '../constants';

const initialState = {
    list: [],
    boardByClasslist: [],
    examTypeByClasslist: [],
    showLoading: false,
};

export function ClassReducer(state = initialState, action) {

    if (action.type === ADD_CLASS) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_CLASS) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_BOARD_BY_CLASS) {
        return {
            ...state,
            boardByClasslist: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_TYPE_BY_CLASS) {
        return {
            ...state,
            examTypeByClasslist: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CLASS) {

        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_CLASS) {

        const classList = [...state.list];
        const index = classList.findIndex((item) => item.id === action.payload.id);
        classList[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: classList,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CLASS) {

        const classList = [...state.list];
        const index = classList.findIndex((item) => item.id === action.payload);
        classList.splice(index, 1);

        return {
            ...state,
            list: classList,
            showLoading: false,
        };
    }

    if (action.type === CLASS_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


