import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";


export function getResumes(page, apply_for, apply_date_start, apply_date_end,) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    apply_for,
    apply_date_start,
    apply_date_end,
  };
  console.log("startDate...", apply_date_start)

  return axios({
    url: GlobalConfigs.API_URL + Apis.RESUME_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateResumeStatus(id, comment) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  console.log("comment before api call", comment)

  const postData = {
    id,
    comment
  };


  return axios({
    url: GlobalConfigs.API_URL + Apis.RESUME_STATUS_UPDATE,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function invidualResume(id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.INDIVIDUAL_RESUME,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}


