import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import * as utility from '../../../utility/Utility';
import { ToastContainer } from 'react-toastify';

import { getContentValidatorData, loadingContentValidatorAction } from '../../../store/actions/ContentValidatorAction';
import { getContentDesignerData, loadingContentDesignerAction } from '../../../store/actions/ContentDesignerAction';
import { approveValidatorData, loadingElibraryAction, uploadConceptData, saveAndUploadConceptMapData, uploadConceptMapAction } from '../../../store/actions/ElibraryAction';

const ElibraryContentStatus = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const fileRef = useRef();
    const conceptMapPath = useSelector(state => state.elibrary.conceptMapPath)

    const [previousScreenValue, setPreviousScreenValue] = useState(state)
    const [pdfPageNo, setPdfPageNo] = useState()
    const [file, setFile] = useState('');
    let errorsObj = { file: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [onSaveLoader, setOnSaveLoader] = useState(false)
    const [onSavePubLoader, setOnSavePubLoader] = useState(false)
    const [validator, setValidator] = useState()

    useEffect(() => {
        dispatch(loadingContentValidatorAction(true));
        dispatch(getContentValidatorData(1,'','','',props.history));
        dispatch(loadingContentDesignerAction(true));
        dispatch(getContentDesignerData(1,'','','',props.history));
    }, [conceptMapPath]);

    /* navigation go back */
    const onGoBackHandler = () => {
        history.goBack();
    }

    /* pdf file save */
    const onSaveAndPublish = async (e) => {
        e.preventDefault();
        dispatch(uploadConceptMapAction(null));
        let error = false;
        const errorObj = { ...errorsObj };
        if (file === '') {
            errorObj.file = 'File is Required';
            error = true;
        } else if (file.type != "application/pdf") {
            errorObj.file = 'Only PDFs are valid';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return
        }
        setOnSaveLoader(false)
        setOnSavePubLoader(true)
        dispatch(loadingElibraryAction(true));
        dispatch(saveAndUploadConceptMapData(previousScreenValue.id, file, props.history))
    }

    const copyPath = () => {
        if (pdfPageNo == '' || pdfPageNo == undefined) {
            utility.showError("Please put PDF page number");
            return
        } else {
            utility.showSuccess("Link copied");
            navigator.clipboard.writeText(previousScreenValue.pdf_path + '#page=' + pdfPageNo)
        }
    }

    /* cile validation */
    const OnSave = async (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (file === '') {
            errorObj.file = 'File is Required';
            error = true;
        } else if (file.type != "application/pdf") {
            errorObj.file = 'Only PDFs are valid';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return
        }
        setOnSaveLoader(true)
        setOnSavePubLoader(false)
        dispatch(loadingElibraryAction(true))
        dispatch(uploadConceptData(previousScreenValue.id, file, props.history));
    }

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    }

    /* preview concept */
    const previewConceptMap = () => {
        history.push({ pathname: "/elibrary-concept-map", state: { conceptMapPath: conceptMapPath } })
    }

    const copyConceptMapPath = () => {
        utility.showSuccess("Concept map lLink copied");
        navigator.clipboard.writeText(conceptMapPath)
    }

    const onvalidatordHandler = () => {
        console.log("onvalidatordHandler")
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-2">
                                        <div className="back-arrow-container">
                                            <Link to="#"
                                                onClick={onGoBackHandler}>
                                                <i className="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="card-body">
                                            <div className="basic-details">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="basic-details-item">
                                                            <label>Category:</label><span>{previousScreenValue.category}</span>
                                                        </div>
                                                        <div className="basic-details-item">
                                                            <label>Branch Name:</label><span>{previousScreenValue.branch_name}</span>
                                                        </div>
                                                        <div className="basic-details-item">
                                                            <label>Board Name:</label><span>{previousScreenValue.board_name}</span>
                                                        </div>

                                                    </div>
                                                    <div className="col-xl-6">
                                                        {previousScreenValue.type_name ?
                                                            <div className="basic-details-item">
                                                                <label>Exam Type:</label><span>{previousScreenValue.type_name}</span>
                                                            </div>
                                                            : null}

                                                        <div className="basic-details-item">
                                                            <label>Chapter Name:</label><span>{previousScreenValue.chapter_name}</span>
                                                        </div>
                                                        <div className="basic-details-item">
                                                            <label>Class No:</label><span>{previousScreenValue.class_no}</span>
                                                        </div>
                                                        <div className="basic-details-item">
                                                            <label>Status:</label><span>{previousScreenValue.status == 0 ? 'inactive' : 'active'}</span>
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Validator</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={validator}
                                                                onChange={onvalidatordHandler}
                                                            >
                                                                <option value="">Select Validator</option>
                                                                {
                                                                    props.contentValidatorList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-xl-12">
                                                        <div className="basic-details-item m-0 path_container">
                                                            <div className="pdf_left_side">
                                                                <label>PDF Path:</label><span>{previousScreenValue.zip_path}</span>
                                                                {previousScreenValue.pdf_path + '#page='}
                                                                <input
                                                                    type="text"
                                                                    className='pdf_page_input'
                                                                    placeholder="page no."
                                                                    onChange={(e) => setPdfPageNo(e.target.value)}
                                                                    value={pdfPageNo}
                                                                    name="pdfpageno"
                                                                    autoComplete="off"
                                                                />
                                                            </div>

                                                            <div className="pdf_right_side">
                                                                <Link to="#" onClick={copyPath}>
                                                                    <i className="bi bi-bookmarks"></i>
                                                                </Link>
                                                            </div>

                                                        </div>
                                                    </div> */}



                                                    {/* <div className="mb-3">
                                                        <label htmlFor="formFileDisabled" className="form-label file-form-label">Concept Map</label>
                                                        <input
                                                            ref={fileRef}
                                                            className="form-control"
                                                            type="file"
                                                            id="formFileDisabled"
                                                            accept=".pdf"
                                                            onChange={handleChange} />
                                                        {errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>}
                                                    </div> */}

                                                    {/* {conceptMapPath != null ?
                                                        <div className="col-xl-12">
                                                            <div className="basic-details-item m-0 path_container">
                                                                <div className="pdf_left_side">
                                                                    <label>Concept Map Path:</label>
                                                                    {conceptMapPath}
                                                                </div>

                                                                <div className="pdf_right_side">
                                                                    <Link to="#" onClick={copyConceptMapPath}>
                                                                        <i className="bi bi-bookmarks"></i>
                                                                    </Link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        : null} */}


                                                    <div className="col-xl-3"></div>
                                                    <div className="col-xl-12 button_container">
                                                        <div className="text-center col-xl-3 mt-4">
                                                            <button type="submit" onClick={OnSave} className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Save
                                                                {props.loader && onSaveLoader ?
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    : null}
                                                            </button>
                                                        </div>
                                                        <div className="text-center col-xl-4  mt-4">
                                                            <button type="submit" onClick={onSaveAndPublish} className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Save & Publish
                                                                {props.loader && onSavePubLoader ?
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    : null}
                                                            </button>
                                                        </div>
                                                        {conceptMapPath != null ?
                                                            <div className="text-center col-xl-3  mt-4">
                                                                <button onClick={previewConceptMap} className="btn btn-primary btn-block category-btn" >Preview
                                                                    {/* {props.showLoading ?
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    : null} */}
                                                                </button>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div className="col-xl-3"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-2"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
            <div><ToastContainer /></div>

        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.contentvalidator.showLoading,
        contentValidatorList: state.contentvalidator.list,
        contentdesignerList: state.contentdesigner.list,
        loader: state.elibrary.showLoading,
    };
};

export default connect(mapStateToProps)(ElibraryContentStatus);