import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import {
  validateEmail,
  validatePhone,
  validateName,
  inputNumberOnly,
  inputAlphabetOnly
} from "../../../utility/Utility";
import {
  loadingUserAction,
  getUserTypeData,
  updateDeveloperData,
  getDistrictData,
} from "../../../store/actions/UserAction";
import { getSubjectsByBoardsDetails } from "../../../store/actions/SujectActions";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getRolesData, createUsersDropdownDetails } from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getStateData } from "../../../store/actions/UserAction";
import DatePicker from "react-datepicker";
import Select from "react-select";


const UpdateContentDeveloper = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const DOBRef = useRef();
  const enrollmentRef = useRef();

  let errorsObj = {
    name: "",
    email: "",
    number: "",
    type: "",
    fName: "",
    mName: "",
    lName: "",
    dob: "",
    gender: "",
    number: "",
    email: "",
    stat: "",
    pincode: "",
    district: "",
    city: "",
    house: "",
    location: "",
    panNo: "",
    aadhar: "",
    qualification: "",
    experience: "",
    board: "",
    subjectPre: "",
    classPre: "",
    timePre: "",
    language: "",
    knowleage: "",
    school: "",
    incharge: "",
    board: "",
    standard: "",
    academic: "",
    enrollment: "",
    rollTime: "",
    RollId: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [firstName, setFirstName] = useState(state?.item?.fname ? state?.item?.fname : "");
  const [middleName, setMiddleName] = useState(state?.item?.mname ? state?.item?.mname : "");
  const [lastName, setLastName] = useState(state?.item?.lname ? state?.item?.lname : "");
  const [email, setEmail] = useState(state?.item?.email);
  const [number, setNumber] = useState(state?.item?.phone);
  const [userType, setUserType] = useState(2);
  // const [boardMultipleId, setBoardMultipleId] = useState(state?.item?.board_preference);
  const [boardMultipleId, setBoardMultipleId] = useState();
  const [dob, setDob] = useState(new Date());
  const [pincode, setPincode] = useState(state?.item?.pincode ? state?.item?.pincode : "");
  const [gender, setGender] = useState(state?.item?.gender ? state?.item?.gender : "");

  const [stat, setState] = useState(state?.item?.state_id + "^" + state?.item?.state_name);
  // const [stat_id, setStateId] = useState(state?.item?.state_id ? state?.item?.state_id : "");
  const [district, setDistrict] = useState(
    state?.item?.district_name ? state?.item?.district_name : ""
  );
  const [city, setCity] = useState(state?.item?.city_name ? state?.item?.city_name : "");
  const [houseName, setHouseName] = useState(
    state?.item?.house_flat_name ? state?.item?.house_flat_name : ""
  );
  const [location, setLocation] = useState(
    state?.item?.area_location ? state?.item?.area_location : ""
  );
  const [panNo, setPanNo] = useState(state?.item?.pan_card ? state?.item?.pan_card : "");
  const [aadhar, setAadhar] = useState(
    state?.item?.adhar_card ? state?.item?.adhar_card : ""
  );
  const [qualification, setQualification] = useState(
    state?.item?.highest_qualification ? state?.item?.highest_qualification : ""
  );
  const [experience, setExperience] = useState(
    state?.item?.experience ? state?.item?.experience : ""
  );
  const [boardPreference, setBoardPreference] = useState(
    state?.item?.board_preference ? state?.item?.board_preference : ""
  );
  const [subjectPreference, setSubjectPreference] = useState(
    state?.item?.subject_preference ? state?.item?.subject_preference : ""
  );
  const [subjectOptionsByBoardId, setSubjectOptionsByBoardId] = useState([]);

  const [subjectOptionsByBoardMultiId, setSubjectOptionsByBoardMultiId] = useState([]);

  const [classPreference, setClassPreference] = useState(
    state?.item?.class_preference ? state?.item?.class_preference : ""
  );
  const [timeSlotPreference, setTimeSlotPreference] = useState(
    state?.item?.time_slot_preference ? state?.item?.time_slot_preference : ""
  );
  const [language, setLanguage] = useState(
    state?.item?.language_communication ? state?.item?.language_communication : ""
  );
  const [computerKnowledge, setComputerKnowledge] = useState(
    state?.item?.computer_knowledge ? state?.item?.computer_knowledge : ""
  );
  const [schoolName, setSchoolName] = useState(
    state?.item?.school_name ? state?.item?.school_name : ""
  );
  const [inchargeName, setInchargeName] = useState(
    state?.item?.incharge_name ? state?.item?.incharge_name : ""
  );
  const [board, setBoard] = useState(state?.item?.board ? state?.item?.board : "");
  const [standard, setStandard] = useState(
    state?.item?.standard ? state?.item?.standard : ""
  );
  const [academicYear, setAcademicYear] = useState(
    state?.item?.academic_year ? state?.item?.academic_year : ""
  );
  const [enrollment, setEnrollment] = useState(new Date());
  const [rollTime, setRollTime] = useState(state?.item?.roll);
  const [RollId, setRollId] = useState(state?.item?.role_id ? state?.item?.role_id : "");

  const [previousPageFilterData, setPreviousPageFilterData] = useState('');

  /* board select */
  const selectedBoard = (e) => {
    var previousBoard = props.boardList.filter((element) => e?.item?.board_preference?.includes(element.id))
    const options = previousBoard.map((item) => ({
      label: item.name,
      value: item.name,
      id: item.id,
    }));
    console.log("options----344---", options)
    setBoardMultipleId(options)
  }

  /* select subject */
  const selectedSubject = (e) => {
    if (props.getSubjectsByBoards.status == 200) {
      var previousSubject = props.getSubjectsByBoards.data.filter((element) => e?.item?.subject_preference?.includes(element.id))
      const options = previousSubject.map((item) => ({
        label: item.name + "(" + item.board_name + ")",
        value: item.name,
        subject_code: item.subject_code,
        id: item.id,
      }));
      setSubjectOptionsByBoardMultiId(options)
    }
  }

  useEffect(() => {
    // console.log("get data from previous page--------------", state);
    setPreviousPageFilterData(props?.location.state?.filterData)
  
    selectedBoard(state);

    dispatch(getRolesData());
    dispatch(loadingUserAction(true));
    dispatch(getStateData());
    dispatch(getBoardData(props.history));
    dispatch(getUserTypeData(props.history));
    dispatch(getSubjectsByBoardsDetails(0));
    // dispatch(getSubjectsByBoardsDetails(0));
    dispatch(createUsersDropdownDetails());
  }, []);

  /* useEffect(() => {
    let previousBoard = props.boardList.filter(element => element.id == state?.item?.board_preference);
    const options = previousBoard.map((item) => ({
      label: item.name,
      value: item.name,
      id: item.id,
    }));
    setBoardMultipleId(options)

  }, [state?.item?.board_preference]); */

  useEffect(() => {
    if (state?.item?.board_preference != '') {

      dispatch(getSubjectsByBoardsDetails(state?.item?.board_preference.toString()));
      selectedSubject(state);
    }
  }, [state?.item?.board_preference, boardMultipleId]);

  useEffect(() => {
    if (state !== "") {
      dispatch(globalLoadingAction(true));
      dispatch(getDistrictData(stat.split("^")[0]));
      // dispatch(getDistrictData(state?.item?.state_id));
    }
  }, [stat,]);

  useEffect(() => {
    let subjectOptions = [];
    if (props.getSubjectsByBoards.status == 200) {
      // console.log("@@@--", props.getSubjectsByBoards.data)
      subjectOptions = props.getSubjectsByBoards.data.map((item) => ({
        label: item.name + "(" + item.board_name + ")",
        // label: item.name,
        value: item.name,
        subject_code: item.subject_code,
        id: item.id,
      }));
    }
    // console.log("^^^^^^", subjectOptions)
    setSubjectOptionsByBoardId(subjectOptions);
  }, [props.getSubjectsByBoards, state?.item?.subject_preference]);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (userType === "") {
      errorObj.type = "Type is Required";
      error = true;
    }


    if (firstName === "") {
      errorObj.fName = "First Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(firstName)) {
      errorObj.fName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(firstName)) {
      errorObj.fName = "Space are not allow";
      error = true;
    } else if (!/^.{2,20}$/.test(firstName)) {
      errorObj.fName = "Minimum 2 and maximum 20 characters are allow";
      error = true;
    }
    if (middleName !== "" && !/^[a-zA-Z\s]+$/.test(middleName)) {
      errorObj.mName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(middleName)) {
      errorObj.mName = "Space are not allow";
    }
    if (lastName === "") {
      errorObj.lName = "Last Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(lastName)) {
      errorObj.lName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(lastName)) {
      errorObj.lName = "Space are not allow";
      error = true;
    } else if (!/^.{2,20}$/.test(lastName)) {
      errorObj.lName = "Minimum 2 and maximum 20 characters are allow";
      error = true;
    }
    if (dob === "") {
      errorObj.dob = "Date of Birth is Required";
      error = true;
    }
    if (gender === "") {
      errorObj.gender = "Gender is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (validateEmail(email) === true) {
      errorObj.email = "Invalid Email";
      error = true;
    }
    if (number === "") {
      errorObj.number = "Number is Required";
      error = true;
    }
    if (validatePhone(number) === false) {
      errorObj.number = "Invalid Number";
      error = true;
    }
    if (stat === "") {
      errorObj.stat = "State is Required";
      error = true;
    }
    if (district === "") {
      errorObj.district = "District is Required";
      error = true;
    }
    if (city === "") {
      errorObj.city = "City is Required";
      error = true;
    } else if (/^\s|\s$/.test(city)) {
      errorObj.city = "only space and end space are not allow";
      error = true;
    }
    if (houseName === "") {
      errorObj.house = "House / Flat Name is Required";
      error = true;
    } else if (/^\s|\s$/.test(houseName)) {
      errorObj.house = "only space and end space are not allow";
      error = true;
    }
    if (location === "") {
      errorObj.location = "Area / Location is Required";
      error = true;
    } else if (/^\s|\s$/.test(location)) {
      errorObj.location = "only space and end space are not allow";
      error = true;
    }
    if (panNo === "") {
      errorObj.panNo = "Pan no. is Required";
      error = true;
    } else if (!/^[A-Z]{5}\d{4}[A-Z]$/.test(panNo)) {
      errorObj.panNo = "Pan no. is Invalid";
      error = true;
    }
    if (aadhar === "") {
      errorObj.aadhar = "Aadhaar no. is Required";
      error = true;
    } else if (!/^\d{12}$/.test(aadhar)) {
      errorObj.aadhar = "Aadhaar no. is Invalid";
      error = true;
    }
    if (qualification === "") {
      errorObj.qualification = "Highest Qualification is Required";
      error = true;
    } else if (/^\s|\s$/.test(qualification)) {
      errorObj.qualification = "only space and end space are not allow";
      error = true;
    }
    if (experience === "") {
      errorObj.experience = "Experience is Required";
      error = true;
    } else if (/^\s|\s$/.test(experience)) {
      errorObj.experience = "only space and end space are not allow";
      error = true;
    }
    if (pincode === "" || !/^\d{6}$/.test(pincode)) {
      errorObj.pincode = "A valid pin Code is Required";
      error = true;
    }
    if (boardMultipleId.length === 0) {
      errorObj.board = "Board is Required";
      error = true;
    }

    if (subjectPreference === "" && userType != 1 && userType != 7) {
      errorObj.subjectPre = "Subject preferance is Required";
      error = true;
    } else if (/^\s|\s$/.test(subjectPreference)) {
      errorObj.subjectPre = "only space and end space are not allow";
      error = true;
    }
    if (classPreference === "" && userType != 1 && userType != 7) {
      errorObj.classPre = "Class Preference is Required";
      error = true;
    } else if (/^\s|\s$/.test(classPreference)) {
      errorObj.classPre = "only space and end space are not allow";
      error = true;
    }
    if (timeSlotPreference === "" && userType != 1 && userType != 7) {
      errorObj.timePre = "Time Slot Preference is Required";
      error = true;
    } else if (/^\s|\s$/.test(timeSlotPreference)) {
      errorObj.timePre = "only space and end space are not allow";
      error = true;
    }
    if (language === "" && userType != 1 && userType != 7) {
      errorObj.language = "Language of communication is Required";
      error = true;
    } else if (/^\s|\s$/.test(language)) {
      errorObj.language = "only space and end space are not allow";
      error = true;
    }
    if (computerKnowledge === "") {
      errorObj.knowleage = "Computer Knowleage is Required";
      error = true;
    } else if (/^\s|\s$/.test(computerKnowledge)) {
      errorObj.knowleage = "only space and end space are not allow";
      error = true;
    }
    if (schoolName === "" && userType == 7 && userType == 8) {
      errorObj.school = "School Name is Required";
      error = true;
    }
    if (inchargeName === "" && userType == 7) {
      errorObj.incharge = "Name of the Incharge is Required";
      error = true;
    }
    if (board === "" && userType == 7) {
      errorObj.board = "Board name is Required";
      error = true;
    }
    if (standard === "" && userType == 7) {
      errorObj.standard = "Standard Name is Required";
      error = true;
    }
    if (academicYear === "") {
      errorObj.academic = "Academic Year is Required";
      error = true;
    } else if (/^\s|\s$/.test(academicYear)) {
      errorObj.academic = "only space and end space are not allow";
      error = true;
    }
    if (enrollment === "") {
      errorObj.enrollment = "Date of Enrollment is Required";
      error = true;
    }

    if (rollTime === "" && userType != 1 && userType != 7) {
      errorObj.rollTime = "Roll Time is Required";
      error = true;
    } else if (/^\s|\s$/.test(rollTime)) {
      errorObj.rollTime = "only space and end space are not allow";
      error = true;
    }


    setErrors(errorObj);
    if (error) {
      return;
    }

    // console.log("*********----", boardPreference, subjectPreference )
    dispatch(loadingUserAction(true));
    dispatch(updateDeveloperData(
      state.item.id,
      parseInt(),
      firstName,
      middleName,
      lastName,
      moment(dob).format("MM/DD/YYYY"),
      gender,
      parseInt(number),

      houseName,
      location,
      city,
      district,
      stat?.split("^")[1],
      stat?.split("^")[0],
      parseInt(pincode),
      parseInt(userType) == 7 ? "" : panNo,
      aadhar,
      parseInt(userType) == 7 ? "" : qualification,
      parseInt(userType) == 7 ? "" : experience,
      parseInt(userType) == 7 ? "" : subjectPreference,
      parseInt(userType) == 7 ? "" : boardPreference,
      parseInt(userType) == 7 ? "" : classPreference,
      parseInt(userType) == 7 ? "" : timeSlotPreference,
      language,
      computerKnowledge,
      academicYear,
      moment(enrollment).format("MM/DD/YYYY"),
      parseInt(userType) == 8 ? "" : rollTime,
      parseInt(userType) == 7 ? board : "",
      parseInt(userType) == 7 ? standard : "",
      parseInt(userType) == 7 && parseInt(userType) == 8 ? schoolName : "",
      parseInt(userType) == 7 ? inchargeName : "",
      "",
      history
    ));

    setEmail("");
    setNumber("");
  }

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/content-developer-list",
      state: { previousPageFilterData },
    });
  };

  /* number restriction */
  const onNumberHandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setNumber(e.target.value);
    }
  };

  const options = props.boardList.map((item) => ({
    label: item.name,
    value: item.name,
    id: item.id,
  }));


  /* board handler */
  const boardPreferenceHandler = (data) => {

    setBoardMultipleId(getIdsAsString(data));
    let boardId = [];
    let addBoardId = null;
    for (let i = 0; i < data.length; i++) {
      addBoardId = data[i].id;
      boardId.push(addBoardId);
    }
    // console.log("$$$$---", boardId.join(","))
    setBoardPreference(boardId);
    if (boardId != "") {
      dispatch(getSubjectsByBoardsDetails(boardId.join(",")));
    }
  };

  /* subject handler */
  const subjectPreferenceHandler = (data) => {

    setSubjectOptionsByBoardMultiId(data);
    let subjectId = [];
    let addSubjectId = null;
    for (let i = 0; i < data.length; i++) {
      addSubjectId = data[i].id;
      subjectId.push(addSubjectId);
    }
    setSubjectPreference(subjectId);
  };

  function getIdsAsString(arrayOfObjects) {
    const idsArray = arrayOfObjects.map(obj => obj.id);
    return idsArray.join(',');
  }

  const onStateHandler = (val) => {
    setState(val);
    setDistrict("")
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="col-xl-2">
                  <div className="back-arrow-container">
                    <Link onClick={onGoBackHandler}>
                      <i class="fa-solid fa-arrow-left"></i>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            {/* <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  User Type
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={userType}
                                  onChange={(e) => setUserType(e.target.value)}
                                >
                                  <option value="">Select user type</option>
                                  {props.userTypeList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.user_type_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.type}
                                </div>
                              )}
                            </div> */}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  First Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.fName && (
                                <div className="text-danger fs-12">
                                  {errors.fName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Middle Name</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the middle name"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.mName && (
                                <div className="text-danger fs-12">
                                  {errors.mName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.lName && (
                                <div className="text-danger fs-12">
                                  {errors.lName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  DOB<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={DOBRef}
                                  selected={dob}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setDob(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() => DOBRef.current.setOpen(true)}
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.dob && (
                                <div className="text-danger fs-12">
                                  {errors.dob}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Gender<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={gender}
                                  onChange={(e) => setGender(e.target.value)}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                              {errors.gender && (
                                <div className="text-danger fs-12">
                                  {errors.gender}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Contact No.
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact no."
                                value={number}
                                onChange={(e) => onNumberHandler(e)}
                                onKeyPress={inputNumberOnly}
                                maxlength="10"
                              />
                              {errors.number && (
                                <div className="text-danger fs-12">
                                  {errors.number}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Email<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the email"
                                readOnly
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <div className="text-danger fs-12">
                                  {errors.email}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  State<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={stat}
                                  // onChange={(e) => setState(e.target.value)}
                                  onChange={(e) => onStateHandler(e.target.value)}
                                >
                                  <option value="">Select State</option>
                                  {props.stateList.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item.id + "^" + item.state_name}
                                      // value={item.state_name}
                                      >
                                        {item.state_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.stat && (
                                <div className="text-danger fs-12">
                                  {errors.stat}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  District<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>

                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={district}
                                  onChange={(e) => setDistrict(e.target.value)}
                                >
                                  <option value="">Select district</option>
                                  {props.districtList.map((item, index) => {

                                    return (
                                      <option
                                        key={index}
                                        value={item.district_name}
                                      >
                                        {item.district_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.district && (
                                <div className="text-danger fs-12">
                                  {errors.district}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  City / Town / Village
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the city/town/village"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                              {errors.city && (
                                <div className="text-danger fs-12">
                                  {errors.city}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Area / Location
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the area/location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                              {errors.location && (
                                <div className="text-danger fs-12">
                                  {errors.location}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  House / Flat Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the house/flat name"
                                value={houseName}
                                onChange={(e) => setHouseName(e.target.value)}
                              />
                              {errors.house && (
                                <div className="text-danger fs-12">
                                  {errors.house}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Pincode<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pincode"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                                onKeyPress={inputNumberOnly}
                                maxlength="6"
                              />
                              {errors.pincode && (
                                <div className="text-danger fs-12">
                                  {errors.pincode}
                                </div>
                              )}
                            </div>

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Pan No.
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the pan no."
                                  value={panNo}
                                  onChange={(e) => setPanNo(e.target.value)}
                                  maxlength="10"
                                />
                                {errors.panNo && (
                                  <div className="text-danger fs-12">
                                    {errors.panNo}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Aadhaar No
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Aadhaar No"
                                value={aadhar}
                                onChange={(e) => setAadhar(e.target.value)}
                                onKeyPress={inputNumberOnly}
                              />
                              {errors.aadhar && (
                                <div className="text-danger fs-12">
                                  {errors.aadhar}
                                </div>
                              )}
                            </div>

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Highest Qualification
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the highest qualification"
                                  value={qualification}
                                  onChange={(e) =>
                                    setQualification(e.target.value)
                                  }
                                />
                                {errors.qualification && (
                                  <div className="text-danger fs-12">
                                    {errors.qualification}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Experience
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the experience"
                                  value={experience}
                                  onChange={(e) =>
                                    setExperience(e.target.value)
                                  }
                                />
                                {errors.experience && (
                                  <div className="text-danger fs-12">
                                    {errors.experience}
                                  </div>
                                )}
                              </div>
                            )}
                            
                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Board Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>

                                  <Select
                                    isMulti
                                    options={options}
                                    // value={options.filter(item => boardMultipleId.split(",").includes(item.id.toString()))}
                                    value={boardMultipleId}
                                    onChange={(data) =>
                                      boardPreferenceHandler(data)
                                    }
                                  ></Select>
                                </div>
                                {errors.board && (
                                  <div className="text-danger fs-12">
                                    {errors.board}
                                  </div>
                                )}
                              </div>
                            )}
                              
                            {boardMultipleId == "" ? (
                              <div className="form-group mb-3 col-md-6 ">
                                <label className="mb-2">
                                  <strong>
                                    Subject Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the subject preference"
                                  value={subjectPreference}
                                  onChange={(e) =>
                                    setSubjectPreference(e.target.value)
                                  }
                                  disabled
                                />
                              </div>
                            ) : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Subject Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>

                                <Select
                                  isMulti
                                  options={subjectOptionsByBoardId}
                                  // value={subjectOptionsByBoardId.filter(item => subjectOptionsByBoardMultiId.includes(item.id))}
                                  value={subjectOptionsByBoardMultiId}
                                  onChange={(data) =>
                                    subjectPreferenceHandler(data)
                                  }
                                ></Select>

                                {errors.subjectPre && (
                                  <div className="text-danger fs-12">
                                    {errors.subjectPre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Class Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the class preference"
                                  value={classPreference}
                                  onChange={(e) =>
                                    setClassPreference(e.target.value)
                                  }
                                />
                                {errors.classPre && (
                                  <div className="text-danger fs-12">
                                    {errors.classPre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Time Slot Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                {/* <input
                                  className="form-control"
                                  placeholder="Enter the time slot preference"
                                  value={timeSlotPreference}
                                  onChange={(e) =>
                                    setTimeSlotPreference(e.target.value)
                                  }
                                /> */}
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={timeSlotPreference}

                                    onChange={(e) =>
                                      setTimeSlotPreference(e.target.value)
                                    }
                                  >
                                    <option value="">Select time slot</option>
                                    {props.createUsersDropdownData.time_slot?.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.name}
                                        >
                                          {item.value}
                                        </option>
                                      );
                                    })}

                                  </select>
                                </div>
                                {errors.timePre && (
                                  <div className="text-danger fs-12">
                                    {errors.timePre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Language of Communication
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the language"
                                  value={language}
                                  onChange={(e) => setLanguage(e.target.value)}
                                />
                                {errors.language && (
                                  <div className="text-danger fs-12">
                                    {errors.language}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Computer Knowledge
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              {/* <input
                                className="form-control"
                                placeholder="Enter the knowledge"
                                value={computerKnowledge}
                                onChange={(e) =>
                                  setComputerKnowledge(e.target.value)
                                }
                              /> */}
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={computerKnowledge}
                                  onChange={(e) =>
                                    setComputerKnowledge(e.target.value)
                                  }
                                >
                                  <option value="">Select computer knowledge</option>
                                  {props?.createUsersDropdownData?.computer_knowledge?.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item}
                                      >
                                        {item}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.knowleage && (
                                <div className="text-danger fs-12">
                                  {errors.knowleage}
                                </div>
                              )}
                            </div>

                            {userType == 7 || userType == 8 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Name of School
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the school"
                                  value={schoolName}
                                  onChange={(e) =>
                                    setSchoolName(e.target.value)
                                  }
                                />
                                {errors.school && (
                                  <div className="text-danger fs-12">
                                    {errors.school}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Name of Incharge
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the name of incharge"
                                  value={inchargeName}
                                  onChange={(e) =>
                                    setInchargeName(e.target.value)
                                  }
                                />
                                {errors.incharge && (
                                  <div className="text-danger fs-12">
                                    {errors.incharge}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Board<span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the board"
                                  value={board}
                                  onChange={(e) => setBoard(e.target.value)}
                                />
                                {errors.board && (
                                  <div className="text-danger fs-12">
                                    {errors.board}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Standard
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the standard"
                                  value={standard}
                                  onChange={(e) => setStandard(e.target.value)}
                                />
                                {errors.standard && (
                                  <div className="text-danger fs-12">
                                    {errors.standard}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Academic Year
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Academic year"
                                value={academicYear}
                                onChange={(e) =>
                                  setAcademicYear(e.target.value)
                                }
                              />
                              {errors.academic && (
                                <div className="text-danger fs-12">
                                  {errors.academic}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Date of Enrollment/Enlistment
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={enrollmentRef}
                                  selected={enrollment}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setEnrollment(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    enrollmentRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.enrollment && (
                                <div className="text-danger fs-12">
                                  {errors.enrollment}
                                </div>
                              )}
                            </div>

                            {userType == 1 || userType == 8 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Roll-Full Time/Contractual
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                {/* <input
                                  className="form-control"
                                  placeholder="Enter the roll"
                                  value={rollTime}
                                  onChange={(e) => setRollTime(e.target.value)}
                                /> */}
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={rollTime}
                                    onChange={(e) => setRollTime(e.target.value)}
                                  >
                                    <option value="">Select Roll</option>
                                    {props?.createUsersDropdownData?.roll?.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.rollTime && (
                                  <div className="text-danger fs-12">
                                    {errors.rollTime}
                                  </div>
                                )}
                              </div>
                            )}

                            {(parseInt(userType) == 1 || parseInt(userType) == 5 || parseInt(userType) == 6) && (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Role<span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={RollId}
                                    onChange={(e) => setRollId(e.target.value)}
                                  >
                                    <option value="">Select Role</option>
                                    {props.roleList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.role_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.RollId && (
                                  <div className="text-danger fs-12">
                                    {errors.RollId}
                                  </div>
                                )}
                              </div>
                            )}


                          </div>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    showLoading: state.user.showLoading,
    userTypeList: state.user.typeList,
    stateList: state.Location.StateList,
    districtList: state.Location.Districtlist,
    boardList: state.board.list,
    getSubjectsByBoards: state.subject.getSubjectsByBoards,
    roleList: state.user.rolesList,
    createUsersDropdownData: state.user.createUsersDropdownData,
  };
};

export default connect(mapStateToProps)(UpdateContentDeveloper);
