import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {
  updateSchoolDetails,
  loadingSchoolAction,
} from "../../../store/actions/SchoolAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

const UpdateSchool = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const enrollmentRef = useRef();
  const affiliationStartRef = useRef();
  const affiliationEndRef = useRef();
  const foundationYearRef = useRef();

  let errorsObj = {
    region: "",
    schoolName: "",
    schoolAdd: "",
    HOI: "",
    board: "",
    pincode: "",
    contactNumber: "",
    website: "",
    mail: "",
    affiliationEnd: "",
    affiliationNumber: "",
    schoolStatus: ""
  };

  const [errors, setErrors] = useState(errorsObj);

  const [region, setRegion] = useState(state.item?.region);
  const [pincode, setPincode] = useState(state.item?.pincode);
  const [board, setBoard] = useState("");
  const [selectedBoard, setselectedBoard] = useState([]);
  const [schoolName, setSchoolName] = useState(state.item?.school_name);
  const [schoolAdd, setSchoolAdd] = useState(state.item?.school_address);
  const [website, setWebsite] = useState(state.item?.website);
  const [contactNumber, setContactNumber] = useState(state.item?.contact_number);
  const [alternateNumber, setAlternateNumber] = useState(
    state.item?.alternate_contact_no
  );
  const [HOI, setHOI] = useState(state.item?.name_of_head);
  // const [enrollmentDate, setEnrollmentDate] = useState(new Date(state.item?.date_of_enrollment));
  const [enrollmentDate, setEnrollmentDate] = useState((state.item?.date_of_enrollment == "" || state.item?.date_of_enrollment == null) ? new Date() : new Date(state.item?.date_of_enrollment));
  const [affiliationNumber, setAffiliationNumber] = useState(state.item?.affiliation_no);
  // const [affilitationStart, setAffilitationStart] = useState(new Date(state.item?.affiliation_period_from));
  const [affilitationStart, setAffilitationStart] = useState((state.item?.affiliation_period_from == "" || state.item?.affiliation_period_from == null) ? new Date() : new Date(state.item?.affiliation_period_from));
  const [affiliationEnd, setAffiliationEnd] = useState((state.item?.affiliate_preriod_to == "" || state.item?.affiliate_preriod_to == null) ? new Date() : new Date(state.item?.affiliate_preriod_to));
  const [schoolStatus, setSchoolStatus] = useState(state.item?.school_status);
  const [foundationYear, setFoundationYear] = useState((state.item?.year_foundation == "" || state.item?.year_foundation == null) ? new Date() : new Date(state.item?.year_foundation));
  const [academicYear, setAcedemicYear] = useState("");
  const [schoolID, setSchoolId] = useState(state.item?.id);
  const [mail, setMail] = useState(state.item?.email);

  const [previousPageFilterData, setPreviousPageFilterData] = useState('');

  //custome style for multi select start
  const style = {
    control: base => ({
      ...base,
      background: "#fff",
      color: "#6e6e6e",
      lineHeight: "2.3",
      fontSize: "1rem",
      borderRadius: "0.625rem",
      border: "1px solid rgba(0,0,0,0.2)",
      boxShadow: "none",
      transition: "border-color 0.3s",
      '&:focus, &:focus-within': {
        border: "1px solid #bf1400",
      }
    })
  };
  //custome style for multi select end

  const options = props.boardList.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  // const initialBoard = state.item?.board
  // ? state.item?.board.split(",").map((board) => ({
  //     value: board.trim(),
  //     label: board.trim(),
  //   }))
  // : [];

  /* set board */
  const initialBoard = () => {
    let data = state.item?.board;
    let stringArray = data.split(",");
    let obj = {};
    let arry = [];
    stringArray.forEach((item) => {
      obj = { "label": item, "value": item };
      arry.push(obj);
    });
    setBoard(arry);
  };
  const multipleSelectionHandler = (data) => {
    setBoard(data);
  };

  useEffect(() => {
    setPreviousPageFilterData(props.location.state?.filterData)

    initialBoard();
    // console.log(initialBoard,"selected board")
    //setselectedBoard(initialBoard)
    console.log("data recived from previous page....", state);
    dispatch(loadingBoardAction(true));
    dispatch(getBoardData(props.history));
  }, []);

  /* form submit */
  function onSubmit(e) {

    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (schoolName === "") {
      errorObj.schoolName = "School name is Required";
      error = true;
    }
    if (board === "" || board.length == 0) {
      errorObj.board = "Board name is Required";
      error = true;
    }
    if (HOI === "") {
      errorObj.person = "Head of the name is Required";
      error = true;
    }

    if (contactNumber === "") {
      errorObj.contactNumber = "Phone No. is Required";
      error = true;
    }
    if (schoolAdd === "") {
      errorObj.schoolAdd = "School address is Required";
      error = true;
    }
    if (pincode === "") {
      errorObj.pincode = "Pincode is Required";
      error = true;
    } else if (!/^\d{6}$/.test(pincode)) {
      errorObj.pincode = "Pincode must be a 6-digit number";
      error = true;
    }

    // if (website === "") {
    //   errorObj.website = "Website is Required";
    //   error = true;
    // }
    if (affiliationEnd === "") {
      errorObj.affiliationEnd = "date is Required";
      error = true;
    }

    if (affiliationNumber === "") {
      errorObj.affiliationNumber = "affiliation number is Required";
      error = true;
    }

    if (HOI === "") {
      errorObj.HOI = "Name of the head of the institute is required";
      error = true;
    }
    if (schoolStatus === "") {
      errorObj.schoolStatus = "School statuse is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    const arry = board.map((item) => item.value);
    const boardData = arry.join(",");

    dispatch(globalLoadingAction(true));

    dispatch(
      updateSchoolDetails(
        state.item.id,
        region,
        parseInt(pincode),
        boardData,
        schoolName,
        schoolAdd,
        website,
        contactNumber,
        alternateNumber,
        HOI,
        moment(enrollmentDate).format("MM/DD/YYYY"),
        affiliationNumber,
        moment(affilitationStart).format("MM/DD/YYYY"),
        moment(affiliationEnd).format("MM/DD/YYYY"),
        schoolStatus,
        moment(foundationYear).format("MM/DD/YYYY"),
        academicYear,
        history
      )
    );
  }

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/school-list",
      state: { previousPageFilterData },
    });
    // history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            {/* <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  Region<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Region"
                                value={region}
                                onChange={(e) => setRegion(e.target.value)}
                              />
                              {errors.region && (
                                <div className="text-danger fs-12">
                                  {errors.region}
                                </div>
                              )}
                            </div> */}

                            <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  Pincode<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pincode"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                              />
                              {errors.pincode && (
                                <div className="text-danger fs-12">
                                  {errors.pincode}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  Board
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow add-Question-arrow-position">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <Select

                                  isMulti
                                  options={options}
                                  value={board}
                                  styles={style}
                                  onChange={(data) =>
                                    multipleSelectionHandler(data)
                                  }
                                >
                                  <option value="">Select Board</option>

                                  {/* {props.boardList.map((item, index) => (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))} */}
                                </Select>
                                {errors.board && (
                                  <div className="text-danger fs-12">
                                    {errors.board}
                                  </div>
                                )}
                              </div>

                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  School Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the school name"
                                value={schoolName}
                                onChange={(e) => setSchoolName(e.target.value)}
                              />
                              {errors.schoolName && (
                                <div className="text-danger fs-12">
                                  {errors.schoolName}
                                </div>
                              )}
                            </div>
                            <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  School Address
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the school address"
                                value={schoolAdd}
                                onChange={(e) => setSchoolAdd(e.target.value)}
                              />
                              {errors.schoolAdd && (
                                <div className="text-danger fs-12">
                                  {errors.schoolAdd}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-6 mb-3">
                              <label className="mb-2">
                                <strong>
                                  Website
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the website"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                              />
                              {errors.website && (
                                <div className="text-danger fs-12">
                                  {errors.website}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Contact Number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact number"
                                value={contactNumber}
                                onChange={(e) =>
                                  setContactNumber(e.target.value)
                                }
                                onKeyPress={inputNumberOnly}
                                maxlength="10"
                              />
                              {errors.contactNumber && (
                                <div className="text-danger fs-12">
                                  {errors.contactNumber}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Alternate Contact Number</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact number"
                                value={alternateNumber}
                                onChange={(e) =>
                                  setAlternateNumber(e.target.value)
                                }
                                onKeyPress={inputNumberOnly}
                                maxlength="10"
                              />
                              {/* {errors.fName && (
                                <div className="text-danger fs-12">
                                  {errors.fName}
                                </div>
                              )} */}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Head of the institute
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the name of head"
                                value={HOI}
                                onChange={(e) => setHOI(e.target.value)}
                              />
                              {errors.HOI && (
                                <div className="text-danger fs-12">
                                  {errors.HOI}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Date of Enrollment
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="date-control-view">
                                <DatePicker
                                  ref={enrollmentRef}
                                  selected={enrollmentDate}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setEnrollmentDate(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    enrollmentRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affiliation number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter affiliation number"
                                value={affiliationNumber}
                                onChange={(e) =>
                                  setAffiliationNumber(e.target.value)
                                }
                              />

                              {errors.affiliationNumber && (
                                <div className="text-danger fs-12">
                                  {errors.affiliationNumber}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affliation period start
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="date-control-view">
                                <DatePicker
                                  ref={affiliationStartRef}
                                  selected={affilitationStart}
                                  placeholderText="Enter the date"
                                  onChange={(date) =>
                                    setAffilitationStart(date)
                                  }
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    affiliationStartRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>

                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Affliation period end
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={affiliationEndRef}
                                  selected={affiliationEnd}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setAffiliationEnd(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                // maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    affiliationEndRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>

                              {errors.affiliationEnd && (
                                <div className="text-danger fs-12">
                                  {errors.affiliationEnd}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Status<span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select

                                  className="form-control form-control"
                                  value={schoolStatus}
                                  onChange={(e) =>
                                    setSchoolStatus(e.target.value)
                                  }
                                >
                                  <option value="">Select school status</option>
                                  <option value="Primary">Primary</option>
                                  <option value="Secondary">Secondary</option>
                                  <option value="Higher Secondary">Higher Secondary
                                  </option>
                                </select>
                              </div>
                              {errors.schoolStatus && (
                                <div className="text-danger fs-12">
                                  {errors.schoolStatus}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Foundation year{" "}
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={foundationYearRef}
                                  selected={foundationYear}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setFoundationYear(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    foundationYearRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>

                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  E-mail
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the e-mail"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                                disabled
                              />
                              {errors.mail && (
                                <div className="text-danger fs-12">
                                  {errors.mail}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row"></div>


                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                            >
                              Update
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.school.showLoading,
    boardList: state.board.list,
  };
};

export default connect(mapStateToProps)(UpdateSchool);
