import {
    UPDATE_PROFILE,
    SET_PROFILE_DATA,
    PROFILE_LOADING
} from '../constants';

const initialState = {
    data: [],
    profilePic: "",
    userName: "",
    showLoading: false,
};

export function ProfileReducer(state = initialState, action) {

    if (action.type === UPDATE_PROFILE) {
        let getData = localStorage.getItem('userDetails');
        let data = JSON.parse(getData);
        data.name = action.payload.name;
        data.email = action.payload.email;
        data.phone = action.payload.phone;
        data.profile_pic = action.payload.profile_pic;
        localStorage.setItem('userDetails', JSON.stringify(data));
        return {
            ...state,
            profilePic: action.payload.profile_pic,
            userName: action.payload.name,
            showLoading: false,
        };
    }

    if (action.type === SET_PROFILE_DATA) {
        return {
            ...state,
            profilePic: action.payload.profile_pic,
            userName: action.payload.name,
            showLoading: false,
        };
    }

    if (action.type === PROFILE_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


