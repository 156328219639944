import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisUserActivity(
  board,className,services,complete_percentage
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    board,class:className,services,complete_percentage
  };
  
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_USER_ACTIVITY_DATABASE_LIST,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function PaymentTransactionDetails(page,trans_id,order_id,student_id, trans_status, mobile) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    trans_id,
    order_id,
    student_id, 
    trans_status,
    mobile
  };
  
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_PAYMENT_TRANSACTION_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
