import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    Pagination,
    Modal,
    Button
} from "react-bootstrap";

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { assignValidatorData, getDetailsDeveloperContentForQuestionData } from '../../../store/actions/AssignContentAction';
import { getContentValidatorData, loadingContentValidatorAction } from '../../../store/actions/ContentValidatorAction';

const AssignQuestionDetailsList = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const questionContentDetailsList = useSelector(state => state.assign.questionContentDetailsList);
    const [validator, setValidator] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getDetailsDeveloperContentForQuestionData(state.id, props.history));
    }, []);

    /* get Validator List  */
    const getValidatorList = () => {
        if (props.contentvalidatorList.length == 0) {
            dispatch(loadingContentValidatorAction(props.history));
            dispatch(getContentValidatorData(0, '', '','', props.history));
        }
    }

    /* get Assign Validator List  */
    const onAssignValidatorHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(assignValidatorData(state.id, validator, props.history))
        setValidator('');
    }


    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>

                            <div className="assign-question-top-view">
                                <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                        {props.validatorLoading ?
                                            <Spinner animation="border" size="sm" />
                                            : <i className="bi bi-caret-down-fill"></i>}
                                    </span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control filter-form-control"
                                        value={validator}
                                        onChange={(e) => setValidator(e.target.value)}
                                        onClick={getValidatorList}
                                    >
                                        <option value="">Select Validator</option>
                                        {
                                            props.contentvalidatorList.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <button type="button" className="btn btn-primary category-btn" onClick={onAssignValidatorHandler} disabled={props.showLoading || validator == '' ? true : false}>
                                    Submit
                                    {props.showLoading ?
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        : null}
                                </button>
                            </div>


                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Board & Type</strong>
                                        </th>
                                        <th>
                                            <strong>Subject</strong>
                                        </th>
                                        <th>
                                            <strong>Chapter</strong>
                                        </th>
                                        <th>
                                            <strong>Class</strong>
                                        </th>
                                        <th>
                                            <strong>Question type</strong>
                                        </th>
                                        <th>
                                            <strong>Question no</strong>
                                        </th>
                                        <th>
                                            <strong>Create date</strong>
                                        </th>
                                        <th>
                                            <strong>Submission date</strong>
                                        </th>
                                        <th>
                                            <strong>Details</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        questionContentDetailsList.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.exam_type}</td>
                                                <td>{item.subject_code}</td>
                                                <td>{item.chapter_no}</td>
                                                <td>{item.standard}</td>
                                                <td>{item.question_type}</td>
                                                <td>{item.question_no}</td>
                                                <td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
                                                <td><Moment format="D MMM YYYY" withTitle>{item.submission_date}</Moment></td>
                                                <td>

                                                    <div className="d-flex">
                                                        <Link
                                                            to={{
                                                                pathname: '/assign-question-details',
                                                                state: item
                                                            }}
                                                            className="btn btn-info btn-xs"
                                                        >
                                                            <i className="fas fa-eye"></i> View
                                                        </Link>

                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.assign.showLoading,
        validatorLoading: state.contentvalidator.showLoading,
        contentvalidatorList: state.contentvalidator.list,
    };
};

export default connect(mapStateToProps)(AssignQuestionDetailsList);