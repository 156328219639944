import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { boardByClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, clearSubjectList, subjectLoading } from '../../../store/actions/SujectActions';
import { updateSchoSubscriptionData, subscriptionLoadingAction } from '../../../store/actions/SubscriptionAction';


const UpdateScholasticSubscription = (props) => {


    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    let errorsObj = { board: '', className: '', subject: '', ePrice: '', sticker: '', sets: '', details: '', courseCode: '' };

    const [errors, setErrors] = useState(errorsObj);

    const arrayObj = [{
        "set": '',
        "module": 0,
        "mock": 0,
        "library": 0,
        "case_studies": 0,
        "price": ''
    }];

    const [subject, setSubject] = useState(state.item?.subject_id);
    const [board, setBoard] = useState(state.item?.board_id);
    const [className, setClassName] = useState(state.item?.class);
    const [sticker, setSticker] = useState(state.item?.sticker_text);
    const [isSticker, setIsSticker] = useState(state.item?.sticker_text == "" || state.item?.sticker_text == null ? false : true);
    const [libraryArray, setLibraryArray] = useState(state.item?.package_details == null || state.item?.package_details.length == 0 ? arrayObj : state.item?.package_details);
    const [courseCode, setCourseCode] = useState(state.item?.course_code)

    const [previousPageFilterData, setPreviousPageFilterData] = useState('');

    useEffect(() => {
        console.log('hola', state.item?.course_code)
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(loadingClassAction(true));
        dispatch(boardByClassData(props.history));
        dispatch(subjectLoading(true));
        dispatch(getSubjects(0, state.item?.board_id, 0, 0, props.history));

    }, []);

    useEffect(() => {
        setPreviousPageFilterData(props.location.state?.filterData)
        onSubjectHandler()
    }, []);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        let error = false;

        const errorObj = { ...errorsObj };

        if (subject === '') {
            errorObj.subject = 'Subject is Required';
            error = true;
        }

        if (board === '') {
            errorObj.board = 'Exam type is Required';
            error = true;
        }
        if (className === '') {
            errorObj.className = 'Class name is Required';
            error = true;
        }
        if (sticker === '' && isSticker == true) {
            errorObj.sticker = 'Sticker Exist is Required';
            error = true;
        }
        libraryArray.forEach((item) => {
            if (/* item.set === '' || */ item.price === '') {
                errorObj.details = 'Subscription package details is Required';
                error = true;
            }
        });

        if (courseCode === '') {
            errorObj.courseCode = 'Course Code is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(subscriptionLoadingAction(true));
        dispatch(updateSchoSubscriptionData(state.item?.id, subject, board, className, libraryArray, isSticker ? sticker : '', courseCode, props.history));
    }


    /* change price */
    const onChangePriceHandler = (val, index) => {

        const re = /^[0-9\b]+$/;
        let arry = [...libraryArray];
        if (val === '' || re.test(val)) {
            arry[index].price = val;
            setLibraryArray(arry);
        }

    }

    /* change set */
    const onChangeSetHandler = (val, index) => {
        let arry = [...libraryArray];
        arry[index].set = val;
        setLibraryArray(arry);
    }

    /* change module */
    const onModuleHandler = (val, index) => {
        let arry = [...libraryArray];
        if (val == true) {
            arry[index].module = 1;
        } else {
            arry[index].module = 0;
        }
        setLibraryArray(arry);
    }

    /* change mock */
    const onMockHandler = (val, index) => {
        let arry = [...libraryArray];
        if (val == true) {
            arry[index].mock = 1;
        } else {
            arry[index].mock = 0;
        }
        setLibraryArray(arry);
    }

    /* change elibrary */
    const onElibraryHandler = (val, index) => {
        let arry = [...libraryArray];
        if (val == true) {
            arry[index].library = 1;
        } else {
            arry[index].library = 0;
        }
        setLibraryArray(arry);
    }

    /* change case studies */
    const onCaseStudiesHandler = (val, index) => {
        let arry = [...libraryArray];
        if (val == true) {
            arry[index].case_studies = 1;
        } else {
            arry[index].case_studies = 0;
        }
        setLibraryArray(arry);
    }

    /* item add */
    const addItemHandler = () => {

        let arry = [...libraryArray];
        let obj = {
            "set": '',
            "module": 0,
            "mock": 0,
            "library": 0,
            "case_studies": 0,
            "price": ''
        };
        arry.push(obj);
        setLibraryArray(arry);
    }

    /* change status */
    const onStatusItem = () => {
        let arry = [...libraryArray];
        let status;
        for (let x = 0; arry.length > x; x++) {

            if (/* arry[x].set === '' || */ arry[x].price === '') {
                status = true;
            } else {
                status = false;
            }
        }
        return status;
    }

    /* remove item */
    const onRemoveItemHandler = (index) => {
        let arry = [...libraryArray];
        arry.splice(index, 1);
        setLibraryArray(arry);
    }

    /* change board */
    const onBoardHandler = (val) => {
        setBoard(val);
        dispatch(clearSubjectList([]));
    }

    /* get subject list */
    const onSubjectHandler = () => {
        dispatch(subjectLoading(true));
        dispatch(getSubjects(0, board, 0, 0, props.history));
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
            pathname: "/scholastic-subscription-list",
            state: { previousPageFilterData },
        });

        // history.goBack();
    }

    return (
        <>
            {console.log('state>>>>>>>>>>>>>>------------', state)}
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">

                                            <form onSubmit={onSubmit}>

                                                <div className="basic-form">
                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Board name<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow add-Question-arrow-position">
                                                                <i className="bi bi-caret-down-fill"></i>
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={board}
                                                                onChange={(e) => onBoardHandler(e.target.value)}
                                                            >
                                                                <option value="">Select Board</option>
                                                                {
                                                                    props.boardList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Class<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow add-Question-arrow-position">
                                                                {props.classLoading ?
                                                                    <Spinner animation="border" size="sm" />
                                                                    : <i className="bi bi-caret-down-fill"></i>}
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={className}
                                                                onChange={(e) => setClassName(e.target.value)}
                                                            >
                                                                <option value="">Select class</option>
                                                                {
                                                                    props.classList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.short_code}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.className && <div className="text-danger fs-12">{errors.className}</div>}
                                                    </div>



                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Subject name<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow">
                                                                {props.subjectLoading ?
                                                                    <Spinner animation="border" size="sm" />
                                                                    : <i className="bi bi-caret-down-fill"></i>}
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={subject}
                                                                onClick={onSubjectHandler}
                                                                onChange={(e) => setSubject(e.target.value)}
                                                            >
                                                                <option value="">Subject name</option>
                                                                {
                                                                    props.subjectList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Subscription package details</strong>
                                                        </label>
                                                        {
                                                            libraryArray.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="subscribtion-dublicate-library-container mb-3">
                                                                        <div className="form-group mb-3">
                                                                            <label className="mb-2">
                                                                                <strong>Set{/* <span className="text-danger">*</span> */}</strong>
                                                                            </label>
                                                                            <div className="subscribtion-dublicate-library">
                                                                                <div className="basic-dropdown">
                                                                                    <span className="drop_down_arrow add-Question-arrow-position">
                                                                                        <i className="bi bi-caret-down-fill"></i>
                                                                                    </span>
                                                                                    <select
                                                                                        defaultValue={"option"}
                                                                                        className="form-control"
                                                                                        value={item.set}
                                                                                        onChange={(e) => onChangeSetHandler(e.target.value, index)}
                                                                                    >
                                                                                        <option value="">Select set</option>
                                                                                        <option value="1">1</option>
                                                                                        <option value="2">2</option>
                                                                                        <option value="3">3</option>
                                                                                        <option value="4">4</option>
                                                                                        <option value="5">5</option>
                                                                                        <option value="6">6</option>
                                                                                        <option value="7">7</option>
                                                                                        <option value="8">8</option>
                                                                                        <option value="9">9</option>
                                                                                        <option value="10">10</option>
                                                                                    </select>

                                                                                </div>

                                                                                {index === (libraryArray.length - 1) ?
                                                                                    <button type="button" className="plus-btn" onClick={addItemHandler} disabled={onStatusItem()}><i class="fa-solid fa-plus"></i></button>
                                                                                    :
                                                                                    <button type="button" onClick={() => onRemoveItemHandler(index)} className="plus-btn"><i class="fa-solid fa-minus"></i></button>
                                                                                }

                                                                            </div>
                                                                        </div>


                                                                        <div className="subscribtion-switch-button-view">

                                                                            <div className="subscribtion-switch-item">
                                                                                <label className='switch-label'>
                                                                                    <strong>Module :</strong>
                                                                                </label>
                                                                                <label class="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.module == 0 ? false : true}
                                                                                        onChange={(e) => onModuleHandler(e.target.checked, index)}
                                                                                    />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                            </div>

                                                                            <div className="subscribtion-switch-item">
                                                                                <label className='switch-label'>
                                                                                    <strong>Mock :</strong>
                                                                                </label>
                                                                                <label class="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.mock == 0 ? false : true}
                                                                                        onChange={(e) => onMockHandler(e.target.checked, index)}
                                                                                    />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                            </div>

                                                                            <div className="subscribtion-switch-item">
                                                                                <label className='switch-label'>
                                                                                    <strong>E-library :</strong>
                                                                                </label>
                                                                                <label class="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.library == 0 ? false : true}
                                                                                        onChange={(e) => onElibraryHandler(e.target.checked, index)}
                                                                                    />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                            </div>

                                                                            <div className="subscribtion-switch-item">
                                                                                <label className='switch-label'>
                                                                                    <strong>Case Studies :</strong>
                                                                                </label>
                                                                                <label class="switch">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.case_studies == 0 ? false : true}
                                                                                        onChange={(e) => onCaseStudiesHandler(e.target.checked, index)}
                                                                                    />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                            </div>

                                                                        </div>

                                                                        <div className="form-group mb-3 mt-2">
                                                                            <label className="mb-2">
                                                                                <strong>Price<span className="text-danger">*</span></strong>
                                                                            </label>
                                                                            <input className="form-control" type="text" placeholder="Price" value={item.price} onChange={(e) => onChangePriceHandler(e.target.value, index)} />
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        {errors.details && <div className="text-danger fs-12">{errors.details}</div>}
                                                    </div>

                                                    <div className="form-check mt-3 mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="sticker"
                                                            name="sticker"
                                                            checked={isSticker}
                                                            onChange={(e) => setIsSticker(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="sticker"
                                                        >Sticker Exist</label>
                                                    </div>

                                                    {isSticker ?
                                                        <div className="form-group mb-3 mt-2">
                                                            <label className="mb-2">
                                                                <strong>Sticker<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <input className="form-control" type="text" placeholder="Sticker" value={sticker} onChange={(e) => setSticker(e.target.value)} />
                                                            {errors.sticker && <div className="text-danger fs-12">{errors.sticker}</div>}
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3 mt-2">
                                                        <label className="mb-2">
                                                            <strong>Course Code<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Course Code" value={courseCode} onChange={(e) => setCourseCode(e.target.value)} />
                                                        {errors.courseCode && <div className="text-danger fs-12">{errors.courseCode}</div>}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Update
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>



                                                </div>

                                            </form>

                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.subscription.showLoading,
        classLoading: state.class.showLoading,
        classList: state.class.boardByClasslist,
        boardList: state.board.list,
        subjectLoading: state.subject.showLoading,
        subjectList: state.subject.subjectList,
    };
};
export default connect(mapStateToProps)(UpdateScholasticSubscription);