import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import Select from "react-select";

import { validateText, validateShortCode, validateMaks } from '../../../utility/Utility';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { addQuestionPattern, loadingPatternAction } from '../../../store/actions/QuestionPatternAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

const CreateQuestionPattern = (props) => {

	const dispatch = useDispatch();

	let errorsObj = { name: '', code: '', marks: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	const [marks, setMarks] = useState('');




	useEffect(() => {

		// dispatch(globalLoadingAction(true));
		// dispatch(getBoardData(props.history));
		// dispatch(getExamCategory(props.history));

	}, []);

	
	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (code === '') {
			errorObj.code = 'Question type code is Required';
			error = true;

		} else if (validateShortCode(code) === false) {
			errorObj.code = 'Invalid Question type code';
			error = true;
		}
		if (name === '') {
			errorObj.name = 'Question name is Required';
			error = true;

		} else if (validateText(name) === false) {
			errorObj.name = 'Invalid Question name';
			error = true;
		}
		if (marks === '') {
			errorObj.marks = 'Marks is Required';
			error = true;

		} else if (validateMaks(marks) === false) {
			errorObj.marks = 'Invalid Marks';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}

		

		dispatch(globalLoadingAction(true));
		dispatch(addQuestionPattern(name, code,"","", marks, props.history));
		setName('');
		setCode('');
		setMarks('');
	}

	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>


													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Question type name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the question type name" value={name} onChange={(e) => setName(e.target.value)} />
														{errors.name && <div className="text-danger fs-12">{errors.name}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Question type code<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the question type code" value={code} onChange={(e) => setCode(e.target.value)} />
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Marks<span className="text-danger">*</span></strong>
														</label>
														<input 
														// type='number' 
														className="form-control" 
														placeholder="Enter the marks" 
														value={marks} 
														onChange={(e) => setMarks(e.target.value)}
														onKeyPress={inputNumberOnly}
														/>
														{errors.marks && <div className="text-danger fs-12">{errors.marks}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.pattern.showLoading,
		boardList: state.board.list,
		categoryList: state.category.list,
	};
};
export default connect(mapStateToProps)(CreateQuestionPattern);