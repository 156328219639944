import swal from "sweetalert";
import {
  addAdvertisement,
  getAdvertisement,
  updateAdvertisement,
  removeAdvertisement,
  updateStatusAdvertisement,
  getAdvertisementScreen,
} from "../../services/AdvertiesmentServices";

import { GET_ADVERTIESMEST_LIST,GET_ADVERTIESMEST_SCREEN_LIST } from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "./GlobalAction";

export function getAdvertisementScreenDetails(history) {
  return (dispatch) => {
    getAdvertisementScreen()
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getAdvertisementScreenAction(response.data.data));
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addAdvertisementDetails(
  title,
  screen,
  file,
  description,
  estimationTime,
  cleardata,
  history
) {
  return (dispatch) => {
    addAdvertisement(title, screen, file, description, estimationTime)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getAdvertisementData(history));
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
          cleardata();
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateAdvertisementDetails(
  id,
  title,
  screen,
  file,
  description,
  estimationTime,
  history
) {
  return (dispatch) => {
    updateAdvertisement(id, title, screen, file, description, estimationTime)
      .then((response) => {
        if (response.data.status === 200) {
          //dispatch(getAdvertisementData(history));
          utility.showSuccess(response.data.msg);
          history.goBack();
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAdvertisementData(history) {
  return (dispatch) => {
    getAdvertisement()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getAdvertisementAction(response.data.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateStatusAdvertisementData(id, history) {
  return (dispatch) => {
    updateStatusAdvertisement(id)
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(getAdvertisementData(history));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function removeAdvertisementData(id, history) {
  return (dispatch) => {
    removeAdvertisement(id)
      .then((response) => {
        dispatch(getAdvertisementData(history));
        swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function getAdvertisementAction(data) {
  return {
    type: GET_ADVERTIESMEST_LIST,
    payload: data,
  };
}

export function getAdvertisementScreenAction(data) {
  return {
    type: GET_ADVERTIESMEST_SCREEN_LIST,
    payload: data,
  };
}

