import swal from "sweetalert";
import {
    addChapters,
    getChapters,
    removeChapters,
    updateChapters,
    getChaptersByBranch,
    updateStatusChapter,
    getChaptersDeffarentsParams,
    getChaptersFilter,
    getChapterOrdering,
    getChaptersWithoutPagination
} from '../../services/ChapterServices';

import {
    CHAPTER_LOADING,
    ADD_CHAPTER,
    UPDATE_CHAPTER,
    GET_CHAPTER,
    REMOVE_CHAPTER,
    UPDATE_STATUS_CHAPTER,
    GET_BRANCH_TYPE_CHAPTER,
    GET_CHAPTER_FILTTER,
    GET_CHAPTER_FOR_DEFFARENTS_PARAMS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addChaptersData(category_id, type_id, sub_type, class_no, capter_name, branch_id, subheading, chapter_no, history) {
    
    return (dispatch) => {
        addChapters(category_id, type_id, sub_type, class_no, capter_name, branch_id, subheading, chapter_no,)
            .then((response) => {
                console.log('hello wolrd', response.data);
                if (response.data.status == 200) {
                    dispatch(addChapterAction(true));
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addChapterAction(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateChaptersData(id, category_id, type_id, sub_type, class_no, capter_name, branch_id, subheading, chapter_no, filter, history) {
    return (dispatch) => {
        updateChapters(id, category_id, type_id, sub_type, class_no, capter_name, branch_id, subheading, chapter_no)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateChapterAction(false));
                    utility.showSuccess(response.data.msg);
                    //history.goBack();
                    history.push({
                        pathname: '/chapter-list',
                        state: filter
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingChaptersAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getChaptersDeffarentsParamsData(categoty_id, board_id, type_id, subtype_id, subject_id, class_id, history) {
    return (dispatch) => {
        getChaptersDeffarentsParams(categoty_id, board_id, type_id, subtype_id, subject_id, class_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getChaptersDeffarentsParamsAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getChaptersData(page, board_id, exam_type_id, exam_subtype_id, standard, category_id, branch_id, history) {
    return (dispatch) => {
        getChapters(page, board_id, exam_type_id, exam_subtype_id, standard, category_id, branch_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getChapterAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getChaptersFilterList(history) {
    return (dispatch) => {
        getChaptersFilter()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getChaptersFilterAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getChaptersNoPagination(branch_id,board_id,exam_type_id,exam_subtype_id,standard,category_id) {
    return (dispatch) => {
        getChaptersWithoutPagination(branch_id,board_id,exam_type_id,exam_subtype_id,standard,category_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getChaptersFilterAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function updateStatusChapterData(item, history) {
    return (dispatch) => {
        updateStatusChapter(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateChapterChapterAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getbranchTypeData(id, history) {
    return (dispatch) => {
        getChaptersByBranch(id)
            .then((response) => {
                // console.log(id,'helo response',response.data);
                dispatch(getChaptersTypeAction(response.data.data));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function removeChaptersData(id,active, history) {
    return (dispatch) => {
        removeChapters(id)
            .then((response) => {
                dispatch(getChaptersData(active,"","", "", "", "", "",history));
                dispatch(removeChapterAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}




export function getChapterOrderingList(order, id,active,board,type,subtype,standard,category,branch) {
    return (dispatch) => {

        // console.log("debanjan",order,id,active,board,type,subtype,standard,category,branch)
        getChapterOrdering(order,id,active,board,type,subtype,standard,category,branch)
            .then((response) => {
                if (response.data.status == 200) {
                   
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                // dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function addChapterAction(data) {
    return {
        type: ADD_CHAPTER,
        payload: data,
    };
}
export function updateChapterAction(data) {
    return {
        type: UPDATE_CHAPTER,
        payload: data,
    };
}
export function getChapterAction(data) {
    return {
        type: GET_CHAPTER,
        payload: data,
    };
}
export function getChaptersFilterAction(data) {
    return {
        type: GET_CHAPTER_FILTTER,
        payload: data,
    };
}
export function getChaptersDeffarentsParamsAction(data) {
    return {
        type: GET_CHAPTER_FOR_DEFFARENTS_PARAMS,
        payload: data,
    };
}
export function getChaptersTypeAction(data) {
    return {
        type: GET_BRANCH_TYPE_CHAPTER,
        payload: data,
    };
}
export function updateChapterChapterAction(item) {
    return {
        type: UPDATE_STATUS_CHAPTER,
        payload: item,
    };
}
export function removeChapterAction(id) {
    return {
        type: REMOVE_CHAPTER,
        payload: id,
    };
}
export function loadingChaptersAction(status) {
    return {
        type: CHAPTER_LOADING,
        payload: status,
    };
}
