import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  uploadContentData, loadingElibraryAction, uploadConceptMap
} from "../../../store/actions/ElibraryAction";
import { getExamCategory, loadingToggleAction, } from "../../../store/actions/ExamCategoryAction";
import { getExamType, loadingAction, } from "../../../store/actions/ExamTypeAction";
import { getExamSubType, loadingSubTypeAction } from "../../../store/actions/ExamSubTypeAction";
import { getNonGroupSubjectsList, subjectLoading, getNonGroupSubjectsAction } from "../../../store/actions/SujectActions";
import { getClassData, loadingClassAction } from "../../../store/actions/ClassAction";
import { getChaptersDeffarentsParamsData, loadingChaptersAction, getChaptersDeffarentsParamsAction } from "../../../store/actions/ChapterAction";
import { getBoardData, loadingBoardAction } from "../../../store/actions/BoardAction";

const UploadContent = (props) => {
  const dispatch = useDispatch();
  const fileRef = useRef();

  let errorsObj = {
    category: "",
    board: "",
    examType: "",
    subtype: "",
    subject: "",
    classroom: "",
    chapter: "",
    file: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [examType, setExamType] = useState("");
  const [examTypeFullString, setExamTypeFullstring] = useState("");
  const [examTypeId, setExamTypeId] = useState("");
  const [subtype, setSubtype] = useState("");
  const [classroom, setClassroom] = useState("");
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [file, setFile] = useState("");
  const [demo, setDemo] = useState(2);

  useEffect(() => {
    // dispatch(getClassData(props.history));
    // dispatch(getBranchDetails(props.history));
    // dispatch(getExamCategory(props.history));
    // dispatch(getBoardData(props.history));
  }, []);

  /* form validation */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (demo == 2 && category == 1 && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (demo == 3 && category == 1 && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (demo == 2 && category == 2 && examType === "") {
      errorObj.examType = "Examtype is Required";
      error = true;
    }
    if (demo == 2 && examType == 1 && subtype === "") {
      errorObj.subtype = "Sub type is Required";
      error = true;
    }
    if (demo == 2 && subject === "") {
      errorObj.subject = "Subject is Required";
      error = true;
    }
    if (demo == 2 && chapter === "") {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if ((demo == 2 && category == 1 && classroom === "") || (demo == 2 && category == 2 && classroom === "") || (demo == 2 && examType == 2 && classroom === "")) {/*   */
      errorObj.classroom = "Class is required";
      error = true;
    }
    // if (demo == 3 && examType != 1 && classroom === "") {
    if (category != '') {
      if ((demo == 3 && category == 1 && classroom === "") || (demo == 3 && category == 2 && classroom === "") || (demo == 3 && examType != 1 && classroom === "")) {
        errorObj.classroom = "Class is required";
        error = true;
      }
    }

    if (file === "") {
      errorObj.file = "File is Required";
      error = true;
    } else if (file.type != "application/pdf") {
      errorObj.file = "Only PDFs are valid";
      error = true;
    }
    
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingElibraryAction(true));
    dispatch(
      uploadContentData(
        parseInt(category),
        parseInt(demo) == 2 || (3 && parseInt(category) == 1) ? board : 0,
        parseInt(demo) == 2 && parseInt(category) == 2 ? examType : 0,
        parseInt(demo) == 2 && parseInt(examType) == 1 ? subtype : 0,
        parseInt(demo) == 2 ? subject : 0,
        (parseInt(demo) == 2 || 3) &&
          (parseInt(category) == 1 || parseInt(category) == 2 || parseInt(examType) == 2)
          ? classroom
          : 0,
        parseInt(demo) == 2 ? chapter : 0,
        file,
        parseInt(demo),
        props.history
      )
    );

    setClassroom("");
    setSubject("");
    setChapter("");
    setFile("");
    fileRef.current.value = "";
    setCategory("");
    setBoard("");
    setExamType("");
    setSubtype("");
    setExamTypeFullstring("")
  }

  /* file store in state */
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  /* radio change */
  const onChangeRadioHandler = (event) => {
    setDemo(event.target.value);
  };

  /* on category change */
  const getCategoryHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingToggleAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  /* on category change */
  const onCategoryHandler = (val) => {
    setCategory(val);
    setExamType("");
    setBoard("");
    setSubtype("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get board list */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };

  /* on change board */
  const onBoardHandler = (val) => {
    setBoard(val);
    dispatch(getNonGroupSubjectsAction([]));
    setSubject('')
  };

  /* get exam type list */
  const getExamtypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingAction(true));
      dispatch(getExamType(props.history));
    }
  };

  /* on change exam type */
  const onExamTypeHandler = (val) => {
    // console.log("+++++++", val.split("^")[0]);
    setExamTypeFullstring(val);
    setExamType(val.split("^")[0]);
    let examTypeId = val.split("^")[0];
    setExamTypeId(examTypeId);
    setSubtype("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get sub type list */
  const getSubtypeHandler = () => {
    if (props.subTypeList.length == 0) {
      dispatch(loadingSubTypeAction(true));
      dispatch(getExamSubType(props.history));
    }
  };

  /* on change subtype */
  const onSubtypeTypeHandler = (val) => {
    setSubtype(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get subject list */
  const getSubjectHandler = () => {
    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && examType == 1 && subtype) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(examType),
          parseInt(subtype),
          0,
          props.history
        )
      );
    } else if (category == 2 && examType != 1) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(examType),
          0,
          0,
          props.history
        )
      );
    }
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* get class list */
  const getClassHandler = () => {
    if (props.classList.length == 0) {
      dispatch(loadingClassAction(true));
      dispatch(getClassData(props.history));
    }
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  /* get chapter list */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && examType == 1 && subtype) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examType,
          subtype,
          subject,
          0,
          props.history
        )
      );
    }
    // else if (category == 2 && examType == 2) {
    else if (category == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examTypeId,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Category<span className="text-danger">*</span></strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.categoryLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                onClick={getCategoryHandler}
                                onChange={(e) =>
                                  onCategoryHandler(e.target.value)
                                }
                              >
                                <option value="">Select Category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.category && (
                                <div className="text-danger fs-12">
                                  {errors.category}
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            onChange={onChangeRadioHandler}
                            className="content-radio-view"
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="2"
                                defaultChecked
                                id="live"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="live"
                              >
                                Live
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="1"
                                id="guest"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="guest"
                              >
                                Guest Demo
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="3"
                                id="register"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="register"
                              >
                                Register Demo
                              </label>
                            </div>
                          </div>

                          {demo == 2 || demo == 3 ? (
                            <>
                              {category == 1 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>Board<span className="text-danger">*</span></strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.boardLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={board}
                                      onClick={getBoardHandler}
                                      onChange={(e) =>
                                        onBoardHandler(e.target.value)
                                      }
                                    >
                                      <option value="">Select board</option>
                                      {props.boardList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.board && (
                                    <div className="text-danger fs-12">
                                      {errors.board}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {category == 2 && demo != 3 ? (
                                <>
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>Exam Type<span className="text-danger">*</span></strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow add-Question-arrow-position">
                                        {props.typeLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          <i className="bi bi-caret-down-fill"></i>
                                        )}
                                      </span>
                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={examTypeFullString}
                                        onClick={getExamtypeHandler}
                                        onChange={(e) =>
                                          onExamTypeHandler(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Examtype
                                        </option>
                                        {props.typeList.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={
                                                item.id + "^" + item.is_class
                                              }
                                            >
                                              {item.type_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.examType && (
                                        <div className="text-danger fs-12">
                                          {errors.examType}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {examType == 1 && demo != 3 ? (
                                    <div className="form-group mb-3">
                                      <label className="mb-2">
                                        <strong>Exam Sub Type<span className="text-danger">*</span></strong>
                                      </label>
                                      <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-arrow-position">
                                          {props.subTypeLoading ? (
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                          ) : (
                                            <i className="bi bi-caret-down-fill"></i>
                                          )}
                                        </span>
                                        <select
                                          defaultValue={"option"}
                                          className="form-control form-control"
                                          value={subtype}
                                          onClick={getSubtypeHandler}
                                          onChange={(e) =>
                                            onSubtypeTypeHandler(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select Sub type
                                          </option>
                                          {props.subTypeList.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {item.subtype_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      {errors.subtype && (
                                        <div className="text-danger fs-12">
                                          {errors.subtype}
                                        </div>
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              {demo != 3 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>Subjects<span className="text-danger">*</span></strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.subjectLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={subject}
                                      onClick={getSubjectHandler}
                                      onChange={(e) =>
                                        setSubject(e.target.value)
                                      }
                                    >
                                      <option value="">Select subjects</option>
                                      {props.subjectList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.subject && (
                                    <div className="text-danger fs-12">
                                      {errors.subject}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {(category == 1 || category == 2) ||
                                examTypeFullString.split("^")[1] == 1 ? (
                                <div className={`form-group mb-3 ${(category == 1 || (category == 2 && demo == 2 || demo == 3) || examType == 2) ? null : 'content_disable'}`}>
                                  <label className="mb-2">
                                    <strong>Class<span className="text-danger">*</span></strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.classLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={classroom}
                                      onClick={getClassHandler}
                                      onChange={(e) =>
                                        setClassroom(e.target.value)
                                      }
                                    >
                                      <option value="">Select class</option>
                                      {props.classList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.short_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.classroom && (
                                    <div className="text-danger fs-12">
                                      {errors.classroom}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              {demo != 3 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>Chapter<span className="text-danger">*</span></strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.chapterLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={chapter}
                                      onClick={getChapterHandler}
                                      onChange={(e) =>
                                        setChapter(e.target.value)
                                      }
                                    >
                                      <option value="">Select chapter</option>
                                      {props.chapterList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.chapter_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.chapter && (
                                    <div className="text-danger fs-12">
                                      {errors.chapter}
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </>
                          ) : null}

                          <div className="mb-3">
                            <label
                              htmlFor="formFileDisabled"
                              className="form-label file-form-label"
                            >
                              Upload content<span className="text-danger">*</span>
                            </label>
                            <input
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              id="formFileDisabled"
                              accept=".pdf"
                              onChange={handleChange}
                            />
                            {errors.file && (
                              <div className="text-danger fs-12 mt-1">
                                {errors.file}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    categoryLoading: state.category.showLoading,
    categoryList: state.category.list,
    typeLoading: state.type.showLoading,
    typeList: state.type.list,
    subTypeLoading: state.subtype.showLoading,
    subTypeList: state.subtype.list,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.nonGroupSubjectList,
    classLoading: state.class.showLoading,
    classList: state.class.list,
    chapterLoading: state.chapter.showLoading,
    chapterList: state.chapter.typelist,
  };
};
export default connect(mapStateToProps)(UploadContent);
