import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useHistory,useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  updateAcademicSessionDetails,
  getAdvertisementScreenDetails,
} from "../../../store/actions/AcademicAction";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateAcademicSession = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state,filter } = useLocation();
  const calendarRef = useRef();

  let errorsObj = { category: "", board: "", type: "", name: "", date: "" };

  const [errors, setErrors] = useState(errorsObj);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // console.log("state...",state);
    // console.log("filter...",filter);
    dispatch(globalLoadingAction(true));
    dispatch(getExamCategory(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getExamType(props.history));
  }, []);

  useEffect(() => {
    setName(state.session_name);
    setCategory(state.exam_category_id);
    setBoard(state.exam_board_type);
    setType(state.exam_board_type);
    setStartDate(new Date(state.session_start_date));
    setEndDate(new Date(state.session_end_date));
  }, [props.categoryList]);

  /* form stubmit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (category == 1 && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    } else if (category === "" && board === "") {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (category == 2 && type === "") {
      errorObj.type = "Exam type is Required";
      error = true;
    } else if (category === "" && type === "") {
      errorObj.type = "Exam type is Required";
      error = true;
    }
    if (name === "") {
      errorObj.name = "Session name is Required";
      error = true;
    }
    if (startDate === "" && endDate === null) {
      errorObj.date = "Session date is Required";
      error = true;
    } else if (startDate && endDate === null) {
      errorObj.date = "Session end date is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      updateAcademicSessionDetails(
        state.id,
        category ? parseInt(category) : 0,
        category == 1 ? parseInt(board) : category == 2 ? parseInt(type) : 0,
        name,
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        state.filter,
        props.history
      )
    );
  }

  /* form data clear */
  const cleardata = () => {
    setName("");
    setCategory("");
    setBoard("");
    setType("");
    setStartDate("");
    setEndDate(null);
  };

  /* date handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  /* navidation go back */
  const onGoBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Exam Category
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="">Select Category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          {category == 1 ? (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  Board<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={board}
                                  onChange={(e) => setBoard(e.target.value)}
                                >
                                  <option value="">Select Board</option>
                                  {props.boardList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.board && (
                                <div className="text-danger fs-12">
                                  {errors.board}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  Exam type
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                >
                                  <option value="">Select Exam type</option>
                                  {props.typeList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.type_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.type}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Session Name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Session date
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="date-control-view">
                              <DatePicker
                                ref={calendarRef}
                                placeholderText="Select date range"
                                selectsRange
                                selected={startDate}
                                onChange={onDateHandler}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                startDate={startDate}
                                endDate={endDate}
                                dropdownMode="select"
                              />
                              <button
                                className="picker-btn"
                                onClick={() =>
                                  calendarRef.current.setOpen(true)
                                }
                              >
                                <i class="fa-solid fa-calendar-days"></i>
                              </button>
                            </div>
                            {errors.date && (
                              <div className="text-danger fs-12">
                                {errors.date}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.advertisement.list,
    screenList: state.advertisement.screenList,
    categoryList: state.category.list,
    boardList: state.board.list,
    typeList: state.type.list,
  };
};
export default connect(mapStateToProps)(UpdateAcademicSession);
