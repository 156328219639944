import swal from "sweetalert";
import {
    BRANCH_LIST,
    BRANCH_LOADING,
    ADD_BRANCH,
    REMOVE_BRANCH,
    UPDATE_BRANCH,
    UPDATE_STATUS_BRANCH
} from '../constants';

import {
    getBranch,
    addBranch,
    updateBranch,
    removeBranch,
    updateStatusBranch
} from '../../services/BranchServices';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';



export function addBranchDetails(branchName, branchCode, branchSubject, question, history) {
    return (dispatch) => {
        addBranch(branchName, branchCode, branchSubject, question)
            .then((response) => {
                dispatch(addBrnch(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateBranchDetails(id, branchName, branchSubject, branchCode, question, history) {
    return (dispatch) => {
        updateBranch(id, branchName, branchSubject, branchCode, question)
            .then((response) => {
                dispatch(updateBrnch(false));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(branchLoading(false));
                utility.showError(error.response.data);
            });
    };
}

export function getBranchDetails(history) {
    return (dispatch) => {
        getBranch()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getBranchList(response.data.data));
                }
                dispatch(branchLoading(false));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(branchLoading(false));
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateStatusBranchData(item, history) {
    return (dispatch) => {
        updateStatusBranch(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusBranchAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeBranchData(id, history) {
    return (dispatch) => {
        removeBranch(id)
            .then((response) => {
                dispatch(removeBranchAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getBranchList(data) {
    return {
        type: BRANCH_LIST,
        payload: data,
    };
}

export function addBrnch(data) {
    return {
        type: ADD_BRANCH,
        payload: data,
    };
}
export function updateBrnch(data) {
    return {
        type: UPDATE_BRANCH,
        payload: data,
    };
}
export function updateStatusBranchAction(item) {
    return {
        type: UPDATE_STATUS_BRANCH,
        payload: item,
    };
}

export function removeBranchAction(id) {
    return {
        type: REMOVE_BRANCH,
        payload: id,
    };
}

export function branchLoading(status) {
    return {
        type: BRANCH_LOADING,
        payload: status,
    };
}