import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./jsx/pages/ResetPassword'));
const Otp = lazy(() => import('./jsx/pages/Otp'));
const Login = lazy(() => import('./jsx/pages/Login'));
const PdfViewElibraryAdmin = lazy(() => import('./jsx/components/E-Library/PdfViewElibraryAdmin'));

function App(props) {

    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.pathname !== "/reset-password") {
            checkAutoLogin(dispatch, props.history);
        }
    }, [dispatch, props.history]);

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/otp' component={Otp} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/reset-password' component={ResetPassword} />
            <Route path='/page-register' component={SignUp} />
            <Route exact path='/elibrary-content-pdf-view/:infoid' component={PdfViewElibraryAdmin} />
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

