import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useHistory } from "react-router-dom";
import {
  addAdvertisementDetails,
  getAdvertisementScreenDetails,
  getAdvertisementData,
} from "../../../store/actions/AdvertisementAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const AddAdvertisement = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const fileRef = useRef(null);

  let errorsObj = { title: "", screen: "", file: "", description: "" };

  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [screen, setScreen] = useState("");
  const [file, setFile] = useState("");
  const [description, setDescription] = useState("");
  const [estimationTime, setEstimationTime] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getAdvertisementScreenDetails(props.history));
    dispatch(getAdvertisementData(props.history));
  }, []);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (screen === "") {
      errorObj.screen = "Screen is Required";
      error = true;
    }
    if (file === "") {
      errorObj.file = "File is Required";
      error = true;
    }
    if (description === "") {
      errorObj.description = "Description is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      addAdvertisementDetails(
        title,
        screen,
        file,
        description,
        estimationTime,
        cleardata,
        props.history
      )
    );
  }

  /* form data clear */
  const cleardata = () => {
    setTitle("");
    setScreen("");
    setDescription("");
    setFile("");
    setSelectedImage(null);
    fileRef.current.value = '';
  };

  /* image upload */
  const handleChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width == 360 && img.height == 120) {
            setSelectedImage(e.target.result);
            setFile(file);
            const errorObj = { ...errors };
            errorObj.file = "";
            setErrors(errorObj);
          } else {
            setSelectedImage(null);
            setFile("");
            const errorObj = { ...errors };
            errorObj.file = "Image size should be 360/120";
            setErrors(errorObj);
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const inlineStyle = {
    backgroundColor: "transparent",
  };

  /* item disable */
  const onDisabled = (value) => {
    let state = false;
    props.list.forEach((element) => {
      if (element.screen_id === value.toString()) {
        state = true;
      }
    });

    return state;
  };

  /* navigation go back */
  const onGoBackHandler = () => {
    history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Title<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            {errors.title && (
                              <div className="text-danger fs-12">
                                {errors.title}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Screen<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control"
                                value={screen}
                                //onClick={getBoardHandler}
                                onChange={(e) => setScreen(e.target.value)}
                              >
                                <option value="">Select Screen</option>
                                {props.screenList.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      disabled={onDisabled(item.id)}
                                      value={item.id}
                                    >
                                      {item.screen_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.screen && (
                              <div className="text-danger fs-12">
                                {errors.screen}
                              </div>
                            )}
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="uploadFile"
                              className="form-label file-form-label"
                            >
                              Upload Image
                              <span className="text-danger">*</span>
                            </label>
                            <div className="image-fileView">
                              {selectedImage ? (
                                <img
                                  className="upload-image"
                                  src={selectedImage}
                                  alt="Selected"
                                />
                              ) : null}
                              <div
                                style={!selectedImage ? inlineStyle : null}
                                className="uploadFile-view"
                              >
                                <input
                                  ref={fileRef}
                                  type="file"
                                  name="uploadFile"
                                  id="uploadFile"
                                  className="image-file"
                                  accept="image/*"
                                  onChange={handleChange}
                                />
                                <label
                                  className="image-file-label"
                                  for="uploadFile"
                                >
                                  <i
                                    className="fa fa-camera"
                                    aria-hidden="true"
                                  ></i>
                                  Upload image
                                </label>
                              </div>
                            </div>
                            <div className="help-text-view mt-1">
                              <p>Upload image dimension should be 360*120</p>
                            </div>
                            {errors.file && (
                              <div className="text-danger fs-12 mt-1">
                                {errors.file}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Description
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter the Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                            {errors.description && (
                              <div className="text-danger fs-12">
                                {errors.description}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.advertisement.list,
    screenList: state.advertisement.screenList,
  };
};
export default connect(mapStateToProps)(AddAdvertisement);
