import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from '../../../apis/Apis';
import { addEventHistoryData } from '../../../store/actions/EventHistoryAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';



const CreateEventHistory = (props) => {

	const dispatch = useDispatch();
	const calendarRef = useRef();
	const fileInputRef = useRef(null);

	let errorsObj = { heading: '', subheading: '', details: '', date: '', file: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [heading, setHeading] = useState('');
	const [subheading, setSubheading] = useState('');
	const [details, setDetails] = useState("");
	const [date, setDate] = useState('');
	const [eventImage, setEventImage] = useState('');
	const [text, setText] = useState('');

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (heading === '') {
			errorObj.heading = 'Title is Required';
			error = true;
		}
		if (subheading === '') {
			errorObj.subheading = 'Sub title is Required';
			error = true;
		}
		if (details === '') {
			errorObj.details = 'Details is Required';
			error = true;
		}
		if (date === '') {
			errorObj.date = 'Date is Required';
			error = true;
		}
		if (eventImage === '') {
			errorObj.file = 'Image is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(addEventHistoryData(heading, subheading, details, moment(date).format('YYYY-MM-DD'), eventImage, props.history));
		setHeading('');
		setSubheading('');
		setDetails('');
		setDate('');
		setEventImage('');

		fileInputRef.current.value = null;

	}


	/* image upload */
	const onFileHandler = (file) => {

		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("tag_image", file);
			axios({
				url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
				method: "POST",
				headers: {
					ContentType: "multipart/form-data",
				},
				data: formData,
			}).then((res) => {
				setEventImage(res.data.imagepath);

			}).then((json) => {
				console.log('result2', json);
			})
		});
	}

	const onImageHandler = (blobInfo) => {

		return new Promise((success, reject) => {
			const formData = new FormData();
			formData.append("tag_image", blobInfo.blob());
			axios({
				url: "https://api.schemaphic.co.in:3000/api/superadmin/uploadimage",
				method: "POST",
				headers: {
					ContentType: "multipart/form-data",
				},
				data: formData,
			}).then((res) => {
				success(res.data.imagepath);
			})
				.then((json) => {
					//console.log('result2', json);
				})
		});
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Title<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the Title" value={heading} onChange={(e) => setHeading(e.target.value)} />
														{errors.heading && <div className="text-danger fs-12">{errors.heading}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Sub title<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the Sub title" value={subheading} onChange={(e) => setSubheading(e.target.value)} />
														{errors.subheading && <div className="text-danger fs-12">{errors.subheading}</div>}
													</div>

													<div className="form-group mb-3 create_history">
														<label className="mb-2">
															<strong>Date<span className="text-danger">*</span></strong>
														</label>
														<div className="date-control-view">
															<DatePicker
																ref={calendarRef}
																selected={date}
																placeholderText="Enter the date"
																onChange={(date) => setDate(date)}
																showYearDropdown
																showMonthDropdown
																// yearDropdownItemNumber={4000}
																// minDate={moment().subtract(100, "years")}
																// maxDate={moment().add(100, "years")}
															// showYearPicker
															/>
															<button type='button' className="picker-btn"
																onClick={() => calendarRef.current.setOpen(true)}
															>
																<i class="fa-solid fa-calendar-days"></i>
															</button>
														</div>
														{errors.date && <div className="text-danger fs-12">{errors.date}</div>}
													</div>

													<div className="mb-3">
														<label htmlFor="formFile" className="form-label"><strong>Upload image<span className="text-danger">*</span></strong></label>
														<input className="form-control"
															type="file"
															ref={fileInputRef}
															id="formFile"
															onChange={(e) => onFileHandler(e.target.files[0])}
															accept="image/*"
														/>
														{errors.file && <div className="text-danger fs-12">{errors.file}</div>}
													</div>
													{eventImage == '' ? null :
														<div className="event-image-view">
															<img src={eventImage} alt="event-image" />
														</div>
													}
													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Details<span className="text-danger">*</span></strong>
														</label>
														<div className="ck-editer-view">

															<Editor
																apiKey={GlobalConfigs.TINY_API_KEY}
																value={details}
																onInit={(evt, editor) => {
																	setText(editor.getContent({ format: 'text' }));
																}}
																onEditorChange={(newValue, editor) => {
																	setDetails(newValue);
																	setText(editor.getContent({ format: 'text' }));
																}}
																init={{
																	height: 300,
																	//menubar: false,
																	plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount'],
																	toolbar: 'undo redo | formatselect | ' +
																		'bold italic backcolor | alignleft aligncenter ' +
																		'alignright alignjustify | bullist numlist outdent indent | ' +
																		'removeformat | help',
																	content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
																	// toolbar: "undo redo | styleselect | fontselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent",
																	//font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
																	//content_style: "@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); body { font-family: Oswald; }",
																}}
															/>
														</div>
														{errors.details && <div className="text-danger fs-12">{errors.details}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn">
															Submit
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
	};
};
export default connect(mapStateToProps)(CreateEventHistory);