import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from '../../../apis/Apis';
import { updateEventHistoryData } from '../../../store/actions/EventHistoryAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateEventHistory = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const calendarRef = useRef();

    let errorsObj = { heading: '', subheading: '', details: '', date: '', file: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [heading, setHeading] = useState(state.data.title);
    const [subheading, setSubheading] = useState(state.data.sub_title);
    const [details, setDetails] = useState(state.data.description);
    const [date, setDate] = useState(new Date(state.data.event_date));
    const [eventImage, setEventImage] = useState(state.data.event_image);
    const [text, setText] = useState('');

    const [previousPageFilterData, setPreviousPageFilterData] = useState('');


    useEffect(() => {
        setPreviousPageFilterData(props.location.state?.filter)
    }, []);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (heading === '') {
            errorObj.heading = 'Heading is Required';
            error = true;
        }
        if (subheading === '') {
            errorObj.subheading = 'subheading is Required';
            error = true;
        }
        if (details === '') {
            errorObj.details = 'Details is Required';
            error = true;
        }
        if (date === '') {
            errorObj.date = 'Date is Required';
            error = true;
        }
        if (eventImage === '') {
            errorObj.file = 'Image is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        dispatch(globalLoadingAction(true));
        dispatch(updateEventHistoryData(state.data.id, heading, subheading, details, moment(date).format('YYYY-MM-DD'), eventImage, state.filter, props.history));
        setHeading('');
        setSubheading('');
        setDetails('');
        setDate('');
    }

    /* image upload */
    const onFileHandler = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("tag_image", file);
            axios({
                url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
                method: "POST",
                headers: {
                    ContentType: "multipart/form-data",
                },
                data: formData,
            }).then((res) => {
                setEventImage(res.data.imagepath);

            }).then((json) => {
                console.log('result2', json);
            })

        });

    }

    const onEditorHandler = (n, e) => {
        setDetails(n);
        setText(e.getContent({ format: 'text' }));
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
			pathname: "/event-history-list",
			state: {previousPageFilterData},
		  });

        // history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Title<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the heading" value={heading} onChange={(e) => setHeading(e.target.value)} />
                                                        {errors.heading && <div className="text-danger fs-12">{errors.heading}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Sub title<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the subheading" value={subheading} onChange={(e) => setSubheading(e.target.value)} />
                                                        {errors.subheading && <div className="text-danger fs-12">{errors.subheading}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Date <span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="date-control-view">
                                                            <DatePicker
                                                                ref={calendarRef}
                                                                selected={date}
                                                                onChange={(date) => setDate(date)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                              

                                                            />
                                                            <button type='button' className="picker-btn"
                                                                onClick={() => calendarRef.current.setOpen(true)}
                                                            >
                                                                <i class="fa-solid fa-calendar-days"></i>
                                                            </button>
                                                        </div>

                                                        {errors.date && <div className="text-danger fs-12">{errors.date}</div>}
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="formFile" className="form-label"><strong>Upload image <span className="text-danger">*</span></strong></label>
                                                        <input className="form-control"
                                                            type="file"
                                                            id="formFile"
                                                            onChange={(e) => onFileHandler(e.target.files[0])}
                                                            accept="image/*"
                                                        />
                                                        {errors.file && <div className="text-danger fs-12">{errors.file}</div>}
                                                    </div>

                                                    <div className="event-image-view">
                                                        <img src={eventImage} alt="event-image" />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Details<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="ck-editer-view">
                                                            <Editor
                                                      		apiKey={GlobalConfigs.TINY_API_KEY}
                                                                value={details}
                                                                onInit={(evt, editor) => {
                                                                    setText(editor.getContent({ format: 'text' }));
                                                                }}
                                                                onEditorChange={(newValue, editor) => {
                                                                    setDetails(newValue);
                                                                    setText(editor.getContent({ format: 'text' }));
                                                                }}
                                                                init={{
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount'],
                                                                    toolbar: 'undo redo | formatselect | ' +
                                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat | help',
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        {errors.details && <div className="text-danger fs-12">{errors.details}</div>}
                                                    </div>



                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn">
                                                            Update
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.board.showLoading,
    };
};
export default connect(mapStateToProps)(UpdateEventHistory);