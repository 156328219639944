import React, { Fragment, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Row,
    Col,
    Card,
    Table,
    Form,
    Button,
    Tooltip
} from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useDebounce } from "use-debounce";
import { getResumeDetails, updateResumeStatusData } from "../../../store/actions/ResumeActions";
import { Modal } from 'react-bootstrap';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import swal from "sweetalert";
import globalConfigs from "../../../configs/GlobalConfigs";

const ResumeList = (props) => {

    const { state } = useLocation();
    const calendarRef = useRef();
    const endCalendarRef = useRef();


    const [active, setActive] = useState(state?.location?.active ? state?.location?.active : 1)
    const [applyFor, setApplyFor] = useState(state?.location?.applyFor ? state?.location?.applyFor : '')
    const [applyForDebounce] = useDebounce(applyFor, 1000);
    const [startDate, setStartDate] = useState(state?.location?.startDate ? state?.location?.startDate : '')
    const [endDate, setEndDate] = useState(state?.location?.endDate ? state?.location?.endDate : '')
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const history = useHistory()
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(globalLoadingAction(true))
        dispatch(getResumeDetails(active, applyForDebounce, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', history));
    }, [])


    const handleSwitchChange = (id) => {
        swal({
            title: "Enter comment",
            icon: "info",
            content: {
                element: "textarea",
                attributes: {
                    placeholder: "Type your comment here",
                    rows: "4",
                    maxlength: "200", // Keep your maxlength as you have it
                },
            },
            buttons: {
                cancel: "Cancel",
                confirm: {
                    text: "Confirm",
                    className: "confirm-button-class"
                },
            },
            dangerMode: true,
        })
            .then((value) => {
                if (value) {
                    dispatch(updateResumeStatusData(id, active, applyForDebounce, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', document.querySelector(".swal-content__textarea").value, history));
                } else {
                    dispatch(getResumeDetails(active, applyForDebounce, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', history));
                }
            });

        // Reference to the textarea and the confirmation button
        const input = document.querySelector(".swal-content__textarea");
        const confirmButton = document.querySelector(".swal-button--confirm");

        // Check if the input and the confirm button exists
        if (input && confirmButton) {
            // Initially disable the confirm button
            confirmButton.disabled = true;

            // Create a counter element to show character count
            const counter = document.createElement('div');
            counter.textContent = `0 / 200`;
            input.parentNode.insertBefore(counter, input.nextSibling);

            // Event listener to enable button and update counter
            input.addEventListener('input', () => {
                const textLength = input.value.length;
                confirmButton.disabled = textLength === 0 || textLength > 200 || input.value.trim() == "";
                counter.textContent = `${textLength} / 200`; // Update the counter

                // Optional: Change color if limit is reached
                counter.style.color = textLength > 200 ? 'red' : 'black';
            });
        }
    };


    /* navigation details page */
    const DetailsHandler = (id) => {
        history.push({
            pathname: '/resume-details',
            state: {
                id: id,
                location: { active: active, applyFor: applyFor, startDate: startDate, endDate: endDate }
            }
        })
    }

    const onDonwloadHandeler = (item) => {
        console.log("item.resume", item.resume)
        /*fetch(item.resume).then(response => {
            response.blob().then(blob => {

                const fileURL = window.URL.createObjectURL(blob);

                console.log("fileURL",fileURL)

                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'resume.pdf';
                alink.click();
            })
        })*/
        return item.resume;
    }

    /* pagination */
    const pageHandler = (data) => {
        setActive(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getResumeDetails(data.selected + 1, applyForDebounce, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', history))
    };

    const onClearHandler = () => {
        setStartDate('');
        // dispatch(globalLoadingAction(true));
        // dispatch(getEventHistoryList(page, '', props.history));
    }
    const onClearEndHandler = () => {
        setEndDate('');
        // dispatch(globalLoadingAction(true));
        // dispatch(getEventHistoryList(page, '', props.history));
    }

    /* change start date */
    const onDateHandler = (date) => {
        setActive(1);
        setStartDate(date);
        dispatch(globalLoadingAction(true))
        dispatch(getResumeDetails(1, applyForDebounce, date ? moment(date).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', history));

    }

    /* change end date */
    const onEndDateHandler = (date) => {
        setActive(1)
        setEndDate(date);
        dispatch(globalLoadingAction(true))
        dispatch(getResumeDetails(1, applyForDebounce, startDate ? moment(startDate).format('YYYY-MM-DD') : '', date ? moment(date).format('YYYY-MM-DD') : '', history));

    }

    /* apply handler */
    const applyHandler = (val) => {
        setActive(1)
        setApplyFor(val);
        dispatch(globalLoadingAction(true))
        dispatch(getResumeDetails(1, val, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', history));
    }

    /* data reset */
    const onResetHandler = () => {
        setActive(1)
        setApplyFor('')
        setStartDate('')
        setEndDate('')
        dispatch(globalLoadingAction(true))
        dispatch(getResumeDetails(1, '', '', '', history));
    }

    return (
        <>
            <Fragment>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>

                                <div className="add-Question-list-view">


                                    <div className="clear-question-type" style={{ marginRight: '5px' }}>
                                        <div className="px-1">
                                            <div className="basic-dropdown">
                                                <span className="drop_down_arrow">
                                                    <i className="bi bi-caret-down-fill"></i>
                                                </span>
                                                <select
                                                    className="form-control filter-form-control"
                                                    style={{ height: '45px' }}
                                                    value={applyFor}
                                                    onChange={(e) => { applyHandler(e.target.value) }}
                                                >
                                                    <option value="">Select Apply For &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                                                    <option value="Faculty">Faculty</option>
                                                    <option value="Editor">Editor</option>
                                                    <option value="Developer">Developer</option>
                                                    <option value="Sme">SME</option>

                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="date-control-view" style={{ marginRight: '5px' }}>
                                        <DatePicker
                                            ref={calendarRef}
                                            placeholderText="Select start date"
                                            selected={startDate}
                                            onChange={(date) => onDateHandler(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}

                                        />
                                        <button className="picker-btn"
                                            onClick={() => calendarRef.current.setOpen(true)}
                                        >
                                            <i class="fa-solid fa-calendar-days"></i>
                                        </button>
                                        {/* {startDate ?
                                            <button onClick={onClearHandler} className="clear-date-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            : null} */}
                                    </div>
                                    <div className="date-control-view" style={{ marginRight: '5px' }}>
                                        <DatePicker
                                            ref={endCalendarRef}
                                            placeholderText="Select end date"
                                            selected={endDate}
                                            onChange={(date) => onEndDateHandler(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            disabled={(startDate == "" || startDate == null || startDate == "Invalid date")}
                                            minDate={startDate}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        <button className="picker-btn"
                                            onClick={() => endCalendarRef.current.setOpen(true)}
                                        >
                                            <i class="fa-solid fa-calendar-days"></i>
                                        </button>
                                        {/* {endDate ?
                                            <button onClick={onClearEndHandler} className="clear-date-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            : null} */}
                                    </div>
                                    <div >
                                        <button onClick={onResetHandler} className={`btn btn-secondary ${applyFor == '' && startDate == '' && endDate == '' ? 'content_disable' : null}`}>Reset</button>
                                    </div>
                                </div>
                                {
                                    props.resumeList.length !== 0 ? (
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>Apply For</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Full Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Email</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Phone</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Gender</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Upload Date</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Download Resume</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Mark as Attended</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Action</strong>
                                                    </th>
                                                </tr>
                                            </thead>
{console.log("props.resumeList------------", props.resumeList)}
                                            <tbody>
                                                {
                                                    props.resumeList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.apply_for == "Sme" ? "SME" : item.apply_for}</td>
                                                                <td>{item.first_name + " " + item.last_name}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.phone}</td>
                                                                <td>{item.gender}</td>
                                                                <td>{moment(item.create_date).format('MM-DD-YYYY')}</td>
                                                                <td><div className='successBox'> <a target="_blank" href={globalConfigs.RESUME + item.resume}>Download</a></div></td>
                                                                <td>{item.is_attended == 1 ?
                                                                    < OverlayTrigger
                                                                        delay={{ hide: 450, show: 300 }}
                                                                        overlay={(props) => (
                                                                            <Tooltip {...props}>{item.comment ? item.comment : ''}</Tooltip>
                                                                        )}
                                                                        placement="top"
                                                                    >
                                                                        <Form.Check
                                                                            className="scale-up-switch"
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            checked={true}
                                                                        />
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <Form.Check
                                                                        className="scale-up-switch"

                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        checked={false}
                                                                        defaultChecked={false}
                                                                        onChange={() => { handleSwitchChange(item.id) }}
                                                                    />
                                                                }
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <Button
                                                                        className="btn btn-primary shadow btn-xs sharp"
                                                                        onClick={() => DetailsHandler(item.id)}
                                                                    >
                                                                        <i class="fa-solid fa-eye"></i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    ) : (

                                        <div style={{ marginTop: '50px' }} className='no_data'>
                                            <strong >No data found</strong>
                                        </div>

                                    )
                                }

                                {
                                    (props.resumeList.length !== 0) && (
                                        <div className="pagination-container">
                                            {
                                                (props.resumeList.length !== 0 && props.showLoading == false) ? (
                                                    <div className="page-result">
                                                        <p>
                                                            Showing: {(active - 1) * 10 + 1} -{" "}
                                                            {10 * active - (10 - props.resumeList.length)} of({" "}
                                                            {props.totalRecode} items)
                                                        </p>
                                                    </div>
                                                ) : <div></div>
                                            }
                                            {props.totalRecode > 10 ?
                                                <ReactPaginate
                                                    containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    nextLabel={<i class="la la-angle-right"></i>}
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    breakLabel="..."
                                                    previousLabel={<i class="la la-angle-left"></i>}
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    renderOnZeroPageCount={null}
                                                    activeClassName="active"
                                                    onPageChange={pageHandler}
                                                    pageRangeDisplayed={3}
                                                    forcePage={active - 1}
                                                    pageCount={props.totalPage}
                                                />
                                                : null}
                                        </div>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Col></Row></Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        resumeList: state.Resume.List,
        totalPage: state.Resume.totalPage,
        totalRecode: state.Resume.totalRecode,
        showLoading: state.global.showLoading
    }
}

export default connect(mapStateToProps)(ResumeList)