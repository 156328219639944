import swal from "sweetalert";
import {
    SUBJECT_LOADING,
    SUBJECT_LIST,
    ADD_SUBJECT,
    UPDATE_SUBJECT,
    REMOVE_SUBJECT,
    UPDATE_STATUS_SUBJECT,
    GET_NON_GROUP_SUBJECT_LIST,
    CLEAR_SUBJECT_LIST,
    BOARD_CLASS_BY_SUBJECT_LIST,
    SUBJECT_FILTTER_LIST,
    GET_SUBJECTS_BY_BOARDS,
    GET_SINGLE_SUBJECT_LIST,
    E_SUB_FILTER_SUBJECTS,
    SUBJECT_CATEGORY_LIST,
    SUBJECT_BOARD_LIST,
    SUBJECT_GROUP_INDIVIDUAL_LIST,
    SUBJECT_TYPE_LIST,
    SUBJEC_TLIST_PAGE_NUMBER
} from '../constants';

import {
    getSubjectList,
    addSubject,
    updateSubject,
    removeSubject,
    updateStatusSubject,
    getboardClassBySubjects,
    getNonGroupSubjects,
    getSubjectsFiltter,
    getSubjectsByBoards,
    getSingleSubjectList,
    getSubjectsFiltterSubscriptionLibrary
} from '../../services/SubjectsService';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addSubjectDetails(category_id, examType_id, subType_id, board_id, subjectName, group_exist, subject_group, color, file, iconImg, history) {
    return (dispatch) => {
        addSubject(category_id, examType_id, subType_id, board_id, subjectName, group_exist, subject_group, color, file, iconImg)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addSubjectData(true));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addSubjectData(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateSubjectDetails(id, category_id, examType_id, subType_id, board_id, subjectName, subject_code, group_exist, subject_group, color, file, iconImg, filter, history) {
    return (dispatch) => {
        updateSubject(id, category_id, examType_id, subType_id, board_id, subjectName, subject_code, group_exist, subject_group, color, file, iconImg)
            .then((response) => {
                dispatch(updateSubjectData(true));
                utility.showSuccess(response.data.msg);
                //history.goBack();
                history.push({
                    pathname: '/subject-list',
                    state: filter
                });
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getSubjects(page, board_id, exam_type_id, exam_subtype_id, category_id, group_exist, history) {
    return (dispatch) => {
        getSubjectList(page, board_id, exam_type_id, exam_subtype_id, category_id, group_exist)
            .then((response) => {
                // console.log("response...", response)
                dispatch(getSubjectDetails(response.data));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSubjectsFiltterList(history) {
    return (dispatch) => {
        getSubjectsFiltter()
            .then((response) => {
                dispatch(getSubjectsFiltterAction(response.data.data));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getSubjectsFiltterSubLibraryList(board_id, exam_type_id, category_id, classno, history) {
    return (dispatch) => {
        getSubjectsFiltterSubscriptionLibrary(board_id, exam_type_id, category_id, classno)
            .then((response) => {
                dispatch(getSubjectsFiltterSubEAction(response.data.data));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getNonGroupSubjectsList(category_id, examType_id, subType_id, board_id, isGroup, history) {

    return (dispatch) => {
        getNonGroupSubjects(category_id, examType_id, subType_id, board_id, isGroup)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getNonGroupSubjectsAction(response.data.data));
                } else {
                    dispatch(getNonGroupSubjectsAction([]));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getboardClassBySubjectsList(board_id, history) {
    return (dispatch) => {
        getboardClassBySubjects(board_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getboardClassBySubjectsAction(response.data.data));
                } else {
                    dispatch(getboardClassBySubjectsAction([]));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
                dispatch(subjectLoading(false));
            });
    };
}

export function updateStatusSubjectData(item, history) {
    return (dispatch) => {
        updateStatusSubject(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusSubjectAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeSubjectDetails(id, active, history) {
    return (dispatch) => {
        removeSubject(id)
            .then((response) => {
                // dispatch(getSubjects(active, "", "", "", "", history));
                dispatch(removeSubjectAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getSubjectsByBoardsDetails(board_id, history) {
    return (dispatch) => {
        getSubjectsByBoards(board_id)
            .then((response) => {
                // console.log("@@@@@----1", response)
                dispatch(getSubjectsByBoardsActions(response.data));
                // dispatch(getSubjects(active,"", "","", "",history));
                // dispatch(removeSubjectAction(id));
                // swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                // swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getSingleSubjectListDetails(page, board_id, exam_type_id, exam_subtype_id, category_id, history) {
    return (dispatch) => {
        getSingleSubjectList(page, board_id, exam_type_id, exam_subtype_id, category_id)
            .then((response) => {
                // console.log("*****-----", response)
                dispatch(getSingleSubjectListActions(response.data.data));
            })
            .catch((error) => {
                console.log(error);
                // swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getSubjectDetails(data) {
    return {
        type: SUBJECT_LIST,
        payload: data,
    };
}
export function getSubjectsFiltterAction(data) {
    return {
        type: SUBJECT_FILTTER_LIST,
        payload: data,
    };
}
export function getSubjectsFiltterSubEAction(data) {
    return {
        type: E_SUB_FILTER_SUBJECTS,
        payload: data,
    };
}
export function clearSubjectList(data) {
    return {
        type: CLEAR_SUBJECT_LIST,
        payload: data,
    };
}
export function getboardClassBySubjectsAction(data) {
    return {
        type: BOARD_CLASS_BY_SUBJECT_LIST,
        payload: data,
    };
}
export function addSubjectData(data) {
    return {
        type: ADD_SUBJECT,
        payload: data,
    };
}

export function updateSubjectData(data) {
    return {
        type: UPDATE_SUBJECT,
        payload: data,
    };
}
export function getNonGroupSubjectsAction(data) {
    return {
        type: GET_NON_GROUP_SUBJECT_LIST,
        payload: data,
    };
}
export function updateStatusSubjectAction(item) {
    return {
        type: UPDATE_STATUS_SUBJECT,
        payload: item,
    };
}

export function removeSubjectAction(id) {
    return {
        type: REMOVE_SUBJECT,
        payload: id,
    };
}

export function subjectLoading(status) {
    return {
        type: SUBJECT_LOADING,
        payload: status,
    };
}

export function getSubjectsByBoardsActions(status) {
    return {
        type: GET_SUBJECTS_BY_BOARDS,
        payload: status,
    };
}

export function getSingleSubjectListActions(status) {
    return {
        type: GET_SINGLE_SUBJECT_LIST,
        payload: status,
    };
}

export function getSubjectCategoryListActions(status) {
    return {
        type: SUBJECT_CATEGORY_LIST,
        payload: status,
    };
}

export function getSubjectBoardListActions(status) {
    return {
        type: SUBJECT_BOARD_LIST,
        payload: status,
    };
}

export function getSsubjectGroupIndividualListActions(status) {
    return {
        type: SUBJECT_GROUP_INDIVIDUAL_LIST,
        payload: status,
    };
}

export function getSubjectTypeListActions(status) {
    return {
        type: SUBJECT_TYPE_LIST,
        payload: status,
    };
}
export function getSubjectListPageNumberActions(status) {
    return {
        type: SUBJEC_TLIST_PAGE_NUMBER,
        payload: status,
    };
}