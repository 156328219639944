import swal from "sweetalert";
import {
    addClass,
    getClass,
    updateClass,
    removeClass,
    updateStatusClass,
    boardByClass,
    examTypeByClass
} from '../../services/ClassServices';

import {
    CLASS_LOADING,
    ADD_CLASS,
    UPDATE_CLASS,
    GET_CLASS,
    REMOVE_CLASS,
    UPDATE_STATUS_CLASS,
    GET_BOARD_BY_CLASS,
    GET_EXAM_TYPE_BY_CLASS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addClassData(class_no, short_code, history) {
    return (dispatch) => {
        addClass(class_no, short_code)
            .then((response) => {
                dispatch(addClassAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addClassAction(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateClassData(id, class_no, short_code, history) {
    return (dispatch) => {
        updateClass(id, class_no, short_code)
            .then((response) => {
                dispatch(updateClassAction(true));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingClassAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getClassData(history) {
    return (dispatch) => {
        getClass()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getClassAction(response.data.data));
                }
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            });
    };
}

export function boardByClassData(history) {
    return (dispatch) => {
        boardByClass()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(boardByClassAction(response.data.data));
                } else {
                    dispatch(boardByClassAction([]));
                }
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            });
    };
}

export function examTypeByClassData(exam_type_id, history) {
    return (dispatch) => {
        examTypeByClass(exam_type_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(examTypeByClassAction(response.data.data));
                } else {
                    dispatch(examTypeByClassAction([]));
                }
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(loadingClassAction(false));
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateStatusClassData(item, history) {
    return (dispatch) => {
        updateStatusClass(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusClassAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeClassData(id, history) {
    return (dispatch) => {
        removeClass(id)
            .then((response) => {
                dispatch(removeClassAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addClassAction(data) {
    return {
        type: ADD_CLASS,
        payload: data,
    };
}
export function updateClassAction(data) {
    return {
        type: UPDATE_CLASS,
        payload: data,
    };
}
export function getClassAction(data) {
    return {
        type: GET_CLASS,
        payload: data,
    };
}
export function boardByClassAction(data) {
    return {
        type: GET_BOARD_BY_CLASS,
        payload: data,
    };
}
export function examTypeByClassAction(data) {
    return {
        type: GET_EXAM_TYPE_BY_CLASS,
        payload: data,
    };
}
export function updateStatusClassAction(item) {
    return {
        type: UPDATE_STATUS_CLASS,
        payload: item,
    };
}
export function removeClassAction(id) {
    return {
        type: REMOVE_CLASS,
        payload: id,
    };
}
export function loadingClassAction(status) {
    return {
        type: CLASS_LOADING,
        payload: status,
    };
}
