import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from 'react-bootstrap';

import { validateSubheading, validateShortCode, validateTextOnly } from '../../../utility/Utility';
import { addExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const CreateExamtype = (props) => {

	const dispatch = useDispatch();
	const file = useRef();

	let errorsObj = { type: '', category: '', code: '', fullTypeName: '', conducted: '', examTypeImage: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [type, setType] = useState('');
	const [category, setCategory] = useState(2);
	const [fullTypeName, setFullTypeName] = useState('');
	const [conductedBy, setConductedBy] = useState('');
	const [code, setCode] = useState('');
	const [examTypeImage, setExamTypeImage] = useState('');
	const [examTypeImageFile, setExamTypeImageFile] = useState('');


	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getExamCategory(props.history));
	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;

		const errorObj = { ...errorsObj };

		if (category === '') {
			errorObj.category = 'Exam category is Required';
			error = true;
		}

		if (type === '') {
			errorObj.type = 'Exam type is Required';
			error = true;

		} else if (validateTextOnly(type) === false) {
			errorObj.type = 'Invalid Exam type';
			error = true;
		}
		if (fullTypeName === '') {
			errorObj.fullTypeName = 'Full exam type name is Required';
			error = true;
		} else if (!/^[a-zA-Z ]+$/.test(fullTypeName)) {
			errorObj.fullTypeName = "Only alphabetic characters is allow";
			error = true;
		}
		if (conductedBy === '') {
			errorObj.conducted = 'Conducted By is Required';
			error = true;
		} else if (!/^[a-zA-Z]+$/.test(conductedBy)) {
			errorObj.conducted = "Only alphabetic characters is allow";
			error = true;
		}
		if (code === '') {
			errorObj.code = 'Exam Short code is Required';
			error = true;

		} else if (validateShortCode(code) === false) {
			errorObj.code = 'Invalid Short code';
			error = true;
		} else if (!/^[a-zA-Z]+$/.test(code)) {
			errorObj.code = "Only alphabetic characters is allow";
			error = true;
		}
		if (examTypeImage === '') {
			errorObj.examTypeImage = 'Exam Type Image is Required';
			error = true;
		}
		
		setErrors(errorObj);
		if (error) {
			return;
		}
		
		dispatch(globalLoadingAction(true));
		dispatch(addExamType(category, type, code, fullTypeName, conductedBy, examTypeImageFile, props.history));
		setType('');
		setCode('');
		setFullTypeName('');
		setConductedBy('');
		setExamTypeImage('')
	}

	/* image file store inro state */
	const handleChangeLiveExamPhoto = (event) => {
		setExamTypeImage(URL.createObjectURL(event.target.files[0]));
		setExamTypeImageFile(event.target.files[0]);
	};

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam category name</strong>
														</label>
														<div className="basic-dropdown">
															{/* <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span> */}
															<select
																defaultValue={"option"}
																className="form-control form-control"
																disabled
																value={category}
																onChange={(e) => setCategory(e.target.value)}
															>
																<option value="">Select category</option>
																{
																	props.categoryList.map((item, index) => {
																		return <option key={index} value={item.id}>{item.category}</option>
																	})
																}
															</select>
														</div>
														{errors.category && <div className="text-danger fs-12">{errors.category}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam type name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the exam type name" value={type} onChange={(e) => setType(e.target.value)} />
														{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Full Exam type name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the exam type name" value={fullTypeName} onChange={(e) => setFullTypeName(e.target.value)} />
														{errors.fullTypeName && <div className="text-danger fs-12">{errors.fullTypeName}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Conducted by<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the conducted by" value={conductedBy} onChange={(e) => setConductedBy(e.target.value)} />
														{errors.conducted && <div className="text-danger fs-12">{errors.conducted}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam short code<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the short code" value={code} onChange={(e) => setCode(e.target.value.toUpperCase())} />
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>

													<div className="col-sm-6">
														<div className="form-group mb-3">
															<label
																htmlFor="formFile"
																className="form-label file-form-label"
															>
																Upload image for exam type<span className="text-danger">*</span>
															</label>
															<input
																ref={file}
																className="form-control"
																type="file"
																id="formFile"
																accept="image/png"
																onChange={handleChangeLiveExamPhoto}
															/>
															{errors.examTypeImage && (
																<div className="text-danger fs-12 mt-1">
																	{errors.examTypeImage}
																</div>
															)}
														</div>

														<div className="form-group mb-3">
															{examTypeImage ? (
																<img
																	className="subject-img-form"
																	src={examTypeImage}
																/>
															) : null}
														</div>
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.type.showLoading,
		categoryList: state.category.list,
	};
};
export default connect(mapStateToProps)(CreateExamtype);