import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getClassData, examTypeByClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { updateComSubscriptionData, subscriptionLoadingAction } from '../../../store/actions/SubscriptionAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const UpdateCompetetiveSubscription = (props) => {

    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    let errorsObj = { type: '', className: '', setConunt: '', questionSet: '', amount: '', ePrice: '', sticker: '', courseCode: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [type, setType] = useState(state.item?.exam_type_id);
    const [typeFullString, setTypeFullString] = useState(state.item?.exam_type_id + "^" + state.item?.is_class);
    const [isClass, setIsClass] = useState();
    const [className, setClassName] = useState(state.item?.class);
    const [setConunt, setSetConunt] = useState(state.item?.set_count);
    const [questionSet, setQuestionSet] = useState(state.item?.question_per_set);
    const [amount, setAmount] = useState(state.item?.amount);
    const [elibraryPrice, setElibraryPrice] = useState(state.item?.library_price);
    const [sticker, setSticker] = useState(state.item?.sticker_text);
    const [isSticker, setIsSticker] = useState(state.item?.sticker_text == "" || state.item?.sticker_text == null ? false : true);
    const [courseCode, setCourseCode] = useState(state.item?.course_code)

    const [previousPageFilterData, setPreviousPageFilterData] = useState('');

    useEffect(() => {
        setIsClass(state.item?.is_class)
        dispatch(loadingAction(true));
        dispatch(getExamType(props.history));
        dispatch(loadingClassAction(true));
        dispatch(examTypeByClassData(state.item?.exam_type_id, props.history));

    }, []);

    useEffect(() => {
        setPreviousPageFilterData(props.location.state?.filterData)
    }, []);


    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        let error = false;

        const errorObj = { ...errorsObj };

        if (type === '') {
            errorObj.type = 'Exam type is Required';
            error = true;
        }
        if (className === '' && parseInt(type) == 2) {
            errorObj.className = 'Class name is Required';
            error = true;
        }
        if (setConunt === '') {
            errorObj.setConunt = 'Set count is Required';
            error = true;
        }
        if (questionSet === '') {
            errorObj.questionSet = 'Question set is Required';
            error = true;
        }
        if (amount === '') {
            errorObj.amount = 'amount is Required';
            error = true;
        }
        if (elibraryPrice === '') {
            errorObj.ePrice = 'E-library Price is Required';
            error = true;
        }
        if (sticker === '' && isSticker == true) {
            errorObj.sticker = 'Sticker Exist is Required';
            error = true;
        }

        if (courseCode === '') {
            errorObj.courseCode = 'Course Code is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(subscriptionLoadingAction(true));
        dispatch(globalLoadingAction(true));
        dispatch(updateComSubscriptionData(state.item?.id, type, (parseInt(type) !== 1 && parseInt(type) !== 5) ? className : 0, setConunt, questionSet, amount, elibraryPrice, isSticker ? sticker : '', courseCode, props.history));
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
			pathname: "/competitive-subscription-list",  
			state: {previousPageFilterData},
		  });
        // history.goBack();
    }

    /* change type */
    const onTypeHandler = (val) => {
        setType(val.split("^")[0]);
        setTypeFullString(val)
        setIsClass(val.split("^")[1])


        dispatch(loadingClassAction(true));
        dispatch(examTypeByClassData(type, props.history));
    }

    return (
        <>
            <div className="row">
                {/* {console.log('state', state)} */}
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Exam type name<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow add-Question-arrow-position">
                                                                <i className="bi bi-caret-down-fill"></i>
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={typeFullString}
                                                                onChange={(e) => onTypeHandler(e.target.value)}
                                                            >
                                                                <option value="">Select Exam type</option>
                                                                {
                                                                    props.typeList.map((item, index) => {
                                                                        return <option key={index} value={item.id + "^" + item.is_class}>{item.type_name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                                    </div>
                                                    {parseInt(type) !== 1 && isClass == true ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    {props.classLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={className}
                                                                    onChange={(e) => setClassName(e.target.value)}
                                                                >
                                                                    <option value="">Select class</option>
                                                                    {
                                                                        props.classList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.short_code}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.className && <div className="text-danger fs-12">{errors.className}</div>}
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Exam set count<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="number" placeholder="Enter the set count" value={setConunt} onChange={(e) => setSetConunt(e.target.value)} />
                                                        {errors.setConunt && <div className="text-danger fs-12">{errors.setConunt}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Question per set<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="number" placeholder="Enter the set count" value={questionSet} onChange={(e) => setQuestionSet(e.target.value)} />
                                                        {errors.questionSet && <div className="text-danger fs-12">{errors.questionSet}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Amount<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="number" placeholder="Enter the set count" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                        {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>E-Library Price<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="number" placeholder="Enter the E-library price" value={elibraryPrice} onChange={(e) => setElibraryPrice(e.target.value)} />
                                                        {errors.ePrice && <div className="text-danger fs-12">{errors.ePrice}</div>}
                                                    </div>

                                                    <div className="form-check mt-3 mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="sticker"
                                                            name="sticker"
                                                            checked={isSticker}
                                                            onChange={(e) => setIsSticker(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="sticker"
                                                        >Sticker Exist</label>
                                                    </div>

                                                    {isSticker ?
                                                        <div className="form-group mb-3 mt-2">
                                                            <label className="mb-2">
                                                                <strong>Sticker<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <input className="form-control" type="text" placeholder="Sticker" value={sticker} onChange={(e) => setSticker(e.target.value)} />
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3 mt-2">
                                                        <label className="mb-2">
                                                            <strong>Course Code<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Course Code" value={courseCode} onChange={(e) => setCourseCode(e.target.value)} />
                                                        {errors.courseCode && <div className="text-danger fs-12">{errors.courseCode}</div>}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Update
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.subscription.showLoading,
        typeLoading: state.type.showLoading,
        typeList: state.type.list,
        classLoading: state.class.showLoading,
        classList: state.class.examTypeByClasslist,
    };
};
export default connect(mapStateToProps)(UpdateCompetetiveSubscription);