import swal from "sweetalert";
import {
    addSCHExamDetails,
    editSCHExamDetails,
    getSCHExamDetails,
    updateSCHExamDetailsStatus,
    addCOMExamDetails,
    editCOMExamDetails,
    getCOMExamDetails,
    updateCOMExamDetailsStatus,
    removeSCHExamDetails,
    removeCOMExamDetails
} from '../../services/ExamDetailsServices';

import {
    EXAM_DETAILS_LOADING,
    GET_SCHOLASTIC_EXAM_DETAILS,
    GET_COMPETITIVE_EXAM_DETAILS,
    UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS,
    UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS,
    REMOVE_SCHOLASTIC_EXAM_DETAILS,
    REMOVE_COMPETITIVE_EXAM_DETAILS,
    SCHOLASTIC_ACADEMIC_SESSION,
    SCHOLASTIC_EXAM_DETAILS_BOARD,
    SCHOLASTIC_EXAM_DETAILS_CLASS,
    SCHOLASTIC_EXAM_DETAILS_SUBJECT,
    SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION,
    SCHOLASTIC_EXAM_DETAILS_STAUS,
    COMPETITIVE_EXAM_DETAILS_TYPE,
    COMPETITIVE_EXAM_DETAILS_CLASS,
    COMPETITIVE_EXAM_DETAILS_SUBJECT,
    COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION,
    COMPETITIVE_EXAM_DETAILS_STAUS,
    COMPETITIVE_EXAM_DETAILS_SUB_TYPE
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addScholasticExamDetails(title, category_id, board_id, class_id, subject_id, syllabus, session, exam_date, type_Exam_id, question_type, total_question, total_marks, total_time, history) {
    return (dispatch) => {
        addSCHExamDetails(title, category_id, board_id, class_id, subject_id, syllabus, session, exam_date, type_Exam_id, question_type, total_question, total_marks, total_time)
            .then((response) => {
                //console.log('hello world', response.data);
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function editScholasticExamDetails(id, title, category_id, board_id, class_id, subject_id, syllabus, session, exam_date, type_Exam_id, question_type, total_question, total_marks, total_time, history) {
    return (dispatch) => {
        editSCHExamDetails(id, title, category_id, board_id, class_id, subject_id, syllabus, session, exam_date, type_Exam_id, question_type, total_question, total_marks, total_time)
            .then((response) => {
                //console.log('hello world', response.data);
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                    history.goBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getScholasticExamDetails(academic_session, status, board_id, class_id, subject_id, history) {
    return (dispatch) => {
        getSCHExamDetails(academic_session, status, board_id, class_id, subject_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSCHExamDetailsAction(response.data));
                    dispatch(getScholasticAcademicSessionAction(response.data.academic_session_master));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateScholasticExamDetailsStatus(item, history) {
    return (dispatch) => {
        updateSCHExamDetailsStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateSCHExamDetailsStatusAction(item));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateCompetitiveExamDetailsStatus(item, history) {
    return (dispatch) => {
        updateCOMExamDetailsStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                console.log('response', response.data);
                if (response.data.status == 200) {
                    dispatch(updateCOMExamDetailsStatusAction(item));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeScholasticExamDetails(id, history) {
    return (dispatch) => {
        removeSCHExamDetails(id)
            .then((response) => {
                dispatch(removeSCHExamDetailsAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}


export function addCompetitiveExamDetails(title, category_id, examtype_id, subtype_id, class_id, syllabus, session, exam_date, subjects_ary, total_question, total_marks, total_time, history) {
    return (dispatch) => {
        addCOMExamDetails(title, category_id, examtype_id, subtype_id, class_id, syllabus, session, exam_date, subjects_ary, total_question, total_marks, total_time)
            .then((response) => {
                if (response.data.status) {
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function editCompetitiveExamDetails(id, title, category_id, examtype_id, subtype_id, class_id, syllabus, session, exam_date, subjects_ary, total_question, total_marks, total_time, history) {
    return (dispatch) => {
        editCOMExamDetails(id, title, category_id, examtype_id, subtype_id, class_id, syllabus, session, exam_date, subjects_ary, total_question, total_marks, total_time)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                    history.goBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function getCompetitiveExamDetails(academic_session, status, exam_type_id, class_id, exam_subtype_id, history) {
    return (dispatch) => {
        getCOMExamDetails(academic_session, status, exam_type_id, class_id, exam_subtype_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getCOMExamDetailsAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeCompetitiveExamDetails(id, history) {
    return (dispatch) => {
        removeCOMExamDetails(id)
            .then((response) => {
                dispatch(removeCOMExamDetailsAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getSCHExamDetailsAction(data) {
    return {
        type: GET_SCHOLASTIC_EXAM_DETAILS,
        payload: data,
    };
}

export function getScholasticAcademicSessionAction(data) {
    return {
        type: SCHOLASTIC_ACADEMIC_SESSION,
        payload: data,
    };
}

export function getCOMExamDetailsAction(data) {
    return {
        type: GET_COMPETITIVE_EXAM_DETAILS,
        payload: data,
    };
}

export function updateSCHExamDetailsStatusAction(data) {
    return {
        type: UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS,
        payload: data,
    };
}

export function updateCOMExamDetailsStatusAction(data) {
    return {
        type: UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS,
        payload: data,
    };
}

export function removeSCHExamDetailsAction(data) {
    return {
        type: REMOVE_SCHOLASTIC_EXAM_DETAILS,
        payload: data,
    };
}

export function removeCOMExamDetailsAction(data) {
    return {
        type: REMOVE_COMPETITIVE_EXAM_DETAILS,
        payload: data,
    };
}

export function loadingExamDetailsAction(status) {
    return {
        type: EXAM_DETAILS_LOADING,
        payload: status,
    };
}

export function scholasticExamDetailsBoardAction(status) {
    return {
        type: SCHOLASTIC_EXAM_DETAILS_BOARD,
        payload: status,
    };
}

export function scholasticExamDetailsClassAction(status) {
    return {
        type: SCHOLASTIC_EXAM_DETAILS_CLASS,
        payload: status,
    };
}
export function scholasticExamDetailsSubjectAction(status) {
    return {
        type: SCHOLASTIC_EXAM_DETAILS_SUBJECT,
        payload: status,
    };
}

export function scholasticExamDetailsAccademicSessionAction(status) {
    return {
        type: SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION,
        payload: status,
    };
}

export function scholasticExamDetailsStausAction(status) {
    return {
        type: SCHOLASTIC_EXAM_DETAILS_STAUS,
        payload: status,
    };
}

export function competitiveExamDetailsTypeAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_TYPE,
        payload: status,
    };
}

export function competitiveExamDetailsClassAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_CLASS,
        payload: status,
    };
}
export function competitiveExamDetailsSubjectAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_SUBJECT,
        payload: status,
    };
}

export function competitiveExamDetailsAccademicSessionAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION,
        payload: status,
    };
}

export function competitiveExamDetailsStausAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_STAUS,
        payload: status,
    };
}
export function competitiveExamDetailsSubTypeAction(status) {
    return {
        type: COMPETITIVE_EXAM_DETAILS_SUB_TYPE,
        payload: status,
    };
}