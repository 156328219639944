import { GET_ACADEMIC_SESSION_LIST,POST_ACADEMIC_SESSION_STATUS_UPDATE } from "../constants";

const initialState = {
  list: [],
  totalPage: 0,
  totalRecode: 0,
  showLoading: false,
};

export function AcademicReducer(state = initialState, action) {
  if (action.type === GET_ACADEMIC_SESSION_LIST) {
    return {
      ...state,
      list: action.payload.data,
      totalPage: action.payload.total_page,
      totalRecode: action.payload.total_record,
      showLoading: false,
    };
  }

  
  if (action.type === POST_ACADEMIC_SESSION_STATUS_UPDATE) {

    const list = [...state.list];
    const index = list.findIndex((item) => item.id === action.payload.id);
    list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

    return {
        ...state,
        list: list,
        showLoading: false,
    };
}

  return state;
}
