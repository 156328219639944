import swal from "sweetalert";
import {
  uploadSchool,
  getSchool,
  updateSchool,
  removeSchool,
  addNewSchool,
} from "../../services/SchoolServices";

import {
  SCHOOL_LOADING,
  GET_SCHOOL_DETAILS,
  REMOVE_SCHOOL_DETAILS,
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";

export function uploadSchoolData(excel, history) {
  return (dispatch) => {
    uploadSchool(excel)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
        }else if(response.data.status == 400){
          utility.showError(response.data.msg)
          dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getSchoolDetails(page, board,region,pincode, school_name, order_name, order_date_modified, history) {
  return (dispatch) => {
   
    getSchool(page, board,region,pincode, school_name, order_name, order_date_modified)
      .then((response) => {
        if (response.data.status == 200) {
          //utility.showSuccess(response.data.msg);
          dispatch(getSchoolAction(response.data));
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateSchoolDetails(
  id,
  region,
  pincode,
  board,
  schoolName,
  schoolAdd,
  website,
  contactNumber,
  alternateNumber,
  HOI,
  enrollmentDate,
  affiliationNumber,
  affilitationStart,
  affiliationEnd,
  schoolStatus,
  foundationYear,
  academicYear,
  history
) {
  
  return (dispatch) => {
    updateSchool(
      id,
      region,
      pincode,
      board,
      schoolName,
      schoolAdd,
      website,
      contactNumber,
      alternateNumber,
      HOI,
      enrollmentDate,
      affiliationNumber,
      affilitationStart,
      affiliationEnd,
      schoolStatus,
      foundationYear,
      academicYear
    )
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          history.push("/school-list");
        }else{
          utility.showError(response.data.msg)
        }
        dispatch(globalLoadingAction(false))
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function removeSchoolData(id,page,board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, history) {
  return (dispatch) => {
    removeSchool(id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getSchoolDetails(page,board, region, pincode, schoolName, nameIsSort, modifieDateIsSort,history));
          dispatch(removeSchoolAction(id));
          swal(response.data.msg, { icon: "success" });
        }
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function addNewSchoolData(
  region,
  pincode,
  board,
  school_name,
  school_address,
  website,
  contact_number,
  alternate_contact_number,
  name_of_head,
  email,
  enrollment_date,
  affiliation_no,
  affiliation_period_from,
  affiliation_period_to,
  school_status,
  academic_year,
  foundation_year,
  history
) {
  
  return (dispatch) => {
    addNewSchool(
      region,
      pincode,
      board,
      school_name,
      school_address,
      website,
      contact_number,
      alternate_contact_number,
      name_of_head,
      email,
      enrollment_date,
      affiliation_no,
      affiliation_period_from,
      affiliation_period_to,
      school_status,
      academic_year,
      foundation_year
    )
      .then((response) => {
        // console.log(response);
        if (response.data.status == 200) {
          dispatch(globalLoadingAction(false));

          utility.showSuccess(response.data.msg);
          history.push("/school-list");
        }else{
          utility.showError(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getSchoolAction(data) {
  return {
    type: GET_SCHOOL_DETAILS,
    payload: data,
  };
}

export function removeSchoolAction(data) {
  return {
    type: REMOVE_SCHOOL_DETAILS,
    payload: data,
  };
}

export function loadingSchoolAction(status) {
  return {
    type: SCHOOL_LOADING,
    payload: status,
  };
}
