import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  validateEmail,
  validatePhone,
  validateName,
  inputNumberOnly,
  inputAlphabetOnly
} from "../../../utility/Utility";
import {
  getUserTypeData,
  addAdminData,
  addDeveloperData,
  getDistrictData,
  getStateData,
} from "../../../store/actions/UserAction";
import {
  getPermisstionData,
} from "../../../store/actions/PermissionAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getRolesData, createUsersDropdownDetails } from "../../../store/actions/UserAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getSubjectsByBoardsDetails } from "../../../store/actions/SujectActions";
import Select from "react-select";

const CreateUser = (props) => {
  const dispatch = useDispatch();
  const DOBRef = useRef();
  const enrollmentRef = useRef();

  let errorsObj = {
    type: "",
    fName: "",
    mName: "",
    lName: "",
    dob: "",
    gender: "",
    number: "",
    email: "",
    house: "",
    location: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    panNo: "",
    aadhar: "",
    qualification: "",
    experience: "",
    subjectPre: "",
    boardPre: "",
    classPre: "",
    timePre: "",
    language: "",
    knowleage: "",
    school: "",
    incharge: "",
    board: "",
    standard: "",
    academic: "",
    enrollment: "",
    rollTime: "",
    menus: "",
    RollId: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [houseName, setHouseName] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [panNo, setPanNo] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [subjectPreference, setSubjectPreference] = useState("");
  const [boardMultipleId, setBoardMultipleId] = useState([]);
  const [boardPreference, setBoardPreference] = useState("");
  const [classPreference, setClassPreference] = useState("");
  const [timeSlotPreference, setTimeSlotPreference] = useState("");
  const [language, setLanguage] = useState("");
  const [computerKnowledge, setComputerKnowledge] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [inchargeName, setInchargeName] = useState("");
  const [board, setBoard] = useState("");
  const [standard, setStandard] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [enrollment, setEnrollment] = useState("");
  const [rollTime, setRollTime] = useState("");
  const [RollId, setRollId] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const chackbox = document.querySelectorAll(".form-check input");

  const [subjectOptionsByBoardId, setSubjectOptionsByBoardId] = useState([]);
  const [subjectOptionsByBoardMultiId, setSubjectOptionsByBoardMultiId] =
    useState([]);

  // const [subjectOptions, setSubjectOptions] = useState([]);

  const options = props.boardList.map((item) => ({
    label: item.name,
    value: item.name,
    id: item.id,
  }));

  /* const subjectOptions = 
  if(props.getSubjectsByBoards != '')}
  props.getSubjectsByBoards.map((item) => ({
    label: item.name,
    value: item.name,
    id: item.id
  })); */

  useEffect(() => {
    dispatch(getRolesData());
    dispatch(getClassData(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getSubjectsByBoardsDetails(0));
    if (props.userTypeList.length == 0) {
      dispatch(globalLoadingAction(true));
      dispatch(getUserTypeData(props.history));
    }
    dispatch(getPermisstionData(true));
    dispatch(getStateData());
    dispatch(createUsersDropdownDetails());
  }, []);

  useEffect(() => {

    if (state !== "") {
      dispatch(globalLoadingAction(true));
      dispatch(getDistrictData(state.split("^")[0]));
    }
  }, [state]);

  useEffect(() => {
    let subjectOptions = [];
    if (props.getSubjectsByBoards.status == 200) {
      // console.log("@@@--", props.getSubjectsByBoards.data)
      subjectOptions = props.getSubjectsByBoards.data.map((item) => ({
        label: item.name + "(" + item.board_name + ")",
        // label: item.name,
        value: item.name,
        subject_code: item.subject_code,
        id: item.id,
      }));
    }
    // console.log("^^^^^^", subjectOptions)
    setSubjectOptionsByBoardId(subjectOptions);
  }, [props.getSubjectsByBoards]);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (userType === "") {
      errorObj.type = "User Type is Required";
      error = true;
    }
    if (firstName === "") {
      errorObj.fName = "First Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(firstName)) {
      errorObj.fName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(firstName)) {
      errorObj.fName = "Space are not allow";
      error = true;
    } else if (!/^.{2,20}$/.test(firstName)) {
      errorObj.fName = "Minimum 2 and maximum 20 characters are allow";
      error = true;
    }
    if (middleName !== "" && !/^[a-zA-Z\s]+$/.test(middleName)) {
      errorObj.mName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(middleName)) {
      errorObj.mName = "Space are not allow";
    }
    if (lastName === "") {
      errorObj.lName = "Last Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(lastName)) {
      errorObj.lName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(lastName)) {
      errorObj.lName = "Space are not allow";
      error = true;
    } else if (!/^.{2,20}$/.test(lastName)) {
      errorObj.lName = "Minimum 2 and maximum 20 characters are allow";
      error = true;
    }
    if (dob === "") {
      errorObj.dob = "Date of Birth is Required";
      error = true;
    }
    if (gender === "") {
      errorObj.gender = "Gender is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    } else {
      if (validateEmail(email) === true) {
        errorObj.email = "Invalid Email";
        error = true;
      }
    }
    if (number === "") {
      errorObj.number = "Contact Number is Required";
      error = true;
    } else {
      if (validatePhone(number) === false) {
        errorObj.number = "Invalid Number";
        error = true;
      }
    }
    if (houseName === "") {
      errorObj.house = "House / Flat Name is Required";
      error = true;
    } else if (/^\s|\s$/.test(houseName)) {
      errorObj.house = "only space and end space are not allow";
      error = true;
    }
    if (location === "") {
      errorObj.location = "Area / Location is Required";
      error = true;
    } else if (/^\s|\s$/.test(location)) {
      errorObj.location = "only space and end space are not allow";
      error = true;
    }
    if (city === "") {
      errorObj.city = "City / Town / Village is Required";
      error = true;
    } else if (/^\s|\s$/.test(city)) {
      errorObj.city = "only space and end space are not allow";
      error = true;
    }
    if (district === "") {
      errorObj.district = "District is Required";
      error = true;
    }
    if (state === "") {
      errorObj.state = "State is Required";
      error = true;
    }

    if (pincode === "" || !/^\d{6}$/.test(pincode)) {
      errorObj.pincode = "A valid pin Code is Required";
      error = true;
    }
    if (panNo === "") {
      errorObj.panNo = "Pan Number is Required";
      error = true;
    } else if (!/^[A-Z]{5}\d{4}[A-Z]$/.test(panNo)) {
      errorObj.panNo = "Pan Number is Invalid";
      error = true;
    }
    if (aadhar === "") {
      errorObj.aadhar = "Aadhaar no. is Required";
      error = true;
    } else if (!/^\d{12}$/.test(aadhar)) {
      errorObj.aadhar = "Aadhaar no. is Invalid";
      error = true;
    }
    if (qualification === "") {
      errorObj.qualification = "Highest Qualification is Required";
      error = true;
    } else if (/^\s|\s$/.test(qualification)) {
      errorObj.qualification = "only space and end space are not allow";
      error = true;
    }
    if (experience === "") {
      errorObj.experience = "Experience is Required";
      error = true;
    } else if (/^\s|\s$/.test(experience)) {
      errorObj.experience = "only space and end space are not allow";
      error = true;
    }
    if (subjectPreference === "" && userType != 1 && userType != 7) {
      errorObj.subjectPre = "Subject Preference is Required";
      error = true;
    } else if (/^\s|\s$/.test(subjectPreference)) {
      errorObj.subjectPre = "only space and end space are not allow";
      error = true;
    }
    /* if (boardPreference === "" && userType != 1 && userType != 7) {
      errorObj.boardPre = "Board Preference is Required";
      error = true;
    } */
    if (boardMultipleId.length === 0 && userType != 1 && userType != 7) {
      errorObj.boardPre = "Board Preference is Required";
      error = true;
    }
    if (classPreference === "" && userType != 1 && userType != 7) {
      errorObj.classPre = "Class Preference is Required";
      error = true;
    } else if (/^\s|\s$/.test(classPreference)) {
      errorObj.classPre = "only space and end space are not allow";
      error = true;
    }
    if (timeSlotPreference === "" && userType != 1 && userType != 7) {
      errorObj.timePre = "Time Slot Preference is Required";
      error = true;
    } else if (/^\s|\s$/.test(timeSlotPreference)) {
      errorObj.timePre = "only space and end space are not allow";
      error = true;
    }
    if (language === "" && userType != 1 && userType != 7) {
      errorObj.language = "Language of communication is Required";
      error = true;
    } else if (/^\s|\s$/.test(language)) {
      errorObj.language = "only space and end space are not allow";
      error = true;
    }
    if (computerKnowledge === "") {
      errorObj.knowleage = "Computer knowledge is Required";
      error = true;
    } else if (/^\s|\s$/.test(computerKnowledge)) {
      errorObj.knowleage = "only space and end space are not allow";
      error = true;
    }
    if (schoolName === "" && userType == 7 && userType == 8) {
      errorObj.school = "School Name is Required";
      error = true;
    }
    if (inchargeName === "" && userType == 7) {
      errorObj.incharge = "Name of the Incharge is Required";
      error = true;
    }
    if (board === "" && userType == 7) {
      errorObj.board = "Board name is Required";
      error = true;
    }
    if (standard === "" && userType == 7) {
      errorObj.standard = "Standard Name is Required";
      error = true;
    }
    if (academicYear === "") {
      errorObj.academic = "Academic Year is Required";
      error = true;
    } else if (!/^\d{4}$/.test(academicYear)) {
      errorObj.academic = "Year should be 4 digit number";
      error = true;
    }
    if (enrollment === "") {
      errorObj.enrollment = "Date of Enrollment is Required";
      error = true;
    }
    if (rollTime === "" && userType != 1 && userType != 8) {
      errorObj.rollTime = "The Roll - Full Time/Contractual is Required";
      error = true;
    } else if (/^\s|\s$/.test(rollTime)) {
      errorObj.rollTime = "only space and end space are not allow";
      error = true;
    }
    if (RollId == "" && (userType == 1 || userType == 5 || userType == 6)) {
      errorObj.RollId = "Role is Required";
      error = true;
    }
    // if (permissionList.length === 0) {
    //   errorObj.menus = "Menus permissions is Required";
    //   error = true;
    // }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));

    if (parseInt(userType) == 1) {
      //   console.log("user type 1 block")
      dispatch(
        addAdminData(
          parseInt(userType),
          firstName,
          middleName,
          lastName,
          moment(dob).format("MM/DD/YYYY"),
          gender,
          parseInt(number),
          email,
          houseName,
          location,
          city,
          district,
          state?.split("^")[1],
          parseInt(pincode),
          panNo,
          aadhar,
          qualification,
          experience,
          computerKnowledge,
          academicYear,
          moment(enrollment).format("MM/DD/YYYY"),
          permissionList,
          RollId,
          props.history
        )
      );
    } else {
      //  console.log("user type not 1 block")
      dispatch(
        addDeveloperData(
          parseInt(userType),
          firstName,
          middleName,
          lastName,
          moment(dob).format("MM/DD/YYYY"),
          gender,
          parseInt(number),
          email,
          houseName,
          location,
          city,
          district,
          state?.split("^")[1],
          state?.split("^")[0],
          parseInt(pincode),
          parseInt(userType) == 7 ? "" : panNo,
          aadhar,
          parseInt(userType) == 7 ? "" : qualification,
          parseInt(userType) == 7 ? "" : experience,
          parseInt(userType) == 7 ? "" : subjectPreference,
          parseInt(userType) == 7 ? "" : boardPreference,
          parseInt(userType) == 7 ? "" : classPreference,
          parseInt(userType) == 7 ? "" : timeSlotPreference,
          language,
          computerKnowledge,
          academicYear,
          moment(enrollment).format("MM/DD/YYYY"),
          parseInt(userType) == 8 ? "" : rollTime,
          parseInt(userType) == 7 ? board : "",
          parseInt(userType) == 7 ? standard : "",
          parseInt(userType) == 7 && parseInt(userType) == 8 ? schoolName : "",
          parseInt(userType) == 7 ? inchargeName : "",
          "",
          props.history
        )
      );
    }

    setUserType("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setDob("");
    setGender("");
    setNumber("");
    setEmail("");
    setHouseName("");
    setLocation("");
    setCity("");
    setDistrict("");
    setState("");
    setPincode("");
    setPanNo("");
    setAadhar("");
    setQualification("");
    setExperience("");
    setSubjectPreference("");
    setBoardPreference("");
    setClassPreference("");
    setTimeSlotPreference("");
    setLanguage("");
    setComputerKnowledge("");
    setSchoolName("");
    setInchargeName("");
    setBoard("");
    setStandard("");
    setAcademicYear("");
    setEnrollment("");
    setRollTime("");
    setBoardMultipleId([]);
    setSubjectOptionsByBoardMultiId([]);
    chackbox.forEach((val, index) => {
      chackbox[index].checked = false;
    });
  }

  /* contact number restriction */
  const onNumberHandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setNumber(e.target.value);
    }
  };

  const onMainPermissionHandler = (event, item) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    if (event.target.checked) {
      arry.push(item.id);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          arry.push(dataArray[i].id);
          document.querySelector(`#check${dataArray[i].id}`).checked = true;
        }
      }
    } else {
      const index = arry.findIndex((val) => val === item.id);
      arry.splice(index, 1);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          const chilsIndex = arry.findIndex((val) => val === dataArray[i].id);
          arry.splice(chilsIndex, 1);
          document.querySelector(`#check${dataArray[i].id}`).checked = false;
        }
      }
    }

    setPermissionList(arry);
  };

  const onSubPermissionHandler = (event, item, data) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    let parentId = arry.includes(item.id);

    if (event.target.checked) {
      if (parentId) {
        arry.push(data.id);
      } else {
        document.querySelector(`#check${item.id}`).checked = true;
        arry.push(item.id, data.id);
      }
    } else {
      let ins = null;
      const subIndex = arry.findIndex((val) => val === data.id);
      arry.splice(subIndex, 1);

      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          let removeId = arry.includes(dataArray[i].id);
          if (removeId) {
            ins = true;
            break;
          }
        }
      }
      if (!ins) {
        const parentIndex = arry.findIndex((val) => val === item.id);
        arry.splice(parentIndex, 1);
        document.querySelector(`#check${item.id}`).checked = false;
      }
    }

    setPermissionList(arry);
  };

  /* change board */
  const boardPreferenceHandler = (data) => {
    // setBoardPreference(data)
    setBoardMultipleId(data);
    
    let boardId = [];
    let addBoardId = null;
    for (let i = 0; i < data.length; i++) {
      addBoardId = data[i].id;
      boardId.push(addBoardId);
    }
    // console.log("$$$$---", boardId.toString())
    setBoardPreference(boardId);
    if (boardId != "") {
      dispatch(getSubjectsByBoardsDetails(boardId.toString()));
    }
    if(data == ''){
      setSubjectPreference("");
      setSubjectOptionsByBoardMultiId([])
    }
  };

  /* change subject */
  const subjectPreferenceHandler = (data) => {
    setSubjectOptionsByBoardMultiId(data);
    let subjectId = [];
    let addSubjectId = null;
    for (let i = 0; i < data.length; i++) {
      addSubjectId = data[i].id;
      subjectId.push(addSubjectId);
    }
    setSubjectPreference(subjectId);
    // console.log("$$$$---", boardId.toString())
    // setBoardPreference(boardId)
  };

  const onStateHandler = (val) => {
    setState(val);
    setDistrict('')
  }



  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  User Type
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={userType}
                                  onChange={(e) => setUserType(e.target.value)}
                                >
                                  <option value="">Select user type</option>
                                  {props.userTypeList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.user_type_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.type}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  First Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.fName && (
                                <div className="text-danger fs-12">
                                  {errors.fName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Middle Name(Optional)</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the middle name"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.mName && (
                                <div className="text-danger fs-12">
                                  {errors.mName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.lName && (
                                <div className="text-danger fs-12">
                                  {errors.lName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  DOB<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={DOBRef}
                                  selected={dob}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setDob(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() => DOBRef.current.setOpen(true)}
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.dob && (
                                <div className="text-danger fs-12">
                                  {errors.dob}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Gender<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={gender}
                                  onChange={(e) => setGender(e.target.value)}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                              {errors.gender && (
                                <div className="text-danger fs-12">
                                  {errors.gender}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Contact Number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact number"
                                value={number}
                                onChange={(e) => onNumberHandler(e)}
                                maxlength="10"
                                onKeyPress={inputNumberOnly}
                              />
                              {errors.number && (
                                <div className="text-danger fs-12">
                                  {errors.number}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Email<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <div className="text-danger fs-12">
                                  {errors.email}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  State<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={state}
                                  // onChange={(e) => setState(e.target.value)}
                                  onChange={(e) => onStateHandler(e.target.value)}

                                >
                                  <option value="">Select State</option>
                                  {props.stateList.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item.id + "^" + item.state_name}
                                      >
                                        {item.state_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.state && (
                                <div className="text-danger fs-12">
                                  {errors.state}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  District<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className={`form-control form-control ${state != '' ? null : 'content_disable'}`}
                                  value={district}
                                  onChange={(e) => setDistrict(e.target.value)}
                                >
                                  <option value="">Select district</option>
                                  {props.districtList.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item.district_name}
                                      >
                                        {item.district_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.district && (
                                <div className="text-danger fs-12">
                                  {errors.district}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  City / Town / Village
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the city/town/village"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                              {errors.city && (
                                <div className="text-danger fs-12">
                                  {errors.city}
                                </div>
                              )}
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Area / Location
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the area/location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                              {errors.location && (
                                <div className="text-danger fs-12">
                                  {errors.location}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  House / Flat Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the house/flat name"
                                value={houseName}
                                onChange={(e) => setHouseName(e.target.value)}
                              />
                              {errors.house && (
                                <div className="text-danger fs-12">
                                  {errors.house}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Pincode<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pincode"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                                maxlength="6"
                                onKeyPress={inputNumberOnly}
                              />
                              {errors.pincode && (
                                <div className="text-danger fs-12">
                                  {errors.pincode}
                                </div>
                              )}
                            </div>

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Pan Number
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the pan number"
                                  value={panNo}
                                  onChange={(e) => setPanNo(e.target.value)}
                                  maxlength="10"
                                />
                                {errors.panNo && (
                                  <div className="text-danger fs-12">
                                    {errors.panNo}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Aadhaar No
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Aadhaar No"
                                value={aadhar}
                                onChange={(e) => setAadhar(e.target.value)}
                                onKeyPress={inputNumberOnly}
                              />
                              {errors.aadhar && (
                                <div className="text-danger fs-12">
                                  {errors.aadhar}
                                </div>
                              )}
                            </div>

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Highest Qualification
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the highest qualification"
                                  value={qualification}
                                  onChange={(e) =>
                                    setQualification(e.target.value)
                                  }
                                />
                                {errors.qualification && (
                                  <div className="text-danger fs-12">
                                    {errors.qualification}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Experience
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the experience"
                                  value={experience}
                                  onChange={(e) =>
                                    setExperience(e.target.value)
                                  }
                                />
                                {errors.experience && (
                                  <div className="text-danger fs-12">
                                    {errors.experience}
                                  </div>
                                )}
                              </div>
                            )}

                            {/* {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Board Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the board preference"
                                  value={boardPreference}
                                  onChange={(e) =>
                                    setBoardPreference(e.target.value)
                                  }
                                />
                                {errors.boardPre && (
                                  <div className="text-danger fs-12">
                                    {errors.boardPre}
                                  </div>
                                )}
                              </div>
                            )} */}
                            
                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Board Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <Select
                                    // className="form-control form-control"
                                    isMulti
                                    options={options}
                                    value={boardMultipleId}
                                    // onChange={(data) => setBoardPreference(data)}
                                    onChange={(data) =>
                                      boardPreferenceHandler(data)
                                    }
                                  >
                                    {/* <option value="">Select Board</option> */}
                                  </Select>
                                  {/* <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={boardPreference}
                                    onChange={(e) => setBoardPreference(e.target.value)}
                                  >
                                    <option value="">Select Board</option>
                                    {props.boardList.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.id}
                                        >
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select> */}
                                </div>
                                {boardMultipleId.length === 0 && errors.boardPre && (
                                  <div className="text-danger fs-12">
                                    {errors.boardPre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 || userType == 7 ? null : (
                              boardMultipleId == "" ? (
                                <div className="form-group mb-3 col-md-6 content_disable">
                                  <label className="mb-2">
                                    <strong>
                                      Subject Preference
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </label>
                                  <input
                                    className="form-control"
                                    placeholder="Enter the subject preference"
                                    value={subjectPreference}
                                    onChange={(e) =>
                                      setSubjectPreference(e.target.value)
                                    }
                                    // disabled
                                  />
                                </div>
                              ) : (
                                <div className="form-group mb-3 col-md-6">
                                  <label className="mb-2">
                                    <strong>
                                      Subject Preference
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </label>

                                  <Select
                                    isMulti
                                    options={subjectOptionsByBoardId}
                                    value={subjectOptionsByBoardMultiId}
                                    onChange={(data) =>
                                      subjectPreferenceHandler(data)
                                    }
                                  ></Select>

                                  {errors.subjectPre && (
                                    <div className="text-danger fs-12">
                                      {errors.subjectPre}
                                    </div>
                                  )}
                                </div>
                              )
                            )}

                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Class Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the class preference"
                                  value={classPreference}
                                  onChange={(e) =>
                                    setClassPreference(e.target.value)
                                  }
                                />
                                {errors.classPre && (
                                  <div className="text-danger fs-12">
                                    {errors.classPre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 || userType == 7 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Time Slot Preference
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                {/* <input
                                  className="form-control"
                                  placeholder="Enter the time slot preference"
                                  value={timeSlotPreference}
                                  onChange={(e) =>
                                    setTimeSlotPreference(e.target.value)
                                  }
                                /> */}
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={timeSlotPreference}
                                    // onChange={(e) => setDistrict(e.target.value)}
                                    onChange={(e) =>
                                      setTimeSlotPreference(e.target.value)
                                    }
                                  >
                                    <option value="">Select time slot</option>
                                    {props.createUsersDropdownData.time_slot?.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.name}
                                        >
                                          {item.value}
                                        </option>
                                      );
                                    })}

                                  </select>
                                </div>
                                {errors.timePre && (
                                  <div className="text-danger fs-12">
                                    {errors.timePre}
                                  </div>
                                )}
                              </div>
                            )}

                            {userType == 1 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Language of Communication
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the language"
                                  value={language}
                                  onChange={(e) => setLanguage(e.target.value)}
                                />
                                {errors.language && (
                                  <div className="text-danger fs-12">
                                    {errors.language}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Computer Knowledge
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              {/* <input
                                className="form-control"
                                placeholder="Enter the knowledge"
                                value={computerKnowledge}
                                onChange={(e) =>
                                  setComputerKnowledge(e.target.value)
                                }
                              /> */}
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={computerKnowledge}
                                  onChange={(e) =>
                                    setComputerKnowledge(e.target.value)
                                  }
                                >
                                  <option value="">Select computer knowledge</option>
                                  {props?.createUsersDropdownData?.computer_knowledge?.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item}
                                      >
                                        {item}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.knowleage && (
                                <div className="text-danger fs-12">
                                  {errors.knowleage}
                                </div>
                              )}
                            </div>

                            {userType == 7 || userType == 8 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Name of School
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the school"
                                  value={schoolName}
                                  onChange={(e) =>
                                    setSchoolName(e.target.value)
                                  }
                                />
                                {errors.school && (
                                  <div className="text-danger fs-12">
                                    {errors.school}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Name of Incharge
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the name of incharge"
                                  value={inchargeName}
                                  onChange={(e) =>
                                    setInchargeName(e.target.value)
                                  }
                                />
                                {errors.incharge && (
                                  <div className="text-danger fs-12">
                                    {errors.incharge}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Board<span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the board"
                                  value={board}
                                  onChange={(e) => setBoard(e.target.value)}
                                />
                                {errors.board && (
                                  <div className="text-danger fs-12">
                                    {errors.board}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            {userType == 7 ? (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Standard
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the standard"
                                  value={standard}
                                  onChange={(e) => setStandard(e.target.value)}
                                />
                                {errors.standard && (
                                  <div className="text-danger fs-12">
                                    {errors.standard}
                                  </div>
                                )}
                              </div>
                            ) : null}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Academic Year
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Academic year"
                                value={academicYear}
                                onChange={(e) =>
                                  setAcademicYear(e.target.value)
                                }
                              />
                              {errors.academic && (
                                <div className="text-danger fs-12">
                                  {errors.academic}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Date of Enrollment/Enlistment
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={enrollmentRef}
                                  selected={enrollment}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setEnrollment(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    enrollmentRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.enrollment && (
                                <div className="text-danger fs-12">
                                  {errors.enrollment}
                                </div>
                              )}
                            </div>

                            {userType == 1 || userType == 8 ? null : (
                              <div className="form-group mb-3 col-md-6">
                                <label className="mb-2">
                                  <strong>
                                    Roll-Full Time/Contractual
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                {/* <input
                                  className="form-control"
                                  placeholder="Enter the roll"
                                  value={rollTime}
                                  onChange={(e) => setRollTime(e.target.value)}
                                /> */}
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={rollTime}
                                    onChange={(e) => setRollTime(e.target.value)}
                                  >
                                    <option value="">Select Roll</option>
                                    {props?.createUsersDropdownData?.roll?.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.rollTime && (
                                  <div className="text-danger fs-12">
                                    {errors.rollTime}
                                  </div>
                                )}
                              </div>
                            )}

                            {(parseInt(userType) == 1 ||
                              parseInt(userType) == 5 ||
                              parseInt(userType) == 6) && (
                                <div className="form-group mb-3 col-md-6">
                                  <label className="mb-2">
                                    <strong>
                                      Role<span className="text-danger">*</span>
                                    </strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow">
                                      <i className="bi bi-caret-down-fill"></i>
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={RollId}
                                      onChange={(e) => setRollId(e.target.value)}
                                    >
                                      <option value="">Select Role</option>
                                      {props.roleList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.role_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.RollId && (
                                    <div className="text-danger fs-12">
                                      {errors.RollId}
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {

  return {
    showLoading: state.user.showLoading,
    userTypeList: state.user.typeList,
    permissionList: state.permission.list,
    districtList: state.Location.Districtlist,
    stateList: state.Location.StateList,
    roleList: state.user.rolesList,
    classList: state.class.list,
    boardList: state.board.list,
    getSubjectsByBoards: state.subject.getSubjectsByBoards,
    createUsersDropdownData: state.user.createUsersDropdownData,
  };
};

export default connect(mapStateToProps)(CreateUser);
