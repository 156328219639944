import swal from "sweetalert";
import { MisRegisterUser } from "../../services/MisRegUserServices";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { MisUserActivity, PaymentTransactionDetails } from "../../services/UserActivityServices";
import { GET_SUBSCRIBED_USER_ACTIVITY, PAYMENT_TRANSACTION_DETAILS } from "../constants";

export function getMisUserActivityData(
  board,className,services,complete_percentage
  ) {
    return (dispatch) => {
        MisUserActivity(
          board,className,services,complete_percentage
      )
        .then((response) => {
          if (response.data.status == 200) {
         
           dispatch(getMisUserActivityAction(response.data.data))
          } 
          dispatch(globalLoadingAction(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(globalLoadingAction(false));
          utility.showError(error.response.data);
        });
    };
  }

  export function PaymentTransactionData(page,trans_id,order_id,student_id, trans_status, mobile ) {
      return (dispatch) => {
        PaymentTransactionDetails(page,trans_id,order_id,student_id, trans_status, mobile)
          .then((response) => {
            if (response.data.status == 200) {
             dispatch(getPaymentTransactionAction(response.data))
            } 
            dispatch(globalLoadingAction(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(globalLoadingAction(false));
            utility.showError(error.response.data);
          });
      };
    }

  export function getMisUserActivityAction(data){
      return{
        type:GET_SUBSCRIBED_USER_ACTIVITY,
        payload:data,
      }
  }

  export function getPaymentTransactionAction(data){
    return{
      type:PAYMENT_TRANSACTION_DETAILS,
      payload:data,
    }
}

  