import { GET_FEEDBACK_QUESTION, FEEDBACK_QUESTION_DELETE } from "../constants";

const initialState = {
  list: [],
  feedback_delete:0,
  showLoading: false,
};

export function FeedbackReducer(state = initialState, action) { 

  if (action.type === GET_FEEDBACK_QUESTION) {
    return {
      ...state,
      list: action.payload,
      showLoading: false,
    };
  }

  if (action.type === FEEDBACK_QUESTION_DELETE) {
    return {
      ...state,
      feedback_delete: action.payload,
      showLoading: false,
    };
  }

  return state;
}
