import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addSubject(exam_category_id, exam_type_id, exam_subtype_id, board_id, name, group_exist, group_subjects, subject_color_code, subject_image, library_image) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        name,
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        board_id,
        group_exist,
        group_subjects,
        subject_color_code,
        subject_image,
        library_image
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateSubject(recid, exam_category_id, exam_type_id, exam_subtype_id, board_id, name, subject_code, group_exist, group_subjects, subject_color_code, subject_image,library_image) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        board_id,
        name,
        subject_code,
        group_exist,
        group_subjects,
        subject_color_code,
        subject_image,
        library_image
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getSubjectList(page, board_id, exam_type_id, exam_subtype_id, category_id,group_exist) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        board_id,
        exam_type_id,
        exam_subtype_id,
        category_id,
        group_exist
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getSubjectsFiltter() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUBJECT_FILTTER_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}

export function getSubjectsFiltterSubscriptionLibrary(board_id,exam_type_id,category_id,classno) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

   let  postData={
        board_id,
        exam_type_id,
        category_id,
        classno
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUBJECT_FILTER_E_SUB,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data:postData
    });
}

export function getNonGroupSubjects(exam_category_id, exam_type_id, exam_subtype_id, board_id, is_group) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    
    const postData = {
        exam_category_id,
        exam_type_id,
        exam_subtype_id,
        board_id,
        is_group:is_group == 1 ? 1 : is_group == 3 ? 3 : '',
    };
    
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_NON_GROUP_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getboardClassBySubjects(board_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        board_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_BOARD_CLASS_BY_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateStatusSubject(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };
    console.log('jjjjj', postData);
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_STATUS_SUBJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeSubject(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_SUBJECT_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getSubjectsByBoards(board_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        board_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SUBJECTS_BY_BOARDS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getSingleSubjectList(page, board_id, exam_type_id, exam_subtype_id, category_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        page,
        board_id,
        exam_type_id,
        exam_subtype_id,
        category_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_GET_SINGLE_SUBJECT_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}
