import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import {
  validateChapter,
  validateTextOnly,
  validateSubheading,
} from "../../../utility/Utility";
import {
  getBranchDetails,
  branchLoading,
} from "../../../store/actions//BranchActions";
import {
  addChaptersData,
  loadingChaptersAction,
} from "../../../store/actions/ChapterAction";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getExamSubType,
  loadingSubTypeAction,
} from "../../../store/actions/ExamSubTypeAction";
import {
  getNonGroupSubjectsList,
  subjectLoading,
  getNonGroupSubjectsAction,
} from "../../../store/actions/SujectActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import * as utility from "../../../utility/Utility";

const CreateChapter = (props) => {
  let errorsObj = {
    category: "",
    board: "",
    type: "",
    subType: "",
    className: "",
    name: "",
    subject: "",
    subheading: "",
    chapterNo: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [typeFullString, setTypeFullString] = useState("");
  const [isClass, seIsClass] = useState("");
  const [subject, setSubject] = useState("");
  const [className, setClassName] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [subheading, setSubheading] = useState("");
  const [chapterNo, setChapterNo] = useState("");
  const [subType, setSubType] = useState("");
  const [isSubType, setIsSubType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getExamCategory(props.history));
    dispatch(getBranchDetails(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getExamType(props.history));
    dispatch(getClassData(props.history));
    dispatch(getExamSubType(props.history));
  }, []);

  /* on form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Category name is Required";
      error = true;
    }
    if (board === "" && category == 1) {
      errorObj.board = "Board name is Required";
      error = true;
    }
    if (type === "" && category != 1) {
      errorObj.type = "Exam type name is Required";
      error = true;
    }
    if (subType === "" && type == 1) {
      errorObj.subType = "Exam sub type is Required";
      error = true;
    }
    if (subject === "") {
      errorObj.subject = "Subject name is Required";
      error = true;
    }
    if (className === "" && (type == 2 || category == 1)) {
      errorObj.className = "class name is Required";
      error = true;
    }
    if (chapterName === "") {
      errorObj.name = "Chapter number is Required";
      error = true;
    } else if (!/^[a-zA-Z0-9\s]+$/.test(chapterName)) {
      errorObj.name = "Only alphabetic characters are allow";
      error = true;
    }
    if (subheading === "") {
      errorObj.subheading = "Chapter name is Required";
      error = true;
    } else if (!/^[a-zA-Z0-9\s-]+$/.test(subheading)) {
      errorObj.subheading = "Only alphabetic characters and '-' are allow";
      error = true;
    }
    if (chapterNo === "") {
      errorObj.chapterNo = "Short code of ch no is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      addChaptersData(
        parseInt(category),
        parseInt(category) == 1 ? board : type.split("^")[0],
        parseInt(type) == 1 ? subType : 0,
        parseInt(category) == 1 || parseInt(type.split("^")[1]) == 1
          ? className
          : 0,
        chapterName.trim(),
        subject,
        subheading,
        chapterNo,
        props.history
      )
    );

    setCategory("");
    setBoard("");
    setType("");
    setClassName("");
    setChapterName("");
    setSubject("");
    setSubheading("");
    setChapterNo("");
    setSubType("");
  }

  /* get subject list */
  const getSubjectHandler = () => {
    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && type.split("^")[0] == 1 && subType) {
      /* type == 1 */
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type),
          parseInt(subType),
          0,
          props.history
        )
      );
    } else if (category == 2 && type.split("^")[0] != 1) {
      /* type != 1 */
      dispatch(subjectLoading(true));
      // dispatch(getNonGroupSubjectsList(parseInt(category), parseInt(type), 0, 0, props.history));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type.split("^")[0]),
          0,
          0,
          props.history
        )
      );
    }
  };

  /* on change category */
  const onChangeCategoryHanler = (val) => {
    setCategory(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* on change board */
  const onChangeBoadHanler = (val) => {
    setBoard(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* on change type */
  const onChangeTypeHanler = (val) => {
    console.log("0000000", val);
    setType(val);
    // setType(val.split("^")[0]);
    setTypeFullString(val);
    seIsClass(val.split("^")[1]);
    dispatch(getNonGroupSubjectsAction([]));
    setIsSubType(val.split("^")[0]);
  };

  /* on change sub type */
  const onChangeSubTypeHanler = (val) => {
    setSubType(val);
    setTypeFullString(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  return (
    <>
      <div className="row">
        {/* {console.log('subjectList', props.subjectList)} */}
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3"></div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Exam Category
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                //onChange={(e) => setCategory(e.target.value)}
                                onChange={(e) =>
                                  onChangeCategoryHanler(e.target.value)
                                }
                              >
                                <option value="">Select Category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          {category == 1 ? (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  Board<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={board}
                                  onChange={(e) =>
                                    onChangeBoadHanler(e.target.value)
                                  }
                                >
                                  <option value="">Select Board</option>
                                  {props.boardList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.board && (
                                <div className="text-danger fs-12">
                                  {errors.board}
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Exam type
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={type}
                                    onChange={(e) =>
                                      onChangeTypeHanler(e.target.value)
                                    }
                                  >
                                    <option value="">Select Exam type</option>
                                    {props.typeList.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.id + "^" + item.is_class}
                                        >
                                          {item.type_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.type && (
                                  <div className="text-danger fs-12">
                                    {errors.type}
                                  </div>
                                )}
                              </div>

                              {/* {type == 1 ? */}
                              {isSubType == 1 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>
                                      Exam sub type
                                      <span className="text-danger">*</span>
                                    </strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow">
                                      <i className="bi bi-caret-down-fill"></i>
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={typeFullString}
                                      onChange={(e) =>
                                        onChangeSubTypeHanler(e.target.value)
                                      }
                                    >
                                      <option value="">Select Exam type</option>
                                      {props.subTypeList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.subtype_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.subType && (
                                    <div className="text-danger fs-12">
                                      {errors.subType}
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Subject name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.subjectLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={subject}
                                onClick={getSubjectHandler}
                                onChange={(e) => setSubject(e.target.value)}
                              >
                                <option value="">Select subject name</option>
                                {props.subjectList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.subject && (
                              <div className="text-danger fs-12">
                                {errors.subject}
                              </div>
                            )}
                          </div>

                          {category == 1 || isClass == 1 ? (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  Class<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={className}
                                  onChange={(e) => setClassName(e.target.value)}
                                >
                                  <option value="">Select Class</option>
                                  {props.classList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.class_no}>
                                        {item.short_code}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.className && (
                                <div className="text-danger fs-12">
                                  {errors.className}
                                </div>
                              )}
                            </div>
                          ) : null}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Chapter number
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the chapter number"
                              value={chapterName}
                              onChange={(e) => setChapterName(e.target.value)}
                            />
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Short code of ch no
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the short code of ch no."
                              value={chapterNo}
                              onChange={(e) => setChapterNo(e.target.value)}
                              onKeyDown={utility.inputAlphabetNumberOnly}
                            />
                            {errors.chapterNo && (
                              <div className="text-danger fs-12">
                                {errors.chapterNo}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Chapter name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the chapter name"
                              value={subheading}
                              onChange={(e) => setSubheading(e.target.value)}
                            />
                            {errors.subheading && (
                              <div className="text-danger fs-12">
                                {errors.subheading}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.chapter.showLoading,
    categoryList: state.category.list,
    branchList: state.branch.branchList,
    boardList: state.board.list,
    typeList: state.type.list,
    classList: state.class.list,
    subTypeList: state.subtype.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.nonGroupSubjectList,
  };
};
export default connect(mapStateToProps)(CreateChapter);
