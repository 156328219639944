import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import swal from "sweetalert";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Button,
	Pagination
} from "react-bootstrap";
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "use-debounce";
import { getStudentData, loadingUserAction, removeStudentDetails, updateStudentStatus } from '../../../store/actions/UserAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

const StudentList = (props) => {
	const CalendarRef = useRef()
	const filterRef = useRef(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { state } = useLocation();

	// const [active, setActive] = useState(state == undefined && state == 'undefined' ? 1 : state.page );
	const [active, setActive] = useState(state?.page ?? 1 );
	// const [name, setName] = useState(state == undefined ? '' : state.name );
	const [name, setName] = useState(state?.name ?? '' );
	const [nameDebounce] = useDebounce(name, 1000);
	// const [email, setEmail] = useState("");
	// const [email, setEmail] = useState(state == undefined ? '' : state.email );
	const [email, setEmail] = useState(state?.email ?? '' );
	const [emailDebounce] = useDebounce(email, 1000);
	// const [phone, setPhone] = useState("");phone
	// const [phone, setPhone] = useState(state == undefined ? '' : state.phone );
	const [phone, setPhone] = useState(state?.phone ?? '' );
	const [phoneDebounce] = useDebounce(phone, 1000);

	const [permission, setPermission] = useState("");

	// const [startDate, setStartDate] = useState("");
	const [startDate, setStartDate] = useState(state == undefined ? '' : state.startDate);
	// const [endDate, setEndDate] = useState("");
	const [endDate, setEndDate] = useState(state == undefined ? '' : state.endDate);

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));

		dispatch(getStudentData(active, name, email, phone, ((endDate == null || endDate == "") ? "" : moment(startDate).format('YYYY-MM-DD')), ((endDate == null || endDate == "") ? "" : moment(endDate).format('YYYY-MM-DD')), props.history));

	}, []);

	useEffect(() => {
		// console.log("endDate", endDate)
		// console.log("startDate", startDate)
		
		if (filterRef.current) {
			setActive(1);
			dispatch(globalLoadingAction(true));
			dispatch(
				getStudentData(1, nameDebounce, emailDebounce, phoneDebounce, ((endDate == null || endDate == "") ? "" : moment(startDate).format('YYYY-MM-DD')), moment(endDate).format('YYYY-MM-DD'), props.history)
			);
		}
	}, [nameDebounce, emailDebounce, phoneDebounce, endDate]);

	useEffect(() => {

		return () => {
			filterRef.current = false;
		}
	}, []);

	/* delete confirmation */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(loadingUserAction(true));
				if (props.list.length == 1) {
					dispatch(removeStudentDetails(i, active - 1, props.history));
					setActive(active - 1)
				} else {
					dispatch(removeStudentDetails(i, active, props.history));
				}

			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {
		dispatch(loadingUserAction(true));
		dispatch(updateStudentStatus(item, props.history));
	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getStudentData(data.selected + 1, name, email, phone, ((endDate == null || endDate == "") ? "" : moment(startDate).format('YYYY-MM-DD')), moment(endDate).format('YYYY-MM-DD'), props.history));
	}

	const onSearchHandler = () => {
		if (name !== "" || email !== "") {
			setActive(1);
			dispatch(globalLoadingAction(true));
			dispatch(getStudentData(1, name, email, phone, ((endDate == null || endDate == "") ? "" : moment(startDate).format('YYYY-MM-DD')), moment(endDate).format('YYYY-MM-DD'), props.history));
		}
	}

	/* field reset */
	const onResetHandler = () => {

		// if (name !== "" || email !== "" || startDate !== null || startDate !== "") {
		// dispatch(globalLoadingAction(true));
		// dispatch(getStudentData(1, '', '','','','', props.history));
		setActive(1);
		setName('');
		setEmail('');
		setPhone('')
		setStartDate('')
		setEndDate('')
		// }

		const stateCopy = { ...state };

		delete stateCopy.page;
		delete stateCopy.name;
		delete stateCopy.email;
		delete stateCopy.phone;
		delete stateCopy.startDate;
		delete stateCopy.endDate;
		props.history.replace({ state: stateCopy });

		dispatch(getStudentData(1, '', '', '', '', '', props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 9) {
				setPermission(item);
			}
		});
	};

	/* start date handeler */
	const onDateHandler = (date) => {
		setStartDate(date[0])
		date[1] !== null && setActive(1)
		setEndDate(date[1])
		filterRef.current = true
	}

	/* change name  */
	const nameChangeHandeler = (value) => {
		setName(value);
		filterRef.current = true
	}

	/* change email */
	const emailChangeHandeler = (value) => {
		setEmail(value)
		filterRef.current = true
	}

	/* change name */
	const phoneChangeHandeler = (value) => {
		setPhone(value)
		filterRef.current = true
	}

	/* clear name */
	const onClearHandlerName = () => {
		setName('');
		setActive(1);
	}

	/* clear email */
	const onClearHandlerEmail = () => {
		setEmail('');
		setActive(1);
	}

	/* clear phone */
	const onClearHandlerPhone = () => {
		setPhone('');
		setActive(1);
	}

	/* clear all */
	const onClearHandlerDate = () => {
		setStartDate('');
		setEndDate('');
		setActive(1);
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="add-Question-list-view">

								<div className="add-filter-group flex-nowrap student_list">
									<div className="col-sm-3 px-1 clear-question-type">
										<input
											className="form-control filter-form-control me-2"
											placeholder="Student name"
											value={name}
											// onChange={(e) => setName(e.target.value)}
											onChange={(e) => nameChangeHandeler(e.target.value)}
											onKeyPress={inputAlphabetOnlyWithSpace}
										/>
										{name ? (
											<button
												onClick={onClearHandlerName}
												className="clear-date-btn"
											>
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										) : null}

									</div>
									<div className="col-sm-3 px-1 clear-question-type ">
										<input
											className="form-control filter-form-control me-2"
											placeholder="Student email"
											value={email}
											// onChange={(e) => setEmail(e.target.value)}
											onChange={(e) => emailChangeHandeler(e.target.value)}
										/>
										{email ? (
											<button
												onClick={onClearHandlerEmail}
												className="clear-date-btn"
											>
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										) : null}
									</div>
									<div className="col-sm-3 px-1 clear-question-type ">
										<input
											className="form-control filter-form-control me-2"
											placeholder="Student phone number"
											value={phone}
											// onChange={(e) => setPhone(e.target.value)}
											onChange={(e) => phoneChangeHandeler(e.target.value)}
											onKeyPress={inputNumberOnly}
											maxLength="10"
										/>
										{phone ? (
											<button
												onClick={onClearHandlerPhone}
												className="clear-date-btn"
											>
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										) : null}
									</div>
									{/* <div className="filter-btn-view">
										<button onClick={onSearchHandler} className="btn btn-danger me-2">Search</button>
										<button onClick={onResetHandler} className="btn btn-info">Reset</button>
									</div> */}{/* style={{ marginTop: '8px', height: '40px' }} */}
									<div className="col-sm-3 date-control-view clear-question-type-new" >
										<DatePicker
											ref={CalendarRef}
											dateFormat="MM/dd/yyyy"
											placeholderText="Select dates"
											onChange={onDateHandler}
											className="form-control align me-2"
											// maxDate={addMonths(new Date(), 12)}
											maxDate={new Date()}
											// minDate={new Date()}
											startDate={startDate}
											endDate={endDate}
											selectsRange
											preventOpenOnFocus={true}
											showDisabledMonthNavigation
											showMonthDropdown
											showYearDropdown
											onKeyDown={(e) => {
												e.preventDefault();
											}}
										/>
										{startDate && endDate ? (
											<button
												onClick={onClearHandlerDate}
												className="clear-date-btn btn_class"
											>
												<i className="fa fa-times" aria-hidden="true"></i>
											</button>
										) : null}
									</div>
									<div >
										<button onClick={onResetHandler} className={`btn btn-secondary ${name == '' && email == '' && phone == '' && startDate == '' && endDate == '' ? 'content_disable' : null}`} style={{ marginLeft: '10px', marginTop: '5px' }}>Reset</button>
									</div>
								</div>

							</div>

							{props.list.length == 0 ?
								(
									<div className="no_data">
										<strong>No data found</strong>
									</div>
								) : (
									<>
										<Table responsive>
											<thead>
												<tr>

													<th>
														<strong>Name</strong>
													</th>

													<th>
														<strong>Email</strong>
													</th>

													<th>
														<strong>Phone No.</strong>
													</th>

													<th>
														<strong>Create Date</strong>
													</th>
													{
														permission.status == "true" && (
															<th width={120}>
																<strong>Status</strong>
															</th>
														)
													}

													{
														(permission.edit == "true" || permission.delete == "true") && (
															<th>
																<strong>Action</strong>
															</th>
														)
													}
												</tr>
											</thead>
											<tbody>
												{
													props.list.map((item, index) => {
														return <tr key={index}>
															<td>{item.fname}</td>
															<td>{item.email}</td>
															<td>{item.mobile}</td>
															<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
															{
																permission.status == "true" && (
																	<td>
																		<Button
																			disabled={props.showLoading}
																			className="btn-xs"
																			variant={item.status == 1 ? "success" : "danger"}
																			onClick={() => onUpdateStatus(item)}
																		>
																			{item.status == 1 ? "Active" : "Inactive"}
																		</Button>
																	</td>
																)
															}
															{
																(permission.edit == "true" || permission.delete == "true") && (
																	<td>
																		<div className="d-flex">

																			{
																				permission.edit == "true" && (
																					<Link
																						to={{
																							pathname: '/update-student',
																							state: { 'data': item, 'filter': { 'page': active, 'name': name, 'email': email, 'phone': phone, 'startDate': startDate, 'endDate': endDate } }
																						}}
																						className="btn btn-warning shadow btn-xs sharp me-1"
																					>
																						<i className="fas fa-pencil-alt"></i>
																					</Link>
																				)
																			}

																			{
																				permission.delete == "true" && (
																					<Button
																						className="btn btn-danger shadow btn-xs sharp"
																						onClick={() => onDeleteModal(item.id)}
																					>
																						<i className="fa fa-trash"></i>
																					</Button>
																				)
																			}

																		</div>
																	</td>
																)
															}
														</tr>
													})
												}

											</tbody>

										</Table>

										<div className="pagination-container">
											<div className="page-result">
												<p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}</p>
											</div>
											{props.totalRecord > 10 ?
												< ReactPaginate
													containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
													pageClassName='page-item'
													pageLinkClassName='page-link'
													nextLabel={<i className="la la-angle-right"></i>}
													nextClassName='page-item'
													nextLinkClassName='page-link'
													breakLabel="..."
													previousLabel={<i className="la la-angle-left"></i>}
													previousClassName='page-item'
													previousLinkClassName='page-link'
													breakClassName='page-item'
													breakLinkClassName='page-link'
													renderOnZeroPageCount={null}
													activeClassName="active"
													onPageChange={pageHandler}
													pageRangeDisplayed={3}
													pageCount={props.totalPage}
													forcePage={active - 1}
												/>
												: null}
										</div>
									</>
								)
							}




						</Card.Body>

					</Card>

				</Col>

			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.user.showLoading,
		totalPage: state.user.totalStudentPage,
		totalRecord: state.user.totalStudentRecord,
		list: state.user.studentList,
	};
};
export default connect(mapStateToProps)(StudentList);