import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";

const StateList = () => {

	const [modal, setModal] = useState(false);

	useEffect(() => {

	}, []);

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>

						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>State code</strong>
										</th>
										<th>
											<strong>State name</strong>
										</th>
										<th>
											<strong>State details</strong>
										</th>
										<th>
											<strong>Status</strong>
										</th>
										<th>
											<strong>Action</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>01</strong>
										</td>
										<td>Mr. Bobby</td>
										<td>Dr. Jackson</td>
										<td><Badge bg="success">Successful</Badge></td>
										<td>
											<div className="d-flex">
												<Link
													href="#"
													className="btn btn-warning shadow btn-xs sharp me-1"
												>
													<i className="fas fa-pencil-alt"></i>
												</Link>
												<Link
													href="#"
													className="btn btn-danger shadow btn-xs sharp"
													onClick={() => setModal(true)}
												>
													<i className="fa fa-trash"></i>
												</Link>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<strong>02</strong>
										</td>
										<td>Mr. Bobby</td>
										<td>Dr. Jackson</td>
										<td><Badge bg="danger">Canceled</Badge></td>
										<td>
											<div className="d-flex">
												<Link
													href="#"
													className="btn btn-warning shadow btn-xs sharp me-1"
												>
													<i className="fas fa-pencil-alt"></i>
												</Link>
												<Link
													href="#"
													className="btn btn-danger shadow btn-xs sharp"
													onClick={() => setModal(true)}
												>
													<i className="fa fa-trash"></i>
												</Link>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<strong>03</strong>
										</td>
										<td>Mr. Bobby</td>
										<td>Dr. Jackson</td>
										<td><Badge bg="warning">Pending</Badge></td>
										<td>
											<div className="d-flex">
												<Link
													href="#"
													className="btn btn-warning shadow btn-xs sharp me-1"
												>
													<i className="fas fa-pencil-alt"></i>
												</Link>
												<Link
													href="#"
													className="btn btn-danger shadow btn-xs sharp"
													onClick={() => setModal(true)}
												>
													<i className="fa fa-trash"></i>
												</Link>
											</div>
										</td>
									</tr>
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm"
				size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Are you sure ?</Modal.Title>
					<Button
						variant=""
						className="btn-close"
						onClick={() => setModal(false)}
					>

					</Button>
				</Modal.Header>
				<Modal.Body>You want to remove this item.</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger light"
						onClick={() => setModal(false)}
					>
						No
					</Button>
					<Button variant="primary">Yes</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}
export default StateList;