import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';


export function uploadCareer(upload_file) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const formData = new FormData();
    formData.append("upload_file", upload_file);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_CAREER_GUIDE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

export function uploadCareerGuideLine(upload_file) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const formData = new FormData();
    formData.append("upload_file", upload_file);

    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_LMS_GUIDLINE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

