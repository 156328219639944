import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getSettings() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_SETTINGS_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function updateSettings(gst_rate, scrolling_text) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    let postData = {
        gst_rate,
        scrolling_text
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_SETTINGS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData
    });

}

export function updateTermsCondition(terms_condition) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    let postData = {
        terms_condition
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TERMS_CONDITION_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData
    });

}

export function updatePrivacyPolicy(update_privacy_policy) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    let postData = {
        update_privacy_policy
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PRIVACY_POLICY_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData
    });
}


export function callMigrationTasks() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CALL_MIGRATION_TAKS_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}
