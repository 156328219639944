
//////////////Global//////////

export const GLOBAL_LOADING = "GLOBAL_LOADING";

//////////////Reason//////////

export const GET_CENCEL_QUESTIONS_REASON = "GET_CENCEL_QUESTIONS_REASON";

//////////////Auth//////////

export const LOGIN_CONFIRMED = "LOGIN_CONFIRMED";
export const LOADING_TOGGLE = "LOADING_TOGGLE";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const SIGNUP_CONFIRMED = "SIGNUP_CONFIRMED";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SET_OTP = "SET_OTP";

//////////////Menu-Premission//////////

export const PERMISSION_LOADING = "PERMISSION_LOADING";
export const GET_PERMISSION = "GET_MENU_PERMISSION";

//////////////User-Creation//////////

export const USER_LOADING = "USER_LOADING";
export const GET_USER_TYPE = "GET_USER_TYPE";
export const ADD_USER = "ADD_USER";
export const ADD_TEACHER = "ADD_TEACHER";
export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const GET_CONTENT_DEVELOPER_LIST = "GET_CONTENT_DEVELOPER_LIST";
export const GET_STUDENT_LIST = "GET_STUDENT_LIST";
export const GET_TEACHER_LIST = "GET_TEACHER_LIST";
export const UPDATE_ADMIN_LIST = "UPDATE_ADMIN_LIST";
export const UPDATE_STUDENT_LIST = "UPDATE_STUDENT_LIST";
export const UPDATE_CONTENT_DEVELOPER_LIST = "UPDATE_CONTENT_DEVELOPER_LIST";
export const UPDATE_TEACHER_LIST = "UPDATE_TEACHER_LIST";
export const UPDATE_ADMIN_STATUS_LIST = "UPDATE_ADMIN_STATUS_LIST";
export const UPDATE_DEVELOPER_STATUS_LIST = "UPDATE_DEVELOPER_STATUS_LIST";
export const UPDATE_STUDENT_STATUS_LIST = "UPDATE_STUDENT_STATUS_LIST";
export const UPDATE_TEACHER_STATUS_LIST = "UPDATE_TEACHER_STATUS_LIST";
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_ADMIN = "REMOVE_ADMIN";
export const REMOVE_CONTENT_DEVELOPER = "REMOVE_CONTENT_DEVELOPER";
export const REMOVE_STUDENT = "REMOVE_STUDENT";
export const REMOVE_TEACHER = "REMOVE_TEACHER";
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_MENU_LIST = "GET_MENU_LIST";


//////////////Profile//////////

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const PROFILE_LOADING = "PROFILE_LOADING";

//////////////Exam-Category//////////

export const CATEGOTY_LOADING_TOGGLE = "CATEGOTY_LOADING_TOGGLE";
export const ADD_EXAM_CATEGORY_CONFIRMED = "ADD_EXAM_CATEGORY_CONFIRMED";
export const GET_EXAM_CATEGORY = "GET_EXAM_CATEGORY";
export const UPDATE_EXAM_CATEGORY = "UPDATE_EXAM_CATEGORY";
export const UPDATE_STATUS_EXAM_CATEGORY = "UPDATE_STATUS_EXAM_CATEGORY";
export const REMOVE_EXAM_CATEGORY = "REMOVE_EXAM_CATEGORY";


//////////////Branch-Category//////////

export const BRANCH_LOADING = "BRANCH_LOADING";
export const BRANCH_LIST = "BRANCH_LIST";
export const ADD_BRANCH = "ADD_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_STATUS_BRANCH = "UPDATE_STATUS_BRANCH";
export const REMOVE_BRANCH = "REMOVE_BRANCH";

//////////////Subject////////////////////

export const SUBJECT_LOADING = "SUBJECT_LOADING";
export const SUBJECT_LIST = "SUBJECT_LIST";
export const SUBJECT_FILTTER_LIST = "SUBJECT_FILTTER_LIST";
export const E_SUB_FILTER_SUBJECTS = "E_SUB_FILTER_SUBJECTS"
export const CLEAR_SUBJECT_LIST = "CLEAR_SUBJECT_LIST";
export const BOARD_CLASS_BY_SUBJECT_LIST = "BOARD_CLASS_BY_SUBJECT_LIST";
export const ADD_SUBJECT = "ADD_SUBJECT";
export const GET_NON_GROUP_SUBJECT_LIST = "GET_NON_GROUP_SUBJECT_LIST";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const UPDATE_STATUS_SUBJECT = "UPDATE_STATUS_SUBJECT";
export const REMOVE_SUBJECT = "REMOVE_SUBJECT";

//////////////Exam-Type//////////

export const TYPE_LOADING = "TYPE_LOADING";
export const ADD_EXAM_TYPE = "ADD_EXAM_TYPE";
export const GET_EXAM_TYPE = "GET_EXAM_TYPE";
export const UPDATE_EXAM_TYPE = "UPDATE_EXAM_TYPE";
export const REMOVE_EXAM_TYPE = "REMOVE_EXAM_TYPE";
export const UPDATE_STATUS_EXAM_TYPE = "UPDATE_STATUS_EXAM_TYPE";

//////////////Exam-Sub Type//////////

export const SUB_TYPE_LOADING = "SUB_TYPE_LOADING";
export const ADD_EXAM_SUB_TYPE = "ADD_EXAM_SUB_TYPE";
export const GET_EXAM_SUB_TYPE = "GET_EXAM_SUB_TYPE";
export const UPDATE_EXAM_SUB_TYPE = "UPDATE_EXAM_SUB_TYPE";
export const UPDATE_STATUS_EXAM_SUB_TYPE = "UPDATE_STATUS_EXAM_SUB_TYPE";
export const REMOVE_EXAM_SUB_TYPE = "REMOVE_EXAM_SUB_TYPE";

//////////////Board//////////

export const BOARD_LOADING = "BOARD_LOADING";
export const ADD_BOARD = "ADD_BOARD";
export const UPDATE_BOARD = "UPDATE_BOARD";
export const GET_BOARD = "GET_BOARD";
export const UPDATE_STATUS_BOARD = "UPDATE_STATUS_BOARD";
export const REMOVE_BOARD = "REMOVE_BOARD";

//////////////Class//////////

export const CLASS_LOADING = "CLASS_LOADING";
export const ADD_CLASS = "ADD_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const GET_CLASS = "GET_CLASS";
export const GET_BOARD_BY_CLASS = "GET_BOARD_BY_CLASS";
export const GET_EXAM_TYPE_BY_CLASS = "GET_EXAM_TYPE_BY_CLASS";
export const UPDATE_STATUS_CLASS = "UPDATE_STATUS_CLASS";
export const REMOVE_CLASS = "REMOVE_CLASS";

//////////////Chapter//////////

export const CHAPTER_LOADING = "CHAPTER_LOADING";
export const ADD_CHAPTER = "ADD_CHAPTER";
export const UPDATE_CHAPTER = "UPDATE_CHAPTER";
export const GET_CHAPTER = "GET_CHAPTER";
export const GET_CHAPTER_FILTTER = "GET_CHAPTER_FILTTER";
export const GET_CHAPTER_FOR_DEFFARENTS_PARAMS = "GET_CHAPTER_FOR_DEFFARENTS_PARAMS";
export const UPDATE_STATUS_CHAPTER = "UPDATE_STATUS_CHAPTER";
export const GET_BRANCH_TYPE_CHAPTER = "GET_BRANCH_TYPE_CHAPTER";
export const REMOVE_CHAPTER = "REMOVE_CHAPTER";

//////////////Question-pattern//////////

export const QUESTION_PATTERN_LOADING = "QUESTION_PATTERN_LOADING";
export const ADD_QUESTION_PATTERN = "ADD_QUESTION_PATTERN";
export const UPDATE_QUESTION_PATTERN = "UPDATE_QUESTION_PATTERN";
export const GET_QUESTION_PATTERN = "GET_QUESTION_PATTERN";
export const UPLOAD_STATUS_QUESTION_PATTERN = "UPLOAD_STATUS_QUESTION_PATTERN";
export const REMOVE_QUESTION_PATTERN = "REMOVE_QUESTION_PATTERN";

//////////////Exam-content//////////

export const EXAM_CONTENT_LOADING = "EXAM_CONTENT_LOADING";
export const ADD_EXAM_CONTENT = "ADD_EXAM_CONTENT";
export const GET_EXAM_CONTENT = "GET_EXAM_CONTENT";
export const GET_QUESTION_DETAILS = "GET_QUESTION_DETAILS";
export const GET_QUESTION_PREVIEW_DETAILS = "GET_QUESTION_PREVIEW_DETAILS";
export const GET_EXAM_CONTENT_LIST = "GET_EXAM_CONTENT_LIST";
export const GET_QUESTION_ERROR_LIST = "GET_QUESTION_ERROR_LIST";
export const GET_TOTAL_CONTENT_PAGE_LIST = "GET_TOTAL_CONTENT_PAGE_LIST";
export const GET_TOTAL_ERROR_PAGE_LIST = "GET_TOTAL_ERROR_PAGE_LIST";
export const UPLOAD_APPROVE_EXAM_CONTENT = "UPLOAD_APPROVE_EXAM_CONTENT";
export const UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS = "UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS";
export const REMOVE_MULTIPLE_QUESTION = "REMOVE_MULTIPLE_QUESTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_QUESTION_ERROR_STATUS = "UPDATE_QUESTION_ERROR_STATUS";
export const REMOVE_EXAM_CONTENT = "REMOVE_EXAM_CONTENT";
export const GET_SCECIAL_CHARACTER = "GET_SCECIAL_CHARACTER";
export const DUPLICATE_QUESTIONS_STATUS_TOGGLE = "DUPLICATE_QUESTIONS_STATUS_TOGGLE";
export const RELOAD_CONTENT = "RELOAD_CONTENT";

//////////////Exam-Time-Configuration//////////

export const TIME_CONFIGURATION_LOADING = "TIME_CONFIGURATION_LOADING";
export const ADD_TIME_CONFIGURATION = "ADD_TIME_CONFIGURATION";
export const GET_TIME_CONFIGURATION = "GET_TIME_CONFIGURATION";
export const UPDATE_TIME_CONFIGURATION = "UPDATE_TIME_CONFIGURATION";
export const REMOVE_TIME_CONFIGURATION = "REMOVE_TIME_CONFIGURATION";

//////////////Exam-Configuration//////////

export const EXAM_CONFIGURATION_LOADING = "EXAM_CONFIGURATION_LOADING";
export const ADD_EXAM_CONFIGURATION = "ADD_EXAM_CONFIGURATION";
export const GET_EXAM_CONFIGURATION = "GET_EXAM_CONFIGURATION";
export const UPDATE_EXAM_CONFIGURATION = "UPDATE_EXAM_CONFIGURATION";
export const REMOVE_EXAM_CONFIGURATION = "REMOVE_EXAM_CONFIGURATION";

//////////////Subscription//////////

export const SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING";
export const ADD_COMPETETIVE_SUBSCRIPTION = "ADD_COMPETETIVE_SUBSCRIPTION";
export const UPDATE_COMPETETIVE_SUBSCRIPTION = "UPDATE_COMPETETIVE_SUBSCRIPTION";
export const GET_COMPETETIVE_SUBSCRIPTION_LIST = "GET_COMPETETIVE_SUBSCRIPTION_LIST";
export const ADD_SCHOLASTIC_SUBSCRIPTION = "ADD_SCHOLASTIC_SUBSCRIPTION";
export const UPDATE_SCHOLASTIC_SUBSCRIPTION = "UPDATE_SCHOLASTIC_SUBSCRIPTION";
export const GET_SCHOLASTIC_SUBSCRIPTION = "GET_SCHOLASTIC_SUBSCRIPTION";

export const GET_INTEGRATED_SUBSCRIPTION = "GET_INTEGRATED_SUBSCRIPTION";

export const GET_LIBRARY_SUBSCRIPTION = "GET_LIBRARY_SUBSCRIPTION";
export const UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS = "UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS";
export const UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS = "UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS";
export const UPDATE_LIBRARY_SUBSCRIPTION_STATUS = "UPDATE_LIBRARY_SUBSCRIPTION_STATUS";
export const REMOVE_COMPETETIVE_SUBSCRIPTION = "REMOVE_COMPETETIVE_SUBSCRIPTION";
export const REMOVE_SCHOLASTIC_SUBSCRIPTION = "REMOVE_SCHOLASTIC_SUBSCRIPTION";
export const REMOVE_LIBRARY_SUBSCRIPTION = "REMOVE_LIBRARY_SUBSCRIPTION";

//////////////E-library//////////

export const ELIBRARY_LOADING = "ELIBRARY_LOADING";
export const GET_ELIBRARY_LIST = "GET_ELIBRARY_LIST";
export const APPROVE_VADIDATOR_BY_ADMIN = "APPROVE_VADIDATOR_BY_ADMIN";
export const GET_ELIBRARY_CONTENT_LIST = "GET_ELIBRARY_CONTENT_LIST";
export const UPLOAD_ELIBRARY_CONTENT = "UPLOAD_ELIBRARY_CONTENT";
export const UPLOAD_CONTENT_MAP = "UPLOAD_CONTENT_MAP";
export const UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH = "UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH";
export const CONTENT_DEVELOPER_LIST = "CONTENT_DEVELOPER_LIST";
export const ASSIGN_LIBRARY = "ASSIGN_LIBRARY";
export const GET_LIBRARY_COUNT = "GET_LIBRARY_COUNT";
export const REMOVE_UPDATE_DATE = "REMOVE_UPDATE_DATE";


//////////////Content-developer//////////

export const CONTENT_VALIDATOR_LOADING = "CONTENT_VALIDATOR_LOADING";
export const GET_CONTENT_VALIDATOR_LIST = "GET_CONTENT_VALIDATOR_LIST";
export const UPDATE_CONTENT_VALIDATOR = "UPDATE_CONTENT_VALIDATOR";
export const UPDATE_CONTENT_VALIDATOR_STATUS = "UPDATE_CONTENT_VALIDATOR_STATUS";
export const REMOVE_CONTENT_VALIDATOR = "REMOVE_CONTENT_VALIDATOR";

//////////////Content-designer//////////

export const CONTENT_DESIGNER_LOADING = "CONTENT_DESIGNER_LOADING";
export const GET_CONTENT_DESIGNER_LIST = "GET_CONTENT_DESIGNER_LIST";
export const UPDATE_CONTENT_DESIGNER = "UPDATE_CONTENT_DESIGNER";
export const UPDATE_CONTENT_DESIGNER_STATUS = "UPDATE_CONTENT_DESIGNER_STATUS";
export const REMOVE_CONTENT_DESIGNER = "REMOVE_CONTENT_DESIGNER";

//////////////Content-Language-validator//////////

export const CONTENT_LANGUAGE_VALIDATOR_LOADING = "CONTENT_LANGUAGE_VALIDATOR_LOADING";
export const GET_CONTENT_LANGUAGE_VALIDATOR_LIST = "GET_CONTENT_LANGUAGE_VALIDATOR_LIST";
export const UPDATE_CONTENT_LANGUAGE_VALIDATOR = "UPDATE_CONTENT_LANGUAGE_VALIDATOR";
export const UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS = "UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS";
export const REMOVE_CONTENT_LANGUAGE_VALIDATOR = "REMOVE_CONTENT_LANGUAGE_VALIDATOR";
export const DELETE_LIBRARY_CONTENT = "DELETE_LIBRARY_CONTENT";

//////////////Event-History//////////
export const EVENT_HISTORY_LOADING = "EVENT_HISTORY_LOADING";
export const ADD_EVENT_HISTORY = "ADD_EVENT_HISTORY";
export const GET_EVENT_HISTORY_LIST = "GET_EVENT_HISTORY_LIST";
export const UPDATE_EVENT_HISTORY_STATUS_LIST = "UPDATE_EVENT_HISTORY_STATUS_LIST";
export const REMOVE_EVENT_HISTORY_LIST = "REMOVE_EVENT_HISTORY_LIST";

//////////////Assign Content//////////

export const ADD_ASSIGN_CONTENT = "ADD_ASSIGN_CONTENT";
export const GET_ASSIGN_E_LIBRARY_CONTENT_LIST = "GET_ASSIGN_E_LIBRARY_CONTENT_LIST";
export const GET_ASSIGN_QUESTION_CONTENT_LIST = "GET_ASSIGN_QUESTION_CONTENT_LIST";
export const GET_E_LIBRARY_CONTENT_REQUEST_DETAILS = "GET_E_LIBRARY_CONTENT_REQUEST_DETAILS";
export const GET_QUESTION_CONTENT_REQUEST_DETAILS = "GET_QUESTION_CONTENT_REQUEST_DETAILS";
export const GET_ASSIGN_QUESTION_DETAILS_BY_ID = "GET_ASSIGN_QUESTION_DETAILS_BY_ID";
export const ASSIGN_CONTENT_LOADING = "ASSIGN_CONTENT_LOADING";

//////////////Type of Exam//////////

export const TYPE_OF_EXAM_LOADING = "TYPE_OF_EXAM_LOADING";
export const ADD_TYPE_OF_EXAM = "ADD_TYPE_OF_EXAM";
export const UPDATE_TYPE_OF_EXAM = "UPDATE_TYPE_OF_EXAM";
export const GET_TYPE_OF_EXAM = "GET_TYPE_OF_EXAM";
export const UPDATE_STATUS_TYPE_OF_EXAM = "UPDATE_STATUS_TYPE_OF_EXAM";
export const REMOVE_TYPE_OF_EXAM = "REMOVE_TYPE_OF_EXAM";

//////////////Exam Details//////////

export const EXAM_DETAILS_LOADING = "EXAM_DETAILS_LOADING";
export const GET_SCHOLASTIC_EXAM_DETAILS = "GET_SCHOLASTIC_EXAM_DETAILS";
export const GET_COMPETITIVE_EXAM_DETAILS = "GET_COMPETITIVE_EXAM_DETAILS";
export const UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS = "UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS";
export const UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS = "UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS";
export const REMOVE_SCHOLASTIC_EXAM_DETAILS = "REMOVE_SCHOLASTIC_EXAM_DETAILS";
export const REMOVE_COMPETITIVE_EXAM_DETAILS = "REMOVE_COMPETITIVE_EXAM_DETAILS";

//////////////School-details//////////

export const SCHOOL_LOADING = "SCHOOL_LOADING";
export const GET_SCHOOL_DETAILS = "GET_SCHOOL_DETAILS";
export const REMOVE_SCHOOL_DETAILS = "REMOVE_SCHOOL_DETAILS";

//////////////Career-Guide//////////

export const CAREER_LOADING = "CAREER_LOADING";

//////////////Settings//////////

export const SETTINGS_LOADING = "SETTINGS_LOADING";

export const GET_SETTINGS = "GET_SETTINGS";


///////////FeedBack////////////

export const FEEDBACK_LOADING = "FEEDBACK_LOADING";
export const GET_FEEDBACK_QUESTION = "GET_FEEDBACK_QUESTION";
export const FEEDBACK_QUESTION_DELETE = "FEEDBACK_QUESTION_DELETE";

///////////MIS////////////////////
export const GET_MIS_REGISTER_USER = "GET_MIS_REGISTER_USER";
export const GET_MIS_FAILED_REGISTRATION_LIST = "GET_MIS_FAILED_REGISTRATION_LIST";
export const GET_SUBSCRIPTION_DETAILS_LIST = "GET_SUBSCRIPTION_DETAILS_LIST";
export const GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST = "GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST";
export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO";
export const GET_SUBSCRIBED_USER_ACTIVITY = "GET_SUBSCRIBED_USER_ACTIVITY";

export const CONCEPT_MAP_PATH_ONLY = "CONCEPT_MAP_PATH_ONLY";
export const GET_SUBJECTS_BY_BOARDS = "GET_SUBJECTS_BY_BOARDS";
export const REMOVE_ASSIGN_CONTENT_LIST = "REMOVE_ASSIGN_CONTENT_LIST";
export const REMOVE_SUCCESSFULLY_FROM_LIST = "REMOVE_SUCCESSFULLY_FROM_LIST";
export const GET_REJECTED_LIST = "GET_REJECTED_LIST";
export const GET_ADDED_DOCUMENT_DETAILS = "GET_ADDED_DOCUMENT_DETAILS";
export const RE_RENDERING_CONTENT_LIST = "RE_RENDERING_CONTENT_LIST";
export const ASSIGN_TO_VALIDATOR_ELIBRARY = "ASSIGN_TO_VALIDATOR_ELIBRARY";
export const CONTENT_LIST_TOTAL_RECORD = "CONTENT_LIST_TOTAL_RECORD";
export const CONTENT_LIST_TOTAL_PAGE = "CONTENT_LIST_TOTAL_PAGE";
export const GET_LIBRARY_HISTORY_LOG = "GET_LIBRARY_HISTORY_LOG";
export const GET_SINGLE_SUBJECT_LIST = "GET_SINGLE_SUBJECT_LIST";
export const NOTIFICATION_DETAILS = "NOTIFICATION_DETAILS";
export const SCHOLASTIC_ACADEMIC_SESSION = "SCHOLASTIC_ACADEMIC_SESSION";
export const COMPETITIVE_ACADEMIC_SESSION = "COMPETITIVE_ACADEMIC_SESSION";
export const ASSIGN_DATA_CLEAR = "ASSIGN_DATA_CLEAR";
export const FILTER_LIST_DATA = "FILTER_LIST_DATA";
export const PAYMENT_TRANSACTION_DETAILS = "PAYMENT_TRANSACTION_DETAILS";
export const GET_QUESTION_HISTORY_LOG = "GET_QUESTION_HISTORY_LOG";
// suject filter list
export const SUBJECT_CATEGORY_LIST = "SUBJECT_CATEGORY_LIST";
export const SUBJECT_BOARD_LIST = "SUBJECT_BOARD_LIST";
export const SUBJECT_GROUP_INDIVIDUAL_LIST = "SUBJECT_GROUP_INDIVIDUAL_LIST";
export const SUBJECT_TYPE_LIST = "SUBJECT_TYPE_LIST";
export const SUBJEC_TLIST_PAGE_NUMBER = "SUBJEC_TLIST_PAGE_NUMBER";
/*  */
export const CREATE_USERS_DROPDOWN_DATA = "CREATE_USERS_DROPDOWN_DATA";
/* Scholastic Exam Details List filter data */
export const SCHOLASTIC_EXAM_DETAILS_BOARD = "SCHOLASTIC_EXAM_DETAILS_BOARD";
export const SCHOLASTIC_EXAM_DETAILS_CLASS = "SCHOLASTIC_EXAM_DETAILS_CLASS";
export const SCHOLASTIC_EXAM_DETAILS_SUBJECT = "SCHOLASTIC_EXAM_DETAILS_SUBJECT";
export const SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION = "SCHOLASTIC_EXAM_DETAILS_ACCADEMIC_SESSION";
export const SCHOLASTIC_EXAM_DETAILS_STAUS = "SCHOLASTIC_EXAM_DETAILS_STAUS";

/* COMPETITIVE Exam Details List filter data */
export const COMPETITIVE_EXAM_DETAILS_TYPE = "COMPETITIVE_EXAM_DETAILS_TYPE";
export const COMPETITIVE_EXAM_DETAILS_CLASS = "COMPETITIVE_EXAM_DETAILS_CLASS";
export const COMPETITIVE_EXAM_DETAILS_SUBJECT = "COMPETITIVE_EXAM_DETAILS_SUBJECT";
export const COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION = "COMPETITIVE_EXAM_DETAILS_ACCADEMIC_SESSION";
export const COMPETITIVE_EXAM_DETAILS_STAUS = "COMPETITIVE_EXAM_DETAILS_STAUS";
export const COMPETITIVE_EXAM_DETAILS_SUB_TYPE = "COMPETITIVE_EXAM_DETAILS_SUB_TYPE";

/* elibrary Details List filter data */
export const ELIBRARY_CONTENT_LIST_TYPE = "ELIBRARY_CONTENT_LIST_TYPE";
export const ELIBRARY_CONTENT_LIST_CATEGORY = "ELIBRARY_CONTENT_LIST_CATEGORY";
export const ELIBRARY_CONTEN_TLIST_BOARD = "ELIBRARY_CONTEN_TLIST_BOARD";
export const ELIBRARY_CONTENT_LIST_CLASS = "ELIBRARY_CONTENT_LIST_CLASS";
export const ELIBRARY_CONTENT_LIST_SUBJECT = "ELIBRARY_CONTENT_LIST_SUBJECT";
export const ELIBRARY_CONTENT_LIST_CHAPTER = "ELIBRARY_CONTENT_LIST_CHAPTER";
export const ELIBRARY_CONTENT_LIST_STATUS = "ELIBRARY_CONTENT_LIST_STATUS";
export const ELIBRARY_CONTENT_LIST_EXAM_TYPE = "ELIBRARY_CONTENT_LIST_EXAM_TYPE";

///////////////////-RESUMES-////////////////

export const GET_RESUMES = "GET_RESUMES"
export const GET_INDIVIDUAL_RESUME = "GET_INDIVIDUAL_RESUME"

//////////////Advertiesment//////////

export const GET_ADVERTIESMEST_LIST="GET_ADVERTIESMEST_LIST"
export const GET_ADVERTIESMEST_SCREEN_LIST="GET_ADVERTIESMEST_SCREEN_LIST"

//////////////Academic Session//////////

export const GET_ACADEMIC_SESSION_LIST="GET_ACADEMIC_SESSION_LIST"
export const POST_ACADEMIC_SESSION_STATUS_UPDATE="POST_ACADEMIC_SESSION_STATUS_UPDATE"

