import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getRolesData, createUsersDropdownDetails } from "../../../store/actions/UserAction";
import moment from "moment";
import {
  validateEmail,
  validatePhone,
  validateName,
  inputNumberOnly,
  inputAlphabetOnly
} from "../../../utility/Utility";
import {
  loadingUserAction,
  getUserTypeData,
  updateAdminData,
  getStateData,
  getDistrictData
} from "../../../store/actions/UserAction";
import {
  getPermisstionData,
  loadingPermissionAction,
} from "../../../store/actions/PermissionAction";

const UpdateAdmin = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const DOBRef = useRef();
  const enrollmentRef = useRef();

  let errorsObj = {
    fName: "",
    mName: "",
    lName: "",
    dob: "",
    gender: "",
    number: "",
    email: "",
    house: "",
    location: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    panNo: "",
    aadhar: "",
    qualification: "",
    experience: "",
    knowleage: "",
    academic: "",
    enrollment: "",
    menus: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [firstName, setFirstName] = useState(state?.item?.fname);
  const [middleName, setMiddleName] = useState(state?.item?.mname);
  const [lastName, setLastName] = useState(state?.item?.lname);
  const [dob, setDob] = useState(new Date(state?.item?.dob));
  const [gender, setGender] = useState(state?.item?.gender);
  const [number, setNumber] = useState(state?.item?.phone);
  const [email, setEmail] = useState(state?.item?.email);
  const [houseName, setHouseName] = useState(state?.item?.house_flat_name);
  const [location, setLocation] = useState(state?.item?.area_location);
  const [city, setCity] = useState(state?.item?.city_name);
  const [district, setDistrict] = useState(state?.item?.district_name);
  const [userState, setUserState] = useState(state?.item?.state_id + "^" + state?.item?.state_name);
  const [pincode, setPincode] = useState(state?.item?.pincode);
  const [panNo, setPanNo] = useState(state?.item?.panno);
  const [aadhar, setAadhar] = useState(state?.item?.adhar_no);
  const [qualification, setQualification] = useState(
    state?.item?.highest_qualification
  );
  const [experience, setExperience] = useState(state?.item?.experience);
  const [computerKnowledge, setComputerKnowledge] = useState(
    state?.item?.computer_knowledge
  );
  const [academicYear, setAcademicYear] = useState(state?.item?.academic_year);
  // const [enrollment, setEnrollment] = useState(
  //   state?.item?.date_of_enrollment ? new Date(state?.item?.date_of_enrollment) : ""
  // );
  const [enrollment, setEnrollment] = useState(new Date(state?.item?.date_of_enrollment));
  const [currentPermissionList, setCurrentPermissionList] = useState(
    state?.item?.menu ? state?.item?.menu : []
  );
  const [RollId, setRollId] = useState(state?.item?.role_id);
  const [permissionList, setPermissionList] = useState(
    state?.item?.menu ? state?.item?.menu : []
  );

  const [previousPageFilterData, setPreviousPageFilterData] = useState('');

  useEffect(() => {
    setPreviousPageFilterData(props.location.state?.filterData)
    console.log("data geting from previous page", state)
    dispatch(getRolesData());
    dispatch(loadingUserAction(true));
    dispatch(getUserTypeData(props.history));
    dispatch(getPermisstionData(true));
    dispatch(getStateData());
    dispatch(createUsersDropdownDetails());
  }, []);

  useEffect(() => {
    if (userState !== "") {
      dispatch(globalLoadingAction(true));
      dispatch(getDistrictData(userState.split("^")[0]));
    }
  }, [userState]);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (firstName === "") {
      errorObj.fName = "First Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(firstName)) {
      errorObj.fName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(firstName)) {
      errorObj.fName = "Space are not allow";
      error = true;
    }
    if (middleName !== '' && (!/^[a-zA-Z\s]+$/.test(middleName))) {
      errorObj.mName = 'Numbers and special characters are not allow';
      error = true;
    } else if (/\s/.test(middleName)) {
      errorObj.mName = "Space are not allow";
      error = true;
    }
    if (lastName === "") {
      errorObj.lName = "Last Name is Required";
      error = true;
    } else if (!/^[a-zA-Z\s]+$/.test(lastName)) {
      errorObj.lName = "Numbers and special characters are not allow";
      error = true;
    } else if (/\s/.test(lastName)) {
      errorObj.lName = "Space are not allow";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (validateEmail(email) === true) {
      errorObj.email = "Invalid Email";
      error = true;
    }
    if (number === "") {
      errorObj.number = "Number is Required";
      error = true;
    }
    if (validatePhone(number) === false) {
      errorObj.number = "Invalid Number";
      error = true;
    }
    if (dob === "") {
      errorObj.dob = "Date of birth is Required";
      error = true;
    }
    if (gender === "") {
      errorObj.gender = "Gender is Required";
      error = true;
    }
    if (houseName === "") {
      errorObj.house = "House / Flat Name is Required";
      error = true;
    } else if (/^\s|\s$/.test(houseName)) {
      errorObj.house = "only space and end space are not allow";
      error = true;
    }
    if (location === "") {
      errorObj.location = "Location Name is Required";
      error = true;
    } else if (/^\s|\s$/.test(location)) {
      errorObj.location = "only space and end space are not allow";
      error = true;
    }
    if (city === "") {
      errorObj.city = "City / Town / Village is Required";
      error = true;
    } else if (/^\s|\s$/.test(city)) {
      errorObj.city = "only space and end space are not allow";
      error = true;
    }
    if (district === "") {
      errorObj.district = "District is Required";
      error = true;
    }
    if (userState === "") {
      errorObj.state = "State is Required";
      error = true;
    }
    if (pincode === "" || !/^\d{6}$/.test(pincode)) {
      errorObj.pincode = "A valid pin Code is Required";
      error = true;
    }
    if (panNo === "") {
      errorObj.panNo = "Pan Number is Required";
      error = true;
    } else if (!/^[A-Z]{5}\d{4}[A-Z]$/.test(panNo)) {
      errorObj.panNo = "Pan Number is Invalid";
      error = true;
    }
    if (aadhar === "") {
      errorObj.aadhar = "Aadhaar no. is Required";
      error = true;
    } else if (!/^\d{12}$/.test(aadhar)) {
      errorObj.aadhar = "Aadhaar no. is Invalid";
      error = true;
    }
    if (qualification === "") {
      errorObj.qualification = "Qualification is Required";
      error = true;
    } else if (/^\s|\s$/.test(qualification)) {
      errorObj.qualification = "only space and end space are not allow";
      error = true;
    }
    if (experience === "") {
      errorObj.experience = "Experience is Required";
      error = true;
    } else if (/^\s|\s$/.test(experience)) {
      errorObj.experience = "only space and end space are not allow";
      error = true;
    }
    if (computerKnowledge === "") {
      errorObj.knowleage = "Computer Knowledge is Required";
      error = true;
    } else if (/^\s|\s$/.test(computerKnowledge)) {
      errorObj.knowleage = "only space and end space are not allow";
      error = true;
    }
    if (academicYear === "") {
      errorObj.academic = "Academic year is Required";
      error = true;
    } else if (/^\s|\s$/.test(academicYear)) {
      errorObj.academic = "only space and end space are not allow";
      error = true;
    }
    if (academicYear === "") {
      errorObj.academic = "Academic year is Required";
      error = true;
    } else if (!/^\d{4}$/.test(academicYear)) {
      errorObj.academic = "Year should be 4 digit number";
      error = true;
    }
    if (enrollment === "") {
      errorObj.enrollment = "Date of Enrollment/Enlistment is Required";
      error = true;
    }
    if (academicYear === "") {
      errorObj.academic = "Academic year is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingUserAction(true));
    dispatch(
      updateAdminData(
        state.item.id,
        firstName,
        middleName,
        lastName,
        moment(dob).format("YYYY-MM-DD"),
        gender,
        number,
        email,
        houseName,
        location,
        city,
        district,
        userState.split("^")[1],
        pincode,
        panNo,
        aadhar,
        qualification,
        experience,
        computerKnowledge,
        academicYear,
        moment(enrollment).format("YYYY-MM-DD"),
        permissionList,
        RollId,
        props.history
      )
    );
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setDob("");
    setGender("");
    setNumber("");
    setEmail("");
    setHouseName("");
    setLocation("");
    setCity("");
    setDistrict("");
    setUserState("");
    setPincode("");
    setPanNo("");
    setAadhar("");
    setQualification("");
    setExperience("");
    setComputerKnowledge("");
    setAcademicYear("");
    setEnrollment("");
  }

  /* number restrictions */
  const onNumberHandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setNumber(e.target.value);
    }
  };

  const onMainPermissionHandler = (event, item) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    if (event.target.checked) {
      arry.push(item.id);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          arry.push(dataArray[i].id);
          document.querySelector(`#check${dataArray[i].id}`).checked = true;
        }
      }
    } else {
      const index = arry.findIndex((val) => val === item.id);
      arry.splice(index, 1);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          const chilsIndex = arry.findIndex((val) => val === dataArray[i].id);
          arry.splice(chilsIndex, 1);
          document.querySelector(`#check${dataArray[i].id}`).checked = false;
        }
      }
    }

    setPermissionList(arry);
  };

  const onSubPermissionHandler = (event, item, data) => {
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    let parentId = arry.includes(item.id);

    if (event.target.checked) {
      if (parentId) {
        arry.push(data.id);
      } else {
        document.querySelector(`#check${item.id}`).checked = true;
        arry.push(item.id, data.id);
      }
    } else {
      let ins = null;
      const subIndex = arry.findIndex((val) => val === data.id);
      arry.splice(subIndex, 1);

      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          let removeId = arry.includes(dataArray[i].id);
          if (removeId) {
            ins = true;
            break;
          }
        }
      }
      if (!ins) {
        const parentIndex = arry.findIndex((val) => val === item.id);
        arry.splice(parentIndex, 1);
        document.querySelector(`#check${item.id}`).checked = false;
      }
    }

    setPermissionList(arry);
  };

  const onCurrentStatus = (id) => {
    let status = null;
    const index = currentPermissionList.findIndex((val) => val === id);
    if (index >= 0) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const onStateHandler = (val) => {
    setUserState(val);
    setDistrict('')
  }

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/admin-list",
      state: {previousPageFilterData},
    });
    // history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  {/* {console.log('hello world.........',permissionList)} */}
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="row">
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  First Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.fName && (
                                <div className="text-danger fs-12">
                                  {errors.fName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>Middle Name(Optional)</strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the middle name"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.mName && (
                                <div className="text-danger fs-12">
                                  {errors.mName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyPress={inputAlphabetOnly}
                              />
                              {errors.lName && (
                                <div className="text-danger fs-12">
                                  {errors.lName}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  DOB<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={DOBRef}
                                  selected={dob}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setDob(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}

                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() => DOBRef.current.setOpen(true)}
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.dob && (
                                <div className="text-danger fs-12">
                                  {errors.dob}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Gender<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={gender}
                                  onChange={(e) => setGender(e.target.value)}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                              {errors.gender && (
                                <div className="text-danger fs-12">
                                  {errors.gender}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Contact Number
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the contact number"
                                value={number}
                                onChange={(e) => onNumberHandler(e)}
                                onKeyPress={inputNumberOnly}
                                maxlength="10"
                              />
                              {errors.number && (
                                <div className="text-danger fs-12">
                                  {errors.number}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Email<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the email"
                                readOnly
                                value={email}
                              //onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <div className="text-danger fs-12">
                                  {errors.email}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  House / Flat Name
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the house/flat name"
                                value={houseName}
                                onChange={(e) => setHouseName(e.target.value)}
                              />
                              {errors.house && (
                                <div className="text-danger fs-12">
                                  {errors.house}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Area / Location
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the area/location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                              {errors.location && (
                                <div className="text-danger fs-12">
                                  {errors.location}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  City / Town / Village
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the city/town/village"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                              {errors.city && (
                                <div className="text-danger fs-12">
                                  {errors.city}
                                </div>
                              )}
                            </div>

                            {/* <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  District<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the district"
                                value={district}
                                onChange={(e) => setDistrict(e.target.value)}
                              />
                              {errors.district && (
                                <div className="text-danger fs-12">
                                  {errors.district}
                                </div>
                              )}
                            </div> */}

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  State<span className="text-danger">*</span>
                                </strong>
                              </label>

                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>

                                <select

                                  className="form-control form-control"
                                  value={userState}
                                  //onChange={(e) => setUserState(e.target.value)&& setDistrict('')}
                                  onChange={(e) => onStateHandler(e.target.value)}
                                >
                                  <option value="">Select State</option>
                                  {props.stateList.map((item, index) => {

                                    return (
                                      <option
                                        key={index}
                                        value={item.id + "^" + item.state_name}
                                      >
                                        {item.state_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.type && (
                                <div className="text-danger fs-12">
                                  {errors.state}
                                </div>
                              )}
                            </div>


                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  District<span className="text-danger">*</span>
                                </strong>
                              </label>
                              {
                                console.log(district, "onchange")
                              }
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={district}
                                  onChange={(e) => setDistrict(e.target.value)}
                                >
                                  <option value="">Select district</option>
                                  {props.districtList.map((item, index) => {

                                    return (
                                      <option key={index} value={item.district_name}>
                                        {item.district_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.district && (
                                <div className="text-danger fs-12">
                                  {errors.district}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Pincode<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pincode"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                                onKeyPress={inputNumberOnly}
                                maxlength="6"
                              />
                              {errors.pincode && (
                                <div className="text-danger fs-12">
                                  {errors.pincode}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Pan Number<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the pan number"
                                value={panNo}
                                onChange={(e) => setPanNo(e.target.value)}
                              />
                              {errors.panNo && (
                                <div className="text-danger fs-12">
                                  {errors.panNo}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Aadhaar No
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Aadhaar No."
                                value={aadhar}
                                onChange={(e) => setAadhar(e.target.value)}
                                onKeyPress={inputNumberOnly}
                              />
                              {errors.aadhar && (
                                <div className="text-danger fs-12">
                                  {errors.aadhar}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Highest Qualification
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the highest qualification"
                                value={qualification}
                                onChange={(e) =>
                                  setQualification(e.target.value)
                                }
                              />
                              {errors.qualification && (
                                <div className="text-danger fs-12">
                                  {errors.qualification}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Experience
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the experience"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                              />
                              {errors.experience && (
                                <div className="text-danger fs-12">
                                  {errors.experience}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Computer Knowledge
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              {/* <input
                                className="form-control"
                                placeholder="Enter the knowledge"
                                value={computerKnowledge}
                                onChange={(e) =>
                                  setComputerKnowledge(e.target.value)
                                }
                              /> */}
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={computerKnowledge}
                                  onChange={(e) =>
                                    setComputerKnowledge(e.target.value)
                                  }
                                >
                                  <option value="">Select computer knowledge</option>
                                  {props?.createUsersDropdownData?.computer_knowledge?.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item}
                                      >
                                        {item}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.knowleage && (
                                <div className="text-danger fs-12">
                                  {errors.knowleage}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Academic Year
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter the Academic year"
                                value={academicYear}
                                onChange={(e) =>
                                  setAcademicYear(e.target.value)
                                }
                              />
                              {errors.academic && (
                                <div className="text-danger fs-12">
                                  {errors.academic}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Date of Enrollment/Enlistment
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="date-control-view">
                                <DatePicker
                                  ref={enrollmentRef}
                                  selected={enrollment}
                                  placeholderText="Enter the date"
                                  onChange={(date) => setEnrollment(date)}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}

                                />
                                <button
                                  type="button"
                                  className="picker-btn"
                                  onClick={() =>
                                    enrollmentRef.current.setOpen(true)
                                  }
                                >
                                  <i class="fa-solid fa-calendar-days"></i>
                                </button>
                              </div>
                              {errors.enrollment && (
                                <div className="text-danger fs-12">
                                  {errors.enrollment}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  Role<span className="text-danger">*</span>
                                </strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow">
                                  <i className="bi bi-caret-down-fill"></i>
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={RollId}
                                  onChange={(e) => setRollId(e.target.value)}
                                >
                                  <option value="">Select Role</option>
                                  {props.roleList.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={item.id}
                                      >
                                        {item.role_name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.RollId && (
                                <div className="text-danger fs-12">
                                  {errors.RollId}
                                </div>
                              )}
                            </div>
                            {/* 
                            <div className="form-group mb-3 col-md-6">
                              <label className="mb-2">
                                <strong>
                                  E-mail
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                className="form-control"
                               
                                value={state?.email}
                                readOnly
                              />
                             
                            </div> */}

                            {/* <div className="form-group mb-3 col-md-12">
                              <label className="mb-2">
                                <strong>
                                  Permission
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                             
                              <div className="permission-list-view">
                                {props.permissionList.map((item, i) => {
                                  if (item.parent_menu == 0) {
                                    return (
                                      <div
                                        key={item.id + i}
                                        className="main-form-check-view"
                                      >
                                        <div className="form-check mt-2 mb-2">
                                          <input
                                            type="checkbox"
                                            defaultChecked={onCurrentStatus(
                                              item.id
                                            )}
                                            className="form-check-input"
                                            id={`check${item.id}`}
                                            name={`check${item.id}`}
                                            onChange={(e) =>
                                              onMainPermissionHandler(e, item)
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`check${item.id}`}
                                          >
                                            {item.menu_name}
                                          </label>
                                        </div>

                                        <div className="menu_permission_sub_container">
                                          {props.permissionList.map(
                                            (data, j) => {
                                              if (item.id == data.parent_menu) {
                                                return (
                                                  <div
                                                    key={data.id + j}
                                                    className="form-check mb-2 me-3"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      defaultChecked={onCurrentStatus(
                                                        data.id
                                                      )}
                                                      className="form-check-input"
                                                      id={`check${data.id}`}
                                                      name={`check${data.id}`}
                                                      onChange={(e) =>
                                                        onSubPermissionHandler(
                                                          e,
                                                          item,
                                                          data
                                                        )
                                                      }
                                                    />

                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`check${data.id}`}
                                                    >
                                                      {data.menu_name}
                                                    </label>

                                                    {(data.menu_name.includes(
                                                      "list"
                                                    ) ||
                                                      data.menu_name.includes(
                                                        "List"
                                                      )) && (
                                                        <div className="sub-form-check-view">
                                                          <div className="form-check mb-2 me-3">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                            />

                                                            <label className="form-check-label">
                                                              Edit
                                                            </label>
                                                          </div>
                                                          <div className="form-check mb-2 me-3">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                            />

                                                            <label className="form-check-label">
                                                              Delete
                                                            </label>
                                                          </div>
                                                          <div className="form-check mb-2 me-3">
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                            />

                                                            <label className="form-check-label">
                                                              Status
                                                            </label>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              {errors.menus && (
                                <div className="text-danger fs-12">
                                  {errors.menus}
                                </div>
                              )}
                            </div> */}

                            <div className="text-center mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block category-btn"
                                disabled={props.showLoading}
                              >
                                Update
                                {props.showLoading ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.user.showLoading,
    userTypeList: state.user.typeList,
    permissionList: state.permission.list,
    districtList: state.Location.Districtlist,
    stateList: state.Location.StateList,
    roleList: state.user.rolesList,
    createUsersDropdownData: state.user.createUsersDropdownData,
  };
};

export default connect(mapStateToProps)(UpdateAdmin);
