import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { addComSubscriptionData, subscriptionLoadingAction } from '../../../store/actions/SubscriptionAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const AddCompetetiveSubscription = (props) => {

	let errorsObj = { type: '', className: '', setConunt: '', questionSet: '', amount: '', ePrice: '', sticker: '', courseCode:'' };
	const [errors, setErrors] = useState(errorsObj);
	const [type, setType] = useState('');
	const [fullStringType, setFullStringType] = useState()

	const [className, setClassName] = useState('');
	const [setConunt, setSetConunt] = useState('');
	const [questionSet, setQuestionSet] = useState('');
	const [amount, setAmount] = useState('');
	const [elibraryPrice, setElibraryPrice] = useState('');
	const [isLibrary, setIsLibrary] = useState(false);
	const [sticker, setSticker] = useState('');
	const [isSticker, setIsSticker] = useState(false);
	const [isClass, setIsClass] = useState()
	const [courseCode, setCourseCode] = useState('')

	const dispatch = useDispatch();

	useEffect(() => {

		dispatch(loadingAction(true));
		dispatch(getExamType(props.history));
		//dispatch(loadingClassAction(true));
		//dispatch(getClassData(props.history));

	}, []);

	/* form stubmit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;

		const errorObj = { ...errorsObj };

		if (type === '') {
			errorObj.type = 'Exam type is Required';
			error = true;
		}
		if (className === '' && parseInt(type) == 2) {
			errorObj.className = 'Class name is Required';
			error = true;
		}
		if (setConunt === '' && isLibrary == false) {
			errorObj.setConunt = 'Set count is Required';
			error = true;
		}
		if (questionSet === '' && isLibrary == false) {
			errorObj.questionSet = 'Question set is Required';
			error = true;
		}
		if (amount === '' && isLibrary == false) {
			errorObj.amount = 'Amount is Required';
			error = true;
		}
		if (elibraryPrice === '') {
			errorObj.ePrice = 'E-library Price is Required';
			error = true;
		}
		if (sticker === '' && isSticker == true) {
			errorObj.sticker = 'Sticker is Required';
			error = true;
		}

		if (courseCode === '' ) {
			errorObj.courseCode = 'Course Code is Required';
			error = true;
		}
		
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(subscriptionLoadingAction(true));
		dispatch(globalLoadingAction(true));
		dispatch(addComSubscriptionData(type, (parseInt(type) !== 1 && parseInt(type) !== 5) ? className : 0, isLibrary ? '' : setConunt, isLibrary ? '' : questionSet, isLibrary ? '' : amount, elibraryPrice, isLibrary ? 1 : 0, isSticker ? sticker : '', courseCode,()=>{blankFields()}, props.history));
	}

	/* all field reset or blank */
	const blankFields=()=>{
		setFullStringType('')
		setType('')
		setClassName('')
		setSetConunt('')
		setQuestionSet('')
		setAmount('')
		setElibraryPrice('')
		setIsLibrary(false)
		setSticker()
		setIsSticker(false)
		setIsClass('')
		setCourseCode('')
	}

	/* change type */
	const onTypeHandler = (val) => {
		setType(val.split("^")[0]);
		setFullStringType(val)
		setIsClass(val.split("^")[1])


	}

	/* get class list */
	const getClassHandler = () => {
		if (props.classList.length == 0) {
			dispatch(loadingClassAction(true));
			dispatch(getClassData(props.history));
		}
	}

	return (
		<>
			<div className="row">
				{/* {console.log('typeList', props.typeList)} */}
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam type name<span className="text-danger">*</span></strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow add-Question-arrow-position">
																<i className="bi bi-caret-down-fill"></i>
															</span>
															<select
																defaultValue={"option"}
																className="form-control form-control"
																value={fullStringType}
																onChange={(e) => onTypeHandler(e.target.value)}
															>
																<option value="">Select Exam type</option>
																{
																	props.typeList.map((item, index) => {
																		return <option key={index} value={item.id + "^" + item.is_class}>{item.type_name}</option>
																	})
																}
															</select>
														</div>
														{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
													</div>

													<div className="form-check mt-3 mb-3">
														<input
															type="checkbox"
															className="form-check-input"
															id="comElibrary"
															name="comElibrary"
															//value={props.totalModules}
															checked={isLibrary}
															onChange={(e) => setIsLibrary(e.target.checked)}
														/>
														<label
															className="form-check-label"
															htmlFor="comElibrary"
														>E-library Only</label>
													</div>

													{parseInt(type) !== 1 && isClass == true ?
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Class<span className="text-danger">*</span></strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow add-Question-arrow-position">
																	{props.classLoading ?
																		<Spinner animation="border" size="sm" />
																		: <i className="bi bi-caret-down-fill"></i>}
																</span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={className}
																	onChange={(e) => setClassName(e.target.value)}
																	onClick={getClassHandler}
																>
																	<option value="">Select class</option>
																	{
																		props.classList.map((item, index) => {
																			return <option key={index} value={item.class_no}>{item.short_code}</option>
																		})
																	}
																</select>
															</div>
															{errors.className && <div className="text-danger fs-12">{errors.className}</div>}
														</div>
														:
														null
													}

													{isLibrary ?
														null :
														<>
															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Exam set count<span className="text-danger">*</span></strong>
																</label>
																<input className="form-control" type="number" placeholder="Enter the set count" value={setConunt} onChange={(e) => setSetConunt(e.target.value)} />
																{errors.setConunt && <div className="text-danger fs-12">{errors.setConunt}</div>}
															</div>

															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Question per set<span className="text-danger">*</span></strong>
																</label>
																<input className="form-control" type="number" placeholder="Enter the question per set" value={questionSet} onChange={(e) => setQuestionSet(e.target.value)} />
																{errors.questionSet && <div className="text-danger fs-12">{errors.questionSet}</div>}
															</div>

															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Amount<span className="text-danger">*</span></strong>
																</label>
																<input className="form-control" type="number" placeholder="Enter the amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
																{errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
															</div>
														</>
													}
													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>E-Library Price<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" type="number" placeholder="Enter the E-library price" value={elibraryPrice} onChange={(e) => setElibraryPrice(e.target.value)} />
														{errors.ePrice && <div className="text-danger fs-12">{errors.ePrice}</div>}
													</div>

													<div className="form-check mt-3 mb-3">
														<input
															type="checkbox"
															className="form-check-input"
															id="sticker"
															name="sticker"
															checked={isSticker}
															onChange={(e) => setIsSticker(e.target.checked)}
														/>
														<label
															className="form-check-label"
															htmlFor="sticker"
														>Sticker Exist</label>
													</div>

													{isSticker ?
														<div className="form-group mb-3 mt-2">
															<label className="mb-2">
																<strong>Sticker<span className="text-danger">*</span></strong>
															</label>
															<input 
															className="form-control" 
															type="text" 
															placeholder="Sticker" 
															value={sticker} 
															onChange={(e) => setSticker(e.target.value)} 
															/>
															{errors.sticker && <div className="text-danger fs-12">{errors.sticker}</div>}
														</div>
														: null}

													<div className="form-group mb-3 mt-2">
														<label className="mb-2">
															<strong>Course Code<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" type="text" placeholder="Course Code" value={courseCode} onChange={(e) => setCourseCode(e.target.value)} />
														{errors.courseCode && <div className="text-danger fs-12">{errors.courseCode}</div>}
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.subscription.showLoading,
		typeLoading: state.type.showLoading,
		typeList: state.type.list,
		classLoading: state.class.showLoading,
		classList: state.class.list,
	};
};
export default connect(mapStateToProps)(AddCompetetiveSubscription);