import React, { useEffect, useState } from "react";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { MisDashboardInfoData } from "../../../store/actions/MisDashboardInfoAction";
import { useDispatch, connect } from "react-redux";

const MisDashBoard = (props) => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(1);

  useEffect(() => {
    getDta();
  }, [period]);

  const getDta = () => {
    dispatch(globalLoadingAction(true));
    dispatch(MisDashboardInfoData(period));
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card height">
                <div class="container">
                  <div className="basic-dropdown MisFilter">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control filter-form-control-input"
                      onChange={(e) => {
                        setPeriod(e.target.value);
                      }}
                    >
                      <option value="1">Today</option>
                      <option value="7">Last 7 days</option>
                      <option value="15">Last 15 days</option>
                      <option value="30">Last 1 month</option>
                      <option value="90">Last 3 months</option>
                      <option value="365">Last 12 months</option>
                    </select>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <div class="info-box info-box-1">
                        <h5>Guest Student Login</h5>

                        <p>{props.dashboardInfo.total_guest_login_count}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="info-box info-box-2">
                        <h5>Demo Libray Visit </h5>
                        <p>{props.dashboardInfo.demo_libray_visit_student}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="info-box info-box-3">
                        <h5> Demo To Register </h5>
                        <p>{props.dashboardInfo.convert_demo_reg_student}</p>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="info-box info-box-4">
                        <h5>Library Time Spend</h5>
                        <p>{props.dashboardInfo.library_details_time_spend}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardInfo: state.MisDashboardInfo.data,
  };
};

export default connect(mapStateToProps)(MisDashBoard);
