import {
    uploadCareer, uploadCareerGuideLine
} from '../../services/CareerGuideServices';

import {
    CAREER_LOADING,
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function uploadCareerData(file, history) {
    return (dispatch) => {
        uploadCareer(file)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function uploadCareerGuideLineData(file, history) {
    return (dispatch) => {
        uploadCareerGuideLine(file)
            .then((response) => {
                console.log("**********---", response)
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}



export function loadingCareerAction(status) {
    return {
        type: CAREER_LOADING,
        payload: status,
    };
}
