import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function addChapters(
  exam_category_id,
  exam_board,
  sub_type,
  standard,
  chapter_name,
  branch_id,
  sub_heading,
  chapter_no
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board,
    sub_type,
    standard,
    chapter_name,
    branch_id,
    sub_heading,
    chapter_no,
  };

  // console.log("postData--", postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.SET_CHAPTERS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateChapters(
  recid,
  exam_category_id,
  exam_board,
  sub_type,
  standard,
  chapter_name,
  branch_id,
  sub_heading,
  chapter_no
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
    exam_category_id,
    exam_board,
    sub_type,
    standard,
    chapter_name,
    branch_id,
    sub_heading,
    chapter_no,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_CHAPTERS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getChaptersDeffarentsParams(
  category_id,
  board_id,
  exam_type_id,
  subtype_id,
  subject_id,
  class_id
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    category_id,
    board_id,
    exam_type_id,
    subtype_id,
    subject_id,
    class_id,
  };
 
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CHAPTERS_FOR_DEFFARENTS_PARAMS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getChapters(
  page,
  board_id,
  exam_type_id,
  exam_subtype_id,
  standard,
  category_id,
  branch_id
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    page,
    board_id,
    exam_type_id,
    exam_subtype_id,
    standard,
    category_id,
    branch_id,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CHAPTERS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getChaptersFilter() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CHAPTERS_FILTTER_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getChaptersWithoutPagination(branch_id,board_id,exam_type_id,exam_subtype_id,standard,category_id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  console.log("branch id is postData",branch_id)

  const postData={
    branch_id,
    board_id,
    exam_type_id,
    exam_subtype_id,
    standard,
    category_id
  }

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CHAPTERS_WITHOUT_PAGINATION,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data:postData
  });
}

export function getChaptersByBranch(branch_id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    branch_id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CHAPTERS_BY_BRANCH_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateStatusChapter(recid, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
    status,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_STATUS_CHAPTERS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeChapters(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_CHAPTERS_API,
    method: "DELETE",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

 export function getChapterOrdering( order_no,recid,page, board_id, exam_type_id, exam_subtype_id, standard, category_id, branch_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
    order_no,recid,page, board_id, exam_type_id, exam_subtype_id, standard, category_id, branch_id
   }

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_CHAPTER_WITH_ORDER_NO,
        method: "POST",
        headers: {
           ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
       data: postData,
    });

 }
