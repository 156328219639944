import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function uploadSchool(upload_excel) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("upload_excel", upload_excel);

  const postData = {
    upload_excel,
  };
  // console.log("postData", postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPLOAD_SCHOOL_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
}

export function getSchool(page, board,region,pincode, school_name, order_name, order_date_modified ) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;



  const postData = {
    page,
    board,
    region,
    pincode, 
    school_name, 
    order_name, 
    order_date_modified,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.SCHOOL_LIST_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateSchool(
  school_id,
  region,
  pincode,
  board,
  school_name,
  school_address,
  website,
  contact_number,
  alternate_contact_number,
  name_of_head,
  enrollment_date,
  affiliation_no,
  affiliation_period_from,
  affiliation_period_to,
  school_status,
  foundation_year,
  academic_year
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    school_id,
    region,
    pincode,
    board,
    school_name,
    school_address,
    website,
    contact_number,
    alternate_contact_number,
    name_of_head,
    enrollment_date,
    affiliation_no,
    affiliation_period_from,
    affiliation_period_to,
    school_status,
    foundation_year,
    academic_year,
  };

  //   console.log("postData..", postData);
  //   return;
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_SCHOOL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeSchool(school_id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    school_id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_SCHOOL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function addNewSchool(
  region,
  pincode,
  board,
  school_name,
  school_address,
  website,
  contact_number,
  alternate_contact_number,
  name_of_head,
  email,
  enrollment_date,
  affiliation_no,
  affiliation_period_from,
  affiliation_period_to,
  school_status,
  academic_year,
  foundation_year
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    region,
    pincode,
    board,
    school_name,
    school_address,
    website,
    contact_number,
    alternate_contact_number,
    name_of_head,
    email,
    enrollment_date,
    affiliation_no,
    affiliation_period_from,
    affiliation_period_to,
    school_status,
    academic_year,
    foundation_year,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_NEW_SCHOOL,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
