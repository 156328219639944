import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { loadingElibraryAction, getContentDeveloperListData, assignLibraryData } from '../../../store/actions/ElibraryAction';
import { getExamCategory } from '../../../store/actions/ExamCategoryAction';
import { getBoardData } from "../../../store/actions/BoardAction"
import { getExamType } from "../../../store/actions/ExamTypeAction"
// import { checkVerifi } from '../../../store/actions/CheckVerifiAction';
import { getClassData } from '../../../store/actions/ClassAction';
import { getBranchDetails } from '../../../store/actions/BranchActions';
import { getbranchTypeData } from '../../../store/actions/ChapterAction';


const AssignLibrary = (props) => {

    const dispatch = useDispatch();
    const fileRef = useRef();

    let errorsObj = { category: '', board: '', subject: '', chapter: '', classroom: '', developerlist: '', examType: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [classroom, setClassroom] = useState('');
    const [subject, setSubject] = useState('');
    const [chapter, setChapter] = useState('');
    const [developerlist, setDeveloperlist] = useState('');
    const [category, setCategory] = useState('');
    const [board, setBoard] = useState('');
    const [examType, setExamType] = useState('');

    useEffect(() => {
        dispatch(getClassData(props.history));
        dispatch(getBranchDetails(props.history));
        dispatch(getExamCategory(props.history));
        dispatch(getBoardData(props.history));
        dispatch(getContentDeveloperListData(props.history));
    }, []);

    /* form submit */
    function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (category === '') {
            errorObj.category = 'Category is Required';
            error = true;
        }
        if (category == 1 &&  board === '') {
            errorObj.board = 'Board is Required';
            error = true;
        }
        if (category == 2 && examType === '') {
            errorObj.examType = 'Examtype is Required';
            error = true;
        }
        if (subject === '') {
            errorObj.subject = 'Subject is Required';
            error = true;
        }
        if (chapter === '') {
            errorObj.chapter = 'Chapter is Required';
            error = true;
        }
        if ((category == 1 && classroom === '') || (category == 2 &&  examType == 2 && classroom === '') ) {
            errorObj.classroom = 'class is required';
            error = true;
        }
        if (developerlist === '') {
            errorObj.developerlist = 'Developer is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        
        // dispatch(checkVerifi());
        dispatch(loadingElibraryAction(true));
        dispatch(assignLibraryData(subject, chapter, classroom, category, examType, board, developerlist, props.history))
        setClassroom('');
        setSubject('');
        setChapter('');
        setDeveloperlist('');
        setCategory('');
        setBoard('');
        setExamType('');
    }

    /* on change subject  */
    const onSubjecthHandler = (event) => {
        setSubject(event.target.value);
        dispatch(getbranchTypeData(event.target.value));
    }

    /* on category */
    const onCategoryHandler = (event) => {
        event.target.value == 1 ?
            setCategory(event.target.value) :
            setCategory(event.target.value)
        dispatch(getExamType(event.target.value, props.history));
    }

    /* on change exam type */
    const onEaxmTypedHandler = (event) => {
        setExamType(event.target.value)
    }

    /* on change board */
    const onBoardHandler = (event) => {
        setBoard(event.target.value)
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Category</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={category}
                                                                onChange={onCategoryHandler}
                                                            >
                                                                <option value="">Select Category</option>
                                                                {
                                                                    props.categoryList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.category}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.category && <div className="text-danger fs-12">{errors.category}</div>}
                                                        </div>
                                                    </div>

                                                    {category == 1 || category == '' ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Board</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={board}
                                                                    onChange={onBoardHandler}
                                                                >
                                                                    <option value="">Select Board</option>
                                                                    {
                                                                        props.boardList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {category == 2 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Exam Type</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={examType}
                                                                    onChange={onEaxmTypedHandler}
                                                                >
                                                                    <option value="">Select Examtype</option>
                                                                    {
                                                                        props.typeList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.type_name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.examType && <div className="text-danger fs-12">{errors.examType}</div>}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {category == 1 || category == '' || examType == 2 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class</strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={classroom}
                                                                    onChange={(e) => setClassroom(e.target.value)}
                                                                >
                                                                    <option value="">Select Class</option>
                                                                    {
                                                                        props.classList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>class {item.class_no}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                {errors.classroom && <div className="text-danger fs-12">{errors.classroom}</div>}
                                                            </div>
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Subject</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={subject}
                                                                onChange={onSubjecthHandler}
                                                            >

                                                                <option value="">Select Subject</option>
                                                                {
                                                                    props.branchList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.branch_name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Chapter</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={chapter}
                                                                onChange={(e) => setChapter(e.target.value)}
                                                            >
                                                                <option value="">Select chapter</option>
                                                                {
                                                                    props.chapterList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.chapter_name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.chapter && <div className="text-danger fs-12">{errors.chapter}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Developer List</strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={developerlist}
                                                                onChange={(e) => setDeveloperlist(e.target.value)}
                                                            >
                                                                <option value="">Select Developer</option>
                                                                {
                                                                    props.contentDeveloperList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.developerlist && <div className="text-danger fs-12">{errors.developerlist}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Submit
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div><ToastContainer /></div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.elibrary.showLoading,
        branchList: state.branch.branchList,
        chapterList: state.chapter.typelist,
        classList: state.class.list,
        boardList: state.board.list,
        categoryList: state.category.list,
        typeList: state.type.list,
        contentDeveloperList: state.elibrary.libararyDeveloperList
    };
};
export default connect(mapStateToProps)(AssignLibrary);