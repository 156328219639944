import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { boardByClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, subjectLoading, clearSubjectList } from '../../../store/actions/SujectActions';
import { updateLibrarySubscriptionData, subscriptionLoadingAction } from '../../../store/actions/SubscriptionAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamCategory } from '../../../store/actions/ExamCategoryAction';


const UpdateLibrarySubscription = (props) => {


    let history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    let errorsObj = { category: '', type: '', board: '', subject: '', className: '', ePrice: '', sticker: '', courseCode: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [category, setCategory] = useState(state.item?.exam_category);
    const [type, setType] = useState(state.item?.exam_type_id);
    const [board, setBoard] = useState(state.item?.board_id);
    const [subject, setSubject] = useState(state.item?.subject_id);
    const [className, setClassName] = useState(state.item?.class_id);
    const [elibraryPrice, setElibraryPrice] = useState(state.item?.library_price);
    const [sticker, setSticker] = useState(state.item?.sticker_text);
    const [isSticker, setIsSticker] = useState(state.item?.sticker_text == "" || state.item?.sticker_text == null ? false : true);
    const [courseCode, setCourseCode] = useState(state.item?.course_code)

    const [previousPageFilterData, setPreviousPageFilterData] = useState('');

    useEffect(() => {
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(loadingClassAction(true));
        dispatch(boardByClassData(props.history));
        dispatch(subjectLoading(true));
        dispatch(getSubjects(0, state.item?.board_id, 0, 0, props.history));
        dispatch(getExamCategory(props.history));
        dispatch(getExamType(props.history));

    }, []);

    useEffect(() => {
        setPreviousPageFilterData(props.location.state?.filterData)
    }, []);


    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        let error = false;

        const errorObj = { ...errorsObj };

        if (category === '') {
            errorObj.category = 'Exam type is Required';
            error = true;
        }
        if (type === '' && parseInt(category) == 2) {
            errorObj.type = 'Exam type is Required';
            error = true;
        }
        if (board === '' && parseInt(category) == 1) {
            errorObj.board = 'Exam type is Required';
            error = true;
        }
        if (subject === '' && parseInt(category) == 1) {
            errorObj.subject = 'Subject is Required';
            error = true;
        }
        if (className === '' && parseInt(category) !== 1) {
            errorObj.className = 'Class name is Required';
            error = true;
        }
        if (elibraryPrice === '') {
            errorObj.ePrice = 'E-library Price is Required';
            error = true;
        }
        if (sticker === '' && isSticker == true) {
            errorObj.sticker = 'Sticker Exist is Required';
            error = true;
        }

        if (courseCode === '') {
            errorObj.courseCode = 'Course Code is Required';
            error = true;
        }


        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(subscriptionLoadingAction(true));
        dispatch(updateLibrarySubscriptionData(state.item?.id, parseInt(category), parseInt(category) == 2 ? type : 0, parseInt(category) == 1 ? board : 0, parseInt(category) == 1 ? subject : 0, parseInt(type) == 1 ? 0 : className, elibraryPrice, isSticker ? sticker : "", courseCode, props.history));
    }

    /* clear subject old list */
    const getBoardHandler = () => {
        dispatch(clearSubjectList([]));
    }

    /* get subject list */
    const getSubjectHandler = () => {
        if (board) {
            dispatch(subjectLoading(true));
            dispatch(getSubjects(0, board, 0, 0, props.history));
        }
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
			pathname: "/library-subscription-list",
			state: {previousPageFilterData},
		  });

        // history.goBack();
    }

    return (
        <>
            <div className="row">
                {console.log('state', state)}
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <Link
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Category<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow add-Question-arrow-position">
                                                                <i className="bi bi-caret-down-fill"></i>
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={category}
                                                                onChange={(e) => setCategory(e.target.value)}
                                                            >
                                                                <option value="">select category</option>
                                                                {
                                                                    props.categoryList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.category}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                                    </div>

                                                    {
                                                        parseInt(category) == 2 ?


                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Exam type name<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <div className="basic-dropdown">
                                                                    <span className="drop_down_arrow add-Question-arrow-position">
                                                                        <i className="bi bi-caret-down-fill"></i>
                                                                    </span>
                                                                    <select
                                                                        defaultValue={"option"}
                                                                        className="form-control form-control"
                                                                        value={type}
                                                                        onChange={(e) => setType(e.target.value)}
                                                                    >
                                                                        <option value="">Select Exam type</option>
                                                                        {
                                                                            props.typeList.map((item, index) => {
                                                                                return <option key={index} value={item.id}>{item.type_name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="form-group mb-3">
                                                                    <label className="mb-2">
                                                                        <strong>Board name<span className="text-danger">*</span></strong>
                                                                    </label>
                                                                    <div className="basic-dropdown">
                                                                        <span className="drop_down_arrow add-Question-arrow-position">
                                                                            <i className="bi bi-caret-down-fill"></i>
                                                                        </span>
                                                                        <select
                                                                            defaultValue={"option"}
                                                                            className="form-control form-control"
                                                                            value={board}
                                                                            onClick={getBoardHandler}
                                                                            onChange={(e) => setBoard(e.target.value)}
                                                                        >
                                                                            <option value="">Select Board</option>
                                                                            {
                                                                                props.boardList.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                                </div>

                                                                <div className="form-group mb-3">
                                                                    <label className="mb-2">
                                                                        <strong>Subject name<span className="text-danger">*</span></strong>
                                                                    </label>
                                                                    <div className="basic-dropdown">
                                                                        <span className="drop_down_arrow">
                                                                            {props.subjectLoading ?
                                                                                <Spinner animation="border" size="sm" />
                                                                                : <i className="bi bi-caret-down-fill"></i>}
                                                                        </span>
                                                                        <select
                                                                            defaultValue={"option"}
                                                                            className="form-control form-control"
                                                                            value={subject}
                                                                            onClick={getSubjectHandler}
                                                                            onChange={(e) => setSubject(e.target.value)}
                                                                        >
                                                                            <option value="">Subject name</option>
                                                                            {
                                                                                props.subjectList.map((item, index) => {
                                                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {errors.subject && <div className="text-danger fs-12">{errors.subject}</div>}
                                                                </div>

                                                            </>
                                                    }

                                                    {parseInt(type) === 1 ?
                                                        null
                                                        :
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    <i className="bi bi-caret-down-fill"></i>
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={className}
                                                                    onChange={(e) => setClassName(e.target.value)}
                                                                >
                                                                    <option value="">Select class</option>
                                                                    {
                                                                        props.classList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.short_code}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.className && <div className="text-danger fs-12">{errors.className}</div>}
                                                        </div>
                                                    }

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>E-Library Price<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="number" placeholder="Enter the E-library price" value={elibraryPrice} onChange={(e) => setElibraryPrice(e.target.value)} />
                                                        {errors.ePrice && <div className="text-danger fs-12">{errors.ePrice}</div>}
                                                    </div>

                                                    <div className="form-check mt-3 mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="sticker"
                                                            name="sticker"
                                                            checked={isSticker}
                                                            onChange={(e) => setIsSticker(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="sticker"
                                                        >Sticker Exist</label>
                                                    </div>

                                                    {isSticker ?
                                                        <div className="form-group mb-3 mt-2">
                                                            <label className="mb-2">
                                                                <strong>Sticker<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <input className="form-control" type="text" placeholder="Sticker" value={sticker} onChange={(e) => setSticker(e.target.value)} />
                                                            {errors.sticker && <div className="text-danger fs-12">{errors.sticker}</div>}
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3 mt-2">
                                                        <label className="mb-2">
                                                            <strong>Course Code<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Course Code" value={courseCode} onChange={(e) => setCourseCode(e.target.value)} />
                                                        {errors.courseCode && <div className="text-danger fs-12">{errors.courseCode}</div>}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Update
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.subscription.showLoading,
        classLoading: state.class.showLoading,
        classList: state.class.boardByClasslist,
        boardList: state.board.list,
        subjectLoading: state.subject.showLoading,
        subjectList: state.subject.subjectList,
        categoryList: state.category.list,
        typeList: state.type.list,
    };
};
export default connect(mapStateToProps)(UpdateLibrarySubscription);