import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Elibrary from "./components/Elibrary";
import Question from "./components/Question";

const AssignDeveloper = (props) => {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(0);

  useEffect(() => {}, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-3 pb-5">
                <div className="row">
                  <div className="col-xl-1"></div>
                  <div className="col-xl-10">
                    <div className="card-body">
                      <div className="assign-container">
                        <div className="assign-btn-view pb-5">
                          <div className="form-check pe-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="elibrary"
                              name="assignRadios"
                              value="0"
                              defaultChecked
                              onChange={(e) => setActivePage(e.target.value)}
                            />
                            <label
                              htmlFor="elibrary"
                              className="form-check-label"
                            >
                              E-library
                            </label>
                          </div>
                          <div className="form-check ps-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="question"
                              name="assignRadios"
                              value="1"
                              onChange={(e) => setActivePage(e.target.value)}
                            />
                            <label
                              htmlFor="question"
                              className="form-check-label"
                            >
                              Question
                            </label>
                          </div>
                        </div>
                        <div>
                          {activePage == 0 ? <Elibrary /> : <Question />}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    assignDataClear: state.assign.assignDataClear,
    branchList: state.branch.branchList,
    chapterList: state.chapter.typelist,
    showLoadingChapter: state.chapter.showLoading,
    classList: state.class.list,
    boardList: state.board.list,
    categoryList: state.category.list,
    typeList: state.type.list,
    patternList: state.pattern.list,
    developerlist: state.user.contentDeveloperList,
    subjectList: state.subject.subjectList,
    subjectshowLoading: state.subject.showLoading,
    getSingleSubjectList: state.subject.getSingleSubjectList,
  };
};
export default connect(mapStateToProps)(AssignDeveloper);
