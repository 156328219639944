import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Card, Table, Button, Modal, Spinner } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Moment from "react-moment";
import PreviewCard from "../ExamContent/component/PreviewCard";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  updateAssignQuestionContentData,
  cancelQuestionRequestDetailsById,
  updateQuestionStatusByAdminData,
  getDetailsDeveloperContentForQuestionData,
} from "../../../store/actions/AssignContentAction";
import { getCancelQuestionsReasonData } from "../../../store/actions/ReasonAction";

const ViewAssignQuestion = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const { state } = useLocation();

  let errorsObj = {
    reason: "",
    question: "",
    otherReason: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [submissionDate, setSubmissionDate] = useState("");
  const [isCheking, setIsCheking] = useState("");
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [isCorrection, setIsCorrection] = useState(false);
  const [questionPreviewDetails, setQuestionPreviewDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(null);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getDetailsDeveloperContentForQuestionData(state.id, props.history)
    );
    dispatch(getCancelQuestionsReasonData(props.history));
  }, []);

  /* on Correcton Submit */
  const onCorrectionSubmit = () => {
    let error = false;
    const errorObj = { ...errorsObj };

    if (reason === "" || reason === null) {
      errorObj.reason = "Reason is Required";
      error = true;
    }
    if (reason == 0 && otherReason.trim() === "") {
      errorObj.otherReason = "Reason is Required";
      error = true;
    }
    if (submissionDate === "") {
      errorObj.date = "Submission date is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      cancelQuestionRequestDetailsById(
        state,
        reason == 0 ? otherReason : reason,
        submissionDate ? moment(submissionDate).format("YYYY-MM-DD") : "",
        2,
        props.history
      )
    );
  };

  /* On continue Question */
  const onCotinueHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      cancelQuestionRequestDetailsById(state, "", "", 1, props.history)
    );
  };

  /* Question status submit */
  const onQuestionStatusSubmit = (status) => {
    setLoading(true);
    setLoadingStatus(status);
    dispatch(
      updateQuestionStatusByAdminData(
        questionPreviewDetails?.id,
        status,
        resetQuestionStatus,
        props.history
      )
    );
  };

  /* reset question status */
  const resetQuestionStatus = () => {
    setLoading(false);
    setLoadingStatus(null);
    setIsPreview(false);
    dispatch(
      getDetailsDeveloperContentForQuestionData(state.id, props.history)
    );
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.replace({
      pathname: "/assign-question-list",
      state: state.filterObj,
    });
  };

  /* preview handler */
  const onPreViewHandler = (item) => {
    setQuestionPreviewDetails(item);
    setIsPreview(true);
  };

  /* change radio handler */
  const onChangeRadioHandler = (event) => {
    setReason(event.target.value);
  };

  /* check status */
  const checkingStatus = () => {
    let status = false;
    props.questionContentDetailsList.forEach((element) => {
      if (element.is_approve == 0) {
        status = true;
        return;
      }
    });
    return status;
  };

  /* continue status check */
  const continueStatus = () => {
    let status = true;
    props.questionContentDetailsList.forEach((element) => {
      if (element.is_approve == 1) {
        status = false;
        return;
      }
    });
    return status;
  };

  /* status correction */
  const correctionStatus = () => {
    let status = true;
    props.questionContentDetailsList.forEach((element) => {
      if (element.is_approve == 2) {
        status = false;
        return;
      }
    });
    return status;
  };

  /* get taotal question */
  const getTotalQuestions = () => {
    let status = 0;
    let arry = state.question_no_details;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number && arry[x].rate) {
        status += parseInt(arry[x].number);
      }
    }

    return status == 0 ? "" : status;
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5 pe-3 ps-3">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-view-form">
       
                        <div className="ms-5 me-5">
                          <div className="column-item">
                            <h4>
                              <strong>No. of Question : </strong>{" "}
                              {/* {state.total_no_questions} */}
                              {getTotalQuestions()}
                            </h4>
                          </div>
                          <div className="column-item">
                            <h4>
                              <strong>Date of Submission : </strong>{" "}
                              <Moment format="DD/MM/YYYY">
                                {state.date_of_submission}
                              </Moment>
                            </h4>
                          </div>
                          <div className="column-item">
                            <h4>
                              <strong>Amount : </strong>{" "}
                              {(() => {
                              switch (parseInt(state.developer_approval_status)) {
                                case 1:
                                  return (
                                    <>
                                    {state.developer_payment}/-
                                    </>
                                  );
                                case 2:
                                  return (
                                    <>
                                    {state.validator_payment}/-
                                    </>
                                  );
                                case 3:
                                  return (
                                    <>
                                    {state.language_validator_payment}/-
                                    </>
                                  );
                                case 4:
                                  return (
                                    <>
                                    {state.designer_payment}/-
                                    </>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                            </h4>
                          </div>
                        </div>
                        <div className="ms-5 me-5">
                          <div className="question-column-item">
                            <div>
                              <h4>
                                <strong>Question type : </strong>
                              </h4>
                            </div>
                            {state.question_no_details.length > 0 && (
                              <div className="question-column-item-value">
                                {state.question_no_details.map(
                                  (item, index) => {
                                    return (
                                      <h4>
                                        {item.type} - {item.number}
                                      </h4>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
                <div className="row">
                  <div className="table-scrollable-view">
                    {props.questionContentDetailsList.length > 0 ? (
                      <>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <strong>#</strong>
                              </th>
                              <th>
                                <strong>Question Type</strong>
                              </th>
                              <th>
                                <strong>Question No.</strong>
                              </th>
                              <th>
                                <strong>Group Id</strong>
                              </th>
                              <th>
                                <strong>Status</strong>
                              </th>
                              <th>
                                <strong>Action</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.questionContentDetailsList.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.question_type}</td>
                                    <td>{item.question_no}</td>
                                    <td>{item.css_group_id}</td>
                                    <td>
                                      {(() => {
                                        switch (parseInt(item.is_approve)) {
                                          case 0:
                                            return (
                                              <Button
                                                className="btn-xs"
                                                variant="warning"
                                              >
                                                Pending
                                              </Button>
                                            );
                                          case 1:
                                            return (
                                              <Button
                                                className="btn-xs"
                                                variant="success"
                                              >
                                                Accepted
                                              </Button>
                                            );
                                          case 2:
                                            return (
                                              <Button
                                                className="btn-xs"
                                                variant="danger"
                                              >
                                                Rejected
                                              </Button>
                                            );
                                          default:
                                            return null;
                                        }
                                      })()}
                                    </td>
                                    <td>
                                      <Button
                                        onClick={() => onPreViewHandler(item)}
                                        disabled={
                                          item.is_approve == 0 ? false : true
                                        }
                                        className="btn-xs btn-info-color"
                                        variant={
                                          item.status == 1
                                            ? "success"
                                            : "danger"
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                        <div>
                          <div className="form-check mt-3 mb-3">
                            <input
                              disabled={checkingStatus()}
                              type="checkbox"
                              className="form-check-input"
                              id="checking"
                              name="checking"
                              checked={isCheking}
                              onChange={(e) => setIsCheking(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checking"
                            >
                              Checking Done
                            </label>
                          </div>
                        </div>
                        <div className="question-btn-group">
                          <button
                            onClick={() => setIsCorrection(true)}
                            disabled={!isCheking || correctionStatus()}
                            type="button"
                            className="btn btn-primary"
                          >
                            Correction
                          </button>
                          <button
                            onClick={() => onCotinueHandler()}
                            disabled={!isCheking || continueStatus()}
                            type="button"
                            className="btn btn-primary"
                          >
                            Continue
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="no_data">
                        <strong>No data found</strong>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade bd-example-modal-lg" show={isPreview} size="lg">
        <Modal.Header>
          <Modal.Title>Preview</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsPreview(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-editor">
            <PreviewCard data={questionPreviewDetails} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-modal-btn-view">
            <Button
              variant="success"
              disabled={loading}
              className="spinner-btn"
              onClick={() => onQuestionStatusSubmit(1)}
            >
              Accept
              {loading && loadingStatus == 1 ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </Button>
            <Button
              variant="danger"
              disabled={loading}
              className="spinner-btn"
              onClick={() => onQuestionStatusSubmit(2)}
            >
              Reject
              {loading && loadingStatus == 2 ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={isCorrection} size="md">
        <Modal.Header>
          <Modal.Title>What is Correct ?</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsCorrection(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <div onChange={onChangeRadioHandler} className="mb-3">
              {props.reasonList.map((item, index) => {
                return (
                  <div className="form-check" key={item.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      value={item.message}
                      id={`rejectReason${item.id}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`rejectReason${item.id}`}
                    >
                      {item.message}
                    </label>
                  </div>
                );
              })}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="reason"
                  value="0"
                  id="other"
                />
                <label className="form-check-label" htmlFor="other">
                  Other
                </label>
              </div>
              {errors.reason && (
                <div className="text-danger fs-12">{errors.reason}</div>
              )}
            </div>
            {reason == 0 ? (
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Reason<span className="text-danger">*</span>
                  </strong>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Please specify the reason to correction..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
                {errors.otherReason && (
                  <div className="text-danger fs-12">{errors.otherReason}</div>
                )}
              </div>
            ) : null}
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Date Of Submission<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="date-control-view">
                <DatePicker
                  ref={calendarRef}
                  selected={submissionDate}
                  placeholderText="Select the submission date"
                  onChange={(date) => setSubmissionDate(date)}
                  showYearDropdown
                  showMonthDropdown
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  onChangeRaw={handleDateChangeRaw}
                />
                <button
                  type="button"
                  className="picker-btn"
                  onClick={() => calendarRef.current.setOpen(true)}
                >
                  <i className="fa-solid fa-calendar-days"></i>
                </button>
              </div>
              {errors.date && (
                <div className="text-danger fs-12">{errors.date}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-modal-btn-view">
            <Button variant="success" onClick={() => onCorrectionSubmit()}>
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    showLoadingChapter: state.chapter.showLoading,
    patternList: state.pattern.list,
    documentDetails: state.assign.questionDocumentDetails,
    questionContentDetailsList: state.assign.questionContentDetailsList,
    developerlist: state.user.contentDeveloperList,
    contentvalidatorList: state.contentvalidator.list,
    languagevalidatorList: state.languagevalidator.list,
    contentdesignerList: state.contentdesigner.list,
    reasonList: state.reason.cancelReasonList,
  };
};
export default connect(mapStateToProps)(ViewAssignQuestion);
