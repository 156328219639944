import { GET_MIS_FAILED_REGISTRATION_LIST } from "../constants";

const initial = {
  list: [],
};

export function MisFailedRegistrationReducer(state = initial, action) {
  if (action.type == GET_MIS_FAILED_REGISTRATION_LIST) {
    return {
      ...state,
      list: action.payload,
    };
  } else {
    return state;
  }
}
