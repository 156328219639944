import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { validateText, validateShortCode } from '../../../utility/Utility';

import { updateBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateBoard = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation();

	let errorsObj = { board: '', code: '', fullBoardname: '', conducted: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [boardName, setBoardName] = useState(state.name);
	const [fullBoardName, setFullBoardName] = useState(state.full_name);
	const [conductedBy, setConductedBy] = useState(state.conducted_by);
	const [shortCode, setShortCode] = useState(state.short_code);

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (boardName === '') {
			errorObj.board = 'Board name is Required';
			error = true;
		} else if (validateText(boardName) === false) {
			errorObj.board = 'Invalid Exam Board name';
			error = true;
		}
		if (fullBoardName === '') {
			errorObj.fullBoardname = 'Full board name is Required';
			error = true;
		}
		if (conductedBy === '') {
			errorObj.conducted = 'Conducted by is Required';
			error = true;
		}
		if (shortCode === '') {
			errorObj.code = 'Board Short code is Required';
			error = true;

		} else if (validateShortCode(shortCode) === false) {
			errorObj.code = 'Invalid Short code';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(updateBoardData(state.id, boardName, fullBoardName, conductedBy, shortCode, props.history));
		setBoardName('');
		setShortCode('');
	}

	const onGoBackHandler = () => {
		history.goBack();
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3">
										<div className="back-arrow-container">
											<Link
												onClick={onGoBackHandler}>
												<i class="fa-solid fa-arrow-left"></i>
											</Link>
										</div>
									</div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Board name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the board name" value={boardName} onChange={(e) => setBoardName(e.target.value)} />
														{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Full board name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the full board name" value={fullBoardName} onChange={(e) => setFullBoardName(e.target.value)} />
														{errors.fullBoardname && <div className="text-danger fs-12">{errors.fullBoardname}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Conducted by<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the conducted by" value={conductedBy} onChange={(e) => setConductedBy(e.target.value)} />
														{errors.conducted && <div className="text-danger fs-12">{errors.conducted}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Board short code<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the short code" value={shortCode} onChange={(e) => setShortCode(e.target.value)} />
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
	};
};
export default connect(mapStateToProps)(UpdateBoard);