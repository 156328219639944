import {
  GET_ADVERTIESMEST_LIST,
  GET_ADVERTIESMEST_SCREEN_LIST,
} from "../constants";

const initialState = {
  list: [],
  screenList: [],
  showLoading: false,
};

export function AdvertisementReducer(state = initialState, action) {
  if (action.type === GET_ADVERTIESMEST_LIST) {
    return {
      ...state,
      list: action.payload,
      showLoading: false,
    };
  }

  if (action.type === GET_ADVERTIESMEST_SCREEN_LIST) {
    return {
      ...state,
      screenList: action.payload,
      showLoading: false,
    };
  }

  return state;
}
