import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Button,
	Pagination
} from "react-bootstrap";
import Moment from 'react-moment';

import RankingTextInput from './component/RankingTextInput';

import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, subjectLoading } from '../../../store/actions/SujectActions';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getSchoSubscriptionData, removeSchoSubscriptionData, updateSchoSubscriptionStatusData, getSubscriptionRankingList } from '../../../store/actions/SubscriptionAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ScholasticSubscriptionList = (props) => {

	const dispatch = useDispatch();
	const { state } = useLocation();

	const previousFilterData = props?.location.state?.previousPageFilterData;

	const [active, setActive] = useState(previousFilterData?.active ?? 1);
	const [subject, setSubject] = useState('');
	const [board, setBoard] = useState(previousFilterData?.board ?? '');
	const [classNo, setClassNo] = useState(previousFilterData?.classNo ?? '');
	const [ranking, setRanking] = useState({ rank0: '', rank1: '', rank2: '', rank3: '', rank4: '', rank5: '', rank6: '', rank7: '', rank8: '', rank9: '' });
	const [permission, setPermission] = useState("");


	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getSchoSubscriptionData(active, subject, board, classNo, props.history));
	}, []);

	useEffect(() => {
		getBoardHandler();
		getClassHandler();
	}, [previousFilterData]);

	/* delete confirmation */
	const onDeleteHandler = (id) => {

		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				if (props.scholasticList.length == 1) {
					dispatch(removeSchoSubscriptionData(id, active - 1, props.history));
					setActive(active - 1)
				} else {
					dispatch(removeSchoSubscriptionData(id, active, props.history));
				}

			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {

		dispatch(globalLoadingAction(true));
		dispatch(updateSchoSubscriptionStatusData(item, props.history));
	}

	// const onSubjectHandler = (id) => {
	// 	setSubject(id);
	// 	setActive(1);
	// 	dispatch(globalLoadingAction(true));
	// 	dispatch(getSchoSubscriptionData(1, id, board, classNo, props.history));
	// }

	// const getSubjectHandler = () => {
	// 	if (props.subjectList.length == 0) {
	// 	dispatch(subjectLoading(true));
	// 	dispatch(getSubjects(props.history));
	// 	}
	// }

	/* change board */
	const onBoardHandler = (id) => {
		setClassNo('');
		setBoard(id);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getSchoSubscriptionData(1, subject, id, id ? classNo : '', props.history));
	}

	/* get board list */
	const getBoardHandler = () => {
		if (props.boardList.length == 0) {
			dispatch(loadingBoardAction(true));
			dispatch(getBoardData(props.history));
		}
	}

	/* change class */
	const onClassHandler = (no) => {
		setClassNo(no);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getSchoSubscriptionData(1, subject, board, no, props.history));
	}

	/* get class list */
	const getClassHandler = () => {
		if (props.classList.length == 0) {
			dispatch(loadingClassAction(true));
			dispatch(getClassData(props.history));
		}
	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getSchoSubscriptionData(data.selected + 1, subject, board, classNo, props.history));
	}

	/* change on blur */
	const onBlurHandler = (val, id) => {
		dispatch(globalLoadingAction(true));
		dispatch(getSubscriptionRankingList(val, id, active, subject, board, classNo, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 55) {
				setPermission(item);
			}
		});
	};

	const resetHandler = () => {
		setClassNo('')
		setBoard('')
		setActive(1)
		dispatch(globalLoadingAction(true));
		dispatch(getSchoSubscriptionData(1, '', '', '', props.history));

		const stateCopy = { ...props?.location.state?.previousPageFilterData };
		delete stateCopy.active;
		delete stateCopy.board;
		delete stateCopy.classNo;
		props.history.replace({ state: stateCopy });
	}

	return (
		<Fragment>
			<Row>
				{/* {console.log('hello world', props.scholasticList)} */}
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="row add-Question-list-view">

								{/* <div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position">
											{props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>

										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={subject}
											onChange={(e) => onSubjectHandler(e.target.value)}
											onClick={getSubjectHandler}
										>
											<option value="">Select subject</option>
											{
												props.subjectList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div> */}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position">
											{/* {props.boardLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
											<i className="bi bi-caret-down-fill"></i>
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={board}
											onChange={(e) => onBoardHandler(e.target.value)}
											onClick={getBoardHandler}
										>
											<option value="">Select board</option>
											{
												props.boardList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position">
											{props.classLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={classNo}
											onChange={(e) => onClassHandler(e.target.value)}
											onClick={getClassHandler}
										>
											<option value="">Select class</option>
											{
												props.classList.map((item, index) => {
													return <option key={index} value={item.class_no}>{item.short_code}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="col-sm-1 px-1 ">
									<button onClick={resetHandler} className={`btn btn-secondary ${board == '' && classNo == '' ? 'content_disable' : null}`}>Reset</button>
								</div>

							</div>
							{
								props.totalRecord == 0 ? (
									<div className='no_data'>
										<strong >No data found</strong>
									</div>
								) : (
									<Table responsive>
										<thead>
											<tr>

												<th>
													<strong>Subject</strong>
												</th>
												<th>
													<strong>Board</strong>
												</th>
												<th>
													<strong>Class</strong>
												</th>
												<th>
													<strong>Sticker text</strong>
												</th>
												<th>
													<strong>Ranking</strong>
												</th>
												<th>
													<strong>Date Time</strong>
												</th>
												{
													permission.status == "true" && (
														<th width={120}>
															<strong>Status</strong>
														</th>
													)
												}

												{
													(permission.edit == "true" || permission.delete == "true") && (
														<th>
															<strong>Action</strong>
														</th>
													)
												}
											</tr>
										</thead>
										<tbody>
											{
												props.scholasticList.map((item, index) => {
													return <tr key={index}>
														<td>{item.subject_name}</td>
														<td>{item.board_name}</td>
														<td>{item.class_no}</td>
														<td>{item.sticker_text == '' ? '-' : item.sticker_text}</td>
														<td>
															<RankingTextInput rank={item.ranking_no} onBlurHandler={(r) => onBlurHandler(r, item.id)} />
														</td>
														<td><Moment format="D MMM YYYY hh:mm a" withTitle>{item.created_at}</Moment></td>
														{
															permission.status == "true" && (
																<td>
																	<Button
																		disabled={props.showLoading}
																		className="btn-xs"
																		variant={item.status == 1 ? "success" : "danger"}
																		onClick={() => onUpdateStatus(item)}
																	>
																		{item.status == 1 ? "Active" : "Inactive"}
																	</Button>
																</td>
															)
														}
														{
															(permission.edit == "true" || permission.delete == "true") && (
																<td>
																	<div className="d-flex">

																		{
																			permission.edit == "true" && (
																				<Link
																					to={{
																						pathname: '/update-scholastic-subscription',
																						state: { item, filterData: { active, board, classNo } }
																					}}
																					className="btn btn-warning shadow btn-xs sharp me-1"
																				>
																					<i className="fas fa-pencil-alt"></i>
																				</Link>
																			)
																		}

																		{
																			permission.delete == "true" && (
																				<Button
																					className="btn btn-danger shadow btn-xs sharp"
																					onClick={() => onDeleteHandler(item.id)}
																				>
																					<i className="fa fa-trash"></i>
																				</Button>
																			)
																		}

																	</div>
																</td>
															)
														}
													</tr>
												})
											}

										</tbody>

									</Table>

								)
							}

							<div className="pagination-container">
								<div className="page-result">
									{props.totalRecord !== 0 && <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.scholasticList.length)} of {props.totalRecord}</p>}
								</div>
								{props.totalRecord > 10 ?
									<ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i class="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i class="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={pageHandler}
										pageRangeDisplayed={3}
										pageCount={props.totalPage}
									/>
									: null}
							</div>

						</Card.Body>

					</Card>

				</Col>

			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		scholasticList: state.subscription.scholasticList,
		totalRecord: state.subscription.totalScholasticRecord,
		totalPage: state.subscription.totalScholasticPage,
		subjectLoading: state.subject.showLoading,
		subjectList: state.subject.subjectList,
		boardLoading: state.board.showLoading,
		boardList: state.board.list,
		classLoading: state.class.showLoading,
		classList: state.class.list,
	};
};
export default connect(mapStateToProps)(ScholasticSubscriptionList);