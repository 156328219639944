import {
    CONTENT_DESIGNER_LOADING,
    GET_CONTENT_DESIGNER_LIST,
    UPDATE_CONTENT_DESIGNER,
    UPDATE_CONTENT_DESIGNER_STATUS,
    REMOVE_CONTENT_DESIGNER,
} from '../constants';

const initialState = {
    list: [],
    totalPage: 0,
    totalRecord: 0,
    showLoading: false,
};

export function ContentDesignerReducer(state = initialState, action) {

    if (action.type === GET_CONTENT_DESIGNER_LIST) {
        return {
            ...state,
            list: action.payload.data,
            totalPage: action.payload.page_count,
            totalRecord: action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CONTENT_DESIGNER_STATUS) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CONTENT_DESIGNER) {
        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === CONTENT_DESIGNER_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


