import {
    SETTINGS_LOADING,
    GET_SETTINGS
} from '../constants';

const initialState = {
    gst: '',
    scrollingData: '',
    termsConditions: '',
    privacyPolicy: '',
};

export function SettingsReducer(state = initialState, action) {

    if (action.type === GET_SETTINGS) {
        return {
            ...state,
            gst: action.payload.gst_rate,
            scrollingData: action.payload.scrolling_text,
            termsConditions: action.payload.terms_condition,
            privacyPolicy: action.payload.privacy_policy,
            showLoading: false,
        };
    }

    if (action.type === SETTINGS_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


