import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { globalLoadingAction } from "../../../../store/actions/GlobalAction";
import {
  addAssignContentData,
  assignDataClearAction,
} from "../../../../store/actions/AssignContentAction";
import {
  loadingUserAction,
  getUserTypeData,
  getDeveloperData,
} from "../../../../store/actions/UserAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../../store/actions/QuestionPatternAction";
import { getExamCategory } from "../../../../store/actions/ExamCategoryAction";
import { getBoardData } from "../../../../store/actions/BoardAction";
import { getExamType } from "../../../../store/actions/ExamTypeAction";
import { getClassData } from "../../../../store/actions/ClassAction";
import { getBranchDetails } from "../../../../store/actions/BranchActions";
import {
  getSubjects,
  subjectLoading,
  getSingleSubjectListDetails,
  getSingleSubjectListActions,
} from "../../../../store/actions/SujectActions";

import {
  getbranchTypeData,
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../../store/actions/ChapterAction";

const Elibrary = (props) => {
  const dispatch = useDispatch();
  const calendarRef = useRef();

  let errorsObj = {
    requestType: "",
    category: "",
    board: "",
    branch: "",
    chapter: "",
    classroom: "",
    developer: "",
    examType: "",
    question: "",
    payment: "",
    date: "",
    subject: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const [requestType, setRequestType] = useState("E");
  const [classroom, setClassroom] = useState("");
  const [branch, setBranch] = useState("");
  const [chapter, setChapter] = useState("");
  const [developer, setDeveloper] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [examType, setExamType] = useState("");
  const [payment, setPayment] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");
  const [subject, setsubject] = useState("");
  const [patternArray, setPatternArray] = useState([
    {
      type: "",
      value: "",
    },
  ]);

  const [spinnerAnimation, setSpinnerAnimation] = useState("border"); // border // grow
  const [spinnerBorderColor, setSpinnerBorderColor] = useState("primary"); // primary, secondary, success, danger, warning, info, light, dark

  const [subtype, setSubtype] = useState("");
  const [examTypeId, setExamTypeId] = useState("");

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    // dispatch(getSubjects(props.history));
    dispatch(getClassData(props.history));
    dispatch(getBranchDetails(props.history));

    dispatch(getExamType(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getUserTypeData(props.history));
    dispatch(getQuestionPattern(props.history));
    dispatch(getDeveloperData(0, "", "", "", props.history));
  }, []);

  useEffect(() => {
    if (props.assignDataClear == 1) {
      setClassroom("");
      setBranch("");
      setChapter("");
      setDeveloper("");
      setCategory("");
      setBoard("");
      setExamType("");
      setRequestType("");
      setPayment("");
      setSubmissionDate("");
      setsubject("");
      dispatch(assignDataClearAction(0));
    }
  }, [props.assignDataClear]);

  /* select exam type handler */
  const selectExamTypeHandler = (examType) => {
    setExamType(examType);
    dispatch(subjectLoading(true));
    dispatch(
      getSingleSubjectListDetails(0, 0, examType, 0, category, props.history)
    );
  };

  /* select board handler */
  const selectBoardHandler = (board) => {
    setBoard(board);
    dispatch(subjectLoading(true));
    dispatch(
      getSingleSubjectListDetails(0, board, 0, 0, category, props.history)
    );
  };

  /* select subject handler */
  const getSubjectHandler = () => {
    if (board) {
      dispatch(getSingleSubjectListActions([]));
      dispatch(subjectLoading(true));
      dispatch(
        getSingleSubjectListDetails(0, board, 0, 0, category, props.history)
      );
    } else {
      dispatch(getSingleSubjectListActions([]));
      dispatch(subjectLoading(true));
      dispatch(
        getSingleSubjectListDetails(0, 0, examType, 0, category, props.history)
      );
    }
  };

  /* select category Handeler */
  const selectCategoryHandeler = (e) => {
    dispatch(getSingleSubjectListActions([]));
    if (e.target.value != "") {
      setCategory(e.target.value);
      setExamType("");
      setClassroom(0);
      setBoard("");
    } else {
      setCategory(e.target.value);
      setBoard("");
    }
  };

  /* select chapter handler */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && examType == 1) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examType,
          0,
          subject,
          0,
          props.history
        )
      );
    }
    // else if (category == 2 && examType == 2) {
    else if (category == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          // examTypeId,
          examType,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    /* let str = '';
        for (let x = 0; patternArray.length > x; x++) {
    
            if (patternArray[x].type && patternArray[x].value) {
                str += `"${patternArray[x].type}":${patternArray[x].value},`
            }
        }
        let details = "{" + str.slice(0, -1) + "}"; */

    /* if (requestType === '') {
            errorObj.requestType = 'Content type is Required';
            error = true;
        } */
    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (board === "" && (category == 1 || category == "")) {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (examType === "" && category == 2) {
      errorObj.examType = "Examtype is Required";
      error = true;
    }
    /* if (branch === '') {
            errorObj.branch = 'Branch is Required';
            error = true;
        } */
    if (subject === "") {
      errorObj.subject = "Subject is Required";
      error = true;
    }
    if (chapter === "") {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (classroom === "" || (classroom === 0 && examType != 1)) {
      console.log("@1");
      errorObj.classroom = "class is required";
      error = true;
    } else if (classroom === "" && classroom === 0) {
      console.log("@2");
      errorObj.classroom = "class is required";
      error = true;
    } else if (classroom === "") {
      console.log("@2");
      errorObj.classroom = "class is required";
      error = true;
    }
    /* if (requestType == 'Q' && details === '{}') {
            errorObj.question = 'Question type is Required';
            error = true;
        } */
    if (submissionDate === "") {
      errorObj.date = "Submission date is Required";
      error = true;
    }
    if (payment === "") {
      errorObj.payment = "Amount is Required";
      error = true;
    }
    if (payment <= 0) {
      errorObj.payment = "Valid Amount is Required";
      error = true;
    }

    if (developer === "") {
      errorObj.developer = "Developer is Required";
      error = true;
    }
    // console.log("errorObj---", errorObj)

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    // dispatch(addAssignContentData(requestType, category, category == 1 ? board : examType, branch, chapter, examType == 'NT' ? 0 : classroom, requestType == 'Q' ? details : '', moment(submissionDate).format('YYYY-MM-DD'), payment, developer, props.history))
    dispatch(
      addAssignContentData(
        requestType,
        category,
        category == 1 ? board : examType,
        subject,
        classroom,
        chapter,
        moment(submissionDate).format("YYYY-MM-DD"),
        payment,
        developer,
        props.history
      )
    );
    /*  setClassroom('');
         setBranch('');
         setChapter('');
         setDeveloper('');
         setCategory('');
         setBoard('');
         setExamType('');
         setRequestType('');
         setPayment('');
         setSubmissionDate('');
         setsubject('') */
    /* setPatternArray([{
            'type': '',
            'value': '',
        }]); */
  }

  const resetExamType = () => {};

  const onBranchHandler = (val) => {
    setBranch(val);
    dispatch(getbranchTypeData(val, props.history));
  };

  const onRemoveHandler = (index) => {
    let arry = [...patternArray];
    arry.splice(index, 1);
    setPatternArray(arry);
  };
  const onChangeQwsType = (val, index) => {
    let arry = [...patternArray];
    arry[index].type = val;
    setPatternArray(arry);
  };
  const onChangeQwsTypeValue = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].value = val;
      setPatternArray(arry);
    }
  };

  const onAddHandler = () => {
    let arry = [...patternArray];
    let obj = {
      type: "",
      value: "",
    };
    arry.push(obj);
    setPatternArray(arry);
  };

  const onAddItem = () => {
    let arry = [...patternArray];
    let status;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].type === "" || arry[x].value === "") {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  const onQwsType = (val) => {
    let status;
    let arry = [...patternArray];
    let temp = arry.find((element) => element.type == val);
    if (temp == undefined) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  const onPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setPayment(val);
    }
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className="basic-form">
        <form onSubmit={onSubmit}>
          {/* <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Content Type<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={requestType}
                                                                onChange={(e) => setRequestType(e.target.value)}
                                                            >
                                                                <option value="">Select Content type</option>
                                                                <option value="E">e-library</option>
                                                                <option value="Q">Question</option>

                                                            </select>
                                                            {errors.requestType && <div className="text-danger fs-12">{errors.requestType}</div>}
                                                        </div>
                                                    </div> */}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Category<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={category}
                // onChange={(e) => setCategory(e.target.value)}
                onChange={(e) => selectCategoryHandeler(e)}
              >
                <option value="">Select Category</option>
                {props.categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.category}
                    </option>
                  );
                })}
              </select>
              {errors.category && (
                <div className="text-danger fs-12">{errors.category}</div>
              )}
            </div>
          </div>

          {category == 2 ? (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Exam Type<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={examType}
                  // onChange={(e) => setExamType(e.target.value)}
                  onChange={(e) => selectExamTypeHandler(e.target.value)}
                  // onClick={getSubjectHandler}
                >
                  <option value="">Select Examtype</option>
                  {props.typeList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.type_name}
                      </option>
                    );
                  })}
                </select>
                {errors.examType && (
                  <div className="text-danger fs-12">{errors.examType}</div>
                )}
              </div>
            </div>
          ) : (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Board<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={board}
                  // onChange={(e) => setBoard(e.target.value)}
                  onChange={(e) => selectBoardHandler(e.target.value)}
                  // onClick={getSubjectHandler}
                >
                  <option value="">Select Board</option>
                  {props.boardList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.board && (
                  <div className="text-danger fs-12">{errors.board}</div>
                )}
              </div>
            </div>
          )}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Subjects<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              {/* <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span> */}
              <span className="drop_down_arrow">
                {props.subjectshowLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              >
                <option value="">Select Subject</option>
                {true &&
                  // props.subjectList.map((item, index) => {
                  props.getSingleSubjectList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {errors.subject && (
                <div className="text-danger fs-12">{errors.subject}</div>
              )}
            </div>
          </div>

          {examType == 1 ? null : (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Class<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={classroom}
                  onChange={(e) => {
                    setClassroom(e.target.value);
                    setChapter("");
                  }}
                >
                  <option value="">Select Class</option>
                  {props.classList.map((item, index) => {
                    return (
                      <option key={index} value={item.class_no}>
                        {item.short_code}
                      </option>
                    );
                  })}
                </select>
                {errors.classroom && (
                  <div className="text-danger fs-12">{errors.classroom}</div>
                )}
              </div>
            </div>
          )}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Chapter<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                {props.showLoadingChapter ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={chapter}
                onChange={(e) => setChapter(e.target.value)}
                onClick={getChapterHandler}
              >
                <option value="">Select chapter</option>
                {props.chapterList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.chapter_name}
                    </option>
                  );
                })}
              </select>
              {errors.chapter && (
                <div className="text-danger fs-12">{errors.chapter}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Content Developer<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={developer}
                onChange={(e) => setDeveloper(e.target.value)}
              >
                <option value="">Select Content Developer</option>
                {props.developerlist.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errors.developer && (
                <div className="text-danger fs-12">{errors.developer}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Time Limit<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="date-control-view">
              <DatePicker
                ref={calendarRef}
                selected={submissionDate}
                placeholderText="Enter the submission date"
                onChange={(date) => setSubmissionDate(date)}
                showYearDropdown
                showMonthDropdown
                // minDate={new Date() }
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                onChangeRaw={handleDateChangeRaw}
              />
              <button
                type="button"
                className="picker-btn"
                onClick={() => calendarRef.current.setOpen(true)}
              >
                <i className="fa-solid fa-calendar-days"></i>
              </button>
            </div>
            {errors.date && (
              <div className="text-danger fs-12">{errors.date}</div>
            )}
          </div>

          {/* {requestType == 'Q' ?
                                                        <div className="form-group mb-3">
                                                            <label>
                                                                <strong>Question Type<span className="text-danger">*</span></strong>
                                                            </label>
                                                            {
                                                                patternArray.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className="inline-form-group-view">
                                                                            <div className="basic-dropdown flex-1">
                                                                                <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                                                <select
                                                                                    defaultValue={"option"}
                                                                                    className="form-control"
                                                                                    value={item.type}
                                                                                    onChange={(e) => onChangeQwsType(e.target.value, index)}
                                                                                >
                                                                                    <option value="">Select question type</option>
                                                                                    {
                                                                                        props.patternList.map((i, idex) => {
                                                                                            return <option key={idex} value={i.short_code} disabled={onQwsType(i.short_code)}>{i.short_code}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <input className="form-control form-control-pattan" placeholder="Enter value" value={item.value} onChange={(e) => onChangeQwsTypeValue(e.target.value, index)} maxLength="3" />
                                                                            {index === 0 ?
                                                                                <button type="button" className="plus-btn" onClick={onAddHandler} disabled={onAddItem()}><i class="fa-solid fa-plus"></i></button>
                                                                                :
                                                                                <button type="button" onClick={() => onRemoveHandler(index)} className="plus-btn"><i className="fa-solid fa-minus"></i></button>

                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            {errors.question && <div className="text-danger fs-12">{errors.question}</div>}
                                                        </div>
                                                        : null} */}

          {/* <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Submission Date<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="date-control-view">
                                                            <DatePicker
                                                                ref={calendarRef}
                                                                selected={submissionDate}
                                                                placeholderText="Enter the submission date"
                                                                onChange={(date) => setSubmissionDate(date)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                maxDate={new Date()}
                                                            />
                                                            <button type='button' className="picker-btn"
                                                                onClick={() => calendarRef.current.setOpen(true)}
                                                            >
                                                                <i className="fa-solid fa-calendar-days"></i>
                                                            </button>
                                                        </div>
                                                        {errors.date && <div className="text-danger fs-12">{errors.date}</div>}
                                                    </div> */}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Amount<span className="text-danger">*</span>
              </strong>
            </label>
            <input
              className="form-control place_Holder"
              placeholder="Enter the amount"
              value={payment}
              onChange={(e) => onPaymentHandler(e.target.value)}
              maxLength="5"
            />
            {errors.payment && (
              <div className="text-danger fs-12">{errors.payment}</div>
            )}
          </div>

          {/* <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Assign developer<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={developer}
                                                                onChange={(e) => setDeveloper(e.target.value)}
                                                            >
                                                                <option value="">Select Developer</option>
                                                                {
                                                                    props.developerlist.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.developer && <div className="text-danger fs-12">{errors.developer}</div>}
                                                        </div>
                                                    </div> */}

          <div className="text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-block category-btn"
              disabled={props.showLoading}
            >
              Assign
              {props.showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    assignDataClear: state.assign.assignDataClear,
    branchList: state.branch.branchList,
    chapterList: state.chapter.typelist,
    showLoadingChapter: state.chapter.showLoading,
    classList: state.class.list,
    boardList: state.board.list,
    categoryList: state.category.list,
    typeList: state.type.list,
    patternList: state.pattern.list,
    developerlist: state.user.contentDeveloperList,
    subjectList: state.subject.subjectList,
    subjectshowLoading: state.subject.showLoading,
    getSingleSubjectList: state.subject.getSingleSubjectList,
  };
};
export default connect(mapStateToProps)(Elibrary);
