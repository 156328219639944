import { Fragment, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { getFeedBackData } from "../../../store/actions/FeedBackAction";
import statusUpdate from "../../../store/actions/FeedBackAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { deleteFeedback, deleteFeedBackAction } from "../../../store/actions/FeedBackAction";
import swal from "sweetalert";

const FeedbackQuestionList = (props) => {

  const dispatch = useDispatch();

  const previousFilterData = props?.location.state;

  const [permission, setPermission] = useState("");
  const [type, setType] = useState(previousFilterData?.type ?? '');
  // const [type, setType] = useState("");


  const feedback_delete = useSelector((state) => state.feedback.feedback_delete);

  useEffect(() => {
    permissionCheck();
  }, []);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getFeedBackData(type, props.history));
  }, [type]);

  useEffect(() => {
    if (feedback_delete == 1) {
      dispatch(globalLoadingAction(true));
      dispatch(getFeedBackData(type, props.history));
      dispatch(deleteFeedBackAction(0));
    }
  }, [feedback_delete]);


  /* status updated */
  const statushandler = (recid, status) => {
    dispatch(globalLoadingAction(true));
    dispatch(statusUpdate(recid, status == 1 ? 0 : 1));
  };

  /* const deleteHandler = (recid) => {
    dispatch(globalLoadingAction(true))
    dispatch(deleteFeedback(recid))

  }; */

  /* delete confirmation */
  const deleteHandler = (recid) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(globalLoadingAction(true))
        dispatch(deleteFeedback(recid))
      }
    });
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 75) {
        setPermission(item);
      }
    });
  };

  const onResetHandler = () => {
    setType("")

    const stateCopy = { ...props?.location.state };
		delete stateCopy.type;
		props.history.replace({ state: stateCopy });
    
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row add-Question-list-view">
                <div className="col-sm-2 px-1 filter-class-library">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Select Type</option>
                      <option value="1">FSD</option>
                      <option value="2">CMC</option>
                      <option value="3">NBF</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-2 px-1 reset-btn-view-bottom-alignment">
                  <button
                    onClick={onResetHandler}
                    className={`btn btn-secondary ${type == '' ? 'content_disable' : null}`}
                  >
                    Reset
                  </button>
                </div>

              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Id</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>Question</strong>
                    </th>
                    {
                      permission.status == "true" && (
                        <th>
                          <strong>Status</strong>
                        </th>

                      )
                    }
                    {
                      (permission.edit == "true" || permission.delete == "true") && (
                        <th>
                          <strong>Action</strong>
                        </th>
                      )
                    }
                  </tr>
                </thead>
                {props.feedbackQuestions != '' ?
                  <tbody>

                    {props.feedbackQuestions.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.question_type == 1 ? 'FSD' : (item.question_type == 2 ? 'CMC' : (item.question_type == 3 ? 'NBF' : ''))}</td>
                          <td className="wide-cell">{item.questions}</td>

                          {
                            permission.status == "true" && (
                              <td>
                                <Button
                                  //disabled={props.showLoading}
                                  className="btn-xs"
                                  variant={item.status == 1 ? "success" : "danger"}
                                  onClick={() => {
                                    statushandler(item.id, item.status);
                                  }}
                                >
                                  {item.status == 1 ? "Active" : "Inactive"}
                                </Button>
                              </td>
                            )
                          }
                          {
                            (permission.edit == "true" || permission.delete == "true") && (
                              <td>
                                <div className="d-flex">
                                  {
                                    permission.edit == "true" && (
                                      <Link
                                        to={{
                                          pathname: '/feedback-update',
                                          state: { item, filterData: { type } }
                                        }}
                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    )
                                  }
                                  {
                                    permission.delete == "true" && (
                                      <Button
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={() => {
                                          deleteHandler(item.id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )
                                  }
                                </div>
                              </td>
                            )
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                  : <tbody>
                    <td colSpan={12}>
                      <div className='no_data'>
                        <strong >No data found</strong>
                      </div>
                    </td>
                  </tbody>
                }
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    //showLoading: state.Feedback.showLoading,
    feedbackQuestions: state.feedback.list,
  };
};

export default connect(mapStateToProps)(FeedbackQuestionList);
