import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisRegisterUser(datediff,board,className,pincode,subscribe_status,appeared_after_register) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
 
  const postData = {
    datediff,
    board,
    class:className,
    pincode,
    subscribe_status,
    appeared_after_register
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REGISTER_USER_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
