import React from 'react';
import {
    Spinner
} from "react-bootstrap";

export default function Loader() {

    return (
        <>
            <div className="screec_container">
                <div className="loader-spinner-container">
                    <div className="spinner-view">
                        <Spinner
                            as="span"
                            animation="border"
                            //size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
