import { globalLoadingAction } from "./GlobalAction";
import * as utility from "../../utility/Utility";
import { getResumes,updateResumeStatus,invidualResume } from "../../services/ResumeServucers";
import { GET_RESUMES,GET_INDIVIDUAL_RESUME } from "../constants";


export function getResumeDetails(page,apply_for,apply_date_start,apply_date_end, history) {
    return (dispatch) => {
        getResumes(page,apply_for,apply_date_start,apply_date_end,)
        .then((response) => {
          if (response.data.status == 200) {
           
           dispatch(getResumeAction(response.data));
            dispatch(globalLoadingAction(false));
          }
        })
        .catch((error) => {
          //console.log(error);
          utility.showError(error.response.data);
          dispatch(globalLoadingAction(false));
        });
    };
  }

  export function updateResumeStatusData(id,active,applyFor,startDate,endDate,comment,history) {
    return (dispatch) => {
      updateResumeStatus(id,comment)
        .then((response) => {
          if (response.data.status == 200) { 
            dispatch(getResumeDetails(active,applyFor,startDate,endDate,history))
            dispatch(globalLoadingAction(false));
            utility.showSuccess(response.data.msg);
          }
        })
        .catch((error) => {
          //console.log(error);
          utility.showError(error.response.data);
          dispatch(globalLoadingAction(false));
        });
    };
  }

  export function individulResumeData(id,history) {
    return (dispatch) => {
      invidualResume(id)
        .then((response) => {
          if (response.data.status == 200) {
           
            dispatch(getIndividualResumeAction(response.data))
            
            dispatch(globalLoadingAction(false));
          }
        })
        .catch((error) => {
          //console.log(error);
          utility.showError(error.response.data);
          dispatch(globalLoadingAction(false));
        });
    };
  }


  export function getResumeAction(data) {
    
    return {
      type: GET_RESUMES,
      payload: data,
    };
  }

  export function getIndividualResumeAction(data) {
    
    return {
      type: GET_INDIVIDUAL_RESUME,
      payload: data,
    };
  }

