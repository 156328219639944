import {
    EXAM_CONTENT_LOADING,
    ADD_EXAM_CONTENT,
    GET_EXAM_CONTENT,
    UPLOAD_APPROVE_EXAM_CONTENT,
    REMOVE_EXAM_CONTENT,
    GET_TOTAL_CONTENT_PAGE_LIST,
    GET_EXAM_CONTENT_LIST,
    GET_QUESTION_ERROR_LIST,
    GET_TOTAL_ERROR_PAGE_LIST,
    GET_QUESTION_DETAILS,
    GET_SCECIAL_CHARACTER,
    ADD_QUESTION,
    UPDATE_QUESTION,
    UPDATE_QUESTION_ERROR_STATUS,
    UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS,
    REMOVE_MULTIPLE_QUESTION,
    DUPLICATE_QUESTIONS_STATUS_TOGGLE,
    RELOAD_CONTENT,
    GET_QUESTION_PREVIEW_DETAILS
} from '../constants';

const initialState = {
    count: 0,
    list: [],
    contentList: [],
    questionErrorList: [],
    totalPage: 0,
    totalQuestionRecord: 0,
    totalErrorPage: 0,
    totalErrorRecord: 0,
    questionDetails: [],
    characterList: [],
    showLoading: false,
    isStatus: false,
    duplicateQuestions: "",
    duplicateQuestionsStatus: 0,
    questionPreview: null,
};

export function ExamContentReducer(state = initialState, action) {

    if (action.type === ADD_EXAM_CONTENT) {
        return {
            ...state,
            duplicateQuestions: action.payload.duplicate_questions,
            duplicateQuestionsStatus: action.payload.duplicate_questions_status,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_CONTENT) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTION_DETAILS) {
        return {
            ...state,
            questionDetails: action.payload,
            isStatus: true,
            showLoading: false,
            count: state.count + 1,
        };
    }
    if (action.type === GET_SCECIAL_CHARACTER) {
        return {
            ...state,
            characterList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_EXAM_CONTENT_LIST) {
        return {
            ...state,
            contentList: action.payload.data,
            totalPage: action.payload.total_page,
            totalQuestionRecord: action.payload.total_questions_record,
            showLoading: false,
        };
    }
    if (action.type === ADD_QUESTION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_QUESTION) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_QUESTION_ERROR_STATUS) {

        const list = [...state.questionErrorList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 2 : 1;

        return {
            ...state,
            questionErrorList: list,
        };
    }

    if (action.type === GET_QUESTION_ERROR_LIST) {
        return {
            ...state,
            questionErrorList: action.payload.data,
            totalErrorPage: action.payload.total_page_count,
            totalErrorRecord: action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === GET_TOTAL_ERROR_PAGE_LIST) {
        return {
            ...state,
            totalErrorPage: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPLOAD_APPROVE_EXAM_CONTENT) {

        const list = [...state.contentList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].is_approve = parseInt(action.payload.is_approve) === 1 ? 0 : 1;

        return {
            ...state,
            contentList: list,
            showLoading: false,
        };
    }

    if (action.type === UPLOAD_MULTIPLE_APPROVE_QUESTION_STATUS) {

        const list = [...state.contentList];
        const idsArry = action.payload.ids;

        idsArry.forEach(element => {
            const index = list.findIndex((item) => item.id === element);
            list[index].is_approve = action.payload.status;
        });

        return {
            ...state,
            contentList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_MULTIPLE_QUESTION) {

        const list = [...state.contentList];
        const idsArry = action.payload.ids;

        idsArry.forEach(element => {
            const index = list.findIndex((item) => item.id === element);
            list.splice(index, 1);
        });

        return {
            ...state,
            contentList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EXAM_CONTENT) {

        const list = [...state.contentList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            contentList: list,
            showLoading: false,
        };
    }

    if (action.type === EXAM_CONTENT_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    if (action.type === DUPLICATE_QUESTIONS_STATUS_TOGGLE) {
        return {
            ...state,
            duplicateQuestionsStatus: action.payload,
        };
    }

    if (action.type === GET_QUESTION_PREVIEW_DETAILS) {
        return {
            ...state,
            questionPreview: action.payload,
        };
    }

    if (action.type === RELOAD_CONTENT) {
        return {
            ...state,
            isStatus: action.payload,
        };
    }

    return state;
}


