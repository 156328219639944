import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getQuestionPattern, loadingPatternAction } from '../../../store/actions/QuestionPatternAction';
import { updateExamConfigurationData, loadingConfiguration } from '../../../store/actions/ExamConfigurationAction';
import { getExamSubType } from '../../../store/actions/ExamSubTypeAction';
import { getBranchDetails, branchLoading } from '../../../store/actions/BranchActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';



const UpdateExamConfiguration = (props) => {

	let history = useHistory();
	const { state } = useLocation();
	const dispatch = useDispatch();

	let errorsObj = {
		category: '',
		type: '',
		subtype: '',
		board: '',
		branch: '',
		conType: '',
		question: '',
		questionNo: '',
	};

	const [errors, setErrors] = useState(errorsObj);
	const [category, setCategory] = useState(state.exam_category_id);
	const [type, setType] = useState(state.exam_type_id);
	const [subtype, setSubtype] = useState(state.exam_subtype_id);
	const [branch, setBranch] = useState(state.branch_id);
	const [questionNo, setQuestionNo] = useState(state.no_questions);
	const [board, setBoard] = useState(state.board_id);
	const [conType, setConType] = useState(state.type);
	const [patternArray, setPatternArray] = useState([{
		'type': '',
		'value': '',
	}]);

	useEffect(() => {
		if (state.exam_category_id == 1) {
			onParse();
		}
		dispatch(globalLoadingAction(true));
		dispatch(getExamCategory(props.history));
		dispatch(getExamType(props.history));
		dispatch(getBoardData(props.history));
		dispatch(getExamSubType(props.history));
		dispatch(getBranchDetails(props.history));
		dispatch(getQuestionPattern(props.history));
	}, []);

	const onParse = () => {
		let arry = [];
		let configuration = state.configuration_details;
		let parse = JSON.parse(configuration);
		let keys = Object.keys(parse);
		let values = Object.values(parse);
		keys.forEach((item, index) => {
			let temObj = {};
			temObj['type'] = item;
			temObj['value'] = values[index];
			arry.push(temObj);
		});
		setPatternArray(arry);
		console.log(configuration);
	}

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		let str = '';
		for (let x = 0; patternArray.length > x; x++) {
			if (patternArray[x].type && patternArray[x].value) {
				str += `"${patternArray[x].type}":${patternArray[x].value},`
			}
		}
		let details = "{" + str.slice(0, -1) + "}";

		if (category === '') {
			errorObj.category = 'Category is required';
			error = true;
		}
		if (board === '' && category == 1) {
			errorObj.board = 'Board is Required';
			error = true;
		}
		if (type === '' && category == 2) {
			errorObj.type = 'Exam type is required';
			error = true;
		}
		if (subtype === '' && category == 2) {
			errorObj.subtype = 'Exam sub type is required';
			error = true;
		}
		if (branch === '' && category == 2) {
			errorObj.branch = 'Branch is required';
			error = true;
		}
		if (conType === '' && category == 1) {
			errorObj.conType = 'Configuration type is required';
			error = true;
		}
		if (questionNo === '' && category == 2) {
			errorObj.questionNo = 'No. of quesion is required';
			error = true;
		}
		if (details === '{}' && category == 1) {
			errorObj.question = 'Question type is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));

		dispatch(updateExamConfigurationData(
			state.id,
			category,
			parseInt(category) == 1 ? board : 0,
			parseInt(category) == 2 ? type : 0,
			parseInt(category) == 2 ? subtype : 0,
			parseInt(category) == 2 ? branch : 0,
			parseInt(category) == 2 ? questionNo : 0,
			parseInt(category) == 1 ? conType : 0,
			parseInt(category) == 1 ? details : '',
			props.history));

		setType('');
		setBoard('');
		setPatternArray([{
			'type': '',
			'value': '',
		}]);

	}

	/* add handler */
	const onAddHandler = () => {

		let arry = [...patternArray];

		let obj = {
			'type': '',
			'value': '',
		};
		arry.push(obj);
		setPatternArray(arry);
	}

	/* on remove */
	const onRemoveHandler = (index) => {
		let arry = [...patternArray];
		arry.splice(index, 1);
		setPatternArray(arry);
	}
	/* onchange Qws type */
	const onChangeQwsType = (val, index) => {
		let arry = [...patternArray];
		arry[index].type = val;
		setPatternArray(arry);
	}

	/* on change Qws type value */
	const onChangeQwsTypeValue = (val, index) => {
		const re = /^[0-9\b]+$/;
		let arry = [...patternArray];
		if (val === '' || re.test(val)) {
			arry[index].value = val;
			setPatternArray(arry);
		}
	}

	/* navigation */
	const onGoBackHandler = () => {
		history.goBack();
	}

	/* on change Qws type */
	const onQwsType = (val) => {
		let status;
		let arry = [...patternArray];
		let temp = arry.find(element => element.type == val);
		if (temp == undefined) {
			status = false;
		} else {
			status = true;
		}
		return status;
	}

	/* on change Question type values */
	const onChangeQuestionTypeValue = (val) => {
		const re = /^[0-9\b]+$/;
		if (val === '' || re.test(val)) {
			setQuestionNo(val);
		}
	}

	return (
		<>
			<div className="row">
				{/* {console.log('state', state)} */}
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3">
										<div className="back-arrow-container">
											<Link
												onClick={onGoBackHandler}>
												<i class="fa-solid fa-arrow-left"></i>
											</Link>
										</div>
									</div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Category</strong>
														</label>
														<div className="basic-dropdown">
															<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
															<select
																defaultValue={"option"}
																className="form-control form-control"
																value={category}
																onChange={(e) => setCategory(e.target.value)}
															>
																<option value="">Select category</option>
																{
																	props.categoryList.map((item, index) => {
																		return <option key={index} value={item.id}>{item.category}</option>
																	})
																}
															</select>
														</div>
														{errors.category && <div className="text-danger fs-12">{errors.category}</div>}
													</div>

													{category == 2 ?

														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Exam type</strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={type}
																	onChange={(e) => setType(e.target.value)}
																>
																	<option value="">Select exam type</option>
																	{
																		props.typeList.map((item, index) => {
																			return <option key={index} value={item.id}>{item.type_name}</option>
																		})
																	}
																</select>
															</div>
															{errors.type && <div className="text-danger fs-12">{errors.type}</div>}
														</div>

														:

														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Board</strong>
															</label>
															<select
																defaultValue={"option"}
																className="form-control form-control"
																value={board}
																onChange={(e) => setBoard(e.target.value)}
															>
																<option value="">Select board</option>
																{
																	props.boardList.map((item, index) => {
																		return <option key={index} value={item.id}>{item.name}</option>
																	})
																}
															</select>
															{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
														</div>
													}

													{category == 2 ?
														<>
															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Exam sub type</strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={subtype}
																		onChange={(e) => setSubtype(e.target.value)}
																	>
																		<option value="">Select exam sub type</option>
																		{
																			props.subtypeList.map((item, index) => {
																				return <option key={index} value={item.id}>{item.subtype_name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
															</div>

															<div className="form-group mb-3">
																<label className="mb-2">
																	<strong>Branch</strong>
																</label>
																<div className="basic-dropdown">
																	<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
																	<select
																		defaultValue={"option"}
																		className="form-control form-control"
																		value={branch}
																		onChange={(e) => setBranch(e.target.value)}
																	>
																		<option value="">Select branch</option>
																		{
																			props.branchList.map((item, index) => {
																				return <option key={index} value={item.id}>{item.branch_name}</option>
																			})
																		}
																	</select>
																</div>
																{errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
															</div>
														</>
														:
														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Configuration type</strong>
															</label>
															<div className="basic-dropdown">
																<span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
																<select
																	defaultValue={"option"}
																	className="form-control form-control"
																	value={conType}
																	onChange={(e) => setConType(e.target.value)}
																>
																	<option value="">Select type</option>
																	<option value='1'>Set</option>
																	<option value='2'>Module</option>
																	<option value='3'>Mock</option>

																</select>
															</div>
															{errors.conType && <div className="text-danger fs-12">{errors.conType}</div>}
														</div>

													}

													{category == 2 ?

														<div className="form-group mb-3">
															<label>
																<strong>No. of Question</strong>
															</label>
															<input className="form-control" placeholder="No. of question" value={questionNo} onChange={(e) => onChangeQuestionTypeValue(e.target.value)} maxLength="6" />
															{errors.questionNo && <div className="text-danger fs-12">{errors.questionNo}</div>}
														</div>
														:

														<div className="form-group mb-3">
															<label className="mb-2">
																<strong>Question Type</strong>
															</label>
															{
																patternArray.map((item, index) => {
																	return (
																		<div key={index} className="inline-form-group-view">
																			<select
																				defaultValue={"option"}
																				className="form-control flex-1"
																				value={item.type}
																				onChange={(e) => onChangeQwsType(e.target.value, index)}
																			>
																				<option value="">Select question type</option>
																				{
																					props.patternList.map((i, idex) => {
																						return <option key={idex} value={i.short_code} disabled={onQwsType(i.short_code)}>{i.short_code}</option>
																					})
																				}
																			</select>
																			<input className="form-control form-control-pattan" placeholder="Enter value" value={item.value} onChange={(e) => onChangeQwsTypeValue(e.target.value, index)} maxLength="3" />
																			{index === 0 ?
																				<button type="button" className="plus-btn" onClick={onAddHandler}><i class="fa-solid fa-plus"></i></button>
																				:
																				<button type="button" onClick={() => onRemoveHandler(index)} className="plus-btn"><i class="fa-solid fa-minus"></i></button>

																			}
																		</div>
																	);
																})
															}

															{errors.question && <div className="text-danger fs-12">{errors.question}</div>}
														</div>
													}

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.ExamConfiguration.showLoading,
		categoryList: state.category.list,
		typeList: state.type.list,
		boardList: state.board.list,
		subtypeList: state.subtype.list,
		patternList: state.pattern.list,
		branchList: state.branch.branchList,
	};
};

export default connect(mapStateToProps)(UpdateExamConfiguration);