import swal from "sweetalert";
import {
    addType,
    getType,
    updateType,
    removeType,
    updateStatusType
} from '../../services/ExamTypeServices';

import {
    TYPE_LOADING,
    ADD_EXAM_TYPE,
    GET_EXAM_TYPE,
    UPDATE_EXAM_TYPE,
    REMOVE_EXAM_TYPE,
    UPDATE_STATUS_EXAM_TYPE
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addExamType(id, type, code, full_name, conducted_by, exam_type_image, history) {
    return (dispatch) => {
        addType(id, type, code, full_name, conducted_by, exam_type_image)
            .then((response) => {
                dispatch(addTypeAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getExamType(history) {
    return (dispatch) => {
        getType()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTypeAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function UpdateExamType(id, category_id, type, code, full_name, conducted_by, exam_type_image, history) {
    return (dispatch) => {
        updateType(id, category_id, type, code, full_name, conducted_by, exam_type_image)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateTypeAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                    history.goBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateStatusExamType(item, history) {
    return (dispatch) => {
        updateStatusType(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {

                dispatch(updateStatusTypeAction(item));
                dispatch(globalLoadingAction(false));
                utility.showSuccess(response.data.msg)
            })
            .catch((error) => {
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeExamType(id, history) {
    return (dispatch) => {
        removeType(id)
            .then((response) => {
                dispatch(removeTypeAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addTypeAction(data) {
    return {
        type: ADD_EXAM_TYPE,
        payload: data,
    };
}
export function getTypeAction(data) {
    return {
        type: GET_EXAM_TYPE,
        payload: data,
    };
}
export function updateTypeAction(data) {
    return {
        type: UPDATE_EXAM_TYPE,
        payload: data,
    };
}
export function updateStatusTypeAction(item) {
    return {
        type: UPDATE_STATUS_EXAM_TYPE,
        payload: item,
    };
}
export function removeTypeAction(id) {
    return {
        type: REMOVE_EXAM_TYPE,
        payload: id,
    };
}
export function loadingAction(status) {
    return {
        type: TYPE_LOADING,
        payload: status,
    };
}
