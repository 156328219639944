import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, clearSubjectList, subjectLoading } from '../../../store/actions/SujectActions';
import { updateIntegratedSubscriptionData, subscriptionLoadingAction } from '../../../store/actions/SubscriptionAction';

const defaultArray = [{ "set": '', "exam_type": '', "class": '', "is_class": '', "question_per_set": '' }];

const UpdateIntegratedSubscription = (props) => {

    const history = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    let errorsObj = { integrated: '', board: '', className: '', schoSet: '', competitive: '', elibray: '', sticker: '', price: '', courseCode: '' };

    const [errors, setErrors] = useState(errorsObj);

    const [integrated, setIntegrated] = useState(state.integrated_name);
    const [board, setBoard] = useState(state.board_id);
    const [className, setClassName] = useState(state.class);
    const [scholasticSet, setScholasticSet] = useState(state.sch_no_set);

    const [isLibrary, setIsLibrary] = useState(state.elibrary_exist === 1 ? true : false);
    const [sticker, setSticker] = useState(state.sticker_text);
    const [isSticker, setIsSticker] = useState(state.sticker_text ? true : false);
    const [courseCode, setCourseCode] = useState(state.course_code);
    const [elibraryPrice, setElibraryPrice] = useState(state.price);
    const [comExamDetails, setComExamDetails] = useState(state.com_exam_details ? JSON.parse(state.com_exam_details) : defaultArray);

    const [previousPageFilterData, setPreviousPageFilterData] = useState('');

    useEffect(() => {
        dispatch(getExamType(props.history));
        dispatch(getClassData(props.history));
    }, []);

    useEffect(() => {
        setPreviousPageFilterData(props.location.filterData)
    }, []);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (integrated === '') {
            errorObj.integrated = 'Integrated is Required';
            error = true;
        }

        if (board === '') {
            errorObj.board = 'Board is Required';
            error = true;
        }

        if (className === '') {
            errorObj.className = 'Class name is Required';
            error = true;
        }

        if (scholasticSet === '') {
            errorObj.schoSet = 'Set is Required';
            error = true;
        }

        // if (elibraryPrice === '' && isLibrary == true) {
        //     errorObj.ePrice = 'E-library Price is Required';
        //     error = true;
        // }

        if (sticker === '' && isSticker == true) {
            errorObj.sticker = 'Sticker Exist is Required';
            error = true;
        }

        comExamDetails.forEach((item) => {
            if (item.set === '' || item.exam_type === '' || (item.is_class == 1 && item.class === '') || item.question_per_set === '') {
                errorObj.competitive = 'Subscription package details is Required';
                error = true;
            }
        });

        if (courseCode === '') {
            errorObj.courseCode = 'Course Code is Required';
            error = true;
        }

        if (elibraryPrice === '') {
            errorObj.price = 'price is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(subscriptionLoadingAction(true));
        dispatch(updateIntegratedSubscriptionData(state.id, integrated, board, className, scholasticSet, comExamDetails, isLibrary ? 1 : 0, isSticker ? sticker : "", elibraryPrice, courseCode, props.history));

        // setIntegrated('');
        // setBoard('');
        // setClassName('');
        // setScholasticSet('');
        // setElibraryPrice('');
        // setIsLibrary(false);
        // setSticker('');
        // setIsSticker('');
        // setCourseCode('');
        // setComExamDetails([{ "set": '', "exam_type": '', "class": '', "is_class": '', "question_per_set": '' }]);
    }

    /* get board list */
    const getBoardHandler = () => {
        if (props.boardList.length == 0) {
            dispatch(loadingBoardAction(true));
            dispatch(getBoardData(props.history));
        }
        dispatch(clearSubjectList([]));
    }

    /* get class list */
    const getClassHandler = () => {
        if (props.classList.length == 0) {
            dispatch(loadingClassAction(true));
            dispatch(getClassData(props.history));
        }
    }

    /* change type */
    const onChangeTypeHandler = (val, index) => {
        let arry = [...comExamDetails];
        arry[index].exam_type = val;
        props.typeList.forEach((item) => {
            if (item.id == val) { arry[index].is_class = item.is_class }
        });
        setComExamDetails(arry);
    }

    /* change set */
    const onChangeSetHandler = (val, index) => {
        const re = /^[0-9\b]+$/;
        let arry = [...comExamDetails];
        if (val === '' || re.test(val)) {
            arry[index].set = val;
            setComExamDetails(arry);
        }
    }

    /* change question set */
    const onChangeQuestionSetHandler = (val, index) => {
        const re = /^[0-9\b]+$/;
        let arry = [...comExamDetails];
        if (val === '' || re.test(val)) {
            arry[index].question_per_set = val;
            setComExamDetails(arry);
        }
    }

    /* change class */
    const onChangeExamClassHandler = (val, index) => {
        let arry = [...comExamDetails];
        arry[index].class = val;
        setComExamDetails(arry);
    }

    /* add item */
    const addItemHandler = () => {
        let arry = [...comExamDetails];
        let obj = { "set": '', "exam_type": '', "class": '', "is_class": '', "question_per_set": '' }
        arry.push(obj);
        setComExamDetails(arry);
    }

    /* change status */
    const onStatusItem = () => {
        let arry = [...comExamDetails];
        let status;
        for (let x = 0; arry.length > x; x++) {
            if (arry[x].set === '' || arry[x].exam_type === '' || (arry[x].is_class == 1 && arry[x].class === '') || arry[x].question_per_set === '') {
                status = true;
            } else {
                status = false;
            }
        }
        return status;
    }

    /* remove item */
    const onRemoveItemHandler = (index) => {
        let arry = [...comExamDetails];
        arry.splice(index, 1);
        setComExamDetails(arry);
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
            pathname: "/integrated-subscription-list",
            state: previousPageFilterData,
        });

        // history.goBack();
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="col-xl-3">
                                    <div className="back-arrow-container">
                                        <Link
                                            onClick={onGoBackHandler}>
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-xl-2"></div>
                                    <div className="col-xl-8">

                                        <div className="card-body">
                                            <div className="basic-form">
                                                <form onSubmit={onSubmit}>
                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Integrated<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Enter the integrated" value={integrated} onChange={(e) => setIntegrated(e.target.value)}
                                                        />
                                                        {errors.integrated && <div className="text-danger fs-12">{errors.integrated}</div>}
                                                    </div>
                                                    <div className="scholastic-section-title">
                                                        <label>Scholastic Subscription:</label>
                                                    </div>

                                                    <div className="scholastic-section">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Board name<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    {props.boardLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={board}
                                                                    onClick={getBoardHandler}
                                                                    onChange={(e) => setBoard(e.target.value)}
                                                                >
                                                                    <option value="">Select Board</option>
                                                                    {
                                                                        props.boardList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.board && <div className="text-danger fs-12">{errors.board}</div>}
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    {props.classLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={className}
                                                                    onClick={getClassHandler}
                                                                    onChange={(e) => setClassName(e.target.value)}
                                                                >
                                                                    <option value="">Select class</option>
                                                                    {
                                                                        props.classList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.short_code}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.className && <div className="text-danger fs-12">{errors.className}</div>}
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="mb-2">
                                                                <strong>Set<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow">
                                                                    <i className="bi bi-caret-down-fill"></i>
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={scholasticSet}
                                                                    onChange={(e) => setScholasticSet(e.target.value)}
                                                                >
                                                                    <option value="">Select set</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>

                                                                </select>
                                                            </div>
                                                            {errors.schoSet && <div className="text-danger fs-12">{errors.schoSet}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="competitive-section-title">
                                                        <label>Competitive Subscription:</label>
                                                    </div>
                                                    <div className="competitive-container-section">
                                                        {
                                                            comExamDetails.map((item, index) => {
                                                                return (
                                                                    <div className="competitive-section mb-3">
                                                                        {index === (comExamDetails.length - 1) ?
                                                                            <button type="button" className="plus-btn" onClick={addItemHandler} disabled={onStatusItem()}><i class="fa-solid fa-plus"></i></button>
                                                                            :
                                                                            <button type="button" onClick={() => onRemoveItemHandler(index)} className="plus-btn"><i class="fa-solid fa-minus"></i></button>
                                                                        }
                                                                        <div className="form-group mb-3">
                                                                            <label className="mb-2">
                                                                                <strong>Exam type name<span className="text-danger">*</span></strong>
                                                                            </label>
                                                                            <div className="basic-dropdown">
                                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                                    <i className="bi bi-caret-down-fill"></i>
                                                                                </span>
                                                                                <select
                                                                                    defaultValue={"option"}
                                                                                    className="form-control form-control"
                                                                                    value={item.exam_type}
                                                                                    onChange={(e) => onChangeTypeHandler(e.target.value, index)}
                                                                                >
                                                                                    <option value="">Select Exam type</option>
                                                                                    {
                                                                                        props.typeList.map((item, index) => {
                                                                                            return <option key={index} value={item.id}>{item.type_name}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group mb-3">
                                                                            <label className="mb-2">
                                                                                <strong>Exam Set count<span className="text-danger">*</span></strong>
                                                                            </label>
                                                                            <input className="form-control" type="text" placeholder="Enter the set count" value={item.set} onChange={(e) => onChangeSetHandler(e.target.value, index)}
                                                                            />
                                                                        </div>

                                                                        <div className="form-group mb-3">
                                                                            <label className="mb-2">
                                                                                <strong>Question per set<span className="text-danger">*</span></strong>
                                                                            </label>
                                                                            <input className="form-control" type="text" placeholder="Enter the question per set" value={item.question_per_set} onChange={(e) => onChangeQuestionSetHandler(e.target.value, index)}
                                                                            />
                                                                        </div>
                                                                        {item.is_class == 0 ?
                                                                            null
                                                                            :
                                                                            <div className="form-group mb-3">
                                                                                <label className="mb-2">
                                                                                    <strong>Class<span className="text-danger">*</span></strong>
                                                                                </label>
                                                                                <div className="basic-dropdown">
                                                                                    <span className="drop_down_arrow add-Question-arrow-position">
                                                                                        {props.classLoading ?
                                                                                            <Spinner animation="border" size="sm" />
                                                                                            : <i className="bi bi-caret-down-fill"></i>}
                                                                                    </span>
                                                                                    <select
                                                                                        defaultValue={"option"}
                                                                                        className="form-control form-control"
                                                                                        value={item.class}
                                                                                        onClick={getClassHandler}
                                                                                        onChange={(e) => onChangeExamClassHandler(e.target.value, index)}
                                                                                    >
                                                                                        <option value="">Select class</option>
                                                                                        {
                                                                                            props.classList.map((item, index) => {
                                                                                                return <option key={index} value={item.id}>{item.short_code}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>}

                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        {errors.competitive && <div className="text-danger fs-12">{errors.competitive}</div>}
                                                    </div>

                                                    <div className="form-check mt-3 mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="shoElibrary"
                                                            name="shoElibrary"
                                                            checked={isLibrary}
                                                            onChange={(e) => setIsLibrary(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="shoElibrary"
                                                        >E-library Only</label>
                                                    </div>

                                                    <div className="form-check mt-3 mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="sticker"
                                                            name="sticker"
                                                            checked={isSticker}
                                                            onChange={(e) => setIsSticker(e.target.checked)}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="sticker"
                                                        >Sticker Exist</label>
                                                    </div>

                                                    {isSticker ?
                                                        <div className="form-group mb-3 mt-2">
                                                            <label className="mb-2">
                                                                <strong>Sticker<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <input className="form-control" type="text" placeholder="Sticker" value={sticker} onChange={(e) => setSticker(e.target.value)} />
                                                            {errors.sticker && <div className="text-danger fs-12">{errors.sticker}</div>}
                                                        </div>
                                                        : null}

                                                    <div className="form-group mb-3 mt-2">
                                                        <label className="mb-2">
                                                            <strong>Course Code<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Course Code" value={courseCode} onChange={(e) => setCourseCode(e.target.value)} />
                                                        {errors.courseCode && <div className="text-danger fs-12">{errors.courseCode}</div>}
                                                    </div>

                                                    <div className="form-group mb-3 mt-2">
                                                        <label className="mb-2">
                                                            <strong>Price<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" type="text" placeholder="Price" value={elibraryPrice} onChange={(e) => setElibraryPrice(e.target.value)} />
                                                        {errors.price && <div className="text-danger fs-12">{errors.price}</div>}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Submit
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-2"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.subscription.showLoading,
        classLoading: state.class.showLoading,
        classList: state.class.list,
        typeLoading: state.type.showLoading,
        typeList: state.type.list,
        boardLoading: state.board.showLoading,
        boardList: state.board.list,
        subjectLoading: state.subject.showLoading,
        subjectList: state.subject.subjectList,
    };
};
export default connect(mapStateToProps)(UpdateIntegratedSubscription);