import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { MisFailedRegistration,UpdateFailedRegistration } from "../../services/FailedRegistrationServices";
import { GET_MIS_FAILED_REGISTRATION_LIST } from "../constants";


export function MisFailedRegistrationData(
  datediff,
  user_problem,
  action_taken
) {
  return (dispatch) => {
    MisFailedRegistration(datediff, user_problem, action_taken)
      .then((response) => {
      
        if (response.data.status == 200) {
        
          dispatch(MisFailedRegistrationAction(response.data.data))
          dispatch(globalLoadingAction(false));
        } else {
         dispatch(
          MisFailedRegistrationAction([])
         )
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function UpdateFailedRegistrationData(id,message,reply_mail_date,status,period, userProblem, actionTaken) {
  return (dispatch) => {
    UpdateFailedRegistration(id,message,reply_mail_date,status)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(MisFailedRegistrationData(period, userProblem, actionTaken));
          //dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function MisFailedRegistrationAction(data){
  return{
    type:GET_MIS_FAILED_REGISTRATION_LIST,
    payload:data
  }
}
