import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { MisFailedSubscription,MisFailedSubscriptionUpdate } from "../../services/FailedSubscriptionServices";
import { GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST } from "../constants";

export function MisFailedSubscriptionData(
    datediff,trans_status,action_taken
  ) {
    return (dispatch) => {
        MisFailedSubscription(datediff,trans_status,action_taken)
        .then((response) => {
           
          if (response.data.status == 200) {
           
            dispatch(getFailedSubscriptionAction(response.data.data))
            dispatch(globalLoadingAction(false));
          } else {
           dispatch(
            getFailedSubscriptionAction([])
           )
          }
          dispatch(globalLoadingAction(false));
        })
        .catch((error) => {
          
          dispatch(globalLoadingAction(false));
          utility.showError(error.response.data);
        });
    };
  }

  export function MisFailedSubscriptionUpdateData(
    id,message,reply_mail_date,status,datediff,trans_status,action_taken
  ) {
    return (dispatch) => {
      MisFailedSubscriptionUpdate(id,message,reply_mail_date,status)
        .then((response) => {
           
          if (response.data.status == 200) {
           
            dispatch(MisFailedSubscriptionData(datediff,trans_status,action_taken))
            dispatch(globalLoadingAction(false));
          } 
          dispatch(globalLoadingAction(false));
        })
        .catch((error) => {
          
          dispatch(globalLoadingAction(false));
          utility.showError(error.response.data);
        });
    };
  }
  

  export function getFailedSubscriptionAction(data){
    
        return{
            type:GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST,
            payload:data
        }
  }