import { GET_DASHBOARD_INFO } from "../constants";

const initial = {
  data: {},
};

export function MisDashboardInfoReducer(state = initial, action) {
  if (action.type == GET_DASHBOARD_INFO) {
    return {
      ...state,

      data: action.payload,
    };
  } else {
    return state;
  }
}
