import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { uploadCareerData, uploadCareerGuideLineData, loadingCareerAction } from '../../../store/actions/CareerGuideAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UploadCareer = (props) => {

    const fileRef = useRef();
    const fileGuidelineRef = useRef();

    let errorsObj = { file: '', fileguideline:'' };
    // let errorsguidelineObj = { fileguideline: '' };
    
    const [errors, setErrors] = useState(errorsObj);
    // const [errorsGuideline, setErrorsGuideline] = useState(errorsguidelineObj);
    const [file, setFile] = useState(null);
    const [fileGuideline, setFileGuideline] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {

    }, []);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (file === null) {
            errorObj.file = 'File is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(uploadCareerData(file, props.history));
        fileRef.current.value = null;
    }

    /* file store in state */
    const handleChange = (event) => {
        setFile(event.target.files[0]);
    }

    /* guid line pdf submit */
    function onSubmitGuidelinePdf(e) {

        e.preventDefault();

        let error = false;
        // const errorsguidelineObj = { ...errorsguidelineObj };
        const errorObj = { ...errorsObj };
        
        if (fileGuideline === null) {
            errorObj.fileguideline = 'Guideline File is Required';
            error = true;
        }
        
        setErrors(errorObj);
        if (error) {
            return;
        }
        
        dispatch(globalLoadingAction(true));
        dispatch(uploadCareerGuideLineData(fileGuideline, props.history));
        fileGuidelineRef.current.value = null;
        setFileGuideline(null)
    }

    /* guid line pdf file store in state */
    const handleChangeGuidelinePdf = (event) => {
        setFileGuideline(event.target.files[0]);
    }

    return (
        <>
            <div className="row">

                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="mb-3">
                                                        <label htmlFor="formFileDisabled" className="form-label file-form-label">Upload career guide pdf file<span className="text-danger">*</span></label>
                                                        <input
                                                            ref={fileRef}
                                                            className="form-control"
                                                            type="file"
                                                            id="formFileDisabled"
                                                            accept=".pdf"
                                                            onChange={handleChange} />
                                                        {errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>}
                                                    </div>


                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Upload
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="basic-form">
                                                <form onSubmit={onSubmitGuidelinePdf}>
                                                    <div className="mb-3">
                                                        <label htmlFor="formFileDisabled" className="form-label file-form-label">Upload instruction pdf file<span className="text-danger">*</span></label>
                                                        <input
                                                            ref={fileGuidelineRef}
                                                            className="form-control"
                                                            type="file"
                                                            id="formFileDisabledGuideline"
                                                            accept=".pdf"
                                                            onChange={handleChangeGuidelinePdf} />
                                                        {errors.fileguideline && <div className="text-danger fs-12 mt-1">{errors.fileguideline}</div>}
                                                    </div>


                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>Upload Guideline
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        showLoading: state.school.showLoading,
    };
};

export default connect(mapStateToProps)(UploadCareer);