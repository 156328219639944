import {
    TYPE_OF_EXAM_LOADING,
    ADD_TYPE_OF_EXAM,
    UPDATE_TYPE_OF_EXAM,
    GET_TYPE_OF_EXAM,
    REMOVE_TYPE_OF_EXAM,
    UPDATE_STATUS_TYPE_OF_EXAM
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function TypeExamReducer(state = initialState, action) {

    if (action.type === ADD_TYPE_OF_EXAM) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_TYPE_OF_EXAM) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_TYPE_OF_EXAM) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_TYPE_OF_EXAM) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_TYPE_OF_EXAM) {

        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === TYPE_OF_EXAM_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


