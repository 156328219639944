import {
    BRANCH_LIST,
    ADD_BRANCH,
    BRANCH_LOADING,
    REMOVE_BRANCH,
    UPDATE_BRANCH,
    UPDATE_STATUS_BRANCH
} from '../constants'

const initialState = {
    branchList: [],
    showLoading: false,
};

export function BranchReducer(state = initialState, action) {

    if (action.type === BRANCH_LIST) {
        return {
            ...state,
            branchList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === ADD_BRANCH) {

        return {
            ...state,
            showLoading: false,
        };
    }
    
    if (action.type === UPDATE_BRANCH) {

        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_BRANCH) {

        const list = [...state.branchList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            branchList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_BRANCH) {

        const list = [...state.branchList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            branchList: list,
            showLoading: false,
        };
    }

    if (action.type === BRANCH_LOADING) {

        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


