import swal from "sweetalert";
import {
    updateProfile
} from '../../services/ProfileServices';

import {
    UPDATE_PROFILE,
    PROFILE_LOADING,
    SET_PROFILE_DATA
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function updateProfileData(name, email, phone, file, history) {
    return (dispatch) => {
        updateProfile(name, email, phone, file)
            .then((response) => {
                console.log('hello world',response.data);
                if(response.data.status){
                    dispatch(updateProfileAction(response.data.data));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateProfileAction(data) {
    return {
        type: UPDATE_PROFILE,
        payload: data,
    };
}
export function setProfileDataAction(data) {
    return {
        type: SET_PROFILE_DATA,
        payload: data,
    };
}
export function loadingProfileAction(status) {
    return {
        type: PROFILE_LOADING,
        payload: status,
    };
}
