import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

import {
  loginConfirmedAction,
  logout,
  checkTokenAction,
} from "../store/actions/AuthActions";

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.LOGIN_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
    },
    data: postData,
  });
}

export function forgotPassword(email) {
  const postData = {
    email,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.FORGOT_PASSWORD_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
    },
    data: postData,
  });
}

export function resetPassword(message, password) {
  const postData = {
    message,
    password,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.RESET_PASSWORD_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
    },
    data: postData,
  });
}

export function resendOTP(mobile) {
  const postData = {
    mobile,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.RESEND_OTP_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
    },
    data: postData,
  });
}

export function checkToken() {
  
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_TOKEN_EXIST_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function checkAutoLogin(dispatch, history) {
  
  const tokenDetailsString = localStorage.getItem("userDetails");
  const tokenDetails = JSON.parse(tokenDetailsString);

  if (tokenDetailsString) {
    dispatch(checkTokenAction(tokenDetails, history));
  } else {
    dispatch(logout(history));
    return;
  }
}

export function updateOldPasswordDetails(old_password, new_password ) {
  const postData = {
    old_password,
    new_password
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_UPDATE_OLD_PASSWORD_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
    },
    data: postData,
  });
}
