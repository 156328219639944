import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";

import { getTypeExamListData, removeTypeExamData, updateStatusTypeExamData } from '../../../store/actions/TypeExamAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const TypeExamList = (props) => {

	const dispatch = useDispatch();
	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getTypeExamListData(props.history));
	}, []);

	/* delect confirmation */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeTypeExamData(i, props.history));
			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateStatusTypeExamData(item, props.history));
	}
	
	const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
          if (item.id == 37) {
            setPermission(item);
          }
        });
      };
	return (
		<Fragment>
			
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Type of exam Name</strong>
										</th>
										<th>
											<strong>Create Date</strong>
										</th>

										{
											permission.status=="true" && (
												<th>
											<strong>Status</strong>
										</th>
											)										}
										{
											(permission.edit=="true" || permission.delete=="true") && (
												<th>
											<strong>Action</strong>
										</th>
											)
										}
									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.type_of_exam_name}</td>
												<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
												{
													permission.status=="true" && (
														<td>
													<Button
														//disabled={props.showLoading}
														className="btn-xs"
														variant={item.status == 1 ? "success" : "danger"}
														onClick={() => onUpdateStatus(item)}
													>
														{item.status == 1 ? "Active" : "Inactive"}
													</Button>
												</td>
													)
												}
												{
													(permission.edit=="true" || permission.delete=="true") && (
														<td>
													<div className="d-flex">
														{
															permission.edit=="true" && (
																<Link
															to={{
																pathname: '/update-type-of-exam',
																state: item
															}}
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
															)
														}

														{
															permission.delete=="true" && (
																<Button
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => onDeleteModal(item.id)}
														>
															<i className="fa fa-trash"></i>
														</Button>
															)
														}

													</div>
												</td>
													)
												}
											</tr>
										})
									}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
		list: state.typeExam.list,
	};
};

export default connect(mapStateToProps)(TypeExamList);