import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";
import Moment from 'react-moment';

import { getExamCategory, loadingToggleAction, removeExamCategory, updateStatusExamCategory } from '../../../store/actions/ExamCategoryAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ExamCategoryList = (props) => {

	const [modal, setModal] = useState(false);
	const dispatch = useDispatch();
	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getExamCategory(props.history));

	}, []);

	/* confirmation for delete */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				// dispatch(globalLoadingAction(true));
				dispatch(removeExamCategory(i, props.history));
			}
		})
	}

	/* update status */
	const onUpdateStatus = (item) => {

		dispatch(globalLoadingAction(true));
		dispatch(updateStatusExamCategory(item, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
		  if (item.id == 13) {
			setPermission(item);
		  }
		});
	  };

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>

										<th>
											<strong>Exam category</strong>
										</th>

										<th>
											<strong>Exam short code</strong>
										</th>

										<th>
											<strong>Create Date</strong>
										</th>
										
										{
											permission.status=="true" && (
												<th width={120}>
											<strong>Status</strong>
										</th>
											)
										}

										{
											(permission.edit=="true" || permission.delete=="true") && (
												<th>
											<strong>Action</strong>
										</th>
											)
										}
									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.category}</td>
												<td>{item.short_code}</td>
												<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
												{
													permission.status=="true" && (
														<td>
													<Button
														className="btn-xs"
														variant={item.status == 1 ? "success" : "danger"}
														onClick={() => onUpdateStatus(item)}
													>
														{item.status == 1 ? "Active" : "Inactive"}
													</Button>
												</td>
													)
												}
												{
													(permission.edit=="true" || permission.delete=="true") && (
														<td>
													<div className="d-flex">

														{
															permission.edit=="true" && (
																<Link
															to={{
																pathname: '/update-category',
																state: item
															}}
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
															)
														}
														{
															permission.delete=="true" && (
																
														<Button
														className="btn btn-danger shadow btn-xs sharp"
														onClick={() => onDeleteModal(item.id)}
													>
														<i className="fa fa-trash"></i>
													</Button>

															)
														}
													</div>
												</td>
													)
												}
											</tr>
										})
									}

								</tbody>

							</Table>

						</Card.Body>

					</Card>

				</Col>

			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	
	return {
		showLoading: state.category.showLoading,
		list: state.category.list,
	};
};
export default connect(mapStateToProps)(ExamCategoryList);