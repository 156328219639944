import swal from "sweetalert";
import {
  getElibraryList,
  approveValidator,
  uploadContent,
  uploadConceptMap,
  saveAndPublishedConceptMap,
  contentDeveloperList,
  assignLibrary,
  getElibraryCount,
  deleteLibraryContentData,
} from "../../services/ElibraryServices";

import {
  GET_ELIBRARY_LIST,
  ELIBRARY_LOADING,
  APPROVE_VADIDATOR_BY_ADMIN,
  GET_ELIBRARY_CONTENT_LIST,
  UPLOAD_ELIBRARY_CONTENT,
  UPLOAD_CONTENT_MAP,
  UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH,
  CONTENT_DEVELOPER_LIST,
  ASSIGN_LIBRARY,
  DELETE_LIBRARY_CONTENT,
  GET_LIBRARY_COUNT,
  REMOVE_UPDATE_DATE,
  CONCEPT_MAP_PATH_ONLY,
  ELIBRARY_CONTENT_LIST_TYPE,
  ELIBRARY_CONTENT_LIST_CATEGORY,
  ELIBRARY_CONTEN_TLIST_BOARD,
  ELIBRARY_CONTENT_LIST_CLASS,
  ELIBRARY_CONTENT_LIST_SUBJECT,
  ELIBRARY_CONTENT_LIST_CHAPTER,
  ELIBRARY_CONTENT_LIST_STATUS,
  ELIBRARY_CONTENT_LIST_EXAM_TYPE,
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";

export function getElibraryData(
  page,
  demo,
  category,
  board,
  examType,
  subject,
  chapter,
  status,
  className,
  history
) {
  return (dispatch) => {
    getElibraryList(
      page,
      demo,
      category,
      board,
      examType,
      subject,
      chapter,
      status,
      className
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getElibraryAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}
export function approveValidatorData(id, validator, history) {
  return (dispatch) => {
    approveValidator(id, validator)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(approveValidatorAction(response.data.data));
          utility.showSuccess(response.data.msg);
          history.goBack();
        }
      })
      .catch((error) => {
        //console.log(error);
        dispatch(loadingElibraryAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function uploadContentData(
  category,
  board,
  examType,
  subtype,
  subject,
  class_id,
  chapter,
  file,
  demo,
  history
) {
  return (dispatch) => {
    uploadContent(
      category,
      board,
      examType,
      subtype,
      subject,
      class_id,
      chapter,
      file,
      demo
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(uploadContentAction(response.data.data));
          utility.showSuccess(response.data.msg);
        }
        if (response.data.status == 410) {
          utility.showError(response.data.msg);
          dispatch(loadingElibraryAction(false));
        }
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function uploadConceptData(recid, file, file2, history) {
  return (dispatch) => {
    uploadConceptMap(recid, file, file2)
      .then((response) => {
        console.log("before action call-11", response.data)
        if (response.data.status == 200) {
          dispatch(uploadConceptMapAction(response.data));
          utility.showSuccess(response.data.msg);
        }
        if (response.data.status == 410) {
          utility.showError(response.data.msg);
          dispatch(loadingElibraryAction(false));
        }
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function saveAndUploadConceptMapData(recid, file, file2, history) {
  return (dispatch) => {
    saveAndPublishedConceptMap(recid, file, file2)
      .then((response) => {
        console.log("hello woeld", response.data);
        if (response.data.status == 200) {
          dispatch(uploadAndPublishedConceptMapAction(response.data));
          utility.showSuccess(response.data.msg);
        }
        if (response.data.status == 410) {
          utility.showError(response.data.msg);
          dispatch(loadingElibraryAction(false));
        }
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getContentDeveloperListData(history) {
  return (dispatch) => {
    contentDeveloperList()
      .then((response) => {
        dispatch(developerListAction(response.data.data));
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function assignLibraryData(
  subject,
  chapter,
  classroom,
  category,
  examType,
  board,
  developerlist,
  history
) {
  return (dispatch) => {
    assignLibrary(
      subject,
      chapter,
      classroom,
      category,
      examType,
      board,
      developerlist
    )
      .then((response) => {
        dispatch(assisnLibraryAction(response.data.uploadId));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function deleteLibraryData(record_id, active, history) {
  return (dispatch) => {
    deleteLibraryContentData(record_id)
      .then((response) => {
        dispatch(
          getElibraryData(
            active,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            history
          )
        );
        dispatch(removeLibraryAction(record_id));
        swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getElibraryCountData(
  type,
  category,
  board,
  examType,
  subject,
  classNo,
  history
) {
  return (dispatch) => {
    getElibraryCount(type, category, board, examType, subject, classNo)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getElibraryCountAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getElibraryAction(data) {
  return {
    type: GET_ELIBRARY_LIST,
    payload: data,
  };
}
export function approveValidatorAction(data) {
  return {
    type: APPROVE_VADIDATOR_BY_ADMIN,
    payload: data,
  };
}
export function loadingElibraryAction(status) {
  return {
    type: ELIBRARY_LOADING,
    payload: status,
  };
}

export function uploadContentAction(data) {
  return {
    type: UPLOAD_ELIBRARY_CONTENT,
    payload: data,
  };
}

export function getContentAction(data) {
  return {
    type: GET_ELIBRARY_CONTENT_LIST,
    payload: data,
  };
}

export function uploadConceptMapAction(data) {
  console.log("action is called")
  return {
    type: UPLOAD_CONTENT_MAP,
    payload: data,
  };
}

export function uploadOnlyConceptMapAction(data) {
  // console.log("uploadOnlyConceptMapAction", data)
  return {
    type: CONCEPT_MAP_PATH_ONLY,
    payload: data,
  };
}

export function uploadAndPublishedConceptMapAction(data) {
  return {
    type: UPLOAD_CONTENT_MAP_SAVE_AND_PUBLISH,
    payload: data,
  };
}

export function developerListAction(data) {
  return {
    type: CONTENT_DEVELOPER_LIST,
    payload: data,
  };
}

export function assisnLibraryAction(data) {
  return {
    type: ASSIGN_LIBRARY,
    payload: data,
  };
}

export function getElibraryCountAction(data) {
  return {
    type: GET_LIBRARY_COUNT,
    payload: data,
  };
}

export function removeLibraryAction(data) {
  return {
    type: DELETE_LIBRARY_CONTENT,
    payload: data,
  };
}

export function removeUpdateDate(data) {
  return {
    type: REMOVE_UPDATE_DATE,
    payload: data,
  };
}

export function elibraryContentListTypeAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_TYPE,
    payload: data,
  };
}

export function elibraryContentListCategoryAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_CATEGORY,
    payload: data,
  };
}

export function elibraryContentListBoardAction(data) {
  return {
    type: ELIBRARY_CONTEN_TLIST_BOARD,
    payload: data,
  };
}

export function elibraryContentListClassAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_CLASS,
    payload: data,
  };
}

export function elibraryContentListSubjectAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_SUBJECT,
    payload: data,
  };
}
export function elibraryContentListChapterAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_CHAPTER,
    payload: data,
  };
}

export function elibraryContentListStatusAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_STATUS,
    payload: data,
  };
}
export function elibraryContentListExamTypeAction(data) {
  return {
    type: ELIBRARY_CONTENT_LIST_EXAM_TYPE,
    payload: data,
  };
}