import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import ReactApexChart from "react-apexcharts";

//images
import sort from '../../../images/dashboard_image/sort.png'
import export_details from '../../../images/dashboard_image/export.png'



const Home = () => {

	const [selectOption, setSelectOption] = useState('')
	const [isShowDropList, setIsShowDropList] = useState(false)
	const [barchartColor, setBarchartColor] = useState('')
	const [userName, setUserName] = useState('')

	const dashboardList = [
		{
			id: 1,
			iconImage: require('../../../images/dashboard_image/total_registration.png'),
			iconBaseColor: '#26BF94',
			heading: 'Total Registration',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: true,
		},
		{
			id: 2,
			iconImage: require('../../../images/dashboard_image/total_subscription.png'),
			iconBaseColor: '#23B7E5',
			heading: 'Total Subscription',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#1C87A9',
			graphicCbseColor: '#24A9D2',
			graphicWbbseColor: '#5AD8FF',
			isClick: true,
		},
		{
			id: 3,
			iconImage: require('../../../images/dashboard_image/total_school.png'),
			iconBaseColor: '#845ADF',
			heading: 'Total Content',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#423095',
			graphicCbseColor: '#6D5AC3',
			graphicWbbseColor: '#AF9CE7',
			isClick: true,
		},
		{
			id: 4,
			iconImage: require('../../../images/dashboard_image/total_content.png'),
			iconBaseColor: '#F5B849',
			heading: 'Total School',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#94630B',
			graphicCbseColor: '#FDAC18',
			graphicWbbseColor: '#F6DAA5',
			isClick: true,
		},
		{
			id: 5,
			iconImage: require('../../../images/dashboard_image/marketing.png'),
			iconBaseColor: '#2688BF',
			heading: 'Marketing',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 6,
			iconImage: require('../../../images/dashboard_image/services.png'),
			iconBaseColor: '#FD7E95',
			heading: 'Services',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 7,
			iconImage: require('../../../images/dashboard_image/account.png'),
			iconBaseColor: '#96D869',
			heading: 'Account',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 8,
			iconImage: require('../../../images/dashboard_image/it.png'),
			iconBaseColor: '#A7A7A7',
			heading: 'IT',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},

	]
	const registration = [
		{
			board: 'ICSE',
			user: '7',
			r_data: '20/04/2024',
		},
		{
			board: 'CBSE',
			user: '9',
			r_data: '21/04/2024',
		},
		{
			board: 'ICSE',
			user: '5',
			r_data: '22/04/2024',
		},
		{
			board: 'CBSE',
			user: '8',
			r_data: '23/04/2024',
		},
		{
			board: 'ICSE',
			user: '3',
			r_data: '24/04/2024',
		},
		{
			board: 'CBSE',
			user: '8',
			r_data: '25/04/2024',
		},
		{
			board: 'ICSE',
			user: '3',
			r_data: '26/04/2024',
		},
	]

	const [dropdownList, setDropdownList] = useState([
		{
			id: 1,
			name: 'This Week',
			isSelect: 1,
		},
		{
			id: 2,
			name: 'This Month',
			isSelect: 0,
		},
		{
			id: 3,
			name: 'This Year',
			isSelect: 0,
		}
	])

	/* graphic details */
	const barChartDetails = {
		/* series: [{
			name: props.MockData ? props.MockData.name : '',
			data: props.MockData ? props.MockData.data : '',
		}, {
			name: props.ModuleData ? props.ModuleData.name : '',
			data: props.ModuleData ? props.ModuleData.data : '',
		}, {
			name: props.SetData ? props.SetData.name : '',
			data: props.SetData ? props.SetData.data : '',
		}], */
		series: [{
			name: "ICSE",
			data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
		},
		{
			name: "CBSE",
			data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
		},
		{
			name: "WBBSE",
			data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
		}
		],
		options: {
			chart: {
				type: "bar",
				height: 100,
				toolbar: {
					show: false,
				},
			},
			
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "70%",
					// endingShape: "rounded",
					borderRadius: 2,
					borderRadiusApplication: 'end',
					// distributed: true,
				},
			},
			dataLabels: {
				enabled: false,
			},

			legend: {
				show: true,
				fontSize: "10px",
				fontWeight: 300,

				labels: {
					colors: "black",
				},

				position: "bottom",
				horizontalAlign: "center",
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					radius: 19,
					strokeColor: "#fff",
					// fillColors: ["#13795C", "#23A37F", "#2FE7B3"],
					fillColors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
					offsetX: 0,
					offsetY: 0,
				},
				onItemClick: {
					toggleDataSeries: false
				},
			},

			yaxis: {
				labels: {
					style: {
						colors: "#3e4954",
						fontSize: "14px",
						fontFamily: "Poppins",
						fontWeight: 100,
					},
				},
				title: {
					text: 'No. of User',
					style: {
						colors: "#2C2C2C50",
						fontSize: "9px",
						fontFamily: "Poppins",
						fontWeight: 100,
					}
				},
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				// categories: props.scholasticLabel,
				categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
				title: {
					text: 'Period',
					style: {
						colors: "#2C2C2C50",
						fontSize: "9px",
						fontFamily: "Poppins",
						fontWeight: 100,
					},
					offsetX: 0,
					offsetY: 80,
				},
			},
			fill: {
				// colors: ["#13795C", "#23A37F", "#2FE7B3"],
				colors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
				opacity: 1,
			},

			/* tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands";
					},
				},
			}, */
		},
	};

	useEffect(() => {
		setSelectOption(dropdownList[0])
		setBarchartColor(dashboardList[0])

		let getData = localStorage.getItem("userDetails");
		let name = JSON.parse(getData).name;
		setUserName(name)
	}, []);

	/* dropdown list select */
	const selectItem = (item) => {
		setSelectOption(item)
		dropdownList.map(u => u.isSelect = u.id == item.id ? 1 : 0);
		setIsShowDropList(!isShowDropList)
	}

	/* dropdown list show */
	const showList = () => {
		setIsShowDropList(!isShowDropList)
	}

	/* change card item */
	const cardItem = (item) => {
		setBarchartColor(item)
	}

	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card dashboard_card_height"> {/* height */}
								{/* top */}
								<div className='d-flex flex-row justify-content-between align-items-center m-4'>
									<div>
										<p className='welcome_text'>Welcome back, {userName}! </p>
									</div>
									<div className='d-flex flex-row'>
										<div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded'>
											<div className='image_container'>
												<img src={sort} alt="" className='image_style' />
											</div>
											<div className='button_text font_color_black'>
												Filter
											</div>

										</div>
										<div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border'>
											<div className='image_container'>
												<img src={export_details} alt="" className='image_style' />
											</div>
											<div className='button_text font_color_blue'>
												Export
											</div>
										</div>
									</div>
								</div>
								{/* middle */}

								<div className='d-flex flex-row m-4 justify-content-between flex-wrap'>
									{dashboardList.map((item, index) => {
										return (
											<div className={`small_card d-flex flex-row rounded mb-4 p-3 ${barchartColor.id === item.id ? 'border_red' : null} ${item.isClick ? 'cursorPointer' : null}`} key={index} onClick={() => item.isClick ? cardItem(item) : null}>
												<div className={`card_icon_container`} style={{ backgroundColor: item.iconBaseColor }}>
													<img src={item.iconImage} alt="" className='icon_image_style' />
												</div>
												<div className='d-flex flex-column justify-content-between right_box_width'>
													<div className='card_heading'>{item.heading}</div>
													<div className='card_counter'>{item.countValue}</div>
													<div className='d-flex align-self-end card_view_all' style={{ color: item.iconBaseColor }}>{item.viewText} <i className="bi bi-arrow-right arrow_icon_size" style={{ color: item.iconBaseColor, marginLeft: '5px' }} ></i></div>
												</div>
											</div>
										)
									})}
								</div>

								<div className='d-flex flex-row m-4 justify-content-between flex-wrap'>
									<div className='registration_container rounded d-flex flex-column'>
										{/* Registration */}
										<div className='d-flex flex-row justify-content-center align-items-center w-100 m-2'>
											<div className='registration_heading'>Registration</div>
											<div className='day_text'>(Last 7 Days)</div>
										</div>
										<div className='d-flex flex-row justify-content-around align-items-center w-100 bottom_line_table_heading'>
											<div className='table_heading_text width_board d-flex justify-content-center align-items-center'>Board</div>
											<div className='table_heading_text width_user d-flex justify-content-center align-items-center'>No. of User</div>
											<div className='table_heading_text width_registration d-flex justify-content-center align-items-center'>Registration Date</div>
										</div>
										<div className='d-flex flex-column justify-content-around align-items-center w-100'>
											{registration.map((item, index) => {
												return (
													<div className='d-flex flex-row my-2' key={index}>
														<div className='table_heading_text width_board d-flex justify-content-center align-items-center'>{item.board}</div>
														<div className='table_heading_text width_user d-flex justify-content-center align-items-center'>{item.user}</div>
														<div className='table_heading_text width_registration d-flex justify-content-center align-items-center'>{item.r_data}</div>
													</div>
												)
											})}
										</div>
									</div>
									<div className='registration_analytics_container rounded d-flex flex-column'>
										<div className='d-flex justify-content-between w-100 m-2 registration_analytics_top_container position-relative'>
											<div className='registration_heading'>{barchartColor?.heading?.split(' ')[1]} Analytics</div>
											<div className={`d-flex flex-column  align-items-center dorpdown_container me-4 rounded ${!isShowDropList ? 'dorpdown_container_single_item_height' : 'dorpdown_container_all_item_height'} dropdown_container_absolute_position`}>
												<div className='d-flex flex-row justify-content-between align-items-center dropdown_font w-100 select_value cursor_context_menu' onClick={() => showList()}>
													<div>
														{selectOption.name}
													</div>
													<div>
														<i className={isShowDropList ? "bi bi-chevron-up" : "bi bi-chevron-down"} ></i> {/*  down*/}
													</div>
												</div>

												{isShowDropList && dropdownList.map((item, index) => {
													return (
														<div className='d-flex justify-content-between align-items-center dropdown_font w-100 my-1 cursor_context_menu' key={index} onClick={() => selectItem(item)}>
															<div>
																{item.name}
															</div>
															<div className='dropdown_circle'>
																{item.isSelect == 1 ? <div className='dropdown_circle_fill'></div> : null}
															</div>
														</div>
													)
												})
												}
											</div>
										</div>
										<div>
											<div className="chart_body">
												<ReactApexChart
													options={barChartDetails.options}
													series={barChartDetails.series}
													type="bar"
													height={260}
												/>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;