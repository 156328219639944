import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";

import { getExamType, loadingAction, removeExamType, updateStatusExamType } from '../../../store/actions/ExamTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ExamtypeList = (props) => {

	const [modal, setModal] = useState(false);
	const dispatch = useDispatch();
	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getExamType(props.history));
	}, []);

	/* delete confirmation */
	const onDelete = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeExamType(id, props.history));
			}
		})

	}

	/* status updated */
	const onUpdateStatus = (item) => {

		dispatch(globalLoadingAction(true));
		dispatch(updateStatusExamType(item, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
		  if (item.id == 16) {
			setPermission(item);
		  }
		});
	  };

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Category</strong>
										</th>
										<th>
											<strong>Type name</strong>
										</th>
										<th>
											<strong>Full name</strong>
										</th>
										<th>
											<strong>Code</strong>
										</th>
										<th>
											<strong>Conducted by</strong>
										</th>
										<th>
											<strong>Create Date</strong>
										</th>
										
										{
											permission.status=="true" && (
												<th>
											<strong>Status</strong>
										</th>
											)
										}
										{
											(permission.edit=="true" || permission.delete=="true") && (
												<th>
											<strong>Action</strong>
										</th>
											)
										}
									</tr>
								</thead>
								<tbody>

									{
										props.typeList.map((item, index) => {
											return <tr key={index}>
												<td>{item.exam_category}</td>
												<td>{item.type_name}</td>
												<td>{item.full_name}</td>
												<td>{item.short_code}</td>
												<td>{item.conducted_by}</td>
												<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
												{
													permission.status=="true" && (
														<td>
													<Button
														//disabled={props.showLoading}
														className="btn-xs"
														variant={item.status == 1 ? "success" : "danger"}
														onClick={() => onUpdateStatus(item)}
													>
														{item.status == 1 ? "Active" : "Inactive"}
													</Button>
												</td>
													)
												}
												{
													(permission.edit=="true" || permission.delete=="true") && (
														<td>
													<div className="d-flex">
														{
															permission.edit=="true" && (
																<Link
															to={{
																pathname: '/update-type',
																state: item
															}}
															className="btn btn-warning shadow btn-xs sharp me-1"
														>
															<i className="fas fa-pencil-alt"></i>
														</Link>
															)
														}
														{
															permission.delete=="true" && (
																<Button
															className="btn btn-danger shadow btn-xs sharp"
															onClick={() => onDelete(item.id)}
														>
															<i className="fa fa-trash"></i>
														</Button>
															)
														}

													</div>
												</td>
													)
												}
											</tr>
										})
									}

								</tbody>
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.type.showLoading,
		typeList: state.type.list,
	};
};

export default connect(mapStateToProps)(ExamtypeList);