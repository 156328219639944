import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function addCategoty(
  category,
  short_code,
  onlineExam_guest,
  onlineExam_register,
  e_library_guest,
  e_library_register,
  performance,
  performance_e_library,
  photo_onlineexam,
  photo_e_library,
  photo_performance,
  photo_performance_e_library
) {
  const postData = {
    short_code,
    onlineExam_guest,
    onlineExam_register,
    e_library_guest,
    e_library_register,
    performance,
    performance_e_library,
    photo_onlineexam,
    photo_e_library,
    photo_performance,
    photo_performance_e_library,
  };


  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const formData = new FormData();
  formData.append("category", category);
  formData.append("short_code", short_code);
  formData.append("onlineExam_guest", onlineExam_guest);
  formData.append("onlineExam_register", onlineExam_register);
  formData.append("e_library_guest", e_library_guest);
  formData.append("e_library_register", e_library_register);
  formData.append("performance", performance);
  formData.append("performance_e_library", performance_e_library);
  formData.append("photo_onlineexam", photo_onlineexam);
  formData.append("photo_e_library", photo_e_library);
  formData.append("photo_performance", photo_performance);
  formData.append("photo_performance_e_library", photo_performance_e_library);
 

  console.log("-------", formData);

  return axios({
    url: GlobalConfigs.API_URL + Apis.SET_EXAM_CATEGORY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
}

export function getCategoty() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_EXAM_CATEGORY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function updateCategoty(
  recid,
  category,
  short_code,
  onlineExam_guest,
  onlineExam_register,
  e_library_guest,
  e_library_register,
  performance,
  performance_e_library,
  photo_onlineexam,
  photo_e_library,
  photo_performance,
  photo_performance_e_library
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const formData = new FormData();
  formData.append("recid", recid);
  formData.append("category", category);
  formData.append("short_code", short_code);
  formData.append("onlineExam_guest", onlineExam_guest);
  formData.append("onlineExam_register", onlineExam_register);
  formData.append("e_library_guest", e_library_guest);
  formData.append("e_library_register", e_library_register);
  formData.append("performance", performance);
  formData.append("performance_e_library", performance_e_library);
  formData.append("photo_onlineexam", photo_onlineexam);
  formData.append("photo_e_library", photo_e_library);
  formData.append("photo_performance", photo_performance);
  formData.append("photo_performance_e_library", photo_performance_e_library);

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_EXAM_CATEGORY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
}

export function updateStatusCategoty(recid, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
    status,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_STATUS_EXAM_CATEGORY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function removeCategoty(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_EXAM_CATEGORY_API,
    method: "DELETE",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
