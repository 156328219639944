import { GET_MIS_REGISTER_USER } from "../constants";
const initialState = {
    list: [],
   
};


export function MisRegUserReducer(state = initialState, action) {

    if (action.type === GET_MIS_REGISTER_USER) {
        return {
            ...state,
            list:action.payload
          
        };
    }

    return state;
}

