import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Dropdown,
    ProgressBar,
    Modal,
    Button
} from "react-bootstrap";

import { getSchoolDetails, removeSchoolData, loadingSchoolAction } from '../../../store/actions/SchoolAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import * as utility from '../../../utility/Utility';

const SchoolList = (props) => {

    const dispatch = useDispatch();
    const previousFilterData = props?.location.state?.previousPageFilterData;

    const [permission, setPermission] = useState("");
    const [page, setPage] = useState(previousFilterData?.page ?? 1);
    const [board, setBoard] = useState(previousFilterData?.board ?? '');
    const [region, setRegion] = useState(previousFilterData?.region ?? '');
    const [pincode, setPincode] = useState(previousFilterData?.pincode ?? '');
    const [schoolName, setSchoolName] = useState(previousFilterData?.schoolName ?? '');

    const [nameIsSorted, setNameIsSorted] = useState('');
    const [nameIsSort, setNameIsSort] = useState('');
    const [modifieDateIsSorted, setmodifieDateIsSorted] = useState('');
    const [modifieDateIsSort, setmodifieDateIsSort] = useState('');

    useEffect(() => {
        permissionCheck();
        dispatch(loadingBoardAction(true));
        dispatch(getBoardData(props.history));
        dispatch(globalLoadingAction(true));
        // dispatch(getSchoolDetails(page, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
        setNameIsSorted(1)
        setmodifieDateIsSorted(1)
    }, []);

    useEffect(() => {
        dispatch(loadingBoardAction(true));
        dispatch(getSchoolDetails(page, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
    }, [page, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort]);


    /* delete confirmation */
    const onDeleteHandler = (i) => {
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (props.schoolList.length == 1) {
                    dispatch(removeSchoolData(i, page - 1, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
                    setPage(page - 1);
                } else {
                    dispatch(removeSchoolData(i, page, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
                }

            }
        })
    }

    /* pagination */
    const pageHandler = (data) => {
        setPage(data.selected + 1);
        // setPage(data.selected + 1)
        dispatch(globalLoadingAction(true));
        // dispatch(getSchoolDetails(data.selected + 1, props.history));

        dispatch(getSchoolDetails(data.selected + 1, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
    }

    const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
            if (item.id == 25) {
                setPermission(item);
            }
        });
    };

    /* change board */
    const onChangeBoardHandler = (e) => {
        setBoard(e)
        setPage(1)
    }

    /* change region */
    const onRegionHandler = (e) => {
        setRegion(e)
        setPage(1)
    }

    /* change pincode */
    const onPincodeHandler = (e) => {
        setPincode(e)
        setPage(1)
    }

    /* change schoold name */
    const onSchoolNameHandler = (e) => {
        setSchoolName(e)
        setPage(1)
    }

    /* filter reset */
    const onResetHandler = () => {
        setBoard('');
        setRegion('');
        setPincode('');
        setSchoolName('');
        setNameIsSort('');
        setmodifieDateIsSort('');
        setPage(1)

        const stateCopy = { ...props?.location.state?.previousPageFilterData };
		delete stateCopy.page;
		delete stateCopy.board;
		delete stateCopy.region;
		delete stateCopy.pincode;
		delete stateCopy.schoolName;
		props.history.replace({ state: stateCopy });

        // dispatch(loadingBoardAction(true));
        // dispatch(getSchoolDetails(1, board, region, pincode, schoolName, nameIsSort, modifieDateIsSort, props.history));
    }

    /* clear board */
    const onClearHandlerBoard = () => {
        setBoard('');
        setPage(1)
    }
    /* clear region */
    const onClearHandlerRegion = () => {
        setRegion('');
        setPage(1)
    }
    /* clear pincode */
    const onClearHandlerPincode = () => {
        setPincode('');
        setPage(1)
    }
    /* clear school name */
    const onClearHandlerSchoolName = () => {
        setSchoolName('');
        setPage(1)
    }
    const showAccDecIcon = () => {
        setNameIsSort(2)
        setNameIsSorted('')
        setmodifieDateIsSorted(1)
    }

    const showAccIcon = () => {
        setNameIsSort(1)
        setNameIsSorted(1)
        setmodifieDateIsSorted(1)
    }

    const showDecIcon = () => {
        setNameIsSort(1)
        setNameIsSorted('')
        setmodifieDateIsSorted(1)
    }

    /* ------ */

    const mdateShowAccDecIcon = () => {
        setmodifieDateIsSort(2)
        setmodifieDateIsSorted('')
        setNameIsSorted(1)
    }

    const mdateShowAccIcon = () => {
        setmodifieDateIsSort(1)
        setmodifieDateIsSorted(1)
        setNameIsSorted(1)
    }

    const mdateShowDecIcon = () => {
        setmodifieDateIsSort(1)
        setmodifieDateIsSorted('')
        setNameIsSorted(1)
    }

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="row chapter-list-view">

                                <div className={`col-sm-3 ${board ? 'school_list' : null}`}>
                                    <div className="form-group mb-3">
                                        <div className="basic-dropdown px-1 clear-question-type">
                                            <span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                style={{ height: '45px' }}
                                                className="form-control filter-form-control"
                                                value={board}
                                                onChange={(e) => onChangeBoardHandler(e.target.value)}
                                            >
                                                <option value="">Select Board</option>
                                                {
                                                    props.boardList.map((item, index) => {
                                                        return <option key={index} value={item.name}>{item.name}</option>
                                                    })
                                                }
                                            </select>
                                            {board ? (
                                                <button
                                                    onClick={onClearHandlerBoard}
                                                    className="clear-date-btn margin_top"
                                                >
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </button>
                                            ) : null}

                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-2 px-1 clear-question-type">
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            placeholder="Region"
                                            value={region}
                                            onChange={(e) => onRegionHandler(e.target.value)}
                                            onKeyPress={utility.inputAlphabetOnly}
                                        />
                                        {region ? (
                                            <button
                                                onClick={onClearHandlerRegion}
                                                className="clear-date-btn margin_top"
                                            >
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-sm-2 px-1 clear-question-type">
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            placeholder="Pincode"
                                            value={pincode}
                                            onChange={(e) => onPincodeHandler(e.target.value)}
                                            onKeyPress={utility.inputNumberOnly}
                                        />
                                        {pincode ? (
                                            <button
                                                onClick={onClearHandlerPincode}
                                                className="clear-date-btn margin_top"
                                            >
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-sm-4 px-1 clear-question-type">
                                    <div className="form-group mb-3">
                                        <input
                                            className="form-control"
                                            placeholder="School Name"
                                            value={schoolName}
                                            onChange={(e) => onSchoolNameHandler(e.target.value)}
                                            onKeyPress={utility.inputAlphabetOnly}
                                        />
                                        {schoolName ? (
                                            <button
                                                onClick={onClearHandlerSchoolName}
                                                className="clear-date-btn margin_top"
                                            >
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-sm-1 px-1 reset-btn-view">
                                    <button onClick={onResetHandler} className={`btn btn-secondary ${board == '' && region == '' && pincode == '' && schoolName == '' ? 'content_disable' : null}`}>Reset</button>
                                </div>
                            </div>



                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Name</strong>
                                            <span className="ml-1">

                                                {nameIsSorted == '' ? (
                                                    nameIsSort == 2 ?
                                                        <i className="fa fa-arrow-down ms-2 fs-14" onClick={() => showDecIcon()} style={{ opacity: '0.7', color: 'blue' }} />
                                                        :
                                                        <i className="fa fa-arrow-up ms-2 fs-14" onClick={() => showAccIcon()} style={{ opacity: '0.7', color: 'green' }} />
                                                )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14" onClick={() => showAccDecIcon()} style={{ opacity: '0.3', color: 'red' }} />)
                                                }

                                            </span>
                                        </th>
                                        <th>
                                            <strong>Board</strong>
                                        </th>
                                        <th>
                                            <strong>Region</strong>
                                        </th>
                                        <th>
                                            <strong>Phone</strong>
                                        </th>
                                        <th>
                                            <strong>Address</strong>
                                        </th>
                                        <th>
                                            <strong>pincode</strong>
                                        </th>
                                        <th>
                                            <strong>Create Date</strong>
                                        </th>
                                        <th>
                                            <strong>Modified Date</strong>
                                            <span className="ml-1">
                                                {modifieDateIsSorted == '' ? (
                                                    modifieDateIsSort == 2 ?
                                                        <i className="fa fa-arrow-down ms-2 fs-14" onClick={() => mdateShowDecIcon()} style={{ opacity: '0.7', color: 'blue' }} />
                                                        :
                                                        <i className="fa fa-arrow-up ms-2 fs-14" onClick={() => mdateShowAccIcon()} style={{ opacity: '0.7', color: 'green' }} />
                                                )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14" onClick={() => mdateShowAccDecIcon()} style={{ opacity: '0.3', color: 'red' }} />)
                                                }

                                            </span>
                                        </th>
                                        {
                                            (permission.edit == "true" || permission.delete == "true") && (
                                                <th>
                                                    <strong>Action</strong>
                                                </th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                {props.schoolList != '' ?
                                    <tbody>
                                        {
                                            props.schoolList.map((item, index) => {
                                                return <tr key={index}>
                                                    <td className="school-name">{item.school_name}</td>
                                                    <td>{item.board}</td>
                                                    <td>{item.region}</td>
                                                    <td className="school-number">{item.contact_number}</td>
                                                    <td className="school-address">{item.school_address}</td>
                                                    <td>{item.pincode}</td>
                                                    <td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
                                                    <td><Moment format="D MMM YYYY" withTitle>{item.modified_at}</Moment></td>
                                                    {
                                                        (permission.edit == "true" || permission.delete == "true") && (
                                                            <td>
                                                                <div className="d-flex">
                                                                    {
                                                                        permission.edit == "true" && (
                                                                            <Link
                                                                                to={{
                                                                                    pathname: '/update-school',
                                                                                    state: {item, filterData: { page, board, region, pincode, schoolName }}

                                                                                }}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Link>
                                                                        )
                                                                    }

                                                                    {
                                                                        permission.delete == "true" && (
                                                                            <Button
                                                                                className="btn btn-danger shadow btn-xs sharp"
                                                                                onClick={() => onDeleteHandler(item.id)}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>
                                                                        )
                                                                    }

                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        <td colSpan={12}>
                                            <div className='no_data'>
                                                <strong >No data found</strong>
                                            </div>
                                        </td>
                                    </tbody>
                                }
                            </Table>
                            <div className="pagination-container">
                                <div className="page-result">
                                    {props.totalRecode > 0 ?
                                        <p>Showing: {(page - 1) * (10) + 1} - {(10 * page) - (10 - props.schoolList.length)} of {props.totalRecode}</p>
                                        : null}
                                </div>
                                {props.totalRecode > 10 ?
                                    <ReactPaginate
                                        containerClassName='pagination-gutter false pagination-circle pagination pagination-sm'
                                        pageClassName='page-item'
                                        pageLinkClassName='page-link'
                                        nextLabel={<i className="la la-angle-right"></i>}
                                        nextClassName='page-item'
                                        nextLinkClassName='page-link'
                                        breakLabel="..."
                                        previousLabel={<i className="la la-angle-left"></i>}
                                        previousClassName='page-item'
                                        previousLinkClassName='page-link'
                                        breakClassName='page-item'
                                        breakLinkClassName='page-link'
                                        renderOnZeroPageCount={null}
                                        activeClassName="active"
                                        onPageChange={pageHandler}
                                        pageRangeDisplayed={3}
                                        pageCount={props.totalPage}
                                        forcePage={page - 1}
                                    />
                                    : null}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}
const mapStateToProps = (state) => {

    return {
        showLoading: state.school.showLoading,
        schoolList: state.school.schoolList,
        totalRecode: state.school.totalRecode,
        totalPage: state.school.totalPage,
        boardLoading: state.board.showLoading,
        boardList: state.board.list,
    };
};

export default connect(mapStateToProps)(SchoolList);