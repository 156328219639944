import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";
import { ADD_FEEDBACK } from "../apis/Apis";

export function UpdateQuestion(recid,question,questions_type) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

   const postData = {
    recid: recid,
    questions:question,
    questions_type:questions_type
  }
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_FEEDBACK,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function DeleteQuestion(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

   const postData = {
    recid: recid
  }
  return axios({
    url: GlobalConfigs.API_URL + Apis.DELETE_FEEDBACK,
    method: "DELETE",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function SentQuestion(question,questions_type) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    questions: question,
    questions_type:questions_type
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_FEEDBACK,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getFeedBack(type) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    type
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_FEEDBACK_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function sentStstus(recid, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const requestData = {
    recid: recid,
    status: status,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.SENT_STATUS,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: requestData,
  });
}
