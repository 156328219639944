import swal from "sweetalert";
import {
    addTypeExam,
    getTypeExam,
    updateTypeExam,
    removeTypeExam,
    updateStatusTypeExam
} from '../../services/TypeExamServices';

import {
    TYPE_OF_EXAM_LOADING,
    ADD_TYPE_OF_EXAM,
    GET_TYPE_OF_EXAM,
    UPDATE_TYPE_OF_EXAM,
    REMOVE_TYPE_OF_EXAM,
    UPDATE_STATUS_TYPE_OF_EXAM
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addTypeExamData(type_exma, history) {
    return (dispatch) => {
        addTypeExam(type_exma)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addTypeExamAction(true));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTypeExamData(id, typeExma, history) {
    return (dispatch) => {
        updateTypeExam(id, typeExma)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateTypeExamAction(true));
                    utility.showSuccess(response.data.msg);
                    history.goBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingTypeExamAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getTypeExamListData(history) {
    return (dispatch) => {
        getTypeExam()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTypeExamAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateStatusTypeExamData(item, history) {
    return (dispatch) => {
        updateStatusTypeExam(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStatusTypeExamAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeTypeExamData(id, history) {
    return (dispatch) => {
        removeTypeExam(id)
            .then((response) => {
                dispatch(removeTypeExamAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addTypeExamAction(data) {
    return {
        type: ADD_TYPE_OF_EXAM,
        payload: data,
    };
}
export function updateTypeExamAction(data) {
    return {
        type: UPDATE_TYPE_OF_EXAM,
        payload: data,
    };
}
export function getTypeExamAction(data) {
    return {
        type: GET_TYPE_OF_EXAM,
        payload: data,
    };
}
export function updateStatusTypeExamAction(item) {
    return {
        type: UPDATE_STATUS_TYPE_OF_EXAM,
        payload: item,
    };
}
export function removeTypeExamAction(id) {
    return {
        type: REMOVE_TYPE_OF_EXAM,
        payload: id,
    };
}
export function loadingTypeExamAction(status) {
    return {
        type: TYPE_OF_EXAM_LOADING,
        payload: status,
    };
}
