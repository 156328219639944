import {
    SUB_TYPE_LOADING,
    ADD_EXAM_SUB_TYPE,
    GET_EXAM_SUB_TYPE,
    UPDATE_EXAM_SUB_TYPE,
    REMOVE_EXAM_SUB_TYPE,
    UPDATE_STATUS_EXAM_SUB_TYPE
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function ExamSubTypeReducer(state = initialState, action) {

    if (action.type === ADD_EXAM_SUB_TYPE) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_SUB_TYPE) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_EXAM_SUB_TYPE) {
      
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_EXAM_SUB_TYPE) {

        const subTypeList = [...state.list];
        const index = subTypeList.findIndex((item) => item.id === action.payload.id);
        subTypeList[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: subTypeList,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EXAM_SUB_TYPE) {

        const subTypeList = [...state.list];
        const index = subTypeList.findIndex((item) => item.id === action.payload);
        subTypeList.splice(index, 1);

        return {
            ...state,
            list: subTypeList,
            showLoading: false,
        };
    }

    if (action.type === SUB_TYPE_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


