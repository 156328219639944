import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { validateClass, validateClassCode } from '../../../utility/Utility';
import { addClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const CreateStandard = (props) => {

	const dispatch = useDispatch();

	let errorsObj = {class: '', short: '' };
	const [errors, setErrors] = useState(errorsObj);
	
	const [className, setClassName] = useState('');
	const [shortCode, setShortCode] = useState('');


	useEffect(() => {
		// dispatch(getExamCategory(props.history));
		// dispatch(getBoardData(props.history));
		// dispatch(getExamType(props.history));
	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (className === '') {
			errorObj.class = 'Class name is Required';
			error = true;

		} else if (validateClass(className) === false) {
			errorObj.class = 'Invalid Class name';
			error = true;
		}
		if (shortCode === '') {
			errorObj.short = 'Class short code is Required';
			error = true;

		} else if (validateClassCode(shortCode) === false) {
			errorObj.short = 'Invalid short code';
			error = true;
		}


		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(addClassData(className, shortCode, props.history));
		setClassName('');
		setShortCode('');
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">

										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Class name<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the class name" value={className} onChange={(e) => setClassName(e.target.value)} />
														{errors.class && <div className="text-danger fs-12">{errors.class}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Class short code<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the short code" value={shortCode} onChange={(e) => setShortCode(e.target.value)} />
														{errors.short && <div className="text-danger fs-12">{errors.short}</div>}
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.class.showLoading,
		categoryList: state.category.list,
		boardList: state.board.list,
		typeList: state.type.list,
	};
};
export default connect(mapStateToProps)(CreateStandard);