import swal from "sweetalert";
import {
    addComSubscription,
    getComSubscriptionList,
    addSchoSubscription,
    getSchoSubscriptionList,
    updateComSubscription,
    updateSchoSubscription,
    updateStatusComSubscription,
    updateStatusSchoSubscription,
    getLibrarySubscriptionList,
    updateStatusLibrarySubscription,
    updateLibrarySubscription,
    getSubscriptionRanking,
    removeComSubscription,
    removeSchoSubscription,
    getComSubscriptionRanking,
    removeLibrarySubscription,
    addIntegratedSubscription,
    updateIntegratedSubscription,
    removeIntegratedscription,
    getIntegratedSubscription,
    getIntegratedscriptionRanking,
    updateIntegratedSubscriptionStatus,
    getSubscriptionRankingForLibrary
} from '../../services/SubscriptionServices';

import {
    SUBSCRIPTION_LOADING,
    ADD_COMPETETIVE_SUBSCRIPTION,
    UPDATE_COMPETETIVE_SUBSCRIPTION,
    GET_COMPETETIVE_SUBSCRIPTION_LIST,
    ADD_SCHOLASTIC_SUBSCRIPTION,
    GET_SCHOLASTIC_SUBSCRIPTION,
    UPDATE_SCHOLASTIC_SUBSCRIPTION,
    UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS,
    UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS,
    GET_LIBRARY_SUBSCRIPTION,
    GET_INTEGRATED_SUBSCRIPTION,
    UPDATE_LIBRARY_SUBSCRIPTION_STATUS,
    REMOVE_COMPETETIVE_SUBSCRIPTION,
    REMOVE_SCHOLASTIC_SUBSCRIPTION,
    REMOVE_LIBRARY_SUBSCRIPTION
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addComSubscriptionData(type_id, class_id, set_conunt, question_set, amount, ePrice, only_elibrary, sticker_text, courseCode,callback, history) {
    return (dispatch) => {
        addComSubscription(type_id, class_id, set_conunt, question_set, amount, ePrice, only_elibrary, sticker_text, courseCode)
            .then((response) => {
                dispatch(addComSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                // if (only_elibrary == 1) {
                //     history.push('/library-subscription-list');
                // } else {
                //     history.push('/competetive-subscription-list');
                // }
                if(callback){
                    callback()
                }
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function addSchoSubscriptionData(subject_id, board_id, class_id, package_details, elibraryPrice, only_elibrary, sticker_text, course_code, history) {
    return (dispatch) => {
        addSchoSubscription(subject_id, board_id, class_id, package_details, elibraryPrice, only_elibrary, sticker_text, course_code)
            .then((response) => {
                dispatch(addSchoSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                // if (only_elibrary == 1) {
                //     history.push('/library-subscription-list');
                // } else {
                //     history.push('/scholastic-subscription-list');
                // }
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function getComSubscriptionData(page, type_id, class_no, history) {
    return (dispatch) => {
        getComSubscriptionList(page, type_id, class_no)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getComSubscriptionAction(response.data));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function getComSubscriptionRankingList(ranking, id, page, type_id, class_no, history) {
    return (dispatch) => {
        getComSubscriptionRanking(ranking, id, page, type_id, class_no,)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getComSubscriptionData(page, type_id, class_no, history));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function getSchoSubscriptionData(page, subject_id, board_id, class_no, history) {
    return (dispatch) => {
        getSchoSubscriptionList(page, subject_id, board_id, class_no)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getSchoSubscriptionAction(response.data));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getSubscriptionRankingList(ranking, id, page, subject, board, classNo, history) {
    return (dispatch) => {
        getSubscriptionRanking(ranking, id, page, subject, board, classNo)
            .then((response) => {
                if (response.data.status == 200) {
                    // dispatch(getSchoSubscriptionAction(response.data));
                    dispatch(getSchoSubscriptionData(page, subject, board, classNo, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getSubscriptionRankingListForLibrary(ranking, id, page, category, type, subject, board, classNo, history) {
    return (dispatch) => {
        getSubscriptionRankingForLibrary(ranking, id, page, subject, board, classNo)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLibrarySubscriptionData(page, category, type, subject, board, classNo, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getLibrarySubscriptionData(page, category_id, type_id, subject_id, board_id, class_no, history) {
    return (dispatch) => {
        getLibrarySubscriptionList(page, category_id, type_id, subject_id, board_id, class_no)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLibrarySubscriptionAction(response.data));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateComSubscriptionData(id, type_id, class_id, set_conunt, question_set, amount, elibraryPrice, sticker_text, courseCode, history) {
    return (dispatch) => {
        updateComSubscription(id, type_id, class_id, set_conunt, question_set, amount, elibraryPrice, sticker_text, courseCode)
            .then((response) => {
                dispatch(updateComSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                history.push('/competetive-subscription-list');
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function updateSchoSubscriptionData(id, subject_id, board_id, class_id, package_details, sticker_text, courseCode, history) {
    return (dispatch) => {
        updateSchoSubscription(id, subject_id, board_id, class_id, package_details, sticker_text, courseCode)
            .then((response) => {
                dispatch(updateSchoSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
                history.push('/scholastic-subscription-list');
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function updateLibrarySubscriptionData(id, category_id, type_id, board_id, subject_id, className_id, elibraryPrice, sticker_text, courseCode, history) {
    return (dispatch) => {
        updateLibrarySubscription(id, category_id, type_id, board_id, subject_id, className_id, elibraryPrice, sticker_text, courseCode)
            .then((response) => {
                dispatch(updateSchoSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                //dispatch(globalLoadingAction(false));
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function updateComSubscriptionStatusData(item, history) {
    return (dispatch) => {
        updateStatusComSubscription(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateComSubscriptionStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateSchoSubscriptionStatusData(item, history) {
    return (dispatch) => {
        updateStatusSchoSubscription(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateSchoSubscriptionStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateLibrarySubscriptionStatusData(item, history) {
    return (dispatch) => {
        updateStatusLibrarySubscription(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                //console.log('response',response.data);
                dispatch(updateLibrarySubscriptionStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function removeComSubscriptionData(id, active, history) {
    return (dispatch) => {
        removeComSubscription(id)
            .then((response) => {
                dispatch(getComSubscriptionData(active, '', '', history));
                dispatch(removeComSubscriptionAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeSchoSubscriptionData(id, active, history) {
    return (dispatch) => {
        removeSchoSubscription(id)
            .then((response) => {
                dispatch(getSchoSubscriptionData(active, '', '', '', history));
                dispatch(removeSchoSubscriptionAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeLibrarySubscriptionData(id, history) {
    return (dispatch) => {
        removeLibrarySubscription(id)
            .then((response) => {
                dispatch(removeLibrarySubscriptionAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addIntegratedSubscriptionData(integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code, history) {
    return (dispatch) => {
        addIntegratedSubscription(integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code)
            .then((response) => {
                //dispatch(addSchoSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(subscriptionLoadingAction(false));
            }).catch((error) => {
                //console.log(error);
                //dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function updateIntegratedSubscriptionData(id, integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code, history) {
    return (dispatch) => {
        updateIntegratedSubscription(id, integrated_name, board_id, class_name, sch_no_set, com_exam_details, elibrary_exist, sticker_text, price, course_code)
            .then((response) => {
                //dispatch(addSchoSubscriptionAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(subscriptionLoadingAction(false));
                history.goBack();
            }).catch((error) => {
                //console.log(error);
                //dispatch(addComSubscriptionAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function getIntegratedSubscriptionData(page, board, classNo, history) {
    return (dispatch) => {
        getIntegratedSubscription(page, board, classNo)
            .then((response) => {
                if (response.data.status === 200) {
                    dispatch(getIntegratedSubscriptionAction(response.data));
                    dispatch(globalLoadingAction(false));
                }
            }).catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function removeIntegratedscriptionData(id, active, board, classNo, history) {
    return (dispatch) => {
        removeIntegratedscription(id)
            .then((response) => {
                dispatch(getIntegratedSubscriptionData(active, board, classNo, history));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function updateIntegratedSubscriptionStatusData(item, active, board, classNo, history) {
    return (dispatch) => {
        updateIntegratedSubscriptionStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(getIntegratedSubscriptionData(active, board, classNo, history));
                dispatch(globalLoadingAction(false));
                utility.showSuccess(response.data.msg);
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getIntegratedscriptionRankingData(ranking, id, page, board, classNo, history) {
    return (dispatch) => {
        getIntegratedscriptionRanking(ranking, id, page, board, classNo)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getIntegratedSubscriptionData(page, board, classNo, history));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function addComSubscriptionAction(data) {
    return {
        type: ADD_COMPETETIVE_SUBSCRIPTION,
        payload: data,
    };
}
export function updateComSubscriptionAction(data) {
    return {
        type: UPDATE_COMPETETIVE_SUBSCRIPTION,
        payload: data,
    };
}
export function addSchoSubscriptionAction(data) {
    return {
        type: ADD_SCHOLASTIC_SUBSCRIPTION,
        payload: data,
    };
}
export function updateSchoSubscriptionAction(data) {
    return {
        type: UPDATE_SCHOLASTIC_SUBSCRIPTION,
        payload: data,
    };
}
export function getComSubscriptionAction(data) {
    return {
        type: GET_COMPETETIVE_SUBSCRIPTION_LIST,
        payload: data,
    };
}
export function getSchoSubscriptionAction(data) {
    return {
        type: GET_SCHOLASTIC_SUBSCRIPTION,
        payload: data,
    };
}
export function getIntegratedSubscriptionAction(data) {
    return {
        type: GET_INTEGRATED_SUBSCRIPTION,
        payload: data,
    };
}
export function getLibrarySubscriptionAction(data) {
    return {
        type: GET_LIBRARY_SUBSCRIPTION,
        payload: data,
    };
}
export function updateComSubscriptionStatusAction(data) {
    return {
        type: UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS,
        payload: data,
    };
}
export function updateSchoSubscriptionStatusAction(data) {
    return {
        type: UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS,
        payload: data,
    };
}
export function updateLibrarySubscriptionStatusAction(data) {
    return {
        type: UPDATE_LIBRARY_SUBSCRIPTION_STATUS,
        payload: data,
    };
}
export function removeComSubscriptionAction(id) {
    return {
        type: REMOVE_COMPETETIVE_SUBSCRIPTION,
        payload: id,
    };
}
export function removeSchoSubscriptionAction(id) {
    return {
        type: REMOVE_SCHOLASTIC_SUBSCRIPTION,
        payload: id,
    };
}
export function removeLibrarySubscriptionAction(id) {
    return {
        type: REMOVE_LIBRARY_SUBSCRIPTION,
        payload: id,
    };
}
export function subscriptionLoadingAction(status) {
    return {
        type: SUBSCRIPTION_LOADING,
        payload: status,
    };
}
