import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useHistory } from 'react-router-dom';
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { updateFeedback } from "../../../store/actions/FeedBackAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateFeedback = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const item = location.state;
  const [question, setQuestion] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [previousPageFilterData, setPreviousPageFilterData] = useState('');


  const errorsObj = { question: "", feedbacktype: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    setQuestion(item.item.questions);
    setFeedbackType(item.item.question_type);
  }, []);

  useEffect(() => {
    setPreviousPageFilterData(props.location.state.filterData)
  }, []);


  /* form stubmit */
  const onSubmit = (e) => {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };

    if (question == "") {
      errorObj.question = "Enter question";
      error = true;
    }
    if (feedbackType == "") {
      errorObj.feedbacktype = "Enter type";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(globalLoadingAction(true));
    dispatch(updateFeedback(item?.id, question, feedbackType));

  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
			pathname: "/feedback-question-list",
			state: previousPageFilterData,
		  });

  }


  return (
    <div className="row">
      <div className="col-xl-6 col-xl-12">
        <div className="row">
          <div className="col-xl-12">
            <div className="card pt-5 pb-5">
              <div className="col-xl-2">
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler} >
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3"></div>
                <div className="col-xl-6">
                  <div className="card-body">
                    <div className="basic-form">
                      <form onSubmit={onSubmit}>
                        <div className="form-group mb-3">
                          <label className="mb-2">
                            <strong>
                              Feedback Type
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <div className="basic-dropdown">
                            <span className="drop_down_arrow">
                              <i className="bi bi-caret-down-fill"></i>
                            </span>

                            <select
                              value={feedbackType}
                              className="form-control form-control"
                              onChange={(e) => { setFeedbackType(e.target.value) }}
                            >
                              <option value="">Select Type</option>
                              <option value="1">FSD</option>
                              <option value="2">CMC</option>
                              <option value="3">NBF</option>

                            </select>
                            {errors.feedbacktype && (
                              <div className="text-danger fs-12">
                                {errors.feedbacktype}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="mb-2">
                            <strong>
                              Question<span className="text-danger">*</span>
                            </strong>
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                          {errors.question && (
                            <div className="text-danger fs-12">
                              {errors.question}
                            </div>
                          )}
                        </div>

                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block category-btn"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateFeedback;
