import swal from "sweetalert";
import {
    getPermisstion,
} from '../../services/PermissionServices';

import {
    PERMISSION_LOADING,
    GET_PERMISSION,
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function getPermisstionData(history) {
    return (dispatch) => {
        getPermisstion()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPermisstionAction(response.data.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPermisstionAction(data) {
    return {
        type: GET_PERMISSION,
        payload: data,
    };
}

export function loadingPermissionAction(status) {
    return {
        type: PERMISSION_LOADING,
        payload: status,
    };
}
