import swal from "sweetalert";
import {
    addExamConfiguration,
    getExamConfiguration,
    updateExamConfiguration,
    removeExamConfiguratio
} from '../../services/ExamConfigurationServices';

import {
    ADD_EXAM_CONFIGURATION,
    EXAM_CONFIGURATION_LOADING,
    GET_EXAM_CONFIGURATION,
    UPDATE_EXAM_CONFIGURATION,
    REMOVE_EXAM_CONFIGURATION
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function addExamConfigurationData(category, board, type, subtype, branch, questionNo, conType, details, history) {
    return (dispatch) => {
        addExamConfiguration(category, board, type, subtype, branch, questionNo, conType, details)
            .then((response) => {
                console.log('response', response.data);
                dispatch(addExamConfigurationAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);   
                dispatch(addExamConfigurationAction(true));
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getExamConfigurationData(category_id, first_time, history) {
    return (dispatch) => {
        getExamConfiguration(category_id)
            .then((response) => {
                console.log('response', response.data);
                if (response.data.status == 200) {
                    dispatch(getExamConfigurationAction(response.data.data));
                    if (first_time == 1) {
                        utility.showSuccess(response.data.msg);
                    }
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);   
                dispatch(loadingConfiguration(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateExamConfigurationData(id, category, board, type, subtype, branch, questionNo, conType, details, history) {
    return (dispatch) => {
        updateExamConfiguration(id, category, board, type, subtype, branch, questionNo, conType, details)
            .then((response) => {
                dispatch(updateExamConfigurationAction(true));
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);   
                dispatch(updateExamConfigurationAction(true));
                utility.showError(error.response.data);
            });
    };
}

export function removeExamConfigurationData(id, history) {
    return (dispatch) => {
        removeExamConfiguratio(id)
            .then((response) => {
                console.log('response', response.data);
                dispatch(removeExamConfigurationAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function addExamConfigurationAction(status) {
    return {
        type: ADD_EXAM_CONFIGURATION,
        payload: status,
    };
}
export function getExamConfigurationAction(data) {
    return {
        type: GET_EXAM_CONFIGURATION,
        payload: data,
    };
}
export function updateExamConfigurationAction(status) {
    return {
        type: UPDATE_EXAM_CONFIGURATION,
        payload: status,
    };
}
export function removeExamConfigurationAction(status) {
    return {
        type: REMOVE_EXAM_CONFIGURATION,
        payload: status,
    };
}
export function loadingConfiguration(status) {
    return {
        type: EXAM_CONFIGURATION_LOADING,
        payload: status,
    };
}
