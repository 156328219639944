import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Button,
	Pagination
} from "react-bootstrap";
import Moment from 'react-moment';
import RankingTextInput from './component/RankingTextInput';


import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getComSubscriptionData, removeComSubscriptionData, updateComSubscriptionStatusData, subscriptionLoadingAction, getComSubscriptionRankingList } from '../../../store/actions/SubscriptionAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const CompetetiveSubscriptionList = (props) => {

	const dispatch = useDispatch();
	const { state } = useLocation();
	const [permission, setPermission] = useState("");

	const previousFilterData = props?.location.state?.previousPageFilterData;
	const [active, setActive] = useState(previousFilterData?.active ?? 1);
	const [type, setType] = useState(previousFilterData?.type ?? '');
	const [classNo, setClassNo] = useState(previousFilterData?.classNo ?? '');

	useEffect(() => {
		// console.log("props?.location.state--->>", props?.location.state)
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionData(active, type, classNo, props.history));

	}, []);

	useEffect(() => {
		getTypeHandler();
		getClassHandler();
	}, [previousFilterData]);

	/* delete confirmation */
	const onDeleteHandler = (id) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				if (props.competetiveList.length == 1) {
					dispatch(removeComSubscriptionData(id, active - 1, props.history));
					setActive(active - 1)
				} else {
					dispatch(removeComSubscriptionData(id, active, props.history));
				}

			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateComSubscriptionStatusData(item, props.history));
	}

	/* change type */
	const onTypeHandler = (id) => {
		setClassNo(0);
		setType(id);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionData(1, id, id ? classNo : 0, props.history));
	}

	/* get type list */
	const getTypeHandler = () => {
		if (props.typeList.length == 0) {
			dispatch(loadingAction(true));
			dispatch(getExamType(props.history));
		}
	}

	/* change class */
	const onClassHandler = (no) => {
		setClassNo(no);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionData(1, type, no, props.history));
	}

	/* get class list */
	const getClassHandler = () => {
		if (props.classList.length == 0) {
			dispatch(loadingClassAction(true));
			dispatch(getClassData(props.history));
		}

	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionData(data.selected + 1, type, classNo, props.history));
	}

	/* change item on blue */
	const onBlurHandler = (val, id) => {
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionRankingList(val, id, active, type, classNo, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 53) {
				setPermission(item);
			}
		});
	};

	const resetHandler = () => {
		setType('')
		setClassNo('')
		setActive(1)
		dispatch(globalLoadingAction(true));
		dispatch(getComSubscriptionData(1, '', '', props.history));

		const stateCopy = { ...props?.location.state?.previousPageFilterData };
		delete stateCopy.active;
		delete stateCopy.type;
		delete stateCopy.classNo;
		props.history.replace({ state: stateCopy });

		/* active, type, classNo */
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>

						<Card.Body>
							<div className="row add-Question-list-view">


								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position">
											{props.typeLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={type}
											onChange={(e) => onTypeHandler(e.target.value)}
											onClick={getTypeHandler}
										>
											<option value="">Select Exam type</option>
											{
												props.typeList.map((item, index) => {
													return <option key={index} value={item.id}>{item.type_name}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position">
											{props.classLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={classNo}
											onChange={(e) => onClassHandler(e.target.value)}
											onClick={getClassHandler}
										>
											<option value="">Select class</option>
											{
												props.classList.map((item, index) => {
													return <option key={index} value={item.class_no}>{item.class_no}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="col-sm-1 px-1 ">
									<button onClick={resetHandler} className={`btn btn-secondary ${type == '' && classNo == '' ? 'content_disable' : null}`}>Reset</button>
								</div>
							</div>
							{
								props.totalRecord == 0 ? (
									<div className='no_data'>
										<strong >No data found</strong>
									</div>
								) : (
									<Table responsive>
										<thead>
											<tr>

												<th>
													<strong>Exam Type</strong>
												</th>

												<th>
													<strong>Class</strong>
												</th>

												<th>
													<strong>Amount</strong>
												</th>
												<th>
													<strong>Set Count</strong>
												</th>
												<th>
													<strong>Question Per Set</strong>
												</th>
												<th>
													<strong>Library Price</strong>
												</th>
												<th>
													<strong>Sticker ext</strong>
												</th>
												<th>
													<strong>Ranking</strong>
												</th>
												<th>
													<strong>Date Time</strong>
												</th>
												{
													permission.status == "true" && (
														<th width={120}>
															<strong>Status</strong>
														</th>
													)
												}

												{
													(permission.edit == "true" || permission.delete == "true") && (
														<th>
															<strong>Action</strong>
														</th>
													)
												}
											</tr>
										</thead>
										<tbody>
											{
												props.competetiveList.map((item, index) => {
													return <tr key={index}>
														<td>{item.type_name}</td>
														<td>{item.class_no == null ? '-' : item.class_no}</td>
														<td>{item.amount}</td>
														<td>{item.set_count}</td>
														<td>{item.question_per_set}</td>
														<td>{item.library_price}</td>
														<td>{item.sticker_text == '' ? '-' : item.sticker_text}</td>
														<td>
															<RankingTextInput rank={item.ranking_no} onBlurHandler={(r) => onBlurHandler(r, item.id)} />
														</td>
														<td><Moment format="D MMM YYYY hh:mm a" withTitle>{item.created_at}</Moment></td>
														{
															permission.status == "true" && (
																<td>
																	<Button
																		disabled={props.showLoading}
																		className="btn-xs"
																		variant={item.status == 1 ? "success" : "danger"}
																		onClick={() => onUpdateStatus(item)}
																	>
																		{item.status == 1 ? "Active" : "Inactive"}
																	</Button>
																</td>
															)
														}
														{
															(permission.edit == "true" || permission.delete == "true") && (
																<td>
																	<div className="d-flex">

																		{
																			permission.edit == "true" && (
																				<Link
																					to={{
																						pathname: '/update-competitive-subscription',
																						state: {item, filterData: { active, type, classNo }}
																						

																					}}
																					className="btn btn-warning shadow btn-xs sharp me-1"
																				>
																					<i className="fas fa-pencil-alt"></i>
																				</Link>
																			)
																		}

																		{
																			permission.delete == "true" && (
																				<Button
																					className="btn btn-danger shadow btn-xs sharp"
																					onClick={() => onDeleteHandler(item.id)}
																				>
																					<i className="fa fa-trash"></i>
																				</Button>
																			)
																		}

																	</div>
																</td>
															)
														}
													</tr>
												})
											}

										</tbody>

									</Table>
								)
							}


							<div className="pagination-container">
								<div className="page-result">
									{props.totalRecord !== 0 && <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.competetiveList.length)} of {props.totalRecord}</p>}
								</div>
								{props.totalRecord > 10 ?
									< ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i class="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i class="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={pageHandler}
										pageRangeDisplayed={3}
										pageCount={props.totalPage}
									/>
									: null}
							</div>

						</Card.Body>

					</Card>

				</Col>

			</Row>

		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		competetiveList: state.subscription.competetiveList,
		totalPage: state.subscription.totalCompetetivePage,
		totalRecord: state.subscription.totalCompetetiveRecord,
		typeLoading: state.type.showLoading,
		typeList: state.type.list,
		classLoading: state.class.showLoading,
		classList: state.class.list,
	};
};
export default connect(mapStateToProps)(CompetetiveSubscriptionList);