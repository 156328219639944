import {
    USER_LOADING,
    GET_USER_TYPE,
    ADD_USER,
    ADD_TEACHER,
    GET_ADMIN_LIST,
    GET_CONTENT_DEVELOPER_LIST,
    GET_STUDENT_LIST,
    GET_TEACHER_LIST,
    UPDATE_ADMIN_LIST,
    UPDATE_CONTENT_DEVELOPER_LIST,
    UPDATE_STUDENT_LIST,
    UPDATE_TEACHER_LIST,
    REMOVE_USER,
    REMOVE_ADMIN,
    REMOVE_CONTENT_DEVELOPER,
    REMOVE_STUDENT,
    REMOVE_TEACHER,
    UPDATE_ADMIN_STATUS_LIST,
    UPDATE_DEVELOPER_STATUS_LIST,
    UPDATE_STUDENT_STATUS_LIST,
    UPDATE_TEACHER_STATUS_LIST,
    GET_ROLE_LIST,
    GET_MENU_LIST,
    CREATE_USERS_DROPDOWN_DATA
} from '../constants';

const initialState = {
    list: [],
    adminList: [],
    totalAdminPage: 0,
    totalAdminRecord: 0,
    contentDeveloperList: [],
    totalDeveloperPage: 0,
    totalDeveloperRecord: 0,
    studentList: [],
    totalStudentPage: 0,
    totalStudentRecord: 0,
    teacherList: [],
    totalTeacherPage: 0,
    totalTeacherRecord: 0,
    typeList: [],
    showLoading: false,
    rolesList:[],
    createUsersDropdownData:[]
  
};

export function UserReducer(state = initialState, action) {

    if (action.type === ADD_USER) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === ADD_TEACHER) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_USER_TYPE) {
       
        return {
            ...state,
            typeList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_ADMIN_LIST) {
        return {
            ...state,
            adminList: action.payload.data,
            totalAdminPage: action.payload.page_count,
            totalAdminRecord: action.payload.total_record,
            showLoading: false,
        };
    }
    if (action.type === GET_CONTENT_DEVELOPER_LIST) {
        return {
            ...state,
            contentDeveloperList: action.payload.data,
            totalDeveloperPage: action.payload.page_count,
            totalDeveloperRecord: action.payload.total_record,
            showLoading: false,
        };
    }
    if (action.type === GET_STUDENT_LIST) {
        return {
            ...state,
            studentList: action.payload.data,
            totalStudentPage: action.payload.page_count,
            totalStudentRecord: action.payload.total_record,
            showLoading: false,
        };
    }
    if (action.type === GET_TEACHER_LIST) {
        return {
            ...state,
            teacherList: action.payload.data,
            totalTeacherPage: action.payload.page_count,
            totalTeacherRecord: action.payload.total_record,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_ADMIN_LIST) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_CONTENT_DEVELOPER_LIST) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === UPDATE_STUDENT_LIST) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_TEACHER_LIST) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_ADMIN_STATUS_LIST) {

        const list = [...state.adminList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            adminList: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_DEVELOPER_STATUS_LIST) {

        const list = [...state.contentDeveloperList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            contentDeveloperList: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STUDENT_STATUS_LIST) {

        const list = [...state.studentList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            studentList: list,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_TEACHER_STATUS_LIST) {

        const list = [...state.teacherList];
        const index = list.findIndex((item) => item.id === action.payload.id);
        list[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            teacherList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_USER) {
        const list = [...state.list];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            list: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_ADMIN) {
        const list = [...state.adminList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            adminList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_CONTENT_DEVELOPER) {
        const list = [...state.contentDeveloperList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            contentDeveloperList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_STUDENT) {
        const list = [...state.studentList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            studentList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_TEACHER) {
        const list = [...state.teacherList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            teacherList: list,
            showLoading: false,
        };
    }

    if (action.type === GET_ROLE_LIST) {
        return {
            ...state,
            rolesList: action.payload,
        };
    }

    if (action.type === USER_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    if (action.type === CREATE_USERS_DROPDOWN_DATA) {
        return {
            ...state,
            createUsersDropdownData: action.payload,
        };
    }
   

    return state;
}


