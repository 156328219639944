import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { validateTextOnly, validateShortCode, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';
import { addBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';


const CreateBoard = (props) => {

	const dispatch = useDispatch();
	let errorsObj = { board: '', code: '', fullBoardname: '', conducted: '' };

	const [errors, setErrors] = useState(errorsObj);
	const [boardName, setBoardName] = useState('');
	const [fullBoardName, setFullBoardName] = useState('');
	const [conductedBy, setConductedBy] = useState('');
	const [shortCode, setShortCode] = useState('');

	useEffect(() => {

	}, []);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();

		let error = false;
		const errorObj = { ...errorsObj };

		if (boardName === '') {
			errorObj.board = 'Board name is Required';
			error = true;
		} else if (validateTextOnly(boardName) === false) {
			errorObj.board = 'Invalid Exam Board name';
			error = true;
		}
		if (fullBoardName === '') {
			errorObj.fullBoardname = 'Full board name is Required';
			error = true;
		}else if(!/^[a-zA-Z\s]+$/.test(fullBoardName)){
			errorObj.fullBoardname = "Only alphabetic characters is allow";
			error = true;
		  }
		if (conductedBy === '') {
			errorObj.conducted = 'Conducted by is Required';
			error = true;
		}else if(!/^[a-zA-Z\s]+$/.test(conductedBy)){
			errorObj.conducted = "Only alphabetic characters is allow";
			error = true;
		  }
		if (shortCode === '') {
			errorObj.code = 'Board Short code is Required';
			error = true;

		} else if (validateShortCode(shortCode) === false) {
			errorObj.code = 'Invalid Short code';
			error = true;
		}else if(!/^[a-zA-Z]+$/.test(shortCode)){
			errorObj.code = "Only alphabetic characters is allow";
			error = true;
		  }
		setErrors(errorObj);
		if (error) {
			return;
		}

		dispatch(globalLoadingAction(true));
		dispatch(addBoardData(boardName, fullBoardName, conductedBy, shortCode, props.history));
		setFullBoardName('');
		setBoardName('');
		setShortCode('');
		setConductedBy('');
	}

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">
					<div className="row">

						<div className="col-xl-12">
							<div className="card pt-5 pb-5">
								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">
										<div className="card-body">
											<div className="basic-form">

												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Exam Category</strong>
														</label>
														<input 
														className="form-control" 
														placeholder="Exam Category" 
														disabled 
														value="Scholastic" />
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Board name<span className="text-danger">*</span></strong>
														</label>
														<input 
														className="form-control" 
														placeholder="Enter the board name" 
														value={boardName} 
														onChange={(e) => setBoardName(e.target.value)} 
														onKeyPress={inputAlphabetOnlyWithSpace}
														/>
														{errors.board && <div className="text-danger fs-12">{errors.board}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Full board name<span className="text-danger">*</span></strong>
														</label>
														<input 
														className="form-control" 
														placeholder="Enter the full board name" 
														value={fullBoardName} 
														onChange={(e) => setFullBoardName(e.target.value)} 
														onKeyPress={inputAlphabetOnlyWithSpace}
														/>
														{errors.fullBoardname && <div className="text-danger fs-12">{errors.fullBoardname}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Conducted by<span className="text-danger">*</span></strong>
														</label>
														<input 
														className="form-control" 
														placeholder="Enter the conducted by" 
														value={conductedBy} 
														onChange={(e) => setConductedBy(e.target.value)} 
														onKeyPress={inputAlphabetOnlyWithSpace}
														/>
														{errors.conducted && <div className="text-danger fs-12">{errors.conducted}</div>}
													</div>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Board short code<span className="text-danger">*</span></strong>
														</label>
														<input 
														className="form-control" 
														placeholder="Enter the short code" 
														value={shortCode} 
														onChange={(e) => setShortCode(e.target.value)} 
														onKeyPress={inputAlphabetOnlyWithSpace}
														/>
														{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
													</div>



													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>

												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>


			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
	};
};
export default connect(mapStateToProps)(CreateBoard);