import swal from "sweetalert";
import {
    getLanguageValidator,
    updateLanguageValidator,
    updateLanguageValidatorStatus,
    removeLanguageValidator
} from '../../services/ContentLanguageValidatorServices';

import {
    CONTENT_LANGUAGE_VALIDATOR_LOADING,
    GET_CONTENT_LANGUAGE_VALIDATOR_LIST,
    UPDATE_CONTENT_LANGUAGE_VALIDATOR,
    UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS,
    REMOVE_CONTENT_LANGUAGE_VALIDATOR
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function getLanguageValidatorData(page, name, email,phoneno, history) {
    return (dispatch) => {
        getLanguageValidator(page, name, email,phoneno)
            .then((response) => {
                dispatch(getLanguageValidatorAction(response.data));
                //utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateLanguageValidatorData(id, name, email, phone, history) {
    return (dispatch) => {
        updateLanguageValidator(id, name, email, phone)
            .then((response) => {
                dispatch(updateLanguageValidatorAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateLanguageValidatorStatusData(item, history) {
    return (dispatch) => {
        updateLanguageValidatorStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateLanguageValidatorStatusAction(item));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}
export function removeLanguageValidatorDetails(id,active, history) {
    return (dispatch) => {
        dispatch(getLanguageValidatorData(active, '','','',history));
        removeLanguageValidator(id)
            .then((response) => {
                console.log('response', response.data);
                dispatch(removeLanguageValidatorAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function getLanguageValidatorAction(data) {
    return {
        type: GET_CONTENT_LANGUAGE_VALIDATOR_LIST,
        payload: data,
    };
}
export function updateLanguageValidatorAction(data) {
    return {
        type: UPDATE_CONTENT_LANGUAGE_VALIDATOR,
        payload: data,
    };
}
export function updateLanguageValidatorStatusAction(data) {
    return {
        type: UPDATE_CONTENT_LANGUAGE_VALIDATOR_STATUS,
        payload: data,
    };
}
export function removeLanguageValidatorAction(data) {
    return {
        type: REMOVE_CONTENT_LANGUAGE_VALIDATOR,
        payload: data,
    };
}
export function loadingLanguageValidatorAction(status) {
    return {
        type: CONTENT_LANGUAGE_VALIDATOR_LOADING,
        payload: status,
    };
}
