import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { Link, useLocation, useHistory } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button,
	Pagination
} from "react-bootstrap";

import { getChaptersData, loadingChaptersAction, removeChaptersData, updateStatusChapterData, getChapterOrderingList } from '../../../store/actions/ChapterAction';
import { getBranchDetails, branchLoading } from '../../../store/actions/BranchActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getNonGroupSubjectsList, getNonGroupSubjectsAction, subjectLoading } from '../../../store/actions/SujectActions';

import RankingTextInput from '../Subscription/component/RankingTextInput';

const ChapterList = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const { state } = useLocation();

	// const [active, setActive] = useState(state == undefined ? 1 : state?.page);
	const [active, setActive] = useState(state?.page ?? 1);
	const [total, setTotal] = useState(1);
	// const [branch, setBranch] = useState(state == undefined ? 0 : state.subject);
	const [branch, setBranch] = useState(state?.subject ?? 0);
	const [page, setPage] = useState(1)
	const [Data, setData] = useState()
	const [totalPage, setTotalPage] = useState()
	const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10)
	const [start, setStart] = useState(1)
	const [end, setEnd] = useState()
	const firstTimeCallStartPage = useRef(true);

	// const [board, setBoard] = useState(state == undefined ? 0 : state.board);
	const [board, setBoard] = useState(state?.board ?? 0);
	// const [type, setType] = useState(state == undefined ? 0 : state.type);
	const [type, setType] = useState(state?.type ?? 0);
	// const [subtype, setSubtype] = useState(state == undefined ? 0 : state.subtype);
	const [subtype, setSubtype] = useState(state?.subtype ?? 0);
	// const [category, setCategory] = useState(state == undefined ? 0 : state.category);
	const [category, setCategory] = useState(state?.category ?? 0);
	// const [standard, setStandard] = useState(state == undefined ? 0 : state.class);
	const [standard, setStandard] = useState(state?.class ?? 0);

	const [permission, setPermission] = useState("");


	useEffect(() => {
		permissionCheck();
		dispatch(loadingToggleAction(true));
		dispatch(getExamCategory(props.history));
		dispatch(loadingAction(true));
		dispatch(getExamType(props.history));
		dispatch(loadingBoardAction(true));
		dispatch(getBoardData(props.history));
		dispatch(loadingClassAction(true));
		dispatch(getClassData(props.history));
		///dispatch(getNonGroupSubjectsAction([]));
		/* if (firstTimeCallStartPage.current) {
			console.log("@1")
			dispatch(globalLoadingAction(true));
			dispatch(getChaptersData(active, board, type, subtype, standard, category, branch, props.history));
		} */
		dispatch(branchLoading(true));
		dispatch(getBranchDetails(props.history));
		setTotalPage(props.totalRecord / pageRangeDisplayed)

		if (firstTimeCallStartPage.current) {
			setStart((page - 1) * pageRangeDisplayed + 1)
			setEnd(pageRangeDisplayed * page)
		}
		firstTimeCallStartPage.current = false
	}, [page, start, end, props.totalRecord]);

	useEffect(() => {
		// console.log("state------------123", state)
		if (state?.type != '') {
			getSubtypeHandler()
		}
		if (state?.branch != '') {
			getSubjectHandler()
		}
		dispatch(globalLoadingAction(true));
		dispatch(getChaptersData(active, board, type, subtype, standard, category, branch, props.history));

		setStart(1);
		setEnd(pageRangeDisplayed * page)
	}, [board, type, subtype, standard, category, branch,]);

	/* set delete confirmation modal */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {

				if (props.list == 1) {
					dispatch(removeChaptersData(i, active - 1, props.history));
					setActive(active - 1)
				} else {
					dispatch(removeChaptersData(i, active, props.history));
				}


			}
		})
	}

	/* item details updated */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateStatusChapterData(item, props.history));
	}

	/* get subject list */
	const onSubjectHandler = (val) => {
		setBranch(val);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getChaptersData(1, board, type, subtype, standard, category, val, props.history));
	}

	/* pagination */
	const handlePageClick = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getChaptersData(data.selected + 1, board, type, subtype, standard, category, branch, props.history));
		setStart(((data.selected + 1) - 1) * pageRangeDisplayed + 1)
		setEnd((data.selected + 1) * pageRangeDisplayed)
		if (((data.selected + 1) * pageRangeDisplayed) > props.totalRecord) {
			setEnd(props.totalRecord);
		}
	}

	/* on change category */
	const onChangeCategoryHandler = (e) => {
		setStandard(0)
		// console.log('*******', e)
		setBranch(0)
		setCategory(e)
		setActive(1);
		setSubtype(0);

		if (e == 1) {
			setType(0)
		} else if (e == 2) {
			setBoard(0)
		}
		if (e == '') {
			setType(0)
			setBoard(0)
			setActive(1);
		}
		dispatch(getNonGroupSubjectsAction([]));

	}

	/* on change board */
	const onChangeBoardHandler = (e) => {
		setBoard(e)
		setActive(1);
		setSubtype(0);
		dispatch(getNonGroupSubjectsAction([]));
	}

	/* on change Type */
	const onChangeTypeHandler = (e) => {
		setType(e)
		setActive(1);
		setSubtype(0);
		dispatch(getNonGroupSubjectsAction([]));
	}

	/* on change class */
	const onChangeClassHandler = (e) => {
		setStandard(e)
		setActive(1);
	}

	/* get subject */
	const getSubjectHandler = () => {
		if (props.subjectList.length == 0) {
			dispatch(subjectLoading(true));
			dispatch(getNonGroupSubjectsList(category, type, subtype, board, props.history));
		}
	}

	/* get subtype list */
	const getSubtypeHandler = () => {
		if (props.subtypeList.length == 0) {
			dispatch(loadingSubTypeAction(true));
			dispatch(getExamSubType(props.history));
		}
	}

	/* on change subtype  */
	const onChangeSubtypeHandler = (value) => {
		setSubtype(value);
		setActive(1)
		dispatch(getNonGroupSubjectsAction([]));
		dispatch(globalLoadingAction(true));
		dispatch(getChaptersData(active, board, type, value, standard, category, branch, props.history));
	}

	/* on reset */
	const onResetHandler = () => {
		dispatch(globalLoadingAction(true));
		dispatch(getChaptersData(1, 0, 0, 0, 0, 0, 0, props.history));
		setActive(1);
		setCategory(0);
		setBoard(0);
		setType(0);
		setSubtype(0);
		setStandard(0);
		setBranch(0);

		const stateCopy = { ...props?.location.state };
		delete stateCopy.page;
		delete stateCopy.category;
		delete stateCopy.board;
		delete stateCopy.type;
		delete stateCopy.subtype;
		delete stateCopy.class;
		delete stateCopy.subject;
		props.history.replace({ state: stateCopy });

		/* 'page': active, 'category': category, 'board': board, 'type': type, 'subtype': subtype, 'class': standard, 'subject': branch */
	}

	/* get list on blur */
	const onBlurHandler = (val, id) => {
		dispatch(globalLoadingAction(true));
		dispatch(getChapterOrderingList(val, id, active, board, type, subtype, standard, category, branch));
	}

	/* const onRankingHandler = (val, id) => {
		dispatch(globalLoadingAction(true));
		dispatch(getChapterOrderingList(val.target.value, id, active, board, type, subtype, standard, category, branch));
	} */

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 34) {
				setPermission(item);
			}
		});
	};

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="row chapter-list-view">
								{/* <div className="col-sm-12 px-1 reset-btn-view">
									<button onClick={onResetHandler} className="btn btn-secondary">Reset</button>
								</div> */}
								{/* <div className="col-sm-2"></div> */}
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={category}
											onChange={(e) => onChangeCategoryHandler(e.target.value)}
										>
											<option value="">Select Category</option>
											{
												props.categorylist.map((item, index) => {
													return <option key={index} value={item.id}>{item.category}</option>
												})
											}
										</select>
									</div>
								</div>

								{category == 1 ?
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												defaultValue={"option"}
												className="form-control filter-form-control"
												value={board}
												onChange={(e) => onChangeBoardHandler(e.target.value)}
											>
												<option value="">Select Board</option>
												{
													props.boardList.map((item, index) => {
														return <option key={index} value={item.id}>{item.name}</option>
													})
												}
											</select>
										</div>
									</div>
									: category == "" ?
										<div className="col-sm-2 px-1">
											<div className="basic-dropdown">
												<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
												<select
													defaultValue={"option"}
													className="form-control filter-form-control content_disable"
												// disabled
												>
													<option value="">Select Board/Type</option>

												</select>
											</div>
										</div>
										:
										<div className="col-sm-2 px-1">
											<div className="basic-dropdown">
												<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
												<select
													defaultValue={"option"}
													className="form-control filter-form-control"
													value={type}
													onChange={(e) => onChangeTypeHandler(e.target.value)}
												>
													<option value="">Select type</option>
													{
														props.typeList.map((item, index) => {
															return <option key={index} value={item.id}>{item.type_name}</option>
														})
													}
												</select>
											</div>
										</div>
								}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.subtypeLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											defaultValue={"option"}
											className={`form-control filter-form-control ${type == 1 ? null : 'content_disable'}`}
											value={subtype}
											// disabled={type == 1 ? false : true}
											onChange={(e) => onChangeSubtypeHandler(e.target.value)}
											onClick={getSubtypeHandler}
										>
											<option value="">Select subtype</option>
											{
												props.subtypeList.map((item, index) => {
													return <option key={index} value={item.id}>{item.subtype_name}</option>
												})
											}
										</select>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											// defaultValue={"option"} , , subtype, ,
											className={`form-control filter-form-control ${type == '' && board == '' ? 'content_disable' : null}`}
											value={branch}
											onChange={(e) => onSubjectHandler(e.target.value)}
											onClick={getSubjectHandler}
										>
											<option value="">Select subject</option>
											{
												props.subjectList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className={`form-control filter-form-control ${type == 1 ? 'content_disable' : null}`}
											value={standard}
											// disabled={type == 1 ? true : false}
											onChange={(e) => onChangeClassHandler(e.target.value)}
										>
											<option value="">Select class</option>
											{
												props.classList.map((item, index) => {
													return <option key={index} value={item.id}>{item.short_code}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className={`col-sm-2 px-1 reset-btn-view ${category == '' && standard == '' ? 'content_disable' : null}`}>
									<button onClick={onResetHandler} className="btn btn-secondary">Reset</button>
								</div>

							</div>
							{
								props.totalRecord == 0 ? (
									<div className='no_data'>
										<strong >No data found</strong>
									</div>
								) : (
									<Table responsive>
										<thead>
											<tr>
												<th>
													<strong>Category</strong>
												</th>
												<th>
													<strong>Board/Type</strong>
												</th>
												<th>
													<strong>Subtype</strong>
												</th>
												<th>
													<strong>Subject</strong>
												</th>
												<th>
													<strong>Standard</strong>
												</th>
												<th>
													<strong>Chapter</strong>
												</th>
												<th>
													<strong>Chapter code</strong>
												</th>
												<th>
													<strong>Order</strong>
												</th>
												<th>
													<strong>Create Date</strong>
												</th>

												{
													permission.status == "true" && (
														<th>
															<strong>Status</strong>
														</th>
													)
												}
												{
													(permission.edit == "true" || permission.delete == "true") && (
														<th>
															<strong>Action</strong>
														</th>
													)
												}
											</tr>
										</thead>
										<tbody>
											{
												props.list.map((item, index) => {
													return <tr key={index}>
														<td>{item.category}</td>
														<td>{item.exam_board}</td>
														<td>{item.subtype_name != '' && item.subtype_name != null ? item.subtype_name : '-'}</td>
														<td>{item.branch_name}</td>
														<td>{item.standard}</td>
														<td>{item.chapter_name}</td>
														<td>{item.short_code}</td>
														<td><RankingTextInput rank={item.order_no} onBlurHandler={(r) => onBlurHandler(r, item.id)} /></td>
														{/* <td><RankingTextInput rank={item.order_no} onRankingHandler={(r) => onRankingHandler(r, item.id)} /></td> */}
														<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
														{
															permission.status == "true" && (
																<td>
																	<Button
																		className="btn-xs"
																		variant={item.status == 1 ? "success" : "danger"}
																		onClick={() => onUpdateStatus(item)}
																	>
																		{item.status == 1 ? "Active" : "Inactive"}
																	</Button>

																</td>
															)
														}
														{
															(permission.edit == "true" || permission.delete == "true") && (
																<td>
																	<div className="d-flex">
																		{
																			permission.edit == "true" && (
																				<Link
																					to={{
																						pathname: '/update-chapter',
																						state: { 'data': item, 'filter': { 'page': active, 'category': category, 'board': board, 'type': type, 'subtype': subtype, 'class': standard, 'subject': branch } }
																					}}
																					className="btn btn-warning shadow btn-xs sharp me-1"
																				>
																					<i className="fas fa-pencil-alt"></i>
																				</Link>
																			)
																		}
																		{
																			permission.delete == "true" && (
																				<Button
																					className="btn btn-danger shadow btn-xs sharp"
																					onClick={() => onDeleteModal(item.id)}
																				>
																					<i className="fa fa-trash"></i>
																				</Button>
																			)
																		}

																	</div>
																</td>
															)
														}
													</tr>
												})
											}
										</tbody>
									</Table>
								)
							}

							<div className="pagination-container">

								<div className="page-result">

									{/* <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord} items</p> */}
									{/* {props.totalRecord !== 0 && <p>{start + '-' + end + ' of ' + props.totalRecord + ' items'}</p>} */}
									{props.totalRecord !== 0 && (
										<p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}  items</p>
									)}
								</div>

								{props.totalRecord > 10 ?
									<ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i className="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i className="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={handlePageClick}
										pageRangeDisplayed={3}
										pageCount={props.totalPage}
										forcePage={active - 1}
									/>
									: null
								}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	// console.log("=========",state.chapter.list)
	return {
		showLoading: state.chapter.showLoading,
		list: state.chapter.list,
		totalPage: state.chapter.totalPage,
		totalRecord: state.chapter.totalRecord,
		branchList: state.branch.branchList,
		categorylist: state.category.list,
		boardList: state.board.list,
		typeList: state.type.list,
		classList: state.class.list,
		subjectLoading: state.subject.showLoading,
		subjectList: state.subject.nonGroupSubjectList,
		subtypeLoading: state.subtype.showLoading,
		subtypeList: state.subtype.list,
	};
};

export default connect(mapStateToProps)(ChapterList);