import {
    SCHOOL_LOADING,
    GET_SCHOOL_DETAILS,
    REMOVE_SCHOOL_DETAILS
} from '../constants';

const initialState = {
    schoolList: [],
    totalRecode: 0,
    totalPage: 0,
    showLoading: false,
};

export function SchoolReducer(state = initialState, action) {

    if (action.type === GET_SCHOOL_DETAILS) {
        return {
            ...state,
            schoolList: action.payload.data,
            totalRecode: action.payload.total_record,
            totalPage: action.payload.total_page,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_SCHOOL_DETAILS) {

        const list = [...state.schoolList];
        const index = list.findIndex((item) => item.id === action.payload);
        list.splice(index, 1);

        return {
            ...state,
            schoolList: list,
            showLoading: false,
        };
    }

    if (action.type === SCHOOL_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


