import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	// Pagination,
	Modal,
	Button,
	Tooltip,
} from "react-bootstrap";

import { getElibraryData, loadingElibraryAction, deleteLibraryData } from '../../../store/actions/ElibraryAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getBranchDetails, branchLoading } from '../../../store/actions/BranchActions';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getAssignElibraryContentData } from '../../../store/actions/AssignContentAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getNonGroupSubjectsList, getNonGroupSubjectsAction, subjectLoading } from '../../../store/actions/SujectActions';

import ReactPaginate from "react-paginate";

import {
	getbranchTypeData,
	getChaptersDeffarentsParamsData,
	loadingChaptersAction,
	getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";

import { deleteAssignContentData, removeScuccessfullyAction, getRejectedListData } from '../../../store/actions/AssignContentAction';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsJournalMedical } from "react-icons/bs";


const AssignElibraryList = (props) => {

	const dispatch = useDispatch();
	const [active, setActive] = useState(1);
	const [demo, setDemo] = useState('');
	const [category, setCategory] = useState(props?.location.state?.category != '' && props?.location.state?.category != undefined ? props?.location.state?.category : '');
	const [board, setBoard] = useState(props?.location.state?.board != '' && props?.location.state?.board != undefined ? props?.location.state?.board : '');
	const [type, setType] = useState(props?.location.state?.type != '' && props?.location.state?.type != undefined ? props?.location.state?.type : '');
	const [classRoom, setClassRoom] = useState(props?.location.state?.classRoom != '' && props?.location.state?.classRoom != undefined ? props?.location.state?.classRoom : '');
	const [subject, setSubject] = useState(props?.location.state?.subject != '' && props?.location.state?.subject != undefined ? props?.location.state?.subject : '');
	const [chapter, setChapter] = useState(props?.location.state?.chapter != '' && props?.location.state?.chapter != undefined ? props?.location.state?.chapter : '');
	const [contentStatus, setContentStatus] = useState(props?.location.state?.contentStatus != '' && props?.location.state?.contentStatus != undefined ? props?.location.state?.contentStatus : '');
	const [approveStatus, setApproveStatus] = useState(props?.location.state?.approveStatus != '' && props?.location.state?.approveStatus != undefined ? props?.location.state?.approveStatus : '')

	const [rejectListModal, setRejectListModal] = useState('');

	const [start, setStart] = useState(1)
	const [end, setEnd] = useState()
	const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10)
	const [page, setPage] = useState(1)
	const [rejectToolTipsHide, setRejectToolTipsHide] = useState(350)
	const [toolTipisShowing, setToolTipisShowing] = useState(false)


	const prevCategoryRef = useRef(category);
	const rejectOverlayRef = useRef();


	const againCallListData = useSelector((state) => state.assign.againCallListData);

	useEffect(() => {
		// console.log("type----", category, board, type, subject, classRoom,)
		dispatch(getExamCategory(props.history));
		dispatch(globalLoadingAction(true));
		dispatch(loadingAction(true));
		dispatch(getExamType(props.history));
		dispatch(loadingBoardAction(true));
		dispatch(getBoardData(props.history));
		dispatch(loadingClassAction(true));
		dispatch(getClassData(props.history));
		// dispatch(getAssignElibraryContentData('E', props.history));
		dispatch(getChaptersDeffarentsParamsAction([]));
	}, []);

	useEffect(() => {
		// console.log("type----", "category--:", category, "board--", board, "type--", type, "subject--", subject, "classRoom--", classRoom,)
		dispatch(getAssignElibraryContentData('E', category, category == 1 ? board : '', category == 2 ? type : '', classRoom, subject, chapter, contentStatus, 1, approveStatus, props.history));
		setActive(1)
	}, [category, board, type, subject, classRoom, chapter, contentStatus, approveStatus]);

	useEffect(() => {
		if (againCallListData == 1) {
			dispatch(getAssignElibraryContentData('E', category, category == 1 ? board : '', category == 2 ? type : '', classRoom, subject, chapter, contentStatus, active, props.history));
			removeScuccessfullyAction(0)
		}
	}, [againCallListData]);


	/* get subject  */
	const getSubjectHandler = () => {
		dispatch(subjectLoading(true));
		dispatch(getNonGroupSubjectsList(category, type, 0, board, props.history));
	}

	/* get Chapter  */
	const getChapterHandler = () => {
		// category_id, board_id, exam_type_id, subtype_id, subject_id, class_id,
		// category, board, type, subtype_id, subject, classRoom,
		dispatch(loadingChaptersAction(true))
		dispatch(
			getChaptersDeffarentsParamsData(
				parseInt(category),
				category == 1 ? board : 0,
				category == 1 && board ? 0 : type,
				0,
				subject,
				classRoom,
				props.history
			)
		);
	}

	/* confirmation for deletion */
	const onDeleteHandler = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(deleteAssignContentData(i, props.history));
			}
		})
	}

	const onChangeDemoHandler = (value) => {
		setDemo(value);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getElibraryData(1, value, category, board, props.history));
	}

	const getCategoryHandler = () => {
		if (props.categoryList.length == 0) {
			dispatch(loadingToggleAction(true));
			dispatch(getExamCategory(props.history));
		}
	}

	const onCategoryHandler = (value) => {
		setCategory(value);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getElibraryData(1, demo, value, board, props.history));
	}

	const getBoardHandler = () => {
		if (props.boardList.length == 0) {
			dispatch(loadingBoardAction(true));
			dispatch(getBoardData(props.history));
		}
	}

	const onBoardHandler = (value) => {
		setBoard(value);
		setActive(1);
		dispatch(globalLoadingAction(true));
		dispatch(getElibraryData(active, demo, category, value, props.history));
	}

	const onIncrementHandler = () => {
		if (active < props.totalPage) {
			setActive(active + 1);
			dispatch(globalLoadingAction(true));
			dispatch(getAssignElibraryContentData('E', props.history));
		}
	}

	const onDecrementHandler = () => {
		if (active > 1) {
			setActive(active - 1);
			dispatch(globalLoadingAction(true));
			dispatch(getAssignElibraryContentData('E', props.history));
		}
	}

	/* form reset */
	const onResetHandler = () => {
		setCategory('')
		setBoard('');
		setType('');
		setSubject('');
		setChapter('');
		setClassRoom('');
		setContentStatus('');
		setApproveStatus('');
	}

	/* on change category */
	const onChangeCategoryHandler = (e) => {

		if (e != prevCategoryRef.current) {
			setBoard('');
			setType('');
			setSubject('');
			setChapter('');
			setClassRoom('')
			dispatch(getChaptersDeffarentsParamsAction([]));
			dispatch(getNonGroupSubjectsAction([]));
		}

		if (e == '') {
			setCategory('')
			setBoard('');
			setType('');
			setSubject('');
			setChapter('');
			setClassRoom('')
			setContentStatus('')
		} else {
			setCategory(e)
		}
	}

	/* on change board */
	const onChangeBoardHandler = (e) => {
		setBoard(e)
	}

	/* on change Type */
	const onChangeTypeHandler = (e) => {
		setType(e)
	}

	/* on change Subject */
	const onSubjectHandler = (e) => {
		setSubject(e)
	}

	/* on change Chapter */
	const onChapterHandler = (e) => {
		setChapter(e)
	}

	/* on change Class */
	const onChangeClassHandler = (e) => {
		setClassRoom(e)
	}

	/* on change Status */
	const onStatusHandler = (e) => {
		setContentStatus(e)
	}

	/* on reject List */
	const rejectListHandeler = (id) => {
		dispatch(globalLoadingAction(true));
		dispatch(getRejectedListData(id));
		setRejectListModal(true)
	}

	/* get History List */
	const HistoryListHandeler = (item) => {
		props.history.push({
			pathname: '/assigned-content-history-list',
			state: item
		})
	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		// dispatch(globalLoadingAction(true));
		// dispatch(getChaptersData(data.selected + 1, board, type, subtype, standard, category, branch, props.history));
		dispatch(getAssignElibraryContentData('E', category, category == 1 ? board : '', category == 2 ? type : '', classRoom, subject, chapter, contentStatus, data.selected + 1, props.history));
		setStart(((data.selected + 1) - 1) * pageRangeDisplayed + 1)
		setEnd((data.selected + 1) * pageRangeDisplayed)
		if (((data.selected + 1) * pageRangeDisplayed) > props.contentListTotalPage) {
			setEnd(props.contentListTotalPage);
		}
	}

	/* modal close */
	const closeRejectModal = () => {
		setRejectListModal(false)
		// setRejectToolTipsHide(100)
		// rejectOverlayRef.current.hide();
		setToolTipisShowing(false)
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="row chapter-list-view">
								<div className="col-sm-12 px-1 reset-btn-view">
									<button onClick={onResetHandler} 
									className={`btn btn-secondary ${category == '' && classRoom == '' && chapter == '' && contentStatus == '' && approveStatus == ''? 'content_disable' : null}`}>Reset</button>
								</div>
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={category}
											onChange={(e) => onChangeCategoryHandler(e.target.value)}
										>
											<option value="">Select Category</option>
											{props.categoryList.map((item, index) => {
												return <option key={index} value={item.id}>{item.category}</option>
											})
											}
										</select>
									</div>
								</div>

								{category == 1 ?
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												// defaultValue={"option"}
												className="form-control filter-form-control"
												value={board}
												onChange={(e) => onChangeBoardHandler(e.target.value)}
											>
												<option value="">Select Board</option>
												{
													props.boardList.map((item, index) => {
														return <option key={index} value={item.id}>{item.name}</option>
													})
												}
											</select>
										</div>
									</div>
									: category == "" ?
										<div className="col-sm-2 px-1">
											<div className="basic-dropdown">
												<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
												<select
													// defaultValue={"option"}
													className={`form-control filter-form-control content_disable`}
													// disabled
												>
													<option value="">Select Board/Type</option>

												</select>
											</div>
										</div>
										:
										<div className="col-sm-2 px-1">
											<div className="basic-dropdown">
												<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
												<select
													// defaultValue={"option"}
													className="form-control filter-form-control"
													value={type}
													onChange={(e) => onChangeTypeHandler(e.target.value)}
												>
													<option value="">Select type</option>
													{
														props.typeList.map((item, index) => {
															return <option key={index} value={item.id}>{item.type_name}</option>
														})
													}
												</select>
											</div>
										</div>
								}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={classRoom}
											disabled={type == 1 ? true : false}
											onChange={(e) => onChangeClassHandler(e.target.value)}
										>
											<option value="">Select class</option>
											{
												props.classList.map((item, index) => {
													return <option key={index} value={item.id}>{item.short_code}</option>
												})
											}
										</select>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.showLoadingSubject ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											// defaultValue={"option"}
											className={`form-control filter-form-control ${category == '' && board == '' && type == '' ? 'content_disable' : null}`}
											value={subject}
											onChange={(e) => onSubjectHandler(e.target.value)}
											onClick={getSubjectHandler}
										>
											<option value="">Select subject</option>
											{
												props.subjectList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>

								{subject != '' ?
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow">
												{props.showLoadingChapter ?
													<Spinner animation="border" size="sm" />
													: <i className="bi bi-caret-down-fill"></i>}
											</span>
											{/* category, board, type, subtype_id, subject, classRoom, */}
											<select
												// defaultValue={"option"}
												className="form-control filter-form-control"
												value={chapter}
												onChange={(e) => onChapterHandler(e.target.value)}
												onClick={getChapterHandler}
											>
												<option value="">Select Chapter</option>
												{
													props.chapterList.map((item, index) => {
														return <option key={index} value={item.id}>{item.chapter_name}</option>
													})
												}
											</select>
										</div>
									</div>
									:
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow">
												<i className="bi bi-caret-down-fill"></i>
											</span>
											{/* category, board, type, subtype_id, subject, classRoom, */}
											<select
												// defaultValue={"option"}
												className="form-control filter-form-control content_disable"
												value={chapter}
												onChange={(e) => onChapterHandler(e.target.value)}
											>
												<option value="">Select Chapter</option>

											</select>
										</div>
									</div>
								}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={contentStatus}
											onChange={(e) => onStatusHandler(e.target.value)}
										>
											<option value="">Select Status</option>
											<option value="0">Pending</option>
											<option value="1">Published</option>

										</select>
									</div>
								</div>

								<div className="col-sm-2 px-1 mt-2">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{/* {props.showLoadingSubject ?
												<Spinner animation="border" size="sm" />
												: */} <i className="bi bi-caret-down-fill"></i>{/* } */}
										</span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={approveStatus}
											onChange={(e) => setApproveStatus(e.target.value)}
											// onClick={getSubjectHandler}
											isSearchable={true}
											styles={{
												height: 200
											}}
										>
											<option value="">Select Approve Status</option>
											{props.filterDataList != '' ?
												props.filterDataList.map((item, index) => {
													return <option key={index} value={item.key}>{item.value}</option>
												})
												: null}
										</select>
									</div>
								</div>
							</div>
							<Table responsive>
								<thead>
									<tr>
										{/* <th className='col_heading'>
											<strong>#</strong>
										</th> */}
										<th className='col_heading'>
											<strong>Category</strong>
										</th>
										<th className='col_heading'>
											<strong>Exam Type</strong>
										</th>
										<th className='col_heading'>
											<strong>Class</strong>
										</th>
										<th className='col_heading'>
											<strong>Board</strong>
										</th>
										<th className='col_heading'>
											<strong>Subject</strong>
										</th>
										<th className='col_heading'>
											<strong>Chapter</strong>
										</th>
										<th className='chapter_col col_heading'>
											<strong>Content Creators</strong>
										</th>
										<th className='col_heading'>
											<strong>Create Date</strong>
										</th>
										<th className='col_heading'>
											<strong>Update Date</strong>
										</th>
										<th className='chapter_col col_heading'>
											<strong>Approve Status</strong>
										</th>
										<th className='col_heading'>
											<strong>Content Status</strong>
										</th>
										<th className='col_heading'>
											<strong>Action</strong>
										</th>
									</tr>
									{/* //13 items */}
								</thead>
								{props.assignList != '' ?
									<tbody>
										{

											props.assignList.map((item, index) => {
												return <tr key={index}>
													{/* <td>{item.serial_no}</td> */}
													<td>{item.category}</td>
													<td>{item.type_name}</td>
													<td>{item.class_code}</td>
													<td>{item.board_name}</td>
													<td>{item.subject_name}</td>
													<td>{item.chapter_name}</td>
													<td>
														<div className='chapter_col'>
															<div><storn>Developer:</storn> {item.developer_name}</div>
															<div>Amount : {item.developer_payment}/-</div>
															<div>Content validator: {item.validator_name != '' ? item.validator_name : "Not assigned"}</div>
															<div>Amount : {item.validator_payment != '' ? item.validator_payment + "/-" : "0/-"}</div>
															<div>Language validator: {item.language_validator_name != '' ? item.language_validator_name : "Not assigned"}</div>
															<div>Amount : {item.language_validator_payment != '' ? item.language_validator_payment + "/-" : "0/-"}</div>
															<div>Designer: {item.designer_name != "" ? item.designer_name : "Not assigned"}</div>
															<div>Amount : {item.designer_payment != '' ? item.designer_payment + "/-" : "0/-"}</div>
														</div>
													</td>
													<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
													<td><Moment format="D MMM YYYY" withTitle>{item.updated_at}</Moment></td>
													<td>
														<div className={`approve_status chapter_col ${item.approved_status == 0 ? "pending_background" : item.developer_approval_status != 4 && item.approved_status == 1 && item.admin_approval_status == 0 ? "approved_background" : item.developer_approval_status != 4 && item.approved_status == 1 && item.admin_approval_status == 1 ? "approved_background" : item.developer_approval_status == 4 && item.approved_status == 1 && item.admin_approval_status == 1 ? "published_background" : item.developer_approval_status == 4 && item.approved_status == 1 && item.admin_approval_status == 2 ? "reject_background" : item.developer_approval_status == 4 && item.approved_status == 1 && item.admin_approval_status == 0 ? "approved_background" : item.admin_approval_status == 2 ? "reject_background" : "reject_background"}`} >
															{item.approved_status_msg}
														</div>
													</td>

													<td>{item.developer_approval_status == 4 && item.approved_status == 1 && item.admin_approval_status == 1 ? "Published" : "Pending"}</td>
													<td>
														<div className="d-flex">
															{
																(
																	< OverlayTrigger
																		delay={{ hide: 450, show: 300 }}
																		overlay={(props) => (
																			<Tooltip {...props}>Edit</Tooltip>
																		)}
																		placement="top"
																	>
																		<Link
																			to={{
																				pathname: '/assign-developer-edit',
																				state: {item, filterData: { category, board, type, classRoom, subject, chapter, contentStatus, approveStatus }}
																				// filterData: { category, board, type, classRoom, subject, chapter, contentStatus, approveStatus }
																			}}
																			className="btn btn-warning shadow btn-xs sharp me-1"
																		>
																			<i className="fas fa-pencil-alt"></i>
																		</Link>
																	</OverlayTrigger>
																)
															}

															{
																(
																	< OverlayTrigger
																		delay={{ hide: 450, show: 300 }}
																		overlay={(props) => (
																			<Tooltip {...props}>Delete</Tooltip>
																		)}
																		placement="top"
																	>
																		<Button
																			className={`btn btn-danger shadow btn-xs sharp me-1 ${item.developer_approval_status > 2 || item.developer_approval_status == 1 && item.approved_status == 1 ? "item_disable" : null}`}
																			onClick={() => onDeleteHandler(item.id)}
																		>
																			<i className="fa fa-trash"></i>
																		</Button>
																	</OverlayTrigger>
																)
															}

															{
																< OverlayTrigger
																	ref={rejectOverlayRef}
																	delay={{ hide: rejectToolTipsHide, show: 300 }}
																	overlay={(props) => (
																		<Tooltip {...props}>Reject List</Tooltip>
																	)}
																	placement="top"
																>
																	<button
																		className={`btn btn-secondary shadow btn-xs sharp me-1 ${item.total_rejected_record == 0 ? "item_disable" : null}`}
																		onClick={() => rejectListHandeler(item.id)}
																	>
																		<i className="fa fa-window-restore"></i>
																	</button>
																</OverlayTrigger>
															}

															{
																< OverlayTrigger
																	delay={{ hide: 450, show: 300 }}
																	overlay={(props) => (
																		<Tooltip {...props}>History List</Tooltip>
																	)}
																	placement="top"
																>
																	<button
																		className={`btn btn-secondary history_background shadow btn-xs sharp me-1`}
																		onClick={() => HistoryListHandeler(item)}
																	>
																		{/* <i className="fa-solid fa-sheet-plastic" style={{ color: '#2c1f51' }}></i> */}
																		<BsJournalMedical size={15} />
																	</button>
																</OverlayTrigger>
															}
														</div>
													</td>
													{/* <BsJournalMedical size={25}
                                                    onClick={() => openContentModal(item.content_data)}
                                                    className='cursorPointer' /> */}

												</tr>
											})
										}
									</tbody>
									: <tbody>
										<td colSpan={12}>
											<div className='no_data'>
												<strong >No data found</strong>
											</div>
										</td>
									</tbody>}
							</Table>
							{/* <div className="pagination-container">
								<div className="page-result">
									<p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.elibraryList.length)} of {props.totalRecord}</p>
								</div>
								<Pagination
									//size={size}
									className="mt-2 pagination pagination-circle">
									<li className="page-item page-indicator">
										<Button className="page-link" onClick={onDecrementHandler} disabled={active <= 1 ? true : false}>
											<i className="la la-angle-left" />
										</Button>
									</li>
									<Pagination.Item
										active={active}
									>
										{active}
									</Pagination.Item>

									<li className="page-item page-indicator">
										<Button className="page-link" onClick={onIncrementHandler} disabled={active == props.totalPage ? true : false}>
											<i className="la la-angle-right" />
										</Button>
									</li>
								</Pagination>
							</div> */}
							<div className="pagination-container">
								<div className="page-result">
									<p>Showing: {(active - 1) * (pageRangeDisplayed) + 1} - {(pageRangeDisplayed * active) - (10 - props.assignList.length)} of {props.contentListTotalRecord}</p>
								</div>
								{props.contentListTotalRecord > pageRangeDisplayed ?
									<ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i className="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i className="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={pageHandler}
										pageRangeDisplayed={3}
										pageCount={props.contentListTotalPage}
										forcePage={active - 1}
									/>
									: null
								}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal className="fade" show={rejectListModal}>
				<Modal.Header>
					<Modal.Title className="modal_title">Rejected Reason List</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='reject_list_parent_container'>
						{props.getRejectedListIdWise.map((item, index) => {
							return (< div className="col-lg-12">
								<div className="reject_list_container">
									<div className="name_icon">
										<div className='name_text'>{item.user_type.split('')[0]}</div>
									</div>
									<div className="reject_details_container">
										<div className="reject_name_container">{item.user_type}/{item.name}</div>
										<div className="reject_reason_container">{item.reason}</div>
									</div>
								</div>
							</div>
							)
						})
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="modal_footer_right_container show_right">
						<Button
							variant="warning text-center m-1"
							// onClick={() => setRejectListModal(false)}
							onClick={() => closeRejectModal()}

						>
							OK
						</Button>
					</div>
				</Modal.Footer>
			</Modal>

		</Fragment >
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.elibrary.showLoading,
		elibraryList: state.elibrary.list,
		categoryList: state.category.list,
		categoryLoading: state.category.showLoading,
		branchList: state.branch.branchList,
		branchLoading: state.branch.showLoading,
		boardLoading: state.board.showLoading,
		boardList: state.board.list,
		totalPage: state.elibrary.totalPage,
		totalRecord: state.elibrary.totalRecord,
		assignList: state.assign.eLibraryContentlist,
		typeList: state.type.list,
		classList: state.class.list,
		subjectList: state.subject.nonGroupSubjectList,
		chapterList: state.chapter.typelist,
		showLoadingSubject: state.subject.showLoading,
		showLoadingChapter: state.chapter.showLoading,
		getRejectedListIdWise: state.assign.getRejectedListIdWise,
		contentListTotalRecord: state.assign.contentListTotalRecord,
		contentListTotalPage: state.assign.contentListTotalPage,
		filterDataList: state.assign.filterDataList,
	};
};

export default connect(mapStateToProps)(AssignElibraryList);