import React, { useEffect, useState } from 'react';

export default function PreviewCard(props) {

    useEffect(() => {
        if (typeof window?.MathJax !== "undefined") {
            window.MathJax.typesetClear()
            window.MathJax.typeset()
        }
    }, []);

    return (
        <>
            <div className="assessment_container">
                <div className="assessment_container_inside">
                    <div className="assessment_container_top">
                        <div className="assessment_question_number"><h3>Question No. : {" "}{props.data?.question_no}</h3></div>
                    </div>
                    <div className="assessment_container_bottom">
                        <div className="assessment_container_bottom_left">
                            <div className="question_details helper-text flex-row d-flex wrap_text">
                                <div className='assessLeftPanelWidth'>
                                    Question :
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: props.data?.question }}></div>

                            </div>
                            <div className='bottom_border'> </div>
                            <div className="option_container helper-text d-flex flex-row">
                                <div className='assessLeftPanelWidth'>
                                    Option :
                                </div>
                                <div className="row flex-1">
                                <div className="col-md-12 display_flex wrap_text"><p className='pe-1'>(A)</p><div className="ml7" dangerouslySetInnerHTML={{ __html: props.data?.option_a }}></div></div>
                                <div className="col-md-12 display_flex wrap_text"><p className='pe-1'>(B)</p><div className="ml7" dangerouslySetInnerHTML={{ __html: props.data?.option_b }}></div></div>
                                <div className="col-md-12 display_flex wrap_text"><p className='pe-1'>(C)</p><div className="ml7" dangerouslySetInnerHTML={{ __html: props.data?.option_c }}></div></div>
                                <div className="col-md-12 display_flex wrap_text"><p className='pe-1'>(D)</p><div className="ml7" dangerouslySetInnerHTML={{ __html: props.data?.option_d }}></div></div>
                                </div>
                            </div>
                            <div className='bottom_border'> </div>
                            <div className="answer_details helper-text same_area mt-2">
                                <div className='assessLeftPanelWidth'>
                                Answer :
                                </div>
                                <div className='flex-1'>
                                    <div className='flex-1'>{props.data?.answer}</div>
                                </div>
                            </div>

                            <div className='bottom_border mt-2'> </div>
                            <div className="assessment_container_bottom_right helper-text same_area d-flex flex-row mt-2 assessment_reasons">
                                <div className='assessLeftPanelWidth '>
                                    Reason :
                                </div>
                                <div className='wrap_text'><div key={props.questionNo} dangerouslySetInnerHTML={{ __html: props.data?.reason }}></div></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}