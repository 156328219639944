import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Modal,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import GlobalConfigs from "../../../configs/GlobalConfigs";

import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { addAssignContentData, updateContentAssignData, getAddedDocumentDetailsData, acceptRejectUploadedContentData, getAddedDocumentDetailsAction, reRenderingContentListAction, assignToValidatorElibraryData, assignToLanguageValidatorElibraryDetailsData, assignToDesignerElibrarysData } from '../../../store/actions/AssignContentAction';
import { loadingUserAction, getUserTypeData, getDeveloperData } from '../../../store/actions/UserAction';

import {
    getContentValidatorData,
    removeContentValidatorDetails,
    updateContentValidatorStatus,
} from "../../../store/actions/ContentValidatorAction";

import { getLanguageValidatorData } from '../../../store/actions/ContentLanguageValidatorAction';
import { getContentDesignerData } from '../../../store/actions/ContentDesignerAction';

import AWS from 'aws-sdk';

const AssignDeveloper = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const calendarRef = useRef();
    const contentValidatorCalendarRef = useRef();
    const languageValidatorCalendarRef = useRef();
    const designerCalendarRef = useRef();
    const { state } = useLocation(props.documentDetails);
    const fileRef = useRef();

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    });

    let errorsObj = { requestType: '', category: '', board: '', branch: '', chapter: '', classroom: '', developer: '', examType: '', question: '', payment: '', date: '', subject: '', file: '', details: '', contentValidatorPayment: '', contentValidator: '', contentValidatorSubmissionDate: '', languageValidatorSubmissionDate: '', languageValidator: '', languageValidatorPayment: '', designerSubmissionDate: '', designer: '', designerPayment: '', };

    const [errors, setErrors] = useState(errorsObj);

    const [requestType, setRequestType] = useState('E');
    const [classroom, setClassroom] = useState('');
    const [classroomCode, setClassroomCode] = useState('');
    const [branch, setBranch] = useState('');
    const [chapter, setChapter] = useState('');
    const [chapterName, setChapterName] = useState('');
    const [developer, setDeveloper] = useState('');
    const [developerName, setDeveloperName] = useState('');
    const [category, setCategory] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [board, setBoard] = useState('');
    const [boardName, setBoardName] = useState('');
    const [examType, setExamType] = useState('');
    const [examTypeName, setExamTypeName] = useState('');
    const [payment, setPayment] = useState('');
    const [submissionDate, setSubmissionDate] = useState(new Date());
    const [subject, setsubject] = useState('');
    const [subjectName, setsubjectName] = useState('');

    const [file, setFile] = useState(null);
    const [details, setDetails] = useState("");
    const [text, setText] = useState('');

    const [contentValidatorSubmissionDate, setContentValidatorSubmissionDate] = useState('');
    const [contentValidator, setContentValidator] = useState('');
    const [contentValidatorPayment, setContentValidatorPayment] = useState('');
    /* languageValidator */
    const [languageValidatorSubmissionDate, setLanguageValidatorSubmissionDate] = useState();
    const [languageValidator, setLanguageValidator] = useState('');
    const [languageValidatorPayment, setLanguageValidatorPayment] = useState('');
    /* designer */
    const [designerSubmissionDate, setDesignerSubmissionDate] = useState('');
    const [designer, setdesigner] = useState('');
    const [designerPayment, setDesignerPayment] = useState('');

    const [content, setContent] = useState('');
    const [filterData, setFilterData] = useState('');


    const documentDetails = useSelector((state) => state.assign.documentDetails);
    const reRenderingContentList = useSelector((state) => state.assign.reRenderingContentList);

    const [conceptMapData, setConceptMapData] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setConceptMapData([])
        setShow(false);
    }
    const handleShow = (data) => {
        setConceptMapData(JSON.parse(data))
        setShow(true)
    };


    useEffect(() => {
        // console.log("props-------", props.location.state)
        dispatch(getDeveloperData(0, '', '', '', props.history));
        dispatch(getContentValidatorData(0, '', '', '', props.history));
        dispatch(getLanguageValidatorData(0, '', '', '', props.history));
        dispatch(getContentDesignerData(0, '', '', '', props.history));
        dispatch(getAddedDocumentDetailsData(state.item.id, props.history))

        if (props.documentDetails == '') {
            dispatch(globalLoadingAction(true));
        } else {
            dispatch(globalLoadingAction(false));
        }

        setFilterData(props.location.state.filterData)
        return () => {
            dispatch(getAddedDocumentDetailsAction([]))
        };
    }, []);

    useEffect(() => {
        // console.log("contentValidatorSubmissionDate---", contentValidatorSubmissionDate)
        // console.log("state---------------123", state.item)
        if (props.documentDetails != '') {
            setDeveloper(props.documentDetails.assigned_developer)
            setSubmissionDate(new Date(props.documentDetails.date_of_submission))
            setPayment(props.documentDetails.developer_payment)
            setDetails(props.documentDetails.content_data)
            if (props.documentDetails.developer_approval_status == 2 || props.documentDetails.developer_approval_status == 3 || props.documentDetails.developer_approval_status == 4) {
                setContentValidator(props.documentDetails.assigned_validator)
                setContentValidatorSubmissionDate(new Date(props.documentDetails.date_of_submission_validator));
                onContentValidatorPaymentHandler(props.documentDetails.validator_payment)
            }
            if (props.documentDetails.developer_approval_status == 3 || props.documentDetails.developer_approval_status == 4) {
                setLanguageValidator(props.documentDetails.assigned_language_validator)
                setLanguageValidatorSubmissionDate(new Date(props.documentDetails.date_of_submission_language_validator));
                onLanguageValidatorPaymentHandler(props.documentDetails.language_validator_payment)
            }

            // if (props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 1 || props.documentDetails.developer_approval_status == 4 ) {
            if (props.documentDetails.developer_approval_status == 4) {
                setdesigner(props.documentDetails.assigned_designer)
                setDesignerSubmissionDate(new Date(props.documentDetails.date_of_submission_designer));
                ondesignerPaymentHandler(props.documentDetails.designer_payment)
            }
        }
    }, [props.documentDetails]);

    useEffect(() => {
        if (reRenderingContentList == 1) {
            dispatch(getAddedDocumentDetailsData(state.item.id, props.history))
            dispatch(reRenderingContentListAction(0))
        }
    }, [reRenderingContentList]);

    /* pdf file download from as3 */
    const handleDownloaConceptMapFile = () => {
        const s3 = new AWS.S3();
        let splitUrl = props.documentDetails.concept_map.split('/');
        let fileName = splitUrl[splitUrl.length - 1]
        // console.log("FileName----", fileName)

        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            //   Key: `templates/${template}`,
            Key: `elibrary/${fileName}`,
        };

        function downloadBlob(blob, name = `${fileName}`) {
            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            const blobUrl = URL.createObjectURL(blob);
            // Create a link element
            const link = document.createElement('a');
            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = name;
            // Append link to the body
            document.body.appendChild(link);
            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            // Remove link from body
            document.body.removeChild(link);
        }

        s3.getObject(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                let pdfBlob = new Blob([data.Body], {
                    // type: 'application/pdf',
                    type: data.ContentType,
                });
                downloadBlob(pdfBlob, `${fileName}`);
            }
        });

    }

    /* download pdf file in local system */
    const handleDownloaWriteupFile = () => {
        const s3 = new AWS.S3();
        let splitUrl = props.documentDetails.write_up_pdf.split('/');
        let fileName = splitUrl[splitUrl.length - 1]

        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            //   Key: `templates/${template}`,
            Key: `elibrary/${fileName}`,
        };

        function downloadBlob(blob, name = `${fileName}`) {
            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            const blobUrl = URL.createObjectURL(blob);
            // Create a link element
            const link = document.createElement('a');
            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = name;
            // Append link to the body
            document.body.appendChild(link);
            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );
            // Remove link from body
            document.body.removeChild(link);
        }

        s3.getObject(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                let pdfBlob = new Blob([data.Body], {
                    type: data.ContentType,
                });
                downloadBlob(pdfBlob, `${fileName}`);
            }
        });
    }

    /* form submit */
    function onSubmit(e) {
        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };
        /* admin_approval_status-- 0 <--2.approved_status--> 2 <--3.developer_approval_status--> 1 <--4.status--> 0 */
        if (props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 0 || props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.status == 1 || props.documentDetails.admin_approval_status == 0 && props.documentDetails.approved_status == 2 && props.documentDetails.developer_approval_status == 1 && props.documentDetails.status == 0) {

            if (submissionDate === '') {
                errorObj.date = 'Submission date is Required';
                error = true;
            }
            if (payment === '') {
                errorObj.payment = 'Amount is Required';
                error = true;
            }
            if (developer === '') {
                errorObj.developer = 'Developer is Required';
                error = true;
            }
            setErrors(errorObj);
            if (error) {
                return;
            }

            dispatch(globalLoadingAction(true));
            dispatch(updateContentAssignData(state.item.developer_approval_status, payment, developer, moment(submissionDate).format('YYYY-MM-DD'), state.item.id, props.history))
        } else if (props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 0 && props.documentDetails.admin_approval_status == 0 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 2) {
            console.log("contentValidator Submit Form ----11--", contentValidatorSubmissionDate) //contentValidatorSubmissionDate, contentValidatorPayment
            if (contentValidator === '' || contentValidator === 0) {
                errorObj.contentValidator = 'Content Validator is Required';
                error = true;
            }
            if (contentValidatorPayment === '' || contentValidatorPayment === 0) {
                errorObj.contentValidatorPayment = 'Amount is Required';
                error = true;
            }
            if (contentValidatorSubmissionDate === undefined || contentValidatorSubmissionDate === '') {
                errorObj.contentValidatorSubmissionDate = 'Submission date is Required';
                error = true;
            }
            setErrors(errorObj);
            if (error) {
                return;
            }

            dispatch(globalLoadingAction(true));
            dispatch(assignToValidatorElibraryData(state.item.id, moment(contentValidatorSubmissionDate).format('YYYY-MM-DD'), contentValidatorPayment, contentValidator, props.history))
        } else if (props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 0 && props.documentDetails.admin_approval_status == 0 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 2) {
            // console.log("contentValidator Submit Form ----11--", contentValidatorSubmissionDate  ) //contentValidatorSubmissionDate, contentValidatorPayment
            if (languageValidator === '' || languageValidator === 0) {
                errorObj.languageValidator = 'Language Validator is Required';
                error = true;
            }
            if (languageValidatorPayment === '' || languageValidatorPayment === 0) {
                errorObj.languageValidatorPayment = 'Amount is Required';
                error = true;
            }
            if (languageValidatorSubmissionDate === undefined || languageValidatorSubmissionDate === '') {
                errorObj.languageValidatorSubmissionDate = 'Submission date is Required';
                error = true;
            }
            setErrors(errorObj);
            if (error) {
                return;
            }
            // console.log("errorObj--", errorObj)

            // console.log("contentValidator Submit Form Data ------", languageValidator, languageValidatorPayment, languageValidatorSubmissionDate)


            dispatch(globalLoadingAction(true));
            dispatch(assignToLanguageValidatorElibraryDetailsData(state.item.id, moment(languageValidatorSubmissionDate).format('YYYY-MM-DD'), languageValidatorPayment, languageValidator, props.history))
        } else if (props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 4 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 2 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.status == 1) {
            console.log("contentValidator Submit Form ----345--", designer, designerPayment, designerSubmissionDate) //contentValidatorSubmissionDate, contentValidatorPayment
            if (designer === '' || designer === 0) {
                errorObj.designer = 'Designer is Required';
                error = true;
            }
            if (designerPayment === '' || designerPayment === 0) {
                errorObj.designerPayment = 'Amount is Required';
                error = true;
            }
            if (designerSubmissionDate === undefined || designerSubmissionDate === '') {
                errorObj.designerSubmissionDate = 'Submission date is Required';
                error = true;
            }
            setErrors(errorObj);
            if (error) {
                return;
            }
            // console.log("errorObj--", errorObj)

            console.log("contentValidator Submit Form Data ------", designer, designerPayment, designerSubmissionDate)


            dispatch(globalLoadingAction(true));
            dispatch(assignToDesignerElibrarysData(state.item.id, moment(designerSubmissionDate).format('YYYY-MM-DD'), designerPayment, designer, props.history))
        }
    }

    /* on change payment */
    const onPaymentHandler = (val) => {
        const regex = /^[0-9\b]+$/;
        if (val === '' || regex.test(val)) {
            setPayment(val);
        }
    }

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    /* accept data handler */
    const acceptHandeler = (e) => {
        dispatch(globalLoadingAction(true));
        dispatch(acceptRejectUploadedContentData(state.item.id, e, props.history));
        // console.log("You press Accept button", e)
    }

    /* reject data handler */
    const rejectHandeler = (e) => {
        dispatch(globalLoadingAction(true));
        dispatch(acceptRejectUploadedContentData(state.item.id, e, props.history));
        // console.log("You press Reject button", e)
    }

    /* content validator payment handler */
    const onContentValidatorPaymentHandler = (val) => {
        const regex = /^[0-9\b]+$/;
        if (val === '' || regex.test(val)) {
            setContentValidatorPayment(val);
        }
    }

    /* Language Validator Payment Handler */
    const onLanguageValidatorPaymentHandler = (val) => {
        const regex = /^[0-9\b]+$/;
        if (val === '' || regex.test(val)) {
            setLanguageValidatorPayment(val);
        }
    }

    /* Designer Payment Handler */
    const ondesignerPaymentHandler = (val) => {
        const regex = /^[0-9\b]+$/;
        if (val === '' || regex.test(val)) {
            setDesignerPayment(val);
        }
    }

    /* image upload */
    const onImageHandler = (blobInfo) => {
        return new Promise((success, reject) => {
            const formData = new FormData();
            formData.append("content_image", blobInfo.blob());
            /* axios({
                url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_IMAGE_API,
                method: "POST",
                headers: {
                    ContentType: "multipart/form-data",
                },
                data: formData,
            }).then((res) => {
                success(res.data.imagepath);
            }).then((json) => {
                console.log(json);
            }); */
        });
    }

    /* navigation go back with state value */
    const onGoBackHandler = () => {
        history.push({
            pathname: '/assign-elibrary-list',
            state: filterData
        });
        // history.goBack();
    }

    /* date change handler */
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }


    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">

                        {/* {console.log("documentDetails-------", props.documentDetails)} */}
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="col-xl-3">
                                    <div className="back-arrow-container">
                                        <Link
                                            onClick={onGoBackHandler}>
                                            <i class="fa-solid fa-arrow-left"></i>
                                        </Link>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3"></div>
                                    <div className="col-xl-6">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>
                                                    <div className="form-group mb-3 two_column">
                                                        <div className="mb-2 one_column">
                                                            <h4><strong>Category :</strong> {props.documentDetails.category}</h4>
                                                        </div>
                                                        <div className="mb-2 one_column">
                                                            <h4><strong>Chapter :</strong> {props.documentDetails.chapter_name}</h4>
                                                        </div>
                                                    </div>
                                                    {props.documentDetails.exam_category_id == 1 ?
                                                        <div className="form-group mb-3 two_column">
                                                            <div className="mb-2 one_column">
                                                                <h4><strong>Board :</strong> {props.documentDetails.board_name}</h4>
                                                            </div>
                                                            <div className="mb-2 one_column">
                                                                <h4><strong>Class :</strong> {props.documentDetails.class_code}</h4>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="form-group mb-3 two_column">
                                                            <div className="mb-2 one_column">
                                                                <h4><strong>Exam Type :</strong> {props.documentDetails.type_name}</h4>
                                                            </div>
                                                            <div className="mb-2 one_column">
                                                                <h4><strong>Class :</strong> {props.documentDetails.class_code}</h4>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="form-group mb-3 two_column">
                                                        <div className="mb-2 one_column">
                                                            <h4><strong>Subject :</strong> {props.documentDetails.subject_name}</h4>
                                                        </div>
                                                        <div className="mb-2 one_column">
                                                            <h4><strong>Type :</strong> {`Live`}</h4>
                                                        </div>
                                                    </div>

                                                    {/* Developer */}
                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Content Developer<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">

                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>

                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={developer}
                                                                onChange={(e) => setDeveloper(e.target.value)}
                                                                disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? true : props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 ? true : props.documentDetails.developer_approval_status == 2 ? true : props.documentDetails.developer_approval_status == 3 ? true : props.documentDetails.developer_approval_status == 4 ? true : false}
                                                            >
                                                                <option value="">Select Developer</option>
                                                                {
                                                                    props.developerlist.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.developer && <div className="text-danger fs-12">{errors.developer}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Time Limit<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="date-control-view">
                                                            <DatePicker
                                                                ref={calendarRef}
                                                                selected={submissionDate}
                                                                placeholderText="Enter the submission date"
                                                                onChange={(date) => setSubmissionDate(date)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                // minDate={new Date()}
                                                                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                                disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? true : props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 ? true : props.documentDetails.developer_approval_status == 2 ? true : props.documentDetails.developer_approval_status == 3 ? true : props.documentDetails.developer_approval_status == 4 ? true : false}
                                                                onChangeRaw={handleDateChangeRaw}
                                                            />
                                                            <button type='button' className="picker-btn"
                                                                onClick={() => calendarRef.current.setOpen(true)}
                                                            >
                                                                <i class="fa-solid fa-calendar-days"></i>
                                                            </button>
                                                        </div>
                                                        {errors.date && <div className="text-danger fs-12">{errors.date}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Amount<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input
                                                            className="form-control placeHolder"
                                                            placeholder="Enter the amount"
                                                            value={payment}
                                                            onChange={(e) => onPaymentHandler(e.target.value)}
                                                            maxLength="5"
                                                            disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? true : props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 ? true : props.documentDetails.developer_approval_status == 2 ? true : props.documentDetails.developer_approval_status == 3 ? true : props.documentDetails.developer_approval_status == 4 ? true : false}
                                                        />
                                                        {errors.payment && <div className="text-danger fs-12">{errors.payment}</div>}
                                                    </div>
                                                    {/* End Developer */}

                                                    {/* Content Validator */}
                                                    <div className={`form-group mb-3 ${props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? "" : props.documentDetails.developer_approval_status == 2 ? "" : props.documentDetails.developer_approval_status == 3 ? "" : props.documentDetails.developer_approval_status == 4 ? "" : "item_disable"}`}>
                                                        <label className="mb-2">
                                                            <strong>Content Validator<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">

                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>

                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={contentValidator}
                                                                onChange={(e) => setContentValidator(e.target.value)}
                                                                disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.admin_approval_status == 2 ? false : props.documentDetails.developer_approval_status == 3 ? true : props.documentDetails.developer_approval_status == 4 ? true : true}

                                                            >
                                                                <option value="">Select Content Validator</option>
                                                                {
                                                                    props.contentvalidatorList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.contentValidator && <div className="text-danger fs-12">{errors.contentValidator}</div>}
                                                        </div>
                                                    </div>

                                                    {props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 || props.documentDetails.developer_approval_status == 2 || props.documentDetails.developer_approval_status == 3 || props.documentDetails.developer_approval_status == 4 ?
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Time Limit<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <div className="date-control-view">
                                                                    <DatePicker
                                                                        ref={contentValidatorCalendarRef}
                                                                        selected={contentValidatorSubmissionDate}
                                                                        placeholderText="Enter the submission date"
                                                                        onChange={(date) => setContentValidatorSubmissionDate(date)}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        // minDate={new Date()}
                                                                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                                        disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.admin_approval_status == 2 ? false : props.documentDetails.developer_approval_status == 3 ? true : true}
                                                                        onChangeRaw={handleDateChangeRaw}
                                                                    />
                                                                    <button type='button' className="picker-btn"
                                                                        onClick={() => contentValidatorCalendarRef.current.setOpen(true)}
                                                                    >
                                                                        <i class="fa-solid fa-calendar-days"></i>
                                                                    </button>
                                                                </div>
                                                                {errors.contentValidatorSubmissionDate && <div className="text-danger fs-12">{errors.contentValidatorSubmissionDate}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Amount<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <input
                                                                    className="form-control placeHolder"
                                                                    placeholder="Enter the amount"
                                                                    value={contentValidatorPayment}
                                                                    onChange={(e) => onContentValidatorPaymentHandler(e.target.value)}
                                                                    maxLength="5"
                                                                    disabled={props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 2 && props.documentDetails.admin_approval_status == 2 ? false : props.documentDetails.developer_approval_status == 3 ? true : true}
                                                                />
                                                                {errors.contentValidatorPayment && <div className="text-danger fs-12">{errors.contentValidatorPayment}</div>}
                                                            </div>
                                                        </>
                                                        : null}
                                                    {/* End Content Validator */}

                                                    {/* Language Validator */}
                                                    {/* <div className="form-group mb-3 item_disable"> */}
                                                    <div className={`form-group mb-3 ${props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status != 1 ? "" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 ? "item_disable" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 0 ? "item_disable" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 ? "" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.approved_status != 0 ? "" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 0 && props.documentDetails.approved_status == 0 && props.documentDetails.status == 0 ? "" : props.documentDetails.developer_approval_status == 4 ? "" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 2 ? "" : "item_disable"}`}>
                                                        <label className="mb-2">
                                                            <strong>Language Validator<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">

                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>

                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={languageValidator}
                                                                onChange={(e) => setLanguageValidator(e.target.value)}
                                                                disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.status == 1 ? false : true}
                                                            >
                                                                <option value="">Select Language Validator</option>
                                                                {
                                                                    props.languagevalidatorList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.languageValidator && <div className="text-danger fs-12">{errors.languageValidator}</div>}
                                                        </div>
                                                    </div>

                                                    {props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status != 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 3 || props.documentDetails.developer_approval_status == 4 ?
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Time Limit<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <div className="date-control-view">
                                                                    <DatePicker
                                                                        ref={languageValidatorCalendarRef}
                                                                        selected={languageValidatorSubmissionDate}
                                                                        placeholderText="Enter the submission date"
                                                                        onChange={(date) => setLanguageValidatorSubmissionDate(date)}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        // minDate={new Date()}
                                                                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                                        disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.status == 1 ? false : true}
                                                                        onChangeRaw={handleDateChangeRaw}

                                                                    />
                                                                    <button type='button' className="picker-btn"
                                                                        onClick={() => contentValidatorCalendarRef.current.setOpen(true)}
                                                                    >
                                                                        <i class="fa-solid fa-calendar-days"></i>
                                                                    </button>
                                                                </div>
                                                                {errors.languageValidatorSubmissionDate && <div className="text-danger fs-12">{errors.languageValidatorSubmissionDate}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Amount<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <input
                                                                    className="form-control placeHolder"
                                                                    placeholder="Enter the amount"
                                                                    value={languageValidatorPayment}
                                                                    onChange={(e) => onLanguageValidatorPaymentHandler(e.target.value)}
                                                                    maxLength="5"
                                                                    disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status != 1 ? false : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.status == 1 ? false : true}
                                                                />
                                                                {errors.languageValidatorPayment && <div className="text-danger fs-12">{errors.languageValidatorPayment}</div>}
                                                            </div>
                                                        </>
                                                        : null}
                                                    {/* End Language Validator */}

                                                    {/* Designer */}
                                                    {/* <div className="form-group mb-3 item_disable"> */}
                                                    <div className={`form-group mb-3 ${props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status != 1 ? "" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 ? "item_disable" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 0 ? "item_disable" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 0 ? "item_disable" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 1 ? "" : props.documentDetails.developer_approval_status == 4 ? "" : "item_disable"}`}>
                                                        <label className="mb-2">
                                                            <strong>Designer<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={designer}
                                                                onChange={(e) => setdesigner(e.target.value)}
                                                                disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status == 1 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status != 2 ? true : false}
                                                            >
                                                                <option value="">Select Designer</option>
                                                                {
                                                                    props.contentdesignerList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.designer && <div className="text-danger fs-12">{errors.designer}</div>}
                                                        </div>
                                                    </div>
                                                    {/* {props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status != 1 || props.documentDetails.developer_approval_status != 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status != 0 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0  || props.documentDetails.developer_approval_status == 4 ? */}
                                                    {props.documentDetails.developer_approval_status == 4 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 1 && props.documentDetails.status == 1 ?
                                                        <>
                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Time Limit<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <div className="date-control-view">
                                                                    <DatePicker
                                                                        ref={designerCalendarRef}
                                                                        selected={designerSubmissionDate}
                                                                        placeholderText="Enter the submission date"
                                                                        onChange={(date) => setDesignerSubmissionDate(date)}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        // minDate={new Date()}
                                                                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                                                        disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status == 1 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status != 2 ? true : false}
                                                                        onChangeRaw={handleDateChangeRaw}
                                                                    />
                                                                    <button type='button' className="picker-btn"
                                                                        onClick={() => contentValidatorCalendarRef.current.setOpen(true)}
                                                                    >
                                                                        <i class="fa-solid fa-calendar-days"></i>
                                                                    </button>
                                                                </div>
                                                                {errors.designerSubmissionDate && <div className="text-danger fs-12">{errors.designerSubmissionDate}</div>}
                                                            </div>

                                                            <div className="form-group mb-3">
                                                                <label className="mb-2">
                                                                    <strong>Amount<span className="text-danger">*</span></strong>
                                                                </label>
                                                                <input
                                                                    className="form-control placeHolder"
                                                                    placeholder="Enter the amount"
                                                                    value={designerPayment}
                                                                    onChange={(e) => ondesignerPaymentHandler(e.target.value)}
                                                                    maxLength="5"
                                                                    disabled={props.documentDetails.developer_approval_status == 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status != 1 && props.documentDetails.approved_status == 1 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status != 2 ? true : false}
                                                                />
                                                                {errors.designerPayment && <div className="text-danger fs-12">{errors.designerPayment}</div>}
                                                            </div>
                                                        </>
                                                        : null}

                                                    {/* End Designer */}

                                                    <div className={`mb-3 position-relative ${props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.status == 1 ? null : "item_disable"}`}>
                                                        <label
                                                            htmlFor="formFileDisabled"
                                                            className="form-label file-form-label"
                                                        >
                                                            Download File
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="icon_right cursorPointer" onClick={() => handleDownloaConceptMapFile()}><i class="bi-download" size="68px"></i></div>
                                                        <div
                                                            ref={fileRef}
                                                            className="form-control"
                                                            type="file"
                                                            id="formFileDisabled"
                                                            onClick={() => handleDownloaConceptMapFile()}
                                                        >
                                                            <strong>Concept Map File</strong>
                                                        </div>


                                                        {/* {errors.file && (
                                                            <div className="text-danger fs-12 mt-1">
                                                                {errors.file}
                                                            </div>
                                                        )} */}
                                                    </div>

                                                    <div className={`mb-3 position-relative ${props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.status == 1 ? null : "item_disable"}`}>
                                                        <label
                                                            htmlFor="formFileDisabled"
                                                            className="form-label file-form-label"
                                                        >
                                                            Download File
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="icon_right cursorPointer" onClick={() => handleDownloaWriteupFile()}><i class="bi-download" size="68px"></i></div>
                                                        <div
                                                            ref={fileRef}
                                                            className="form-control"
                                                            type="file"
                                                            id="formFileDisabled"
                                                            onClick={() => handleDownloaWriteupFile()}
                                                        >
                                                            <strong>Writeup File</strong>
                                                        </div>
                                                        {/* {errors.file && (
                                                            <div className="text-danger fs-12 mt-1">
                                                                {errors.file}
                                                            </div>
                                                        )} */}
                                                    </div>


                                                    {
                                                        props.documentDetails.concept_map_data ? (

                                                            <button type="button" className="btn-s btn btn-success mb-2" onClick={() => { handleShow(props.documentDetails.concept_map_data) }}>Concept Map</button>

                                                        ) : null
                                                    }


                                                    {/* ${props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 ? null : "item_disable"} */}
                                                    <div className={`form-group mb-3`}>
                                                        <label className="mb-2">
                                                            <strong>Content<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="ck-editer-view">

                                                            <Editor
                                                                apiKey={GlobalConfigs.TINY_API_KEY}
                                                                value={props.documentDetails.content_data}
                                                                onInit={(evt, editor) => {
                                                                    //setText(editor.getContent({ format: 'text' }));
                                                                }}
                                                                onEditorChange={(newValue, editor) => {
                                                                    setContent(newValue);
                                                                    //setText(editor.getContent({ format: 'text' }));
                                                                }}
                                                                //initialValue="<p>This is the initial content of the editor.</p>"
                                                                init={{
                                                                    height: 400,
                                                                    menubar: true,
                                                                    image_advtab: true,
                                                                    external_plugins: {
                                                                        'tiny_mce_wiris': `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                                                    },
                                                                    images_upload_handler: onImageHandler,
                                                                    plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'pagebreak', 'help', 'wordcount'],
                                                                    toolbar: 'undo redo | formatselect | ' +
                                                                        'bold italic backcolor | alignleft aligncenter ' +
                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                        'removeformat |pagebreak| help | image |' + 'tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                                                                    setup: editor => {
                                                                        editor.ui.registry.addButton('pagebreak', {
                                                                            text: 'Page Break',
                                                                            onAction: function (_) {
                                                                                editor.execCommand('mceInsertContent', false, '<p style="page-break-before:always;"></p>');
                                                                            }
                                                                        });
                                                                    },
                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                }}
                                                            />
                                                        </div>
                                                        {errors.details && <div className="text-danger fs-12">{errors.details}</div>}
                                                    </div>
                                                    {/* state.developer_approval_status == 1 && state.approved_status == 1 */}
                                                    {props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 2 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 || props.documentDetails.developer_approval_status == 3 && props.documentDetails.status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.approved_status == 1 || props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 ?
                                                        <div className='accept_reject_button_container'>
                                                            <div className='accept_reject_btn accept_btn_background' onClick={() => acceptHandeler(1)}>Accept</div>
                                                            <div className='accept_reject_btn reject_btn_background' onClick={() => rejectHandeler(2)}>Reject</div>
                                                        </div>
                                                        /* : props.documentDetails.developer_approval_status == 2 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 ?
                                                            <div className='accept_reject_button_container'>
                                                                <div className='accept_reject_btn accept_btn_background' onClick={() => acceptHandeler(1)}>Accept-</div>
                                                                <div className='accept_reject_btn reject_btn_background' onClick={() => rejectHandeler(2)}>Reject-</div>
                                                            </div> */
                                                        :
                                                        null}

                                                    <div className={`text-center mt-4 ${props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 0 ? "item_disable" : props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.status == 1 ? "item_disable" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.status == 1 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.approved_status == 1 ? "item_disable" : props.documentDetails.developer_approval_status == 2 && props.documentDetails.status == 0 && props.documentDetails.admin_approval_status == 0 && props.documentDetails.approved_status == 1 ? "item_disable" : props.documentDetails.developer_approval_status == 3 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status == 0 ? "item_disable" : props.documentDetails.developer_approval_status == 4 && props.documentDetails.approved_status == 1 && props.documentDetails.admin_approval_status != 2 ? "item_disable" : null}`}>


                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Assign
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>
                                                    {/* props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 0 && props.documentDetails.admin_approval_status == 0 */}

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Concept Map</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        Array.isArray(conceptMapData) && conceptMapData.length !== 0 ?

                            (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Topic Heading</th>
                                            <th>Page No</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            conceptMapData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.topic_heading ? item?.topic_heading : 'NA'}</td>
                                                    <td>{item?.page_no ? item?.page_no : 'NA'}</td>

                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                </table>
                            ) : (
                                <h3>No Data Found</h3>
                            )
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.assign.showLoading,
        branchList: state.branch.branchList,
        chapterList: state.chapter.typelist,
        showLoadingChapter: state.chapter.showLoading,
        classList: state.class.list,
        boardList: state.board.list,
        categoryList: state.category.list,
        typeList: state.type.list,
        patternList: state.pattern.list,
        developerlist: state.user.contentDeveloperList,
        subjectList: state.subject.subjectList,
        documentDetails: state.assign.documentDetails,
        contentvalidatorList: state.contentvalidator.list,
        languagevalidatorList: state.languagevalidator.list,
        contentdesignerList: state.contentdesigner.list,
    };
};
export default connect(mapStateToProps)(AssignDeveloper);