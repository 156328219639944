import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addType(exam_category_id, type_name, short_code, full_name, conducted_by, exam_type_image) {

    console.log("addType-----",exam_category_id, type_name, short_code, full_name, conducted_by, exam_type_image )
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        exam_category_id,
        type_name,
        short_code,
        full_name,
        conducted_by,
        exam_type_image
    };

    const formData = new FormData();
    formData.append("exam_category_id", exam_category_id);
    formData.append("type_name", type_name);
    formData.append("short_code", short_code);
    formData.append("full_name", full_name);
    formData.append("conducted_by", conducted_by);
    formData.append("exam_type_image", exam_type_image);

    return axios({
        url: GlobalConfigs.API_URL + Apis.SET_EXAM_TYPE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

export function getType() {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_EXAM_TYPE_API,
        method: "GET",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
    });

}

export function updateType(recid, exam_category_id, type_name, short_code, full_name, conducted_by, exam_type_image) {

    console.log("formData--444--", recid, exam_category_id, type_name, short_code, full_name, conducted_by, exam_type_image)

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        exam_category_id,
        type_name,
        short_code,
        full_name,
        conducted_by,
        exam_type_image
    };

    const formData = new FormData();
    formData.append("recid", recid);
    formData.append("exam_category_id", exam_category_id);
    formData.append("type_name", type_name);
    formData.append("short_code", short_code);
    formData.append("full_name", full_name);
    formData.append("conducted_by", conducted_by);
    formData.append("exam_type_image", exam_type_image);

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_EXAM_TYPE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: formData,
    });

}

export function updateStatusType(recid, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid,
        status
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_STATUS_EXAM_TYPE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeType(recid) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        recid
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_EXAM_TYPE_API,
        method: "DELETE",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

