import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory, } from 'react-router-dom';
import Moment from 'react-moment';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import {
	Row,
	Col,
	Card,
	Table,
	Pagination,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";
import { getSubjects, subjectLoading, removeSubjectDetails, updateStatusSubjectData, getSubjectCategoryListActions, getSubjectBoardListActions, getSsubjectGroupIndividualListActions, getSubjectTypeListActions, getSubjectListPageNumberActions } from '../../../store/actions/SujectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';

const SubjectList = (props) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const { state } = useLocation();

	const [active, setActive] = useState(props?.location.state?.page != '' && props?.location.state?.page != undefined ? props?.location.state?.page : 1);
	const [board, setBoard] = useState(props?.location.state?.board != '' && props?.location.state?.board != undefined ? props?.location.state?.board : 0);
	const [type, setType] = useState(props?.location.state?.type != '' && props?.location.state?.type != undefined ? props?.location.state?.type : 0);
	const [subtype_id, setSubtype_id] = useState(props?.location.state?.sub_type != '' && props?.location.state?.sub_type != undefined ? props?.location.state?.sub_type : 0);
	const [category, setCategory] = useState(parseInt(props?.location.state?.category != '' && props?.location.state != undefined ? props?.location.state?.category : 0));
	const [group, setGroup] = useState(props?.location.state?.group != '' && props?.location.state != undefined ? props?.location.state?.group : '');

	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(loadingToggleAction(true));
		dispatch(getExamCategory(props.history));
		dispatch(loadingAction(true));
		dispatch(getExamType(props.history));
		dispatch(loadingSubTypeAction(true));
		dispatch(getExamSubType(props.history));
		dispatch(loadingBoardAction(true));
		dispatch(getBoardData(props.history));
		dispatch(globalLoadingAction(true));
		// dispatch(getSubjects(active, board, type, subtype_id, category, group, props.history));
	}, []);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getSubjects(active, board, type, subtype_id, category, group, props.history));
	}, [active, board, type, subtype_id, category, group]);


	/* delete confirmation */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				if (props.subjectList.length == 1) {
					dispatch(removeSubjectDetails(i, active - 1, props.history));
					setActive(active - 1)
				} else {
					dispatch(removeSubjectDetails(i, active, props.history));
					dispatch(getSubjects(active, board, type, subtype_id, category, group, props.history));
				}
			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateStatusSubjectData(item, props.history));
	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getSubjects(data.selected + 1, board, type, subtype_id, category, group, props.history));
		dispatch(getSubjectListPageNumberActions(data.selected + 1))
	}

	/* change category */
	const onChangeCategoryHandler = (e) => {
		setCategory(e)
		dispatch(getSubjectCategoryListActions(e))
		setActive(1);
		if (e == 1) {
			setType(0)
			dispatch(getSubjectTypeListActions(0))
		} else if (e == 2) {
			setBoard(0)
			dispatch(getSubjectBoardListActions(0))
		} else if (e == 3) {
			setType(0)
			dispatch(getSubjectTypeListActions(0))
			setBoard(0)
			dispatch(getSubjectBoardListActions(0))
			setGroup('')
		}
	}

	/* change group subject */
	const onChangeGroupHandler = (e) => {
		setGroup(e)
		setActive(1);
		dispatch(getSsubjectGroupIndividualListActions(e))
	}

	/* change board */
	const onChangeBoardHandler = (e) => {
		setBoard(e)
		setActive(1);
		dispatch(getSubjectBoardListActions(e))
		dispatch(getSubjectTypeListActions(0))
	}

	/* change type */
	const onChangeTypeHandler = (e) => {
		setType(e)
		setActive(1);
		dispatch(getSubjectTypeListActions(e))
		dispatch(getSubjectBoardListActions(0))
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 28) {
				setPermission(item);
			}
		});
	};

	 /* reset */
	const resetHandler = () => {
		setBoard(0)
		setCategory('')
		setGroup('')
		setType(0)

		const stateCopy = { ...props?.location.state };
		delete stateCopy.page;
		delete stateCopy.category;
		delete stateCopy.board;
		delete stateCopy.type;
		delete stateCopy.sub_type;
		delete stateCopy.group;
		props.history.replace({ state: stateCopy });

		/* 'page': active, 'category': category, 'board': board, 'type': type, 'sub_type': subtype_id, 'group': group */
	}

	return (
		<Fragment>

			<Row>
				<Col lg={12}>
					<Card>
						{/* {console.log('subjectList', props.subjectList)} */}
						<div className="add-filter-group mt-3">
							<div className="me-2">
								<div className="basic-dropdown">
									<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
									<select
										defaultValue={"option"}
										className="form-control filter-form-control-input"
										value={category}
										onChange={(e) => onChangeCategoryHandler(e.target.value)}
									>
										<option value="">Select Category</option>
										{
											props.categorylist.map((item, index) => {
												return <option key={index} value={item.id}>{item.category}</option>
											})
										}
									</select>
								</div>
							</div>
							{category == 1 ?
								<div className="me-2">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control-input"
											value={board}
											onChange={(e) => onChangeBoardHandler(e.target.value)}
										>
											<option value="">Select Board</option>
											{
												props.boardList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>
								: category == "" || category == 3 ?
									<div className="me-2 content_disable">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												defaultValue={"option"}
												className="form-control filter-form-control-input "
												// value={status}
												// onChange={(e) => onChangeStatusHandler(e.target.value)}
												disabled
											>
												<option value="">Select Board/Type</option>

											</select>
										</div>
									</div>
									:
									<div className="me-2">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												defaultValue={"option"}
												className="form-control filter-form-control-input"
												value={type}
												onChange={(e) => onChangeTypeHandler(e.target.value)}
											>
												<option value="">Select Type</option>
												{
													props.typeList.map((item, index) => {
														return <option key={index} value={item.id}>{item.type_name}</option>
													})
												}
											</select>
										</div>
									</div>

							}



							<div className={`me-2 ${category == 3 ? 'content_disable' : null}`}>
								<div className="basic-dropdown">
									<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
									<select
										defaultValue={"option"}
										className="form-control filter-form-control-input"
										value={group}
										onChange={(e) => onChangeGroupHandler(e.target.value)}
									>
										<option value="">Select Group/Individual</option>
										<option value="1">Group Subject</option>
										<option value="2">Non Group Subject</option>
										<option value="3">Combo Subject</option>
										{/* {
											props.categorylist.map((item, index) => {
												return <option key={index} value={item.id}>{item.category}</option> (group != '' || category != '') ? disabled : null )
											})
										} */}
									</select>
								</div>
							</div>
							<div style={{ paddingTop: '5px' }} className="col-sm-1 px-1 ">
								<button onClick={resetHandler} className={`btn btn-secondary ${group != '' || category != '' ? null : 'content_disable'}`} disabled={group != '' || category != '' ? false : true} >Reset</button>
							</div>

						</div>
						<Card.Body>
							{
								props.totalRecord == 0 ? (
									<div className='no_data'>
										<strong >No data found</strong>
									</div>

								) : (
									<Table responsive>
										<thead>
											<tr>
												<th>
													<strong>Category</strong>
												</th>
												<th>
													<strong>Board/Type</strong>
												</th>
												<th>
													<strong>Image</strong>
												</th>
												<th>
													<strong>Icon</strong>
												</th>
												<th>
													<strong>Subject Name</strong>
												</th>
												<th>
													<strong>Short Code</strong>
												</th>
												<th>
													<strong>Color</strong>
												</th>
												<th>
													<strong>Create Date</strong>
												</th>

												{
													permission.status == "true" && (
														<th>
															<strong>Status</strong>
														</th>
													)
												}
												{
													(permission.edit == "true" || permission.delete == "true") && (
														<th>
															<strong>Action</strong>
														</th>
													)
												}
											</tr>
										</thead>

										{props.subjectList != '' ?
											<tbody>
												{
													props.subjectList.map((item, index) => {
														return <tr key={index}>
															<td>{item.exam_category_name}</td>
															<td>{item.board_name ? item.board_name : item.type_name}</td>
															<td>{item.subject_image == "undefined" || item.subject_image == null ? "No Image" : <div><img className="subject-img" src={item.subject_image} /></div>}</td>
															<td>{item.elibrary_image == "undefined" || item.elibrary_image == null || item.elibrary_image == "" ? "No Image" : <div><img className="subject-img" src={item.elibrary_image} /></div>}</td>
															<td>{item.name}</td>
															<td>{item.subject_code}</td>
															<td><div className="subject-color-box" style={{ backgroundColor: item.subject_color_code }}></div></td>
															<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
															{
																permission.status == "true" && (
																	<td>
																		<Button
																			className="btn-xs"
																			variant={item.status == 1 ? "success" : "danger"}
																			onClick={() => onUpdateStatus(item)}
																		>
																			{item.status == 1 ? "Active" : "Inactive"}
																		</Button>
																	</td>
																)
															}
															{
																(permission.edit == "true" || permission.delete == "true") && (
																	<td>
																		<div className="d-flex">
																			{
																				permission.edit == "true" && (
																					<Link
																						to={{
																							pathname: '/update-subject',
																							state: { 'data': item, 'filter': { 'page': active, 'category': category, 'board': board, 'type': type, 'sub_type': subtype_id, 'group': group } }
																						}}
																						className="btn btn-warning shadow btn-xs sharp me-1"
																					>
																						<i className="fas fa-pencil-alt"></i>
																					</Link>
																				)
																			}
																			{
																				permission.delete == "true" && (
																					<Button
																						className="btn btn-danger shadow btn-xs sharp"
																						onClick={() => onDeleteModal(item.id)}
																					>
																						<i className="fa fa-trash"></i>
																					</Button>
																				)
																			}
																		</div>
																	</td>
																)
															}
														</tr>
													})
												}
											</tbody>
											:
											<tbody>
												<td colSpan={12}>
													<div className='no_data'>
														<strong >No data found</strong>
													</div>
												</td>
											</tbody>
										}
									</Table>
								)
							}

							<div className="pagination-container">
								<div className="page-result">
									{props.totalRecord >  0 ?
										props.totalRecord !== 0 && <p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.subjectList.length)} of {props.totalRecord}</p>
										: null
									}

								</div>
								{props.totalRecord > 10 ?
									<ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i className="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i className="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={pageHandler}
										pageRangeDisplayed={3}
										pageCount={props.totalPage}
										forcePage={active - 1}
									/>
									: null
								}

							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment >
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.subject.showLoading,
		subjectList: state.subject.subjectList,
		totalPage: state.subject.totalPage,
		totalRecord: state.subject.totalRecord,
		categorylist: state.category.list,
		boardList: state.board.list,
		typeList: state.type.list,
	};
};
export default connect(mapStateToProps)(SubjectList);