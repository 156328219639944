import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Editor } from '@tinymce/tinymce-react';
import { getSettingsData, updatePrivacyPolicyData } from '../../../store/actions/SettingsAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import GlobalConfigs from "../../../configs/GlobalConfigs";

const PrivacyPolicy = (props) => {

    const dispatch = useDispatch();

    let errorsObj = { textBody: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [textBody, setTextBody] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getSettingsData(props.history));
        setTextBody(props.data);
    }, [props.data]);

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (textBody === '') {
            errorObj.textBody = 'Privacy Policy is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        dispatch(globalLoadingAction(true));
        dispatch(updatePrivacyPolicyData(textBody, props.history));

    }

    return (
        <>
            <div className="row">

                <div className="col-xl-6 col-xl-12">
                    <div className="row">

                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-2"></div>
                                    <div className="col-xl-8">

                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group ck-editer-view">
                                                        <label className="mb-2">
                                                            <strong>Privacy Policy</strong>
                                                        </label>
                                                        <Editor
                                                            apiKey={GlobalConfigs.TINY_API_KEY}
                                                            value={textBody}
                                                            onInit={(evt, editor) => {
                                                                setText(editor.getContent({ format: 'text' }));
                                                            }}
                                                            onEditorChange={(newValue, editor) => {
                                                                setTextBody(newValue);
                                                                setText(editor.getContent({ format: 'text' }));
                                                            }}
                                                            init={{
                                                                height: 300,
                                                                menubar: false,
                                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount'],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat | help',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                        />
                                                        {errors.textBody && <div className="text-danger fs-12">{errors.textBody}</div>}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Submit
                                                            {props.showLoading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                : null}
                                                        </button>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-2"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        showLoading: state.settings.showLoading,
        data: state.settings.privacyPolicy,
    };
};
export default connect(mapStateToProps)(PrivacyPolicy);
