import {
    TYPE_LOADING,
    ADD_EXAM_TYPE,
    GET_EXAM_TYPE,
    UPDATE_EXAM_TYPE,
    REMOVE_EXAM_TYPE,
    UPDATE_STATUS_EXAM_TYPE
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function ExamTypeReducer(state = initialState, action) {

    if (action.type === ADD_EXAM_TYPE) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_TYPE) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_EXAM_TYPE) {
        const typelist = [...state.list];
        const index = typelist.findIndex((item) => item.id === parseInt(action.payload.recid));
        typelist[index].exam_category = action.payload.exam_category;
        typelist[index].exam_category_id = parseInt(action.payload.exam_category_id);
        typelist[index].type_name = action.payload.type_name;
        typelist[index].short_code = action.payload.short_code;
        return {
            ...state,
            list: typelist,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_EXAM_TYPE) {

        const typelist = [...state.list];
        const index = typelist.findIndex((item) => item.id === action.payload.id);
        typelist[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: typelist,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EXAM_TYPE) {

        const typeList = [...state.list];
        const index = typeList.findIndex((item) => item.id === action.payload);
        typeList.splice(index, 1);

        return {
            ...state,
            list: typeList,
            showLoading: false,
        };
    }

    if (action.type === TYPE_LOADING) {
        return {
            ...state,
            showLoading: true,
        };
    }

    return state;
}


