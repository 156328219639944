import {
    ADD_EXAM_CATEGORY_CONFIRMED,
    CATEGOTY_LOADING_TOGGLE,
    GET_EXAM_CATEGORY,
    REMOVE_EXAM_CATEGORY,
    UPDATE_STATUS_EXAM_CATEGORY,
    UPDATE_EXAM_CATEGORY
} from '../constants';

const initialState = {
    list: [],
    showLoading: false,
};

export function ExamCategoryReducer(state = initialState, action) {

    if (action.type === ADD_EXAM_CATEGORY_CONFIRMED) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_EXAM_CATEGORY) {
        return {
            ...state,
            list: action.payload,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_EXAM_CATEGORY) {
        const categorylist = [...state.list];
        const index = categorylist.findIndex((item) => item.id === action.payload.recid);
        categorylist[index].category = action.payload.category;
        categorylist[index].short_code = action.payload.short_code;
        return {
            ...state,
            list: categorylist,
            showLoading: false,
        };
    }

    if (action.type === UPDATE_STATUS_EXAM_CATEGORY) {

        const categorylist = [...state.list];
        const index = categorylist.findIndex((item) => item.id === action.payload.id);
        categorylist[index].status = parseInt(action.payload.status) === 1 ? 0 : 1;

        return {
            ...state,
            list: categorylist,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EXAM_CATEGORY) {

        const categorylist = [...state.list];
        const index = categorylist.findIndex((item) => item.id === action.payload);
        categorylist.splice(index, 1);

        return {
            ...state,
            list: categorylist,
            showLoading: false,
        };
    }

    if (action.type === CATEGOTY_LOADING_TOGGLE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}


