import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import swal from "sweetalert";
import Moment from 'react-moment';
import { Link, useLocation, useHistory } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Button,
	Pagination
} from "react-bootstrap";

import { getBranchDetails } from '../../../store/actions/BranchActions';
import { getbranchTypeData } from '../../../store/actions/ChapterAction';
import { getClassData } from '../../../store/actions/ClassAction';
import { loadingContentAction, getQuestionListData, updateErrorLogData, downloadErrorExcelFile, updateErrorStatusData } from '../../../store/actions/ExamContentAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const QuestionErrorLog = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation();

	const [active, setActive] = useState(state == undefined ? 1 : state.active);
	const [demo, setDemo] = useState(state == undefined ? '' : state.demo);
	const [category, setCategory] = useState('');
	const [questiono, setQuestiono] = useState(state == undefined ? '' : state.questiono);
	const [branch, setBranch] = useState('');
	const [chapter, setChapter] = useState('');
	const [className, setClassName] = useState(state == undefined ? '' : state.className);
	const [errorLog, setErrorLog] = useState(state == undefined ? '' : state.errorLog);

	const [permission, setPermission] = useState("");

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionListData(active, demo, questiono, '', '', className, errorLog, props.history));
		dispatch(getBranchDetails(props.history));
		dispatch(getClassData(props.history));
	}, [demo, className, errorLog]);

	/* get class list */
	const onClassHandler = (value) => {
		dispatch(globalLoadingAction(true));
		setClassName(value);
		dispatch(getQuestionListData(1, demo, '', '', '', value, '', props.history));
		setActive(1);
	}

	/* get question list */
	const filterQuestionHandler = () => {
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionListData(1, '', questiono, '', '', '', '', props.history));
		setActive(1);
	}

	/* get demo list */
	const onDemoHandler = (value) => {
		setClassName('');
		setErrorLog('');
		setDemo(value);
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionListData(1, value, '', '', '', '', '', props.history));
		setActive(1);
	}

	/* download excel file */
	const onDownloadExcelHandler = () => {
		dispatch(globalLoadingAction(true));
		dispatch(downloadErrorExcelFile(props.history));
	}

	/* on error status change */
	const onErrorStatusHandler = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateErrorStatusData(item, props.history));
	}

	/* on change log */
	const onLogHandler = (value) => {
		setErrorLog(value);
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionListData(1, demo, '', '', '', className, value ? parseInt(value) : '', props.history));
	}

	/* question edit */
	const EditQuestion = (id) => {

		let obj = {
			active,
			demo,
			questiono,
			className,
			errorLog,
			currentPage: 2,
			id
		}

		history.push({
			pathname: '/update-question',
			state: obj
		});

	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(globalLoadingAction(true));
		dispatch(getQuestionListData(data.selected + 1, demo, questiono, branch, chapter, className, errorLog, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 50) {
				setPermission(item);
			}
		});
	};

	/* filter reset */
	const resetHandler = () => {
		setDemo('')
		setClassName('')
		setErrorLog('')
		setActive(1)
	  }


	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>

							<div className="add-question-view">
								<div className="hear-btn-content">
								</div>
								<div className="add-filter-group">
									<input
										className="form-control filter-form-control-input me-2"
										placeholder="Enter question No"
										value={questiono}
										onChange={(e) => setQuestiono(e.target.value)}
									/>
									<button onClick={filterQuestionHandler} className="btn btn-danger me-2">Search</button>
									<button onClick={onDownloadExcelHandler} className="btn btn-secondary me-2">Download Excel</button>
								</div>
							</div>

							<div className="row add-Question-list-view">

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={demo}
											onChange={(e) => onDemoHandler(e.target.value)}
										>
											<option value="">Select type</option>
											<option value='Demo'>Demo</option>
											<option value='Live'>Live</option>
										</select>
									</div>
								</div>

								{/* <div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={branch}
											onChange={(e) => onBranchHandler(e.target.value)}
										>
											<option value="">Select branch</option>
											{
												props.branchList.map((item, index) => {
													return <option key={index} value={item.id + '^' + item.branch_code}>{item.branch_name}</option>
												})
											}
										</select>
									</div>
								</div> */}

								{/* <div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={chapter}
											onChange={(e) => onChapterHandler(e.target.value)}
										>
											<option value="">Select chapter</option>
											{
												props.chapterList.map((item, index) => {
													return <option key={index} value={item.short_code}>{item.chapter_name}</option>
												})
											}
										</select>
									</div>
								</div> */}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={className}
											onChange={(e) => onClassHandler(e.target.value)}
										>
											<option value="">Select class</option>
											{
												props.classList.map((item, index) => {
													return <option key={index} value={item.class_no}>{item.short_code}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={errorLog}
											onChange={(e) => onLogHandler(e.target.value)}
										>
											<option value="">Select status</option>
											<option value="1">Resolve</option>
											<option value="2">Pending</option>
										</select>
									</div>
								</div>
								<div className="col-sm-1 px-1 ">
									<button onClick={resetHandler} className={`btn btn-secondary ${demo == '' && className == '' && errorLog == '' ? 'content_disable' : null} `}>Reset</button>
								</div>
							</div>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Id</strong>
										</th>
										<th>
											<strong>Exam type</strong>
										</th>
										<th>
											<strong>Standard</strong>
										</th>
										<th>
											<strong>Subject</strong>
										</th>
										<th>
											<strong>Chapter</strong>
										</th>
										<th>
											<strong>Question Type</strong>
										</th>
										<th>
											<strong>Question No</strong>
										</th>
										<th>
											<strong>Question Status</strong>
										</th>
										<th>
											<strong>Option A</strong>
										</th>
										<th>
											<strong>Option B</strong>
										</th>
										<th>
											<strong>Option C</strong>
										</th>
										<th>
											<strong>Option D</strong>
										</th>
										<th>
											<strong>reason Status</strong>
										</th>
										{
											permission.status == "true" && (
												<th>
													<strong>Action</strong>
												</th>
											)
										}

									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.id}</td>
												<td>{item.exam_type}</td>
												<td>{item.standard}</td>
												<td>{item.subject}</td>
												<td>{item.chapter}</td>
												<td>{item.question_type}</td>
												<td>
													<Link
														onClick={() => EditQuestion(item.question_no)}
													>
														{item.question_no}
													</Link>
												</td>
												<td>{item.question_status}</td>
												<td>{item.option_a}</td>
												<td>{item.option_b}</td>
												<td>{item.option_c}</td>
												<td>{item.option_d}</td>
												<td>{item.reason_status}</td>
												{
													permission.status == "true" && (
														<td>
															<Button
																className="btn-xs"
																variant={item.status == 1 ? "success" : "danger"}
																onClick={() => onErrorStatusHandler(item)}
															>
																{item.status == 1 ? "Resolve" : "Pending"}
															</Button>
														</td>
													)
												}
											</tr>
										})
									}
								</tbody>
							</Table>
							<div className="pagination-container">
								<div className="page-result">
									<p>Showing: {(active - 1) * (10) + 1} - {(10 * active) - (10 - props.list.length)} of {props.totalRecord}</p>
								</div>
								<ReactPaginate
									containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
									pageClassName='page-item'
									pageLinkClassName='page-link'
									nextLabel={<i class="la la-angle-right"></i>}
									nextClassName='page-item'
									nextLinkClassName='page-link'
									breakLabel="..."
									previousLabel={<i class="la la-angle-left"></i>}
									previousClassName='page-item'
									previousLinkClassName='page-link'
									breakClassName='page-item'
									breakLinkClassName='page-link'
									renderOnZeroPageCount={null}
									activeClassName="active"
									onPageChange={pageHandler}
									pageRangeDisplayed={3}
									pageCount={props.totalPage}
									forcePage={active - 1}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.content.showLoading,
		list: state.content.questionErrorList,
		totalPage: state.content.totalErrorPage,
		totalRecord: state.content.totalErrorRecord,
		branchList: state.branch.branchList,
		chapterList: state.chapter.typelist,
		classList: state.class.list,
	};
};

export default connect(mapStateToProps)(QuestionErrorLog);