import { GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST } from "../constants";

const initial = {
  list: [],
};

export function FailedSubscriptionReducer(state=initial,action) {

  if (action.type == GET_MIS_FAILED_SUBSCRIPTION_DETAILS_LIST) {
    return {
      ...state,
      list: action.payload,
    };
  } else {
    return state;
  }
}
