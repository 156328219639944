//Check token exist or not
export const CHECK_TOKEN_EXIST_API = "superadmin/checktokenexist";

//param - tag_image
export const UPLOAD_IMAGE_API = "superadmin/uploadimage";

//param - tag_pdf
export const UPLOAD_PDF_API = "superadmin/uploadpdf";

//param - email,password
export const LOGIN_API = "superadmin/login";

//param - email
export const FORGOT_PASSWORD_API = "superadmin/forgetpassword";

//param - message, password
export const RESET_PASSWORD_API = "superadmin/generatepassword";

//param - mobile
export const RESEND_OTP_API = "superadmin/resendotp";

//param - userid
export const GET_MENU_LIST_API = "superadmin/getmenulist";

//param - Null
export const GET_USER_TYPE_LIST_API = "superadmin/master/getusertypelist";

//param - email,phone,name,user_type
export const ADD_USER_LIST_API = "superadmin/registration";

//param - email,phone,name,gender
export const ADD_TEACHER_LIST_API = "superadmin/addteacher";

//param - id,email,phone,name
export const UPDATE_ADMIN_LIST_API = "superadmin/updateadmin";

//param - id,email,phone,name
export const UPDATE_CONTENT_DEVELOPER_LIST_API = "superadmin/updatecontentdevelopers";

//param - id,email,mobile,fname,lname,dob,profile_pic,gender,address,pincode,standard,board,school_name,school_address
export const UPDATE_STUDENT_LIST_API = "superadmin/updatestudents";

//param - id,email,phone,name
export const UPDATE_TEACHER_LIST_API = "superadmin/updateteachers";

//param - Null
export const GET_USER_LIST_API = "superadmin/getuserslist";

//param - page
export const GET_ADMIN_LIST_API = "superadmin/adminlist";

//param - page
export const GET_DEVELOPER_LIST_API = "superadmin/contentdeveloperlist";

//param - page
export const GET_STUDENT_LIST_API = "superadmin/studentslist";

//param - NULL
export const GET_TEACHER_LIST_API = "superadmin/teacherslist";

//param - id,user_type
export const REMOVE_USER_LIST_API = "superadmin/deleteuser";

//param - recid
export const REMOVE_ADMIN_LIST_API = "superadmin/deleteadmin";

//param - recid
export const REMOVE_CONTENT_DEVELOPER_LIST_API =
  "superadmin/deletecontentdeveloper";

//param - recid
export const REMOVE_STUDENT_LIST_API = "superadmin/deletestudent";

//param - recid
export const REMOVE_TEACHER_LIST_API = "superadmin/deleteteacher";

//param - recid,status
export const CHANGE_ADMIN_STATUS_API = "superadmin/changeadminstatus";

//param - recid,status
export const CHANGE_DEVELOPER_STATUS_API =
  "superadmin/changecontentdevelopertatus";

//param - recid,status
export const CHANGE_STUDENT_STATUS_API = "superadmin/changestudenttatus";

//param - recid,status
export const CHANGE_TEACHER_STATUS_API = "superadmin/changeteacherstatus";

//param - short_code, category
export const SET_EXAM_CATEGORY_API = "superadmin/master/setexamcategory";

//param - Null
export const GET_EXAM_CATEGORY_API = "superadmin/master/examcategorylist";

//param - recid, category,short_code
export const UPDATE_EXAM_CATEGORY_API = "superadmin/master/editexamcategory";

//param - recid,status
export const UPDATE_STATUS_EXAM_CATEGORY_API =
  "superadmin/master/updatestatusexamcategory";

//param - recid
export const REMOVE_EXAM_CATEGORY_API = "superadmin/master/deleteexamcategory";

//param - exam_category_id, type_name,short_code
export const SET_EXAM_TYPE_API = "superadmin/master/setexamtype";

//param - Null
export const GET_EXAM_TYPE_API = "superadmin/master/getexamtype";

//param - recid,type_name,exam_category_id,short_code
export const UPDATE_EXAM_TYPE_API = "superadmin/master/editexamtype";

//param - recid
export const REMOVE_EXAM_TYPE_API = "superadmin/master/deleteexamtype";

//param - recid,status
export const UPDATE_STATUS_EXAM_TYPE_API =
  "superadmin/master/updatestatusexamtype";

//param - subtype_name, exam_type_id
export const SET_EXAM_SUB_TYPE_API = "superadmin/master/setexamsubtype";

//param - Null
export const GET_EXAM_SUB_TYPE_API = "superadmin/master/getexamsubtype";

//param - Null
export const GET_EXAM_SUB_TYPE_BY_ID_API = "superadmin/master/getexamsubtype_byid";

//param - recid, exam_type_id, subtype_name
export const UPDATE_EXAM_SUB_TYPE_API = "superadmin/master/editexamsubtype";

//param - recid,status
export const UPDATE_STATUS_EXAM_SUB_TYPE_API =
  "superadmin/master/updatestatusexamsubtype";

//param - recid
export const REMOVE_EXAM_SUB_TYPE_API = "superadmin/master/deleteexamsubtype";

//param - name, short_code
export const SET_BOARD_API = "superadmin/master/setboard";

//param - recid,name, short_code
export const UPDATE_BOARD_API = "superadmin/master/editboard";

//param - Null
export const GET_BOARD_API = "superadmin/master/getboards";

//param - recid,status
export const UPDATE_STATUS_BOARD_API = "superadmin/master/updatestatusboard";

//param - recid
export const REMOVE_BOARD_API = "superadmin/master/deleteboard";

//param - class_no, short_code
export const SET_CLASS_API = "superadmin/master/setclass";

//param - recid,class_no, short_code
export const UPDATE_CLASS_API = "superadmin/master/editclass";

//param - Null
export const GET_CLASS_API = "superadmin/master/getclasses";

//param - board_id
export const GET_BOARD_BY_CLASS_API =
  "superadmin/master/getclasslistagainst_board";

//param - exam_type_id
export const GET_EXAM_TYPE_BY_CLASS_API =
  "superadmin/master/getclasslistagainst_examtype";

//param - recid,status
export const UPDATE_STATUS_CLASS_API = "superadmin/master/updatestatusclasses";

//param - recid
export const REMOVE_CLASS_API = "superadmin/master/deleteclasses";

//param - name
export const ADD_SUBJECT_API = "superadmin/master/setsubjects";

//param - recid,name,exam_type_id
export const UPDATE_SUBJECT_API = "superadmin/master/editsubject";

//param - Null
export const GET_SUBJECT_API = "superadmin/master/getsubjects";

//param - Null
export const GET_SUBJECT_FILTTER_API = "superadmin/subjectlist_filter";

//param-null
export const GET_SUBJECT_FILTER_E_SUB="superadmin/elibrary_subjects_list";

//param - exam_category_id, exam_type_id, exam_subtype_id, board_id
export const GET_NON_GROUP_SUBJECT_API =
  "superadmin/master/getsinglesubjectlist";

//param - board_id, class_id
export const GET_BOARD_CLASS_BY_SUBJECT_API =
  "superadmin/master/getsubjectlistagainst_board_class";

//param - recid,status
export const UPDATE_STATUS_SUBJECT_API =
  "superadmin/master/updatestatussubject";

//param - recid
export const REMOVE_SUBJECT_API = "superadmin/master/deletesubject";

//param - branch_name; subject_id; branch_code; question_no;
export const ADD_BRANCH_API = "superadmin/master/setbranch";

//param - recid,branch_name; subject_id; branch_code; question_no;
export const UPDATE_BRANCH_API = "superadmin/master/editbranch";

//param - Null
export const GET_BRANCH_LIST_API = "superadmin/master/getbranches";

//param - recid,status
export const UPDATE_STATUS_BRANCH_LIST_API =
  "superadmin/master/updatestatusbranch";

//param - recid
export const REMOVE_BRANCH_LIST_API = "superadmin/master/deletebranch";

//param - chapter_name, short_code, branch_id
export const SET_CHAPTERS_API = "superadmin/master/setchapters";

//param - recid,chapter_name, short_code, branch_id
export const UPDATE_CHAPTERS_API = "superadmin/master/editchapters";

//param - Null
export const GET_CHAPTERS_API = "superadmin/master/getchapters";

//param - Null
export const GET_CHAPTERS_FILTTER_API = "superadmin/chapterlist_filter";

export const GET_CHAPTERS_WITHOUT_PAGINATION="superadmin/chapter_list_filter"

//param - category_id, board_id, exam_type_id, subtype_id, subject_id, class_id
export const GET_CHAPTERS_FOR_DEFFARENTS_PARAMS_API =
  "superadmin/master/getchapterlist";

//param- category_id, board_id, exam_type_id, subtype_id, subject_id, class_id

export const UPDATE_CHAPTER_WITH_ORDER_NO =
  "superadmin/master/updatechapter_orderno";


//param - recid,status
export const UPDATE_STATUS_CHAPTERS_API =
  "superadmin/master/updatestatuschapter";

//param - branch_id
export const GET_CHAPTERS_BY_BRANCH_API =
  "superadmin/master/getchaptersbybranch";

//param - recid
export const REMOVE_CHAPTERS_API = "superadmin/master/deletechapter";

//param - name, short_code, exam_type_id, board_ids, marks
export const SET_QUESTION_PATTERN_API = "superadmin/master/setquestionpattern";

//param - name, short_code, exam_type_id, board_ids, marks
export const UPDATE_QUESTION_PATTERN_API =
  "superadmin/master/updatequestionpattern";

//param - Null
export const GET_QUESTION_PATTERN_API = "superadmin/master/getquestionpattern";

//param - recid,status
export const UPDATE_STATUS_QUESTION_PATTERN_API =
  "superadmin/master/updatestatusquestionpattern";

//param - recid
export const REMOVE_QUESTION_PATTERN_API =
  "superadmin/master/deletequestionpattern";

//param - question_zip
export const UPLOAD_QUESTION_FILE_API = "superadmin/master/uploadquestions";

//param - question_zip
export const UPLOAD_QUESTION_BULK_IMAGE_API =
  "superadmin/upload_bulk_question_images";

//param - question_zip
export const ADD_QUESTION_API = "superadmin/master/setquestions";

//param - id
export const GET_QUESTION_DETAILS_API = "superadmin/getquestionbyid";

//param - question_no
export const GET_ERROR_QUESTION_DETAILS_API =
  "superadmin/getquestionbyquestionno";

//param - null
export const GET_SCECIAL_CHARACTER_LIST_API = "superadmin/getspecialcharectors";

//param -
export const ADD_QUESTION_DETAILS_API = "superadmin/addquestion";

//param - id
export const UPDATE_QUESTION_DETAILS_API = "superadmin/updatequestion";

//param - page
export const GET_QUESTION_LIST_API = "superadmin/master/getquestions";

//param - id,status
export const UPDATE_QUESTION_APPROVE_LIST_API = "superadmin/approvequestion";

//param - ids,status
export const UPDATE_MULTIPLE_QUESTION_APPROVE_STATUS_API =
  "superadmin/approvemultiplequestion";

//param - ids,status
export const REMOVE_MULTIPLE_QUESTION_APPROVE_STATUS_API =
  "superadmin/deletemultiplequestion";

//param - path
export const DOWNLOAD_QUESTION_ZIP_API = "superadmin/master/downloadexcelfile";

//param - NULL
export const DOWNLOAD_EXCEL_ERROR_LOG_API =
  "superadmin/master/downloadexcel_errorlog";

//param - recid,log_status
export const UPDATE_QUESTION_ERROR_LOG_STATUS_API =
  "superadmin/updatequestions_errorlog";

//param - id,
export const REMOVE_QUESTION_LIST_API = "superadmin/deletequestion";

//param - id,image_name,index
export const REMOVE_QUESTION_IMAGE_API = "superadmin/removequestionimage";

//param - NULL
export const GET_QUESTION_ERROR_LOG_API =
  "superadmin/master/getquestions_errorlog";

//param - exam_category_id,exam_type_id,exam_subtype_id,board_id,class_id,subject_id,competive_exam_duration,set_exam_duration,module_exam_duration,mock_exam_duration
export const ADD_TIME_CONFIGURATION_API = "superadmin/master/setexamtimeconfig";

//param - null
export const GET_TIME_CONFIGURATION_API =
  "superadmin/master/examtimeconfiglist";

//param - exam_category_id,exam_type_id,exam_subtype_id,board_id,class_id,subject_id,competive_exam_duration,set_exam_duration,module_exam_duration,mock_exam_duration
export const UPDATE_TIME_CONFIGURATION_API =
  "superadmin/master/updateexamtimeconfiguration";

//param - id
export const REMOVE_TIME_CONFIGURATION_API =
  "superadmin/master/deleteexamtimeconfiguration";

//param - exam_category_id, board_id, type, configuration_details
export const ADD_EXAM_CONFIGURATION_API =
  "superadmin/master/setexamconfiguration";
//param - null

export const GET_EXAM_CONFIGURATION_API =
  "superadmin/master/getexamconfiguration";

//param - id,exam_category_id, board_id, type, configuration_details
export const UPDATE_EXAM_CONFIGURATION_API =
  "superadmin/master/updateexamconfiguration";

//param - id
export const REMOVE_EXAM_CONFIGURATION_API =
  "superadmin/master/deleteexamconfiguration";

//param - class,exam_type_id, set_count, question_per_set, amount
export const ADD_COMPETETIVE_SUBSCRIPTION_API =
  "superadmin/storeexamcompetetivesubscription";

//param - recid,class,exam_type_id, set_count, question_per_set, amount
export const UPDATE_COMPETETIVE_SUBSCRIPTION_API =
  "superadmin/editexamcompetetivesubscription";

//param - subject_id, board_id, class, set_count, question_per_set, amount_per_set, module_amount, total_modules, mock_amount, total_mocks, case_study_amount
export const ADD_SCHOLASTIC_SUBSCRIPTION_API = "superadmin/storeexamscolasticsubscription";

//param - recid,subject_id, board_id, class, set_count, question_per_set, amount_per_set, module_amount, total_modules, mock_amount, total_mocks, case_study_amount
export const UPDATE_SCHOLASTIC_SUBSCRIPTION_API = "superadmin/editexamscolasticsubscription";

//param - recid, exam_category_id, exam_type_id, board_id, subject_id, class, library_price
export const UPDATE_LIBRARY_SUBSCRIPTION_LIST_API = "superadmin/update_elibrary_subscription_details";

//param - NULL
export const GET_COMPETETIVE_SUBSCRIPTION_LIST_API = "superadmin/getexamcompetetivesubscription";

//param - NULL
export const UPDATE_COMPETETIVE_SUBSCRIPTION_RANKING_API = "superadmin/getexamcompetetivesubscription_ranking";

//param - NULL
export const GET_SCHOLASTIC_SUBSCRIPTION_LIST_API = "superadmin/getexamscolasticsubscription";

//param - NULL
export const UPDATE_SCHOLASTIC_SUBSCRIPTION_RANKING_API ="superadmin/updateexamscolasticsubscription_ranking";

//param - NULL
export const ADD_INTEGRATED_SUBSCRIPTION_API ="superadmin/storeintegratedsubscription";

//param - NULL
export const UPDATE_INTEGRATED_SUBSCRIPTION_API ="superadmin/editintegratedsubscription";

//param - NULL
export const GET_INTEGRATED_SUBSCRIPTION_API ="superadmin/getintegratedsubscription";

//param - NULL
export const UPDATE_INTEGRATED_SUBSCRIPTION_RANKING_API ="superadmin/updateintegratedsubscription_ranking";

//param - NULL
export const UPDATE_INTEGRATED_SUBSCRIPTION_STATUS_API ="superadmin/updatestatusintegratedsubscription";

//param - NULL
export const REMOVE_INTEGRATED_SUBSCRIPTION_API ="superadmin/deleteintegratedsubscription";

//param - NULL
export const UPDATE_LIBRARY_SUBSCRIPTION_RANKING_API =
  "superadmin/updateelibrarysubscription_ranking";

//param - NULL
export const GET_E_LIBRARY_SUBSCRIPTION_LIST_API =
  "superadmin/e-library_subscription_list";

//param - recid,status
export const UPDATE_COMPETETIVE_SUBSCRIPTION_STATUS_API =
  "superadmin/updatestatusexamcompetetivesubscription";

//param - recid,status
export const UPDATE_SCHOLASTIC_SUBSCRIPTION_STATUS_API =
  "superadmin/updatestatusexamscolasticsubscription";

//param - recid,status
export const UPDATE_LIBRARY_SUBSCRIPTION_STATUS_API =
  "superadmin/update_e_library_subscription_status";

//param - recid
export const REMOVE_COMPETETIVE_SUBSCRIPTION_LIST_API =
  "superadmin/deleteexamcompetetivesubscription";

//param - recid
export const REMOVE_SCHOLASTIC_SUBSCRIPTION_LIST_API =
  "superadmin/deleteexamscolasticsubscription";

//param - recid
export const REMOVE_Library_SUBSCRIPTION_LIST_API =
  "superadmin/delete_e_library_subscription";

//param
export const GET_ELIBRARY_LIST_API = "contentdeveloper/e-library-list";

//param
export const GET_ELIBRARY_dashboard_API = "superadmin/elibrary_dashboard";

//param
export const GET_CONTENT_VALIDATOR_LIST_API =
  "contentvalidator/contentvalidatorlist";

//param //param id, name, email, phone
export const UPDATE_CONTENT_VALIDATOR_API =
  "contentvalidator/updatecontentvalidator";

//param id, status
export const UPDATE_CONTENT_VALIDATOR_STATUS_API =
  "contentvalidator/changecontentvalidatorstatus";

//param
export const REMOVE_CONTENT_VALIDATOR_LIST_API =
  "contentvalidator/deletecontentvalidator";

//param
export const GET_LANGUAGE_VALIDATOR_LIST_API =
  "contentvalidator/contentlanguagevalidatorlist";

//param id,name,email,phone
export const UPDATE_LANGUAGE_VALIDATOR_API =
  "superadmin/updatecontentdevelopers";

//param id,status
export const UPDATE_LANGUAGE_VALIDATOR_STATUS_API =
  "superadmin/changecontentdevelopertatus";

//param id
export const REMOVE_LANGUAGE_VALIDATOR_API =
  "superadmin/deletecontentdeveloper";

//param
export const GET_CONTENT_DESIGNER_LIST_API =
  "contentdesigner/contentdesignerlist";

//param id, name, email, phone
export const UPDATE_CONTENT_DESIGNER_API =
  "contentdesigner/updatecontentdesigner";

//param id,status
export const UPDATE_CONTENT_DESIGNER_STATUS_API =
  "contentdesigner/changecontentdesignerstatus";

//param id
export const REMOVE_CONTENT_DESIGNER_LIST_API =
  "contentdesigner/deletecontentdesigner";

//param
export const APPROVE_VALIDATOR_API =
  "contentvalidator/assign_e-libray_content_validator";

//param - formData ( library_zip, user_id, branch_id, chapter_id, class_id, exam_category_id, exam_type_id, board_id)
export const UPLOAD_ELIBRARY_API = "contentdeveloper/uploadlibrary";

//param - branch_id
export const GET_CHAPTER_LIST_BY_BRANCH_ID_API =
  "contentdeveloper/getchaptersbybranch";

//param - formData ( recid, library_pdf)
export const POST_UPLOAD_CONCEPT_MAP_API = "contentdeveloper/uploadconceptmap";

//param - formData ( recid, library_pdf)
export const POST_PUBLISHED_LIBRARY_API = "contentdeveloper/publishelibrary";

//param - null
export const POST_CONTECT_DEVELOPER_LIST_API =
  "superadmin/contentdeveloperlist";

//param - user_id, branch_id, chapter_id, class_id, exam_category_id, exam_type_id, board_id, assigned_developer
export const POST_ASSIGN_LIBRARY_API = "superadmin/assignlibrary";

//param - recid
export const POST_DELETE_CONTENT_LIBRARY_API =
  "contentdeveloper/deleteelibrary";

//param - title, sub_title, description, event_date
export const ADD_EVENT_HISTORY_API = "superadmin/addevent";

//param - record_id,title, sub_title, description, event_date
export const Edit_EVENT_HISTORY_API = "superadmin/editevent";

//param - null
export const GET_EVENT_HISTORY_API = "superadmin/getevents";

//param - recid,status
export const UPDATE_EVENT_HISTORY_STATUS_API = "superadmin/updatestatusevents";

//param - recid
export const REMOVE_EVENT_HISTORY_API = "superadmin/deleteevents";

//param - recid,name,email,phone,profile_pic
export const UPDATE_PROFILE_API = "superadmin/master/updateprofile";

//param - request_type, exam_category_id, board_code, branch_id, chapter_id, class_id, question_no_details, date_of_submission, payment_per_page, assigned_developer
export const ADD_ASSIGN_CONTENT_DEVELOPER_API = "contentdeveloper/create_assign_content";

//param - null
export const ADD_ASSIGN_CONTENT_QUESTION_DEVELOPER_API ="contentdeveloper/create_assign_content_questions";

//param - recid
export const UPDATE_ASSIGN_CONTENT_QUESTION_DEVELOPER_API ="contentdeveloper/update_content_assign_questions";

//param - null
export const GET_ASSIGN_CONTENT_QUESTION_DEVELOPER_API ="contentdeveloper/assign_content_request_list_questions";

//param - null
export const GET_ASSIGN_CONTENT_DEVELOPER_LIST_API = "contentdeveloper/assign_content_request_list";

//param - rec_id
export const GET_E_LIBRARY_CONTENT_REQUEST_DETAILS_API =
  "contentdeveloper/get_content_request_details_elibrary";

//param - rec_id
export const GET_QUESTION_CONTENT_REQUEST_DETAILS_API =
  "contentdeveloper/get_content_request_details_question";

//param - rec_id
export const ASSIGN_CONTENT_VALIDATOR_API =
  "contentdeveloper/assign_contentvalidator";

//param - type_of_exam_name
export const ADD_TYPE_OF_EXAM_API = "superadmin/master/settypeofexam";

//param - Content-Type
export const GET_TYPE_OF_EXAM_API = "superadmin/master/typeofexamlist";

//param - recid,status
export const UPDATE_STATUS_TYPE_OF_EXAM_API =
  "superadmin/master/updatestatustypeofexam";

//param - recid,type_of_exam_name
export const UPDATE_TYPE_OF_EXAM_API = "superadmin/master/edittypeofexam";

//param - recid,
export const REMOVE_TYPE_OF_EXAM_API = "superadmin/master/deletetypeofexam";

//param - title, exam_category_id, board_id, class_id, subject_id, syllabus_file_path, academic_session, exam_date, type_exam, question_type, total_no_question, total_marks, total_time
export const ADD_SCHOLASTIC_EXAM_DETAILS_API =
  "superadmin/master/addrecord_exam_details_scholastic";

//param - title, exam_category_id, board_id, class_id, subject_id, syllabus_file_path, academic_session, exam_date, type_exam, question_type, total_no_question, total_marks, total_time
export const UPDATE_SCHOLASTIC_EXAM_DETAILS_API =
  "superadmin/master/editrecord_exam_details_scholastic";

//param - null,
export const GET_SCHOLASTIC_EXAM_DETAILS_API =
  "superadmin/master/get_exam_details_scholastic";

//param - title, exam_category_id, exam_type_id, exam_subtype_id, class_id, syllabus_file_path, academic_session, exam_date, subjects_ary, total_no_question, total_marks, total_time
export const ADD_COMPETITIVE_EXAM_DETAILS_API =
  "superadmin/master/addrecord_exam_details_competitive";

//param - id,title, exam_category_id, exam_type_id, exam_subtype_id, class_id, syllabus_file_path, academic_session, exam_date, subjects_ary, total_no_question, total_marks, total_time
export const UPDATE_COMPETITIVE_EXAM_DETAILS_API =
  "superadmin/master/editrecord_exam_details_competitive";

//param - null,
export const GET_COMPETITIVE_EXAM_DETAILS_API =
  "superadmin/master/get_exam_details_competitive";

//param - id,
export const REMOVE_SCHOLASTIC_EXAM_DETAILS_API =
  "superadmin/master/delete_exam_details_scholastic";

//param - id,
export const REMOVE_COMPETITIVE_EXAM_DETAILS_API =
  "superadmin/master/delete_exam_details_competitive";

//param - id, status
export const UPDATE_SCHOLASTIC_EXAM_DETAILS_STATUS_API =
  "superadmin/master/updatestatus_exam_details_scholastic";

//param - id, status
export const UPDATE_COMPETITIVE_EXAM_DETAILS_STATUS_API =
  "superadmin/master/updatestatus_exam_details_competitive";

//param - upload_excel
export const UPLOAD_SCHOOL_DETAILS_API = "superadmin/master/uploadschoolnames";

//param - NULL
export const SCHOOL_LIST_API = "superadmin/master/schoollist";


//param -
export const UPDATE_SCHOOL_API = "institute/editschool";

//param - school_id
export const REMOVE_SCHOOL_API = "superadmin/master/deleteschool";

//param - upload_file
export const UPLOAD_CAREER_GUIDE_API = "superadmin/master/uploadstudyguide";

//param - null
export const GET_SETTINGS_API = "superadmin/get_settings";

//param - gst_rate,scrolling_text
export const UPDATE_SETTINGS_API = "superadmin/update_settings";

//param - terms_condition
export const UPDATE_TERMS_CONDITION_API = "superadmin/update_terms_condition";

//param - update_privacy_policy
export const UPDATE_PRIVACY_POLICY_API = "superadmin/update_privacy_policy";

//backup - database
export const DB_BCKUP = "superadmin/getbackup_database_access";

//param - Get Feedback
export const GET_FEEDBACK_API = "superadmin/master/getfeedbackquestions";
export const SENT_STATUS = "superadmin/master/updatestatusfeedbackquestions";
export const ADD_FEEDBACK = "superadmin/master/setfeedbackquestions";
export const DELETE_FEEDBACK = "superadmin/master/deletefeedbackquestions";
export const UPDATE_FEEDBACK = "superadmin/master/editfeedbackquestions";

//backup - token
export const GER_MENU_PERMISSION_LIST_API = "superadmin/getadminmenulist";

//param - datediff,board,class,pincode
export const GET_MIS_REGISTER_USER_API = "misreport/registered_users";

//param -datediff,user_problem,action_taken
export const GET_FAILED_REGISTRATION_API = "misreport/failed_registration";

//param - id,message,reply_mail_date,status
export const UPDATE_FAILED_REGISTRATION_API = "misreport/update_fail_register_suggestion_call"

//param -datediff,board,class,pincode,category,subject,services,fsd,nbf
export const GET_SUBSCRIPTION_DETAILS = "misreport/subscription_details"

//param -datediff,trans_status,action_taken
export const GET_FAILED_SUBSCRIPTION_DETAILS = "misreport/failed_subscription"

//param -id,message,reply_mail_date,status
export const UPDATE_FAILED_SUBSCRIPTION = "misreport/update_fail_subscription_suggestion_call"

//param -datediff
export const GET_DASHBOARD_INFO = "misreport/mis_dashboard"

//param -board,class,services,complete_percentage
export const GET_USER_ACTIVITY_DATABASE_LIST = "misreport/subscribed_user_activity"


//param -null
export const GET_DISTRICT_LIST = "institute/getdistrict_list"

//param -null
export const GET_STATE_LIST = "institute/getstate_list"

//param -role_name,description,role_permission
export const ADD_ROLE = "superadmin/setadminrole"

//param -null
export const GET_ROLE_LIST = "superadmin/getrolelist"

//param -region,pincode,board,school_name,school_address,website,contact_number,alternate_contact_number,name_of_head,email,enrollment_date,affiliation_no,affiliation_period_from,affiliation_period_to,school_status,academic_year,foundation_year
export const ADD_NEW_SCHOOL = "institute/addschool"

//param-role_id
export const DELETE_ROLE = "superadmin/deleteadminrole"

//param-role_id,role_name,description,role_permission
export const UPDATE_ROLE = "superadmin/editadminrole"

//param - 
export const GET_SUBJECTS_BY_BOARDS_API = "superadmin/getsubjectsby_boards";

//param - user_type, payment_per_page,assigned_to_whom,date_of_submission,recid
export const POST_UPDATE_CONTENT_ASSIGN_API = "contentdeveloper/update_content_assign";

//param - recid
export const POST_DELETE_ASSIGN_CONTENT_API = "contentdeveloper/delete_assign_content";

//param - recid
export const POST_GET_REJECTED_LIST_API = "contentdeveloper/get_rejected_list";

//param - recid
export const POST_GET_ADDED_DOCUMENT_DETAILS_API = "contentdeveloper/getaddeddocument_details";

//param - recid
export const GET_ASSIGN_DETAILS_BY_ID_API = "contentdeveloper/getquestion_details_byid";

//param - recid
export const POST_ACCEPT_REJECT_ASSIGN_DETAILS_BY_ID_API = "contentdeveloper/acceptrejectuploaded_content_questions";

//param - recid
export const POST_DELETE_ASSIGN_CONTENT_QUESTION_API = "contentdeveloper/delete_assign_content_question";

//param - recid
export const POST_ACCEPT_REJECT_ASSIGN_SINGLE_QUESTION_DETAILS_BY_ID_API = "contentdeveloper/acceptreject_single_questions";

//param - recid, status (1 = Accept, 2= Reject)
export const POST_ACCEPT_REJECT_UPLOADED_CONTENT_API = "contentdeveloper/acceptrejectuploaded_content";

//param - recid, date_of_submission, payment_per_page, assigned_validator
export const POST_ASSIGN_TO_VALIDATOR_ELIBRARY_API = "contentvalidator/assign_to_validator_elibrary";

//param - recid, date_of_submission, payment_per_page, assigned_validator
export const POST_ASSIGN_TO_LANGUAGE_VALIDATOR_ELIBRARY_API = "contentvalidator/assign_to_language_validator_elibrary";

//param - recid, date_of_submission, payment_per_page, assigned_validator
export const POST_ASSIGN_TO_DESIGNER_ELIBRARY_API = "contentdesigner/assign_to_designer_elibrary";

//param - recid
export const POST_GET_LIBRARY_HISTORY_LOG_API = "superadmin/getlibraryhistorylog";

//param - recid
export const POST_GET_QUESTIONS_HISTORY_LOG_API = "superadmin/getquestionshistorylog";

//param - recid
export const POST_GET_SINGLE_SUBJECT_LIST_API = "superadmin/master/getsubjects_nongroups";

//param - old_password, new_password
export const POST_UPDATE_OLD_PASSWORD_API = "superadmin/updateoldpassword";

//param - old_password, new_password
export const POST_NOTIFICATION_DETAILS_API = "superadmin/notification_details";

//param - recid
export const POST_READ_NOTIFICATION_API = "superadmin/read_notification";

//param - recid
export const POST_PAYMENT_TRANSACTION_DETAILS_API = "misreport/payment_transaction_details";

//param - recid
export const POST_UPLOAD_LMS_GUIDLINE_API = "superadmin/master/uploadlmsguideline";

//param - NULL
export const RESUME_LIST_API = "superadmin/get_applied_users_list";

export const RESUME_STATUS_UPDATE="superadmin/update_attended_status"

export const INDIVIDUAL_RESUME="superadmin/get_applied_users_details"

//param - NULL
export const GET_ADVERTIESMENT_SCREEN_API = "superadmin/master/get_screen_lists";

//param - title,estimation_time,screen_name,description,advertisments_image
export const ADD_ADVERTIESMENT_API="superadmin/master/addadvertisments"

//param - NULL
export const GET_ADVERTIESMENT_API = "superadmin/master/getadvertisments";

//param - recid
export const REMOVE_ADVERTIESMENT_API = "superadmin/master/deleteadvertisments";

//param - recid
export const UPDATE_ADVERTIESMENT_STATUS_API = "superadmin/master/update_advertisments_status";

//param - title,estimation_time,screen_name,description,advertisments_image
export const UPDATE_ADVERTIESMENT_API="superadmin/master/edit_advertisments";

//param - exam_category_id
export const ADD_ACADEMIC_SESSIONS_API="superadmin/master/add_academic_sessions";

//param - NULL
export const GET_ACADEMIC_SESSIONS_API = "superadmin/master/academic_sessions_list";

//param - recid
export const REMOVE_ACADEMIC_SESSIONS_API = "superadmin/master/delete_academic_sessions";

//param - exam_category_id
export const UPDATE_ACADEMIC_SESSIONS_API="superadmin/master/update_academic_session";

//param - recid
export const UPDATE_ACADEMIC_SESSIONS_STATUS_API = "superadmin/master/update_academic_sessions_status";

//param - title,estimation_time,screen_name,description,advertisments_image
export const CREATE_USERS_DROPDOWN_DATA_API="superadmin/master/create_users_dropdown_data";

//param - null
export const GET_CANCEL_REQUEST_QUESTION_API="contentdeveloper/getrejection_reasons_master";


//param - null
export const GET_CALL_MIGRATION_TAKS_API="migration/call_migration_tasks";